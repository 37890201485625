import axios from 'axios';
import { stringify } from 'qs';

import { StrapiResponseList } from '../interfaces/commons';
import {
  CourseLessonTemplateRequest,
  CourseLessonTemplateResponse,
} from '../interfaces/course-template-lessons';

const { REACT_APP_API_URL } = process.env;

export const getCourseTemplateLessons = async (
  searchParams: any,
  token: string | null
) => {
  const queryParams = stringify(
    {
      populate: 'lessonTemplate',

      sort: ['order:asc'],

      filters: {
        courseTemplate: searchParams.get('courseTemplate'),
      },
    },
    {
      skipNulls: true,
    }
  );

  const { data } = await axios.get<
    StrapiResponseList<CourseLessonTemplateResponse>
  >(`${REACT_APP_API_URL}/course-lesson-templates?${queryParams}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
};

export const postCourseTemplateLesson = async (
  courseTemplateId: number,
  lessonTemplateId: number,
  order: number,
  token: string | null
) => {
  return axios.post(
    `${REACT_APP_API_URL}/course-lesson-templates`,
    {
      data: {
        courseTemplate: courseTemplateId,
        lessonTemplate: lessonTemplateId,
        order: order + 1,
      },
    },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
};

export const putCourseTemplateLesson = async (
  relationId: number,
  updatedRelation: CourseLessonTemplateRequest,
  token: string | null
) => {
  return axios.put(
    `${REACT_APP_API_URL}/course-lesson-templates/${relationId}`,
    {
      data: updatedRelation,
    },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
};

export const deleteCourseTemplateLesson = async (
  relationId: number,
  token: string | null
) => {
  return axios.delete(
    `${REACT_APP_API_URL}/course-lesson-templates/${relationId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
};

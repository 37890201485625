import { useFormContext } from 'react-hook-form';
import { CourseTemplateRequest } from '../interfaces/course-templates';
import InputPresentational from './Form/InputPresentational';
import PriceInputPresentational from './Form/PriceInputPresentational';
import RadioGroupPaymentCourse from './Form/RadioGroupPaymentCourse';
import H4SpacingLetter from './Typography/H4SpacingLetter';
import { useAuth } from '../contexts/Auth';

const FormCostiNuovoTemplate = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CourseTemplateRequest>();

  const [{ settings }] = useAuth();
  const initialSubscriptionAmount = watch('initialSubscriptionAmount');
  const subscriptionQuote = watch(
    'headquarterBalance.administrations.estimatedPriceUnit'
  );

  const TransferSum =
    (initialSubscriptionAmount || 0) + (subscriptionQuote || 0);

  const TransferMarkup = TransferSum * (settings?.bankTransferFee || 0.2);
  const bankTransferTotal = Math.ceil(TransferSum + TransferMarkup);

  return (
    <div className='pt-6 pb-8 space-y-8'>
      <div className='space-y-4'>
        <H4SpacingLetter>Quota corso</H4SpacingLetter>

        <div className='grid md:grid-cols-3 items-start gap-4'>
          <PriceInputPresentational
            id='initialSubscriptionAmount'
            label='Quota corso unitaria GIÀ SCONTATA'
            name='initialSubscriptionAmount'
            type='number'
            register={register}
            required
            error={{ ...errors.initialSubscriptionAmount, type: 'required' }}
          />
          <PriceInputPresentational
            id=''
            label='Prezzo unitario di vendita'
            name=''
            type='number'
            value={bankTransferTotal}
            disabled
          />
          {/* <div className='md:col-span-2'>
            <p className='mb-0 text-gray-500 text-sm italic'>
              * Il prezzo unitario corrisponde al prezzo minimo pagato con
              carta.
              <br />
              Il pagamento con bonifico avrà una maggiorazione del 20%.
            </p> */}
          {/* inserire la percentuale corretta della maggiorazione */}
          {/* </div> */}
        </div>
      </div>
      <div className='space-y-4'>
        <H4SpacingLetter>Spesa amministrativa</H4SpacingLetter>
        <div className='grid md:grid-cols-3 items-end gap-4'>
          <PriceInputPresentational
            id='headquarterBalance.administrations.estimatedPriceUnit'
            label='Spesa amministrativa unitaria'
            name='headquarterBalance.administrations.estimatedPriceUnit'
            type='number'
            register={register}
            required
            error={{ ...errors.administrativeAmount, type: 'required' }}
          />
        </div>
      </div>
      <div className='space-y-4'>
        <RadioGroupPaymentCourse isCourseCanceled={false} />
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Numero di partecipanti</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            id='minParticipants'
            label='Numero minimo di partecipanti'
            name='minParticipants'
            type='number'
            register={register}
            required
            error={{ ...errors.minParticipants, type: 'required' }}
          />
          <InputPresentational
            id='maxParticipants'
            label='Numero massimo di partecipanti'
            name='maxParticipants'
            type='number'
            register={register}
            required
            error={{ ...errors.maxParticipants, type: 'required' }}
          />
        </div>
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Liquidi</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            id='glassesForBottle'
            label='Numero bicchieri per bottiglia'
            name='glassesForBottle'
            type='number'
            register={register}
          />
          <InputPresentational
            id='wineTypes'
            label='Numero tipologie di vino'
            name='wineTypes'
            type='number'
            register={register}
          />
          <PriceInputPresentational
            id='bottlePrice'
            label='Prezzo medio a bottiglia in €'
            name='bottlePrice'
            type='number'
            register={register}
          />
        </div>
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Materiale didattico</H4SpacingLetter>

        <div className='grid md:grid-cols-3 items-end gap-4'>
          <PriceInputPresentational
            id='materialAmount'
            label='Quota materiale didattico'
            name='materialAmount'
            type='number'
            register={register}
          />
          <PriceInputPresentational
            id='shippingMaterialAmount'
            label='Spedizione materiale'
            name='shippingMaterialAmount'
            type='number'
            register={register}
          />
        </div>
      </div>
      <div className='space-y-4'>
        <H4SpacingLetter>Spese della sede</H4SpacingLetter>
        <div className='grid md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            id='safetyMargin'
            label='Margine di sicurezza (%)'
            name='safetyMargin'
            defaultValue={(settings?.safetyMargin || 0) * 100}
            type='number'
            register={register}
          />
        </div>
      </div>
    </div>
  );
};

export default FormCostiNuovoTemplate;

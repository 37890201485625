import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import TesoreriaTable from '../Tables/TesoreriaTable';
import { useMutation, useQuery } from 'react-query';
import receipts, { find } from '../../../api/receipts';
import { useAuth } from '../../../contexts/Auth';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import FiltersBar from '../../../components/Filters/FiltersBar';
import { FilterType } from '../../../interfaces/filters';
import sectionsAPIs from '../../../api/section';
import { useDebounce } from 'use-debounce';
import qs from 'qs';
import Pagination from '../../../components/Pagination';
import {
  PAYMENT_METHODS,
  getDateTimeFromDate,
} from '../../../libs/utils/helpers';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import moment from 'moment';
import { DownloadIcon } from '@heroicons/react/solid';
import { isDelegato } from '../../../libs/utils/auth';
import { ProductType } from '../../../interfaces/orders';
import { PaymentMethod, PaymentStatus } from '../../../interfaces/payments';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ListaRicevute = () => {
  const [{ token, profile }] = useAuth();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);

  const keywords = debounceSearchParams.get('search')?.split(' ');

  const search =
    keywords?.map((keyword) => {
      return {
        $or: [
          {
            profile: {
              $or: [
                { name: { $contains: keyword } },
                { surname: { $contains: keyword } },
              ],
            },
          },
          {
            section: {
              $or: [
                {
                  name: { $contains: debounceSearchParams.get('search') },
                },
              ],
            },
          },
          { code: { $contains: debounceSearchParams.get('search') } },
          {
            notes: { $contains: debounceSearchParams.get('search') },
          },
          {
            description: {
              $contains: debounceSearchParams.get('search'),
            },
          },

          {
            fiscalCode: {
              $contains: debounceSearchParams.get('search'),
            },
          },
          {
            badgeNumber: {
              $contains: debounceSearchParams.get('search'),
            },
          },
          { type: { $contains: debounceSearchParams.get('search') } },
        ],
      };
    }) || [];
  const paypalSearch =
    keywords?.map((keyword) => {
      return {
        $or: [
          {
            profile: {
              $or: [
                { name: { $contains: keyword } },
                { surname: { $contains: keyword } },
              ],
            },
          },
          {
            payment: {
              receipt: {
                $or: [
                  {
                    section: {
                      $or: [
                        {
                          name: {
                            $contains: debounceSearchParams.get('search'),
                          },
                        },
                      ],
                    },
                  },
                  { code: { $contains: debounceSearchParams.get('search') } },
                  {
                    notes: { $contains: debounceSearchParams.get('search') },
                  },
                  {
                    description: {
                      $contains: debounceSearchParams.get('search'),
                    },
                  },

                  {
                    fiscalCode: {
                      $contains: debounceSearchParams.get('search'),
                    },
                  },
                  {
                    badgeNumber: {
                      $contains: debounceSearchParams.get('search'),
                    },
                  },
                  { type: { $contains: debounceSearchParams.get('search') } },
                ],
              },
            },
          },
        ],
      };
    }) || [];

  const dateFilter = {
    date: {
      $gte: qs.parse(
        Object.entries(qs.parse(debounceSearchParams.get('date') + '')).map(
          ([, value]) => value as any
        )[0]?.label
      ).from,
      $lte: getDateTimeFromDate(
        qs.parse(
          Object.entries(qs.parse(debounceSearchParams.get('date') + '')).map(
            ([, value]) => value as any
          )[0]?.label
        ).to
      ),
    },
  };

  const queryFiltersReceipts = {
    filters: {
      $and: [...search, dateFilter],
      paymentMethod: {
        $in: Object.entries(qs.parse(queryParams.get('paymentMethod') + ''))
          .map(([, value]) => value)
          .map((elem: any) => elem.value),
      },
      section: {
        id: {
          $in: Object.entries(qs.parse(queryParams.get('sections') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.value),
        },
      },
    },
    sort: 'date:desc',
    populate: [
      'entries.causal',
      'entries.amount',
      'section',
      'date',
      'paymentMethod',
      'fiscalCode',
      'badgeNumber',
      'name',
      'type',
      'code',
      'description',
      'profile.orders.payment',
      'payments',
      'orders.coupon',
      'orders.payment',
    ],
    pagination: {
      page: debounceSearchParams?.get('page'),
      pageSize: debounceSearchParams?.get('pageSize') || 5,
    },
  };
  const queryFiltersPaypalReconciliation = {
    filters: {
      $and: [...paypalSearch],
      payment: {
        method: { $eq: PaymentMethod.PAYPAL },
        status: { $eq: PaymentStatus.COMPLETED },
        receipt: {
          section: {
            id: {
              $in: Object.entries(qs.parse(queryParams.get('sections') + ''))
                .map(([, value]) => value)
                .map((elem: any) => elem.value),
            },
          },
          date: {
            $gte: qs.parse(
              Object.entries(
                qs.parse(debounceSearchParams.get('date') + '')
              ).map(([, value]) => value as any)[0]?.label
            ).from,
            $lte: getDateTimeFromDate(
              qs.parse(
                Object.entries(
                  qs.parse(debounceSearchParams.get('date') + '')
                ).map(([, value]) => value as any)[0]?.label
              ).to
            ),
          },
        },
      },
      $or: [
        { productType: { $in: [ProductType.EVENT, ProductType.COURSE] } },
        {
          productType: ProductType.SUBSCRIPTION,
          associationCode: { $null: true },
        },
      ],
    },
    sort: 'payment.date:desc',
    populate: [
      'payment.receipt.entries.causal',
      'payment.receipt.entries.amount',
      'payment.receipt.section',
      'payment.receipt.date',
      'payment.receipt.paymentMethod',
      'payment.receipt.fiscalCode',
      'payment.receipt.badgeNumber',
      'payment.receipt.name',
      'payment.receipt.type',
      'payment.receipt.code',
      'payment.receipt.description',
      'payment.date',
      'payment',
      'profile',
      'event.section',
      'course.section',
      'payment.providerResponse',
      'coupon',
    ],
    pagination: {
      page: debounceSearchParams?.get('page'),
      pageSize: debounceSearchParams?.get('pageSize'),
    },
  };

  const receiptsQuery = useQuery({
    queryKey: ['receipts', ...debounceSearchParams],
    queryFn: () =>
      find({
        token,
        query: queryFiltersReceipts,
      }),
    onError: () => toast.error('Errore durante il recupero delle ricevute'),
  });

  const { mutate: usersListCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadUsersCSVList', receipts.downloadCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(receiptsQuery.data?.meta?.pagination?.total) >= 10000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 10000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  const {
    mutate: paypalpaymentsReconciliation,
    isLoading: isGettingPayPalCSV,
  } = useMutation(
    'downloadUsersCSVList',
    receipts.downloadPayPalReconciliation,
    {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        toast.success('Documento CSV scaricato con successo');
      },
    }
  );

  return (
    <>
      <TitlePage title='Tutte le ricevute (entrate)' />
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Tutte le ricevute (entrate)</H1Styled>
        <div className='space-y-5'>
          <FiltersBar
            filters={[
              {
                type: FilterType.DATE,
                label: 'Filtra per Data',
                attribute: 'date',
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'sections',
                label: 'Sezione',
                key: 'sectionsListFilters',
                searchForAttributes: ['name'],
                source: (data: any) =>
                  sectionsAPIs.findOptions({
                    ...data,
                    query: { ...data.query },
                  }),
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'paymentMethod',
                label: 'Metodo di pagamento',
                key: 'paymentMethodReceipts',
                source: () => ({
                  data: PAYMENT_METHODS,
                }),
              },
              {
                attribute: 'search',
                type: FilterType.SEARCH_BAR,
                label: 'Cerca...',
              },
            ]}
          />
          <div className='flex justify-end w-full'>
            <PrimaryButton
              textSmall
              onClick={() => {
                paypalpaymentsReconciliation({
                  token,
                  body: {},
                  query: queryFiltersPaypalReconciliation,
                  fileName: `Lista ricevute ONAV ${moment().format(
                    'HH[:]MM[:]ss'
                  )}`,
                });
              }}
              disabled={isGettingPayPalCSV}
            >
              {!isDelegato(profile) && (
                <span className='text-sm underline flex flex-row'>
                  <DownloadIcon className='w-4 h-4 mt-[3px]' />{' '}
                  <p>Genera riconciliazione PayPal</p>
                </span>
              )}
            </PrimaryButton>
            <PrimaryButton
              textSmall
              disabled={isDownloadingCSV}
              isLoading={isDownloadingCSV}
              onClick={() => {
                usersListCSVMutation({
                  token,
                  body: {},
                  query: queryFiltersReceipts,
                  fileName: `Lista ricevute ONAV ${moment().format(
                    'HH[:]MM[:]ss'
                  )}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
              </span>
            </PrimaryButton>
          </div>
          <TesoreriaTable receiptsQuery={receiptsQuery} />
          <Pagination
            pagination={receiptsQuery.data?.meta?.pagination}
            defaultPageSize={5}
          />
        </div>
      </div>
    </>
  );
};

export default ListaRicevute;

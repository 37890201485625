import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';

import { useMutation, useQueryClient } from 'react-query';
import { createAudience, listAudiences } from '../../api/audiences';
import { useAuth } from '../../contexts/Auth';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import InputPresentational from '../Form/InputPresentational';
import Spinner from '../Layout/Loading/Spinner';

interface CreateNewAudienceModalProps {
  buttonTxt?: string;
  buttonType: 'text';
  activeFilters: any;
}

const CreateNewAudienceModal: React.FC<CreateNewAudienceModalProps> = ({
  buttonTxt = 'Salva Audience',
  buttonType,
  activeFilters,
}) => {
  const delay = Number(process.env.REACT_APP_DEBOUNCE_DELAY);
  const [{ token, profile }] = useAuth();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const [audienceTitle, setAudienceTitle] = useState<string | undefined>('');
  const [open, setOpen] = useState(false);
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const { mutate: createAudienceMutation, isLoading } = useMutation({
    mutationFn: () => {
      return createAudience({
        data: {
          data: {
            title: audienceTitle,
            audienceQuery: activeFilters,
          },
        },
        token,
      });
    },
    onSuccess: () => {
      setOpen(false);
      toast.success('Audience salvata con successo');
      queryClient.refetchQueries(['audiences'], { exact: false });
      setAudienceTitle('');
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.error.message || "Qualcosa e' andato storto!"
      );
    },
  });

  const newAudience = () => {
    if (!audienceTitle || audienceTitle === '') {
      return toast.error('Inserire titolo audience');
    }
    createAudienceMutation();
  };
  return (
    <>
      {buttonType === 'text' && (
        <PrimaryButton textSmall onClick={() => setOpen(true)}>
          <span className='text-sm underline'>{buttonTxt}</span>
        </PrimaryButton>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Salva Audience
                </h4>

                <div className='px-4 py-6 '>
                  <p className='text-gray-700'>
                    Salva la combinazione dei filtri come nuova Audience
                  </p>
                </div>
                <div className='px-4 py-6 '>
                  <InputPresentational
                    id='title'
                    label='Titolo'
                    name='title'
                    type='text'
                    value={audienceTitle}
                    onChange={(event) => {
                      setAudienceTitle(event.target.value);
                    }}
                  />
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton textSmall onClick={() => setOpen(false)}>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    small
                    onClick={() => newAudience()}
                  >
                    Salva
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CreateNewAudienceModal;

import TitlePage from '../../../components/TitlePage';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';
import AddCoursePartecipantsModal from '../../../components/Modals/AddCoursePartecipantsModal';
import ReportModal from '../../../components/Modals/ReportModal';
import SelectPartecipants from '../Tables/SelectPartecipants';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { CourseStatus, StrapiResponse } from '../../../interfaces/commons';
import { useAuth } from '../../../contexts/Auth';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
  Participant,
  PARTICIPANTS_STATUS_OPTIONS,
  ParticipantsResponse,
  ParticipantStatus,
} from '../../../interfaces/participants';
import { toast } from 'react-toastify';
import { ArrowPathIcon } from '../../../components/CustomIcons';
import AssignCategoryModal from '../../../components/Modals/AssignCategoryModal';
import { bulkUpdateProfiles } from '../../../api/profile';
import payments from '../../../api/payments';
import FiltersBar from '../../../components/Filters/FiltersBar';
import { FilterType } from '../../../interfaces/filters';
import qs from 'qs';
import { useDebounce } from 'use-debounce';
import { CourseResponse } from '../../../interfaces/courses';
import { ProductType } from '../../../interfaces/orders';
import courseParticipants from '../../../api/courseParticipants';
import CreateDiplomaModal, {
  DiplomaForm,
} from '../../../components/Modals/CreateDiplomaModal';
import sectionsAPIs from '../../../api/section';
import receipts from '../../../api/receipts';
import Pagination from '../../../components/Pagination';
import BaseModal from '../../../components/Modals/BaseModal';
import { DownloadIcon } from '@heroicons/react/outline';
import moment from 'moment';
import {
  checkProfile,
  getParticipantsAudienceQuery,
  getParticipantsQuery,
} from '../../../libs/utils/helpers';
import { isDelegato } from '../../../libs/utils/auth';
import CreateNewParticipantsAudienceModal from '../../../components/Modals/CreateNewParticipantsAudienceModal';
import { createCourseParticipantsAudience } from '../../../api/audiences';
import { ParticipantsAudienceForm } from '../../../interfaces/audiences';
import _ from 'lodash';
import {
  PAYMENT_STATUSES_OPTIONS_SOURCE,
  PaymentStatus,
} from '../../../interfaces/payments';

interface Props {
  courseDetailQuery: UseQueryResult<
    StrapiResponse<CourseResponse> | undefined,
    unknown
  >;
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const PartecipantiCorso: React.FC<Props> = ({ courseDetailQuery }) => {
  const [{ token, profile }] = useAuth();
  const params = useParams();
  const [isAssigningVotes, setIsAssigningVotes] = useState<boolean>(false);
  const [isAssigningNotes, setIsAssigningNotes] = useState<boolean>(false);
  const [isCancelingParticipations, setIsCancelingParticipations] =
    useState<boolean>(false);

  const [isRenewingParticipations, setIsRenewingParticipations] =
    useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedParticipants, setSelectedPartecipants] = useState<
    Partial<ParticipantsResponse & { multiple?: boolean }>[]
  >([]);
  const isUserDelegate = isDelegato(profile);
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const toggleWarningModal = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const queryClient = useQueryClient();

  const refreshProfiles = async () => {
    await queryClient.refetchQueries(['getProfileListForParticipations']);
    queryClient.invalidateQueries([
      'getCourseReport',
      courseDetailQuery.data?.data.id,
    ]);
  };

  const QUERY = useMemo(
    () =>
      getParticipantsQuery({
        queryParams,
        id: params.id,
        entityName: 'course',
      }),
    [debounceSearchParams]
  );

  const participantsQuery = useQuery(
    ['getParticipants', QUERY],
    () => {
      return courseParticipants.find({
        token,
        ...QUERY,
      });
    },
    {
      onSuccess: () => {
        refreshProfiles();
        setSelectedPartecipants([]);
      },
    }
  );

  const profileAudienceTotals = useMemo(() => {
    const totalParticipants = participantsQuery.data?.meta.pagination?.total;
    const selectedParticipantsWithProfile = selectedParticipants.filter(
      (_participant) => _participant.attributes?.profile?.data?.id
    ).length;

    return {
      selectedParticipantsNumber: selectedParticipantsWithProfile,
      totalParticipantsNumber: totalParticipants,
    };
  }, [participantsQuery.data, selectedParticipants]);

  const confirmedParticipants = useMemo(
    () =>
      participantsQuery.data?.data.filter(
        (elem) => elem.attributes.status === ParticipantStatus.CONFIRMED
      ),
    [participantsQuery.data?.data]
  );

  const maxParticipants = useMemo(
    () => courseDetailQuery.data?.data.attributes.maxParticipants,
    [courseDetailQuery.data?.data.attributes.maxParticipants]
  );

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setSelectedPartecipants(
      e.target.checked
        ? participantsQuery.data?.data.map((elem) => ({
            ...elem,
            multiple: true,
          })) || []
        : []
    );
  };

  const { mutate: mutateBulkUpdate, isLoading: isUpdatingParticipants } =
    useMutation('bulkUpdate', courseParticipants.bulkUpdate, {
      onSuccess: () => {
        queryClient.invalidateQueries(['getParticipants']);
        queryClient.invalidateQueries(['course', params.id]);
        setSelectedPartecipants([]);
        setChecked(false);
        toast.success('Operazione conclusa con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    });

  const {
    mutate: mutateBulkUpdateParticipants,
    isLoading: isUpdatingParticipantsInBulk,
  } = useMutation(
    'bulkUpdateParticipants',
    courseParticipants.bulkUpdateParticipants,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getParticipants']);
        queryClient.invalidateQueries(['course', params.id]);
        setSelectedPartecipants([]);
        setChecked(false);
        toast.success('Operazione conclusa con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    }
  );

  const { mutate: mutateBulkProfiles, isLoading: isUpdatingProfiles } =
    useMutation('bulkProfilesUpdate', bulkUpdateProfiles, {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['getParticipants']);
        queryClient.invalidateQueries(['course', params.id]);
        setSelectedPartecipants([]);
        setChecked(false);
        toast.success(
          `Operazione conclusa con successo! Categoria cambiata a ${data.data.length} profili`
        );
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    });

  const {
    mutate: mutateCreationParticipant,
    isLoading: isAddingNewParticipant,
  } = useMutation('addParticipator', courseParticipants.create, {
    onSuccess: (data) => {
      participantsQuery.refetch();
      queryClient.invalidateQueries(['getParticipants']);
      queryClient.invalidateQueries(['course', params.id]);
      toast.success(
        `Operazione conclusa con successo! L'utente ${data.data.attributes.profile?.data.attributes.name} ${data.data.attributes.profile?.data.attributes.surname} è stato aggiunto al corso corrente`
      );
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.error.message || 'Ooops qualcosa è andato storto'
      );
    },
  });

  const { mutate: mutatePaymentUpdate, isLoading: isUpdatingPayment } =
    useMutation('updatePayment', payments.update, {
      onSuccess: () => {
        participantsQuery.refetch();
        queryClient.invalidateQueries(['getParticipants']);
        queryClient.invalidateQueries(['course', params.id]);
        toast.success(`Operazione conclusa con successo!`);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    });

  const onUpdateProfiles = (category?: number) => {
    mutateBulkProfiles({
      token,
      body: {
        profileIds: selectedParticipants.map(
          (elem) => elem.attributes?.profile?.data?.id || 0
        ),
        data: { profileCategory: category },
      },
    });
  };

  const {
    mutate: bulkPrintDiplomaMutation,
    isLoading: isPrintingDiplomasInBulk,
  } = useMutation('bulkPrintDiplomas', courseParticipants.bulkPrintDiplomas, {
    onError: async (error: any) => {
      const messageJSON = await error.response.data.text();
      const message = JSON.parse(messageJSON).error?.message;

      toast.error(message || 'Ooops qualcosa è andato storto');
    },
  });

  const onPrintDiplomas = async (
    profiles: number[],
    values?: DiplomaForm,
    courseName?: string
  ) => {
    bulkPrintDiplomaMutation({
      token,
      body: { participantsIds: profiles, customData: values },
      fileName: `Diplomi corso ${courseName} ${moment().format('MM-DD-YYY')}`,
      query: QUERY,
    });
  };

  const onUpdateParticipant = ({
    vote,
    cumLaude,
    status,
    note,
  }: {
    vote?: string;
    cumLaude?: boolean;
    note?: string;
    status?: ParticipantStatus;
  }) => {
    mutateBulkUpdate({
      token,
      body: {
        participantsIds: selectedParticipants.map((elem) => elem?.id || 0),
        data: { evaluation: vote, cumLaude, status, note },
      },
    });
  };

  const bulkUpdateParticipants = (
    participants: { id: number; data: Participant }[]
  ) => {
    mutateBulkUpdateParticipants({
      token,
      body: { participants },
    });
  };

  /**
   *
   * Creazione audience
   */

  const {
    mutate: createAudienceMutation,
    isLoading: isCreatingParticipantsAudience,
  } = useMutation({
    mutationFn: createCourseParticipantsAudience,
    onSuccess: () => {
      toast.success('Audience salvata con successo');
      queryClient.refetchQueries(['audiences'], { exact: false });
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.error.message || "Qualcosa e' andato storto!"
      );
    },
  });

  const onCreateCourseParticipantsAudience = (
    values: ParticipantsAudienceForm
  ) => {
    createAudienceMutation({
      token,
      data: getParticipantsAudienceQuery({
        formValues: values,
        query: QUERY,
        selectedProfilesIds: selectedParticipants.map(
          (_participant) => _participant.attributes?.profile?.data?.id
        ),
      }),
      id: String(params.id),
    });
  };

  const toggleVotesModal = () => {
    if (
      selectedParticipants.length === 1 &&
      !selectedParticipants[0].multiple
    ) {
      setSelectedPartecipants([]);
      setChecked(false);
      return setIsAssigningVotes((v) => !v);
    }

    if (!selectedParticipants.every((elem) => !elem.attributes?.evaluation)) {
      setChecked(false);
      return setSelectedPartecipants((oldValues) =>
        oldValues.filter((elem) => !elem.attributes?.evaluation)
      );
    }
    setIsAssigningVotes((v) => !v);
  };
  const toggleNotesModal = () => {
    setIsAssigningNotes((v) => !v);
  };

  const toggleCancelingParticipations = () => {
    if (
      selectedParticipants.length === 1 &&
      !selectedParticipants[0].multiple
    ) {
      setSelectedPartecipants([]);
      setChecked(false);
      return setIsCancelingParticipations((v) => !v);
    }

    if (
      !selectedParticipants.every(
        (elem) => elem?.attributes?.status !== ParticipantStatus.CANCELED
      )
    ) {
      setChecked(false);
      return setSelectedPartecipants((oldValues) =>
        oldValues.filter(
          (elem) => elem?.attributes?.status !== ParticipantStatus.CANCELED
        )
      );
    }
    setIsCancelingParticipations((v) => !v);
  };

  const toggleRenewingParticipations = () => {
    const totalParticipants =
      (confirmedParticipants?.length || 0) + selectedParticipants?.length;

    if (totalParticipants > (maxParticipants || 0)) {
      return setIsWarningModalOpen(true);
    }
    if (
      selectedParticipants.length === 1 &&
      !selectedParticipants[0].multiple
    ) {
      setSelectedPartecipants([]);
      setChecked(false);
      return setIsRenewingParticipations((v) => !v);
    }

    if (
      selectedParticipants.some(
        (elem) =>
          elem?.attributes?.status === ParticipantStatus.PENDING ||
          elem?.attributes?.status === ParticipantStatus.CONFIRMED
      )
    ) {
      setChecked(false);
      return setSelectedPartecipants((oldValues) =>
        oldValues.filter(
          (elem) =>
            elem?.attributes?.status !== ParticipantStatus.PENDING &&
            elem?.attributes?.status !== ParticipantStatus.CONFIRMED
        )
      );
    }
    setIsRenewingParticipations((v) => !v);
  };

  const isCourseCanceled = useMemo(
    () =>
      courseDetailQuery.data?.data.attributes.status === CourseStatus.CANCELED,
    [courseDetailQuery.data?.data.attributes.status]
  );

  const { mutate: mutateDownloadReceipt } = useMutation(
    'downloadUserReceipt',
    receipts.downloadReceipt,
    {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto');
      },
      onSuccess: () => {
        toast.success('Ricevuta scaricata con successo');
      },
    }
  );

  const { mutate: participantsCSVMutation, isLoading: isDownloadingCSV } =
    useMutation(
      'downloadCourseParticipantsCSVList',
      courseParticipants.downloadCSV,
      {
        onError: () => {
          toast.error('Ooops... Qualcosa è andato storto.');
        },
        onSuccess: () => {
          if (Number(participantsQuery.data?.meta?.pagination?.total) >= 10000)
            toast.warning(
              "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 10000 elementi."
            );
          else toast.success('Documento CSV scaricato con successo');
        },
      }
    );

  const canDelegatoEdit = useMemo(
    () => courseDetailQuery.data?.data.attributes.canDelegateEdit,
    [courseDetailQuery]
  );

  return (
    <>
      <TitlePage title='Dettaglio corso | Partecipanti' />

      <div>
        <div className='pt-6 pb-8 space-y-6'>
          <FiltersBar
            filters={[
              {
                type: FilterType.MULTISELECT,
                attribute: 'status',
                label: 'Stato pagamento',
                key: 'lessonStatusesList',
                source: () => ({
                  data: PAYMENT_STATUSES_OPTIONS_SOURCE,
                }),
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'subscriptionStatus',
                label: 'Stato iscrizione',
                key: 'subscriptionStatus',
                source: () => ({
                  data: PARTICIPANTS_STATUS_OPTIONS,
                }),
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'sections',
                label: 'Sezione',
                key: 'sectionsListFilters',
                searchForAttributes: ['name'],
                source: (data: any) =>
                  sectionsAPIs.findOptions({
                    ...data,
                    enableAuthentication: false,
                    query: { ...data.query },
                  }),
              },
              {
                type: FilterType.SEARCH_BAR,
                attribute: 'search',
                label: 'Cerca...',
              },
            ]}
          />

          <div className='flex flex-col md:flex-row justify-between'>
            <div className='flex flex-wrap justify-center md:justify-start gap-4'>
              {
                <CreateDiplomaModal
                  onConfirm={(values) =>
                    onPrintDiplomas(
                      selectedParticipants?.map((elem) => Number(elem.id)),
                      values,
                      courseDetailQuery.data?.data.attributes.title
                    )
                  }
                  isLoading={isPrintingDiplomasInBulk}
                  disabled={
                    selectedParticipants.length === 0 ||
                    isCourseCanceled ||
                    isDelegato(profile)
                  }
                  participants={selectedParticipants.map(
                    (_participant) =>
                      `${_participant.attributes?.profile?.data.attributes.name} ${_participant.attributes?.profile?.data.attributes.surname}`
                  )}
                />
              }
              <UnderlineButton
                disabled={
                  participantsQuery?.data?.data?.filter(
                    (elem) => !elem.attributes.evaluation
                  ).length === 0 ||
                  selectedParticipants.every(
                    (elem) => elem.attributes?.evaluation || !elem.multiple
                  ) ||
                  isCourseCanceled ||
                  isDelegato(profile)
                }
                onClick={toggleVotesModal}
              >
                Assegna esame{' '}
                {!selectedParticipants.every(
                  (elem) => elem.attributes?.evaluation
                ) &&
                  selectedParticipants.some(
                    (elem) => elem.attributes?.evaluation
                  ) && <ArrowPathIcon className='h-5 w-5 mx-2' />}
              </UnderlineButton>
              <UnderlineButton
                disabled={
                  participantsQuery?.data?.data?.filter(
                    (elem) =>
                      elem.attributes.status !== ParticipantStatus.CANCELED
                  ).length === 0 ||
                  selectedParticipants.every(
                    (elem) =>
                      elem?.attributes?.status === ParticipantStatus.CANCELED ||
                      !elem.multiple
                  ) ||
                  isCourseCanceled
                }
                onClick={toggleCancelingParticipations}
              >
                Annulla iscrizione{' '}
                {!selectedParticipants.every(
                  (elem) =>
                    elem?.attributes?.status === ParticipantStatus.CANCELED
                ) &&
                  selectedParticipants.some(
                    (elem) =>
                      elem?.attributes?.status === ParticipantStatus.CANCELED
                  ) && <ArrowPathIcon className='h-5 w-5 mx-2' />}
              </UnderlineButton>
              <UnderlineButton
                disabled={
                  participantsQuery?.data?.data?.filter(
                    (elem) =>
                      elem.attributes.status !== ParticipantStatus.CONFIRMED &&
                      elem.attributes.status !== ParticipantStatus.PENDING
                  ).length === 0 ||
                  selectedParticipants.every(
                    (elem) =>
                      elem?.attributes?.status ===
                        ParticipantStatus.CONFIRMED ||
                      elem?.attributes?.status === ParticipantStatus.PENDING ||
                      !elem.multiple
                  ) ||
                  isCourseCanceled ||
                  checkProfile(profile, canDelegatoEdit)
                }
                onClick={toggleRenewingParticipations}
              >
                Riattiva iscrizione{' '}
                {!selectedParticipants.every(
                  (elem) =>
                    elem?.attributes?.status !== ParticipantStatus.CANCELED
                ) &&
                  selectedParticipants.some(
                    (elem) =>
                      elem?.attributes?.status !== ParticipantStatus.CANCELED
                  ) && <ArrowPathIcon className='h-5 w-5 mx-2' />}
              </UnderlineButton>
              {!isUserDelegate && (
                <AssignCategoryModal
                  onConfirm={(categoryId) => onUpdateProfiles(categoryId)}
                  disabled={
                    selectedParticipants.length === 0 || isCourseCanceled
                  }
                  isLoading={isUpdatingProfiles}
                />
              )}
              <BaseModal
                isOpen={isWarningModalOpen}
                toggle={toggleWarningModal}
                title='Attenzione'
                confirmButtonText='Ok'
                onConfirm={() => setIsWarningModalOpen(false)}
                subtitle={`È stato raggiunto il numero massimo di partecipanti, impossibile riattivare lo stato d'iscrizione ${
                  selectedParticipants.length > 1
                    ? 'di questi utenti.'
                    : 'di questo utente.'
                }`}
              />
              <CreateNewParticipantsAudienceModal
                isLoading={isCreatingParticipantsAudience}
                onSubmit={onCreateCourseParticipantsAudience}
                selectedParticipantsNumber={
                  profileAudienceTotals.selectedParticipantsNumber
                }
                totalParticipantsNumber={
                  profileAudienceTotals.totalParticipantsNumber
                }
              />
            </div>
            <div className='flex flex-wrap justify-center md:justify-start gap-4'>
              <AddCoursePartecipantsModal
                participantsQuery={participantsQuery}
                entityDate={
                  courseDetailQuery?.data?.data?.attributes?.startDate ||
                  new Date().toISOString()
                }
                isLoading={isAddingNewParticipant}
                onConfirm={(_participant) =>
                  mutateCreationParticipant({
                    token,
                    body: {
                      profile: _participant?.profile?.value as number,
                      course: courseDetailQuery.data?.data?.id as number,
                      productType: ProductType.COURSE,
                    },
                    query: {
                      populate: '*',
                    },
                  })
                }
                disabled={isCourseCanceled}
              />
              <ReportModal
                id={courseDetailQuery.data?.data.id}
                disabled={isCourseCanceled}
              />
              <UnderlineButton
                disabled={isDownloadingCSV}
                onClick={() => {
                  participantsCSVMutation({
                    token,
                    body: {},
                    query: QUERY,
                    fileName: `Partecipanti corso ${
                      courseDetailQuery.data?.data.id
                    } ${moment().format('HH[:]mm[:]ss')}`,
                  });
                }}
              >
                <DownloadIcon className='w-4 h-4' /> Scarica CSV
              </UnderlineButton>
            </div>
          </div>

          <SelectPartecipants
            courseDetailQuery={courseDetailQuery}
            isLoading={
              isUpdatingPayment ||
              isUpdatingParticipants ||
              isUpdatingParticipantsInBulk
            }
            onDownloadReceipt={(receipts) =>
              mutateDownloadReceipt({
                token,
                body: { receipts },
                fileName: `Ricevute corso ${courseDetailQuery.data?.data.attributes.title}`,
              })
            }
            participantsQuery={participantsQuery}
            selectedParticipants={selectedParticipants}
            checked={checked}
            onCheckAll={onCheckAll}
            setSelectedParticipants={setSelectedPartecipants}
            onUpdateParticipant={onUpdateParticipant}
            isAssigningVotes={isAssigningVotes}
            isAssigningNotes={isAssigningNotes}
            toggleNotesModal={toggleNotesModal}
            toggleVotesModal={toggleVotesModal}
            isCancelingParticipations={isCancelingParticipations}
            isRenewingParticipations={isRenewingParticipations}
            toggleCancelingParticipations={toggleCancelingParticipations}
            toggleRenewingParticipations={toggleRenewingParticipations}
            onUpdatePayment={(data) =>
              mutatePaymentUpdate({ token, id: data?.id, body: data.payment })
            }
            bulkUpdateParticipants={bulkUpdateParticipants}
            disabled={checkProfile(profile, canDelegatoEdit)}
            searchWords={[
              debounceSearchParams.get('search') + '',
              ...Object.entries(qs.parse(queryParams.get('status') + ''))
                .map(([, value]) => value)
                .map((elem: any) => elem.label),
              ...Object.entries(qs.parse(queryParams.get('region') + ''))
                .map(([, value]) => value)
                .map((elem: any) => elem.label),
            ]}
          />
          <Pagination pagination={participantsQuery.data?.meta?.pagination} />
        </div>

        {/* <PrimaryButton onClick={() => {}}>Salva</PrimaryButton> */}
      </div>
    </>
  );
};

export default PartecipantiCorso;

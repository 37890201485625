import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from './Buttons/PrimaryButton';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import Badge from './Badge';
import { Section } from '../interfaces/section';
import { useQuery } from 'react-query';
import { regions } from '../api/regions';
import { match } from 'ts-pattern';
import FormLoading from './Layout/Loading/FormLoading';
import Filters from './Filters/Filters';
import { useSearchParams } from 'react-router-dom';
import sectionsAPIs from '../api/section';
import { useAuth } from '../contexts/Auth';

interface SectionsModalProps {
  label?: string;
  setValueForm: any;
  defaultValues?: Section[];
}

const SectionsModal: React.FC<SectionsModalProps> = ({
  label,
  setValueForm,
  defaultValues,
}) => {
  const [{ token }] = useAuth();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeFilters, setActiveFilters] = useState<any[]>(
    searchParams.getAll('regions')
  );
  const [selectedSection, setSelectedSection] = useState<Section[]>(
    defaultValues || []
  );

  const regionsQuery = useQuery('regions', regions);
  const sectionsQuery = useQuery('sections', () =>
    sectionsAPIs.findOptions({
      token,
    })
  );

  useEffect(() => {
    setActiveFilters(searchParams.getAll('regions'));
  }, [searchParams]);

  const updateSelection = (value: Section) => {
    const addSelection = [...selectedSection, value];
    const removeSelection = selectedSection.filter(
      (region) => region.id !== value.id
    );

    selectedSection.some((r) => r.id === value.id)
      ? setSelectedSection(removeSelection)
      : setSelectedSection(addSelection);
  };

  useEffect(() => {
    const sectionsArray = selectedSection.map((obj) => {
      return obj.id;
    });
    setValueForm('secondarySections', sectionsArray);
  }, [selectedSection]);

  return (
    <>
      <div>
        <label className='block text-sm text-gray-500'>{label}</label>
        <button
          onClick={() => setOpen(true)}
          type={'button'}
          className='text-base w-max whitespace-nowrap text-primary underline underline-offset-4 mt-1'
        >
          Seleziona più sezioni
        </button>
        <div className='flex items-center flex-wrap gap-3 mt-4'>
          {selectedSection.map((section, i) => (
            <Badge grayBorder text={section.attributes.name} key={i} />
          ))}
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto max-h-screen'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen '
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6 max-h-[90vh] min-h-[80vh]'>
                <div className='mb-10'>
                  <h4 className='text-2.5xl font-IBM font-light text-gray-700 '>
                    Seleziona le Sezioni di interesse
                  </h4>
                  <Filters region />
                </div>
                <div className='space-y-8'>
                  {match(regionsQuery)
                    .with({ status: 'idle' }, { status: 'loading' }, () => (
                      <FormLoading numberItems={1} />
                    ))
                    .with({ status: 'error' }, () => <div>Errore...</div>)
                    .with({ status: 'success' }, (query) => {
                      return query.data.data
                        .filter((reg) => {
                          if (activeFilters.length === 0) return true;
                          return activeFilters.includes(reg.attributes.name);
                        })
                        .map((region, i) => (
                          <div className='space-y-6 pb-10' key={i}>
                            <h4 className='text-xl font-IBM font-light'>
                              Regione{' '}
                              <span className='text-primary font-medium'>
                                {region.attributes.name}
                              </span>
                            </h4>
                            <fieldset className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4  gap-3'>
                              {match(sectionsQuery)
                                .with(
                                  { status: 'idle' },
                                  { status: 'loading' },
                                  () => <FormLoading numberItems={1} />
                                )
                                .with({ status: 'error' }, () => (
                                  <div>Errore...</div>
                                ))
                                .with({ status: 'success' }, (query) => {
                                  return query.data.data
                                    .filter(
                                      (value) =>
                                        value.attributes.region?.data?.id ===
                                        region.id
                                    )
                                    .map((section, i) => (
                                      <SimpleCheckboxPresentational
                                        label={section.attributes.name}
                                        id={'sezione-' + section.id}
                                        key={i}
                                        name='section'
                                        describedby='section'
                                        checked={selectedSection.some(
                                          (sec) => sec.id === section.id
                                        )}
                                        onChange={() =>
                                          updateSelection(section)
                                        }
                                      />
                                    ));
                                })
                                .exhaustive()}
                            </fieldset>
                          </div>
                        ));
                    })
                    .exhaustive()}
                </div>

                <div className='flex justify-end mt-6 sticky right-0 bottom-0'>
                  <PrimaryButton
                    small
                    onClick={() => {
                      searchParams.delete('regions');
                      setSearchParams(searchParams);
                      setOpen(false);
                    }}
                  >
                    Salva selezione
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SectionsModal;

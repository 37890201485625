import { InfoPaymentMethod, PaymentMethod } from '../../interfaces/payments';
import { CostCenter } from '../../interfaces/receipts';

export const METHOD_INFO_OPTIONS = [
  {
    value: InfoPaymentMethod.CASH_SECTION,
    label: 'Contanti sezione',
  },
  {
    value: InfoPaymentMethod.CASH_HEADQUARTER,
    label: 'Contanti sede',
  },
  {
    value: InfoPaymentMethod.BANK_TRANSFER_SECTION,
    label: 'Bonifico sezione',
  },
  {
    value: InfoPaymentMethod.BANK_TRANSFER_HEADQUARTER,
    label: 'Bonifico sede',
  },
  {
    value: InfoPaymentMethod.ATM,
    label: 'Bancomat',
  },
  {
    value: InfoPaymentMethod.CARD,
    label: 'Carta',
  },
];

export const COSTS_CENTER_OPTIONS = [
  { value: CostCenter.NATIONAL, label: 'Sede nazionale' },
  { value: CostCenter.DELEGATION, label: 'Delegazione' },
];

export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Paypal', value: PaymentMethod.PAYPAL },
  { label: 'Contanti', value: PaymentMethod.CASH },
  { label: 'Assegno', value: PaymentMethod.BANK_CHECK },
  { label: 'Bonifico a sezione', value: PaymentMethod.BANK_TRANSFER_SECTION },
  { label: 'Bonifico a sede', value: PaymentMethod.BANK_TRANSFER_SITE },
  { label: 'Bancomat', value: PaymentMethod.BANCOMAT },
  { label: 'Bollettino', value: PaymentMethod.PAYMENT_SLIP },
];

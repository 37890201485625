import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { update, findOne } from '../../../api/receipts';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

import ReceiptForm from '../../../components/ReceiptForm';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { useAuth } from '../../../contexts/Auth';
import {
  ReceiptAttributes,
  ReceiptRequest,
} from '../../../interfaces/receipts';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { receiptsValidator } from '../../../validators/receipts';
import { sanitizeCurrencyValue } from '../../../libs/utils/helpers';
import {
  formatReceiptFromBackend,
  receiptToBackend,
} from '../../../libs/utils/receipt';
import moment from 'moment';
import _ from 'lodash';

const emptyReceipt: ReceiptAttributes = {
  code: null,
  description: '',
  date: moment().format('YYYY-MM-DD'),
  paymentMethod: null,
  fiscalCode: null,
  entries: [],
  badgeNumber: null,
  profile: null,
  type: null,
  section: null,
  notes: null,
  payments: {
    data: [],
  },
};

const TesoreriaCreaRicevuta = () => {
  const [{ token }] = useAuth();
  const navigate = useNavigate();

  const { receiptId } = useParams();

  const receiptMethods = useForm<ReceiptRequest>({
    defaultValues: {
      ...emptyReceipt,
      section: null,
      payments: [],
      entries: [],
      orders: [],
    },
    resolver: useYupValidationResolver(receiptsValidator),
  });

  useQuery({
    queryKey: ['receipt', receiptId],
    queryFn: () =>
      findOne({
        token,
        id: receiptId!,
        query: {
          populate: [
            'orders.event',
            'orders.course',
            'profile',
            'entries',
            'section',
          ],
        },
      }),

    onSuccess: ({ data }) => {
      const formattedReceipt = formatReceiptFromBackend(data);
      receiptMethods.reset({
        ...formattedReceipt,
        profile: {
          value: formattedReceipt?.profile?.data.id,
          label: `${formattedReceipt?.profile?.data?.attributes?.surname}`,
        },
        date: moment(data.attributes.date).format('YYYY-MM-DD'),
        section: formattedReceipt.section?.data?.id,
        isCourse: !!formattedReceipt?.orders?.data[0]?.attributes?.course?.data,
        isEvent: !!formattedReceipt?.orders?.data[0]?.attributes?.event?.data,
        orders: undefined,
        payments: undefined,
      });
    },
    onError: () => {
      toast.error('Errore durante il recupero della ricevuta');
    },
  });

  const updateReceipt = useMutation({
    mutationKey: ['update', receiptId],
    mutationFn: (body: ReceiptRequest) => {
      const editedRecipt = receiptToBackend(body);
      return update({
        token,
        body: {
          ...editedRecipt,
          entries: editedRecipt.entries?.map((_entry) => ({
            ..._entry,
            amount: sanitizeCurrencyValue(_entry.amount),
          })),
        },
        receiptId: Number(receiptId),
      });
    },
    onSuccess: ({ data }) => {
      toast.success('Ricevuta modificata correttamente');
      navigate(-1);
    },
    onError: () => {
      toast.error('Errore durante la creazione della ricevuta');
    },
  });

  const onSubmit = (body: ReceiptRequest) => {
    updateReceipt.mutate({
      ..._.omit(body, ['date']),
      profile: body.profile?.value as any,
    });
  };

  return (
    <FormProvider {...receiptMethods}>
      <TitlePage title='Modifica ricevuta' />
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <form
          className='mt-12'
          onSubmit={receiptMethods.handleSubmit(onSubmit)}
        >
          <div className='flex flex-row items-center justify-between'>
            <H1Styled noMargin>Modifica ricevuta</H1Styled>
            <div className='flex gap-3'>
              <PrimaryButton
                outline
                onClick={() => {
                  navigate(-1);
                }}
              >
                Annulla
              </PrimaryButton>
              <PrimaryButton
                disabled={updateReceipt.isLoading}
                isLoading={updateReceipt.isLoading}
                type='submit'
              >
                Modifica ricevuta
              </PrimaryButton>
            </div>
          </div>

          <ReceiptForm receiptForm={receiptMethods} treasury />
        </form>
      </div>
    </FormProvider>
  );
};

export default TesoreriaCreaRicevuta;

import axios from 'axios';
import qs from 'qs';
import { StrapiResponse, StrapiResponseList } from '../interfaces/commons';

import {
  CourseTemplateResponse,
  CourseTemplateRequest,
} from '../interfaces/course-templates';

const { REACT_APP_API_URL } = process.env;

export const getCourseTemplates = async (
  searchParams: any,
  token: string | null
) => {
  const query = {
    populate: '*',
    sort: 'createdAt:desc',

    pagination: {
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize') || 10,
    },
    filters: {
      title: { $contains: searchParams.get('search') },
      $and: [
        {
          createdAt: {
            $lte: searchParams.get('dateTo'),
            $gte: searchParams.get('dateFrom'),
          },
        },
        {
          visibleForOnavIT: {
            $eq: searchParams.get('onavIt'),
          },
        },
      ],
    },
  };

  const { data } = await axios.get<StrapiResponseList<CourseTemplateResponse>>(
    `${REACT_APP_API_URL}/course-templates?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const getCourseTemplate = async (
  courseTemplateId: string | number,
  token: string | null
) => {
  const query = {
    populate: [
      '*',
      'details.otherInfos',
      'lessonTemplates',
      'minProfileCategorySub',
      'headquarterBalance.administrations',
      'safetyMargin',
    ],
  };

  const { data } = await axios.get<StrapiResponse<CourseTemplateResponse>>(
    `${REACT_APP_API_URL}/course-templates/${courseTemplateId}?${qs.stringify(
      query
    )}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const postCourseTemplate = async ({
  newCourseTemplate,
  image,
  carousel,
  token,
}: {
  newCourseTemplate: CourseTemplateRequest;
  image: File[];
  carousel: File[];
  token: string | null;
}) => {
  const formData = new FormData();

  formData.append('data', JSON.stringify(newCourseTemplate));

  if (image[0]) formData.append('files.image', image[0], image[0].name);

  carousel.forEach((image) =>
    formData.append('files.carousel', image, image.name)
  );

  const { data } = await axios.post<StrapiResponse<CourseTemplateResponse>>(
    `${REACT_APP_API_URL}/course-templates`,
    formData,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const putCourseTemplate = async (
  courseTemplateId: string | number,
  updatedCourseTemplate: CourseTemplateRequest,
  token: string | null
) => {
  delete updatedCourseTemplate.image;
  delete updatedCourseTemplate.carousel;

  const { data } = await axios.put<StrapiResponse<CourseTemplateResponse>>(
    `${REACT_APP_API_URL}/course-templates/${courseTemplateId}`,
    { data: updatedCourseTemplate },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const deleteCourseTemplate = async (
  courseTemplateId: string | number,
  token: string | null
) => {
  const { data } = await axios.delete<StrapiResponse<CourseTemplateResponse>>(
    `${REACT_APP_API_URL}/course-templates/${courseTemplateId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

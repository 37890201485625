import React, { useState } from 'react';
import DeleteLessonTemplateModal from '../../../components/Modals/DeleteLessonTemplateModal';
import IconLink from '../../../components/TextLink/IconLink';
import { PencilIcon } from '@heroicons/react/outline';
import { UseQueryResult } from 'react-query';
import { LessonTemplatesListResponse } from '../../../interfaces/lesson-templates';

import { match } from 'ts-pattern';
import { formatDuration } from '../../../libs/utils/formatters/lessonFormatters';
import { useNavigate } from 'react-router-dom';

interface LessonsListTableProps {
  query: UseQueryResult<LessonTemplatesListResponse>;
  deleteLessonTemplate: (id: string | number) => void;
}
const LessonsListTable: React.FC<LessonsListTableProps> = ({
  query,
  deleteLessonTemplate,
}) => {
  const tableElements = ['Lezione', 'Categoria', 'Durata', 'Prezzo', ' ', ' '];
  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'success' }, (query) => {
              if (!query.data)
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      <tr className='cursor-pointer hover:bg-neutral-100'>
                        <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                          Nessuna lezione trovata
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              const { data: lessons } = query.data;
              return (
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                    <tr>
                      {tableElements.map((element) => (
                        <th className='font-normal px-6 py-3 text-left tracking-wide'>
                          {element}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {lessons.map(({ id, attributes: lesson }) => (
                      <tr
                        key={id}
                        className='cursor-pointer hover:bg-neutral-100'
                        onClick={() => {
                          navigate(`${id}/modifica-lezione`);
                        }}
                      >
                        <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                          {lesson.title}
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                          {lesson.category}
                        </td>

                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                          {formatDuration(lesson.minutes)}
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                          € {lesson.estimatePayment}
                        </td>

                        <td className='whitespace-nowrap px-6 py-4 '>
                          <IconLink orangeTxt href={`${id}/modifica-lezione`}>
                            <PencilIcon className='w-6 h-6' />
                          </IconLink>
                        </td>
                        <td
                          className='whitespace-nowrap px-6 py-4 '
                          onClick={(event) => event.stopPropagation()}
                        >
                          <DeleteLessonTemplateModal
                            onConfirm={() => deleteLessonTemplate(id)}
                            titleLesson={lesson.title}
                            buttonType='icon'
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
            })
            .with({ status: 'error' }, () => <p>Errore...</p>)
            .with({ status: 'idle' }, () => <p>Caricando...</p>)
            .with({ status: 'loading' }, () => <p>Caricando...</p>)
            .exhaustive()}
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default LessonsListTable;

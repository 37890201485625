import { useNavigate } from 'react-router-dom';
import React from 'react';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import IconLink from '../../../components/TextLink/IconLink';
import { PencilIcon } from '@heroicons/react/outline';
import { StrapiResponseList } from '../../../interfaces/commons';
import { CourseResponse } from '../../../interfaces/courses';
import BadgeStatus from '../../../components/BadgeStatus';
import DeleteModal from '../../../components/Modals/DeleteModal';
import { formatCurrency } from '../../../libs/utils/formatters/currency';
import BadgeBoolean from '../../../components/BadgeBoolean';
import moment from 'moment';
import { ParticipantStatus } from '../../../interfaces/participants';

interface CoursesTableProps {
  query: UseQueryResult<StrapiResponseList<CourseResponse>>;
  deleteCourse: (id: string | number) => void;
}
const CoursesTable: React.FC<CoursesTableProps> = ({ query, deleteCourse }) => {
  const tableElements = [
    'Data',
    'Corso',
    'Online',
    'Sezione',
    'Minimo partecipanti',
    'Partecipanti iscritti',
    'Partecipanti in attesa',
    'Quote per associazione',
    'Entrate',
    'Uscite',
    'Margine',
    'Stato',
    '',
  ];

  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, i) => (
                          <th
                            className='font-normal px-6 py-3 text-left tracking-wide'
                            key={i}
                          >
                            {element}
                          </th>
                        ))}
                        <th className='font-normal px-6 py-3 text-left tracking-wide' />
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data.map((course) => {
                        const effectiveParticipantsNumber =
                          course.attributes.partecipations?.data.filter(
                            (participation) =>
                              participation.attributes.status ===
                              ParticipantStatus.CONFIRMED
                          ).length;

                        const waitingParticipantsNumber =
                          course.attributes.partecipations?.data.filter(
                            (participation) =>
                              participation.attributes.status ===
                              ParticipantStatus.PENDING
                          ).length;

                        return (
                          <tr
                            key={course.id}
                            className='cursor-pointer hover:bg-neutral-100'
                            onClick={() => {
                              navigate(
                                `dettaglio-corso/${course.id}/dati-principali`
                              );
                            }}
                          >
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.startDate
                                ? moment(course.attributes.startDate).format(
                                    'DD/MM/YYYY'
                                  )
                                : '-'}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.title}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <BadgeBoolean
                                active={
                                  course.attributes.isOnline ? true : false
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.section?.data
                                ? course.attributes.section?.data.attributes
                                    .name
                                : '-'}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.minParticipants || 0}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {effectiveParticipantsNumber || 0}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {waitingParticipantsNumber || 0}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.subscriptionAmount || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.estimatesEarnings || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.estimatesOutflow || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.estimatesProfit || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              <BadgeStatus status={course.attributes.status} />
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 '>
                              <IconLink
                                orangeTxt
                                href={`dettaglio-corso/${course.id}/dati-principali`}
                              >
                                <PencilIcon className='w-6 h-6' />
                              </IconLink>
                            </td>

                            <td
                              className='whitespace-nowrap px-6 py-4 '
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteModal
                                title='Eliminazione corso:'
                                buttonType='icon'
                                buttonTxt='Elimina corso'
                                buttonDisabled={
                                  course.attributes.status === 'published'
                                }
                                onConfirm={() => deleteCourse(course.id)}
                                description='Sei sicuro di voler eliminare il corso:'
                                item={course.attributes.title}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default CoursesTable;

import { StrapiMeta } from './commons';

export interface OnavPositionListResponse {
  meta: StrapiMeta;
  data: OnavPosition[];
}

export enum OnavPositionRole {
  CONSIGLIERE_AUSILIARE = 'CONSIGLIERE_AUSILIARE',
  CONSIGLIERE = 'CONSIGLIERE',
  CONSULENTE_CONSIGLIO = 'CONSULENTE_CONSIGLIO',
  DELEGATO_PRO_TEMPORE = 'DELEGATO_PRO_TEMPORE',
  DELEGATO = 'DELEGATO',
  DIRETTORE_GENERALE = 'DIRETTORE_GENERALE',
  MEMBRO_CONSIGLIO_SCIENTIFICO = 'MEMBRO_CONSIGLIO_SCIENTIFICO',
  PRESIDENTE_COLLEGIO_PROBIVIRI = 'PRESIDENTE_COLLEGIO_PROBIVIRI',
  PRESIDENTE_COLLEGIO_SINDACALE = 'PRESIDENTE_COLLEGIO_SINDACALE',
  PRESIDENTE_CONSIGLIO_SCIENTIFICO = 'PRESIDENTE_CONSIGLIO_SCIENTIFICO',
  PRESIDENTE = 'PRESIDENTE',
  PROBIVIRO = 'PROBIVIRO',
  RAPPRESENTANTE = 'RAPPRESENTANTE',
  RESP_SOCIAL = 'RESP_SOCIAL',
  SINDACO = 'SINDACO',
  TESORIERE = 'TESORIERE',
  TESORIERE_PRO_TEMPORE = 'TESORIERE_PRO_TEMPORE',
  VICE_DELEGATO = 'VICE_DELEGATO',
  VICE_PRESIDENTE = 'VICE_PRESIDENTE',
}

export interface OnavPosition {
  id: number;
  attributes: {
    name: string;
    order: number;
    createdAt: string;
    updatedAt: string;
    role: OnavPositionRole;
  };
}

import axios from 'axios';
import { ILoginResponse, ILoginRequest, IUser } from '../interfaces/login';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

export const login = async (body: ILoginRequest) => {
  const { data } = await instance.post<ILoginResponse>(
    `${REACT_APP_API_URL}/auth/local`,
    body
  );

  return data;
};

export const me = async (token: string) => {
  const response = await instance.get<IUser>(`${REACT_APP_API_URL}/users/me`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response;
};

const Checkbox: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = (props) => {
  return (
    <input
      type='checkbox'
      className={`left-4 top-1/2 -mt-2 h-4 w-4 rounded text-primary focus:ring-primary sm:left-6 ${
        props.disabled
          ? 'cursor-not-allowed border-gray-200'
          : 'cursor-pointer border-gray-300'
      }`}
      {...props}
    />
  );
};

export default Checkbox;

import React, { useMemo } from 'react';
import { formatCurrency } from '../../../libs/utils/helpers';
import {
  CostCenter,
  ReceiptCausal,
  ReceiptResponse,
} from '../../../interfaces/receipts';

interface Quote {
  [key: string]: string;
}

const quote: Quote = {
  QUOTA_ASSOCIATIVA: 'Quota associativa',
  RINNOVO_QUOTA_ASSOCIATIVA: 'Rinnovo quota associativa',
  QUOTA_ISCRIZIONE: 'Spesa amministrativa',
  QUOTA_CORSO: 'Quota corso',
  QUOTA_EVENTO: 'Quota evento',
  MATERIALE_DIDATTICO: 'Materiale didattico',
  GADGET_ONAV: 'Gadget ONAV',
  CUSTOM: '',
  MATERIALE_ONAV: 'Materiale ONAV',
  INTEGRAZIONE_WINE_CONNOISSEUR: 'Integrazione wine connoisseur',
  PROVENTI_VARI: 'Proventi vari',
  CESSIONE_AVANZO_GIACENZE: 'Cessione avanzo giacenze',
  EROGAZIONE_LIBERALE_A_FAVORE_ATTIVITA_ISTITUZIONALE:
    'Erogazione liberale a favore attività istituzionale',
};

interface Props {
  receipt: ReceiptResponse;
}

const ReceiptRowAmounts: React.FC<Props> = ({ receipt }) => {
  const total = useMemo(
    () =>
      receipt.attributes.entries?.reduce(
        (acc, val) => acc + Number(val.amount),
        0
      ),
    [receipt]
  );

  const entries = useMemo(() => {
    return receipt.attributes.entries;
  }, [receipt]);

  return (
    <>
      <div className='flex justify-between'>
        <p>Importo totale: </p>
        <p className='font-semibold text-gray-800'>{formatCurrency(total)}</p>
      </div>

      <table className='w-full mt-3'>
        <thead>
          <tr className='bg-gray-50 text-gray-500'>
            <th>
              <p className='p-2'>CAUSALE</p>
            </th>
            <th>
              <p className='p-2'>CENTRO</p>
            </th>
            <th>
              <p className='p-2'>PREZZO</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {entries?.map((_entry, idx) => {
            const { causal } = _entry;

            return (
              <tr key={`receipt-entry-${idx}`} className='border-b-2'>
                <td>
                  <p className='p-2'>
                    {causal === ReceiptCausal.CUSTOM
                      ? _entry.customLabel
                      : causal
                      ? quote[causal]
                      : null}
                  </p>
                </td>
                <td>
                  <p className='p-2'>
                    {_entry.costCenter === CostCenter.NATIONAL
                      ? '1'
                      : receipt.attributes.section.data.attributes.costCenter}
                  </p>
                </td>
                <td>
                  <p className='p-2'>{formatCurrency(_entry.amount)}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <p className='mt-2'>CAUSALE:</p>
        {receipt.attributes.entries?.map((_entry, idx) => {
          const { causal } = _entry;

          return (
            <div
              key={`receipt-entry-${idx}`}
              className='flex justify-between gap-3'
            >
              <p>{causal ? quote[causal] : null}</p>
              <p>
                {_entry.costCenter === CostCenter.NATIONAL
                  ? 'Nazionale'
                  : 'Delegazione'}
              </p>
              <p>{formatCurrency(_entry.amount)}</p>
            </div>
          );
        })} */}
    </>
  );
};

export default ReceiptRowAmounts;

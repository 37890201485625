import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';

import { EventRequest } from '../interfaces/events';
import { Wine } from '../interfaces/strapiComponents/wine';
import InputPresentational from './Form/InputPresentational';

import { MinusCircleIcon } from '@heroicons/react/outline';
import StaticInputPresentational from './Form/StaticInputPresentational';
import { formatCurrency } from '../libs/utils/helpers';
import H4SpacingLetter from './Typography/H4SpacingLetter';
import UnderlineButton from './Buttons/UnderlineButton';

const emptyWine: Wine = {
  wine: '',
  estimatedPricePerBottle: 0,
  estimatedBottles: 0,
  finalPricePerBottle: 0,
  finalBottles: 0,
};

const ListaVini = ({ disabled = false }) => {
  const { watch, control } = useFormContext<EventRequest>();
  const wines = watch('wines');

  const wineTypes = wines?.length;

  const estimatedBottles = wines.reduce((estimatedBottles, wine) => {
    return estimatedBottles + wine.estimatedBottles;
  }, 0);

  const estimatedTotal = wines.reduce((estimatedTotal, wine) => {
    const wineTotal = wine.estimatedBottles * wine.estimatedPricePerBottle;
    return estimatedTotal + wineTotal;
  }, 0);

  const finalBottles = wines.reduce((finalBottles, wine) => {
    return finalBottles + wine.finalBottles;
  }, 0);

  const finalTotal = wines.reduce((finalTotal, wine) => {
    const wineTotal = wine.finalBottles * wine.finalPricePerBottle;
    return finalTotal + wineTotal;
  }, 0);

  const useWineArray = useFieldArray({
    control,
    name: 'wines',
  });
  const addWine = () => useWineArray.append(emptyWine);

  return (
    <div>
      <div className='grid grid-cols-11 gap-6 items-center'>
        {useWineArray.fields.length > 0 && <WineHeader />}
        {useWineArray.fields.map((wine, wineIdx) => (
          <WineRow
            key={wine.id}
            wineIdx={wineIdx}
            removeWine={useWineArray.remove}
          />
        ))}
      </div>
      <div className='my-6 w-full flex justify-center'>
        <UnderlineButton onClick={addWine} disabled={disabled}>
          Aggiungi vino
        </UnderlineButton>
      </div>
      <div className='flex w-full justify-between gap-6'>
        <div className='space-y-4 bg-neutral-100 px-3 py-4 w-full'>
          <H4SpacingLetter>Totale vini preventivo</H4SpacingLetter>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            <StaticInputPresentational
              big
              label='Tipologie di vino'
              text={wineTypes}
            />
            <StaticInputPresentational
              big
              label='Totale bottiglie'
              text={estimatedBottles}
            />
            <StaticInputPresentational
              big
              label='Totale spesa'
              text={formatCurrency(estimatedTotal)}
            />
          </div>
        </div>

        <div className='space-y-4 bg-neutral-100 px-3 py-4 w-full'>
          <H4SpacingLetter>Totale vini consuntivo</H4SpacingLetter>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            <StaticInputPresentational
              big
              label='Tipologie di vino'
              text={wineTypes}
            />
            <StaticInputPresentational
              big
              label='Totale bottiglie'
              text={finalBottles}
            />
            <StaticInputPresentational
              big
              label='Totale spesa'
              text={formatCurrency(finalTotal)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const WineHeader = () => (
  <>
    <div className='col-span-4'>
      <H4SpacingLetter>Lista vini</H4SpacingLetter>
    </div>
    <div className='col-span-3'>
      <H4SpacingLetter>Bilancio preventivo</H4SpacingLetter>
    </div>
    <div className='col-span-3'>
      <H4SpacingLetter>Bilancio consuntivo</H4SpacingLetter>
    </div>
  </>
);

interface WineRowProps {
  wineIdx: number;
  removeWine: UseFieldArrayRemove;
}

const WineRow: React.FC<WineRowProps> = ({ wineIdx, removeWine }) => {
  const { watch, register } = useFormContext<EventRequest>();
  const wine = watch('wines')[wineIdx];

  return (
    <>
      <div className='col-span-4 items-end flex gap-3'>
        <div className='rounded-full border-2 border-primary w-14 h-12 flex items-center justify-center'>
          <p className='text-2xl font-bold text-primary'>{wineIdx + 1}</p>
        </div>
        <InputPresentational
          label='Azienda - Denominazione - Annata'
          type='text'
          id={`wines.${wineIdx}.wine`}
          name={`wines.${wineIdx}.wine`}
          register={register}
        />
      </div>

      <InputPresentational
        label='Costo (iva incl.)'
        type='number'
        id={`wines.${wineIdx}.estimatedPricePerBottle`}
        name={`wines.${wineIdx}.estimatedPricePerBottle`}
        register={register}
      />

      <InputPresentational
        label='N. Bottiglie'
        type='number'
        id={`wines.${wineIdx}.estimatedBottles`}
        name={`wines.${wineIdx}.estimatedBottles`}
        register={register}
      />

      <InputPresentational
        disabled
        label='Totale'
        type='number'
        value={wine.estimatedPricePerBottle * wine.estimatedBottles}
      />

      {/* Bilancio consuntivo */}

      <InputPresentational
        label='Costo (iva incl.)'
        type='number'
        id={`wines.${wineIdx}.finalPricePerBottle`}
        name={`wines.${wineIdx}.finalPricePerBottle`}
        register={register}
      />

      <InputPresentational
        label='N. Bottiglie'
        type='number'
        id={`wines.${wineIdx}.finalBottles`}
        name={`wines.${wineIdx}.finalBottles`}
        register={register}
      />

      <InputPresentational
        disabled
        label='Totale'
        type='number'
        value={wine.finalPricePerBottle * wine.finalBottles}
      />

      <div className='flex items-end h-full pb-2'>
        <MinusCircleIcon
          className=' text-primary'
          width={24}
          onClick={() => removeWine(wineIdx)}
        />
      </div>
    </>
  );
};

export default ListaVini;

import axios from 'axios';
import qs from 'qs';
import { NationListResponse } from '../interfaces/nation';

const { REACT_APP_API_URL } = process.env;

export const nations = async () => {
  const query = {
    populate: '*',
    pagination: {
      pageSize: 200,
    },
  };

  const { data } = await axios.get<NationListResponse>(
    `${REACT_APP_API_URL}/nations?${qs.stringify(query)}`
  );
  return data;
};

import { basename } from 'path';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';

import Select from 'react-select';
import { selectStyle } from './SearchableSelect';

export interface IOption {
  label: string;
  value: string | number;
}

interface MultiselectProps {
  id: string;
  name: string;
  label?: string;
  info?: string;
  control?: any;
  error?: FieldError;
  onInputChange?: (val: string) => void;
  options: IOption[];
  required?: boolean;
}

const Multiselect: React.FC<MultiselectProps> = ({
  id,
  name,
  label = '',
  info,
  options,
  control,
  error,
  required = false,
  onInputChange,
}) => {
  const filterOptions = (option: IOption, input: string) => {
    if (input) {
      const inputWords = input.toLowerCase().split(' ');
      return inputWords.every((word) =>
        option.label?.toLowerCase().includes(word)
      );
    }
    return true;
  };

  return (
    <div className='w-full'>
      <label
        htmlFor={id}
        className='text-sm text-gray-500 flex justify-between'
      >
        {`${label}${required ? ' *' : ''}`}
        {info}
      </label>
      <div className='mt-1 flex items-center z-50'>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              onInputChange={onInputChange}
              name={name}
              isMulti
              styles={selectStyle(error) as any}
              menuPortalTarget={document.body}
              menuPosition='fixed'
              className='w-full absolute z-10'
              options={options}
              value={value}
              ref={ref}
              onChange={onChange}
              filterOption={filterOptions}
            />
          )}
        ></Controller>
      </div>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export default Multiselect;

import axios from 'axios';
import QueryString from 'qs';
import { StrapiResponse, StrapiResponseList } from '../interfaces/commons';
import { Order, OrderForm, ProductType } from '../interfaces/orders';
import { PaymentMethod, PaymentStatus } from '../interfaces/payments';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const findOne = async ({
  token,
  id,
  query = {},
}: {
  token?: string | null;
  id?: number | string | null;
  query?: object;
}) => {
  const { data } = await instance.get<
    StrapiResponse<{ attributes: Order; id: number }>
  >(`${REACT_APP_API_URL}/orders/${id}?${QueryString.stringify(query)}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const find = async ({
  token,
  query = {},
}: {
  token?: string | null;
  query?: object;
}) => {
  const { data } = await instance.get<
    StrapiResponseList<{ id: number; attributes: Order }>
  >(`${REACT_APP_API_URL}/admin/orders?${QueryString.stringify(query)}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const create = async ({
  token,
  query = {},
  body,
}: {
  token?: string | null;
  query?: object;
  body: OrderForm;
}) => {
  const { data } = await instance.post<
    StrapiResponse<{ attributes: Order; id: number }>
  >(
    `${REACT_APP_API_URL}/orders?${QueryString.stringify(query)}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const disconnect = async ({
  token,
  id,
}: {
  token: string | null;
  id: number;
}) => {
  const { data } = await instance.post<
    StrapiResponse<{ attributes: Order; id: number }>
  >(
    `${REACT_APP_API_URL}/orders/${id}/disconnect`,
    {},
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const update = async ({
  token,
  query = {},
  body,
  id,
}: {
  token?: string | null;
  query?: object;
  body: OrderForm;
  id?: number;
}) => {
  const { data } = await instance.put<
    StrapiResponseList<{ attributes: Order; id: number }>
  >(
    `${REACT_APP_API_URL}/orders/${id}?${QueryString.stringify(query)}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const orders = { findOne, find, create, update, disconnect };
export default orders;

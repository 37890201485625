import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useAuth } from '../../../contexts/Auth';

import { createEvent } from '../../../api/events';

import { EventRequest, EventStatus } from '../../../interfaces/events';

import DatiPrincipaliEvento from './tabs/dati-principali';
import LocationEvento from './tabs/location';

import HeaderTitle from '../../../components/HeaderTitle';
import StatusEventModal from '../../../components/Modals/StatusEventModal';
import TabBar from '../../../components/TabBars/TabBar';

import { emptyEvent } from '../utils/emptyItems';
import CostiEvento from './tabs/costi';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { newEventValidator } from '../../../validators/event';
import moment from 'moment';
import { uploadToStrapi } from '../../../api/courses';

const tabs = [
  {
    label: 'Dati principali',
    href: '/eventi/nuovo',
  },
  {
    label: 'Location',
    href: '/eventi/nuovo/location',
  },

  {
    label: 'Costi',
    href: '/eventi/nuovo/costi',
  },
];

const NuovoEvento = () => {
  const navigate = useNavigate();
  const [{ token }] = useAuth();

  const newEventFormMethods = useForm<EventRequest>({
    defaultValues: emptyEvent,
    resolver: useYupValidationResolver(newEventValidator),
  });

  const formValues = newEventFormMethods.watch();

  const newEventMutationFn = (newEvent: EventRequest) => {
    return createEvent(newEvent, token!);
  };

  const onNewEventMutationSuccess = () => {
    toast.success('Evento creato con successo!');
    navigate('/eventi');
  };

  const onNewEventMutationErrors = () => {
    toast.error("Errore durante la creazione dell'evento");
  };

  const newEventMutation = useMutation({
    mutationKey: ['createEvent'],
    mutationFn: newEventMutationFn,
    onSuccess: onNewEventMutationSuccess,
    onError: onNewEventMutationErrors,
  });

  const formHasErrors: boolean =
    Object.keys(newEventFormMethods.formState.errors).length > 0;

  const { mutateAsync: uploadFileMutation } = useMutation({
    mutationKey: ['uploadFile'],
    mutationFn: ({ files, token }: { files: File[]; token: string | null }) =>
      uploadToStrapi(files, token),

    onError: (err) => {
      console.log('err', err);
      toast.error('Qualcosa è andato storto!');
    },
  });

  const save = (status?: EventStatus) => {
    return newEventFormMethods.handleSubmit(async (updatedEventValues) => {
      let uploadEventImageRes: any;
      let uploadEventCarouselRes: any;

      const areElementsFile = (elements?: any[] | null) => {
        return elements?.some((element) => element instanceof File);
      };

      if (updatedEventValues.image) {
        uploadEventImageRes = await uploadFileMutation({
          files: updatedEventValues.image as File[],
          token,
        });
      }

      if (areElementsFile(updatedEventValues.carousel)) {
        uploadEventCarouselRes = await uploadFileMutation({
          files: updatedEventValues.carousel as File[],
          token,
        });
      }

      newEventMutation.mutate({
        ...updatedEventValues,
        status: status || updatedEventValues.status,
        mailDate: moment(updatedEventValues.mailDate).utc().toISOString(),
        firstReminderDate: moment(updatedEventValues.firstReminderDate)
          .utc()
          .toISOString(),
        secondReminderDate: moment(updatedEventValues.secondReminderDate)
          .utc()
          .toISOString(),
        startDate: moment(updatedEventValues.startDate).utc().toISOString(),
        image: uploadEventImageRes?.[0]?.id,
        carousel: uploadEventCarouselRes?.map((file: any) => file.id),
      });
    });
  };

  const saveAsDraft = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione, controlla tutti i campi.');
    } else {
      save(EventStatus.DRAFT)();
    }
  };

  const saveAndPublish = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione, controlla tutti i campi.');
    } else {
      save(EventStatus.PUBLISHED)();
    }
  };

  const onCarouselImageDelete = async (index: number) => {
    const carouselImages = Array.isArray(formValues.carousel)
      ? [...formValues.carousel]
      : [];
    carouselImages?.splice(index, 1); // Rimuovi l'immagine dall'array
    newEventFormMethods.setValue('carousel', carouselImages as File[]);
  };

  useEffect(() => {
    newEventFormMethods.setValue('location', undefined);
  }, [newEventFormMethods.watch('section')]);

  const isLoading = newEventMutation.isLoading;

  return (
    <FormProvider {...newEventFormMethods}>
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <HeaderTitle
          category='Evento'
          primaryButtonText='Salva e pubblica'
          primaryButtonOnClick={saveAndPublish}
          secondaryButtonText='Salva come bozza'
          secondaryButtonOnClick={saveAsDraft}
          buttonsDisabled={isLoading}
          // modal={<StatusEventModal />}
        />

        <div className='pt-4'>
          <TabBar tabs={tabs} />
          <Routes>
            <Route
              index
              element={
                <DatiPrincipaliEvento
                  onImageDelete={() =>
                    newEventFormMethods.setValue('image', undefined)
                  }
                  onCarouselImageDelete={(index) =>
                    onCarouselImageDelete(index)
                  }
                  eventMethods={newEventFormMethods}
                />
              }
            />
            <Route path='location' element={<LocationEvento />} />
            <Route
              path='costi'
              element={<CostiEvento defaultEvent={undefined} />}
            />
          </Routes>
        </div>
      </div>
    </FormProvider>
  );
};
export default NuovoEvento;

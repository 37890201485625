import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormDatiPrincipaliUtente from '../../../../components/FormDatiPrincipaliUtenti';

const NuovoUtenteAnagrafica = () => {
  const { watch, control, setValue, resetField } = useFormContext();

  // Logica per cambiare le select di Nazione, regione e provincia

  useEffect(() => {
    if (watch('region') !== undefined) {
      setValue('nation', 1);
    }
  }, [watch('region', 'province')]);

  useEffect(() => {
    resetField('province');
  }, [watch('region')]);

  useEffect(() => {
    if (watch('nation') !== 1) {
      resetField('province');
      resetField('region');
    }
  }, [watch('nation')]);

  // Logica per cambiare le select di Nazione, regione e provincia di HomeAddress
  useEffect(() => {
    if (watch('homeAddress.region') !== undefined) {
      setValue('homeAddress.nation', 1);
    }
  }, [watch('homeAddress.region', 'homeAddress.province')]);

  useEffect(() => {
    resetField('homeAddress.province');
  }, [watch('homeAddress.region')]);

  useEffect(() => {
    if (watch('homeAddress.nation') !== 1) {
      resetField('homeAddress.province');
      resetField('homeAddress.region');
    }
  }, [watch('homeAddress.nation')]);

  return (
    <div className='pb-28'>
      <FormDatiPrincipaliUtente creationForm/>
    </div>
  );
};

export default NuovoUtenteAnagrafica;

export const emptyEvent = {
  subscriptionAmount: 0,
  minParticipants: 1,
  autosetReminderDates: false,
  balance: {
    price: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    contributes: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    wines: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },

    food: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    rooms: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    sponsorship: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    extras: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    teacherPayments: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    teacherRefunds: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    direction: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
  },
  headquarterBalance: {
    cards: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    shipping: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    administrations: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    teachingMaterial: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
  },
  wines: [],
};

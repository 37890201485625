import {
  useLayoutEffect,
  useEffect,
  useRef,
  useState,
  ChangeEventHandler,
  FormEvent,
  ChangeEvent,
} from 'react';
import { LessonTemplate } from '../../../interfaces/lesson-templates';
import classNames from '../../../libs/utils/classNames';
import { formatDuration } from '../../../libs/utils/formatters/lessonFormatters';

const tableElements = ['Lezione', 'Categoria', 'Tipologia', 'Durata', 'Prezzo'];

interface SelectLessonsProps {
  lessons: LessonTemplate[];
  selectedLessons: number[];
  setSelectedLessons: React.Dispatch<React.SetStateAction<number[]>>;
}

const SelectLessons: React.FC<SelectLessonsProps> = ({
  lessons,
  selectedLessons,
  setSelectedLessons,
}) => {
  const [checked, setChecked] = useState(false);

  useLayoutEffect(() => {
    setChecked(false);
    if (selectedLessons.length === lessons.length) setChecked(true);
  }, [selectedLessons]);

  function toggleAll(e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    const allLessonIds = lessons
      .filter(() => checked) // filtro per checked perché se checked === false allora returna vuoto
      .map((lesson) => lesson.id); // poi mi tiro fuori solo gli id

    setSelectedLessons(allLessonIds);
  }

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='relative overflow-hidden ring-1 border ring-black ring-opacity-5'>
          <table className='min-w-full table-fixed divide-y divide-gray-300'>
            <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
              <tr className='custom-padding-table'>
                <th scope='col' className='relative w-12 px-6 sm:w-16 sm:px-8'>
                  <input
                    type='checkbox'
                    className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6'
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th>
                {tableElements.map((element) => (
                  <th className='font-normal px-3 py-3 text-left tracking-wide'>
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {lessons?.map((lesson: LessonTemplate) => (
                <tr
                  key={lesson.id}
                  className={
                    selectedLessons.includes(lesson.id)
                      ? 'bg-sand-50'
                      : undefined
                  }
                >
                  <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                    {selectedLessons.includes(lesson.id) && (
                      <div className='absolute inset-y-0 left-0 w-0.5 bg-primary' />
                    )}
                    <input
                      type='checkbox'
                      className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6'
                      value={lesson.id}
                      checked={selectedLessons.includes(lesson.id)}
                      onChange={(e) =>
                        setSelectedLessons(
                          e.target.checked
                            ? [...selectedLessons, lesson.id]
                            : selectedLessons.filter((p) => p !== lesson.id)
                        )
                      }
                    />
                  </td>
                  <td
                    className={classNames(
                      'whitespace-nowrap py-4 pr-3 text-sm',
                      selectedLessons.includes(lesson.id)
                        ? 'text-primary'
                        : 'text-gray-600'
                    )}
                  >
                    {lesson.attributes.title}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {lesson.attributes.category}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {lesson.attributes.endCourse
                      ? 'Fine corso'
                      : 'Non fine corso'}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {formatDuration(lesson.attributes.minutes)}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {lesson.attributes.estimatePayment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SelectLessons;

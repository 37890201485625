import { useForm } from 'react-hook-form';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { LabelForm } from '../../../interfaces/labels';
import { useMutation, useQuery } from 'react-query';
import labels from '../../../api/labels';
import { useAuth } from '../../../contexts/Auth';
import Multiselect from '../../../components/Form/Multiselect';
import { getProfiles } from '../../../api/profile';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import {
  ControlledSelect,
} from '../../../components/Form/Selects';
import { getQuerySearchFilters } from '../../../libs/utils/profile';

const StampaEtichetta = () => {
  const [{ token }] = useAuth();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);

  const { mutate } = useMutation('createLabel', labels.create, {
    onSuccess: () => {
      toast.success('Etichette salvate con successo');
    },
    onError: () => {
      toast.error('Ooops... Qualcosa è andato storto');
    },
  });
  const { handleSubmit, control, formState, register, watch } =
    useForm<LabelForm>({
      mode: 'onChange',
      defaultValues: { type: 'standard' },
      resolver: useYupValidationResolver(
        Yup.object().shape({
          recipients: Yup.array()
            .min(1, 'Questo campo è obbligatorio')
            .required('Questo campo è obbligatorio'),
          // street: Yup.string().required('Questo campo è obbligatorio'),
          // streetNumber: Yup.string().required('Questo campo è obbligatorio'),
          // city: Yup.string().required('Questo campo è obbligatorio'),
          // cap: Yup.string().required('Questo campo è obbligatorio'),
          // province: Yup.string().required('Questo campo è obbligatorio'),
          // region: Yup.string().required('Questo campo è obbligatorio'),
          // addressDetail: Yup.string().required('Questo campo è obbligatorio'),
        })
      ),
    });

  const onSubmit = (values: LabelForm) => {
    mutate({
      filename: 'nome etichetta',
      body: {
        ...values,
        recipients: values.recipients.map((elem) => elem.value),
      },
      token,
    });
  };

  const profilesQuery = useQuery(
    ['getProfileListForParticipations', debouncedSearch],
    () =>
      getProfiles({
        token,
        query: {
          filters: {
            $and: getQuerySearchFilters(debouncedSearch),
          },
        },
      })
  );

  return (
    <>
      <TitlePage title='Stampa etichette' />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='col-span-12 lg:col-span-9 xl:col-span-10'
      >
        <H1Styled>Stampa etichette</H1Styled>

        <div className='my-10'>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              01
            </div>
            <div className='mx-6'>
              <p className=' w-96 text-lg text-gray-700'>
                Scegli i destinatari
              </p>
              <Multiselect
                id={'recipients'}
                name={'recipients'}
                label=''
                control={control}
                options={
                  profilesQuery.data?.data?.map((elem) => ({
                    label: `${elem.attributes.name} ${elem.attributes.surname}`,
                    value: elem.id,
                  })) || []
                }
                onInputChange={(_search) => setSearch(_search)}
                error={{ ...formState.errors['recipients'], type: 'required' }}
              />
            </div>
          </div>
          {/* <div className='flex w-full lg:max-w-[60rem] my-4 mb-8 mt-12'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              02
            </div>
            <div className='mx-6 w-full'>
              <p className=' w-96 text-lg text-gray-700'>Scrivi l'indirizzo</p>
              <form>
                <div className='pt-4 pb-8 grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-x-8 gap-y-4'>
                  <InputPresentational
                    id='street'
                    label='Via'
                    name='street'
                    type='text'
                    error={{ ...formState.errors['street'], type: 'required' }}
                    register={register}
                  />

                  <InputPresentational
                    id='house-number'
                    label='Numero civico'
                    name='streetNumber'
                    type='text'
                    error={{
                      ...formState.errors['streetNumber'],
                      type: 'required',
                    }}
                    register={register}
                  />

                  <InputPresentational
                    id='city'
                    label='Città'
                    name='city'
                    register={register}
                    error={{ ...formState.errors['city'], type: 'required' }}
                    type='text'
                  />
                  <InputPresentational
                    id='cap'
                    label='Cap'
                    name='cap'
                    register={register}
                    error={{ ...formState.errors['cap'], type: 'required' }}
                    type='text'
                  />

                  <InputPresentational
                    id='province'
                    label='Provincia'
                    name='province'
                    type='text'
                    error={{
                      ...formState.errors['province'],
                      type: 'required',
                    }}
                    register={register}
                  />

                  <InputPresentational
                    id='region'
                    label='Regione'
                    name='region'
                    type='text'
                    error={{
                      ...formState.errors['region'],
                      type: 'required',
                    }}
                    register={register}
                  />
                  <InputPresentational
                    id='addressDetail'
                    label='Presso - C/O'
                    name='addressDetail'
                    type='text'
                    error={{
                      ...formState.errors['addressDetail'],
                      type: 'required',
                    }}
                    register={register}
                  />
                </div>
              </form>
            </div>
          </div> */}
          <div className='flex w-full lg:max-w-[60rem] my-4 mb-8 mt-12'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              02
            </div>
            <div className='mx-6 w-full'>
              <p className=' w-96 text-lg text-gray-700'>Scegli tipologia</p>
              <form>
                <div className='pt-4 pb-8 grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-x-8 gap-y-4'>
                  <ControlledSelect
                    name='type'
                    control={control as any}
                    options={[
                      { value: 'standard', label: 'Etichetta PDF' },
                      { value: 'dymo', label: 'Dymo' },
                    ]}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <PrimaryButton type='submit'> Stampa</PrimaryButton>
      </form>
    </>
  );
};

export default StampaEtichetta;

import FormDatiPrincipaliSezione from '../../../../components/FormDatiPrincipaliSezione';
import TitlePage from '../../../../components/TitlePage';

export default function DatiPrincipali() {
  return (
    <>
      <TitlePage title='Crea nuova Sezione | Dati principali' />
      <FormDatiPrincipaliSezione />
    </>
  );
}

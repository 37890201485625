import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TitlePage from '../../components/TitlePage';
import ListaCoupon from './Pages/lista-coupon';
import CreaNuovoCoupon from './Pages/NuovoCoupon';
import ModificaCoupon from './Pages/ModificaCoupon';

export default function Coupon() {
  const SIDEBAR_ITEMS = [
    { href: '/coupon', label: 'Tutti i coupon' },
    { href: '/coupon/nuovo-coupon', label: 'Crea nuovo coupon' },
  ];

  return (
    <div className='grid w-full grid-cols-12 gap-8 px-4 pb-10 pt-7 lg:py-12 lg:px-10'>
      <TitlePage title='Coupon' />
      <Sidebar items={SIDEBAR_ITEMS} />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <Routes>
          <Route index element={<ListaCoupon />} />

          <Route path='dettaglio-coupon/:id' element={<ModificaCoupon />} />
          <Route path='nuovo-coupon' element={<CreaNuovoCoupon />} />
        </Routes>
      </div>
    </div>
  );
}

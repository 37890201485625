import { Dispatch, SetStateAction } from 'react';

export interface StrapiMeta {
  pagination?: StrapiPagination;
}

export interface StrapiPagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

export interface StrapiResponse<T> {
  data: T;
  meta: StrapiMeta;
}
export interface StrapiResponseList<T> {
  data: T[];
  meta: StrapiMeta;
}

export interface StrapiData<T> {
  attributes: T;
  id: number;
}

export interface StrapiDataList<T> {
  data: StrapiData<T>[];
}

export enum Level {
  PRIMO_LIVELLO = 'Primo livello',
  SECONDO_LIVELLO = 'Secondo livello',
  TERZO_LIVELLO = 'Terzo livello',
  MASTER_MONOTEMATICO = 'Master monotematico',
}

export enum Category {
  ASSAGGIATORE = 'Assaggiatore',
  SOMMELIER = 'Sommelier',
  ONLINE = 'Online',
  MASTER = 'Master',
  APPROFONDIMENTO = 'Approfondimento',
}

export interface DescriptionList {
  id?: number;
  description: string;
}

export enum CourseStatus {
  DRAFT = 'draft',
  TO_APPROVE = 'to_approve',
  NOT_APPROVED = 'not_approved',
  PUBLISHED = 'published',
  FINAL_BALANCE_DELEGATION = 'final_balance_delegation',
  FINAL_BALANCE_NATIONAL = 'final_balance_national',
  CANCELED = 'canceled',
  // SENT = 'sent', -> non presente in strapi
  // CLOSED = 'closed', -> non presente in strapi
}

export interface IState<T> {
  state: T;
  setState: Dispatch<SetStateAction<T>>;
}

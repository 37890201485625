import TitlePage from "../../../components/TitlePage";
import FormDirezione from "../../../components/FormDirezione";

const Direzione = () => {
  return (
    <>
      <TitlePage title="Crea nuovo corso | Direzione" />
      <FormDirezione />
    </>
  );
};

export default Direzione;

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import H3Styled from '../../../../components/Typography/H3Styled';
import UtenteRinnovoTesseraTable from '../../Tables/UtenteRinnovoTessera';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../../../../interfaces/profile';
import Pagination from '../../../../components/Pagination';
import { StrapiMeta } from '../../../../interfaces/commons';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import { FilterType } from '../../../../interfaces/filters';

export default function StoricoTessera({
  infoUser,
  meta,
}: {
  infoUser: Profile;
  meta: StrapiMeta;
}) {
  const navigate = useNavigate();

  return (
    <div className='space-y-5'>
      <H3Styled>Storico tessera</H3Styled>
      <FiltersBar
        filters={[
          {
            type: FilterType.CHECKBOX,
            attribute: 'notPaid',
            label: 'Non pagato',
          },
        ]}
        hideAppliedFilters
      />
      <UtenteRinnovoTesseraTable infoUser={infoUser} />
      <div className='flex items-center gap-4'>
        <PrimaryButton
          outline
          onClick={() => navigate(`/utenti/modifica/${infoUser.id}/dati-onav`)}
          type='button'
        >
          Torna ai dati ONAV
        </PrimaryButton>
      </div>
    </div>
  );
}

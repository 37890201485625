import { XIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import IconButton from '../Buttons/IconButton';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Media, MediaAttributes } from '../../interfaces/media';
import { getMediaUrl } from '../../libs/utils/media';

interface CarouselUploadFilesPreviewProps {
  imageSources?: (File | Media)[];
  onRemove?: (index: number) => void;
  disabled?: boolean;
}

const CarouselUploadFilesPreview: React.FC<CarouselUploadFilesPreviewProps> = ({
  imageSources,
  onRemove = () => {},
  disabled = false,
}) => {
  if (!imageSources || imageSources.length === 0) {
    return null;
  }

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    const urls: string[] = [];

    imageSources.forEach((source) => {
      if (source instanceof File) {
        urls.push(URL.createObjectURL(source));
      } else if (
        typeof source.attributes === 'object' &&
        'url' in source.attributes
      ) {
        urls.push(getMediaUrl(source.attributes.url));
      }
    });

    setImageUrls(urls);

    return () => {
      urls.forEach((url) => {
        if (url.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [imageSources]);

  const handleRemoveClick = (index: number) => {
    if (!disabled) {
      onRemove(index);
    } else {
      return;
    }
  };

  return (
    <div className='md:pt-7 md:pb-1 md:px-4 w-full'>
      <Carousel
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        onChange={() => {}}
        onClickItem={() => {}}
        onClickThumb={() => {}}
        thumbWidth={80}
        renderThumbs={() => {
          return imageUrls.map((url, index) => {
            const backgroundImageStyle = {
              backgroundImage: `url(${url})`,
            };

            return (
              <div
                key={index}
                className='h-[72px] w-full bg-center bg-cover rounded-md'
                style={backgroundImageStyle}
              />
            );
          });
        }}
      >
        {imageUrls.map((url, index) => {
          const backgroundImageStyle = {
            backgroundImage: `url(${url})`,
          };

          return (
            <div
              key={index}
              className='h-[50vh] w-full bg-center bg-contain bg-no-repeat rounded-md'
              style={backgroundImageStyle}
            >
              <div className='absolute top-0 right-5 flex items-center justify-center mt-2 mr-2'>
                <IconButton redTxt onClick={() => handleRemoveClick(index)}>
                  <XIcon className='w-5 h-5 bg-white' />
                </IconButton>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselUploadFilesPreview;

import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { PlusIcon } from '@heroicons/react/outline';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  children?:
    | string
    | number
    | string[]
    | number[]
    | JSX.Element
    | JSX.Element[];
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  subtitle?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  className?: string;
  hideBottom?: boolean;
  isLoading?: boolean;
}

const BaseModal: React.FC<Props> = ({
  isOpen,
  toggle,
  children,
  onConfirm,
  onCancel,
  title,
  subtitle,
  confirmButtonText,
  cancelButtonText,
  className,
  hideBottom,
  isLoading,
}) => {
  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className={`fixed z-10 inset-0 overflow-y-auto ${className}`}
          onClose={toggle}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                {title && (
                  <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                    {title}
                  </h4>
                )}

                {subtitle && (
                  <div
                    className='space-y-5 text-gray-700'
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  ></div>
                )}
                {children}
                {!hideBottom && (
                  <div className='flex items-center justify-end gap-4 mt-6'>
                    {onCancel && (
                      <PrimaryButton textSmall onClick={onCancel || toggle}>
                        {cancelButtonText || 'Annulla'}
                      </PrimaryButton>
                    )}
                    <PrimaryButton
                      small
                      type='submit'
                      onClick={onConfirm && onConfirm}
                      isLoading={isLoading}
                    >
                      {confirmButtonText || 'Conferma'}
                    </PrimaryButton>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BaseModal;

export const getQuerySearchFilters = (searchedText: string) => {
  const keywords = searchedText
    .trim()
    .split(' ')
    .filter((keyword) => keyword !== '');

  let searchFilters = [];

  if (keywords.length === 2) {
    searchFilters = [
      {
        $or: [
          { name: { $contains: searchedText } },
          { surname: { $contains: searchedText } },
          {
            $and: [
              { name: { $contains: keywords[0] } },
              { surname: { $contains: keywords[1] } },
            ],
          },
          {
            $and: [
              { name: { $contains: keywords[1] } },
              { surname: { $contains: keywords[0] } },
            ],
          },
        ],
      },
    ];
  } else {
    searchFilters = keywords.map((keyword) => ({
      $or: [
        { name: { $contains: keyword } },
        { surname: { $contains: keyword } },
      ],
    }));
  }

  return searchFilters;
};

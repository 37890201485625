import React, { useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import H1Styled from '../../../../components/Typography/H1Styled';
import NuovoCoupon from './nuovo-coupon';
import { createCoupon, listCoupons } from '../../../../api/coupon';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useAuth } from '../../../../contexts/Auth';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { modelData } from '../../utils';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { couponValidator } from '../../../../validators/coupon';

const CreaNuovoCoupon = () => {
  const [{ token }] = useAuth();
  const navigate = useNavigate();
  const { REACT_APP_DEBOUNCE_DELAY } = process.env;
  const delay = Number(REACT_APP_DEBOUNCE_DELAY);
  const [searchParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const refetchTable = useQuery(['coupons', ...debounceSearchParams], () =>
    listCoupons(debounceSearchParams, token)
  );

  const [checked, setChecked] = useState<'' | 'all' | 'course' | 'event'>('');

  const newCouponMethods = useForm({
    resolver: useYupValidationResolver(couponValidator),
  });

  const { mutate, isLoading } = useMutation(createCoupon, {
    onSuccess: () => {
      toast.success('Coupon creato con successo');
      navigate(`/coupon`);
      refetchTable.refetch();
    },
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });

  const createNewCoupon = async (data: FieldValues) => {
    const modeledData = modelData(data, checked);
    if (modeledData.validFor === '')
      return toast.error("Valido per e' obbligatorio");
    if (new Date(modeledData.startValidity) > new Date(modeledData.endValidity))
      return toast.error(
        "'Valido fino al' deve essere una data successiva rispetto a 'Valido dal'"
      );
    mutate({
      data: { data: modeledData },
      token,
    });
  };

  return (
    <FormProvider {...newCouponMethods}>
      <form
        className='col-span-12 lg:col-span-9 xl:col-span-10'
        onSubmit={newCouponMethods.handleSubmit(createNewCoupon)}
      >
        <div className='flex justify-between'>
          <H1Styled>Crea Nuovo Coupon</H1Styled>
          <div className='flex items-center h-10 gap-4'>
            <PrimaryButton
              outline
              onClick={() => {
                navigate('/coupon');
              }}
            >
              Chiudi
            </PrimaryButton>
            <PrimaryButton type='submit'>Salva</PrimaryButton>
          </div>
        </div>

        <div className='pt-12'>
          <NuovoCoupon checked={checked} setChecked={setChecked} />
        </div>
      </form>
    </FormProvider>
  );
};
export default CreaNuovoCoupon;

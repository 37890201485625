import { Fragment, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useSearchParams } from 'react-router-dom';
import { BackspaceIcon } from '@heroicons/react/outline';
import { ListFilters } from '../../interfaces/filters';
import FetchCheckbox from './components/FetchCheckbox';
import { listCoursesFilter } from '../../api/courses';
import { onavPositions } from '../../api/onavPositions';
import { listEventsFilter } from '../../api/events';
import { profileCategories } from '../../api/profileCategories';
import { paymentMode } from '../../api/paymentMode';
import SelectDate from './components/selectDate';
import { regions } from '../../api/regions';
import ConstantCheckbox from './components/ConstantCheckbox';
import { courseStatus } from '../../constants/course';
import { sendStatus } from '../../constants/announcement';
import { years } from '../../constants/years';
import { eventStatus } from '../../constants/events';
import { lessonCategory } from '../../constants/course';
import sectionsAPIs from '../../api/section';

export default function Filters({
  teacher,
  volunteer,
  searching,
  magazine,
  section,
  course,
  onavPosition,
  event,
  profileCategory,
  exam,
  paid,
  notPaid,
  presence,
  paymenMethods,
  date,
  hideAppliedFilters,
  region,
  coupon,
  onavIt,
  status,
  eventstatus,
  send,
  digitalBadge,
  year,
  printedBadge,
  lessonCategories,
  endCourse,
  onChange,
}: ListFilters) {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');

  const onChangeBoolean = (labelName: string) => {
    const value = searchParams.get(labelName);
    if (value) {
      searchParams.delete('page');
      searchParams.delete(labelName);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('page');
      searchParams.append(labelName, 'true');
      setSearchParams(searchParams);
    }
  };

  const onChangeSearch = (value: string) => {
    // TODO: aggiungere useDebounce
    setSearch(value);

    value === ''
      ? searchParams.delete('search')
      : searchParams.set('search', value);
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const filterCheckList = [
    {
      label: 'Volontario',
      labelName: 'isVolunteer',
      visible: volunteer,
    },
    {
      label: 'Tessera non stampata',
      labelName: 'printedBadge',
      visible: printedBadge,
    },
    {
      label: 'Tessera non digitale',
      labelName: 'printDigitalBadge',
      visible: digitalBadge,
    },
    {
      label: 'Docente',
      labelName: 'isTeacher',
      visible: teacher,
    },
    {
      label: 'Rivista digitale',
      labelName: 'printDigitalMagazine',
      visible: magazine,
    },
    {
      label: 'Esame',
      labelName: 'evaluation',
      visible: exam,
    },
    {
      label: 'Pagato',
      labelName: 'paid',
      visible: paid,
    },
    {
      label: 'Non pagato',
      labelName: 'notPaid',
      visible: notPaid,
    },
    {
      label: 'Presente',
      labelName: 'presence',
      visible: presence,
    },
    {
      label: 'Visibile su onav.it',
      labelName: 'onavIt',
      visible: onavIt,
    },
    {
      label: 'Singolo',
      labelName: 'single',
      visible: coupon,
    },
    {
      label: 'Fine Corso',
      labelName: 'endCourse',
      visible: endCourse,
    },
  ];

  const filtersNames = [
    'course',
    'event',
    // 'isTeacher',
    // 'isVolunteer',
    // 'printDigitalMagazine',
    'section',
    'onavPosition',
    'profileCategory',
    'dateFrom',
    'dateTo',
    'regions',
    'status',
    'year',
    'category',
  ];

  const deleteFilter = (item: string, filterName: string) => {
    const newFilter = [...searchParams].filter(
      ([key, value]) => key !== filterName || value !== item
    );
    setSearchParams(newFilter);
  };

  return (
    <div className='bg-white'>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-40 flex xl:hidden'
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='relative flex flex-col w-full h-full max-w-xs py-4 pb-12 ml-auto overflow-y-auto bg-white shadow-xl'>
              <div className='flex items-center justify-between px-4'>
                <h2 className='text-lg font-medium font-IBM text-primary'>
                  Filtri
                </h2>
                <button
                  type='button'
                  className='flex items-center justify-center w-10 h-10 p-2 -mr-2 text-gray-400 bg-white rounded-md'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='w-6 h-6' aria-hidden='true' />
                </button>
              </div>

              <form className='mt-4'>
                {searching && (
                  <div className='flex items-center px-4 mb-4'>
                    <input
                      type='text'
                      name='search'
                      id='search'
                      className='block w-full text-sm border-gray-300 rounded-full focus:ring-primary focus:border-primary bg-neutral-50'
                      placeholder='Cerca...'
                      value={search}
                      onChange={(e) => onChangeSearch(e.target.value)}
                    />
                    {search && (
                      <BackspaceIcon
                        className='w-5 h-5 -ml-8 text-gray-500 cursor-pointer'
                        aria-hidden='true'
                        onClick={() => {
                          searchParams.delete('search');
                          setSearch('');
                          setSearchParams(searchParams);
                        }}
                      />
                    )}
                  </div>
                )}
                {date && <SelectDate mobile />}
                {course && (
                  <FetchCheckbox
                    mobile
                    api={listCoursesFilter}
                    queryName='corsesCheckbox'
                    label='Corsi'
                    filterName='course'
                  />
                )}
                {event && (
                  <FetchCheckbox
                    mobile
                    api={listEventsFilter}
                    queryName='eventsCheckbox'
                    label='Eventi'
                    filterName='event'
                  />
                )}
                {section && (
                  <FetchCheckbox
                    mobile
                    api={sectionsAPIs.findOptions}
                    queryName='sectionCheckbox'
                    label='Sezioni'
                    filterName='section'
                  />
                )}

                {onavPosition && (
                  <FetchCheckbox
                    mobile
                    api={onavPositions}
                    queryName='onavPositionCheckbox'
                    label='Carica'
                    filterName='onavPosition'
                  />
                )}
                {profileCategory && (
                  <FetchCheckbox
                    mobile
                    api={profileCategories}
                    queryName='profileCategoryCheckbox'
                    label='Titolo'
                    filterName='profileCategory'
                  />
                )}
                {paymenMethods && (
                  <FetchCheckbox
                    mobile
                    api={paymentMode}
                    queryName='paymentModeCheckbox'
                    label='Metodo di pagamento'
                    filterName='paymentMode'
                  />
                )}
                {region && (
                  <FetchCheckbox
                    mobile
                    api={regions}
                    queryName='regionsCheckbox'
                    label='Regione'
                    filterName='regions'
                  />
                )}
                {status && (
                  <ConstantCheckbox
                    mobile
                    constant={courseStatus}
                    label='Stato'
                    filterName='status'
                  />
                )}
                {eventstatus && (
                  <ConstantCheckbox
                    mobile
                    constant={eventStatus}
                    label='Stato'
                    filterName='status'
                  />
                )}
                {send && (
                  <ConstantCheckbox
                    mobile
                    constant={sendStatus}
                    label='Stato'
                    filterName='status'
                  />
                )}
                {year && (
                  <ConstantCheckbox
                    mobile
                    constant={years}
                    label='Anno'
                    filterName='year'
                  />
                )}
                {lessonCategories && (
                  <ConstantCheckbox
                    mobile
                    constant={lessonCategory}
                    label='Categoria'
                    filterName='category'
                  />
                )}

                {filterCheckList.map(
                  (filter, index) =>
                    filter.visible && (
                      <div
                        className='flex items-center gap-4 px-4 py-6 border-t border-gray-200'
                        key={index}
                      >
                        <div className='relative flex items-start'>
                          <div className='flex items-center h-5'>
                            <input
                              id={filter.labelName}
                              aria-describedby='active-description'
                              name={filter.labelName}
                              type='checkbox'
                              onChange={onChange}
                              className='w-4 h-4 rounded-full focus:ring-primary text-primary border-primary'
                            />
                          </div>
                          <label
                            htmlFor={filter.labelName}
                            className='ml-3 text-sm font-normal text-gray-700'
                          >
                            {filter.label}
                          </label>
                        </div>
                      </div>
                    )
                )}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters desktop */}
      <section aria-labelledby='filter-heading'>
        <h2 id='filter-heading' className='sr-only'>
          Desktop filters
        </h2>

        <div className='relative z-10 pb-4 bg-white'>
          <div className='flex items-center justify-end w-full px-4 py-2'>
            <button
              type='button'
              className='inline-block text-sm font-medium text-gray-700 hover:text-gray-900 xl:hidden'
              onClick={() => setOpen(true)}
            >
              Filtri
            </button>

            <div className='hidden pr-4 xl:block'>
              <div className='flow-root'>
                <Popover.Group className='flex items-center -mx-4 divide-x divide-gray-200'>
                  {date && <SelectDate />}
                  {course && (
                    <FetchCheckbox
                      api={listCoursesFilter}
                      queryName='corsesCheckbox'
                      label='Corsi'
                      filterName='course'
                    />
                  )}
                  {event && (
                    <FetchCheckbox
                      api={listEventsFilter}
                      queryName='eventsCheckbox'
                      label='Eventi'
                      filterName='event'
                    />
                  )}
                  {section && (
                    <FetchCheckbox
                      api={sectionsAPIs.findOptions}
                      queryName='sectionCheckbox'
                      label='Sezioni'
                      filterName='section'
                    />
                  )}

                  {onavPosition && (
                    <FetchCheckbox
                      api={onavPositions}
                      queryName='onavPositionCheckbox'
                      label='Carica'
                      filterName='onavPosition'
                    />
                  )}
                  {profileCategory && (
                    <FetchCheckbox
                      api={profileCategories}
                      queryName='profileCategoryCheckbox'
                      label='Titolo'
                      filterName='profileCategory'
                    />
                  )}

                  {paymenMethods && (
                    <FetchCheckbox
                      api={paymentMode}
                      queryName='paymentModeCheckbox'
                      label='Metodo di pagamento'
                      filterName='paymentMode'
                    />
                  )}
                  {region && (
                    <FetchCheckbox
                      api={regions}
                      queryName='regionsCheckbox'
                      label='Regione'
                      filterName='regions'
                    />
                  )}
                  {status && (
                    <ConstantCheckbox
                      constant={courseStatus}
                      label='Stato'
                      filterName='status'
                    />
                  )}
                  {eventstatus && (
                    <ConstantCheckbox
                      constant={eventStatus}
                      label='Stato'
                      filterName='status'
                    />
                  )}
                  {send && (
                    <ConstantCheckbox
                      constant={sendStatus}
                      label='Stato'
                      filterName='status'
                    />
                  )}
                  {year && (
                    <ConstantCheckbox
                      constant={years}
                      label='Anno'
                      filterName='year'
                    />
                  )}
                  {lessonCategories && (
                    <ConstantCheckbox
                      constant={lessonCategory}
                      label='Categoria'
                      filterName='category'
                    />
                  )}

                  {filterCheckList.map(
                    (filter, index) =>
                      filter.visible && (
                        <div
                          className='relative flex items-start px-4'
                          key={index}
                        >
                          <div className='flex items-center h-5'>
                            <input
                              id={filter.labelName}
                              aria-describedby='active-description'
                              name={filter.labelName}
                              type='checkbox'
                              className='w-4 h-4 rounded-full focus:ring-primary text-primary border-primary'
                              checked={Boolean(
                                searchParams.get(filter.labelName)
                              )}
                              onClick={() => onChangeBoolean(filter.labelName)}
                            />
                          </div>
                          <label
                            htmlFor={filter.labelName}
                            className='ml-3 text-sm font-normal text-gray-700'
                          >
                            {filter.label}
                          </label>
                        </div>
                      )
                  )}
                  {searching && (
                    <div className='flex items-center w-48 pl-4'>
                      <input
                        type='text'
                        name='search'
                        id='search'
                        className='block w-full text-sm border-gray-300 rounded-full focus:ring-primary focus:border-primary bg-neutral-50'
                        placeholder='Cerca...'
                        value={search}
                        onChange={(e) => onChangeSearch(e.target.value)}
                      />
                      {search && (
                        <BackspaceIcon
                          className='w-5 h-5 -ml-8 text-gray-500 cursor-pointer'
                          aria-hidden='true'
                          onClick={() => {
                            searchParams.delete('search');
                            setSearch('');
                            setSearchParams(searchParams);
                          }}
                        />
                      )}
                    </div>
                  )}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Filtri applicati */}
        {!hideAppliedFilters && (
          <div className='bg-neutral-100'>
            <div className='px-4 py-3 xl:flex xl:items-center'>
              <h3 className='text-xs font-medium tracking-wide text-gray-500 uppercase'>
                Filtri applicati
              </h3>

              <div
                aria-hidden='true'
                className='hidden w-px h-5 bg-gray-300 xl:block xl:ml-4'
              />

              <div className='mt-2 xl:mt-0 xl:ml-4'>
                <div className='flex flex-wrap items-center -m-1'>
                  {filtersNames.map((filterName: any) =>
                    searchParams
                      .getAll(filterName)
                      .map((activeFilter: string) => (
                        <span
                          key={activeFilter}
                          className='m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-normal bg-white text-gray-900'
                        >
                          <span>{activeFilter} </span>
                          <button
                            type='button'
                            className='inline-flex flex-shrink-0 w-4 h-4 p-1 ml-1 text-gray-400 rounded-full hover:bg-gray-200 hover:text-gray-500'
                            onClick={() =>
                              deleteFilter(activeFilter, filterName)
                            }
                          >
                            <span className='sr-only'>
                              Remove filter for {activeFilter}
                            </span>
                            <svg
                              className='w-2 h-2'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 8 8'
                            >
                              <path
                                strokeLinecap='round'
                                strokeWidth='1.5'
                                d='M1 1l6 6m0-6L1 7'
                              />
                            </svg>
                          </button>
                        </span>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

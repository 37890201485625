import React from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { CoonstantCheckboxProps } from '../../../interfaces/fetchCheckbox';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import classNames from '../../../libs/utils/classNames';
import { useSearchParams } from 'react-router-dom';

const ConstantCheckbox = ({
  label,
  constant,
  mobile,
  filterName,
}: CoonstantCheckboxProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeCheck = (item: any) => {
    searchParams.delete('page');
    const exist = searchParams.getAll(filterName).includes(item);
    if (!exist) {
      searchParams.append(filterName, item);
      setSearchParams(searchParams);
    } else {
      const newFilter = [...searchParams].filter(
        ([key, value]) => key !== filterName || value !== item
      );
      setSearchParams(newFilter);
    }
  };

  const List = () => {
    return (
      <form className='space-y-4 max-h-[65vh] overflow-auto pt-1'>
        {constant.map((value) => (
          <div key={value.id} className='flex items-center pl-1 '>
            <input
              id={`${value.id}`}
              name={`${value.id}`}
              type='checkbox'
              checked={searchParams.getAll(filterName).includes(value.title)}
              className='w-4 h-4 border-gray-300 rounded cursor-pointer text-primary focus:ring-primary'
              onChange={() => onChangeCheck(value.title)}
            />
            <label
              htmlFor={`${value.id}`}
              className='pr-6 ml-3 text-sm font-normal text-gray-900 truncate cursor-pointer select-none whitespace-nowrap max-w-72'
            >
              {value.name || value.title}
            </label>
          </div>
        ))}
      </form>
    );
  };

  return mobile ? (
    <Disclosure
      as='div'
      key={label}
      className='px-4 py-6 pr-1 border-t border-gray-200'
    >
      {({ open }) => (
        <>
          <h3 className='flow-root -mx-2 -my-3'>
            <Disclosure.Button className='flex items-center justify-between w-full px-2 py-3 text-sm text-gray-400 bg-white'>
              <span className='font-normal text-gray-800'>{label}</span>
              <span className='flex items-center ml-6'>
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-5 w-5 transform'
                  )}
                  aria-hidden='true'
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className='pt-6'>
            <List />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Popover key='sections' className='relative inline-block px-4 text-left'>
      <Popover.Button className='inline-flex justify-center text-sm font-normal text-gray-700 group hover:text-gray-800'>
        <span>{label}</span>

        <ChevronDownIcon
          className='flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500'
          aria-hidden='true'
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='absolute right-0 p-4 pr-1 mt-2 origin-top-right bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <List />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default ConstantCheckbox;

import React from 'react';
import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { nations } from '../../api/nations';
import { FieldError } from 'react-hook-form';

const NationsSelect = ({
  defaultValue,
  control,
  name,
  required,
  legendIcons,
  error,
}: {
  defaultValue?: number;
  control?: any;
  name?: string;
  required?: boolean;
  legendIcons?: JSX.Element[];
  error?: FieldError;
}) => {
  const query = useQuery('nations', nations);

  return (
    <>
      {match(query)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          return (
            <SelectPresentational
              label='Nazione'
              name={name || 'nation'}
              defaultValue={defaultValue}
              control={control}
              options={query.data?.data.map((nation) => ({
                value: nation?.id,
                label: nation?.attributes?.name,
              }))}
              required={required}
              error={error}
              legendIcons={legendIcons}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default NationsSelect;

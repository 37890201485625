import axios from 'axios';
import qs from 'qs';
import { StrapiResponse, StrapiResponseList } from '../interfaces/commons';
import {
  BulkUpdateLessonsDataRequest,
  CourseLessonRequest,
  CourseLessonResponse,
} from '../interfaces/lesson';

const { REACT_APP_API_URL } = process.env;

const instance = axios.create();

export const getCourseLessons = async (id: string, token?: string | null) => {
  const query = { populate: '*', filters: { course: id } };
  const { data } = await axios.get<StrapiResponseList<CourseLessonResponse>>(
    `${REACT_APP_API_URL}/lessons?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const getLessons = async ({
  filters,
  populate = '*',
  token,
  searchParams,
  sort = ['order:asc'],
}: {
  filters: object;
  sort?: object;
  token: string | null;
  populate?: object | string;
  searchParams?: URLSearchParams;
}) => {
  const query = {
    populate,
    filters,
    sort,
    pagination: {
      page: searchParams?.get('page'),
      pageSize: searchParams?.get('pageSize') || 5,
    },
  };

  const { data } = await axios.get<StrapiResponseList<CourseLessonResponse>>(
    `${REACT_APP_API_URL}/lessons?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const getLesson = async ({
  id,
  token,
}: {
  id: string;
  token: string | null;
}) => {
  const query = {
    populate: [
      'slidePdf',
      'teacher',
      'availableTeacher',
      'lessonTemplate.availableTeacher',
      'userActions',
    ],
  };

  const { data } = await axios.get<StrapiResponse<CourseLessonResponse>>(
    `${REACT_APP_API_URL}/lessons/${id}?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const updateCourseLesson = async ({
  id,
  token,
  body,
  slidePdf,
}: {
  id: string;
  body: any;
  slidePdf?: File[];
  token: string | null;
}) => {
  const formData = new FormData();

  delete body.slidePdf;
  formData.append('data', JSON.stringify(body));

  if (slidePdf && slidePdf.length > 0)
    formData.append('files.slidePdf', slidePdf[0], slidePdf[0]?.name);

  const response = await axios.put<StrapiResponse<CourseLessonResponse>>(
    `${REACT_APP_API_URL}/lessons/${id}`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  if (axios.isAxiosError(response)) throw response;

  return response.data;
};

export const deleteCourseLesson = async ({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) => {
  const { data } = await axios.delete(`${REACT_APP_API_URL}/lessons/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export const createCourseLesson = async (
  body: CourseLessonRequest,
  token: string | null,
  slidePdf?: File[]
) => {
  const formData = new FormData();
  delete body.slidePdf;

  formData.append('data', JSON.stringify(body));

  if (slidePdf && slidePdf.length > 0)
    formData.append('files.slidePdf', slidePdf[0], slidePdf[0]?.name);

  const { data } = await instance.post<StrapiResponse<CourseLessonResponse>>(
    `${REACT_APP_API_URL}/lessons`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const downloadLessonsCSV = async ({
  token,
  body,
  query,
  fileName,
}: {
  token?: string | null;
  body: Object;
  query?: Object;
  fileName: string;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/lessons/csv?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const bulkUpdateLessons = async ({
  token,
  data,
}: {
  token: string | null;
  data: BulkUpdateLessonsDataRequest;
}) => {
  return await instance.patch(
    `${REACT_APP_API_URL}/lessons/bulk-update`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

import React from 'react';

// components
import TitlePage from '../../../../components/TitlePage';
import Underline from '../../../../components/TextLink/Underline';
import AddExistingLessonModal from '../../../../components/Modals/AddExistingLessonModal';
import LessonsListTemplateTable from '../../Tables/LessonsListTemplateTable';

// interfaces
import { CourseLessonTemplateResponse } from '../../../../interfaces/course-template-lessons';

// utils
import cloneDeep from 'lodash.clonedeep';

interface LezioniTemplateProps {
  relations: CourseLessonTemplateResponse[];
  setRelations: React.Dispatch<
    React.SetStateAction<CourseLessonTemplateResponse[]>
  >;
}

const LezioniTemplate: React.FC<LezioniTemplateProps> = ({
  relations,
  setRelations,
}) => {
  const moveLesson = (lessonId: number, direction: 1 | -1) => {
    const copy: CourseLessonTemplateResponse[] = cloneDeep(relations);

    const relationAidx = copy.findIndex(
      (r) => r.attributes.lessonTemplate.data.id === lessonId
    );

    const relationBidx = copy.findIndex((_, index) => {
      return index === relationAidx + direction;
    });

    if (relationAidx > -1 && relationBidx > -1) {
      copy[relationAidx].attributes.order += direction;
      copy[relationBidx].attributes.order -= direction;

      [copy[relationAidx], copy[relationBidx]] = [
        copy[relationBidx],
        copy[relationAidx],
      ];
      setRelations(copy);
    }
  };

  const up = (lessonId: number) => {
    moveLesson(lessonId, -1);
  };

  const down = (lessonId: number) => {
    moveLesson(lessonId, 1);
  };

  const selectedLessonTemplateIds = relations.map(
    (r) => r.attributes.lessonTemplate.data?.id
  );
  return (
    <>
      <TitlePage title='Template corsi | Lezioni' />

      <div>
        <div className='pt-6 pb-8'>
          <div className='mb-6 flex items-center justify-end gap-4'>
            <Underline
              href='/corsi/template/nuova-lezione'
              label='Crea nuovo template lezione'
            />
            <AddExistingLessonModal
              startFrom={relations.length}
              filterIds={selectedLessonTemplateIds}
            />
          </div>
          <LessonsListTemplateTable up={up} down={down} relations={relations} />
        </div>
      </div>
    </>
  );
};
export default LezioniTemplate;

import qs from 'qs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useAuth } from '../../../../contexts/Auth';
import UtenteCorsi from '../../Tables/UtenteCorsi';
import { useState } from 'react';
import { ParticipantsResponse } from '../../../../interfaces/participants';
import payments from '../../../../api/payments';
import { toast } from 'react-toastify';
import { FilterType } from '../../../../interfaces/filters';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import sectionsAPIs from '../../../../api/section';
import coursesAPIs from '../../../../api/courses';
import courseParticipants from '../../../../api/courseParticipants';
import Pagination from '../../../../components/Pagination';
import { PAYMENT_STATUSES_OPTIONS_SOURCE } from '../../../../interfaces/payments';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ModificaUtenteCorsi = () => {
  const [queryParams] = useSearchParams();
  const params = useParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);
  const [{ token }] = useAuth();
  const [checked, setChecked] = useState(false);
  const [selectedParticipants, setSelectedPartecipants] = useState<
    Partial<ParticipantsResponse & { multiple?: boolean }>[]
  >([]);
  const queryClient = useQueryClient();

  const participantsQuery = useQuery(
    ['getParticipants', ...debounceSearchParams],
    () => {
      return courseParticipants.find({
        token,
        populate: [
          'profile.orders.payment',
          'profile.orders.coupon',
          'profile.section',
          'profile.orders.course',
          'profile.badge',
          'course.section.nation',
          'course.details.otherInfos',
          'course.location',
          'course.profileCategory',
          'course.lessons',
          'course.headquarterExpense.estimateBalance',
          'course.headquarterExpense.finalBalance',
          'course.headquarterBalance.administrations.estimatedPriceUnit',
          'course.balance.estimateBalance',
          'course.balance.finalBalance',
          'profile.user',
          'profile.profileCategory',
          'evaluation',
        ],
        pagination: {
          page: debounceSearchParams?.get('page'),
          pageSize: debounceSearchParams?.get('pageSize'),
        },
        sort: 'course.startDate:desc',
        filters: {
          profile: {
            id: params.id,
          },
          $or: [
            {
              course: {
                $or: [
                  {
                    id: Object.entries(
                      qs.parse(queryParams.get('courses') + '')
                    )
                      .map(([, value]) => value)
                      .map((elem: any) => elem.value),
                  },
                  {
                    title: { $contains: debounceSearchParams.get('search') },
                  },
                  {
                    section: {
                      id: {
                        $in: Object.entries(
                          qs.parse(queryParams.get('sections') + '')
                        )
                          .map(([, value]) => value)
                          .map((elem: any) => elem.value),
                      },
                    },
                  },
                ],
                orders: {
                  payment: {
                    status: {
                      $in: Object.entries(
                        qs.parse(queryParams.get('status') + '')
                      )
                        .map(([, value]) => value)
                        .map((elem: any) => elem.value),
                    },
                  },
                  profile: params.id,
                },
              },
            },
            {
              profile: {
                $or: [
                  {
                    section: {
                      name: { $contains: debounceSearchParams.get('search') },
                    },
                  },
                  {
                    orders: {
                      payment: {
                        method: {
                          $contains: debounceSearchParams.get('search'),
                        },
                      },
                    },
                  },
                  {
                    orders: {
                      payment: {
                        amount: {
                          $contains: debounceSearchParams.get('search'),
                        },
                      },
                    },
                  },
                ],
              },
            },
          ],
          evaluation: {
            $notNull: debounceSearchParams.get('evaluation'),
          },
        },
      });
    }
  );

  const { mutate: mutatePaymentUpdate } = useMutation(
    'updatePayment',
    payments.update,
    {
      onSuccess: () => {
        participantsQuery.refetch();
        queryClient.invalidateQueries(['getParticipants']);
        toast.success(`Operazione conclusa con successo!`);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    }
  );

  const { mutate: mutateBulkUpdate, isLoading: isUpdatingPayment } =
    useMutation('bulkUpdateParticipants', courseParticipants.bulkUpdate, {
      onSuccess: () => {
        queryClient.invalidateQueries(['getParticipants']);
        setSelectedPartecipants([]);
        setChecked(false);
        toast.success('Operazione conclusa con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    });

  const onUpdateParticipant = (vote: string, cumLaude: boolean) => {
    mutateBulkUpdate({
      token,
      body: {
        participantsIds: selectedParticipants.map((elem) => elem.id || 0),
        data: { evaluation: vote, cumLaude: cumLaude },
      },
    });
  };

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setSelectedPartecipants(
      e.target.checked
        ? participantsQuery.data?.data.map((elem) => ({
            ...elem,
            multiple: true,
          })) || []
        : []
    );
  };

  return (
    <>
      <FiltersBar
        filters={[
          {
            type: FilterType.CHECKBOX,
            attribute: 'evaluation',
            label: 'Esame',
          },
          {
            type: FilterType.MULTISELECT,
            attribute: 'status',
            label: 'Stato pagamento',
            key: 'lessonStatusesList',
            source: () => ({
              data: PAYMENT_STATUSES_OPTIONS_SOURCE,
            }),
          },
          {
            type: FilterType.MULTISELECT,
            attribute: 'sections',
            label: 'Sezione',
            key: 'sectionsListFilters',
            source: (data: any) =>
              sectionsAPIs.findOptions({
                ...data,
                query: { ...data.query },
              }),
            searchForAttributes: ['name'],
          },
          {
            type: FilterType.MULTISELECT,
            attribute: 'courses',
            label: 'Corsi',
            key: 'userCoursesFilters',
            searchForAttributes: ['title'],
            source: coursesAPIs.filtersFind,
          },
          {
            type: FilterType.SEARCH_BAR,
            attribute: 'search',
            label: 'Cerca...',
          },
        ]}
      />
      <UtenteCorsi
        participantsQuery={participantsQuery}
        onUpdateParticipant={onUpdateParticipant}
        selectedParticipants={selectedParticipants}
        setSelectedParticipants={setSelectedPartecipants}
        isLoading={isUpdatingPayment}
        checked={checked}
        onCheckAll={onCheckAll}
        onUpdatePayment={(data) =>
          mutatePaymentUpdate({ token, id: data.id, body: data.payment })
        }
        searchWords={[
          debounceSearchParams.get('search') + '',
          ...Object.entries(qs.parse(queryParams.get('status') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.label),
          ...Object.entries(qs.parse(queryParams.get('region') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.label),
        ]}
      />
      <Pagination pagination={participantsQuery?.data?.meta?.pagination} />
    </>
  );
};

export default ModificaUtenteCorsi;

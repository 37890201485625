import React from 'react';
import classNames from '../../libs/utils/classNames';

interface IconButtonProps {
  onClick: any;
  small?: boolean;
  redTxt?: boolean;
  orangeTxt?: boolean;
  cancelAction?: boolean;
  children: any;
  tealTxt?: boolean;
  type?: 'submit' | 'button';
  disabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  onClick,
  redTxt,
  orangeTxt,
  cancelAction,
  tealTxt,
  type = 'button',
  disabled,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        `font-normal flex items-center justify-center text-center whitespace-nowrap ${
          disabled ? 'cursor-not-allowed' : 'hover:opacity-80'
        }text-base rounded-full w-max`,

        redTxt ? 'text-red-700' : '',
        orangeTxt ? 'text-orange-400' : '',
        cancelAction ? 'bg-white text-red-700 p-0.5' : '',
        tealTxt ? 'text-teal-600' : '',
        disabled ? 'opacity-60 cursor-not-allowed' : ''
      )}
    >
      {children}
    </button>
  );
};

export default IconButton;

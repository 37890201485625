import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import InputPresentational from './Form/InputPresentational';
import PriceInputPresentational from './Form/PriceInputPresentational';
import H4SpacingLetter from './Typography/H4SpacingLetter';
import { CourseStatus } from '../interfaces/commons';
import { isDelegato } from '../libs/utils/auth';
import { useAuth } from '../contexts/Auth';
import { toast } from 'react-toastify';
import { checkProfile } from '../libs/utils/helpers';
import RegisterCourseSupervisorPaymentModal from './Modals/RegisterCourseSupervisorPaymentModal';
import RegisterCourseTreasurerPaymentModal from './Modals/RegisterCourseTreasurerPaymentModal';

const FormDirezione = () => {
  const { register, getValues, watch, setValue } = useFormContext();

  const [{ profile, token }] = useAuth();

  const isCourseCanceled = useMemo(
    () => getValues('status') === CourseStatus.CANCELED,
    [getValues('status')]
  );
  const canDelegatoEdit =
    watch('canDelegateEdit') && !watch('disableSecondLevel');

  const onChangeTreasurerAmount = (value: number) => {
    const limit = Number(watch('treasurerAmountLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('treasurerAmount', limit);
      toast.error("L'importo consuntivo non può superare l'importo preventivo");
      return;
    } else if (isDelegato(profile) && Number(value) <= limit) {
      setValue('treasurerAmount', value);
    }

    if (!isDelegato(profile)) setValue('treasurerAmount', value);
  };

  const onChangeSupervisorAmount = (value: number) => {
    const limit = Number(watch('supervisorAmountLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('supervisorAmount', limit);
      toast.error("L'importo consuntivo non può superare l'importo preventivo");
      return;
    } else if (isDelegato(profile) && Number(value) <= limit) {
      setValue('supervisorAmount', value);
    }

    if (!isDelegato(profile)) setValue('supervisorAmount', value);
  };

  return (
    <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-4 mt-6'>
      <div className='px-3 py-4 bg-neutral-100 rounded-md'>
        <H4SpacingLetter>Tesoriere</H4SpacingLetter>

        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <div className='xl:col-span-2'>
            <InputPresentational
              id='treasurerName'
              label='Nome e Cognome'
              name='treasurerName'
              type='text'
              register={register}
              disabled={
                isCourseCanceled || checkProfile(profile, canDelegatoEdit)
              }
            />
          </div>

          <PriceInputPresentational
            id='treasurerAmount'
            label='Preventivo'
            name='treasurerAmount'
            type='number'
            disabled={
              isCourseCanceled || checkProfile(profile, canDelegatoEdit)
            }
            value={watch('treasurerAmount')}
            onChange={(e) => onChangeTreasurerAmount(Number(e.target.value))}
          />
        </div>
        {watch('treasurerAmountPaid') || watch('treasurerAmountPaid') === 0 ? (
          <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <InputPresentational
              id='treasurerPaymentDate'
              label='Data pagamento'
              name='treasurerPaymentDate'
              type='date'
              defaultValue={watch('treasurerPaymentDate')}
              disabled
            />
            <PriceInputPresentational
              id='treasurerAmount'
              label='Somma pagata'
              name='treasurerAmount'
              type='number'
              disabled
              defaultValue={watch('treasurerAmountPaid')}
            />
          </div>
        ) : (
          <></>
        )}
        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <RegisterCourseTreasurerPaymentModal course={getValues()} />
        </div>
      </div>

      <div className='px-3 py-4 bg-neutral-100 rounded-md'>
        <H4SpacingLetter>Responsabile</H4SpacingLetter>

        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <div className='xl:col-span-2'>
            <InputPresentational
              id='supervisorName'
              label='Nome e Cognome'
              name='supervisorName'
              type='text'
              disabled={
                isCourseCanceled || checkProfile(profile, canDelegatoEdit)
              }
              register={register}
            />
          </div>

          <PriceInputPresentational
            id='supervisorAmount'
            label='Preventivo'
            name='supervisorAmount'
            type='number'
            disabled={
              isCourseCanceled || checkProfile(profile, canDelegatoEdit)
            }
            value={watch('supervisorAmount')}
            onChange={(e) => onChangeSupervisorAmount(Number(e.target.value))}
          />
        </div>
        {watch('supervisorAmountPaid') ||
        watch('supervisorAmountPaid') === 0 ? (
          <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <InputPresentational
              id='supervisorPaymentDate'
              label='Data pagamento'
              name='supervisorPaymentDate'
              type='date'
              defaultValue={watch('supervisorPaymentDate')}
              disabled
            />
            <PriceInputPresentational
              id='supervisorAmount'
              label='Somma pagata'
              name='supervisorAmount'
              type='number'
              disabled
              defaultValue={watch('supervisorAmountPaid')}
            />
          </div>
        ) : (
          <></>
        )}
        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <RegisterCourseSupervisorPaymentModal course={getValues()} />
        </div>
      </div>
    </div>
  );
};

export default FormDirezione;

import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { fetchEventsForCoupon } from '../../api/events';
import { SearchableSelect } from '../Form/SearchableSelect';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import FullPageSpinner from '../Layout/Loading/FullPageSpinner';
import { useAuth } from '../../contexts/Auth';

const EventSelect = ({
  defaultValue,
  control,
  name,
}: {
  defaultValue?: number | string;
  control?: any;
  name?: string;
}) => {
  const [search, setSearch] = useState<string>('');
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const [debouncedSearch] = useDebounce(search, 500);
  const [{ token }] = useAuth();

  const query = useQuery(
    ['eventsCoupon', debouncedSearch],
    () => {
      const keywords = debouncedSearch
        ?.trim()
        ?.split(' ')
        ?.filter((keyword) => keyword !== '');

      const searchFilters = keywords?.map((keyword) => {
        return {
          $or: [{ title: { $contains: keyword } }],
        };
      });

      return fetchEventsForCoupon({
        query: {
          filters: {
            $and: [
              ...searchFilters,
              { $or: [{ status: 'published' }, { status: 'draft' }] },
            ],
          },
        },
        token,
      });
    },
    {
      onSuccess: () => {
        setFirstRender(true);
      },
    }
  );

  if (query.isFetching && !firstRender) {
    return <FullPageSpinner />;
  }

  return (
    <SearchableSelect
      label="Seleziona l'evento"
      name={name || 'event'}
      onInput={setSearch}
      control={control}
      defaultValue={defaultValue}
      isLoading={query.isFetching}
      options={match(query)
        .with(
          { status: 'error' },
          { status: 'idle' },
          { status: 'loading' },
          () => []
        )
        .with({ status: 'success' }, (query) => {
          const options = query?.data?.data.map((event) => ({
            value: event.id,
            label: event.attributes.title,
          }));
          return options || [];
        })
        .exhaustive()}
    />
  );
};

export default EventSelect;

import TitlePage from '../../../../components/TitlePage';
import FormModificaCoupon from '../../../../components/FormModificaCoupon';
import { Coupon } from '../../../../interfaces/coupon';

export default function DettaglioCoupon({
  couponData,
  checked,
  setChecked,
}: {
  couponData: Coupon;
  checked: string;
  setChecked: any;
}) {
  return (
    <>
      <TitlePage title='Modifica Coupon' />
      <FormModificaCoupon
        couponData={couponData}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
}

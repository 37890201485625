import moment, { Moment } from 'moment';

export const formatDate = (
  date: Date | Moment | string | undefined | null,
  formatPattern?: string
) => {
  const pattern = formatPattern || 'YYYY-MM-DD HH:mm';
  if (!date) return moment().format(pattern);
  return moment(date || new Date().toDateString())
    .format(pattern);
};

import TitlePage from '../../../../components/TitlePage';
import Underline from '../../../../components/TextLink/Underline';
import AddExistingLessonModal from '../../../../components/Modals/AddExistingLessonModal';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

interface LezioniTemplateProps {}

const LezioniTemplate: React.FC<LezioniTemplateProps> = () => {
  const { setValue, watch } = useFormContext();

  const [selectedLessons, setSelectedLessons] = useState<number[]>(
    watch('lessonTemplates')
  );

  useEffect(() => {
    setValue('lessonTemplates', selectedLessons);
  }, [selectedLessons]);
  return (
    <>
      <TitlePage title='Template corsi | Lezioni' />

      <div>
        <div className='pt-6 pb-8'>
          <div className='mb-6 flex items-center justify-end gap-4'>
            <Underline
              href='/corsi/template/nuova-lezione'
              label='Crea nuovo template lezione'
            />
            <AddExistingLessonModal />
            {/* <UnderlineButton onClick={() => {}}>
              <DownloadIcon className='w-4 h-4' /> Scarica CSV lezioni
            </UnderlineButton> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default LezioniTemplate;

import { UseFormReturn } from 'react-hook-form';
import FormDatiPrincipaliEvento from '../../../../components/FormDatiPrincipaliEvento';
import TitlePage from '../../../../components/TitlePage';

interface DatiPrincipaliProps {
  onImageDelete?: (fileId?: number) => void;
  onCarouselImageDelete?: (index: number) => void;
  eventMethods?: UseFormReturn<any, any>;
}

export default function DatiPrincipaliEvento({
  onImageDelete,
  onCarouselImageDelete,
  eventMethods,
}: DatiPrincipaliProps) {
  return (
    <>
      <TitlePage title='Crea nuovo evento | Dati principali' />

      <form>
        <FormDatiPrincipaliEvento
          onImageDelete={onImageDelete}
          onCarouselImageDelete={onCarouselImageDelete}
          eventMethods={eventMethods}
        />
      </form>
    </>
  );
}

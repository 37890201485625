import TitlePage from '../../../../components/TitlePage';
import PrimaryLinkFull from '../../../../components/TextLink/PrimaryLinkFull';
import * as Yup from 'yup';
import ReceiptForm from '../../../../components/ReceiptForm';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { useForm } from 'react-hook-form';
import { ReceiptRequest } from '../../../../interfaces/receipts';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../../contexts/Auth';
import { useNavigate, useParams } from 'react-router';
import receipts from '../../../../api/receipts';
import { toast } from 'react-toastify';
import moment from 'moment';
import { match } from 'ts-pattern';
import FormLoading from '../../../../components/Layout/Loading/FormLoading';
import { receiptsValidator } from '../../../../validators/receipts';
import { sanitizeCurrencyValue } from '../../../../libs/utils/helpers';
import _ from 'lodash';

const UpdateReceipt: React.FC = () => {
  const [{ token }] = useAuth();
  const { id: profileId, receiptId } = useParams();
  const navigate = useNavigate();

  const receiptQuery = useQuery(
    'getReceipt',
    () =>
      receipts.findOne({
        token: token!,
        query: { populate: '*' },
        id: receiptId!.toString(),
      }),
    {
      onSuccess: (data) => {
        receiptForm.reset({
          badgeNumber: data.data.attributes.badgeNumber,
          code: data.data.attributes.code,
          date: data.data.attributes.date,
          description: data.data.attributes.description,
          entries: data.data.attributes.entries || [],
          fiscalCode: data.data.attributes.fiscalCode,
          paymentMethod: data.data.attributes.paymentMethod,
          section: data.data.attributes.section.data.id,
          type: data.data.attributes.type,
          notes: data.data.attributes.notes,
          profile: {
            value: data.data?.attributes?.profile?.data.id,
            label: `${data.data?.attributes?.profile?.data?.attributes?.surname}`,
          },
        });
      },
    }
  );

  const receiptForm = useForm<ReceiptRequest, ReceiptRequest>({
    resolver: useYupValidationResolver(receiptsValidator),
    defaultValues: { entries: [], date: moment().format('YYYY-MM-DD') },
  });

  const { mutate: mutateUpdateReceipt } = useMutation(
    'updateReceipt',
    receipts.update,
    {
      onSuccess: (data) => {
        toast.success('Ricevuta modificata con successo');
        navigate(`/utenti/modifica/${profileId}/ricevute`);
      },
      onError: (err: any) => {
        toast.error(err.response.data.error.message);
      },
    }
  );

  const { handleSubmit } = receiptForm;

  const onCancel = () => {
    receiptForm.reset({});
    navigate(-1);
  };

  return match(receiptQuery)
    .with({ status: 'idle' }, { status: 'loading' }, () => <FormLoading />)
    .with({ status: 'error' }, () => <div>Errore...</div>)
    .with({ status: 'success' }, (query) => {
      return (
        <>
          <TitlePage title='Dettaglio corso | Modifica ricevuta' />

          <div className='mt-6'>
            <div className='my-6'>
              <h5 className='text-lg text-gray-800'>Modifica ricevuta</h5>

              <ReceiptForm receiptForm={receiptForm} treasury={false} />
            </div>
            <form
              onSubmit={handleSubmit((values) =>
                mutateUpdateReceipt({
                  token,
                  body: {
                    ..._.omit(values, ['date']),
                    profile: values.profile?.value as any,
                    entries: values.entries.map((_entry) => ({
                      ..._entry,
                      amount: sanitizeCurrencyValue(_entry.amount),
                    })),
                  },
                  receiptId: query.data.data.id,
                })
              )}
            >
              <div className='flex items-center justify-start gap-4'>
                <PrimaryLinkFull onClick={onCancel} outline>
                  Annulla
                </PrimaryLinkFull>

                <PrimaryButton type='submit'>Modifica ricevuta</PrimaryButton>
              </div>
            </form>
          </div>
        </>
      );
    })
    .exhaustive();
};

export default UpdateReceipt;

import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { useQuery } from 'react-query';
import TitlePage from '../../components/TitlePage';
import TrainingOfferCard from '../../components/TrainingOfferCard';
import H1Styled from '../../components/Typography/H1Styled';
import Pagination from '../../components/Pagination';
import { match } from 'ts-pattern';
import Spinner from '../../components/Layout/Loading/Spinner';
import { getCourseTemplates } from '../../api/course-templates';
import { fromResponseToRequest } from '../../libs/utils/formatters/courseTemplateFormatters';
import { me } from '../../api/login';
import { useDebounce } from 'use-debounce';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function NuovoCorso() {
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const query = useQuery(['course-templates', ...debounceSearchParams], () =>
    getCourseTemplates(debounceSearchParams, token)
  );

  return (
    <>
      <TitlePage title='Crea nuovo corso' />

      <H1Styled>Crea nuovo corso da template</H1Styled>

      <div className='my-12 grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <div className='w-full flex items-center justify-center'>
              <Spinner />
            </div>
          ))
          .with({ status: 'error' }, () => <div>Errore...</div>)
          .with({ status: 'success' }, ({ data }) => {
            return (
              <>
                {data.data.map((offer, i) => {
                  return (
                    <TrainingOfferCard
                      key={i}
                      trainingOffer={fromResponseToRequest(offer)}
                      courseTemplateId={offer.id}
                    />
                  );
                })}
              </>
            );
          })
          .exhaustive()}
      </div>
      <Pagination
        pagination={query.data?.meta.pagination}
        defaultPageSize={9}
      />
    </>
  );
}

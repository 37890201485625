import axios from "axios";
import qs from "qs";
import { RegionListResponse } from "../interfaces/region";

const { REACT_APP_API_URL } = process.env;

export const regions = async () => {
  const query = {
    populate: "*",
    pagination: {
      pageSize: 100,
    },
  };

  const { data } = await axios.get<RegionListResponse>(
    `${REACT_APP_API_URL}/regions?${qs.stringify(query)}`
  );
  return data;
};

import { useFormContext } from 'react-hook-form';
import OnavPositionsSelect from '../../../../components/FetchSelect/OnavPosizionsSelect';
import RoleSelect from '../../../../components/FetchSelect/RoleSelection';
import SectionsSelect from '../../../../components/FetchSelect/SectionsSelect';
import SimpleCheckboxPresentational from '../../../../components/Form/SimpleCheckboxPresentational';
import SectionsModal from '../../../../components/SectionsModal';
import H3Styled from '../../../../components/Typography/H3Styled';
import UserBooleanBox from '../../../../components/UserBooleanBox';
import { useAuth } from '../../../../contexts/Auth';
import { ProfileRequest } from '../../../../interfaces/profile';
import { isDelegato } from '../../../../libs/utils/auth';

const NuovoUtenteDatiOnav = () => {
  const [{ profile }] = useAuth();
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ProfileRequest>();
  return (
    <>
      <div className='mt-12'>
        <H3Styled>Dati ONAV utente</H3Styled>
        <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mb-12 items-start'>
          <div className='grid gap-6'>
            <RoleSelect
              disabled={isDelegato(profile)}
              control={control}
              defaultValue={'Authenticated'}
            />
            <OnavPositionsSelect
              disabled={isDelegato(profile)}
              control={control}
            />
          </div>
          <UserBooleanBox setValueForm={setValue} />
        </div>

        <H3Styled>Digital ONAV</H3Styled>
        <div className='bg-neutral-50 p-8 rounded-lg space-y-4 height-auto text-gray-600 my-6 mb-14 w-full md:w-1/2'>
          <fieldset className='space-y-5'>
            <div className='flex items-center'>
              <SimpleCheckboxPresentational
                id='printDigitalBadge'
                label={`Tessera SOLO digitale`}
                name='printDigitalBadge'
                register={register}
              />
            </div>

            <SimpleCheckboxPresentational
              id='printDigitalMagazine'
              label={`Rivista SOLO digitale`}
              name='printDigitalMagazine'
              register={register}
            />
          </fieldset>
        </div>

        <H3Styled>Sezione di appartenenza</H3Styled>
        <div className='pt-6 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 pb-60'>
          <div className='grid gap-x-8 gap-y-6'>
            <SectionsSelect
              label='Sezione di appartenenza'
              control={control}
              required
              error={{ ...errors.section, type: 'required' }}
            />
          </div>
          <div>
            <label className='block text-sm text-gray-500'>
              Altre sezioni seguite
            </label>

            <SectionsModal setValueForm={setValue} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NuovoUtenteDatiOnav;

import { XIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import IconButton from '../Buttons/IconButton';
import { Media, MediaAttributes } from '../../interfaces/media';
import { getMediaUrl } from '../../libs/utils/media';

interface ImageUploadPreviewProps {
  imageSource?: File | File[] | Media;
  fieldName?: string;
  onRemove?: () => void;
  disabled?: boolean;
}

const ImageUploadPreview: React.FC<ImageUploadPreviewProps> = ({
  imageSource,
  fieldName = '',
  onRemove = () => {},
  disabled = false,
}) => {
  if (!imageSource) {
    return null;
  }

  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    let sources: (File | Media)[];

    if (Array.isArray(imageSource)) {
      sources = imageSource;
    } else {
      sources = [imageSource];
    }

    sources.forEach((source) => {
      if (source instanceof File) {
        const url = URL.createObjectURL(source);
        setImageUrl(url);
      } else if (
        typeof source.attributes === 'object' &&
        'url' in source.attributes
      ) {
        const url = getMediaUrl(source.attributes.url);
        setImageUrl(url);
      }
    });
  }, [imageSource]);

  const handleRemoveClick = () => {
    if (!disabled) {
      onRemove();
    } else {
      return;
    }
  };

  return (
    <div className='relative'>
      <div className='relative'>
        <img src={imageUrl} alt={fieldName} className='w-full h-auto rounded' />
      </div>
      <div className='absolute top-0 right-0 flex items-center justify-center mt-2 mr-2'>
        <IconButton redTxt onClick={handleRemoveClick}>
          <XIcon className='w-5 h-5 bg-white' />
        </IconButton>
      </div>
    </div>
  );
};

export default ImageUploadPreview;

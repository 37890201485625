import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import classNames from '../../libs/utils/classNames';

interface ShowMoreProps {
  href: string;
  label?: string;
  right?: boolean;
}

const ShowMore: React.FC<ShowMoreProps> = ({
  label = 'Leggi di più',
  href,
  right,
}) => {
  return (
    <Link to={href}>
      <div className='text-base flex items-center gap-2 w-max whitespace-nowrap text-gray-700 font-medium hover:text-primary'>
        <ChevronRightIcon
          className={classNames('w-5 h-5', right ? 'order-2' : '')}
        />
        {label}
      </div>
    </Link>
  );
};

export default ShowMore;

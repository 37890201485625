import { IOption } from '../components/Form/Multiselect';
import { StrapiResponseList } from './commons';
import { Order } from './orders';
import { Payment, PaymentMethod } from './payments';
import { Profile } from './profile';
import { Section } from './section';

export enum ReceiptCausal {
  QUOTA_ASSOCIATIVA = 'QUOTA_ASSOCIATIVA',
  RINNOVO_QUOTA_ASSOCIATIVA = 'RINNOVO_QUOTA_ASSOCIATIVA',
  QUOTA_ISCRIZIONE = 'SPESA_AMMINISTRATIVA',
  QUOTA_CORSO = 'QUOTA_CORSO',
  QUOTA_EVENTO = 'QUOTA_EVENTO',
  MATERIALE_DIDATTICO = 'MATERIALE_DIDATTICO',
  GADGET_ONAV = 'GADGET_ONAV',
  CUSTOM = 'CUSTOM',
  MATERIALE_ONAV = 'MATERIALE_ONAV',
  INTEGRAZIONE_WINE_CONNOISSEUR = 'INTEGRAZIONE_WINE_CONNOISSEUR',
  PROVENTI_VARI = 'PROVENTI_VARI',
  CESSIONE_AVANZO_GIACENZE = 'CESSIONE_AVANZO_GIACENZE',
  EROGAZIONE_LIBERALE_A_FAVORE_ATTIVITA_ISTITUZIONALE = 'EROGAZIONE_LIBERALE_A_FAVORE_ATTIVITA_ISTITUZIONALE',
}
export interface ReceiptRequest {
  code?: string | null;
  date?: string | null;
  section?: number | null;
  type?: string | null;
  paymentMethod?: PaymentMethod | null;
  badgeNumber?: string | null;
  fiscalCode?: string | null;
  description?: string | null;
  entries: ReceiptEntry[];
  notes?: string | null;
  isEvent?: boolean;
  isCourse?: boolean;
  payments?: string[] | null;
  orders?: string[] | null;
  profile?: IOption | null;
}

export interface ReceiptEntry {
  amount: number | null;
  causal: ReceiptCausal | null;
  costCenter?: CostCenter | null;
  customLabel?: string;
}

export enum CostCenter {
  NATIONAL = 'NATIONAL',
  DELEGATION = 'DELEGATION',
}

export enum ReceiptType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  COURSE = 'COURSE',
  EVENT = 'EVENT',
}

export interface ReceiptAttributes {
  code: string | null;
  description: string | null;
  entries: ReceiptEntry[];
  notes: string | null;
  date: string | null;
  paymentMethod: PaymentMethod | null;
  fiscalCode: string | null;
  badgeNumber: string | null;
  type: string | null;
  section: { data: Section } | number | null;
  payments: { data: { attributes: Payment; id: number }[] };
  profile: IOption | null;
  orders?: StrapiResponseList<{ attributes: Order; id: number }>;
}

export interface ReceiptResponse {
  id: number;
  attributes: ReceiptAttributes & {
    profile?: { data: Profile };
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    causal: string | null;
    section?: { data: Section };
    orders?: { data: Order[]} 
  };
}

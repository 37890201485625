import UtenteRicevuteTable from '../../Tables/UtenteRicevute';
import { useAuth } from '../../../../contexts/Auth';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import receipts from '../../../../api/receipts';
import qs from 'qs';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import { FilterType } from '../../../../interfaces/filters';
import Pagination from '../../../../components/Pagination';
import { getDateTimeFromDate } from '../../../../libs/utils/helpers';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ModificaUtenteRicevute = () => {
  const [{ token }] = useAuth();
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);

  const receiptsQuery = useQuery(
    ['userReceipts', ...debounceSearchParams],
    () =>
      receipts.find({
        token,
        query: {
          filters: {
            profile: id,
            paymentMethod: {
              $in: Object.entries(
                qs.parse(queryParams.get('paymentMethod') + '')
              )
                .map(([, value]) => value)
                .map((elem: any) => elem.value),
            },
            $and: [
              {
                date: {
                  $gte: qs.parse(
                    Object.entries(
                      qs.parse(debounceSearchParams.get('date') + '')
                    ).map(([, value]) => value as any)[0]?.label
                  ).from,
                  $lte: getDateTimeFromDate(
                    qs.parse(
                      Object.entries(
                        qs.parse(debounceSearchParams.get('date') + '')
                      ).map(([, value]) => value as any)[0]?.label
                    ).to
                  ),
                },
              },
            ],
            $or: [
              {
                entries: {
                  $or: [
                    {
                      causal: { $contains: debounceSearchParams.get('search') },
                    },
                    {
                      amount: { $contains: debounceSearchParams.get('search') },
                    },
                  ],
                },
              },
              {
                section: {
                  $or: [
                    {
                      name: { $contains: debounceSearchParams.get('search') },
                    },
                  ],
                },
              },

              {
                $or: [
                  { name: { $contains: debounceSearchParams.get('search') } },
                  { code: { $contains: debounceSearchParams.get('search') } },
                  {
                    notes: { $contains: debounceSearchParams.get('search') },
                  },
                  {
                    description: {
                      $contains: debounceSearchParams.get('search'),
                    },
                  },

                  {
                    fiscalCode: {
                      $contains: debounceSearchParams.get('search'),
                    },
                  },
                  {
                    badgeNumber: {
                      $contains: debounceSearchParams.get('search'),
                    },
                  },
                  { type: { $contains: debounceSearchParams.get('search') } },
                ],
              },
            ],
          },
          sort: { name: 'asc' },
          populate: [
            'entries.causal',
            'entries.amount',
            'section',
            'date',
            'paymentMethod',
            'fiscalCode',
            'badgeNumber',
            'name',
            'type',
            'code',
            'description',
            'profile.orders.payment',
            'payments',
            'orders',
            'profile.orders.payment',
            'section',
          ],
          pagination: {
            page: debounceSearchParams.get('page') || 1,
            pageSize: debounceSearchParams.get('pageSize'),
          },
        },
      })
  );

  return (
    <>
      <FiltersBar
        filters={[
          { type: FilterType.DATE, label: 'Data', attribute: 'date' },
          {
            type: FilterType.MULTISELECT,
            attribute: 'paymentMethod',
            label: 'Metodo di pagamento',
            key: 'paymentMethodReceipts',
            source: () => ({
              data: [
                {
                  id: 'BANK_TRANSFER',
                  attributes: { name: 'Bonifico bancario' },
                },
                { id: 'CARD', attributes: { name: 'Carta' } },
                { id: 'CASH', attributes: { name: 'Contanti' } },
              ],
            }),
          },
          {
            attribute: 'search',
            type: FilterType.SEARCH_BAR,
            label: 'Cerca...',
          },
        ]}
      />
      {/* <div className='w-full flex justify-end'>
        <UnderlineButton onClick={() => {}}>
          <DownloadIcon className='w-4 h-4' /> Scarica CSV
        </UnderlineButton>
      </div> */}
      <UtenteRicevuteTable
        searchingWords={[
          debounceSearchParams.get('search') + '',
          ...Object.entries(qs.parse(queryParams.get('paymentMethod') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.label),
        ]}
        receiptsQuery={receiptsQuery}
      />
      <Pagination pagination={receiptsQuery?.data?.meta?.pagination} />
    </>
  );
};

export default ModificaUtenteRicevute;

import TitlePage from '../../../../components/TitlePage';
import FormModificaComunicato from '../../../../components/FormModificaComunicato';
import { Announcment } from '../../../../interfaces/announcments';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  announcmentData: Announcment;
  onFilesDelete?: (fileId?: number) => void;
  formMethods?: UseFormReturn<any, any>;
}

export default function DettaglioComunicato({
  announcmentData,
  onFilesDelete,
  formMethods,
}: Props) {
  return (
    <>
      <TitlePage title='Modifica Comunicato' />
      <FormModificaComunicato
        announcmentData={announcmentData}
        formMethods={formMethods}
        onFilesDelete={onFilesDelete}
      />
    </>
  );
}

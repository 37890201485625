import React from "react";

const CheckboxLoading = () => {
  return (
    <div className="mr-4 space-y-2 w-72">
      <div className=" flex-1 space-y-2 animate-pulse h-6 bg-gray-200 rounded pr-10 w-2/3"></div>
      <div className=" flex-1 space-y-2 animate-pulse h-6 bg-gray-200 rounded pr-10 w-3/3"></div>
      <div className=" flex-1 space-y-2 animate-pulse h-6 bg-gray-200 rounded pr-10 w-3/5"></div>
      <div className=" flex-1 space-y-2 animate-pulse h-6 bg-gray-200 rounded pr-10 w-5/6"></div>
      <div className=" flex-1 space-y-2 animate-pulse h-6 bg-gray-200 rounded pr-10 w-2/3"></div>
    </div>
  );
};

export default CheckboxLoading;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRouteOnlySecretary from '../../components/Auth/PrivateRouteOnlySecretary';
import PageLayout from '../../components/Layout/PageLayout';
import Sidebar from '../../components/Sidebar';
import { useAuth } from '../../contexts/Auth';
import { isSegreteriaNazionale } from '../../libs/utils/auth';
import DettaglioCorso from './DettaglioCorso';
import NuovoCorso from './nuovo-corso';
import NuovoCorsoDaTemplate from './NuovoCorsoDaTemplate';
import ListaTemplateCorsi from './Template/lista-template';
import ListaTemplateLezioni from './Template/ListaLezioni';
import ModificaTemplateLezione from './Template/ListaLezioni/modifica-lezione';
import NuovoTemplateLezione from './Template/ListaLezioni/nuova-lezione';
import ModificaTemplateCorsi from './Template/ModificaTemplate';
import NuovoTemplateCorsi from './Template/NuovoTemplate';
import ListaCorsi from './tutti-corsi';

export default function Corsi() {
  const [{ profile }] = useAuth();

  const SIDEBAR_ITEMS = [
    { href: '/corsi', label: 'Lista corsi' },
    { href: '/corsi/nuovo-corso', label: 'Crea nuovo corso' },
  ];

  const TEMPLATE_ITEMS = [
    { href: '/corsi/template/lista-lezioni', label: 'Template lezioni' },
    {
      href: '/corsi/template/nuova-lezione',
      label: 'Crea nuovo template lezione',
    },
    { href: '/corsi/template/lista-corsi', label: 'Template corsi' },
    {
      href: '/corsi/template/nuovo-corso/dati-principali',
      label: 'Crea nuovo template corso',
    },
  ];

  return (
    <>
      <div className='pt-7 pb-10 lg:py-12 px-4 lg:px-10 w-full grid grid-cols-12 gap-8 '>
        <Sidebar
          items={SIDEBAR_ITEMS}
          secondMenuItems={
            isSegreteriaNazionale(profile) ? TEMPLATE_ITEMS : undefined
          }
          secondMenuTitle='Template'
        />
        <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
          <Routes>
            <Route index element={<ListaCorsi />} />
            <Route path='dettaglio-corso/:id/*' element={<DettaglioCorso />} />
            <Route path='nuovo-corso' element={<NuovoCorso />} />
            <Route
              path='nuovo-corso/:id/*'
              element={<NuovoCorsoDaTemplate />}
            />
            <Route
              element={<PrivateRouteOnlySecretary element={<PageLayout />} />}
            >
              <Route path='template'>
                <Route
                  path='lista-lezioni'
                  element={<ListaTemplateLezioni />}
                />
                <Route
                  path={`lista-lezioni/:id/modifica-lezione`}
                  element={<ModificaTemplateLezione />}
                />
                <Route
                  path='nuova-lezione'
                  element={<NuovoTemplateLezione />}
                />
                <Route path='lista-corsi' element={<ListaTemplateCorsi />} />
                <Route
                  path='lista-corsi/:id/*'
                  element={<ModificaTemplateCorsi />}
                />
                <Route path='nuovo-corso/*' element={<NuovoTemplateCorsi />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { StrapiResponseList } from '../../../interfaces/commons';
import { CourseResponse } from '../../../interfaces/courses';
import { formatCurrency } from '../../../libs/utils/formatters/currency';
import moment from 'moment';

interface StatisticsCourseProps {
  query: UseQueryResult<StrapiResponseList<CourseResponse>>;
}
const StatisticsCourse: React.FC<StatisticsCourseProps> = ({ query }) => {
  const tableElements = [
    'Data',
    'Corso',
    'Sezione',
    'Quote per associazione',
    'Entrate preventivo',
    'Uscite preventivo',
    'Utile preventivo',
    'Entrate consuntivo',
    'Uscite consuntivo',
    'Utile consuntivo',
    'Scostamento utile',
  ];

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='font-normal px-6 py-3 text-left tracking-wide'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data.map((course) => (
                        <tr key={course.id}>
                          <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                            {course.attributes.startDate
                              ? moment(course.attributes.startDate).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {course.attributes.title}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {course.attributes.section?.data
                              ? course.attributes.section?.data.attributes.name
                              : '-'}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(
                              course.attributes.subscriptionAmount
                            )}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(
                              course.attributes.estimatesEarnings
                            )}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(course.attributes.estimatesOutflow)}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(course.attributes.estimatesProfit)}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(course.attributes.finalEarnings)}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(course.attributes.finalOutflow)}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(course.attributes.finalProfit)}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {formatCurrency(
                              course.attributes.finalProfit -
                                course.attributes.estimatesProfit
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default StatisticsCourse;

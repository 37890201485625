import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { listLocationsBySection } from '../../../../api/location';
import UnderlineButton from '../../../../components/Buttons/UnderlineButton';
import TitlePage from '../../../../components/TitlePage';
import { useAuth } from '../../../../contexts/Auth';
import LocationTable from '../../Tables/locationTable';
import Pagination from '../../../../components/Pagination';
import { useMemo } from 'react';

const ListaLocation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const pagination = useMemo(
    () => ({
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize'),
    }),
    [searchParams]
  );

  const query = useQuery(
    ['locationsList', pagination],
    () => listLocationsBySection(Number(id), token, pagination),
    {
      refetchOnWindowFocus: false,
      enabled: !!pagination,
    }
  );

  return (
    <>
      <TitlePage title='Modifica sezione | Lista Location' />

      <div className='mt-10 space-y-5'>
        <div className='flex justify-end w-full'>
          <UnderlineButton
            onClick={() => {
              navigate(`/sezioni/modifica/${id}/location/nuova`);
            }}
          >
            Aggiungi nuova location
          </UnderlineButton>
        </div>
        {
          // if section has no locations show message
          query.data?.data.length === 0 ? (
            <div className='flex justify-center'>
              <p className='text-lg font-light text-gray-500'>
                Non ci sono location associate a questa sezione
              </p>
            </div>
          ) : (
            <LocationTable query={query} />
          )
        }
        <Pagination pagination={query.data?.meta.pagination} />
      </div>
    </>
  );
};

export default ListaLocation;

import axios from 'axios';
import { ProfileHistory } from '../interfaces/profile-history';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

export const profileHistory = async (id: number, token: string | null) => {
  const { data } = await instance.get<ProfileHistory[]>(
    `${REACT_APP_API_URL}/profile-history/${id}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

import React from 'react';
import { NavLink as NavLinkRoute } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { isDelegato } from '../../libs/utils/auth';

const NavLink = () => {
  const [{ profile }] = useAuth();

  const MENU_ITEMS = [
    { href: '/', label: 'Home' },
    { href: '/statistiche', label: 'Statistiche', hasChild: true },
    { href: '/coupon', label: 'Coupon' },
    { href: '/corsi', label: 'Corsi' },
    { href: '/eventi', label: 'Eventi' },
    { href: '/sezioni', label: 'Sezioni' },
    { href: '/comunicati', label: 'Comunicati' },
    { href: '/utenti', label: 'Utenti' },
    { href: '/stampa', label: 'Stampa' },
    { href: '/tesoreria/entrate', label: 'Tesoreria' },
  ];
  return (
    <>
      {MENU_ITEMS.filter((item) => {
        if (isDelegato(profile)) {
          return item.label !== 'Coupon' && item.label !== 'Stampa';
        }
        return item;
      }).map((item) => (
        <NavLinkRoute
          to={item.href}
          key={item.label}
          className={({ isActive }) =>
            'relative uppercase text-sm font-normal text-gray-800 hover:text-primary active:text-primary font-ubuntu duration-300 ' +
            (isActive ? 'active' : '')
          }
        >
          {item.label}
        </NavLinkRoute>
      ))}
    </>
  );
};

export default NavLink;

import axios from 'axios';
import qs from 'qs';
import { courseStatus } from '../constants/course';
import {
  CourseStatus,
  StrapiResponse,
  StrapiResponseList,
} from '../interfaces/commons';
import {
  CourseReport,
  CourseRequest,
  CourseResponse,
} from '../interfaces/courses';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

export const fetchCoursesForCoupon = async ({
  query = {},
  token,
}: {
  query?: object;
  token: string | null;
}) => {
  const queryString = qs.stringify({
    populate: '*',
    pagination: {
      pageSize: 50000, // da valutare una soluzione diversa in futuro quando i corsi saranno tanti
    },
    ...query,
  });

  const { data } = await axios.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${queryString}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const listCourses = async (
  searchParams: any,
  token: string | null,
  pageAttrName?: string,
  pageSizeAttrName?: string
) => {
  const selectedStatus = courseStatus.filter(({ title }) =>
    searchParams.getAll('status').find((v: string) => v === title)
  );
  const statusArr = selectedStatus.map((object) => object.id);

  const query = {
    populate: '*',
    sort: 'createdAt:desc',

    pagination: {
      page: searchParams.get(pageAttrName || 'page'),
      pageSize: searchParams.get(pageSizeAttrName || 'pageSize'),
    },
    filters: {
      title: { $contains: searchParams.get('search') },
      section: { name: { $eq: searchParams.getAll('section') } },
      startDate: { $contains: searchParams.getAll('year') },
      $and: [
        {
          startDate: {
            $lte: searchParams.get('dateTo'),
            $gte: searchParams.get('dateFrom'),
          },
        },
        { status: { $notNull: true, $eq: statusArr } },
      ],
    },
  };

  const { data } = await instance.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const coursesListForStats = async (
  searchParams: any,
  token: string | null,
  pageAttrName?: string,
  pageSizeAttrName?: string
) => {
  const query = {
    populate: [
      '*',
      'partecipations.guests',
      'section',
      'balance.price',
      'balance.contributes',
      'balance.wines',
      'balance.food',
      'balance.rooms',
      'balance.sponsorship',
      'balance.contributes',
      'balance.extras',
      'balance.teacherPayments',
      'balance.teacherRefunds',
      'balance.couponsExpenses',
      'balance.discountExpenses',
      'balance.couponsVirtuosity',
      'balance.direction',
      'headquarterBalance.cards',
      'headquarterBalance.administrations',
      'headquarterBalance.teachingMaterial',
      'headquarterBalance.shipping',
    ],
    sort: 'startDate:desc',

    pagination: {
      page: searchParams.get(pageAttrName || 'page'),
      pageSize: searchParams.get(pageSizeAttrName || 'pageSize'),
    },
    filters: {
      section: {
        id: {
          $in: Object.entries(qs.parse(searchParams.get('sections') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.value),
        },
      },
      status: {
        $notNull: true,
        $in: [
          CourseStatus.PUBLISHED,
          CourseStatus.FINAL_BALANCE_DELEGATION,
          CourseStatus.FINAL_BALANCE_NATIONAL,
        ],
      },
      $and: [
        {
          startDate: {
            $gte: new Date(searchParams.get('year'), 0, 1),
            $lte:
              searchParams.get('year') === new Date().getFullYear()
                ? new Date()
                : new Date(searchParams.get('year'), 11, 31),
          },
        },
      ],
    },
  };

  const { data } = await instance.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const listCoursesFilter = async ({
  token,
  query,
}: {
  token: string | null;
  query: object;
}) => {
  const { data } = await instance.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createCourseFromTemplate = async (
  courseTemplateId: number,
  token: string | null
): Promise<StrapiResponse<CourseResponse>> => {
  const { data } = await instance.post<StrapiResponse<CourseResponse>>(
    `${REACT_APP_API_URL}/courses`,
    { data: { courseTemplate: courseTemplateId } },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const updateCourse = async (
  courseId: string | number,
  updatedCourse: CourseRequest,
  token: string | null
): Promise<StrapiResponse<CourseResponse>> => {
  delete updatedCourse.partecipations;
  delete updatedCourse.orders;
  const { data } = await instance.put<StrapiResponse<CourseResponse>>(
    `${REACT_APP_API_URL}/courses/${courseId}`,
    { data: updatedCourse },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const uploadToStrapi = async (files: File[], token: string | null) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    const response = await instance.post(
      `${REACT_APP_API_URL}/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const data = response.data;
    // Restituisce la risposta del server
    return data;
  } catch (error) {
    console.log('Errore:', error);
    return error;
  }
};

export const getCourseDetail = async (
  courseId: string,
  token: string | null,
  filters?: any
): Promise<StrapiResponse<CourseResponse>> => {
  const populate = [
    'section.nation',
    'details.otherInfos',
    'location',
    'minProfileCategorySub',
    'lessons',
    'courseTemplate',
    'image',
    'partecipations',
    'balance.price',
    'balance.contributes',
    'balance.wines',
    'balance.food',
    'balance.rooms',
    'balance.sponsorship',
    'balance.contributes',
    'balance.extras',
    'balance.teacherPayments',
    'balance.teacherRefunds',
    'balance.couponsExpenses',
    'balance.couponsVirtuosity',
    'balance.discountExpenses',
    'balance.direction',
    'carousel',
    'headquarterBalance.cards',
    'headquarterBalance.administrations',
    'headquarterBalance.teachingMaterial',
    'headquarterBalance.safetyMargin',
    'headquarterBalance.shipping',
    'orders.payment',
    'canDelegateEdit',
    'courseTemplate.details.otherInfos',
    'courseTemplate.minProfileCategorySub',
    'courseTemplate.section',
    'courseTemplate.lessonTemplates',
    'courseTemplate.treasurer',
    'courseTemplate.delegate',
    'courseTemplate.image',
    'courseTemplate.carousel',
    'courseTemplate.headquarterBalance.cards',
    'courseTemplate.headquarterBalance.administrations',
    'courseTemplate.headquarterBalance.teachingMaterial',
    'courseTemplate.headquarterBalance.shipping',
  ];

  const query = {
    populate,
    filters,
  };

  const { data } = await instance.get<StrapiResponse<CourseResponse>>(
    `${REACT_APP_API_URL}/admin/courses/${courseId}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const deleteCourse = async (
  courseId: string | number,
  token: string | null
) => {
  const { data } = await instance.delete<StrapiResponse<CourseResponse>>(
    `${REACT_APP_API_URL}/courses/${courseId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const find = async ({
  token,
  query,
}: {
  token: string | null;
  query: object;
}) => {
  const { data } = await axios.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const filtersFind = async ({
  token,
  query = {},
}: {
  token: string | null;
  query?: any;
}) => {
  const statusFilters = {
    $or: [
      { status: CourseStatus.PUBLISHED },
      { status: CourseStatus.FINAL_BALANCE_DELEGATION },
      { status: CourseStatus.FINAL_BALANCE_NATIONAL },
    ],
  };

  const queryString = qs.stringify({
    ...query,
    filters: {
      $and: [statusFilters, query.filters],
    },
    pagination: {
      pageSize: 50000,
    },
  });

  const { data } = await axios.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${queryString}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const filtersFindForTeacher = async ({
  token,
  query = {},
}: {
  token: string | null;
  query?: any;
}) => {
  const statusFilters = {
    $or: [
      { status: CourseStatus.PUBLISHED },
      { status: CourseStatus.DRAFT },
      { status: CourseStatus.FINAL_BALANCE_DELEGATION },
      { status: CourseStatus.FINAL_BALANCE_NATIONAL },
    ],
  };

  const queryString = qs.stringify({
    ...query,
    filters: {
      $and: [statusFilters, query.filters],
    },
    pagination: {
      pageSize: 50000,
    },
  });

  const { data } = await axios.get<StrapiResponseList<CourseResponse>>(
    `${REACT_APP_API_URL}/courses?${queryString}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const findReport = async ({
  token,
  id,
}: {
  token: string | null;
  id?: number;
}) => {
  const { data } = await axios.get<CourseReport>(
    `${REACT_APP_API_URL}/courses/${id}/report`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const downloadCostsCSV = async ({
  token,
  id,
  fileName,
}: {
  token?: string | null;
  id: string;
  fileName: string;
}) => {
  const { data } = await instance.get(
    `${REACT_APP_API_URL}/courses/${id}/costs-csv`,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const coursesAPIs = {
  find,
  filtersFind,
  filtersFindForTeacher,
  findReport,
  downloadCostsCSV,
};

export default coursesAPIs;

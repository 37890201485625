import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const lessonTemplateValidator = Yup.object().shape({
  title: Yup.string().required(ValidationErrorMessages.REQUIRED),
  description: Yup.string().required(ValidationErrorMessages.REQUIRED),
  category: Yup.string().required(ValidationErrorMessages.REQUIRED),
  minutes: Yup.number()
    .required(ValidationErrorMessages.REQUIRED)
    .min(0, ValidationErrorMessages.POSITIVE)
    .typeError(ValidationErrorMessages.TYPE_NUMERIC),
  estimatePayment: Yup.number()
    .required(ValidationErrorMessages.REQUIRED)
    .min(0, ValidationErrorMessages.POSITIVE)
    .typeError(ValidationErrorMessages.TYPE_NUMERIC),
  endCourse: Yup.boolean().optional(),
  availableTeacher: Yup.array()
    .min(1, 'Seleziona almeno un docente!')
    .required('Seleziona almeno un docente!'),
});

export const lessonValidator = Yup.object().shape(
  {
    title: Yup.mixed().when('isOnline', {
      is: (isOnline: boolean) => isOnline,
      then: Yup.string(),
      otherwise: Yup.string().required(ValidationErrorMessages.REQUIRED),
    }),
    description: Yup.mixed().when('isOnline', {
      is: (isOnline: boolean) => isOnline,
      then: Yup.string(),
      otherwise: Yup.string().required(ValidationErrorMessages.REQUIRED),
    }),
    category: Yup.mixed().when('isOnline', {
      is: (isOnline: boolean) => isOnline,
      then: Yup.string(),
      otherwise: Yup.string().required(ValidationErrorMessages.REQUIRED),
    }),
    minutes: Yup.mixed().when('isOnline', {
      is: (isOnline: boolean) => isOnline,
      then: Yup.number().min(0, ValidationErrorMessages.POSITIVE),
      otherwise: Yup.number()
        .min(0, ValidationErrorMessages.POSITIVE)
        .required(ValidationErrorMessages.REQUIRED)
        .typeError(ValidationErrorMessages.TYPE_NUMERIC),
    }),
    estimatePayment: Yup.mixed().when('isOnline', {
      is: (isOnline: boolean) => isOnline,
      then: Yup.number().min(0, ValidationErrorMessages.POSITIVE),
      otherwise: Yup.number()
        .min(0, ValidationErrorMessages.POSITIVE)
        .required(ValidationErrorMessages.REQUIRED)
        .typeError(ValidationErrorMessages.TYPE_NUMERIC),
    }),
    endCourse: Yup.boolean().optional(),
    date: Yup.mixed().when('isOnline', {
      is: (isOnline: boolean) => isOnline,
      then: Yup.date().typeError(ValidationErrorMessages.REQUIRED),
      otherwise: Yup.date()
        .required(ValidationErrorMessages.REQUIRED)
        .typeError(ValidationErrorMessages.REQUIRED),
    }),
    teacher: Yup.mixed().when(['isWaiverTeacher', 'waiverTeacher'], {
      is: (isWaiverTeacher: boolean) => !isWaiverTeacher,
      then: Yup.mixed().required(
        'Seleziona un docente dalla lista, oppure inserisci un docente in deroga!'
      ),
      otherwise: Yup.mixed(),
    }),
    waiverTeacher: Yup.string().when(['isWaiverTeacher'], {
      is: (isWaiverTeacher: boolean) => isWaiverTeacher,
      then: Yup.string().required(
        'Seleziona un docente dalla lista, oppure inserisci un docente in deroga!'
      ),
      otherwise: Yup.string(),
    }),
  },
  [['teacher', 'waiverTeacher']]
);

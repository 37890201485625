import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import H1Styled from '../../../../components/Typography/H1Styled';
import { useAuth } from '../../../../contexts/Auth';
import { match } from 'ts-pattern';
import { updateCoupon, listCoupons, getCoupon } from '../../../../api/coupon';
import DettaglioCoupon from './dettaglio-coupon';
import { useMutation, useQuery } from 'react-query';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import FormLoading from '../../../../components/Layout/Loading/FormLoading';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { modelData } from '../../utils';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { couponValidator } from '../../../../validators/coupon';
import { formatDate } from '../../../../libs/utils/formatters/datetimeFormatter';

const ModificaCoupon = () => {
  const { id } = useParams();
  const [{ token }] = useAuth();
  const [searchParams] = useSearchParams();
  const { REACT_APP_DEBOUNCE_DELAY } = process.env;
  const delay = Number(REACT_APP_DEBOUNCE_DELAY);
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const query = useQuery(['coupon', id], () => getCoupon(Number(id), token), {
    onSuccess: (data) => {
      setChecked(data.data.attributes.validFor);
    },
  });
  const couponList = useQuery(['coupons'], () =>
    listCoupons(debounceSearchParams, token)
  );

  const [checked, setChecked] = useState<'' | 'all' | 'course' | 'event'>('');

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(updateCoupon, {
    onSuccess: () => {
      toast.success('Coupon aggiornato con successo');
      couponList.refetch();
      navigate('/coupon');
    },
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });

  const updateCouponInfo = (data: FieldValues) => {
    const modeledData = modelData(data, checked);
    mutate({ id: Number(id), data: modeledData, token });
  };

  const updateCouponMethods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(couponValidator),
  });
  const { handleSubmit, reset } = updateCouponMethods;

  useEffect(() => {
    reset({
      ...query.data,
      startValidity: formatDate(
        query.data?.data.attributes.startValidity,
        'YYYY-MM-DD'
      ),
      endValidity: formatDate(
        query.data?.data.attributes.endValidity,
        'YYYY-MM-DD'
      ),
      active: query.data?.data.attributes.active,
      single: query.data?.data.attributes.single,
      title: query.data?.data.attributes.title,
      price: query.data?.data.attributes.price,
    });
  }, [query.data]);

  return (
    <>
      <div>
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <FormLoading />
          ))
          .with({ status: 'error' }, () => <div>Errore...</div>)
          .with({ status: 'success' }, (query) => {
            return (
              <FormProvider {...updateCouponMethods}>
                <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
                  <form onSubmit={handleSubmit(updateCouponInfo)}>
                    <div className='flex justify-between'>
                      <H1Styled>Modifica Coupon</H1Styled>
                      <div className='flex items-center h-10 gap-4'>
                        <PrimaryButton
                          outline
                          onClick={() => {
                            navigate('/coupon');
                          }}
                        >
                          Chiudi
                        </PrimaryButton>
                        <PrimaryButton type='submit'>Salva</PrimaryButton>
                      </div>
                    </div>

                    <div className='pt-12'>
                      <DettaglioCoupon
                        couponData={query.data.data}
                        checked={checked}
                        setChecked={setChecked}
                      />
                    </div>
                  </form>
                </div>
              </FormProvider>
            );
          })
          .exhaustive()}
      </div>
    </>
  );
};
export default ModificaCoupon;

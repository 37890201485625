import React from "react";
import Header from "../NavBar/Header";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";

const LoginLayout = () => {
  return (
    <div className="bg-white overflow-x-hidden">
      <Outlet />
    </div>
  );
};
export default LoginLayout;

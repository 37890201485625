import TitlePage from '../../../../components/TitlePage';
import FormNuovoComunicato from '../../../../components/FormNuovoComunicato';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  formMethods: UseFormReturn<any, any>;
}

export default function NewAnnouncment({ formMethods }: Props) {
  return (
    <>
      <TitlePage title='Nuovo Comunicato' />
      <FormNuovoComunicato formMethods={formMethods} />
    </>
  );
}

import axios from 'axios';
import qs from 'qs';
import { OnavPositionListResponse } from '../interfaces/onavPosition';
import { Params } from '../interfaces/params';

const { REACT_APP_API_URL } = process.env;

export const onavPositions = async ({ search }: Params) => {
  const query = {
    pagination: {
      pageSize: 30,
    },
    filters: {
      name: { $contains: search },
    },
  };

  const { data } = await axios.get<OnavPositionListResponse>(
    `${REACT_APP_API_URL}/onav-positions?${qs.stringify(query)}`
  );
  return data;
};

export const find = async ({
  token,
  query,
}: {
  token: string | null;
  query: object;
}) => {
  const { data } = await axios.get<OnavPositionListResponse>(
    `${REACT_APP_API_URL}/onav-positions?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const onavPosition = { find };

export default onavPosition;

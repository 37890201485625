import TitlePage from "../../../../components/TitlePage";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FormCostiNuovoTemplate from "../../../../components/FormCostiNuovoTemplate";
import { useFormContext } from "react-hook-form";

interface CostiTemplateProps {
}

const CostiTemplate: React.FC<CostiTemplateProps> = ({ }) => {
  return (
    <>
      <TitlePage title='Template corsi | Costi' />

      <form>
        <FormCostiNuovoTemplate />
      </form>
    </>
  );
};

export default CostiTemplate;

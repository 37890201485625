import React, { useEffect } from 'react';
import InputPresentational from './Form/InputPresentational';

import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import TextAreaPresentational from './Form/TextAreaPresentational';

import { ErrorOption, UseFormReturn, useFormContext } from 'react-hook-form';
import SectionsSelect from './FetchSelect/SectionsSelect';

import H4SpacingLetter from './Typography/H4SpacingLetter';
import { formatDate } from '../libs/utils/formatters/datetimeFormatter';
import moment from 'moment';
import GadgetForm from './GadgetForm';
import { EventRequest, EventStatus } from '../interfaces/events';
import ProfileCategorySelect from './FetchSelect/ProfileCategorySelect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadAreaPresentational from './Form/UploadAreaPresentational';
import ImageUploadFilePreview from './Form/ImageUploadFilePreview';
import CarouselUploadFilesPreview from './Form/CarouselUploadFilesPreview';
import { subDaysFromDate } from '../libs/utils/date';
import useDidMountEffect from '../hooks/useDidMountEffect';

const {
  REACT_APP_MAIL_DAYS = 30,
  REACT_APP_FIRST_REMINDER_DAYS = 15,
  REACT_APP_SECOND_REMINDER_DAYS = 5,
} = process.env;

interface FormDatiPrincipaliEventoProps {
  onImageDelete?: (fileId?: number) => void;
  onCarouselImageDelete?: (index: number) => void;
  eventMethods?: UseFormReturn<any, any>;
}

const FormDatiPrincipaliEvento = ({
  onImageDelete,
  onCarouselImageDelete,
  eventMethods,
}: FormDatiPrincipaliEventoProps) => {
  const { register, control, watch, setValue, setError, formState } =
    useFormContext<EventRequest>();

  const { errors } = formState;

  const status = watch('status');

  const isEventStarted: boolean = moment(moment()).isAfter(watch('startDate'));

  const canEditEvent =
    status && status !== EventStatus.DRAFT ? !isEventStarted : true;

  const isEventCanceled = watch('status') === EventStatus.CANCELED;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

  const onChangeStartDate = (date?: string | null) => {
    if (!date) return;

    const startDate = new Date(date);
    setValue(
      'mailDate',
      formatDate(
        moment(subDaysFromDate(startDate, Number(REACT_APP_MAIL_DAYS))).set({
          hour: 19,
          minute: 0,
        })
      ),
      { shouldValidate: true }
    );

    setValue(
      'firstReminderDate',
      formatDate(
        moment(
          subDaysFromDate(startDate, Number(REACT_APP_FIRST_REMINDER_DAYS))
        ).set({ hour: 19, minute: 0 })
      ),
      { shouldValidate: true }
    );

    setValue(
      'secondReminderDate',
      formatDate(
        moment(
          subDaysFromDate(startDate, Number(REACT_APP_SECOND_REMINDER_DAYS))
        ).set({ hour: 19, minute: 0 })
      ),
      { shouldValidate: true }
    );

    delete errors.startDate;
  };

  return (
    <div className='pt-6 pb-8 grid grid-cols-6 gap-4 md:gap-x-12'>
      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='title'
          label='Titolo'
          name='title'
          type='text'
          register={register}
          required
          error={errors.title}
          disabled={!canEditEvent || isEventCanceled}
        />
      </div>
      <div className='col-span-6'>
        <InputPresentational
          id='subtitle'
          label='Sottotitolo'
          name='subtitle'
          type='text'
          register={register}
          required
          error={{
            ...errors.subtitle,
            type: 'required',
          }}
          disabled={!canEditEvent || isEventCanceled}
        />
      </div>

      <div className='col-span-6'>
        <SimpleCheckboxPresentational
          id='includeTeachers'
          name='includeTeachers'
          label='Solo per docenti'
          register={register}
          disabled={!canEditEvent || isEventCanceled}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <SectionsSelect
          control={control}
          defaultValue={watch('section') as number}
          required
          error={{
            ...errors.section,
            type: 'required',
          }}
          disabled={!canEditEvent || isEventCanceled}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <ProfileCategorySelect
          label='Rivolto a'
          id='minProfileCategorySub'
          name='minProfileCategorySub'
          defaultValue={watch('minProfileCategorySub')}
          control={control}
          required
          error={{ ...errors.minProfileCategorySub, type: 'required' }}
          disabled={isEventCanceled}
        />
      </div>

      <div className='col-span-6'>
        <TextAreaPresentational
          id='shortDescription'
          label='Descrizione breve'
          name='shortDescription'
          register={register}
          disabled={!canEditEvent || isEventCanceled}
        />
      </div>

      <div className='col-span-6'>
        <label
          htmlFor={'description'}
          className='flex justify-between text-sm text-gray-500 mb-2'
        >
          <div>Descrizione</div>
        </label>
        <ReactQuill
          id='description'
          theme='snow'
          value={watch('description')}
          onChange={(value) => setValue('description', value)}
          modules={modules}
          formats={formats}
          readOnly={!canEditEvent || isEventCanceled}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='startDate'
          label='Data e ora inizio evento'
          name='startDate'
          type='datetime-local'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
          onChange={({ target }) => {
            setValue('startDate', target.value);
            onChangeStartDate(target.value);
          }}
          required
          error={{
            ...errors.startDate,
            type: 'required',
          }}
        />
      </div>

      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='mailDate'
          label='Data invio email'
          name='mailDate'
          type='datetime-local'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
          required
          error={{
            ...errors.mailDate,
            type: 'required',
          }}
        />
      </div>
      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='firstReminderDate'
          label='Data primo reminder'
          name='firstReminderDate'
          type='datetime-local'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
          required
          error={{
            ...errors.firstReminderDate,
            type: 'required',
          }}
        />
      </div>
      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='secondReminderDate'
          label='Data secondo reminder'
          name='secondReminderDate'
          type='datetime-local'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
          required
          error={{
            ...errors.secondReminderDate,
            type: 'required',
          }}
        />
      </div>
      <div className='col-span-6'>
        <SimpleCheckboxPresentational
          id='disableEmails'
          name='disableEmails'
          label='Disabilita notifiche email'
          register={register}
          disabled={!canEditEvent || isEventCanceled}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='speakerName'
          label='Relatore'
          name='speakerName'
          type='text'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
        />
      </div>

      <div className='col-span-6'>
        <H4SpacingLetter>Media</H4SpacingLetter>
      </div>

      <div className='col-span-6 md:col-span-3 space-y-3'>
        <UploadAreaPresentational
          label='Immagine singola'
          name='image'
          formMethods={eventMethods}
          acceptedExtensions={['jpg', 'jpeg', 'png']}
        />
        <div className='flex flex-wrap items-start justify-start gap-3'>
          <ImageUploadFilePreview
            imageSource={watch('image')}
            onRemove={onImageDelete}
          />
        </div>
      </div>
      <div className='col-span-6 md:col-span-3 space-y-3'>
        <UploadAreaPresentational
          label='Carosello'
          name='carousel'
          formMethods={eventMethods}
          acceptedExtensions={['jpg', 'jpeg', 'png']}
          multiple
        />
        <div className='flex flex-wrap items-start justify-start gap-3'>
          <CarouselUploadFilesPreview
            imageSources={watch('carousel')}
            onRemove={onCarouselImageDelete}
          />
        </div>
      </div>
      <div className='col-span-6'>
        <InputPresentational
          id='videoUrl'
          label='Video URL'
          name='videoUrl'
          type='text'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
        />
      </div>

      <div className='col-span-6'>
        <H4SpacingLetter>Cosa comprende l'evento</H4SpacingLetter>
      </div>

      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.winesDescription'
          label='Descrizione vini'
          name='details.winesDescription'
          disabled={!canEditEvent || isEventCanceled}
          register={register}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <GadgetForm disabled={!canEditEvent || isEventCanceled} />
      </div>
    </div>
  );
};

export default FormDatiPrincipaliEvento;

import { useFormContext } from 'react-hook-form';
import CourseSelect from './FetchSelect/CourseSelect';
import EventSelect from './FetchSelect/EventSelect';
import InputPresentational from './Form/InputPresentational';
import PriceInputPresentational from './Form/PriceInputPresentational';
import RoundedCheckboxPresentational from './Form/RoundedCheckboxPresentational';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';

const FormNuovoCoupon = ({
  checked,
  setChecked,
}: {
  checked: string;
  setChecked: any;
}) => {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext();

  return (
    <div className='grid grid-cols-12 mt-12 pb-60'>
      <div className='grid grid-cols-1 col-span-12 gap-4 sm:grid-cols-2 xl:col-span-6'>
        <div>
          <InputPresentational
            id='titleCoupon'
            label='Titolo'
            name='title'
            type='text'
            register={register}
            required
            error={{ ...errors.title, type: 'required' }}
          />
        </div>
        <div>
          <PriceInputPresentational
            id='priceCoupom'
            label='Importo'
            name='price'
            type='number'
            register={register}
            required
            error={{ ...errors.price, type: 'required' }}
          />
        </div>
        <div>
          <InputPresentational
            id='startValidity'
            label='Valido dal'
            name='startValidity'
            type='date'
            register={register}
            required
            error={{ ...errors.startValidity, type: 'required' }}
            />
        </div>
        <div>
          <InputPresentational
            id='endValidity'
            label='Valido fino al'
            name='endValidity'
            type='date'
            register={register}
            required
            error={{ ...errors.endValidity, type: 'required' }}
          />
        </div>
        <fieldset className='flex items-center justify-start col-span-1 gap-4 my-4 sm:col-span-2'>
          <SimpleCheckboxPresentational
            label='Singolo / Nominale'
            id='single'
            name='single'
            describedby='single-coupon'
            register={register}
          />
          <SimpleCheckboxPresentational
            label='Attivo'
            id='active'
            name='active'
            describedby='active-coupon'
            register={register}
          />
        </fieldset>

        <div className='col-span-1 sm:col-span-2'>
          <div className='flex flex-col items-start gap-3 mb-4 sm:flex-row sm:items-center sm:justify-between'>
            <p className='text-sm font-normal text-gray-700'>Valido per: *</p>
            <fieldset className='flex items-center -mx-4 divide-x divide-gray-200'>
              <RoundedCheckboxPresentational
                label='Corso'
                id='course'
                name='course_coupon'
                describedby='course-coupon'
                register={register}
                control={control}
                checked={checked === 'course'}
                onChange={() => setChecked('course')}
              />
              <RoundedCheckboxPresentational
                label='Evento'
                id='event'
                name='event_coupon'
                describedby='event-coupon'
                register={register}
                control={control}
                checked={checked === 'event'}
                onChange={() => setChecked('event')}
              />
              <RoundedCheckboxPresentational
                label='Tutto il sito'
                id='all'
                name='all_coupon'
                describedby='all-coupon'
                register={register}
                control={control}
                checked={checked === 'all'}
                onChange={() => setChecked('all')}
              />
            </fieldset>
          </div>

          {checked === 'course' ? (
            <CourseSelect control={control} />
          ) : checked === 'event' ? (
            <EventSelect control={control} />
          ) : checked === 'all' ? null : null}
        </div>
      </div>
    </div>
  );
};

export default FormNuovoCoupon;

import React from 'react';
import Header from '../NavBar/Header';
import Footer from '../Footer';
import { Outlet } from 'react-router-dom';

const PageLayout = () => {
  return (
    <>
      <div className='App bg-white overflow-x-hidden pr-4 lg:pr-0'>
        <Outlet />
      </div>
    </>
  );
};
export default PageLayout;

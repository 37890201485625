import React, { useState, useEffect } from 'react';

import IconLink from '../../../components/TextLink/IconLink';
import { DuplicateIcon, PencilIcon } from '@heroicons/react/outline';

import IconButton from '../../../components/Buttons/IconButton';
import { UseQueryResult } from 'react-query';
import { CourseTemplateResponse } from '../../../interfaces/course-templates';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import DeleteCourseTemplateModal from '../../../components/Modals/DeleteCourseTemplateModal';
import { StrapiResponseList } from '../../../interfaces/commons';
import BadgeBoolean from '../../../components/BadgeBoolean';
import { useNavigate } from 'react-router-dom';

interface TemplateListTableProps {
  query: UseQueryResult<StrapiResponseList<CourseTemplateResponse>>;
  deleteCourseTemplate: (id: string | number) => void;
}

const TemplateListTable: React.FC<TemplateListTableProps> = ({
  query,
  deleteCourseTemplate,
}) => {
  const tableElements = ['Titolo', 'Stato', 'Visibile su onav.it', '', ''];
  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, index) => (
                          <th
                            className='font-normal px-6 py-3 text-left tracking-wide'
                            key={index}
                          >
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data.map((template) => (
                        <tr
                          key={template.id}
                          className='cursor-pointer hover:bg-neutral-100'
                          onClick={() => {
                            navigate(`${template.id}/dati-principali`);
                          }}
                        >
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 w-1/2'>
                            {template.attributes.title}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {template.attributes.visibleForOnavIT
                              ? 'Pubblicato'
                              : 'Bozza'}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            <div className='flex'>
                              <BadgeBoolean
                                active={template.attributes.visibleForOnavIT}
                              />
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-6 py-4'>
                            <IconLink
                              orangeTxt
                              href={`${template.id}/dati-principali`}
                            >
                              <PencilIcon className='w-6 h-6' />
                            </IconLink>
                          </td>
                          <td
                            className='whitespace-nowrap px-6 py-4'
                            onClick={(event) => event.stopPropagation()}
                          >
                            <DeleteCourseTemplateModal
                              titleTemplate={template.attributes.title}
                              buttonType='icon'
                              onConfirm={() =>
                                deleteCourseTemplate(template.id)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default TemplateListTable;

import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getCourseTemplates } from '../../../api/course-templates';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { useAuth } from '../../../contexts/Auth';
import TemplateListTable from '../Tables/TemplateListTable';
import { deleteCourseTemplate } from '../../../api/course-templates';
import { toast } from 'react-toastify';
import Filters from '../../../components/Filters/Filters';
import Pagination from '../../../components/Pagination';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function ListaTemplateCorsi() {
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const query = useQuery(['templates', ...debounceSearchParams], () =>
    getCourseTemplates(debounceSearchParams, token)
  );

  const { mutate: deleteTemplate } = useMutation({
    mutationFn: (id: string | number) => deleteCourseTemplate(id, token),
    onSuccess: () => {
      toast.success('Template eliminato con successo');
      query.refetch();
    },
    onError: () => {
      toast.error('Qualcosa è andato storto!');
    },
  });

  return (
    <>
      <TitlePage title='Lista template corsi' />

      <H1Styled>Lista template corsi</H1Styled>

      <div className='space-y-5'>
        <Filters searching onavIt hideAppliedFilters />
        <TemplateListTable
          query={query}
          deleteCourseTemplate={deleteTemplate}
        />
        <Pagination pagination={query.data?.meta?.pagination} />
      </div>
    </>
  );
}

const BalanceTableHeaders = () => {
  return (
    <thead className='text-sm uppercase text-gray-500 bg-gray-50'>
      <tr>
        <th className='border-r'></th>
        <th
          colSpan={3}
          className='relative font-normal px-6 pt-3 tracking-wide border-r text-center capitalize text-base text-gray-900 italic'
        >
          Bilancio preventivo
        </th>

        <th
          colSpan={3}
          className='relative font-normal px-6 pt-3 tracking-wide border-r text-center capitalize text-base text-gray-900 italic'
        >
          Bilancio consuntivo
        </th>

        <th className='font-normal px-6 py-3 text-left tracking-wide'></th>
      </tr>
      <tr>
        <th className='font-normal px-6 py-3 text-left tracking-wide border-r'>
          Voci
        </th>

        <th className='relative font-normal px-6 py-3 text-left tracking-wide'>
          Unitario
        </th>
        <th className='font-normal px-6 py-3 text-left tracking-wide'>
          Uscite
        </th>
        <th className='font-normal px-6 py-3 text-left tracking-wide border-r'>
          Entrate
        </th>

        <th className='relative font-normal px-6 py-3 text-left tracking-wide'>
          Unitario
        </th>
        <th className='font-normal px-6 py-3 text-left tracking-wide'>
          Uscite
        </th>
        <th className='font-normal px-6 py-3 text-left tracking-wide border-r'>
          Entrate
        </th>

        <th className='font-normal px-6 py-3 text-left tracking-wide'>Note</th>
      </tr>
    </thead>
  );
};

export default BalanceTableHeaders;

import roles from '../../constants/roles';
import { IUser } from '../../interfaces/login';

export const isSegreteriaNazionale = (profile: IUser | undefined): boolean => {
  return profile?.role.name === roles.SEGRETERIA_NAZIONALE;
};

export const isDelegato = (profile: Partial<IUser> | undefined): boolean => {
  return profile?.role?.name
    ? [roles.DELEGATO_SEZIONE, roles.DELEGATO_REGIONE].includes(
        profile?.role?.name
      )
    : false;
};

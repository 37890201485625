import { CardHistory } from './cardHistory';
import { StrapiResponse } from './commons';
import { Coupon } from './coupon';
import { CourseAttributes } from './courses';
import { EventAttributes } from './events';

import {
  InfoPaymentMethod,
  Payment,
  PaymentMethod,
  PaymentStatus,
} from './payments';
import { Profile } from './profile';
import { ReceiptAttributes } from './receipts';
export interface Order {
  productType: ProductType;
  course?: { data: { attributes: CourseAttributes; id: number } };
  event?: { data: { attributes: EventAttributes; id: number } };
  payment?: { data: { attributes: Payment; id: number } };
  associationCode?: string;
  profile: { data: Profile | null };
  cardHistory: { data: CardHistory };
  receipt: StrapiResponse<{ attributes: ReceiptAttributes; id: number } | null>;
  coupon?: { data: Coupon };
  quantity?: number;
}

export enum ProductType {
  EVENT = 'EVENT',
  COURSE = 'COURSE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  OTHER = 'OTHER',
}

export interface OrderForm {
  amount?: number;
  productType?: ProductType;
  profile?: number | null;
  year?: number;
  payment?: {
    status?: PaymentStatus;
    method?: PaymentMethod | null;
    date?: string;
  };
  section: number | null;
  autoReceipt?: boolean;
  cardHistory?: { year: number };
}

import { Listbox, Transition } from '@headlessui/react';
import React from 'react';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';

import classNames from '../../libs/utils/classNames';
import { SelectorIcon } from '@heroicons/react/outline';

export interface IOption {
  label: string;
  value: string | number;
}

export const BasicSelect: React.FC<{
  options: { value: string; label: string }[];
  value?: string;
  onChange: (value: string) => unknown;
  error?: FieldError;
  className?: string;
  label?: string;
  defaultValue?: { value: string; label: string };
  inputClassName?: string;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
}> = ({
  options,
  className,
  label,
  value,
  onChange,
  defaultValue,
  error,
  disabled,
  required,
  multiple,
}) => {
  const selected = options.find((o) => o.value === value);

  const buttonStyle = `relative w-full px-3 py-2 pl-3 pr-10 text-left bg-white border border-${
    error ? 'red-500' : 'gray-300'
  } rounded-md shadow-sm cursor-default focus:outline-none focus:ring-0 focus:border-${
    error ? 'red-500' : 'primary'
  } sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`;

  return (
    <div className={`w-full ${className as string} `}>
      <Listbox
        value={selected?.value ?? defaultValue?.value ?? '-'}
        onChange={onChange}
        disabled={disabled}
      >
        {({ open }) => (
          <>
            <Listbox.Label className='block text-sm text-gray-500'>
              {label ? label : null}
              {required ? ' *' : ''}
            </Listbox.Label>

            <div className='relative mt-1'>
              <Listbox.Button className={buttonStyle}>
                <span className='block truncate'>
                  {selected?.label || ' - '}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  <SelectorIcon
                    className='w-5 h-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              <div className='z-40 absolute w-full mt-1 bg-white rounded-md shadow-lg'>
                <Transition
                  show={open}
                  enter='transition duration-100 ease-out'
                  enterFrom='transform scale-95 opacity-0'
                  enterTo='transform scale-100 opacity-100'
                  leave='transition duration-75 ease-out'
                  leaveFrom='transform scale-100 opacity-100'
                  leaveTo='transform scale-95 opacity-0'
                >
                  <Listbox.Options
                    static
                    className='absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                  >
                    {options.map(({ value, label }) => (
                      <Listbox.Option
                        key={value}
                        value={value}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary' : 'text-gray-800',
                            'cursor-default select-none relative py-2 pl-8 pr-4 z-30'
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {label}
                            </span>
                            {selected && (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                )}
                              >
                                <svg
                                  className='w-5 h-5'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                    clipRule='evenodd'
                                  />
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export const ControlledSelect: React.FC<{
  control?: Control<FieldValues>;
  name: string;
  options: { value: string; label: string }[];
  className?: string;
  label?: string;
  defaultValue?: { value: string; label: string };
  disabled?: boolean;
  error?: FieldError;
  required?: boolean;
}> = ({
  name,
  control,
  options,
  className,
  label,
  defaultValue,
  error,
  disabled,
  required,
}) => {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <BasicSelect
            onChange={onChange}
            value={value}
            error={error}
            defaultValue={defaultValue!}
            {...{
              options,
              className: className as string,
              label: label as string,
            }}
            className={className}
            disabled={disabled}
            required={required}
          />
        )}
      />
      {error && error.message && (
        <p className='mt-1 absolute text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

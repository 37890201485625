import TitlePage from '../../../components/TitlePage';
import InputPresentational from '../../../components/Form/InputPresentational';
import { useFormContext } from 'react-hook-form';
import LocationsSelect from '../../../components/FetchSelect/LocationsSelect';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { location } from '../../../api/location';
import { CourseStatus } from '../../../interfaces/commons';
import { checkProfile } from '../../../libs/utils/helpers';
import { useAuth } from '../../../contexts/Auth';
import sectionsAPIs from '../../../api/section';

export default function LocationCorso() {
  const {
    register,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [{ profile }] = useAuth();

  const locationQuery = useQuery(['location', watch('location')], () => {
    if (!watch('location')) return;
    return location(watch('location'));
  });

  const nationQuery = useQuery(['nation', watch('section')], () =>
    watch('section.data')
      ? sectionsAPIs.findSectionNation(watch('section.data.id'))
      : sectionsAPIs.findSectionNation(watch('section'))
  );

  useEffect(() => {
    if (locationQuery.data && locationQuery.status === 'success') {
      const { city, address } = locationQuery.data?.data.attributes;
      setValue('address', address);
      setValue('city', city);
    }
  }, [watch('location'), locationQuery.status]);

  useEffect(() => {
    if (nationQuery.data && nationQuery.status === 'success')
      setValue('nation', nationQuery.data?.data.attributes.name);
    else setValue('nation', '');
  }, [watch('section'), nationQuery.status]);

  const isCourseCanceled = useMemo(
    () => getValues('status') === CourseStatus.CANCELED,
    [watch('status')]
  );

  const canDelegatoEdit = watch('canDelegateEdit');

  return (
    <>
      <TitlePage title='Dettaglio corso | Location' />

      <form className='mt-6'>
        <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-4'>
          <LocationsSelect
            disabled={
              isCourseCanceled || checkProfile(profile, canDelegatoEdit)
            }
            control={control}
            locationId={getValues('location')}
            required
            error={{ ...errors.location, type: 'required' }}
          />
          <InputPresentational
            id='address'
            label='Indirizzo'
            name='address'
            type='text'
            register={register}
            disabled
            required
            error={{ ...errors.address, type: 'required' }}
          />
          <InputPresentational
            id='city'
            label='Città'
            name='city'
            type='text'
            register={register}
            disabled
            required
            error={{ ...errors.city, type: 'required' }}
          />
          <InputPresentational
            id='nation'
            label='Nazione'
            name='nation'
            type='text'
            register={register}
            disabled
            required
            error={{ ...errors.nation, type: 'required' }}
          />
          {/* <TextAreaPresentational
            id='googleLink'
            label='Link Google Maps'
            name='googleLink'
            register={register}
          /> */}
          {/* <fieldset className='space-y-5'>
            <div>
              <div className='relative flex items-start'>
                <SimpleCheckboxPresentational
                  id='mapEnabled'
                  name='mapEnabled'
                  label='Disattiva la mappa'
                  register={register}
                  disabled={
                    isCourseCanceled || checkProfile(profile, canDelegatoEdit)
                  }
                />
              </div>
              <p id='offers-description' className='text-gray-400 text-sm'>
                Disattiva il link per visualizzare la mappa in onav.it nei casi
                in cui la location non venga trovata
              </p>
            </div>
          </fieldset> */}
        </div>
      </form>
    </>
  );
}

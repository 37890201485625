import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import NavLink from "./NavLink";
import DropdownProfile from "./DropdownProfile";
import OnavLink from "./OnavLink";

export default function Header() {
  const [open, setOpen] = useState(false);
  return (
    <Popover className="relative bg-white">
      <div className="mx-auto px-4 lg:px-10">
        <div className="flex justify-between items-center py-6 md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img
                className="h-12 w-auto"
                src="/images/onav-icon.svg"
                alt="Onav Organizzazione Nazionale Assaggiatori Vino"
                title="Onav Organizzazione Nazionale Assaggiatori Vino"
              />
            </a>
          </div>
          <div className="lg:hidden flex items-center gap-2">
            <OnavLink />
            <DropdownProfile />
            <Popover.Button
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              onClick={() => setOpen(true)}
            >
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden lg:flex items-center gap-8">
            <Popover.Group as="nav" className="flex space-x-8 xl:space-x-10">
              <NavLink />
            </Popover.Group>

            <div className="flex items-center gap-2">
              <OnavLink />
              <DropdownProfile />
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        show={open}
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-40"
        >
          <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <img
                  className="h-12 w-auto"
                  src="/images/onav-icon.svg"
                  alt="Onav Organizzazione Nazionale Assaggiatori Vino"
                  title="Onav Organizzazione Nazionale Assaggiatori Vino"
                />
                <div className="-mr-2">
                  <Popover.Button
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="pb-6 px-5 space-y-6 flex justify-center">
              <div
                className="grid grid-cols-1 gap-y-8 gap-x-3 text-center w-fit "
                onClick={() => {
                  setOpen(false);
                }}
              >
                <NavLink />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

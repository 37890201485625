import { useAuth } from '../../../contexts/Auth';
import { AppSettings } from '../../../interfaces/appSettings';
import {
  CourseTemplateRequest,
  CourseTemplateResponse,
} from '../../../interfaces/course-templates';
import { formatDate } from './datetimeFormatter';

export function fromResponseToRequest(
  courseTemplateResponse: CourseTemplateResponse,
  settings?: AppSettings | undefined
): CourseTemplateRequest {
  const { attributes } = courseTemplateResponse;
  return {
    ...attributes,
    section: attributes.section?.data?.id,
    location: attributes.location?.data?.id,
    delegate: attributes.delegate?.data?.id,
    treasurer: attributes.treasurer?.data?.id,
    lessonTemplates:
      attributes.lessonTemplates?.data?.map((lesson) => lesson.id) || [],
    minProfileCategorySub: attributes.minProfileCategorySub?.data?.id,
    mailDate: attributes.mailDate
      ? formatDate(attributes.mailDate)
      : attributes.mailDate,
    firstReminderDate: attributes.firstReminderDate
      ? formatDate(attributes.firstReminderDate)
      : attributes.firstReminderDate,
    secondReminderDate: attributes.secondReminderDate
      ? formatDate(attributes.secondReminderDate)
      : attributes.secondReminderDate,
    carousel: [],
    image: undefined,
    registrationFee: attributes.registrationFee,
    membershipFee: attributes.membershipFee,
    courseFee: attributes.courseFee,
    safetyMargin: attributes.safetyMargin
      ? (attributes.safetyMargin || 0) * 100
      : (settings?.safetyMargin || 0) * 100,
  };
}

import axios from 'axios';
import qs from 'qs';
import { ProfileCategoryListResponse } from '../interfaces/profileCategory';

const { REACT_APP_API_URL } = process.env;

export const profileCategories = async ({ query = {} }: { query?: object }) => {
  const queryString = qs.stringify({
    pagination: {
      pageSize: 30,
    },
    sort: 'sort:desc',
    ...query,
  });

  const { data } = await axios.get<ProfileCategoryListResponse>(
    `${REACT_APP_API_URL}/profile-categories?${queryString}`
  );
  return data;
};

export const MedalIcon: React.FC<{ fill?: string; disabled?: boolean }> = ({
  fill = '#718096',
  disabled,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.795'
      height='24.143'
      viewBox='0 0 18.795 24.143'
      className={disabled ? `cursor-not-allowed` : ''}
    >
      <g
        id='Raggruppa_1463'
        data-name='Raggruppa 1463'
        transform='translate(0 0)'
      >
        <path
          id='Tracciato_582'
          data-name='Tracciato 582'
          d='M278.712,400.752l-.694-.487-.793.3a.776.776,0,0,1-.863-.222l-.551-.644-.842.092a.785.785,0,0,1-.781-.429l-.367-.748q-.581,3.006-1.381,5.964,1.547-.343,3.095-.737,1.175,1.081,2.325,2.206.694-2.562,1.234-5.16A.771.771,0,0,1,278.712,400.752Z'
          transform='translate(-269.76 -381.904)'
          fill={fill}
        />
        <path
          id='Tracciato_583'
          data-name='Tracciato 583'
          d='M314.014,399.525a.781.781,0,0,1-.781.429l-.841-.092-.551.643a.776.776,0,0,1-.863.222l-.793-.3-.693.486a.77.77,0,0,1-.406.138q.539,2.6,1.233,5.158,1.149-1.125,2.325-2.206,1.547.394,3.095.737-.8-2.938-1.374-5.927Z'
          transform='translate(-299.648 -382.067)'
          fill={fill}
        />
        <path
          id='Tracciato_584'
          data-name='Tracciato 584'
          d='M276.113,316.779l.4-.882a.547.547,0,0,0-.118-.618l-.694-.674.165-.953a.548.548,0,0,0-.268-.569l-.84-.48-.077-.965a.547.547,0,0,0-.4-.485l-.933-.256-.314-.915a.548.548,0,0,0-.509-.37l-.967-.016-.532-.808a.548.548,0,0,0-.585-.232l-.941.225-.716-.65a.548.548,0,0,0-.624-.079l-.856.452-.856-.452a.548.548,0,0,0-.624.079l-.716.65-.941-.225a.548.548,0,0,0-.585.232l-.532.808-.967.016a.548.548,0,0,0-.509.37l-.315.915-.933.256a.548.548,0,0,0-.4.485l-.077.965-.84.48a.548.548,0,0,0-.268.569l.165.953-.694.674a.548.548,0,0,0-.118.618l.4.882-.5.825a.548.548,0,0,0,.039.628l.6.756-.283.925a.548.548,0,0,0,.194.6l.773.582-.045.967a.548.548,0,0,0,.337.531l.894.371.2.947a.548.548,0,0,0,.459.431l.958.137.427.868a.547.547,0,0,0,.551.3l.962-.105.629.735a.547.547,0,0,0,.609.156l.905-.341.792.555a.547.547,0,0,0,.629,0l.792-.555.905.341a.548.548,0,0,0,.609-.156l.629-.735.962.105a.547.547,0,0,0,.551-.3l.427-.868.958-.137a.548.548,0,0,0,.458-.431l.2-.947.894-.371a.548.548,0,0,0,.337-.531l-.045-.967.773-.582a.548.548,0,0,0,.194-.6l-.284-.925.6-.756a.548.548,0,0,0,.039-.628Zm-8.812,8.554a7.964,7.964,0,1,1,7.964-7.964A7.973,7.973,0,0,1,267.3,325.332Z'
          transform='translate(-257.903 -307.988)'
          fill={fill}
        />
        <path
          id='Tracciato_585'
          data-name='Tracciato 585'
          d='M282.061,338.112A6.061,6.061,0,1,0,276,332.051,6.068,6.068,0,0,0,282.061,338.112Zm5.622-6.061a5.622,5.622,0,1,1-5.622-5.622A5.629,5.629,0,0,1,287.683,332.051Z'
          transform='translate(-272.663 -322.671)'
          fill={fill}
        />
        <path
          id='Tracciato_586'
          data-name='Tracciato 586'
          d='M286.605,330.4a5.23,5.23,0,0,1,1.145.4,5.468,5.468,0,0,1,1.29.886,5.221,5.221,0,0,1,1.349,5.6,5.2,5.2,0,0,1-3.1,3.1,5.213,5.213,0,0,1-5.6-1.35,5.469,5.469,0,0,1-.886-1.29,5.232,5.232,0,0,1-.4-1.145,6.149,6.149,0,0,1-.117-.777c-.009-.182-.015-.279-.015-.279s0,.1,0,.279a5.44,5.44,0,0,0,.081.786,5.3,5.3,0,0,0,.364,1.176,5.187,5.187,0,0,0,.865,1.349,5.428,5.428,0,0,0,1.493,1.177,5.243,5.243,0,0,0,2.041.6,5.377,5.377,0,0,0,2.259-.289,5.367,5.367,0,0,0,3.25-3.25,5.385,5.385,0,0,0,.289-2.261,5.247,5.247,0,0,0-.6-2.039,5.421,5.421,0,0,0-1.177-1.493,5.191,5.191,0,0,0-1.349-.864,5.3,5.3,0,0,0-1.176-.365,5.458,5.458,0,0,0-.787-.081h-.279l.279.015A6.124,6.124,0,0,1,286.605,330.4Z'
          transform='translate(-276.152 -326.16)'
          fill={fill}
        />
        <path
          id='Tracciato_587'
          data-name='Tracciato 587'
          d='M282.791,324.427a7.565,7.565,0,0,0-.86-2.942,7.825,7.825,0,0,0-1.691-2.16,7.6,7.6,0,0,0-1.943-1.258,7.674,7.674,0,0,0-1.7-.537,8.02,8.02,0,0,0-1.138-.125l-.4,0,.4.018a8.714,8.714,0,0,1,1.128.163,7.6,7.6,0,0,1,1.667.577,7.864,7.864,0,0,1,1.883,1.28,7.565,7.565,0,1,1-10.689,10.689,7.872,7.872,0,0,1-1.28-1.883,7.6,7.6,0,0,1-.577-1.667,8.741,8.741,0,0,1-.163-1.128c-.012-.263-.018-.4-.018-.4s0,.141,0,.4a8.016,8.016,0,0,0,.125,1.138,7.68,7.68,0,0,0,.537,1.7,7.586,7.586,0,0,0,1.258,1.943,7.83,7.83,0,0,0,2.16,1.691,7.561,7.561,0,0,0,2.944.86,7.731,7.731,0,0,0,8.353-8.355Z'
          transform='translate(-265.657 -315.665)'
          fill={fill}
        />
      </g>
    </svg>
  );
};

export const ArrowPathIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      className='w-6 h-6'
      {...props}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
      />
    </svg>
  );
};

import { StrapiResponse } from './commons';
import { ReceiptResponse } from './receipts';

export interface PaymentResponse {
  data: {
    id: number;
    attributes: Payment;
  };
}

export interface Payment {
  amount: number;
  description: string;
  date: string;
  status: PaymentStatus;
  providerResponse: null | object;
  createdAt: string | null;
  updatedAt: string | null;
  method: PaymentMethod | null;
  receipt: StrapiResponse<ReceiptResponse>;
}

export enum InfoPaymentMethod {
  CASH_SECTION = 'CASH_SECTION',
  CASH_HEADQUARTER = 'CASH_HEADQUARTER',
  BANK_TRANSFER_SECTION = 'BANK_TRANSFER_SECTION',
  BANK_TRANSFER_HEADQUARTER = 'BANK_TRANSFER_HEADQUARTER',
  ATM = 'ATM',
  CARD = 'CARD',
}

export enum PaymentStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  AUTHORIZED = 'AUTHORIZED',
  CAPTURED = 'CAPTURED',
  VOIDED = 'VOIDED',
  PARTIAL = 'PARTIAL',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}

export enum PaymentMethod {
  PAYPAL = 'PAYPAL',
  CASH = 'CASH',
  BANK_CHECK = 'BANK_CHECK',
  BANK_TRANSFER_SECTION = 'BANK_TRANSFER_SECTION',
  BANK_TRANSFER_SITE = 'BANK_TRANSFER_SITE',
  BANCOMAT = 'BANCOMAT',
  PAYMENT_SLIP = 'PAYMENT_SLIP',
  AXERVE = 'AXERVE',
}

export const PaymentStatusLabel = {
  [PaymentStatus.COMPLETED]: 'Pagato',
  [PaymentStatus.FAILED]: 'Pagamento fallito',
  [PaymentStatus.PENDING]: 'In attesa',
  [PaymentStatus.DRAFT]: 'Non pagato',
  [PaymentStatus.PARTIAL]: 'Parziale',
  [PaymentStatus.CANCELED]: 'Annullato',
  [PaymentStatus.REFUNDED]: 'Rimborsato',
};

export const PAYMENT_STATUSES_OPTIONS = [
  {
    id: PaymentStatus.COMPLETED,
    title: PaymentStatusLabel[PaymentStatus.COMPLETED],
  },
  { id: PaymentStatus.FAILED, title: PaymentStatusLabel[PaymentStatus.FAILED] },
  {
    id: PaymentStatus.PENDING,
    title: PaymentStatusLabel[PaymentStatus.PENDING],
  },
  { id: PaymentStatus.DRAFT, title: PaymentStatusLabel[PaymentStatus.DRAFT] },
  {
    id: PaymentStatus.PARTIAL,
    title: PaymentStatusLabel[PaymentStatus.PARTIAL],
  },
  {
    id: PaymentStatus.CANCELED,
    title: PaymentStatusLabel[PaymentStatus.CANCELED],
  },
  {
    id: PaymentStatus.REFUNDED,
    title: PaymentStatusLabel[PaymentStatus.REFUNDED],
  },
];

export const PAYMENT_STATUSES_OPTIONS_SOURCE = [
  {
    id: PaymentStatus.COMPLETED,
    attributes: {
      name: PAYMENT_STATUSES_OPTIONS.find(
        (elem) => elem.id === PaymentStatus.COMPLETED
      )?.title,
    },
  },
  {
    id: PaymentStatus.FAILED,
    attributes: {
      name: PAYMENT_STATUSES_OPTIONS.find(
        (elem) => elem.id === PaymentStatus.FAILED
      )?.title,
    },
  },
  {
    id: PaymentStatus.PENDING,
    attributes: {
      name: PAYMENT_STATUSES_OPTIONS.find(
        (elem) => elem.id === PaymentStatus.PENDING
      )?.title,
    },
  },
  {
    id: PaymentStatus.DRAFT,
    attributes: {
      name: PAYMENT_STATUSES_OPTIONS.find(
        (elem) => elem.id === PaymentStatus.DRAFT
      )?.title,
    },
  },
  {
    id: PaymentStatus.PARTIAL,
    attributes: {
      name: PAYMENT_STATUSES_OPTIONS.find(
        (elem) => elem.id === PaymentStatus.PARTIAL
      )?.title,
    },
  },
  {
    id: PaymentStatus.CANCELED,
    attributes: {
      name: PAYMENT_STATUSES_OPTIONS.find(
        (elem) => elem.id === PaymentStatus.CANCELED
      )?.title,
    },
  },
];

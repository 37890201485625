import { XIcon } from "@heroicons/react/outline";
import React from "react";
import IconButton from "../Buttons/IconButton";

interface FreeLabelProps {
  label: string;
}
const FreeLabel: React.FC<FreeLabelProps> = ({
  label,
}) => {
  return (
    <label className="block text-sm text-gray-500 mb-1">
      {label}
    </label>
  );
};

export default FreeLabel;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../libs/utils/classNames';

interface UnderlineButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: any;
  small?: boolean;
}

const UnderlineButton: React.FC<UnderlineButtonProps> = ({
  onClick,
  children,
  small,
  className,
  ...otherProps
}) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className={classNames(
        'text-base flex items-center gap-2 w-max whitespace-nowrap  ' +
          className,
        small ? '!text-sm' : '',
        otherProps.disabled
          ? 'cursor-not-allowed bordeaux-300 text-primary/60'
          : 'text-primary'
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default UnderlineButton;

import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';

interface Props {
  isOpen: boolean;
  onClose: () => unknown;
  onConfirm?: () => unknown;
  subtitle?: string;
  title?: string;
  textButton?: string;
  cancelTextButton?: string;
  children?: JSX.Element | JSX.Element[] | string | number | null;
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  subtitle,
  title,
  textButton,
  cancelTextButton,
  children,
  onConfirm,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-10 inset-0 overflow-y-auto'
        onClose={onClose}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6'>
              <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                {title}
              </h4>

              <p className='text-gray-500 font-light'>{subtitle}</p>
              {children}
              <div className='flex items-center justify-end gap-4 mt-6'>
                <PrimaryButton textSmall onClick={onClose}>
                  {cancelTextButton || 'Annulla'}
                </PrimaryButton>
                <PrimaryButton onClick={onConfirm}>
                  {textButton || 'Conferma'}
                </PrimaryButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;

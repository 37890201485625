import React from 'react';
import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { audiences } from '../../api/audiences';
import { useAuth } from '../../contexts/Auth';
import { FieldError } from 'react-hook-form';

const AudienceSelect = ({
  defaultValue,
  control,
  register,
  name,
  required,
  error,
}: {
  defaultValue?: number;
  control?: any;
  register?: any;
  name?: string;
  required?: boolean;
  error?: FieldError;
}) => {
  const [{ token }] = useAuth();
  const audiencesQuery = useQuery('audiences', () => audiences(token));

  return (
    <>
      {match(audiencesQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          return (
            <SelectPresentational
              label='Audience'
              name={name || 'audience'}
              defaultValue={defaultValue}
              register={register}
              control={control}
              options={query.data?.data.map((audience) => ({
                value: audience.id,
                label: audience.attributes.title,
              }))}
              required={required}
              error={error}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default AudienceSelect;

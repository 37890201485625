import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/Auth";
import Spinner from "../Layout/Loading/Spinner";

const PublicRoute: React.FC<{
  element: React.ReactElement;
  privateRedirect?: string;
}> = (props) => {
  const [{ appLoaded, isAuthenticated }] = useAuth();
  const { element: PublicElement, privateRedirect = "/profile" } = props;

  if (appLoaded)
    return isAuthenticated ? (
      <Navigate replace to={privateRedirect} />
    ) : (
      PublicElement
    );

  return <div className="w-full h-screen flex items-center justify-center"><Spinner /></div>;
};

export default PublicRoute;

import TitlePage from '../../../components/TitlePage';
import FormDirezione from '../../../components/FormDirezione';

export default function DirezioneCorso() {
  return (
    <>
      <TitlePage title='Dettaglio corso | Direzione' />

      <form className='mt-6'>
        <FormDirezione />
      </form>
    </>
  );
}

import React, { useState } from 'react';

import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import H1Styled from '../../../../components/Typography/H1Styled';

import NuovoComunicato from './nuovo-comunicato';
import { createAnnouncment } from '../../../../api/announcments';
import { useAuth } from '../../../../contexts/Auth';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import Spinner from '../../../../components/Layout/Loading/Spinner';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { announcementValidator } from '../../../../validators/announcement';
import { AnnouncmentRequest } from '../../../../interfaces/announcments';

const CreaNuovoComunicato = () => {
  const navigate = useNavigate();
  const [{ token }] = useAuth();
  const [loading, setLoading] = useState(false);
  const newAnnouncementMethods = useForm<AnnouncmentRequest>({
    resolver: useYupValidationResolver(announcementValidator),
  });
  const { handleSubmit } = newAnnouncementMethods;

  const removeEmptyFields = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '') {
        delete obj[key];
      }
    });
  };

  const { mutate, isLoading } = useMutation(createAnnouncment, {
    onSuccess: () => {
      toast.success('Comunicato creato con successo');
      navigate(`/comunicati`);
      setLoading(false);
    },
    onError: (error: any) => {
      setLoading(false);
      toast.error(error.response.data.error.message);
    },
  });

  const createNewAnnouncement = async (data: FieldValues) => {
    if (!data.audience) return toast.error("Selezionare un'audience");

    removeEmptyFields(data);
    setLoading(true);
    mutate({
      data: { data },
      token,
    });
  };

  return (
    <FormProvider {...newAnnouncementMethods}>
      <form
        className='col-span-12 lg:col-span-9 xl:col-span-10'
        onSubmit={handleSubmit(createNewAnnouncement)}
      >
        <div className='flex justify-between'>
          <H1Styled>Nuovo Comunicato</H1Styled>
          <div className='flex items-center h-10'>
            {loading && <Spinner />}
            <div className='mr-6'>
              <PrimaryButton
                isLoading={isLoading}
                disabled={isLoading}
                type='submit'
              >
                Salva
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end mr-8'>
          <p className='text-lg text-gray-500'>
            STATO:
            <span className='ml-3 font-bold text-gray-800 uppercase'>
              BOZZA
            </span>
          </p>
        </div>
        <div className='pt-12'>
          <NuovoComunicato formMethods={newAnnouncementMethods} />
        </div>
      </form>
    </FormProvider>
  );
};
export default CreaNuovoComunicato;

import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createCourseFromTemplate } from '../api/courses';
import { useAuth } from '../contexts/Auth';
import { StrapiResponse } from '../interfaces/commons';
import { CourseTemplateRequest } from '../interfaces/course-templates';
import { CourseResponse } from '../interfaces/courses';
import UnderlineButton from './Buttons/UnderlineButton';

interface TrainingOfferCardProps {
  trainingOffer: CourseTemplateRequest;
  courseTemplateId: number;
}
const TrainingOfferCard: React.FC<TrainingOfferCardProps> = ({
  trainingOffer,
  courseTemplateId,
}) => {
  const navigate = useNavigate();
  const [{ token }] = useAuth();

  const createCourseFromTemplateMutationFn = () => {
    return createCourseFromTemplate(courseTemplateId, token);
  };

  const onCreateCourseFromTemplateMutationSuccess = (
    data: StrapiResponse<CourseResponse>
  ) => {
    const newCourseId = data.data.id;
    navigate('/corsi/nuovo-corso/' + newCourseId + '/dati-principali');
  };

  const createCourseFromTemplateMutation = useMutation({
    mutationKey: ['createCourseFromTemplate'],
    mutationFn: createCourseFromTemplateMutationFn,
    onSuccess: onCreateCourseFromTemplateMutationSuccess,
    onError: () => {},
  });

  const createCourse = () => {
    createCourseFromTemplateMutation.mutate();
  };

  return (
    <div className='bg-white border flex flex-col items-start justify-between px-4 py-6 w-full space-y-5 mr-6'>
      <div className='space-y-4'>
        <h1 className='font-IBM font-medium text-gray-600 text-1.5xl'>
          {trainingOffer.title || 'Non definito'}
        </h1>
        <p className='text-sm text-gray-500'>
          {trainingOffer.shortDescription}
        </p>
      </div>
      <UnderlineButton onClick={createCourse}>
        Crea corso da template
      </UnderlineButton>
    </div>
  );
};

export default TrainingOfferCard;

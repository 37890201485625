import React from 'react';
import classNames from '../../libs/utils/classNames';

interface SandButtonProps {
  onClick?: (e?: any) => void;
  smallRadius?: boolean;
  children: any;
  isLoading?: boolean;
}

const SandButton: React.FC<
  SandButtonProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({ children, onClick, smallRadius, isLoading, ...rest }) => {
  return (
    <button
      {...rest}
      onClick={(e) => onClick && onClick(e)}
      className={classNames(
        'font-normal flex items-center justify-center text-center whitespace-nowrap  py-2 px-4 bg-sand-100 rounded-md text-primary w-max disabled:bg-sand-50',
        rest.disabled && !isLoading
          ? 'disabled:cursor-not-allowed '
          : 'hover:opacity-80',
        smallRadius ? 'text-sm rounded-full' : '',
        isLoading ? 'cursor-wait disabled:bg-sand-50' : ''
      )}
    >
      {children}
    </button>
  );
};

export default SandButton;

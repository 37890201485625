import TitlePage from '../../../../components/TitlePage';
import FormDirezione from '../../../../components/FormDirezione';

interface DirezioneTemplateProps {}
const DirezioneTemplate: React.FC<DirezioneTemplateProps> = ({}) => {
  return (
    <>
      <TitlePage title='Template corsi | Direzione' />

      <form>
        <FormDirezione />
      </form>
    </>
  );
};
export default DirezioneTemplate;

export interface CardHistory {
  id: number;
  attributes: CardHistoryAttributes;
}

export enum CardHistoryType {
  PRIMA_TESSERA = 'primaTessera',
  RINNOVO = 'rinnovo',
  REISCRIZIONE = 'reiscrizione',
}

export interface CardHistoryAttributes {
  printed: boolean;
  letterPrinted: boolean;
  type: string;
  createdAt: string;
  updatedAt: string;
  year: number;
}

export default function OnavLink() {
  const { REACT_APP_ONAVIT_URL } = process.env;

  return (
    <a
      href={REACT_APP_ONAVIT_URL}
      target='_blank'
      rel='noreferrer'
      className='text-sm py-2 px-4 bg-sand-100 rounded-full text-primary border  w-max  flex items-center uppercase font-medium mr-1 hover:bg-primary hover:text-white duration-300'
    >
      Onav.it
    </a>
  );
}

import React from 'react';

import { useMutation, useQueryClient } from 'react-query';

import {
  PencilIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/outline';

// context
import { useAuth } from '../../../contexts/Auth';

// components
import IconLink from '../../../components/TextLink/IconLink';
import DeleteCourseTemplateLessonModal from '../../../components/Modals/DeleteCourseLessonTemplateModal';

// api
import { deleteCourseTemplateLesson } from '../../../api/course-lesson-templates';

// interfaces
import { CourseLessonTemplateResponse } from '../../../interfaces/course-template-lessons';
import { formatDuration } from '../../../libs/utils/formatters/lessonFormatters';
import { useNavigate } from 'react-router-dom';

const tableElements = [
  'N.',
  'Lezione',
  'Categoria',
  'Durata',
  'Prezzo',
  '',
  '',
];
interface LessonsListTemplateTableProps {
  relations: CourseLessonTemplateResponse[];
  up?: (lessonId: number) => void;
  down?: (lessonId: number) => void;
}
const LessonsListTemplateTable: React.FC<LessonsListTemplateTableProps> = ({
  relations,
  up,
  down,
}) => {
  const [{ token }] = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: deleteRelation } = useMutation({
    mutationFn: (relationId: number) => {
      return deleteCourseTemplateLesson(relationId, token);
    },
    onSuccess: async () => {
      queryClient.refetchQueries('course-lesson-templates');
    },
  });

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
              <tr>
                {tableElements.map((element) => (
                  <th className='font-normal px-6 py-3 text-left tracking-wide'>
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {relations.map((relation, index) => {
                const lesson = relation.attributes.lessonTemplate.data;
                return (
                  <tr
                    className='cursor-pointer hover:bg-neutral-100'
                    onClick={() => {
                      navigate(
                        `/corsi/template/lista-lezioni/${lesson.id}/modifica-lezione`
                      );
                    }}
                  >
                    <td className='whitespace-nowrap px-6 py-4 flex justify-start'>
                      <div className='flex flex-col justify-center items-center hover:cursor-default'>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            up!(lesson?.id!);
                          }}
                        >
                          <ChevronUpIcon className='h-7 w-7 text-black hover:text-primary' />
                        </button>
                        <div className='font-IBM w-8 h-8 flex items-center justify-center border border-gray-700 text-gray-700 rounded-full text-sm font-medium'>
                          {index + 1}
                        </div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            down!(lesson.id!);
                          }}
                        >
                          <ChevronDownIcon className='h-7 w-7 text-black hover:text-primary' />
                        </button>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                      {lesson?.attributes?.title}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lesson?.attributes?.endCourse
                        ? 'Fine corso'
                        : 'Non fine corso'}
                    </td>

                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {formatDuration(lesson?.attributes?.minutes)}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      € {lesson?.attributes?.estimatePayment}
                    </td>

                    <td className='whitespace-nowrap px-6 py-4 '>
                      <IconLink
                        orangeTxt
                        href={`/corsi/template/lista-lezioni/${lesson?.id}/modifica-lezione`}
                      >
                        <PencilIcon className='w-6 h-6' />
                      </IconLink>
                    </td>
                    <td
                      className='whitespace-nowrap px-6 py-4 '
                      onClick={(event) => event.stopPropagation()}
                    >
                      <DeleteCourseTemplateLessonModal
                        titleLesson={lesson?.attributes?.title}
                        buttonType='icon'
                        onConfirm={() => deleteRelation(relation.id)}
                      />
                    </td>
                  </tr>
                );
              })}
              {relations.length < 1 && (
                <tr>
                  <td className='py-6' colSpan={4}>
                    <p className='w-full flex justify-center items-center'>
                      Nessun template lezione selezionato
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LessonsListTemplateTable;

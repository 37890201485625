import { CourseTemplateRequest } from '../interfaces/course-templates';

export const emptyCourseTemplate: CourseTemplateRequest = {
  title: '',
  subtitle: '',
  includeTeachers: false,
  shortDescription: '',
  description: '',
  videoUrl: '',
  startDate: null,
  endDate: null,
  mailDate: null,
  firstReminderDate: null,
  secondReminderDate: null,
  firstRepresentative: '',
  secondRepresentative: '',
  treasurerName: '',
  treasurerPaymentDate: null,
  treasurerAmountPaid: null,
  treasurerAmount: 0,
  treasurerAmountLimit: 0,
  supervisorName: '',
  supervisorPaymentDate: null,
  supervisorAmount: 0,
  supervisorAmountLimit: 0,
  initialSubscriptionAmount: 0,
  administrativeAmount: 0,
  minParticipants: 1,
  maxParticipants: 1,
  glassesForBottle: 0,
  wineTypes: 0,
  visibleForOnavIT: false,
  bottlePrice: 0,
  materialAmount: 0,
  shippingMaterialAmount: 0,
  slug: '',
  details: {
    aimings: '',
    lessonsDescription: '',
    winesDescription: '',
    otherInfos: [],
  },
  lessonTemplates: [],
  createdAt: '',
  isOnline: false,
  canDelegateEdit: undefined,
  image: undefined,
  carousel: undefined,
  headquarterBalance: {
    cards: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    shipping: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    administrations: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    teachingMaterial: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
    safetyMargin: {
      estimatedPriceUnit: 0,
      estimatedOutflow: 0,
      estimatedIncome: 0,
      finalPriceUnit: 0,
      finalOutflow: 0,
      finalIncome: 0,
      notes: '',
    },
  },
};

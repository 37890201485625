import { IOption } from '../components/Form/Multiselect';
import { StrapiDataList, StrapiResponse, StrapiResponseList } from './commons';
import { Location } from './location';
import { Section } from './section';
import { BalanceVoice } from './courses';
import { Profile } from './profile';
import { Media } from './media';
import { ProfileCategory } from './profileCategory';
import { Balance, HeadquarterBalance } from './strapiComponents/balance';
import { Wine } from './strapiComponents/wine';
import { EventParticipation } from './event-participants';
import { CostCenter } from './receipts';
// import { Balance } from './strapiComponents/balance';

export enum EventStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FINAL_BALANCE_DELEGATION = 'final_balance_delegation',
  FINAL_BALANCE_NATIONAL = 'final_balance_national',
  CANCELED = 'canceled',
}

export type EventListResponse = StrapiResponseList<{
  id: number;
  attributes: EventAttributes;
}>;

export type EventBalance = {
  finalIncome: number;
  subscriptionFinalIncome: number;
};

export type EventResponse = StrapiResponse<{
  id: number;
  attributes: EventAttributes;
}>;

export type EventResponseListElement = {
  id: number;
  attributes: EventAttributes;
};

export interface EventsCommonAttributes {
  details?: {
    wineDetails: string;
    otherInfos?: {
      description: string;
    }[];
  };
  title: string;
  subtitle: any;
  includeTeachers: boolean;
  shortDescription: string;
  description: string;
  videoUrl?: string;
  startDate?: string | null;
  mailDate?: string | null;
  firstReminderDate?: string | null;
  secondReminderDate?: string | null;
  daysConfirmBefore: number;
  mapEnabled: boolean;
  subscriptionAmount: number;
  minParticipants: number;
  maxParticipants: number;
  initialMaxParticipants?: number;
  estimatesEarnings: number;
  estimatesOutflow: number;
  estimatesProfit: number;
  finalEarnings: number;
  finalOutflow: number;
  finalProfit: number;
  glassesForBottle: number;
  firstRepresentative: string;
  secondRepresentative: string;
  treasurerName: string;
  treasurerPaymentDate?: string | null;
  treasurerAmount: number;
  speakerName: string;
  supervisorPaymentDate: string | null;
  supervisorAmount: number;
  administrativeAmount: number;
  wines: Wine[];
  status: EventStatus;
  initialSubscriptionAmount?: number;
  disableSubscriptions: boolean | null;
}

export type EventRequest = EventsCommonAttributes & {
  location?: number | IOption | null;
  section?: number | null;
  partecipations?: StrapiDataList<any>;
  balance: Balance;
  headquarterBalance: HeadquarterBalance;
  minProfileCategorySub?: IOption | number;
  carousel?: File[] | Media[];
  image?: File[] | Media;
  autosetReminderDates: boolean;
  confirmedParticipants?: number;
  // courseTemplate?: number;
  // delegate?: number | null;
  // treasurer?: number | null;
  // lessons: number[] | null;
};

export interface EventAttributes extends EventsCommonAttributes {
  minProfileCategorySub?: { data?: ProfileCategory };
  balance: Balance;
  headquarterBalance: HeadquarterBalance;
  partecipations?: StrapiResponseList<EventParticipation>;
  location?: { data?: Location };
  section?: { data?: Section };
  delegate?: { data?: Profile };
  treasurer?: { data?: Profile };
  carousel?: { data?: Media[] };
  image?: { data?: Media };
  profileCategory?: { data: ProfileCategory };
  createdAt: string;
  confirmedParticipants?: number;
}

export interface EventGuest {
  firstName: string;
  id?: number;
  lastName: string;
}

export type EventReport = {
  totalGuests: number;
  totalPresents: number;
  totalCanceled: number;
  totalPending: number;
  totalConfirmed: number;
  totalNotConfirmed: number;
};

import { useNavigate } from 'react-router-dom';
import React from 'react';

interface UtenteLezioniAbilitateTableProps {}
const UtenteLezioniAbilitateTable: React.FC<
  UtenteLezioniAbilitateTableProps
> = ({}) => {
  const lezioni = [
    {
      id: 1,
      course: 'Corso - Assaggiatore di vino',
      lesson: 'Lezione - Assaggiatore di vino',
    },
    {
      id: 2,
      course: 'Corso - Assaggiatore di vino',
      lesson: 'Lezione - Assaggiatore di vino',
    },
    {
      id: 3,
      course: 'Corso - Assaggiatore di vino',
      lesson: 'Lezione - Assaggiatore di vino',
    },
  ];

  const tableElements = ['N', 'Template corso', 'Template lezione'];

  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
              <tr>
                {tableElements.map((element) => (
                  <th className='font-normal px-6 py-3 text-left tracking-wide'>
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {lezioni.map((lezione) => (
                <tr key={lezione.id} className='cursor-default'>
                  <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                    {lezione.id}
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 uppercase'>
                    {lezione.course}
                  </td>

                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                    {lezione.lesson}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UtenteLezioniAbilitateTable;

import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../components/Buttons/PrimaryButton';

export default function PageNotFound() {
  return (
    <div className='text-center h-[calc(100vh-380px)] flex justify-center items-center flex-col'>
      <span className='text-[100px] md:text-[200px] text-primary'>404</span>
      <h1 className='text-4.5xl text-neutral-700 font-IBM font-medium'>
        Pagina non trovata
      </h1>
      <p className='mb-16'>
        Il link a questa pagina potrebbe essere errato, non aggiornato oppure
        non hai permessi per visualizzarla.
      </p>
      <PrimaryButton>
        <Link to={'/'}>Torna alla Home</Link>
      </PrimaryButton>
    </div>
  );
}

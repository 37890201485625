import React from 'react';
import { match } from 'ts-pattern';
import { UseQueryResult, useMutation } from 'react-query';
import receipts from '../../../api/receipts';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { StrapiResponseList } from '../../../interfaces/commons';
import { ReceiptResponse } from '../../../interfaces/receipts';
import { toast } from 'react-toastify';
import ReceiptRow from '../../Tesoreria/Tables/ReceiptRow';

interface UtenteRicevuteTableProps {
  receiptsQuery: UseQueryResult<StrapiResponseList<ReceiptResponse>, unknown>;
  searchingWords: string[];
}
const UtenteRicevuteTable: React.FC<UtenteRicevuteTableProps> = ({
  receiptsQuery,
  searchingWords,
}) => {
  const tableElements = [
    'Nominativo',
    'Numero',
    'Data emissione',
    'Importo',
    'Coupon',
    'Voci che visualizza il partecipante',
    'Causale modifica',
    'Tipologia',
    'Metodo di pagamento',
    'Sezione',
    'N tessera',
    'Codice fiscale',
    '',
    '',
    '',
  ];

  const { mutate: mutateReceipt } = useMutation(
    'downloadUserReceipt',
    receipts.downloadReceipt,
    {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto');
      },
      onSuccess: () => {
        toast.success('Ricevuta scaricata con successo');
      },
    }
  );

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(receiptsQuery)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              return (
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                    <tr>
                      {tableElements.map((element, idx) => (
                        <th
                          key={idx}
                          className='font-normal px-6 py-3 text-left tracking-wide'
                        >
                          {element}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {receiptsQuery?.data?.data?.map((receipt) => {
                      return (
                        <ReceiptRow
                          key={receipt.id}
                          receipt={receipt}
                          searchWords={searchingWords || []}
                        />
                      );
                    })}
                  </tbody>
                </table>
              );
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default UtenteRicevuteTable;

import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import NoteInputTable from '../../../components/Form/NoteInputTable';
import PriceInputTable from '../../../components/Form/PriceInputTable';

import { EventRequest } from '../../../interfaces/events';

import { formatCurrency } from '../../../libs/utils/formatters/currency';
import BalanceTableHeaders from '../../Corsi/Tables/Commons/BalanceTableHeader';

interface HeadquarterBalanceTableProps {
  type: 'evento' | 'corso';
  estimatedOutflow?: number;
  finalOutflow?: number;
  disabled?: boolean;
  isEventPublished?: boolean;
}

const HeadquarterBalanceTable: React.FC<HeadquarterBalanceTableProps> = ({
  estimatedOutflow,
  finalOutflow,
  disabled,
  isEventPublished,
}) => {
  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden border'>
          <table className='min-w-full divide-y divide-gray-300'>
            <BalanceTableHeaders />
            <tbody className='divide-y divide-gray-200 bg-white'>
              <CardsRow disabled={disabled} />
              <TeachingMaterialRow
                disabled={disabled}
                isEventPublished={isEventPublished}
              />
              <OutflowRow
                estimatedOutflow={estimatedOutflow}
                finalOutflow={finalOutflow}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const CardsRow = ({ disabled = false }) => {
  const { watch, register } = useFormContext<EventRequest>();
  const cards = useMemo(
    () => watch('headquarterBalance').cards,
    [watch('headquarterBalance')]
  );
  return (
    <tr>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Quota tessera
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(cards?.estimatedPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.estimatedOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(cards?.estimatedIncome)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.finalOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(cards?.finalIncome)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='headquarterBalance.cards.notes'
          name='headquarterBalance.cards.notes'
          type='text'
          register={register}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const TeachingMaterialRow = ({
  disabled = false,
  isEventPublished = false,
}) => {
  const { watch, register } = useFormContext<EventRequest>();
  const teachingMaterial = useMemo(
    () => watch('headquarterBalance').teachingMaterial,
    [watch('headquarterBalance')]
  );

  const isTouched = useMemo(
    () => watch('headquarterBalance.teachingMaterial.isTouched'),
    [watch('headquarterBalance.teachingMaterial.isTouched')]
  );

  return (
    <tr>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Quota materiale didattico
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='headquarterBalance.teachingMaterial.estimatedPriceUnit'
          name='headquarterBalance.teachingMaterial.estimatedPriceUnit'
          type='number'
          // min={minimumEstimatedOutflow}
          disabled={disabled || isEventPublished}
          register={register}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teachingMaterial.estimatedOutflow)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teachingMaterial.estimatedIncome)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teachingMaterial.finalPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='headquarterBalance.teachingMaterial.finalOutflow'
          name='headquarterBalance.teachingMaterial.finalOutflow'
          type='number'
          className={isTouched ? 'bg-gray-500/25' : ''}
          // min={minimumFinalOutflow}
          disabled={disabled}
          register={register}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teachingMaterial.finalIncome)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='headquarterBalance.teachingMaterial.notes'
          name='headquarterBalance.teachingMaterial.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const OutflowRow = ({ estimatedOutflow = 0, finalOutflow = 0 }) => {
  return (
    <tr className='bg-gray-50'>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r uppercase'>
        Totale spese
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium'>
        {formatCurrency(estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'></td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium'>
        {formatCurrency(finalOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'></td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
    </tr>
  );
};

export default HeadquarterBalanceTable;

import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import { ParticipantsResponse } from '../../../interfaces/participants';
import { Payment } from '../../../interfaces/payments';
import UserCourseRow from './UserCourseRow';
import { StrapiResponseList } from '../../../interfaces/commons';

const tableElements = [
  'Data corso',
  'Corso',
  'Sezione',
  'Esame',
  'Voto',
  'Quota',
  'Coupon utilizzato',
  'Stato pagamento',
  'Metodo di pagamento',
  'Stato iscrizione',
  ' ',
];

interface Props {
  participantsQuery: UseQueryResult<
    StrapiResponseList<ParticipantsResponse>,
    unknown
  >;
  selectedParticipants: Partial<
    ParticipantsResponse & {
      multiple?: boolean | undefined;
    }
  >[];
  checked: boolean;
  onCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedParticipants: React.Dispatch<
    React.SetStateAction<
      Partial<
        ParticipantsResponse & {
          multiple?: boolean | undefined;
        }
      >[]
    >
  >;
  onUpdateParticipant: (vote: string, cumLaude: boolean) => void;
  onUpdatePayment: ({
    payment,
    id,
  }: {
    payment: Partial<Payment>;
    id?: number;
  }) => void;
  searchWords: string[];
  isLoading: boolean;
}

const UtenteCorsi: React.FC<Props> = ({
  participantsQuery,
  selectedParticipants,
  checked,
  onCheckAll,
  setSelectedParticipants,
  onUpdateParticipant,
  onUpdatePayment,
  searchWords,
  isLoading,
}) => {
  return (
    <>
      {match(participantsQuery)
        .with({ status: 'success' }, (participantsQuery) => {
          const { data: participantsData } = participantsQuery;
          return (
            <div className='-my-2 overflow-x-auto'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='relative overflow-hidden ring-1 border ring-black ring-opacity-5'>
                  <table className='min-w-full table-fixed divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr className='custom-padding-table-course'>
                        {/* <th
                          scope='col'
                          className='relative w-12 px-6 sm:w-16 sm:px-8'
                        >
                          <Checkbox
                            checked={checked}
                            onChange={(e) => onCheckAll(e)}
                          />
                        </th> */}
                        {tableElements.map((element, idx) => (
                          <th
                            key={`table-header-${idx}`}
                            className='font-normal px-3 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {participantsData?.data?.map((participant, idx) => (
                        <UserCourseRow
                          key={`user-course-row-${idx}`}
                          participant={participant}
                          onUpdateParticipant={onUpdateParticipant}
                          onUpdatePayment={onUpdatePayment}
                          selectedParticipants={selectedParticipants}
                          searchWords={searchWords}
                          setSelectedParticipants={setSelectedParticipants}
                          isLoading={isLoading}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })
        .with({ status: 'idle' }, { status: 'loading' }, () => {
          return <p>Caricamento partecipanti...</p>;
        })
        .with({ status: 'error' }, () => {
          return <p>Errore caricamento partecipanti...</p>;
        })
        .exhaustive()}
    </>
  );
};

export default UtenteCorsi;

import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { listCoupons } from "../../../api/coupon";
import FilterCoupon from "../../../components/Filters/FilterCoupon";
import Pagination from "../../../components/Pagination";
import TitlePage from "../../../components/TitlePage";
import H1Styled from "../../../components/Typography/H1Styled";
import { useAuth } from "../../../contexts/Auth";
import CouponTable from "../Tables/CouponTable";

const ListaCoupon = () => {
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const { REACT_APP_DEBOUNCE_DELAY } = process.env;
  const delay = Number(REACT_APP_DEBOUNCE_DELAY);
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const query = useQuery(["coupons", ...debounceSearchParams], () =>
    listCoupons(debounceSearchParams, token)
  );

  return (
    <>
      <TitlePage title='Lista Coupon' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Coupon</H1Styled>

        <div className='mt-10 space-y-20'>
          <FilterCoupon event single active searching />
          <CouponTable query={query} />
          <Pagination pagination={query.data?.meta.pagination} />
        </div>
      </div>
    </>
  );
};

export default ListaCoupon;

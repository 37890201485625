import { StrapiResponseList } from './../interfaces/commons';
import axios from 'axios';
import qs from 'qs';
import {
  EventBalance,
  EventListResponse,
  EventReport,
  EventRequest,
  EventResponse,
  EventResponseListElement,
  EventStatus,
} from '../interfaces/events';
import { Params } from '../interfaces/params';
import { eventStatus } from '../constants/events';

const { REACT_APP_API_URL } = process.env;

const event = axios.create({
  baseURL: REACT_APP_API_URL + '/events',
});

const instance = axios.create();

export const listEvents = async (
  searchParams: any,
  token: string | null,
  pageAttrName?: string,
  pageSizeAttrName?: string
) => {
  const selectedStatus = eventStatus.filter(({ title }) =>
    searchParams.getAll('status').find((v: string) => v === title)
  );
  const statusArr = selectedStatus.map((object) => object.id);

  const query = {
    populate: [
      'partecipations.guests',
      'section',
      'balance.price',
      'balance.contributes',
      'balance.wines',
      'balance.food',
      'balance.rooms',
      'balance.sponsorship',
      'balance.contributes',
      'balance.extras',
      'balance.teacherPayments',
      'balance.teacherRefunds',
      'balance.direction',
      'headquarterBalance.cards',
      'headquarterBalance.administrations',
      'headquarterBalance.teachingMaterial',
      'headquarterBalance.shipping',
    ],
    sort: 'startDate:desc',
    pagination: {
      page: searchParams.get(pageAttrName || 'page'),
      pageSize: searchParams.get(pageSizeAttrName || 'pageSize'),
    },
    filters: {
      title: { $contains: searchParams.get('search') },
      startDate: { $contains: searchParams.getAll('year') },
      $or: searchParams
        .getAll('section')
        .map((sectionName: string) => ({ section: { name: sectionName } })),
      $and: [
        {
          startDate: {
            $lte: searchParams.get('dateTo'),
            $gte: searchParams.get('dateFrom'),
          },
        },
        { status: { $notNull: true, $in: statusArr } },
      ],
    },
  };

  const { data } = await axios.get<EventListResponse>(
    `${REACT_APP_API_URL}/events?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const eventsListForStats = async (
  searchParams: any,
  token: string | null,
  pageAttrName?: string,
  pageSizeAttrName?: string
) => {
  const query = {
    populate: [
      '*',
      'partecipations.guests',
      'section',
      'balance.price',
      'balance.contributes',
      'balance.wines',
      'balance.food',
      'balance.rooms',
      'balance.sponsorship',
      'balance.contributes',
      'balance.extras',
      'balance.teacherPayments',
      'balance.couponsExpenses',
      'balance.couponsVirtuosity',
      'balance.discountExpenses',
      'balance.teacherRefunds',
      'balance.direction',
      'headquarterBalance.cards',
      'headquarterBalance.administrations',
      'headquarterBalance.teachingMaterial',
      'headquarterBalance.shipping',
    ],
    sort: 'startDate:desc',

    pagination: {
      page: searchParams.get(pageAttrName || 'page'),
      pageSize: searchParams.get(pageSizeAttrName || 'pageSize'),
    },
    filters: {
      section: {
        id: {
          $in: Object.entries(qs.parse(searchParams.get('sections') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.value),
        },
      },
      status: {
        $notNull: true,
        $in: [
          EventStatus.PUBLISHED,
          EventStatus.FINAL_BALANCE_DELEGATION,
          EventStatus.FINAL_BALANCE_NATIONAL,
        ],
      },
      $and: [
        {
          startDate: {
            $gte: new Date(searchParams.get('year'), 0, 1),
            $lte:
              searchParams.get('year') === new Date().getFullYear()
                ? new Date()
                : new Date(searchParams.get('year'), 11, 31),
          },
        },
      ],
    },
  };

  const { data } = await axios.get<EventListResponse>(
    `${REACT_APP_API_URL}/events?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const listEventsFilter = async (
  { search, pageSize, page }: Params,
  token: string | null
) => {
  const query = {
    populate: '*',

    pagination: {
      page: page,
      pageSize: pageSize || 20,
    },
    filters: {
      title: { $contains: search },
    },
  };

  const { data } = await axios.get<EventListResponse>(
    `${REACT_APP_API_URL}/events?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createEvent = async (newEvent: EventRequest, token: string) => {
  const formattedNewEvent = {
    ...newEvent,
  };
  const { data } = await event.post<EventResponse>(
    '',
    { data: formattedNewEvent },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const fetchEventsForCoupon = async ({
  query = {},
  token,
}: {
  query?: object;
  token: string | null;
}) => {
  const queryString = qs.stringify({
    populate: '*',
    pagination: {
      pageSize: 50000, // da valutare una soluzione diversa in futuro quando i corsi saranno tanti
    },
    ...query,
  });

  const { data } = await axios.get<
    StrapiResponseList<EventResponseListElement>
  >(`${REACT_APP_API_URL}/events?${queryString}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
};

export const updateEvent = async (
  eventId: string,
  updatedEvent: EventRequest,
  token: string | null
) => {
  const formattedNewEvent = {
    ...updatedEvent,
  };
  //non passiamo i partecipanti nell'update dell'evento: non si impostano con questa API
  delete formattedNewEvent.partecipations;

  const { data } = await event.put<EventResponse>(
    eventId,
    { data: formattedNewEvent },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const findEventById = async (eventId: string, token: string | null) => {
  const queryParams = qs.stringify({
    populate: [
      'section',
      'location',
      'minProfileCategorySub',
      'partecipations',
      'details',
      'details.otherInfos',
      'image',
      'balance.price',
      'balance.contributes',
      'balance.wines',
      'balance.food',
      'balance.rooms',
      'balance.sponsorship',
      'balance.contributes',
      'balance.extras',
      'balance.teacherPayments',
      'balance.teacherRefunds',
      'balance.direction',
      'carousel',
      'headquarterBalance.cards',
      'headquarterBalance.administrations',
      'headquarterBalance.teachingMaterial',
      'headquarterBalance.shipping',
      'balance.discountExpenses',
      'balance.couponsVirtuosity',
      'balance.couponsExpenses',
      'wines',
    ],
  });

  const eventResponse = await instance.get<EventResponse>(
    `${REACT_APP_API_URL}/admin/events/${eventId}?${queryParams}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return eventResponse;
};

export const addPartecipations = async (
  eventId: string,
  token: string | null,
  profileIds: number[]
) => {
  const addPartecipationsResponse = await event.post(
    eventId + '/subscribe',
    {
      data: profileIds,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return addPartecipationsResponse;
};

export const find = async ({
  token,
  query,
}: {
  token: string | null;
  query: object;
}) => {
  const { data } = await axios.get<EventListResponse>(
    `${REACT_APP_API_URL}/events?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const filtersFind = async ({
  token,
  query = {},
}: {
  token: string | null;
  query?: any;
}) => {
  const statusFilters = {
    $or: [
      { status: EventStatus.PUBLISHED },
      { status: EventStatus.FINAL_BALANCE_DELEGATION },
      { status: EventStatus.FINAL_BALANCE_NATIONAL },
    ],
  };

  const queryString = qs.stringify({
    ...query,
    filters: {
      $and: [statusFilters, query.filters],
    },
    pagination: {
      pageSize: 50000,
    },
  });

  const { data } = await axios.get<StrapiResponseList<EventResponse>>(
    `${REACT_APP_API_URL}/events?${queryString}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const deleteEventById = async (
  eventId: string,
  token: string | null
) => {
  const eventResponse = await event.delete<EventResponse>(eventId, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  return eventResponse;
};

export const findReport = async ({
  token,
  id,
}: {
  token: string | null;
  id?: number;
}) => {
  const { data } = await axios.get<EventReport>(
    `${REACT_APP_API_URL}/events/${id}/report`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const findBalance = async ({
  token,
  id,
}: {
  token?: string | null;
  id?: string;
}) => {
  const { data } = await axios.get<EventBalance>(
    `${REACT_APP_API_URL}/events/${id}/balance`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const downloadCostsCSV = async ({
  token,
  id,
  fileName,
}: {
  token?: string | null;
  id?: string;
  fileName: string;
}) => {
  const { data } = await instance.get(
    `${REACT_APP_API_URL}/events/${id}/costs-csv`,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const eventsAPIs = {
  find,
  filtersFind,
  findReport,
  findBalance,
  downloadCostsCSV,
};

export default eventsAPIs;

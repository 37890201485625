import InputPresentational from './Form/InputPresentational';
import AudienceSelect from './FetchSelect/AudienceSelect';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { AnnouncmentRequest } from '../interfaces/announcments';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadAreaPresentational from './Form/UploadAreaPresentational';
import DocumentUploadPrev from './Form/DocumentUploadPrev';

interface FormNuovoComunicatoProps {
  formMethods?: UseFormReturn<any, any>;
}

const FormNuovoComunicato = ({ formMethods }: FormNuovoComunicatoProps) => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<AnnouncmentRequest>();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const fileSelected = watch('pdf');

  const onFileDelete = () => {
    setValue('pdf', null);
  };

  const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];
  return (
    <div className='grid grid-cols-4 gap-4 pt-6 pb-8 mt-12'>
      <div className='grid grid-cols-1 col-span-12 gap-4 sm:grid-cols-2 xl:col-span-6'>
        <InputPresentational
          id='title'
          label='Titolo'
          name='title'
          type='text'
          register={register}
          required
          error={{ ...errors.title, type: 'required' }}
        />

        <AudienceSelect
          register={register}
          control={control}
          required
          error={{ ...errors.audience, type: 'required' }}
        />
      </div>
      <div className='grid grid-cols-1 col-span-12 gap-4 sm:grid-cols-2 xl:col-span-6'>
        <div className='col-span-4'>
          <label
            htmlFor={'description'}
            className='flex justify-between text-sm text-gray-500 mb-2'
          >
            <div>Testo del messaggio</div>
          </label>
          <ReactQuill
            id='description'
            theme='snow'
            value={watch('description')}
            onChange={(value) => setValue('description', value)}
            modules={modules}
            formats={formats}
          />
          {errors.description && (
            <p className='mt-2 text-sm text-red-500'>
              {errors.description.message}
            </p>
          )}
        </div>
        {/* <input type='file' {...register('pdf')} /> */}
        <div className='space-y-3'>
          <UploadAreaPresentational
            name='pdf'
            label='Carica pdf comunicato'
            formMethods={formMethods}
            acceptedExtensions={['pdf']}
          />
          <DocumentUploadPrev
            documentName={
              (fileSelected?.length && fileSelected[0].name) || undefined
            }
            fieldName='pdf'
            onRemove={onFileDelete}
          />
        </div>
        {/* <SimpleCheckboxPresentational
          label='Inserisci firma'
          describedby=''
          id='signature'
          name='signature'
          register={register}
        /> */}
      </div>
    </div>
  );
};

export default FormNuovoComunicato;

import React, { useState } from 'react';
import { ParticipantsResponse } from '../../../interfaces/participants';
import { mapPaymentMethod } from '../../Tesoreria/utils';
import Highlighter from 'react-highlight-words';
import classNames from '../../../libs/utils/classNames';
import moment from 'moment';
import IconButton from '../../../components/Buttons/IconButton';
import { MedalIcon } from '../../../components/CustomIcons';
import VoteModal from '../../../components/Modals/VoteModal';
import { Level } from '../../../interfaces/commons';
import {
  formatCurrency,
  getCouponTotalHTML,
} from '../../../libs/utils/helpers';
import { ProductType } from '../../../interfaces/orders';
import StatusPaymentModal from '../../../components/Modals/StatusPaymentModal';
import { getParticipantStatus } from '../../Corsi/Tables/SelectPartecipants';
import { Payment } from '../../../interfaces/payments';

interface Props {
  participant: ParticipantsResponse;
  selectedParticipants: Partial<
    ParticipantsResponse & {
      multiple?: boolean | undefined;
    }
  >[];
  setSelectedParticipants: React.Dispatch<
    React.SetStateAction<
      Partial<
        ParticipantsResponse & {
          multiple?: boolean | undefined;
        }
      >[]
    >
  >;
  onUpdateParticipant: (vote: string, cumLaude: boolean) => void;
  onUpdatePayment: ({
    payment,
    id,
  }: {
    payment: Partial<Payment>;
    id?: number;
  }) => void;
  searchWords: string[];
  isLoading: boolean;
}

const UserCourseRow: React.FC<Props> = ({
  participant,
  selectedParticipants,
  searchWords,
  setSelectedParticipants,
  onUpdateParticipant,
  onUpdatePayment,
  isLoading,
}) => {
  const findParticipants =
    participant?.attributes?.profile?.data?.attributes?.orders?.data?.length &&
    participant?.attributes?.profile?.data?.attributes?.orders?.data.find(
      (elem) =>
        elem.attributes.course?.data?.id ===
        participant?.attributes?.course?.data?.id
    );

  const findMethodPayment = () => {
    if (!findParticipants) {
      return '';
    }
    return mapPaymentMethod(
      findParticipants.attributes.payment?.data?.attributes.method
    );
  };
  const [isAssigningVotes, setIsAssigningVotes] = useState<boolean>(false);

  const toggleVotesModal = () => {
    setIsAssigningVotes((v) => !v);
  };
  return (
    <tr
      key={participant.id}
      className={
        selectedParticipants
          .filter((elem) => elem.multiple)
          .find((_participant) => participant.id === _participant.id)
          ? 'bg-sand-50'
          : undefined
      }
    >
      {/* <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                            {selectedParticipants
                              .filter((elem) => elem.multiple)
                              .find(
                                (_participant) =>
                                  participant.id === _participant.id
                              ) && (
                              <div className='absolute inset-y-0 left-0 w-0.5 bg-primary' />
                            )}
                            <Checkbox
                              value={participant.id}
                              checked={
                                !!selectedParticipants
                                  .filter((elem) => elem.multiple)
                                  .find(
                                    (_participant) =>
                                      participant.id === _participant.id
                                  )
                              }
                              onChange={(e) =>
                                setSelectedParticipants(
                                  e.target.checked
                                    ? [
                                        ...selectedParticipants,
                                        { ...participant, multiple: true },
                                      ]
                                    : selectedParticipants.filter(
                                        (p) => p.id !== participant.id
                                      )
                                )
                              }
                            />
                          </td> */}
      <td
        className={classNames(
          'whitespace-nowrap py-4 px-3 text-sm',
          selectedParticipants
            .filter((elem) => elem.multiple)
            .find((_participant) => participant.id === _participant.id)
            ? 'text-primary'
            : 'text-gray-600'
        )}
      >
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={
            moment(
              participant.attributes.course?.data.attributes?.startDate
            ).format('DD/MM/YYYY') || '-'
          }
        />
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={
            participant.attributes.course?.data?.attributes?.title || '-'
          }
        />
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={
            participant.attributes.course?.data?.attributes?.section?.data
              ?.attributes?.name + '' || '-'
          }
        />
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <IconButton
          redTxt
          disabled={!!participant.attributes.evaluation}
          onClick={() => {
            toggleVotesModal();
            setSelectedParticipants([participant]);
          }}
        >
          <MedalIcon
            fill={participant.attributes.evaluation ? '#6e0028' : undefined}
            disabled={!!participant.attributes.evaluation}
          />
        </IconButton>
        <VoteModal
          isOpen={isAssigningVotes}
          toggle={toggleVotesModal}
          partecipantName={`${selectedParticipants.map(
            (elem) =>
              ` ${elem.attributes?.profile?.data?.attributes.name} ${elem.attributes?.profile?.data?.attributes.surname}`
          )}`}
          onConfirm={(vote, cumLaude = false) =>
            onUpdateParticipant(vote, cumLaude)
          }
          course={participant.attributes.course?.data?.attributes}
        />
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        {participant.attributes.course?.data?.attributes?.level ===
        Level.PRIMO_LIVELLO ? (
          '-'
        ) : participant.attributes.evaluation ? (
          <>
            <span className='text-gray-800 font-IBM font-medium text-lg'>
              <Highlighter
                highlightClassName='p-1 rounded-md'
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={`${participant.attributes.evaluation}${
                  participant.attributes.cumLaude ? 'L' : ''
                }`}
              />
            </span>
            {` / 30`}
          </>
        ) : (
          '-'
        )}
      </td>
      <td className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={formatCurrency(
            participant.attributes.profile?.data.attributes.orders.data.find(
              (elem) =>
                elem?.attributes.productType === ProductType.COURSE &&
                elem?.attributes?.course?.data?.id ===
                  participant?.attributes?.course?.data?.id
            )?.attributes?.payment?.data?.attributes?.amount
          )}
        />
      </td>
      <td className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
        <p
          dangerouslySetInnerHTML={{
            __html: getCouponTotalHTML(
              participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                (elem) =>
                  elem?.attributes?.course?.data?.id ===
                  participant?.attributes?.course?.data?.id
              )?.attributes?.coupon?.data
            ),
          }}
        />
      </td>

      <td className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
        <StatusPaymentModal
          participant={participant}
          onSubmit={(data) => onUpdatePayment(data)}
          entity='course'
          isLoading={isLoading}
        />
      </td>
      <td className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={findMethodPayment() || '-'}
        />
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-not-allowed'>
        {getParticipantStatus(participant.attributes.status)}
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
        {/* <Underline
                              href='/corsi/dettaglio-corso/partecipanti/crea-ricevuta-template'
                              label='Crea ricevuta'
                            /> */}
        {/* Se la ricevuta è già stata creata visualizzare questo
                      <Underline href="" label="Visualizza ricevuta" /> 
                    */}
      </td>
    </tr>
  );
};

export default UserCourseRow;

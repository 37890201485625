import { useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { PlusIcon } from '@heroicons/react/outline';
import * as Yup from 'yup';
import BaseModal from './BaseModal';
import ProfileCategorySelect from '../FetchSelect/ProfileCategorySelect';
import { useForm } from 'react-hook-form';
import useYupValidationResolver from '../../libs/YupValidationResolver';

interface Props {
  onConfirm: (categoryId: number) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const AssignCategoryModal: React.FC<Props> = ({
  onConfirm,
  disabled,
  isLoading,
}) => {
  const [isOpen, setOpen] = useState(false);

  const { control, handleSubmit, reset, formState } = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(
      Yup.object().shape({
        category: Yup.number().required('Questo campo è obbligatorio'),
      })
    ),
  });

  const toggleModal = () => {
    setOpen((v) => !v);
    reset({});
  };

  const onSubmit = (values: any) => {
    onConfirm(values.category);
    toggleModal();
  };

  return (
    <>
      <UnderlineButton disabled={disabled} onClick={() => setOpen(true)}>
        <PlusIcon className='w-4 h-4' /> Assegna categoria
      </UnderlineButton>
      <BaseModal
        title={'Assegna categoria'}
        isOpen={isOpen}
        toggle={toggleModal}
        subtitle={
          'Seleziona e assegna una nuova categoria - titolo ONAV agli utenti selezionati.'
        }
        className={'mx-auto md:w-[500px] w-11/12'}
        hideBottom
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <br />
          <ProfileCategorySelect
            label='categoria'
            name='category'
            control={control}
          />
          {formState.errors.category && (
            <span className='text-sm text-red-500'>
              Questo campo è obbligatorio
            </span>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className='flex items-center justify-end gap-4 mt-6'>
            <PrimaryButton onClick={toggleModal} textSmall>
              {'Annulla'}
            </PrimaryButton>
            <PrimaryButton
              type='submit'
              small
              isLoading={isLoading}
              disabled={isLoading}
            >
              {'Conferma'}
            </PrimaryButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
};

export default AssignCategoryModal;

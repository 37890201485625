import React, { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from '../../libs/utils/classNames';
import { FieldError, useController, UseControllerProps } from 'react-hook-form';

interface IOption {
  value: any; //id
  label: string; //name
}

interface InputSelectPresentationalProps {
  label: string;
  options: IOption[];
  disabled?: boolean;
  required?: boolean;
  error?: FieldError;
}
const InputSelectPresentational = (
  props: InputSelectPresentationalProps & UseControllerProps<any>
) => {
  const { label, options, defaultValue, name, disabled, required, error } =
    props;
  const defaultOption =
    defaultValue && options?.find((option) => option.value === defaultValue);
  const [selected, setSelected] = useState<IOption | undefined>(defaultOption);
  const [query, setQuery] = useState('');

  const {
    field: { value, onChange },
  } = useController(props);

  useEffect(() => {
    let valueId: number | undefined;

    if (typeof value === 'number') {
      valueId = value;
    } else if (typeof value === 'object') valueId = value.value;
    else valueId = undefined;

    setSelected(options?.find((option) => option.value === valueId));
  }, [value, options]);

  const filteredOptions =
    query === ''
      ? options
      : options?.filter((option) => {
          const inputWords = query.toLowerCase().split(' ');
          return inputWords.every((word) =>
            option.label?.toLowerCase().includes(word)
          );
        });

  return (
    <div className='w-full'>
      <Combobox
        disabled={disabled}
        name={name}
        value={selected}
        onChange={onChange}
      >
        {label && (
          <Combobox.Label className='block text-sm text-gray-500'>
            {`${label}${required ? ' *' : ''}`}
          </Combobox.Label>
        )}
        <div className='relative'>
          <Combobox.Input
            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
              error?.message
                ? 'border-red-100 focus:border-red-100 border-[1px]'
                : ''
            }`}
            displayValue={(option: IOption) => option && option.label}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
          />
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </Combobox.Button>

          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
              {options && options.length > 0 ? (
                filteredOptions.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                    Nessuna location trovata.
                  </div>
                ) : (
                  filteredOptions?.map((option) => (
                    <Combobox.Option
                      key={option.value}
                      value={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-primary' : 'text-gray-800',
                          'cursor-default select-none relative py-2 pl-8 pr-4 z-30'
                        )
                      }
                    >
                      {option.label}
                    </Combobox.Option>
                  ))
                )
              ) : (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  Nessuna location trovata. Assicurati di aver selezionato una
                  sezione.
                </div>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};
export default InputSelectPresentational;

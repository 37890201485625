import axios from 'axios';
import { LabelForm } from '../interfaces/labels';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

const create = async ({
  token,
  body,
  filename
}: {
  token?: string | null;
  body: LabelForm & {recipients: number[]}
  filename:string
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/labels/print`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${filename}.pdf`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const labels = { create };

export default labels;

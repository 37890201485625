import { PencilIcon } from '@heroicons/react/outline';
import React from 'react';
import { UseQueryResult } from 'react-query';
import IconLink from '../../../components/TextLink/IconLink';
import { match } from 'ts-pattern';
import { Section } from '../../../interfaces/section';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { StrapiResponseList } from '../../../interfaces/commons';
import { useNavigate } from 'react-router-dom';
import DeleteSectionModal from '../../../components/Modals/DeleteSectionModal';
import { useAuth } from '../../../contexts/Auth';
import { isSegreteriaNazionale } from '../../../libs/utils/auth';

interface SectionTableProps {
  query: UseQueryResult<StrapiResponseList<Section>>;
}

const SectionTable = ({ query }: SectionTableProps) => {
  const tableElements = [
    'Centro di Costo',
    'Sezione',
    'Provincia',
    'Regione',
    '',
    '',
  ];
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  return (
    <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8'>
        <div className='overflow-hidden border ring-1 ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='text-sm text-gray-500 uppercase bg-gray-50'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='px-6 py-3 font-normal tracking-wide text-left'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {query.data.data.map((section) => (
                        <tr
                          key={section.id}
                          className='cursor-pointer hover:bg-neutral-100'
                          onClick={() => {
                            navigate(`modifica/${section.id}`);
                          }}
                        >
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {section.attributes.costCenter}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {section.attributes.name}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {
                              section.attributes?.province?.data?.attributes
                                .name
                            }
                          </td>

                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {section.attributes?.region?.data?.attributes.name}
                          </td>

                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            <IconLink orangeTxt href={`modifica/${section.id}`}>
                              <PencilIcon className='w-6 h-6' />
                            </IconLink>
                          </td>
                          {isSegreteriaNazionale(profile) && (
                            <td
                              className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap '
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteSectionModal
                                sectionId={section.id}
                                sectionName={section.attributes.name}
                                buttonType='icon'
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='my-20 text-center text-gray-500'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default SectionTable;

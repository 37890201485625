import React from 'react';
import {
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
} from '@heroicons/react/solid';
import { PaymentStatus } from '../interfaces/payments';

interface Props {
  status?: PaymentStatus;
  className?: string;
}
const PaymentBadge: React.FC<Props> = ({ className, status }) => {
  return (
    <div className={'flex justify-center ' + className}>{getBadge(status)}</div>
  );
};

const getBadge = (status?: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.COMPLETED:
      return <CheckCircleIcon className='w-6 h-6 text-green-600' />;
    case PaymentStatus.PENDING:
      return <ClockIcon className='w-6 h-6 text-yellow-600' />;
    default:
      return <XCircleIcon className='w-6 h-6 text-red-700' />;
  }
};

export default PaymentBadge;

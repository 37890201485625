import React from "react";

interface CoccardaLeftProps {
  property: string;
}
const CoccardaLeft: React.FC<CoccardaLeftProps> = ({ property }) => {
  return (
    <svg
      id="Coccarda_left"
      data-name="Coccarda left"
      xmlns="http://www.w3.org/2000/svg"
      width="24.976"
      height="52.331"
      viewBox="0 0 24.976 52.331"
      className={property}
    >
      <path
        id="Tracciato_468"
        data-name="Tracciato 468"
        d="M36.054,83.868s-5.171-1.412-7.8,1.509C28.25,85.377,32.878,87.974,36.054,83.868Z"
        transform="translate(-11.45 -33.856)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_469"
        data-name="Tracciato 469"
        d="M35.4,80.185a3.775,3.775,0,0,1-.309-2.272,5.759,5.759,0,0,1,3.742,3.5A12.7,12.7,0,0,0,35.4,80.185Z"
        transform="translate(-14.201 -31.58)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_470"
        data-name="Tracciato 470"
        d="M25.679,80.436s-4.631-2.706-7.927-.572C17.752,79.864,21.554,83.576,25.679,80.436Z"
        transform="translate(-7.195 -32.039)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_471"
        data-name="Tracciato 471"
        d="M26.137,76.059a3.8,3.8,0,0,1,.287-2.277,5.777,5.777,0,0,1,2.714,4.35A12.786,12.786,0,0,0,26.137,76.059Z"
        transform="translate(-10.577 -29.906)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_472"
        data-name="Tracciato 472"
        d="M16.723,74.146s-3.776-3.818-7.51-2.613C9.213,71.532,11.929,76.1,16.723,74.146Z"
        transform="translate(-3.734 -28.899)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_473"
        data-name="Tracciato 473"
        d="M18.063,69.715a3.774,3.774,0,0,1,.861-2.123,5.784,5.784,0,0,1,1.5,4.907A12.762,12.762,0,0,0,18.063,69.715Z"
        transform="translate(-7.321 -27.397)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_474"
        data-name="Tracciato 474"
        d="M9.794,65.51S7.13,60.841,3.211,61.033C3.211,61.033,4.658,66.158,9.794,65.51Z"
        transform="translate(-1.301 -24.736)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_475"
        data-name="Tracciato 475"
        d="M11.9,61.582a3.773,3.773,0,0,1,1.381-1.826,5.794,5.794,0,0,1,.185,5.13A12.9,12.9,0,0,0,11.9,61.582Z"
        transform="translate(-4.824 -24.221)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_476"
        data-name="Tracciato 476"
        d="M5.365,55.221S4,50.018.16,49.186C.16,49.186.238,54.511,5.365,55.221Z"
        transform="translate(-0.065 -19.936)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_477"
        data-name="Tracciato 477"
        d="M8.09,52.215a3.746,3.746,0,0,1,1.8-1.4,5.791,5.791,0,0,1-1.142,5A12.708,12.708,0,0,0,8.09,52.215Z"
        transform="translate(-3.279 -20.595)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_478"
        data-name="Tracciato 478"
        d="M3.632,44.128s.018-5.382-3.47-7.182C.162,36.946-1.138,42.109,3.632,44.128Z"
        transform="translate(0 -14.975)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_479"
        data-name="Tracciato 479"
        d="M6.706,42.253a3.733,3.733,0,0,1,2.1-.888A5.778,5.778,0,0,1,6.418,45.9,12.841,12.841,0,0,0,6.706,42.253Z"
        transform="translate(-2.601 -16.766)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_480"
        data-name="Tracciato 480"
        d="M4.482,32.989s1.405-5.192-1.5-7.84C2.98,25.15.4,29.8,4.482,32.989Z"
        transform="translate(-0.881 -10.194)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_481"
        data-name="Tracciato 481"
        d="M7.581,32.338a3.73,3.73,0,0,1,2.262-.311,5.759,5.759,0,0,1-3.48,3.759A12.853,12.853,0,0,0,7.581,32.338Z"
        transform="translate(-2.579 -12.959)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_482"
        data-name="Tracciato 482"
        d="M7.9,22.566s2.693-4.652.569-7.963C8.471,14.6,4.776,18.422,7.9,22.566Z"
        transform="translate(-2.694 -5.919)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_483"
        data-name="Tracciato 483"
        d="M11.109,23.027a3.747,3.747,0,0,1,2.267.289,5.742,5.742,0,0,1-4.33,2.726A12.838,12.838,0,0,0,11.109,23.027Z"
        transform="translate(-3.666 -9.316)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_484"
        data-name="Tracciato 484"
        d="M13.7,13.57s3.8-3.793,2.6-7.544C16.3,6.026,11.751,8.754,13.7,13.57Z"
        transform="translate(-5.357 -2.443)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_485"
        data-name="Tracciato 485"
        d="M17.047,14.917a3.746,3.746,0,0,1,2.113.865,5.721,5.721,0,0,1-4.885,1.507A12.639,12.639,0,0,0,17.047,14.917Z"
        transform="translate(-5.786 -6.046)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_486"
        data-name="Tracciato 486"
        d="M21.482,6.61S26.13,3.935,25.939,0C25.939,0,20.839,1.451,21.482,6.61Z"
        transform="translate(-8.684)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_487"
        data-name="Tracciato 487"
        d="M24.991,8.725a3.764,3.764,0,0,1,1.818,1.387A5.731,5.731,0,0,1,21.7,10.3,12.717,12.717,0,0,0,24.991,8.725Z"
        transform="translate(-8.796 -3.537)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_488"
        data-name="Tracciato 488"
        d="M29.072,5.286s5.252,1.062,7.688-2.024C36.76,3.262,31.971.979,29.072,5.286Z"
        transform="translate(-11.784 -1.077)"
        fill="#ced5de"
      />
    </svg>
  );
};

export default CoccardaLeft;

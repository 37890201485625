import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { LogoutIcon, UserIcon } from "@heroicons/react/solid";
import classNames from "../../libs/utils/classNames";
import { Link } from "react-router-dom";
import { AuthActionType, useAuth } from "../../contexts/Auth";

export default function DropdownProfile() {
  const [{ profile }, dispatchAuthChange] = useAuth();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="text-sm py-2 px-2 bg-primary rounded-full text-white w-max font-normal flex items-center border hover:border-primary duration-300">
          <UserIcon className="w-5 h-5" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/area-riservata/profilo"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-700" : "",
                    "group flex items-center px-4 py-3 text-sm"
                  )}
                >
                  <UserIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Il mio profilo
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/login"
                  onClick={() =>
                    dispatchAuthChange({ type: AuthActionType.Logout })
                  }
                  className={classNames(
                    active ? "bg-gray-100 text-primary" : "text-primary",
                    "group flex items-center px-4 py-3 text-sm"
                  )}
                >
                  <LogoutIcon
                    className="mr-3 h-5 w-5 text-primary group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Logout
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import DeleteAudienceModal from '../../../components/Modals/DeleteAudienceModal';
import { AudiencesListResponse } from '../../../interfaces/audiences';
import QueryString from 'qs';

interface AudienceTableProps {
  query: UseQueryResult<AudiencesListResponse>;
}
const AudienceTable: React.FC<AudienceTableProps> = ({ query }) => {
  const tableElements = ['Titolo', '', ''];

  // if query status is error automatically refresh page
  if (query.status === 'error') {
    window.location.reload();
  }

  const strigifyFilters = (object: any) => {
    const translation: any = {
      isTeacher: 'Docente',
      isVolunteer: 'Volontario',
      onavPosition: 'Carica',
      profileCategories: 'Categoria',
      sections: 'Sezioni',
      printDigitalMagazine: 'Rivista digitale',
      search: '',
      events: 'Eventi',
      courses: 'Corsi',
      isCardPaid: 'Tessera pagata',
    };

    const keys = Object.keys(object);

    if (!keys.length)
      return (
        <p className=' font-thin'>
          <span className=' font-medium'>Nessuno</span> (seleziona tutti gli
          utenti ONAV)
        </p>
      );

    const getValuesList = (key: string, queryString: string) => {
      const filter = QueryString.parse(decodeURI(queryString));
      const filterKeys = Object.keys(filter);

      const values = filterKeys.map((key) => filter[key]);
      return values.map((v: any) => v.label);
    };

    const filterElements: JSX.Element[] = keys.map((key) => {
      switch (key) {
        case 'search':
          return (
            <p>
              <span className=' font-medium'>
                Nome, cognome o email contengono
              </span>{' '}
              "{object[key]}"
            </p>
          );
        case 'sections':
        case 'events':
        case 'courses':
          const values = getValuesList(key, object[key]);
          if (values.length < 1) return <></>;
          return (
            <div>
              <p className='font-medium'>{translation[key]}</p>
              <ul className='list-disc pl-8'>
                {values.map((v) => (
                  <li>{v}</li>
                ))}
              </ul>
            </div>
          );
        case 'profileCategories':
          const valuesProfileCategories = getValuesList(key, object[key]);
          if (valuesProfileCategories.length < 1) return <></>;
          return (
            <div>
              <p className='font-medium'>{translation[key]}</p>
              <ul className='list-disc pl-8'>
                {valuesProfileCategories.map((v) => (
                  <li>{v}</li>
                ))}
              </ul>
            </div>
          );
        default:
          return (
            <p>
              <span className=' font-medium'>{translation[key]}:</span> Si
            </p>
          );
      }
    });

    return filterElements;
  };

  return (
    <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8'>
        <div className='overflow-hidden border ring-1 ring-black ring-opacity-5 '>
          {match(query)
            .with(
              { status: 'idle' },
              { status: 'loading' },
              { status: 'error' },
              () => <TableLoading tableElements={tableElements} />
            )
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='text-sm text-gray-500 uppercase bg-gray-50'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='px-6 py-3 font-normal tracking-wide text-left'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='items-center bg-white divide-y divide-gray-200'>
                      {query.data.data.map((audience, index) => (
                        <tr
                          key={index}
                          className='cursor-pointer hover:bg-neutral-100'
                        >
                          <td className='items-center px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {audience.attributes.title}
                          </td>
                          <td className='px-6 py-3'>
                            {/* <p>
                              {strigifyFilters(
                                audience.attributes.audienceQuery
                              )}
                            </p> */}
                          </td>

                          <td
                            className='flex items-center justify-end px-6 py-4 text-sm text-gray-500 whitespace-nowrap'
                            onClick={(event) => event.stopPropagation()}
                          >
                            <DeleteAudienceModal
                              titleAudience={audience.attributes.title}
                              audienceId={audience.id}
                              buttonType='icon'
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='my-20 text-center text-gray-500'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default AudienceTable;

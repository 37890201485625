import { DownloadIcon } from '@heroicons/react/outline';
import { useMutation, useQuery, UseQueryResult } from 'react-query';
import receipts from '../../../../api/receipts';
import TitlePage from '../../../../components/TitlePage';
import { useAuth } from '../../../../contexts/Auth';
import TesoreriaTable from '../../../Tesoreria/Tables/TesoreriaTable';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import qs from 'qs';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import { FilterType } from '../../../../interfaces/filters';
import { PAYMENT_METHOD_OPTIONS } from '../../../../libs/utils/options';
import { StrapiResponse } from '../../../../interfaces/commons';
import { EventResponse } from '../../../../interfaces/events';
import Pagination from '../../../../components/Pagination';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getDateTimeFromDate } from '../../../../libs/utils/helpers';

interface Props {
  eventDetailQuery: UseQueryResult<
    StrapiResponse<EventResponse> | undefined,
    unknown
  >;
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const RicevuteEvento: React.FC<Props> = ({ eventDetailQuery }) => {
  const [{ token }] = useAuth();
  const { eventId } = useParams();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);

  const queryFiltersReceipts = {
    pagination: {
      page: debounceSearchParams?.get('page'),
      pageSize: debounceSearchParams?.get('pageSize') || 5,
    },

    filters: {
      orders: { event: eventId },
      $and: [
        {
          date: {
            $gt: qs.parse(
              Object.entries(
                qs.parse(debounceSearchParams.get('date') + '')
              ).map(([, value]) => value as any)[0]?.label
            ).from,
            $lt: getDateTimeFromDate(
              qs.parse(
                Object.entries(
                  qs.parse(debounceSearchParams.get('date') + '')
                ).map(([, value]) => value as any)[0]?.label
              ).to
            ),
          },
        },
      ],
      $or: [
        { name: { $contains: debounceSearchParams.get('search') } },
        { code: { $contains: debounceSearchParams.get('search') } },
        { notes: { $contains: debounceSearchParams.get('search') } },
        {
          description: { $contains: debounceSearchParams.get('search') },
        },
        {
          paymentMethod: {
            $contains: debounceSearchParams.get('search'),
          },
        },
        { fiscalCode: { $contains: debounceSearchParams.get('search') } },
        {
          badgeNumber: { $contains: debounceSearchParams.get('search') },
        },
        { type: { $contains: debounceSearchParams.get('search') } },
      ],
      paymentMethod: {
        $in: Object.entries(qs.parse(queryParams.get('paymentMethod') + ''))
          .map(([, value]) => value)
          .map((elem: any) => elem.value),
      },
    },
    populate: ['entries', 'section', 'profile', 'orders.coupon'],
    sort: { name: 'asc' },
  };

  const receiptsQuery = useQuery(
    ['listReceipts', ...debounceSearchParams],
    () =>
      receipts.find({
        token,
        query: queryFiltersReceipts,
      })
  );

  const { mutate: receiptsListCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadUsersCSVList', receipts.downloadCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(receiptsQuery.data?.meta?.pagination?.total) >= 10000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 10000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  return (
    <>
      <TitlePage title='Dettaglio evento | Ricevute' />
      <FiltersBar
        filters={[
          { type: FilterType.DATE, label: 'Data', attribute: 'date' },
          {
            type: FilterType.MULTISELECT,
            attribute: 'paymentMethod',
            label: 'Metodo di pagamento',
            key: 'paymentMethodReceipts',
            source: () => ({
              data: PAYMENT_METHOD_OPTIONS,
            }),
          },
          {
            attribute: 'search',
            type: FilterType.SEARCH_BAR,
            label: 'Cerca...',
          },
        ]}
      />

      <form>
        <div className='pt-6 pb-8 space-y-6'>
          <div className='flex justify-end w-full'>
            <PrimaryButton
              textSmall
              disabled={isDownloadingCSV}
              isLoading={isDownloadingCSV}
              onClick={() => {
                receiptsListCSVMutation({
                  token,
                  body: {},
                  query: queryFiltersReceipts,
                  fileName: `Lista ricevute evento ${
                    eventDetailQuery?.data?.data?.data?.attributes?.title
                  } ONAV ${moment().format('HH[:]MM[:]ss')}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' />{' '}
                <p>Scarica CSV ricevute</p>
              </span>
            </PrimaryButton>
          </div>

          <TesoreriaTable receiptsQuery={receiptsQuery} />
          <Pagination
            pagination={receiptsQuery.data?.meta?.pagination}
            defaultPageSize={5}
          />
        </div>
      </form>
    </>
  );
};

export default RicevuteEvento;

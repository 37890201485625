interface Year {
  id: number;
  title: string;
  name?: string;
}

interface YearFilterBar {
  label: string;
  value: number;
}

const currentYear = new Date().getFullYear();
const years: Year[] = [];
const yearsFilterBar: YearFilterBar[] = [];

for (let year = currentYear + 1; year >= 2022; year--) {
  years.push({
    id: year,
    title: year.toString(),
  });
  yearsFilterBar.push({
    label: year.toString(),
    value: year,
  });
}

export { years, yearsFilterBar };

import { UseQueryResult } from 'react-query';
import BadgeBoolean from '../../../components/BadgeBoolean';
import StatusPaymentModal from '../../../components/Modals/StatusPaymentModal';
import {
  EventParticipant,
  ParticipantsResponse,
} from '../../../interfaces/participants';
import { Payment } from '../../../interfaces/payments';
import { match } from 'ts-pattern';
import Highlighter from 'react-highlight-words';
import classNames from '../../../libs/utils/classNames';
import moment from 'moment';
import { getParticipantStatus } from '../../Corsi/Tables/SelectPartecipants';
import { mapPaymentMethod } from '../../Tesoreria/utils';
import { mapStatusPayment } from '../utils';
import { EventParticipation } from '../../../interfaces/event-participants';
import { StrapiData, StrapiResponseList } from '../../../interfaces/commons';
import {
  formatCurrency,
  getCouponTotalHTML,
} from '../../../libs/utils/helpers';

const tableElements = [
  'Data evento',
  'Evento',
  'Sezione',
  'Presente',
  'Conviventi',
  'Quota',
  'coupon utilizzato',
  'Stato Pagamento',
  'Metodo Pagamento',
  'Stato Iscrizione',
  ' ',
];

interface UtenteEventiProps {
  participantsQuery: UseQueryResult<
    StrapiResponseList<StrapiData<EventParticipant>>,
    unknown
  >;
  selectedParticipants: Partial<
    ParticipantsResponse & {
      multiple?: boolean | undefined;
    }
  >[];
  setSelectedParticipants: React.Dispatch<
    React.SetStateAction<
      Partial<
        ParticipantsResponse & {
          multiple?: boolean | undefined;
        }
      >[]
    >
  >;
  onUpdatePayment: ({
    payment,
    id,
  }: {
    payment: Partial<Payment>;
    id?: number;
  }) => void;
  searchWords: string[];
  isLoading: boolean;
}

const UtenteEventi: React.FC<UtenteEventiProps> = ({
  participantsQuery,
  selectedParticipants,
  searchWords,
  onUpdatePayment,
  isLoading,
}) => {
  return (
    <>
      {match(participantsQuery)
        .with({ status: 'success' }, (participantsQuery) => {
          const { data: participantsData } = participantsQuery;

          return (
            <div className='-my-2 overflow-x-auto'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='relative overflow-hidden ring-1 border ring-black ring-opacity-5'>
                  <table className='min-w-full table-fixed divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr className='custom-padding-table-event'>
                        {tableElements.map((element) => (
                          <th className='font-normal px-3 py-3 text-left tracking-wide'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {participantsData?.data?.map((participant) => {
                        const findParticipants =
                          participant?.attributes?.profile?.data?.attributes
                            ?.orders?.data?.length &&
                          participant?.attributes?.profile?.data?.attributes
                            ?.orders?.data[0];

                        const findStatusPayment = () => {
                          if (!findParticipants) {
                            return '';
                          }
                          return mapStatusPayment(
                            findParticipants.attributes.payment?.data
                              ?.attributes.status
                          );
                        };
                        const findMethodPayment = () => {
                          if (!findParticipants) {
                            return '';
                          }
                          return mapPaymentMethod(
                            findParticipants.attributes.payment?.data
                              ?.attributes.method
                          );
                        };

                        return (
                          <tr
                            key={participant.id}
                            className={
                              selectedParticipants
                                .filter((elem) => elem.multiple)
                                .find(
                                  (_participant) =>
                                    participant.id === _participant.id
                                )
                                ? 'bg-sand-50'
                                : undefined
                            }
                          >
                            <td
                              className={classNames(
                                'whitespace-nowrap py-4 px-3 text-sm',
                                selectedParticipants
                                  .filter((elem) => elem.multiple)
                                  .find(
                                    (_participant) =>
                                      participant.id === _participant.id
                                  )
                                  ? 'text-primary'
                                  : 'text-gray-600'
                              )}
                            >
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  moment(
                                    participant?.attributes?.event?.data
                                      .attributes?.startDate
                                  ).format('DD/MM/YYYY') || '-'
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={`${participant?.attributes?.event?.data?.attributes?.title}`}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={`${
                                  participant?.attributes?.event?.data
                                    ?.attributes?.section?.data?.attributes
                                    ?.name + ''
                                }`}
                              />
                            </td>
                            <td className='whitespace-nowrap py-4 text-sm text-gray-500'>
                              <BadgeBoolean
                                active={
                                  participant?.attributes?.isPresent
                                    ? true
                                    : false
                                }
                              />
                            </td>

                            <td className='whitespace-nowrap px-1 py-4 text-sm text-gray-500'>
                              <>
                                <p className='text-gray-500'>
                                  {participant?.attributes?.guests?.length}
                                </p>
                              </>
                            </td>
                            <td className='whitespace-nowrap px-1 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={formatCurrency(
                                  participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                                    (elem) =>
                                      elem?.attributes?.event?.data?.id ===
                                      participant?.attributes?.event?.data?.id
                                  )?.attributes?.payment?.data?.attributes
                                    ?.amount
                                )}
                              />
                            </td>
                            <td className='whitespace-nowrap px-4 py-4 text-sm text-gray-500'>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: getCouponTotalHTML(
                                    participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                                      (elem) =>
                                        elem?.attributes?.event?.data?.id ===
                                        participant?.attributes?.event?.data?.id
                                    )?.attributes?.coupon?.data
                                  ),
                                }}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <StatusPaymentModal
                                participant={participant}
                                onSubmit={(data) => onUpdatePayment(data)}
                                entity='event'
                                isLoading={isLoading}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={findMethodPayment() || '-'}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-not-allowed'>
                              {getParticipantStatus(
                                participant?.attributes?.status
                              )}
                            </td>
                            {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Underline
                                small
                                href=''
                                label='Visualizza ricevuta'
                              />
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })
        .with({ status: 'idle' }, { status: 'loading' }, () => {
          return <p>Caricamento partecipanti...</p>;
        })
        .with({ status: 'error' }, () => {
          return <p>Errore caricamento partecipanti...</p>;
        })
        .exhaustive()}
    </>
  );
};

export default UtenteEventi;

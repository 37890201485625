import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import {
  delLessonTemplate,
  getLessonTemplates,
} from '../../../../api/lesson-template';
import TitlePage from '../../../../components/TitlePage';
import H1Styled from '../../../../components/Typography/H1Styled';
import { useAuth } from '../../../../contexts/Auth';
import LessonsListTable from '../../Tables/LessonsListTable';
import Pagination from '../../../../components/Pagination';
import Filters from '../../../../components/Filters/Filters';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function ListaTemplateLezioni() {
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const query = useQuery(['lessons', ...debounceSearchParams], () =>
    getLessonTemplates(debounceSearchParams, token)
  );

  const deleteLessonTemplate = async (id: string | number) => {
    try {
      await delLessonTemplate(id, token);
      toast.success('Template lezione eliminato', { type: 'success' });
      query.refetch();
    } catch (e) {
      toast.error('Errore eliminiazione template', { type: 'error' });
    }
  };

  return (
    <>
      <TitlePage title='Lista template lezioni' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Lista template lezioni</H1Styled>

        <div className='space-y-5'>
          <Filters lessonCategories endCourse searching />
          <LessonsListTable
            query={query}
            deleteLessonTemplate={deleteLessonTemplate}
          />
          <Pagination pagination={query.data?.meta?.pagination} />
        </div>
      </div>
    </>
  );
}

import React from 'react';
import { FieldError } from 'react-hook-form';

interface PriceInputPresentationalProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id: string;
  label: string;
  name: string;
  type: string;
  register?: any;
  required?: boolean;
  defaultValue?: number;
  readOnly?: boolean;
  error?: FieldError;
  className?: string;
}
const PriceInputPresentational: React.FC<PriceInputPresentationalProps> = ({
  id,
  label,
  name,
  type = 'number',
  required,
  register,
  defaultValue,
  readOnly,
  className,
  error,
  ...otherProps
}) => {
  return (
    <div>
      <label htmlFor={id} className='block text-sm text-gray-500'>
        {`${label}${required ? ' *' : ''}`}
      </label>
      <div className='relative'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
          <span className='text-gray-800 sm:text-sm'>€</span>
        </div>
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={id}
          readOnly={readOnly}
          defaultValue={defaultValue}
          {...(register && register(name, { valueAsNumber: true }))}
          className={`appearance-none block w-full pl-7 pr-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:border-slate-200 ${className} ${
            error?.message
              ? 'border-red-100 focus:border-red-100 border-[1px]'
              : ''
          }`}
          {...otherProps}
        />
      </div>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export default PriceInputPresentational;

import axios from 'axios';
import { Media } from '../../interfaces/media';

const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

const formatIfLocal = (url = '') => {
  if (url.startsWith('/')) {
    return `${BASE_URL}${url}`;
  }
  return url;
};

export const getSmallImage = (media: Media) => {
  const { attributes } = media || {};
  const { formats, url } = attributes || {};
  const image = formats?.small?.url || url;
  return formatIfLocal(image);
};

export const getThumbImage = (media: Media) => {
  const { attributes } = media || {};
  const { formats, url } = attributes || {};
  const image = formats?.thumbnail?.url || url;
  return formatIfLocal(image);
};

export const getImage = (media: Media) => {
  const { attributes } = media || {};
  const { url } = attributes || {};
  return formatIfLocal(url);
};

export const getUrl = (media: Media) => {
  const { attributes } = media || {};
  const { url } = attributes || {};
  return formatIfLocal(url);
};

export const getMediaUrl = (url: string) => {
  if (url.includes('https')) return url;
  if (!url) return 'null';
  return `${BASE_URL}${url}`;
};

export const deleteMedia = async ({
  fileId,
  token,
}: {
  fileId: number | undefined;
  token: string | null;
}) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/upload/files/${fileId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  if (axios.isAxiosError(response)) throw response;

  return response.data;
};

import UploadAreaPresentational from './Form/UploadAreaPresentational';
import DocumentUploadPrev from './Form/DocumentUploadPrev';
import FreeLabel from './Form/FreeLabel';
import InputPresentational from './Form/InputPresentational';
import RadioGroupTeacher from './Form/RadioGroupTeacher';
import TextAreaPresentational from './Form/TextAreaPresentational';
import {
  CourseLessonRequest,
  CourseLessonResponse,
} from '../interfaces/lesson';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import RoundedCheckboxPresentational from './Form/RoundedCheckboxPresentational';
import SelectPresentational from './Form/SelectPresentational';
import { categories } from '../constants/categories';
import { isDelegato } from '../libs/utils/auth';
import { useAuth } from '../contexts/Auth';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useMemo } from 'react';
import UploadedFilePreview from './Form/UploadedFilePreview';
import PriceInputPresentational from './Form/PriceInputPresentational';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';

interface FormEditLessonProps {
  data?: CourseLessonResponse;
  editLessonMethods: UseFormReturn<any, any>;
  onFilesDelete?: (fileId?: number) => void;
  teacherType?: 'teacher' | 'waiverTeacher' | undefined;
  setTeacherType?: any;
}

const FormEditLesson = ({
  data,
  editLessonMethods,
  onFilesDelete,
}: FormEditLessonProps) => {
  const fileSelected = editLessonMethods?.watch('slidePdf');
  const [{ profile }] = useAuth();

  const {
    formState: { errors },
    register,
    control,
    watch,
    setValue,
  } = useFormContext<CourseLessonRequest>();

  const lastAction = useMemo(
    () =>
      data?.attributes?.userActions
        ? data?.attributes?.userActions.at(-1)
        : null,
    [data]
  );

  const onChangeEstimatePayment = (value: number) => {
    const limit = Number(watch('estimatePaymentLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('estimatePayment', limit);
      toast.error(
        "L'importo può essere aumentato solo dalla Segreteria Nazionale"
      );
      return;
    } else if (isDelegato(profile) && Number(value) <= limit) {
      setValue('estimatePayment', value);
    }

    if (!isDelegato(profile)) setValue('estimatePayment', value);
  };

  return (
    <>
      <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6'>
        <div className='space-y-4'>
          <InputPresentational
            id='title'
            label='Titolo'
            name='title'
            type='text'
            register={register}
            required
            error={{ ...errors.title, type: 'required' }}
          />
          <TextAreaPresentational
            id='description'
            label='Descrizione'
            name='description'
            register={register}
            required
            error={{ ...errors.description, type: 'required' }}
          />
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <SelectPresentational
            name='category'
            label='Categoria'
            control={control}
            options={categories}
            required
            error={{ ...errors.category, type: 'required' }}
          />
          <InputPresentational
            id='minutes'
            label='Durata (in minuti)'
            name='minutes'
            type='number'
            register={register}
            required
            error={{ ...errors.minutes, type: 'required' }}
          />

          <InputPresentational
            id='date'
            label='Data e ora'
            name='date'
            type='datetime-local'
            register={register}
            required
            error={{ ...errors.date, type: 'required' }}
          />
          <div className='h-full flex items-center -ml-3'>
            <RoundedCheckboxPresentational
              id='endCourse'
              describedby='endCourse'
              label='Fine corso'
              name='endCourse'
              register={register}
            />
          </div>
        </div>
      </div>
      <div className='pt-6 pb-8'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='col-span-1 md:col-span-2'>
            <FreeLabel label='Scelta docente' />
            <RadioGroupTeacher />
          </div>
          <div className='  py-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
            <PriceInputPresentational
              id='estimatePayment'
              label='Compenso preventivo docente (€)'
              name='estimatePayment'
              type='text'
              value={watch('estimatePayment')}
              onChange={(e) => onChangeEstimatePayment(Number(e.target.value))}
              required
              error={{ ...errors.estimatePayment, type: 'required' }}
            />
            <PriceInputPresentational
              id='estimateRefund'
              label='Rimborso preventivo docente (€)'
              name='estimateRefund'
              type='text'
              register={register}
            />
          </div>

          <div className='  py-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
            {data?.attributes.paymentDate && (
              <>
                <InputPresentational
                  id='paymentDate'
                  label='Data pagamento'
                  name='paymentDate'
                  type='date'
                  required
                  register={register}
                  error={errors.paymentDate}
                />
                <PriceInputPresentational
                  id='actualRefund'
                  label='Rimborso docente'
                  name='actualRefund'
                  type='text'
                  error={errors.actualRefund}
                  register={register}
                />
                <PriceInputPresentational
                  id='actualPayment'
                  label='Compenso docente'
                  name='actualPayment'
                  type='text'
                  register={register}
                  error={errors.actualPayment}
                />
              </>
            )}
          </div>

          <div className='space-y-3'>
            <TextAreaPresentational
              id='wineList'
              label='Lista dei vini'
              name='wineList'
              register={register}
            />
          </div>

          <div className='space-y-3'>
            <UploadAreaPresentational
              label='Slide lezioni'
              name='slidePdf'
              formMethods={editLessonMethods}
              acceptedExtensions={['pdf']}
              disabled={fileSelected?.data || Array.isArray(fileSelected)}
            />

            {!!fileSelected && (
              <UploadedFilePreview
                file={fileSelected}
                onRemove={onFilesDelete}
              />
            )}
          </div>

          <div className='space-y-3'>
            {!watch('isWaiverTeacher') && (
              <SimpleCheckboxPresentational
                id='sendEmailNotificationToTeacher'
                name='sendEmailNotificationToTeacher'
                label='Invia email al docente'
                disabled={
                  !watch('isTeacherChanged') &&
                  watch('sendEmailNotificationToTeacherOldStatus')
                }
                register={register}
              />
            )}

            {/* {lastAction && (
              <span className='text-gray-600'>{`Ultima conferma di aggiornamento con invio email fatta il ${moment(
                lastAction?.date
              ).format('DD/MM/YYYY [alle ore] HH:mm:ss')} da ${
                lastAction?.user?.email
              }`}</span>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormEditLesson;

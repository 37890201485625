import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { postCourseTemplate } from '../../../../api/course-templates';
import TabBar from '../../../../components/TabBars/TabBar';
import { emptyCourseTemplate } from '../../../../constants/course-templates';
import { useAuth } from '../../../../contexts/Auth';
import { CourseTemplateRequest } from '../../../../interfaces/course-templates';
import CostiTemplate from './costi';
import DatiPrincipaliTemplate from './dati-principali';
import DirezioneTemplate from './direzione';
import LezioniTemplate from './lezioni';

import {
  withFilesContext,
  useFilesContext,
} from '../../../../contexts/CourseTemplateFilesContext';
import HeaderTitleTemplate from '../../../../components/HeaderTitleTemplate';
import { toast } from 'react-toastify';
import { IOption } from '../../../../components/Form/Multiselect';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { courseTemplateValidator } from '../../../../validators/courseTemplate';

const NuovoTemplateCorsi = () => {
  const { image, carousel } = useFilesContext();
  const navigate = useNavigate();
  const tabs = [
    {
      label: 'Dati principali',
      href: '/corsi/template/nuovo-corso/dati-principali',
    },
  ];

  const [{ token }] = useAuth();

  const newCourseTemplateMethods = useForm<CourseTemplateRequest>({
    defaultValues: emptyCourseTemplate,
    resolver: useYupValidationResolver(courseTemplateValidator),
  });

  const onSubmit = async () => {
    try {
      const { minProfileCategorySub, canDelegateEdit } =
        newCourseTemplateMethods.getValues();

      const newCourseTemplate = {
        ...newCourseTemplateMethods.getValues(),
        minProfileCategorySub: minProfileCategorySub as IOption,
        canDelegateEdit: canDelegateEdit,
      };

      const { data } = await postCourseTemplate({
        newCourseTemplate,
        image,
        carousel,
        token,
      });

      toast.success('Creazione avvenuta con successo!');
      navigate(`/corsi/template/lista-corsi/${data.id}/dati-principali`);
    } catch (err) {
      toast.error("Qualcosa e' andato storto");
    }
  };

  return (
    <FormProvider {...newCourseTemplateMethods}>
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <HeaderTitleTemplate
          category='Nuovo template'
          title={newCourseTemplateMethods.watch('title')}
          preview
          approvalRequest
          primaryButtonOnClick={onSubmit}
        />
        <TabBar tabs={tabs} />
        <Routes>
          <Route path='dati-principali' element={<DatiPrincipaliTemplate />} />
          <Route path='lezioni' element={<LezioniTemplate />} />
          <Route path='direzione' element={<DirezioneTemplate />} />
          <Route path='costi' element={<CostiTemplate />} />
        </Routes>
      </div>
    </FormProvider>
  );
};

export default withFilesContext(NuovoTemplateCorsi);

import React from 'react';
import { FieldError } from 'react-hook-form';

interface TextAreaPresentationalProps {
  id: string;
  label: string;
  name: string;
  register?: any;
  control?: any;
  required?: boolean;
  defalutValue?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: FieldError;
}
const TextAreaPresentational: React.FC<TextAreaPresentationalProps> = ({
  id,
  label,
  name,
  register,
  control,
  required,
  defalutValue,
  disabled = false,
  readOnly = false,
  error,
}) => {
  return (
    <div>
      <label htmlFor={id} className='block text-sm text-gray-500'>
        {`${label}${required ? ' *' : ''}`}
      </label>
      <div className='mt-1'>
        <textarea
          rows={4}
          name={name}
          id={id}
          className={`block w-full px-3 py-2 text-gray-800 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 disabled:bg-slate-50 disabled:border-slate-200 ${
            error?.message
              ? 'border-red-100 focus:border-red-100 border-[1px]'
              : ''
          }`}
          defaultValue={defalutValue}
          control={control}
          {...(register && register(name, { required }))}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export default TextAreaPresentational;

import { useParams } from 'react-router-dom';
import React from 'react';
import { CourseLessonResponse } from '../../../interfaces/lesson';
import LessonRow from './Commons/LessonRow';
import TableHeader from '../../../components/Table/TableHeader';

interface LessonsDetailCourseTableProps {
  lessons?: CourseLessonResponse[];
  isCourseCanceled?: boolean;
  page?: number | null;
  pageSize?: number | null;
  isDirty?: boolean;
  isDetail?: boolean;
  disabled?: boolean;
  selectedLessons?: Partial<
    CourseLessonResponse & {
      multiple?: boolean | undefined;
    }
  >[];

  setSelectedLessons?: React.Dispatch<
    React.SetStateAction<
      Partial<
        CourseLessonResponse & {
          multiple?: boolean | undefined;
        }
      >[]
    >
  >;
  onCheckAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkedHeader?: boolean;
}
const LessonsDetailCourseTable: React.FC<LessonsDetailCourseTableProps> = ({
  lessons = [],
  isCourseCanceled,
  page,
  pageSize,
  isDirty,
  isDetail,
  disabled,
  setSelectedLessons,
  selectedLessons,
  onCheckAll,
  checkedHeader,
}) => {
  const tableElements = [
    { key: null, label: 'N.' },
    { key: null, label: 'Data' },
    { key: null, label: 'Lezione' },
    { key: null, label: 'Docente' },
    { key: null, label: 'Email inviata' },
    { key: null, label: 'Stato' },
    { key: null, label: 'Compenso' },
    { key: null, label: 'Rimborso' },
    { key: null, label: 'Data pagamento' },
    { key: null, label: ' ' },
  ];

  const { id: courseId } = useParams();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50 text-sm uppercase text-gray-500'></thead>
            {onCheckAll && (
              <TableHeader
                columnsNames={tableElements}
                checked={checkedHeader}
                onCheckAll={onCheckAll}
              />
            )}
            <tbody className='divide-y divide-gray-200 bg-white'>
              {lessons.map((lesson, lessonIdx) => {
                return (
                  <LessonRow
                    key={`lesson-${lessonIdx}`}
                    lesson={lesson}
                    lessonIdx={lessonIdx}
                    selectedLessons={selectedLessons}
                    setSelectedLessons={setSelectedLessons}
                    page={page}
                    pageSize={pageSize}
                    courseId={courseId}
                    isCourseCanceled={isCourseCanceled}
                    isDetail={isDetail}
                    isDirty={isDirty}
                    disabled={disabled}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LessonsDetailCourseTable;

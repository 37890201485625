import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { useAuth } from '../../contexts/Auth';
import { isSegreteriaNazionale } from '../../libs/utils/auth';
import ListaSezioni from './Pages/lista-sezioni';
import ModificaSezione from './Pages/ModificaSezione';
import ModificaLocation from './Pages/ModificaSezione/new-location';
import ModificaMansione from './Pages/ModificaSezione/new-mansione';
import NuovaSezione from './Pages/NuovaSezione';

const Sezioni = () => {
  const [{ profile }] = useAuth();

  const SIDEBAR_ITEMS = [{ href: '/sezioni', label: 'Tutte le sezioni' }];
  if (isSegreteriaNazionale(profile))
    SIDEBAR_ITEMS.push({ href: '/sezioni/nuova', label: 'Nuova sezione' });

  return (
    <div className='grid w-full grid-cols-12 gap-8 px-4 pb-10 pt-7 lg:py-12 lg:px-10'>
      <Sidebar items={SIDEBAR_ITEMS} />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <Routes>
          <Route index element={<ListaSezioni />} />
          <Route path='nuova/*' element={<NuovaSezione />} />
          <Route path='modifica/:id/*' element={<ModificaSezione />} />
          <Route
            path='modifica/:id/location/nuova'
            element={<ModificaLocation />}
          />
          <Route
            path='modifica/:id/location/:locationId'
            element={<ModificaLocation />}
          />
          <Route
            path='modifica/:id/mansioni/nuova'
            element={<ModificaMansione />}
          />
          <Route
            path='modifica/:id/mansioni/:mansioneId'
            element={<ModificaMansione />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Sezioni;

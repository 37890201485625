import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getCourseDetail,
  updateCourse,
  uploadToStrapi,
} from '../../../api/courses';
import HeaderTitle from '../../../components/HeaderTitle';
import StatusCourseModal from '../../../components/Modals/StatusCourseModal';
import TabBar from '../../../components/TabBars/TabBar';
import { emptyCourseTemplate } from '../../../constants/course-templates';
import { useAuth } from '../../../contexts/Auth';
import { withFilesContext } from '../../../contexts/CourseTemplateFilesContext';
import AggiungiLezioneCorso from '../Shared/aggiungi-lezione';
import ModificaLezioneCorso from '../Shared/modifica-lezione';
import DatiPrincipali from './dati-principali';
import Direzione from './direzione';
import Lezioni from './lezioni';
import Location from './location';
import { CourseRequest, CourseResponse } from '../../../interfaces/courses';
import {
  CourseStatus,
  DescriptionList,
  StrapiResponse,
} from '../../../interfaces/commons';
import { IOption } from '../../../components/Form/Multiselect';
import { isDelegato, isSegreteriaNazionale } from '../../../libs/utils/auth';
import CostiCorso from '../Shared/costi';
import CostsCenter from '../Template/ModificaTemplate/costs-center';
import { emptyEvent } from '../../Eventi/utils/emptyItems';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { courseValidator } from '../../../validators/course';
import { useEffect } from 'react';
import moment from 'moment';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';

const NuovoCorsoDaTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [{ token, profile }] = useAuth();

  const newCourseFromTemplateFormMethods = useForm<CourseRequest>({
    defaultValues: emptyCourseTemplate,
    resolver: useYupValidationResolver(courseValidator),
  });
  const isDirty = newCourseFromTemplateFormMethods.formState.isDirty;

  useEffect(() => {
    newCourseFromTemplateFormMethods.setValue('location', undefined);
  }, [newCourseFromTemplateFormMethods.watch('section')]);

  const tabs = [
    {
      label: 'Dati principali',
      href: `/corsi/nuovo-corso/${id}/dati-principali`,
    },
    {
      label: 'Location',
      href: `/corsi/nuovo-corso/${id}/location`,
    },
    {
      label: 'Lezioni',
      href: `/corsi/nuovo-corso/${id}/lezioni`,
    },
    {
      label: 'Direzione',
      href: `/corsi/nuovo-corso/${id}/direzione`,
    },
    {
      label: 'Costi',
      href: `/corsi/nuovo-corso/${id}/costi`,
    },
    {
      label: 'Centri di costo',
      href: `/corsi/nuovo-corso/${id}/costs-center`,
    },
  ];

  // const query = useQuery('courseLesson', () => getCourseLessons(id!, token));

  const courseDetailQueryFn = () => {
    return getCourseDetail(id!, token);
  };

  const onCourseDetailQuerySuccess = (data: StrapiResponse<CourseResponse>) => {
    const { attributes: templateAttributes } =
      data?.data.attributes.courseTemplate.data || {};
    const lessons = data.data.attributes.lessons.data;
    const lessonIds = lessons?.map((lesson) => lesson.id);

    const estimatedTeacherPayment =
      lessons?.reduce(
        (total, lesson) => total + lesson.attributes.estimatePayment,
        0
      ) || 0;

    const estimatedTeacherRefund =
      lessons?.reduce(
        (total, lesson) => total + (lesson.attributes.estimateRefund || 0),
        0
      ) || 0;

    newCourseFromTemplateFormMethods.reset({
      ...emptyCourseTemplate,
      ...templateAttributes,
      status: CourseStatus.DRAFT,
      details: {
        lessonsDescription: templateAttributes?.details?.lessonsDescription,
        winesDescription: templateAttributes?.details?.winesDescription,
        aimings: templateAttributes?.details?.aimings,
        otherInfos:
          templateAttributes?.details?.otherInfos?.map((gadget) => ({
            description: gadget.description,
          })) || [],
      },
      section: isDelegato(profile)
        ? profile?.section?.data?.id
        : templateAttributes?.section?.data?.id,
      location: templateAttributes?.location?.data?.id,
      delegate: templateAttributes?.delegate?.data?.id,
      treasurer: templateAttributes?.treasurer?.data?.id,
      mailDate: formatDate(moment().set({ hour: 19, minute: 0 })),
      firstReminderDate: formatDate(moment().set({ hour: 19, minute: 0 })),
      secondReminderDate: formatDate(moment().set({ hour: 19, minute: 0 })),
      minProfileCategorySub:
        templateAttributes?.minProfileCategorySub?.data?.id,
      lessons: lessonIds || [],
      image: undefined,
      carousel: [],
      partialPayment: true,
      cardPayment: true,
      balance: {
        ...emptyEvent?.balance,
        teacherPayments: {
          ...emptyEvent?.balance.teacherPayments,
          estimatedOutflow: estimatedTeacherPayment,
        },
        teacherRefunds: {
          ...emptyEvent?.balance.teacherRefunds,
          estimatedOutflow: estimatedTeacherRefund,
        },
      },
      headquarterBalance: {
        ...emptyEvent.headquarterBalance,
        teachingMaterial: {
          ...emptyEvent.headquarterBalance.teachingMaterial,
          estimatedPriceUnit: templateAttributes?.materialAmount,
          finalOutflow: templateAttributes?.materialAmount,
        },
        shipping: {
          ...emptyEvent.headquarterBalance.shipping,
          estimatedOutflow: templateAttributes?.shippingMaterialAmount,
          finalPriceUnit: templateAttributes?.shippingMaterialAmount,
        },
        administrations: {
          ...emptyEvent.headquarterBalance?.administrations,
          estimatedOutflow:
            templateAttributes?.headquarterBalance?.administrations
              ?.estimatedOutflow,
          finalOutflow:
            templateAttributes?.headquarterBalance?.administrations
              ?.finalOutflow,
          estimatedPriceUnit:
            templateAttributes?.headquarterBalance?.administrations
              ?.estimatedPriceUnit,
        },
      },
      courseFee: templateAttributes?.courseFee,
      membershipFee: templateAttributes?.membershipFee,
      registrationFee: templateAttributes?.registrationFee,
      treasurerAmount: templateAttributes?.treasurerAmount,
      treasurerAmountLimit: templateAttributes?.treasurerAmount,
      supervisorAmount: templateAttributes?.supervisorAmount,
      supervisorAmountLimit: templateAttributes?.supervisorAmount,
      initialSubscriptionAmount: templateAttributes?.initialSubscriptionAmount,
      subscriptionAmount: emptyEvent?.subscriptionAmount,
      canDelegateEdit: templateAttributes?.canDelegateEdit,
    });
  };

  const onCourseDetailQueryError = () => {
    toast.error('Errore nel recupero dei dati dal template');
  };

  const courseDetailQuery = useQuery({
    queryKey: ['course', id],
    queryFn: courseDetailQueryFn,
    onSuccess: onCourseDetailQuerySuccess,
    onError: onCourseDetailQueryError,
  });

  const onSaveCourseMutationSuccess = () => {
    toast.success('Corso salvato con successo!');
    navigate('/corsi');
  };

  const onSaveCourseMutationError = () => {
    toast.error('Qualcosa è andato storto!');
  };

  const saveCourseMutationFn = ({
    newCourseFromTemplate,
  }: {
    newCourseFromTemplate: CourseRequest;
  }) => {
    return updateCourse(id!, newCourseFromTemplate, token);
  };

  const saveCourseMutation = useMutation({
    mutationKey: ['saveCourse', id],
    mutationFn: saveCourseMutationFn,
    onSuccess: onSaveCourseMutationSuccess,
    onError: onSaveCourseMutationError,
  });

  const { mutateAsync: uploadFileMutation } = useMutation({
    mutationKey: ['uploadFile'],
    mutationFn: ({ files, token }: { files: File[]; token: string | null }) =>
      uploadToStrapi(files, token),

    onError: (err) => {
      console.log('err', err);
      toast.error('Qualcosa è andato storto!');
    },
  });

  const save = (status?: CourseStatus) => {
    return newCourseFromTemplateFormMethods.handleSubmit(
      async (updatedEventValues) => {
        const course = {
          ...newCourseFromTemplateFormMethods.getValues(),
          location: newCourseFromTemplateFormMethods.getValues()
            .location as IOption,
        };

        let uploadCourseImageRes: any;
        let uploadCourseCarouselRes: any;

        const areElementsFile = (elements?: any[] | null) => {
          return elements?.some((element) => element instanceof File);
        };

        if (course.image) {
          uploadCourseImageRes = await uploadFileMutation({
            files: course.image as File[],
            token,
          });
        }

        if (areElementsFile(course.carousel)) {
          uploadCourseCarouselRes = await uploadFileMutation({
            files: course.carousel as File[],
            token,
          });
        }

        saveCourseMutation.mutate({
          newCourseFromTemplate: {
            ...course,
            location: course.location ? Number(course.location) : null,
            delegate: null,
            status: status || course.status,
            mailDate: moment(course.mailDate).utc().toISOString(),
            firstReminderDate: moment(course.firstReminderDate)
              .utc()
              .toISOString(),
            secondReminderDate: moment(course.secondReminderDate)
              .utc()
              .toISOString(),
            details: {
              ...course.details,
              otherInfos: course.details?.otherInfos?.map(
                (gadget: DescriptionList) => ({
                  description: gadget.description,
                })
              ),
            },
            image: uploadCourseImageRes?.[0]?.id,
            carousel: uploadCourseCarouselRes?.map((file: any) => file.id),
            safetyMargin: course.safetyMargin,
            canDelegateEdit: course.canDelegateEdit,
            subscriptionAmount: course.subscriptionAmount,
          },
        });
      }
    );
  };

  const formHasErrors: boolean =
    Object.keys(newCourseFromTemplateFormMethods.formState.errors).length > 0;

  const saveAsDraft = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione. Controlla tutti i campi');
    } else {
      save(CourseStatus.DRAFT)();
    }
  };

  const saveAndPublish = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione. Controlla tutti i campi');
    } else {
      save(CourseStatus.PUBLISHED)();
    }
  };

  const saveAndAskToApprove = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione. Controlla tutti i campi');
    } else {
      save(CourseStatus.TO_APPROVE)();
    }
  };

  const formValues = newCourseFromTemplateFormMethods.watch();

  const getHeaderPrimaryButtonProps = () => {
    const canPublish = isSegreteriaNazionale(profile);
    if (canPublish) {
      return {
        primaryButtonText: 'Pubblica',
        primaryButtonOnClick: saveAndPublish,
      };
    }

    return {
      primaryButtonText: 'Salva',
      primaryButtonOnClick: saveAndAskToApprove,
    };
  };

  const onCarouselImageDelete = async (index: number) => {
    const carouselImages = Array.isArray(formValues.carousel)
      ? [...formValues.carousel]
      : [];
    carouselImages?.splice(index, 1); // Rimuovi l'immagine dall'array
    newCourseFromTemplateFormMethods.setValue(
      'carousel',
      carouselImages as File[]
    );
  };

  const isLoading = saveCourseMutation.isLoading;

  return (
    <FormProvider {...newCourseFromTemplateFormMethods}>
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <HeaderTitle
          category='Nuovo corso'
          modal={<StatusCourseModal defaultStatus={formValues.status} />}
          {...getHeaderPrimaryButtonProps()}
          secondaryButtonText='Salva bozza'
          secondaryButtonOnClick={saveAsDraft}
          buttonsDisabled={isLoading}
        />

        <div className='pt-6'>
          <TabBar tabs={tabs} />
          <Routes>
            <Route
              path='dati-principali'
              element={
                <DatiPrincipali
                  onImageDelete={() =>
                    newCourseFromTemplateFormMethods.setValue(
                      'image',
                      undefined
                    )
                  }
                  onCarouselImageDelete={(index) =>
                    onCarouselImageDelete(index)
                  }
                  courseMethods={newCourseFromTemplateFormMethods}
                />
              }
            />
            <Route path='location' element={<Location />} />
            <Route path='lezioni' element={<Lezioni isDirty={isDirty} />} />
            <Route
              path='lezioni/modifica-lezione/:id'
              element={<ModificaLezioneCorso courseId={id} />}
            />
            <Route
              path='lezioni/aggiungi-lezione'
              element={<AggiungiLezioneCorso />}
            />
            <Route path='direzione' element={<Direzione />} />
            <Route
              path='costi'
              element={
                <CostiCorso
                  title='Crea nuovo corso'
                  defaultCourse={undefined}
                />
              }
            />
            <Route
              path='costs-center'
              element={
                <CostsCenter
                  form={newCourseFromTemplateFormMethods as any}
                  title='Centri di costo'
                />
              }
            />
          </Routes>
        </div>
      </div>
    </FormProvider>
  );
};
export default withFilesContext(NuovoCorsoDaTemplate);

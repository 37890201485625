import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { location } from '../../../../api/location';
import LocationsSelect from '../../../../components/FetchSelect/LocationsSelect';
import InputPresentational from '../../../../components/Form/InputPresentational';
import TitlePage from '../../../../components/TitlePage';
import { EventRequest, EventStatus } from '../../../../interfaces/events';
import sectionsAPIs from '../../../../api/section';

export default function LocationEvento() {
  const {
    register,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext<EventRequest>();

  const selectedSection = String(watch('section'));
  const selectedLocation = watch('location');

  const locationQueryFn = () => {
    if (typeof selectedLocation === 'number') {
      return location(selectedLocation);
    }
  };

  const locationsQuery = useQuery({
    queryKey: ['location', selectedLocation],
    queryFn: locationQueryFn,
  });

  const nationQuery = useQuery({
    queryKey: ['nation', selectedSection],
    queryFn: () => {
      if (selectedSection) {
        return sectionsAPIs.findSectionNation(selectedSection);
      }
    },
  });

  const isEventCanceled = watch('status') === EventStatus.CANCELED;

  useEffect(() => {
    locationsQuery.refetch();
    nationQuery.refetch();
  }, [selectedSection, selectedLocation]);

  return (
    <>
      <TitlePage title='Dettaglio evento | Location' />

      <form className='mt-6'>
        <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-4'>
          <LocationsSelect
            // disabled={isCourseCanceled}
            control={control}
            locationId={selectedLocation as number}
            required
            error={{
              ...errors.location,
              type: 'required',
            }}
            disabled={isEventCanceled}
          />
          <InputPresentational
            id='address'
            label='Indirizzo'
            name='address'
            type='text'
            value={locationsQuery.data?.data.attributes.address}
            // register={register}
            disabled
          />
          <InputPresentational
            id='city'
            label='Città'
            name='city'
            type='text'
            value={locationsQuery.data?.data.attributes.city}
            disabled
          />
          <InputPresentational
            id='nation'
            label='Nazione'
            name='nation'
            type='text'
            value={nationQuery.data?.data.attributes.name}
            disabled
          />
          {/* <TextAreaPresentational
            id='googleLink'
            label='Link Google Maps'
            name='googleLink'
            register={register}
          /> */}

          {/* <fieldset className='space-y-5'>
            <div>
              <div className='relative flex items-start'>
                <SimpleCheckboxPresentational
                  id='mapEnabled'
                  name='mapEnabled'
                  label='Disattiva la mappa'
                  register={register}
                  disabled={isEventCanceled}
                />
              </div>
              <p id='offers-description' className='text-gray-400 text-sm'>
                Disattiva il link per visualizzare la mappa in onav.it nei casi
                in cui la location non venga trovata
              </p>
            </div>
          </fieldset> */}
        </div>
      </form>
    </>
  );
}

import React from 'react';
import classNames from '../libs/utils/classNames';

interface BadgeProps {
  green?: boolean;
  greenOutline?: boolean;
  red?: boolean;
  gray?: boolean;
  grayBorder?: boolean;
  teal?: boolean;
  orange?: boolean;
  yellow?: boolean;
  text?: string;
}
const Badge: React.FC<BadgeProps> = ({
  text,
  green,
  greenOutline,
  red,
  gray,
  grayBorder,
  teal,
  orange,
  yellow,
}) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center px-5 py-1.5 rounded-full text-sm font-normal',
        green ? 'bg-green-600 text-white' : '',
        greenOutline
          ? 'border border-green-600 text-green-600 bg-transparent'
          : '',
        red ? 'bg-red-700 text-white' : '',
        gray ? 'bg-gray-200 text-gray-500 font-medium uppercase' : '',
        grayBorder
          ? 'bg-neutral-50 border border-gray-200 font-normal text-gray-800'
          : '',
        teal ? 'bg-teal-600 text-white' : '',
        orange ? 'bg-orange-500 text-white' : '',
        yellow ? 'bg-yellow-500 text-white' : ''
      )}
    >
      {text}
    </span>
  );
};

export default Badge;

import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import UnderlineButton from '../../../../components/Buttons/UnderlineButton';
import TabBar from '../../../../components/TabBars/TabBar';
import H1Styled from '../../../../components/Typography/H1Styled';
import DatiPrincipali from './dati-principali';

import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useAuth } from '../../../../contexts/Auth';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { sectionValidator } from '../../../../validators/section';
import { SectionRequest } from '../../../../interfaces/section';
import sectionsAPIs from '../../../../api/section';

const NuovaSezione = () => {
  const tabs = [
    {
      label: 'Dati principali',
      href: '/sezioni/nuova',
    },
    // {
    //   label: "Location",
    //   href: "/sezioni/nuova/location",
    // },
    // {
    //   label: "Firma",
    //   href: "/sezioni/nuova/firma",
    // },
    // {
    //   label: "Mansioni",
    //   href: "/sezioni/nuova/mansioni",
    // },
  ];
  const [{ token }] = useAuth();
  const navigate = useNavigate();

  const newSectionFormMethods = useForm<SectionRequest>({
    resolver: useYupValidationResolver(sectionValidator),
  });

  const removeEmptyFields = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '') {
        delete obj[key];
      }
    });
  };

  const { mutate, isLoading } = useMutation(sectionsAPIs.create, {
    onSuccess: (data) => {
      toast.success('Sezione creata con successo');
      navigate('/sezioni');
      navigate(`/sezioni/modifica/${data.data.data.id}/location`);
    },
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });

  const createNewSection = async (data: FieldValues) => {
    removeEmptyFields(data);
    await mutate({
      data: { data },
      token,
    });
  };

  return (
    <FormProvider {...newSectionFormMethods}>
      <form
        className='col-span-12 lg:col-span-9 xl:col-span-10'
        onSubmit={newSectionFormMethods.handleSubmit(createNewSection)}
      >
        <div className='flex justify-between'>
          <H1Styled>Nuova sezione</H1Styled>
          <div className='flex items-center h-10'>
            <div className='mr-6'>
              <UnderlineButton
                onClick={() => {
                  navigate('/sezioni');
                }}
              >
                Chiudi
              </UnderlineButton>
            </div>
            <PrimaryButton type='submit'>Salva</PrimaryButton>
          </div>
        </div>

        <div className='pt-12'>
          <TabBar tabs={tabs} />
          <Routes>
            <Route index element={<DatiPrincipali />} />
            {/* <Route path="location" element={<ListaLocation  />} />
            <Route path="aggiungi-location" element={<NuovaLocation register={register} control={control} />} />
            <Route path="firma" element={<Firma register={register} control={control} />} />
            <Route path="mansioni" element={<Mansioni />} /> */}
          </Routes>
        </div>
      </form>
    </FormProvider>
  );
};
export default NuovaSezione;

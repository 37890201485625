import FormNuovoCoupon from '../../../../components/FormNuovoCoupon';
import TitlePage from '../../../../components/TitlePage';

export default function NewCoupon({
  checked,
  setChecked,
}: {
  checked: string;
  setChecked: any;
}) {
  return (
    <>
      <TitlePage title='Crea nuovo Coupon' />
      <FormNuovoCoupon
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
}

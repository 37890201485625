import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MinusCircleIcon } from '@heroicons/react/outline';
import { DescriptionList } from '../interfaces/commons';
import classNames from '../libs/utils/classNames';

interface Props {
  disabled?: boolean;
}

const GadgetForm = ({ disabled }: Props) => {
  const { watch, setValue, register } = useFormContext();

  const add = () => {
    const gadgets: DescriptionList[] = watch('details.otherInfos') || [];
    const newGadgets = [
      ...gadgets,
      {
        description: '',
      },
    ];
    setValue('details.otherInfos', newGadgets);
  };

  const remove = (idx: number) => {
    const gadgets: DescriptionList[] = watch('details.otherInfos');
    const newGadgets = gadgets.filter((_, gadgetIdx) => gadgetIdx !== idx);
    setValue('details.otherInfos', newGadgets);
  };
  const gadgets = watch('details.otherInfos');
  return (
    <div>
      <label className='block text-sm text-gray-500'>Elenco gadget</label>

      <div className='flex flex-col py-3 gap-2'>
        {gadgets &&
          gadgets.map((gadget: DescriptionList, idx: number) => {
            return (
              <div key={idx} className='flex flex-row gap-5 items-center'>
                <div className='h-10 w-10 min-w-[2.5rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
                  {('0' + (idx + 1)).slice(-2)}
                </div>
                <input
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
                  key={gadget.id}
                  {...register(`details.otherInfos.${idx}.description`)}
                  disabled={disabled}
                />
                <button
                  onClick={() => remove(idx)}
                  type={'button'}
                  className='h-7 w-7 text-primary pointer'
                  disabled={disabled}
                >
                  <MinusCircleIcon />
                </button>
              </div>
            );
          })}
      </div>

      <button
        onClick={add}
        type={'button'}
        className={classNames(
          'text-base w-max whitespace-nowrap text-primary underline underline-offset-4 mt-1',
          disabled ? 'text-slate-400' : ''
        )}
        disabled={disabled}
      >
        Aggiungi gadget
      </button>
    </div>
  );
};

export default GadgetForm;

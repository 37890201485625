import axios from 'axios';
import QueryString from 'qs';
import { Statistics } from '../interfaces/statistics';

const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const find = async ({
  token,
  query,
}: {
  token?: string | null;
  query?: Object;
}): Promise<Statistics> => {
  const { data: receipts } = await instance.get<Statistics>(
    `${REACT_APP_API_URL}/statistics?${QueryString.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return receipts;
};

const statisticsAPIs = { find };

export default statisticsAPIs;

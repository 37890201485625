import React from 'react';
import { useNavigate, useParams } from 'react-router';
import Underline from './TextLink/Underline';
import { ReceiptCausal, ReceiptEntry } from '../interfaces/receipts';
import qs from 'qs';
import { useSearchParams } from 'react-router-dom';
import { ProductType } from '../interfaces/orders';
import { useAuth } from '../contexts/Auth';
import { isDelegato } from '../libs/utils/auth';

interface ReceivedTemplateBoxProps {
  title: string;
  entries?: ReceiptEntry[];
  queryString?: string;
  showEntries?: boolean;
}
const ReceiptTemplate: React.FC<ReceivedTemplateBoxProps> = ({
  title,
  entries,
  queryString,
  showEntries = true,
}) => {
  const params = useParams();
  const { id: courseId, participantId, eventId } = params;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [{ profile }] = useAuth();

  const handleRedirect = () => {
    navigate(
      getReceiptUrl({
        entityId: courseId || eventId,
        queryString,
        entries,
        productType: searchParams.get('productType') as ProductType,
        participantId,
      })
    );
  };

  const quoteAssociativeEntry = entries?.find(
    (entry) => entry.causal === ReceiptCausal.QUOTA_ASSOCIATIVA
  );

  return (
    <div className='border border-gray-300 px-4 py-8 w-full space-y-10'>
      <h4 className='text-1.5xl font-IBM font-light text-gray-700 text-center'>
        {title}
      </h4>
      {quoteAssociativeEntry && isDelegato(profile) && (
        <p className='mb-0 text-gray-500 text-sm italic'>
          * ricordati di comunicare a segreteria@onav.it l’avvenuta
          registrazione
        </p>
      )}

      {showEntries && (
        <div className='divide-y space-y-4 text-sm text-gray-500'>
          <div className='space-y-3'>
            {entries?.map((entry, idx) => (
              <div
                key={`receipt-entry-${idx}`}
                className='flex flex-row items-center justify-between'
              >
                <p className=''>{entry.causal}</p>
                <p className='text-right'>€ {entry.amount}</p>
              </div>
            ))}
          </div>

          <div className='flex flex-row items-center justify-between pt-4'>
            <p className=''>Totale</p>
            <p className='text-right font-bold'>
              {entries?.reduce((acc, val) => Number(val.amount) + acc, 0)}
            </p>
          </div>
        </div>
      )}

      <div className='flex items-center justify-center'>
        <button onClick={handleRedirect}>
          <Underline label='Crea ricevuta' />
        </button>
      </div>
    </div>
  );
};

const getReceiptUrl = ({
  productType,
  queryString,
  entries,
  entityId,
  participantId,
}: {
  productType: ProductType;
  queryString?: string;
  entries?: ReceiptEntry[];
  entityId?: string;
  participantId?: string;
}) => {
  switch (productType) {
    case ProductType.COURSE:
      return `/corsi/dettaglio-corso/${entityId}/partecipanti/${participantId}/ricevute/crea?${queryString}&entries=${qs.stringify(
        entries
      )}`;
    case ProductType.EVENT:
      return `/eventi/modifica/${entityId}/partecipanti/${participantId}/ricevute/crea?${queryString}&entries=${qs.stringify(
        entries
      )}`;
    default:
      return '/';
  }
};

export default ReceiptTemplate;

import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import PageNotFound from './pages/404';
import Home from './pages/Home';
import Statistiche from './pages/Statistiche';
import Login from './pages/Login';
import MainLayout from './components/Layout/MainLayout';
import LoginLayout from './components/Layout/LoginLayout';
import Profilo from './pages/Profilo';
import Utenti from './pages/Utenti';
import Corsi from './pages/Corsi';
import Comunicati from './pages/Comunicati';
import Stampa from './pages/Stampa';
import Tesoreria from './pages/Tesoreria';
import Coupon from './pages/Coupon';
import Sezioni from './pages/Sezioni';
import Eventi from './pages/Eventi';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AuthProvider } from './contexts/Auth';
import { PrivateRoute, PublicRoute } from './components/Auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivatePage from './pages/PrivatePage';
import './styles/global.css';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Routes>
          <Route element={<PrivateRoute element={<MainLayout />} />}>
            <Route index element={<Home />} />
            <Route path='*' element={<PageNotFound />} />
            <Route path='/privato' element={<PrivatePage />} />
            <Route path='/area-riservata/'>
              <Route path='profilo' element={<Profilo />} />
            </Route>
            <Route path='/corsi/*' element={<Corsi />} />
            <Route path='/statistiche' element={<Statistiche />} />
            <Route path='/coupon/*' element={<Coupon />} />
            <Route path='/stampa/*' element={<Stampa />} />

            <Route path='/tesoreria/*' element={<Tesoreria />} />
            <Route path='/utenti/*' element={<Utenti />} />
            <Route path='/comunicati/*' element={<Comunicati />} />
            <Route path='/stampa/*' element={<Stampa />} />
            <Route path='/eventi/*' element={<Eventi />} />
            <Route path='/sezioni/*' element={<Sezioni />} />
          </Route>

          <Route element={<PublicRoute element={<LoginLayout />} />}>
            <Route path='/login' element={<Login />} />
          </Route>
        </Routes>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;

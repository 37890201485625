import { LessonTemplateAttributes } from '../interfaces/lesson-templates';
import { Category } from '../interfaces/commons';

export const emptyLessonTemplate: LessonTemplateAttributes = {
  title: '',
  description: '',
  minutes: 0,
  estimatePayment: 0,
  estimatePaymentLimit: 0,
  estimateRefund: 0,
  category: Category.ASSAGGIATORE,
};

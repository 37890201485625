import TitlePage from '../../../components/TitlePage';
// import PrimaryButton from '../../../components/Buttons/PrimaryButton';
// import UnderlineButton from '../../../components/Buttons/UnderlineButton';
// import { DownloadIcon } from '@heroicons/react/outline';
import LessonsDetailCourseTable from '../Tables/LessonsDetailCourseTable';
// import AddExistingLessonModal from '../../../components/Modals/AddExistingLessonModal';
import Underline from '../../../components/TextLink/Underline';
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  bulkUpdateLessons,
  downloadLessonsCSV,
  getLessons,
} from '../../../api/lesson';
import { useAuth } from '../../../contexts/Auth';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { CourseRequest, CourseResponse } from '../../../interfaces/courses';
import { CourseStatus, StrapiResponse } from '../../../interfaces/commons';
import Pagination from '../../../components/Pagination';
import { useDebounce } from 'use-debounce';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { useMemo, useState } from 'react';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';
import moment from 'moment';
import { DownloadIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import { checkProfile } from '../../../libs/utils/helpers';
import {
  CourseLessonResponse,
  TeachersAcceptanceStatus,
} from '../../../interfaces/lesson';
import BaseModal from '../../../components/Modals/BaseModal';
import { ArrowPathIcon } from '../../../components/CustomIcons';

interface Props {
  isDirty: boolean;
  courseDetailQuery?: UseQueryResult<StrapiResponse<CourseResponse>, unknown>;
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const LezioniCorsi: React.FC<Props> = ({ isDirty, courseDetailQuery }) => {
  const { id: courseId } = useParams();
  const [{ token, profile }] = useAuth();
  const { watch } = useFormContext<CourseRequest>();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);
  const navigate = useNavigate();
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState<
    Partial<CourseLessonResponse & { multiple?: boolean }>[]
  >([]);
  const [
    isSendingNotificationsToTeachers,
    setIsSendingNotificationsToTeachers,
  ] = useState(false);
  const [checkedHeader, setCheckedHeader] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const toggleWarning = () => setIsWarningOpen((v) => !v);

  const handleAddLesson = () => {
    if (isDirty) return toggleWarning();
    navigate(`/corsi/dettaglio-corso/${courseId}/lezioni/aggiungi-lezione`);
  };

  const { data } = useQuery(
    ['lessons', courseId, ...debounceSearchParams],
    () => {
      return getLessons({
        filters: {
          course: {
            id: {
              $eq: courseId,
            },
          },
        },
        populate: '*',
        token,
        searchParams: debounceSearchParams,
      });
    }
  );

  const isCourseCanceled = watch('status') === CourseStatus.CANCELED;
  const canDelegatoEdit = watch('canDelegateEdit');

  const { mutate: lessonsCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadCourseLessonsCSVList', downloadLessonsCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(data?.meta?.pagination?.total) >= 10000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 10000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  //gestione selezione righe
  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedHeader(e.target.checked);
    setSelectedLessons(
      e.target.checked
        ? data?.data.map((elem) => ({
            ...elem,
            multiple: true,
          })) || []
        : []
    );
  };

  const toggleTeacherNotificationModal = () =>
    setIsSendingNotificationsToTeachers((v) => !v);
  const areDirtyLessons = useMemo(
    () =>
      selectedLessons.some(
        (elem) =>
          elem.attributes?.emailToTeacherSent ||
          !elem.attributes?.teacher?.data ||
          elem.attributes.teachersAcceptanceStatus !==
            TeachersAcceptanceStatus.PENDING
      ),
    [selectedLessons]
  );

  /**
   * Tolgo dalla selezione chi ha accettato la lezione e quelle lezioni senza docente
   */
  const handleTeacherNotificationsModal = () => {
    if (areDirtyLessons) {
      setCheckedHeader(false);
      return setSelectedLessons((oldValues) =>
        oldValues.filter(
          (elem) =>
            !elem.attributes?.emailToTeacherSent &&
            elem.attributes?.teacher?.data?.attributes &&
            elem.attributes.teachersAcceptanceStatus ===
              TeachersAcceptanceStatus.PENDING
        )
      );
    }
    toggleTeacherNotificationModal();
  };

  const { mutate, isLoading: isBulkUpdating } = useMutation(
    'bulk-update-lessons',
    bulkUpdateLessons,
    {
      onSuccess: () => {
        toggleTeacherNotificationModal();
        queryClient.invalidateQueries([
          'lessons',
          courseId,
          ...debounceSearchParams,
        ]);

        setSelectedLessons([]);
      },
    }
  );

  return (
    <>
      <TitlePage title='Dettaglio corso | Lezioni' />
      <BaseModal
        isOpen={isSendingNotificationsToTeachers}
        toggle={handleTeacherNotificationsModal}
        title='Notifica insegnanti'
        confirmButtonText='Invia'
        cancelButtonText='Annulla'
        isLoading={isBulkUpdating}
        onConfirm={() =>
          mutate({
            token,
            data: {
              data: selectedLessons.map((_lesson) => ({
                id: _lesson.id,
                sendEmailNotificationToTeacher: true,
              })),
            },
          })
        }
        onCancel={toggleTeacherNotificationModal}
        subtitle={`Vuoi notificare gli insegnanti delle lezioni selezionate?`}
      />
      <div className='mt-6'>
        <div className='pt-6 pb-8'>
          <div className='mb-6 flex items-center justify-end gap-4'>
            <button
              onClick={() => {
                handleTeacherNotificationsModal();
              }}
              disabled={selectedLessons.length === 0}
              className='flex flex-row'
            >
              <Underline
                label='Notifica docenti in attesa'
                disabled={selectedLessons.length === 0}
              />{' '}
              {areDirtyLessons && (
                <div className='h-5 w-5'>
                  <ArrowPathIcon className='flex h-full w-full mx-2 text-primary mt-1' />
                </div>
              )}
            </button>
            <button
              onClick={handleAddLesson}
              disabled={
                isCourseCanceled || checkProfile(profile, canDelegatoEdit)
              }
            >
              <Underline
                label='Crea lezione'
                disabled={
                  isCourseCanceled || checkProfile(profile, canDelegatoEdit)
                }
              />
            </button>
            <UnderlineButton
              disabled={isDownloadingCSV}
              onClick={() => {
                lessonsCSVMutation({
                  token,
                  body: {},
                  query: { filters: { course: courseId } },
                  fileName: `Lezioni corso ${
                    courseDetailQuery?.data?.data?.attributes?.title
                  }  ${moment().format('HH[:]MM[:]ss')}`,
                });
              }}
            >
              <DownloadIcon className='w-4 h-4' /> Scarica CSV
            </UnderlineButton>
          </div>

          <LessonsDetailCourseTable
            lessons={data?.data}
            isCourseCanceled={isCourseCanceled}
            page={data?.meta?.pagination?.page}
            pageSize={data?.meta?.pagination?.pageSize}
            isDirty={isDirty}
            isDetail
            disabled={checkProfile(profile, canDelegatoEdit)}
            setSelectedLessons={setSelectedLessons}
            selectedLessons={selectedLessons}
            onCheckAll={onCheckAll}
            checkedHeader={checkedHeader}
          />
          <Pagination pagination={data?.meta?.pagination} />
        </div>
      </div>
      <ConfirmationModal
        isOpen={isWarningOpen}
        onClose={toggleWarning}
        onConfirm={() =>
          navigate(
            `/corsi/dettaglio-corso/${courseId}/lezioni/aggiungi-lezione`
          )
        }
        title='ATTENZIONE!'
        subtitle='Se prosegui perderai le ultime modifiche apportate'
        textButton='Prosegui'
      />
    </>
  );
};
export default LezioniCorsi;

import InputSelectPresentational from '../Form/InputSelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { FieldError, useFormContext } from 'react-hook-form';
import { IOption } from '../Form/Multiselect';
import locationsAPIs from '../../api/locationsAPIs';
import { useAuth } from '../../contexts/Auth';

const LocationsSelect = ({
  locationId,
  sectionId,
  label,
  control,
  disabled = false,
  required,
  error,
}: {
  locationId?: number;
  sectionId?: number;
  label?: string;
  control?: any;
  disabled?: boolean;
  required?: boolean;
  error?: FieldError;
}) => {
  const { watch } = useFormContext();
  const section = watch('section');
  const [{ token }] = useAuth();

  const sectionLocationQueryFn = () => {
    return locationsAPIs.find({
      query: {
        filters: { sections: { id: section } },
        pagination: { pageSize: 500 },
      },
      token,
    });
  };

  const locationsQuery = useQuery({
    queryKey: ['locations', section],
    queryFn: sectionLocationQueryFn,
  });

  return (
    <>
      {match(locationsQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          const options = query?.data?.data.map((location) => {
            const option: IOption = {
              label: location.attributes.name,
              value: location.id,
            };
            return option;
          });

          return (
            <InputSelectPresentational
              label={label || 'Nome'}
              name='location'
              control={control}
              defaultValue={locationId}
              options={options || []}
              disabled={disabled}
              required={required}
              error={error}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default LocationsSelect;

import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import SelectPresentational from '../Form/SelectPresentational';

const SectionTypeSelect = ({
  defaultValue,
  label,
  control,
  error,
}: {
  defaultValue?: string;
  label?: string;
  control?: any;
  error?: FieldError;
}) => {
  return (
    <SelectPresentational
      label='Tipo di sezione'
      name='type'
      required
      defaultValue={defaultValue}
      control={control}
      error={error}
      options={[
        { label: 'SEZIONI', value: 'sezioni' },
        { label: 'DELEGAZIONI', value: 'delegazioni' },
        { label: 'NAZIONALI', value: 'nazionali' },
        { label: 'REGIONALI', value: 'regionali' },
      ]}
    />
  );
};

export default SectionTypeSelect;

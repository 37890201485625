import React from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { createProfile } from '../../../api/profile';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';
import TabBar from '../../../components/TabBars/TabBar';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { useAuth } from '../../../contexts/Auth';
import NuovoUtenteAnagrafica from './Pages/anagrafica';
import NuovoUtenteDatiOnav from './Pages/dati-onav';
import { toast } from 'react-toastify';
import FullPageSpinner from '../../../components/Layout/Loading/FullPageSpinner';
import capitalize from '../../../libs/utils/capitalize';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { profileValidator } from '../../../validators/profile';
import { ProfileRequest } from '../../../interfaces/profile';

const NuovoUtente = () => {
  const tabs = [
    {
      label: 'Anagrafica',
      href: '/utenti/nuovo',
    },
    {
      label: 'Dati ONAV',
      href: '/utenti/nuovo/dati-onav',
    },
  ];

  const newUSerForm = useForm<ProfileRequest>({
    resolver: useYupValidationResolver(profileValidator),
  });

  const [{ token }] = useAuth();
  const navigate = useNavigate();

  const removeEmptyFields = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '') {
        delete obj[key];
      }
    });
  };

  const { mutate, isLoading } = useMutation(createProfile, {
    onSuccess: () => {
      toast.success('Utente creato con successo');
      navigate('/utenti');
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.error.message === 'Section is required'
          ? 'La sezione di appartenenza è richiesta, aggiungila nei dati ONAV'
          : error.response.data.error.message
      );
    },
  });


  const createNewUser = async (data: FieldValues) => {
    removeEmptyFields(data);
    mutate({
      body: {
        ...data,
        password: 'Password123',
        username: data.email,
        name: capitalize(data.name),
        surname: capitalize(data.surname),
      },
      token,
    });
  };

  return (
    <>
      <TitlePage title='Utenti' />

      {isLoading && <FullPageSpinner />}
      <FormProvider {...newUSerForm}>
        <form
          className='col-span-12 lg:col-span-9 xl:col-span-10'
          onSubmit={newUSerForm.handleSubmit(createNewUser)}
        >
          <div className='flex flex-col md:flex-row items-center justify-between gap-4 mb-5'>
            <div className='flex content-center items-center'>
              <img
                src='/images/user.jpg'
                className='border rounded-full h-14 mb-5 mr-6'
              />
              <H1Styled>Crea nuovo utente</H1Styled>
            </div>

            <div className='flex items-center gap-4'>
              <PrimaryButton
                outline
                onClick={() => {
                  navigate('/utenti');
                }}
              >
                Chiudi
              </PrimaryButton>
              <PrimaryButton type='submit'>Crea utente</PrimaryButton>
            </div>
          </div>
          <div className='space-y-5'>
            <TabBar tabs={tabs} />

            <Routes>
              <Route path='/' element={<NuovoUtenteAnagrafica />} />
              <Route path='dati-onav' element={<NuovoUtenteDatiOnav />} />
            </Routes>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default NuovoUtente;

import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import NoteInputTable from '../../../components/Form/NoteInputTable';
import PriceInputTable from '../../../components/Form/PriceInputTable';
import { EventRequest } from '../../../interfaces/events';
import { formatCurrency } from '../../../libs/utils/formatters/currency';
import BalanceTableHeaders from './Commons/BalanceTableHeader';
import { useAuth } from '../../../contexts/Auth';
import { isSegreteriaNazionale } from '../../../libs/utils/auth';
import { getEntityBalanceTotals } from '../../../libs/utils/helpers';
import { CourseRequest } from '../../../interfaces/courses';

interface HeadquarterBalanceTableProps {
  estimatedOutflow?: number;
  estimatedIncome?: number;
  finalOutflow?: number;
  disabled?: boolean;
  safetyMargin?: number;
  estimatedBalanceOutlfow?: number;
  isCoursePublished?: boolean;
}

const HeadquarterBalanceTable: React.FC<HeadquarterBalanceTableProps> = ({
  estimatedOutflow = 0,
  estimatedIncome = 0,
  finalOutflow = 0,
  disabled,
  safetyMargin = 0,
  isCoursePublished = false,
}) => {
  const [{ profile }] = useAuth();
  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden border'>
          <table className='min-w-full divide-y divide-gray-300'>
            <BalanceTableHeaders />
            <tbody className='divide-y divide-gray-200 bg-white'>
              <CardsRow disabled={disabled} />
              <AdministrationRow disabled={disabled} />
              <TeachingMaterialRow
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <ShippingRow
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              {isSegreteriaNazionale(profile) && (
                <MarginRow safetyMargin={safetyMargin} />
              )}
              <OutflowRow
                estimatedOutflow={estimatedOutflow}
                estimatedIncome={estimatedIncome}
                finalOutflow={finalOutflow}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const CardsRow = ({ disabled = false }) => {
  const { watch, register } = useFormContext<EventRequest>();
  const cards = useMemo(
    () => watch('headquarterBalance').cards,
    [watch('headquarterBalance')]
  );
  return (
    <tr>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Quota tessera
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.estimatedOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(cards?.estimatedIncome)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(cards?.finalOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(cards?.finalIncome)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='headquarterBalance.cards.notes'
          name='headquarterBalance.cards.notes'
          type='text'
          register={register}
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const AdministrationRow = ({ disabled = false }) => {
  const { watch, register } = useFormContext<EventRequest>();
  const administrations = useMemo(
    () => watch('headquarterBalance').administrations,
    [watch('headquarterBalance')]
  );

  const isTouched = useMemo(
    () => watch('headquarterBalance.administrations.isTouched'),
    [watch('headquarterBalance.administrations.isTouched')]
  );

  return (
    <tr>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Spesa amministrativa
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(administrations?.estimatedPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(administrations?.estimatedOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(administrations?.estimatedIncome)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(administrations?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(administrations?.finalOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(administrations?.finalIncome)} */}
        <PriceInputTable
          id='headquarterBalance.administrations.finalIncome'
          name={'headquarterBalance.administrations.finalIncome'}
          className={isTouched ? 'bg-gray-500/25' : ''}
          type='number'
          register={register}
          disabled={disabled}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='headquarterBalance.administrations.notes'
          name='headquarterBalance.administrations.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const TeachingMaterialRow = ({
  disabled = false,
  isCoursePublished = false,
}) => {
  const { watch, register } = useFormContext<EventRequest>();
  const teachingMaterial = useMemo(
    () => watch('headquarterBalance').teachingMaterial,
    [watch('headquarterBalance')]
  );

  const isTouched = useMemo(
    () => watch('headquarterBalance').teachingMaterial.isTouched,
    [watch('headquarterBalance.teachingMaterial.isTouched')]
  );

  return (
    <tr>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Quota materiale didattico
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='headquarterBalance.teachingMaterial.estimatedPriceUnit'
          name='headquarterBalance.teachingMaterial.estimatedPriceUnit'
          type='number'
          disabled={disabled || isCoursePublished}
          register={register}
        />
      </td>{' '}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teachingMaterial?.estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teachingMaterial?.estimatedIncome)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teachingMaterial?.finalPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='headquarterBalance.teachingMaterial.finalOutflow'
          name='headquarterBalance.teachingMaterial.finalOutflow'
          className={isTouched ? 'bg-gray-500/25' : ''}
          type='number'
          disabled={disabled}
          register={register}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teachingMaterial?.finalIncome)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='headquarterBalance.teachingMaterial.notes'
          name='headquarterBalance.teachingMaterial.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const ShippingRow = ({ disabled = false, isCoursePublished = false }) => {
  const { register } = useFormContext<EventRequest>();

  return (
    <tr>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Spedizione materiale
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(shipping?.estimatedPriceUnit)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='headquarterBalance.shipping.estimatedOutflow'
          name={'headquarterBalance.shipping.estimatedOutflow'}
          type='number'
          register={register}
          disabled={disabled || isCoursePublished}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(shipping?.estimatedIncome)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(shipping?.finalPriceUnit)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='headquarterBalance.shipping.finalOutflow'
          name='headquarterBalance.shipping.finalOutflow'
          type='number'
          disabled={disabled}
          register={register}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(shipping?.finalIncome)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='headquarterBalance.shipping.notes'
          name='headquarterBalance.shipping.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const MarginRow = ({ safetyMargin = 0 }) => {
  const { watch } = useFormContext<EventRequest>();
  const safetyMarginValue = useMemo(
    () => watch('headquarterBalance.safetyMargin.estimatedOutflow'),
    [watch('headquarterBalance.safetyMargin.estimatedOutflow')]
  );

  return (
    <tr className='bg-gray-50'>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Margine di sicurezza
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {`${safetyMargin * 100}%`}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
        {formatCurrency(safetyMarginValue)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'></td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'></td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
    </tr>
  );
};

const OutflowRow = ({
  estimatedOutflow = 0,
  finalOutflow = 0,
  estimatedIncome = 0,
}) => {
  const { watch } = useFormContext<CourseRequest>();
  const headquarterBalance = watch('headquarterBalance');

  const { finalIncome } = getEntityBalanceTotals(headquarterBalance);
  return (
    <tr className='bg-gray-50'>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r uppercase'>
        Totale uscite / entrate
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium'>
        {formatCurrency(estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'>
        {formatCurrency(estimatedIncome)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium'>
        {formatCurrency(finalOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'>
        {formatCurrency(finalIncome)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
    </tr>
  );
};

export default HeadquarterBalanceTable;

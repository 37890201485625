import React, { useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

interface InputPresentationalTaxCodeProps {
  disabled?: boolean;
  defaultValue?: string | number | Date;
  required?: boolean;
  legendIcons?: JSX.Element[];
  error?: FieldError;
}
const InputPresentationalTaxCode: React.FC<InputPresentationalTaxCodeProps> = ({
  disabled,
  defaultValue,
  required,
  legendIcons,
  error,
}) => {
  const { register, watch } = useFormContext();
  const [value, setValue] = useState(watch('fiscalCode') || defaultValue);

  return (
    <div className='w-full'>
      <div className='flex'>
        <label className='flex justify-between text-sm text-gray-500'>
          <div>{`${'Codice fiscale'}${required ? ' *' : ''}`} </div>
        </label>
        {legendIcons && (
          <ul className='flex flex-row'>
            {legendIcons.map((legendIcon, idx) => {
              return (
                <li className='flex h-5 mx-1' key={`input-legend-${idx}`}>
                  {legendIcon}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className='mt-1 flex items-center'>
        <input
          id='fiscalCode'
          type='text'
          autoComplete='fiscalCode'
          {...(register &&
            register('fiscalCode', {
              required,
            }))}
          value={value}
          pattern='^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$'
          onChange={(e) => setValue(e.target.value.toUpperCase())}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
            error?.message
              ? 'border-red-100 focus:border-red-100 border-[1px]'
              : ''
          }`}
        />
      </div>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};
export default InputPresentationalTaxCode;

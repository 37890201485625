import cloneDeep from 'lodash.clonedeep';
import { ReceiptRequest, ReceiptResponse } from '../../interfaces/receipts';

// Utility necessarie per consistenza nel backend, dove la voce QUOTA_ISCRIZIONE non è stata rinominata per non compromettere le ricevute già emesse

export const receiptToBackend = (receiptValues: ReceiptRequest) => {
  let convertedReceipt = cloneDeep(receiptValues);

  if (
    convertedReceipt &&
    convertedReceipt.entries &&
    convertedReceipt.entries.length > 0 &&
    convertedReceipt.entries.some(entry => entry.causal === 'SPESA_AMMINISTRATIVA')
  ) {
    convertedReceipt.entries.forEach((entry: any) => {
      if (entry.causal === 'SPESA_AMMINISTRATIVA') {
        entry.causal = 'QUOTA_ISCRIZIONE';
      }
    });
  }

  return convertedReceipt;
};

export const formatReceiptFromBackend = (storedReceipt: ReceiptResponse) => {
  let originalReceipt = cloneDeep(storedReceipt.attributes);

  if (
    originalReceipt &&
    originalReceipt.entries &&
    originalReceipt.entries.length > 0
  ) {
    originalReceipt.entries.forEach((entry: any) => {
      if (entry.causal === 'QUOTA_ISCRIZIONE') {
        entry.causal = 'SPESA_AMMINISTRATIVA';
      }
    });
  }

  return originalReceipt;
};


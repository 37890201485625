import { MinusIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAuth } from '../contexts/Auth';
import { ReceiptCausal, ReceiptRequest } from '../interfaces/receipts';
import InputPresentational from './Form/InputPresentational';
import { IOption } from './Form/Multiselect';
import SelectPresentational from './Form/SelectPresentational';
import { isDelegato } from '../libs/utils/auth';
import ControlledCurrencyInput from './Form/CurrencyInput';
import roles from '../constants/roles';
import { IUser } from '../interfaces/login';

const getCausalOptions = (
  profile?: Partial<IUser>,
  isFree?: boolean,
  isEvent?: boolean,
  isCourse?: boolean
) => {
  const role = profile?.role?.name;
  const isUserDelegato = isDelegato(profile);

  const commonOptions: IOption[] = [
    { label: 'Materiale ONAV', value: 'MATERIALE_ONAV' },
    {
      label: 'Integrazione wine connoisseur ',
      value: 'INTEGRAZIONE_WINE_CONNOISSEUR',
    },
  ];

  const courseOptions: IOption[] = [
    { label: 'Spesa amministrativa', value: 'SPESA_AMMINISTRATIVA' },
    { label: 'Quota corso', value: 'QUOTA_CORSO' },
  ];
  const eventOptions: IOption[] = [
    { label: 'Quota evento', value: 'QUOTA_EVENTO' },
  ];

  const renewQuoteOption: IOption[] = [
    {
      label: 'Quota associativa',
      value: 'QUOTA_ASSOCIATIVA',
    },
    {
      label: 'Erogazione liberale a favore attività istituzionale',
      value: 'EROGAZIONE_LIBERALE_A_FAVORE_ATTIVITA_ISTITUZIONALE',
    },
    {
      label: 'Cessione avanzo giacenze',
      value: 'CESSIONE_AVANZO_GIACENZE',
    },
    {
      label: 'Proventi vari',
      value: 'PROVENTI_VARI',
    },
  ];

  const segreteriaOnlyOptions: IOption[] = [
    ...renewQuoteOption,
    ...courseOptions,
    ...eventOptions,
    { label: 'Gadget ONAV', value: 'GADGET_ONAV' },
    { label: 'Materiale didattico', value: 'MATERIALE_DIDATTICO' },
    { label: 'Rinnovo quota associativa', value: 'RINNOVO_QUOTA_ASSOCIATIVA' },
    { label: 'Evento ONAV', value: 'EVENTO_ONAV' },
  ];

  if (role === roles.SEGRETERIA_NAZIONALE || (isUserDelegato && !isFree)) {
    if (!isFree) return [...commonOptions, ...segreteriaOnlyOptions];
    if (isCourse) return [...courseOptions, ...renewQuoteOption];
    if (isEvent) return [...eventOptions, ...renewQuoteOption];

    return [
      ...commonOptions,
      ...renewQuoteOption,
      { label: 'Causale libera', value: 'CUSTOM' },
    ];
  }
  return commonOptions;
};

interface Props {
  receiptForm: UseFormReturn<ReceiptRequest, ReceiptRequest>;
  index: number;
  disabled?: boolean;
  idx: number;
  treasury?: boolean;
}
const ReceiptEntry: React.FC<Props> = ({
  receiptForm,
  index,
  disabled = false,
  idx,
  treasury,
}) => {
  const { register, setValue, getValues, watch, control, formState } =
    receiptForm;
  const { errors } = formState;
  const [{ profile }] = useAuth();

  useEffect(() => {
    if (isDelegato(profile)) {
      //@ts-ignore
      if (watch(`entries[${index}].causal`) === ReceiptCausal.MATERIALE_ONAV)
        //@ts-ignore
        return setValue(`entries[${index}].costCenter`, 'NATIONAL');
      if (
        //@ts-ignore
        watch(`entries[${index}].causal`) ===
        ReceiptCausal.INTEGRAZIONE_WINE_CONNOISSEUR
      )
        //@ts-ignore
        return setValue(`entries[${index}].costCenter`, 'DELEGATION');
    }
    //@ts-ignore
  }, [watch(`entries[${index}].causal`)]);

  return (
    <div className='flex flex-row items-center w-full max-w-[60rem] my-4'>
      <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM font-bold text-primary'>
        {index + 1}
      </div>

      <div className='bg-gray-100 p-6 rounded-md gap-8 mx-8 w-full'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
          <ControlledCurrencyInput
            id='amount'
            label='Importo'
            name={`entries[${index}].amount`}
            control={control as any}
            disabled={disabled}
            //@ts-ignore
            error={{ ...errors[`entries[${index}].amount`], type: 'required' }}
          />

          <SelectPresentational
            name={`entries[${idx}].causal`}
            label='Causale'
            control={control}
            disabled={disabled}
            //@ts-ignore
            error={{ ...errors[`entries[${index}].causal`], type: 'required' }}
            options={getCausalOptions(
              profile,
              treasury,
              watch('isEvent'),
              watch('isCourse')
            )}
          />

          <SelectPresentational
            name={`entries[${idx}].costCenter`}
            label='Centro di costo'
            disabled={disabled || isDelegato(profile)}
            error={{
              //@ts-ignore
              ...errors[`entries[${index}].costCenter`],
              type: 'required',
            }}
            control={control}
            options={[
              { label: 'Delegazione', value: 'DELEGATION' },
              { label: 'Segreteria nazionale', value: 'NATIONAL' },
            ]}
          />
        </div>
        {/*//@ts-ignore */}
        {watch(`entries[${index}].causal`) === 'CUSTOM' && (
          <InputPresentational
            id='customLabel'
            label='Inserire Causale libera'
            name={`entries[${index}].customLabel`}
            register={register}
            type='text'
            disabled={disabled}
            error={{
              //@ts-ignore
              ...errors[`entries[${index}].customLabel`],
              type: 'required',
            }}
          />
        )}
      </div>
      <button
        className={`h-6 w-6 min-w-[1.5rem] border border-primary rounded-full flex justify-center items-center font-IBM   ${
          disabled
            ? 'text-primary cursor-not-allowed '
            : 'text-primary cursor-pointer hover:bg-primary hover:text-white'
        }`}
        onClick={() =>
          setValue(
            'entries',
            getValues().entries.filter((elem, idx) => idx !== index)
          )
        }
        disabled={disabled}
      >
        <MinusIcon className='w-4 h-4' />
      </button>
    </div>
  );
};

export default ReceiptEntry;

import InputPresentational from '../../../../components/Form/InputPresentational';
import DeleteAccountModal from '../../../../components/Modals/DeleteAccountModal';
import H3Styled from '../../../../components/Typography/H3Styled';
import { useAuth } from '../../../../contexts/Auth';
import { Profile } from '../../../../interfaces/profile';
import { isDelegato } from '../../../../libs/utils/auth';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { confirmUser } from '../../../../api/profile';

const { REACT_APP_ASSISTENCE_EMAIL_ADDRESS } = process.env;

const ModificaUtenteImpostazioni = ({ infoUser }: { infoUser: Profile }) => {
  const [{ profile, token }] = useAuth();
  const queryClient = useQueryClient();

  const { mutate: confirm } = useMutation(confirmUser, {
    onSuccess: () => {
      toast.success('Account confermato con successo');
      queryClient.invalidateQueries({ queryKey: ['profile2'] });
    },
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });

  return (
    <>
      {!isDelegato(profile) ? (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-end'>
          {!infoUser.attributes.user.data?.attributes.confirmed && (
            <>
              <div className='space-y-5'>
                <H3Styled>Conferma registrazione</H3Styled>
                <p className='text-sm text-gray-400'>
                  <br />
                  Clicca “Conferma registrazione” per confermare la
                  registrazione dell'utente.
                </p>
              </div>
              <div>
                <PrimaryButton
                  outline
                  onClick={() =>
                    confirm({ id: infoUser.attributes.user.data?.id!, token })
                  }
                >
                  Conferma registrazione
                </PrimaryButton>
              </div>
            </>
          )}
          <div className='space-y-5 mt-10'>
            <H3Styled>Cambio indirizzo email</H3Styled>
            <p className='text-sm text-gray-400'>
              Per il cambio indirizzo email è necessario richiedere assistenza.
              <br />
              Clicca “Cambia indirizzo email” per rivolgerti all’assistenza.
            </p>
            <InputPresentational
              id='email'
              label='Email'
              name='email'
              type='text'
              defaultValue={infoUser.attributes.user.data?.attributes.email}
              disabled
            />
          </div>
          <div>
            <a
              href={`mailto:${REACT_APP_ASSISTENCE_EMAIL_ADDRESS}?subject=Richiesta cambio indirizzo email utente ONAV`}
              className='font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base bg-transparent rounded-full text-primary border border-primary py-2 px-6 w-max'
            >
              Cambia indirizzo email
            </a>
          </div>
          <div className='space-y-5 mt-10'>
            <H3Styled>Elimina account</H3Styled>
            <p className='text-sm text-gray-400'>
              Eliminando l’account del profilo utente selezionato tutti i suoi
              dati andranno persi.
              <br />
              <b>L’azione è irreversibile</b>.
            </p>
          </div>
          <div>
            <DeleteAccountModal infoUser={infoUser} />
          </div>
        </div>
      ) : (
        <p className='py-20 text-gray-400 '>
          Solo la segreteria nazionale ha accesso a queste impostazioni{' '}
        </p>
      )}
    </>
  );
};

export default ModificaUtenteImpostazioni;

import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import InputPresentational from '../../../components/Form/InputPresentational';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { PrintDiplomaForm } from '../../../interfaces/profile';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { useMutation, useQuery } from 'react-query';
import { profileCategories } from '../../../api/profileCategories';
import { createDiploma, getProfiles } from '../../../api/profile';
import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useAuth } from '../../../contexts/Auth';
import SimpleCheckboxPresentational from '../../../components/Form/SimpleCheckboxPresentational';
import SelectPresentational from '../../../components/Form/SelectPresentational';
import { levels } from '../../../constants/levels';
import { SearchableSelect } from '../../../components/Form/SearchableSelect';
import { getQuerySearchFilters } from '../../../libs/utils/profile';

const StampaDiploma = () => {
  const [{ token }] = useAuth();

  const { handleSubmit, control, register, formState, watch, resetField } =
    useForm<PrintDiplomaForm>({
      reValidateMode: 'onChange',
      resolver: useYupValidationResolver(
        Yup.object().shape({
          category: Yup.object().required('Questo campo è obbligatorio'),
          profile: Yup.object().required('Questo campo è obbligatorio'),
          courseLevel: Yup.string().required('Questo campo è obbligatorio'),
          evaluation: Yup.number()
            .min(1, 'Il valore più basso deve essere 1')
            .max(30, 'Il valore più alto deve essere 30'),
          cumLaude: Yup.boolean(),
          customLocation: Yup.string().required('Questo campo è obbligatorio'),
          customDate: Yup.string().required('Questo campo è obbligatorio'),
          withBackground: Yup.boolean(),
        })
      ),
    });

  const [isFirstLevel, setIsFirstLevel] = useState<boolean>(true);

  useEffect(() => {
    if (
      !!watch('courseLevel') &&
      String(watch('courseLevel')) !== levels[0].value
    ) {
      setIsFirstLevel(false);
    } else {
      resetField('evaluation');
      resetField('cumLaude');
      setIsFirstLevel(true);
    }
  }, [watch('courseLevel')]);

  const [isFullVote, setIsFullVote] = useState<boolean>(false);

  useEffect(() => {
    if (!!watch('evaluation') && watch('evaluation') === 30) {
      setIsFullVote(true);
    } else {
      resetField('cumLaude');
      setIsFullVote(false);
    }
  }, [watch('evaluation')]);

  const [search, setSearch] = useState<{
    profile: string;
    category: string;
  }>({
    profile: '',
    category: '',
  });

  const [debouncedSearch] = useDebounce(search, 500);

  const { mutate } = useMutation('createDiploma', createDiploma);

  const onSubmit = (data: PrintDiplomaForm) => {
    mutate({
      token,
      body: {
        category: data.category.value,
        profile: data.profile.value,
        courseLevel: data.courseLevel,
        evaluation: data.evaluation,
        cumLaude: data.cumLaude,
        customDate: data.customDate,
        customLocation: data.customLocation,
        withBackground: data.withBackground,
      },
    });
  };

  const { data: profileCategoriesData, isFetching: isProfileCatDataFetching } =
    useQuery(['getProfileCategories', debouncedSearch.category], () => {
      const keywords = debouncedSearch.category
        .trim()
        .split(' ')
        .filter((keyword) => keyword !== '');

      const searchFilters = keywords?.map((keyword) => {
        return {
          $or: [{ title: { $contains: keyword } }],
        };
      });

      return profileCategories({
        query: {
          filters: {
            $and: searchFilters,
          },
          populate: ['orders.payment', 'orders.cardHistory'],
        },
      });
    });

  const profilesQuery = useQuery(
    ['getProfileListForParticipations', debouncedSearch.profile],
    () => {
      return getProfiles({
        token,
        query: {
          filters: {
            $and: getQuerySearchFilters(debouncedSearch.profile),
          },
          pagination: {
            pageSize: 50,
          },
        },
      });
    }
  );

  return (
    <>
      <TitlePage title='Stampa diploma' />

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='col-span-12 lg:col-span-9 xl:col-span-10'
      >
        <H1Styled>Stampa diploma</H1Styled>

        <div className='my-10'>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              01
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Scegli tipologia
            </p>
            <SearchableSelect
              className='w-full'
              name='category'
              control={control}
              isLoading={isProfileCatDataFetching}
              options={
                profileCategoriesData?.data.map((profileCategory) => ({
                  label: profileCategory.attributes.title,
                  value: profileCategory.id,
                })) || []
              }
              onInput={(_search) =>
                setSearch((oldValues) => ({ ...oldValues, category: _search }))
              }
              error={{ ...formState.errors['category'], type: 'required' }}
            />
          </div>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              02
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Scegli l'utente
            </p>
            <SearchableSelect
              className='w-full'
              name='profile'
              control={control}
              isLoading={profilesQuery.isFetching}
              options={
                profilesQuery.data?.data?.map((elem) => ({
                  label: `${elem.attributes.name} ${elem.attributes.surname}`,
                  value: elem.id,
                })) || []
              }
              onInput={(_search) =>
                setSearch((oldValues) => ({ ...oldValues, profile: _search }))
              }
              error={{ ...formState.errors['profile'], type: 'required' }}
            />
          </div>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              03
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Seleziona il livello del corso
            </p>
            <SelectPresentational
              name='courseLevel'
              control={control}
              options={levels}
              required
              error={{ ...formState.errors['courseLevel'], type: 'required' }}
            />
          </div>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              04
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Scrivi il voto dell'esame
            </p>
            <div className=' w-full flex items-center'>
              <InputPresentational
                label=''
                name='evaluation'
                type='number'
                register={register}
                disabled={isFirstLevel}
                error={{ ...formState.errors['evaluation'], type: 'required' }}
              />
              {isFullVote && (
                <SimpleCheckboxPresentational
                  id='cumLaude'
                  name='cumLaude'
                  label='Lode'
                  className='pl-10'
                  register={register}
                />
              )}
            </div>
          </div>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              05
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Scrivi la data dell'esame
            </p>
            <InputPresentational
              id='1'
              label=''
              name='customDate'
              type='text'
              register={register}
              error={{ ...formState.errors['customDate'], type: 'required' }}
            />
          </div>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              06
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Seleziona il luogo dell'esame
            </p>
            <InputPresentational
              id='customLocation'
              label=''
              name='customLocation'
              type='text'
              register={register}
              error={{
                ...formState.errors['customLocation'],
                type: 'required',
              }}
            />
          </div>
          <div className='flex w-full max-w-[60rem] my-4 mb-8 items-center'>
            <div className='h-12 w-12 min-w-[3rem] border border-primary rounded-full flex justify-center items-center font-IBM text-primary '>
              07
            </div>
            <p className='mx-6 md:w-1/2 lg:w-4/9 text-lg text-gray-700'>
              Immagine sfondo
            </p>

            <span className='flex w-full'>
              <SimpleCheckboxPresentational
                id='withBackground'
                name='withBackground'
                register={register}
              />
            </span>
          </div>
        </div>
        <PrimaryButton type='submit'>Stampa</PrimaryButton>
      </form>
    </>
  );
};

export default StampaDiploma;

import React from 'react';
import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { provinces } from '../../api/provinces';
import { FieldError } from 'react-hook-form';

const ProvincesSelect = ({
  defaultValue,
  control,
  name,
  region, // Passandogli l'id della regione si mostrano solo le province di quella regione
  required,
  legendIcons,
  error,
}: {
  defaultValue?: number;
  control?: any;
  name?: string;
  region?: number;
  required?: boolean;
  legendIcons?: JSX.Element[];
  error?: FieldError;
}) => {
  const query = useQuery('provinces', provinces);

  return (
    <>
      {match(query)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          return (
            <SelectPresentational
              label='Provincia'
              name={name || 'province'}
              control={control}
              defaultValue={defaultValue}
              options={(region
                ? query.data?.data.filter(
                    (province) =>
                      province?.attributes?.region?.data?.id === region
                  )
                : query.data?.data
              ).map((province) => ({
                value: province.id,
                label: province.attributes.name,
              }))}
              required={required}
              legendIcons={legendIcons}
              error={error}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default ProvincesSelect;

import React, { useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { CourseLessonRequest } from '../../interfaces/lesson';
import SingleTeacherSelect from '../FetchSelect/SingleTeacherSelect';
import InputPresentational from './InputPresentational';

interface RadioGroupTeacherProps {
  causale?: string;
  control?: any;
}
const RadioGroupTeacher: React.FC<RadioGroupTeacherProps> = () => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CourseLessonRequest>();

  const [showTeachersSelect, setShowTeachersSelect] = useState(true);
  const [
    isWaiverTeacher,
    availableTeacher,
    waiverTeacher,
    teacher,
    lessonTemplate,
  ] = watch([
    'isWaiverTeacher',
    'availableTeacher',
    'waiverTeacher',
    'teacher',
    'lessonTemplate',
  ]);

  useEffect(() => {
    if (isWaiverTeacher) {
      setShowTeachersSelect(false);
    } else {
      setShowTeachersSelect(true);
      setValue('waiverTeacher', '');
    }
  }, [isWaiverTeacher]);

  return (
    <fieldset className='mt-4'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
        <div key='teacher1' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='isWaiverTeacher'
              aria-describedby='description'
              name='isWaiverTeacher'
              type='radio'
              className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
              checked={!isWaiverTeacher}
              onClick={() => {
                setValue('isWaiverTeacher', false);
              }}
            />
          </div>
          <div className='ml-3 text-sm w-full'>
            <label htmlFor='teacher1' className='font-medium text-gray-700'>
              Scegli un docente dalla lista
            </label>
            {showTeachersSelect && (
              <SingleTeacherSelect
                availableTeacher={availableTeacher as number[]}
                label=''
                id={'teacher'}
                name={'teacher'}
                disabled={isWaiverTeacher}
                control={control as Control<any>}
                error={{ ...errors.teacher, type: 'required' }}
                defaultValue={watch('teacher')}
                lessonTemplateId={lessonTemplate?.data?.id}
              />
            )}
          </div>
        </div>

        <div key='teacher2' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='teacher2'
              aria-describedby='description'
              name='teacherType'
              type='radio'
              className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
              checked={isWaiverTeacher}
              onClick={() => {
                setValue('isWaiverTeacher', true);
              }}
            />
          </div>
          <div className='ml-3 text-sm w-full'>
            <label htmlFor='teacher2' className='font-medium text-gray-700'>
              Docente in deroga
            </label>

            <div className='mt-1'>
              {!showTeachersSelect && (
                <InputPresentational
                  id='waiverTeacher'
                  name='waiverTeacher'
                  type='text'
                  label=''
                  register={register}
                  defaultValue={waiverTeacher}
                  disabled={!isWaiverTeacher}
                  error={{ ...errors.waiverTeacher, type: 'required' }}

                  // className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 text-sm'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default RadioGroupTeacher;

import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createLocation,
  location,
  updateLocation,
} from '../../../../api/location';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import InputPresentational from '../../../../components/Form/InputPresentational';
import TitlePage from '../../../../components/TitlePage';
import H1Styled from '../../../../components/Typography/H1Styled';
import { useAuth } from '../../../../contexts/Auth';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { locationValidator } from '../../../../validators/location';

export default function ModificaLocation() {
  const [{ token }] = useAuth();

  const navigate = useNavigate();
  const { id, locationId } = useParams();

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm({ resolver: useYupValidationResolver(locationValidator) });

  if (locationId) {
    useQuery({
      queryKey: ['location', locationId],
      queryFn: () => location(locationId),
      onSuccess: (data) => reset(data.data.attributes),
    });
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ location }: any) => {
      if (locationId)
        return updateLocation(Number(locationId), location, token);
      return createLocation(location, token);
    },
    onSuccess: () => {
      toast.success('Location creata con successo');
      navigate(`/sezioni/modifica/${id}/location`);
    },
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });

  const createNewLocation = async (data: FieldValues) => {
    //  removeEmptyFields(data);
    mutate({
      location: { ...data, sections: id },
    });
  };

  return (
    <>
      <TitlePage title='Crea nuovo corso | Location' />

      <form className='mt-6' onSubmit={handleSubmit(createNewLocation)}>
        <H1Styled>
          {locationId ? 'Modifica' : 'Aggiungi nuova'} Location
        </H1Styled>
        <div className='grid grid-cols-1 gap-4 pt-10 pb-8 md:grid-cols-2'>
          <InputPresentational
            id='name'
            label='Nome Location'
            name='name'
            type='text'
            register={register}
            required
            error={{ ...errors.name, type: 'required' }}
          />
          <InputPresentational
            id='city'
            label='Città'
            name='city'
            type='text'
            register={register}
            required
            error={{ ...errors.city, type: 'required' }}
          />
          <InputPresentational
            id='address'
            label='Indirizzo'
            name='address'
            type='text'
            register={register}
            required
            error={{ ...errors.address, type: 'required' }}
          />

          <InputPresentational
            id='website'
            label='Sito web'
            name='website'
            type='text'
            register={register}
          />
        </div>

        {/* <label className="flex justify-between mb-2 text-sm text-gray-500">
          <div>Mappa </div>
        </label>
        <div className="grid-cols-2 mb-10 bg-gray-100 rounded-md h-80"> </div> */}

        <div className='flex items-center h-10'>
          <div className='flex items-center justify-between space-x-6'>
            <PrimaryButton
              outline
              onClick={() => {
                navigate('/sezioni/modifica/' + id + '/location');
              }}
            >
              Indietro
            </PrimaryButton>
            <PrimaryButton type='submit'>Salva</PrimaryButton>
          </div>
        </div>
      </form>
    </>
  );
}

import TitlePage from "../../../../components/TitlePage";
import FormDatiPrincipaliTemplate from "../../../../components/FormDatiPrincipaliTemplate";

interface DatiPrincipaliTemplateProps {
}

const DatiPrincipaliTemplate: React.FC<DatiPrincipaliTemplateProps> = ({
}) => {
  return (
    <>
      <TitlePage title='Template corsi | Dati principali' />

      <form>
        <FormDatiPrincipaliTemplate />
      </form>
    </>
  );
};

export default DatiPrincipaliTemplate;

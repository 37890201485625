import TitlePage from '../../../components/TitlePage';
import { useQuery } from 'react-query';
import Underline from '../../../components/TextLink/Underline';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LessonsDetailCourseTable from '../Tables/LessonsDetailCourseTable';
import { getLessons } from '../../../api/lesson';
import { useAuth } from '../../../contexts/Auth';
import Pagination from '../../../components/Pagination';
import { useDebounce } from 'use-debounce';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { useState } from 'react';

interface Props {
  isDirty: boolean;
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const Lezioni: React.FC<Props> = ({ isDirty }) => {
  const [{ token }] = useAuth();
  const { id: courseId } = useParams();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);
  const navigate = useNavigate();
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const toggleWarning = () => setIsWarningOpen((v) => !v);

  const { data } = useQuery(
    ['lessons', courseId, ...debounceSearchParams],
    () => {
      return getLessons({
        filters: {
          course: {
            id: {
              $eq: courseId,
            },
          },
        },
        populate: '*',
        token,
        searchParams: debounceSearchParams,
      });
    }
  );

  const handleAddLesson = () => {
    if (isDirty) return toggleWarning();
    navigate(`/corsi/nuovo-corso/${courseId}/lezioni/aggiungi-lezione`);
  };

  return (
    <>
      <TitlePage title='Crea nuovo corso | Lezioni' />

      <div className='mt-6'>
        <div className='pt-6 pb-8'>
          <div className='mb-6 flex items-center justify-end gap-4'>
            <button onClick={handleAddLesson}>
              <Underline
                // href={`/corsi/dettaglio-corso/${courseId}/lezioni/aggiungi-lezione`}
                label='Crea lezione'
              />
            </button>
            {/* <UnderlineButton onClick={() => {}}>
              <DownloadIcon className="w-4 h-4" /> Scarica CSV lezioni
            </UnderlineButton> */}
          </div>
          <LessonsDetailCourseTable
            lessons={data?.data}
            page={data?.meta?.pagination?.page}
            pageSize={data?.meta?.pagination?.pageSize}
            isDirty={isDirty}
            isDetail={false}
          />
          <Pagination pagination={data?.meta?.pagination} />
        </div>
      </div>
      <ConfirmationModal
        isOpen={isWarningOpen}
        onClose={toggleWarning}
        onConfirm={() =>
          navigate(`/corsi/nuovo-corso/${courseId}/lezioni/aggiungi-lezione`)
        }
        title='ATTENZIONE!'
        subtitle='Se prosegui perderai le ultime modifiche apportate'
        textButton='Prosegui'
      />
    </>
  );
};

export default Lezioni;

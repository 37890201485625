import React, { useEffect } from 'react';
import { DownloadIcon } from '@heroicons/react/outline';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';

import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import UsciteTable from '../Tables/UsciteTable';
import Filters from '../../../components/Filters/Filters';
import { useAuth } from '../../../contexts/Auth';
import { useQuery } from 'react-query';
import { listOutflows } from '../../../api/outflows';
import { useSearchParams } from 'react-router-dom';

const TesoreriaUscite = () => {
  const [{ token }] = useAuth();

  const [searchParams] = useSearchParams();

  const outflowsQuery = useQuery({
    queryKey: ['outflows'],
    queryFn: () => listOutflows(searchParams, token),
  });

  useEffect(() => {
    outflowsQuery.refetch();
  }, [searchParams]);

  return (
    <>
      <TitlePage title='Tutte le uscite' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Tutte le uscite</H1Styled>

        <div className='space-y-5'>
          <Filters section date searching />
          <div className='w-full flex justify-end'>
            <UnderlineButton onClick={() => {}}>
              <DownloadIcon className='w-4 h-4' /> Scarica CSV uscite
            </UnderlineButton>
          </div>
          <UsciteTable outflowsQuery={outflowsQuery} />
        </div>
      </div>
    </>
  );
};

export default TesoreriaUscite;

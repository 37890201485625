import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getStatusTitle } from '../libs/utils/course';
import BackButton from './Buttons/BackButton';
import PrimaryButton from './Buttons/PrimaryButton';
import SandButton from './Buttons/SandButton';
import H1Styled from './Typography/H1Styled';

interface HeaderTitleProps {
  category: string;
  title?: string;
  modal?: any;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  preview?: boolean;
  previewButtonOnClick?: () => void;
  showInOnavit?: boolean;
  buttonsDisabled?: boolean;
}
const HeaderTitle: React.FC<HeaderTitleProps> = ({
  category,
  title,
  modal,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  preview,
  previewButtonOnClick,
  buttonsDisabled,
}) => {
  const { watch } = useFormContext();

  return (
    <>
      <BackButton />
      <div className='flex flex-col  md:flex-row mt-2'>
        <div className='w-full md:w-2/3 text-center md:text-left'>
          <H1Styled>
            <span className='font-medium'>{category}</span>: {watch('title')}
          </H1Styled>
        </div>
        <div className='flex flex-col items-center md:items-end gap-4 w-full md:w-1/3'>
          <div className='flex gap-3'>
            {preview && (
              <SandButton smallRadius onClick={previewButtonOnClick}>
                Visualizza anteprima
              </SandButton>
            )}

            {secondaryButtonText && (
              <PrimaryButton
                outline
                onClick={secondaryButtonOnClick}
                disabled={buttonsDisabled}
                type='submit'
              >
                {secondaryButtonText}
              </PrimaryButton>
            )}

            {primaryButtonText && (
              <PrimaryButton
                onClick={primaryButtonOnClick}
                disabled={buttonsDisabled}
                type='submit'
              >
                {primaryButtonText}
              </PrimaryButton>
            )}
          </div>

          {modal && (
            <div className='flex items-center gap-2'>
              <p className='text-gray-500 '>
                Stato {category}:{' '}
                <span className='uppercase text-gray-800 font-bold'>
                  {watch('status') ? getStatusTitle(watch('status')) : ' - '}
                </span>
              </p>
              {modal}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderTitle;

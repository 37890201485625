import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import coursesAPIs from '../../api/courses';
import { deleteCourse } from '../../api/courses';
import Pagination from '../../components/Pagination';
import TitlePage from '../../components/TitlePage';
import H1Styled from '../../components/Typography/H1Styled';
import { useAuth } from '../../contexts/Auth';
import CoursesTable from './Tables/CoursesTable';
import sectionsAPIs from '../../api/section';
import { FilterType } from '../../interfaces/filters';
import FiltersBar from '../../components/Filters/FiltersBar';
import { CourseStatus } from '../../interfaces/commons';
import qs from 'qs';
import { getDateTimeFromDate } from '../../libs/utils/helpers';
import { isDelegato } from '../../libs/utils/auth';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function ListaCorsi() {
  const [searchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const query = useQuery(['courses', ...debounceSearchParams], () =>
    coursesAPIs.find({
      token,
      query: {
        filters: {
          title: { $contains: debounceSearchParams.get('search') },
          section: {
            id: {
              $in: Object.entries(
                qs.parse(debounceSearchParams.get('sections') + '')
              )
                .map(([, value]) => value)
                .map((elem: any) => elem.value),
            },
          },
          startDate: { $contains: debounceSearchParams.getAll('year') },
          $and: [
            {
              startDate: {
                $gte: qs.parse(
                  Object.entries(
                    qs.parse(debounceSearchParams.get('date') + '')
                  ).map(([, value]) => value as any)[0]?.label
                ).from,
                $lte: getDateTimeFromDate(
                  qs.parse(
                    Object.entries(
                      qs.parse(debounceSearchParams.get('date') + '')
                    ).map(([, value]) => value as any)[0]?.label
                  ).to
                ),
              },
            },
            {
              status: {
                $in: Object.entries(
                  qs.parse(debounceSearchParams.get('status') + '')
                )
                  .map(([, value]) => value)
                  .map((elem: any) => elem.value),
              },
            },
          ],
        },
        populate: '*',
        pagination: {
          page: searchParams.get('page'),
          pageSize: searchParams.get('pageSize'),
        },
        sort: 'createdAt:desc',
      },
    })
  );

  const { mutate: deleteCourseMutation } = useMutation({
    mutationFn: (id: string | number) => deleteCourse(id, token),
    onSuccess: () => {
      toast.success('Corso eliminato con successo');
      query.refetch();
    },
    onError: () => {
      toast.error('Qualcosa è andato storto!');
    },
  });

  return (
    <>
      <TitlePage title='Corsi' />
      <H1Styled>Tutti i corsi</H1Styled>

      <div className='space-y-5'>
        <FiltersBar
          filters={[
            {
              type: FilterType.DATE,
              attribute: 'date',
              label: 'Data',
              key: 'data',
            },
            {
              type: FilterType.MULTISELECT,
              attribute: 'sections',
              label: 'Sezione',
              key: 'sectionsListFilters',
              source: (data: any) => {
                return sectionsAPIs.findOptions({
                  ...data,
                  query: {
                    ...data.query,
                    ...(isDelegato(profile)
                      ? { filters: { id: profile?.section.data?.id } }
                      : {}),
                  },
                });
              },
              searchForAttributes: ['name'],
            },
            {
              type: FilterType.MULTISELECT,
              attribute: 'status',
              label: 'Stato',
              key: 'courseStatus',
              source: () => ({
                data: [
                  { id: CourseStatus.DRAFT, attributes: { name: 'Bozza' } },
                  {
                    id: CourseStatus.TO_APPROVE,
                    attributes: { name: 'In approvazione' },
                  },
                  {
                    id: CourseStatus.NOT_APPROVED,
                    attributes: { name: 'Non approvato' },
                  },
                  {
                    id: CourseStatus.PUBLISHED,
                    attributes: { name: 'Pubblicato' },
                  },
                  {
                    id: CourseStatus.FINAL_BALANCE_DELEGATION,
                    attributes: { name: 'Consuntivato da sezione' },
                  },
                  {
                    id: CourseStatus.FINAL_BALANCE_NATIONAL,
                    attributes: {
                      name: 'Consuntivato da segreteria nazionale',
                    },
                  },
                  {
                    id: CourseStatus.CANCELED,
                    attributes: { name: 'Annullato' },
                  },
                ],
              }),
            },
            {
              type: FilterType.SEARCH_BAR,
              attribute: 'search',
              label: 'Cerca...',
            },
          ]}
        />
        <CoursesTable query={query} deleteCourse={deleteCourseMutation} />
        <Pagination pagination={query.data?.meta.pagination} />
      </div>
    </>
  );
}

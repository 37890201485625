import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/react-hook-form';

const NumberSelect = ({
  defaultValue,
  label,
  name,
  required,
  legendIcons,
  error,
}: {
  defaultValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
  legendIcons?: JSX.Element[];
  error?: FieldError;
}) => {
  const { control } = useFormContext();

  return (
    <div>
      <div className='flex flex-row'>
        {' '}
        <label className='text-sm text-gray-500 flex justify-between'>
          <div>{`${label}${required ? ' *' : ''}`}</div>
        </label>
        {legendIcons && (
          <ul className='flex flex-row'>
            {legendIcons.map((legendIcon, idx) => {
              return (
                <li className='flex h-5 mx-1' key={`input-legend-${idx}`}>
                  {legendIcon}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <PhoneInput name={name || 'mobilePhone'} control={control} />
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export default NumberSelect;

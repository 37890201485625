import { useState } from 'react';

import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { toast } from 'react-toastify';

// interfaces
import { CourseTemplateRequest } from '../../../../interfaces/course-templates';

// hooks
import { useAuth } from '../../../../contexts/Auth';
import { withFilesContext } from '../../../../contexts/CourseTemplateFilesContext';

// components../NuovoTemplate/HeaderTitleTemplate
import CostiTemplate from './costi';
import DatiPrincipaliTemplate from './dati-principali';
import DirezioneTemplate from './direzione';
import HeaderTitleTemplate from '../../../../components/HeaderTitleTemplate';
import LezioniTemplate from './lezioni';
import TabBar from '../../../../components/TabBars/TabBar';

// api
import {
  getCourseTemplate,
  putCourseTemplate,
} from '../../../../api/course-templates';

// utils
import { emptyCourseTemplate } from '../../../../constants/course-templates';
import { fromResponseToRequest } from '../../../../libs/utils/formatters/courseTemplateFormatters';
import { CourseLessonTemplateResponse } from '../../../../interfaces/course-template-lessons';
import {
  getCourseTemplateLessons,
  putCourseTemplateLesson,
} from '../../../../api/course-lesson-templates';
import { IOption } from '../../../../components/Form/Multiselect';
import CostsCenter from './costs-center';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { updateCourseTemplateValidator } from '../../../../validators/courseTemplate';

const ModificaTemplateCorsi = () => {
  const [{ token, settings }] = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [relations, setRelations] = useState<CourseLessonTemplateResponse[]>(
    []
  );

  const tabs = [
    {
      label: 'Dati principali',
      href: `/corsi/template/lista-corsi/${id}/dati-principali`,
    },
    {
      label: 'Lezioni',
      href: `/corsi/template/lista-corsi/${id}/lezioni`,
    },
    {
      label: 'Direzione',
      href: `/corsi/template/lista-corsi/${id}/direzione`,
    },
    {
      label: 'Costi',
      href: `/corsi/template/lista-corsi/${id}/costi`,
    },
    {
      label: 'Centri di costo',
      href: `/corsi/template/lista-corsi/${id}/costs-center`,
    },
  ];

  const [searchParams] = useSearchParams();
  searchParams.set('courseTemplate', id!);

  const courseTemplateCourse = useForm<CourseTemplateRequest>({
    defaultValues: emptyCourseTemplate,
    resolver: useYupValidationResolver(updateCourseTemplateValidator),
  });

  useQuery({
    queryKey: ['course-template', id],
    queryFn: () => {
      return getCourseTemplate(id!, token);
    },
    onError: async () => {
      toast.error('Errore durante il recupero dei dati');
      navigate('/corsi/template/lista-corsi');
    },
    onSuccess: async ({ data: courseTemplateResponse }) => {
      courseTemplateCourse.reset(
        fromResponseToRequest(courseTemplateResponse, settings)
      );
    },
  });

  const { mutateAsync: updateCourse } = useMutation({
    mutationFn: () => {
      const {
        minProfileCategorySub,
        treasurerAmount,
        supervisorAmount,
        safetyMargin,
      } = courseTemplateCourse?.getValues();
      const updatedCourseTemplate = {
        ...courseTemplateCourse.getValues(),
        minProfileCategorySub: minProfileCategorySub as IOption,
        treasurerAmountLimit: treasurerAmount,
        supervisorAmountLimit: supervisorAmount,
        safetyMargin: (safetyMargin || 0) / 100,
      };

      return Promise.all([
        putCourseTemplate(id!, updatedCourseTemplate, token),
        relations.map((relation) =>
          updateRelation({
            relationId: relation.id,
            updatedRelation: {
              ...relation.attributes,
              lessonTemplate: relation.attributes.lessonTemplate.data.id,
            },
          })
        ),
      ]);
    },
    onError: async (err) => {
      console.log('err', err);
      toast.error('Errore durante il salvataggio delle modifiche');
    },
    onSuccess: async () => {
      toast.success('Template corso salvato con successo!');
      navigate('/corsi/template/lista-corsi');
    },
  });

  useQuery({
    queryKey: 'course-lesson-templates',
    queryFn: () => getCourseTemplateLessons(searchParams, token),
    onSuccess: async (data) => setRelations(data.data),
  });

  const { mutateAsync: updateRelation } = useMutation({
    mutationFn: ({ relationId, updatedRelation }: any) => {
      return putCourseTemplateLesson(relationId!, updatedRelation, token);
    },
  });

  return (
    <FormProvider {...courseTemplateCourse}>
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <HeaderTitleTemplate
          category='Modifica template'
          title={courseTemplateCourse.watch('title')}
          preview
          approvalRequest
          primaryButtonOnClick={updateCourse}
        />
        <TabBar tabs={tabs} />
        <Routes>
          <Route path='dati-principali' element={<DatiPrincipaliTemplate />} />
          <Route
            path='lezioni'
            element={
              <LezioniTemplate
                relations={relations}
                setRelations={setRelations}
              />
            }
          />
          <Route path='direzione' element={<DirezioneTemplate />} />
          <Route path='costi' element={<CostiTemplate />} />
          <Route
            path='costs-center'
            element={
              <CostsCenter
                form={courseTemplateCourse}
                title='Centri di costo'
              />
            }
          />
        </Routes>
      </div>
    </FormProvider>
  );
};
export default withFilesContext(ModificaTemplateCorsi);

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { postLessonTemplate } from '../../../../api/lesson-template';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { IOption } from '../../../../components/Form/Multiselect';
import FormLessonTemplate from '../../../../components/FormLessonTemplate';

import TitlePage from '../../../../components/TitlePage';
import H1Styled from '../../../../components/Typography/H1Styled';
import { emptyLessonTemplate } from '../../../../constants/lesson-template';
import { useAuth } from '../../../../contexts/Auth';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { LessonTemplateAttributes } from '../../../../interfaces/lesson-templates';
import { lessonTemplateValidator } from '../../../../validators/lesson';
import { useState } from 'react';

export default function NuovoTemplateLezione() {
  const navigate = useNavigate();
  const [{ token }] = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const newLessonTemplateMethods = useForm<LessonTemplateAttributes>({
    defaultValues: emptyLessonTemplate,
    mode: 'onSubmit',
    resolver: useYupValidationResolver(lessonTemplateValidator),
  });

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const availableTeacher = newLessonTemplateMethods?.getValues()
      ?.availableTeacher as IOption[];

    const newLessonTemplate = {
      ...newLessonTemplateMethods.getValues(),
      availableTeacher:
        (availableTeacher &&
          availableTeacher.map((teacher: any) => teacher.value)) ||
        [],
      estimatePaymentLimit:
        newLessonTemplateMethods.getValues().estimatePayment,
    };

    try {
      await postLessonTemplate(newLessonTemplate, token);
      toast.success('Template lezione creato con successo', {
        type: 'success',
      });
      navigate('/corsi/template/lista-lezioni');
    } catch (e) {
      toast.error('Errore nella creazione del nuovo template', {
        type: 'error',
      });
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <FormProvider {...newLessonTemplateMethods}>
      <TitlePage title='Crea nuovo template lezione' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <div className='space-y-6'>
          <div className='flex flex-col sm:flex-row items-start md:items-center justify-between gap-4 '>
            <H1Styled>Crea nuovo template lezione</H1Styled>
            <div className='flex items-center gap-4'>
              <PrimaryButton
                onClick={newLessonTemplateMethods.handleSubmit(onSubmit)}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Salva template
              </PrimaryButton>
            </div>
          </div>

          <FormLessonTemplate />
        </div>

        <div className='space-y-5'></div>
      </div>
    </FormProvider>
  );
}

import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const courseTemplateValidator = Yup.object().shape({
  title: Yup.string().required(ValidationErrorMessages.REQUIRED),
  subtitle: Yup.string()
    .max(250, 'Limite massimo di caratteri raggiunto')
    .required(ValidationErrorMessages.REQUIRED),
  minProfileCategorySub: Yup.number().required(
    ValidationErrorMessages.REQUIRED
  ),
  level: Yup.string().required(ValidationErrorMessages.REQUIRED),
  shortDescription: Yup.string().required(ValidationErrorMessages.REQUIRED),
});

export const updateCourseTemplateValidator = Yup.object().shape({
  title: Yup.string().required(ValidationErrorMessages.REQUIRED),
  subtitle: Yup.string().required(ValidationErrorMessages.REQUIRED),
  minProfileCategorySub: Yup.number().required(
    ValidationErrorMessages.REQUIRED
  ),
  level: Yup.string().required(ValidationErrorMessages.REQUIRED),
  shortDescription: Yup.string().required(ValidationErrorMessages.REQUIRED),
  initialSubscriptionAmount: Yup.number()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED)
    .min(1, ValidationErrorMessages.POSITIVE),
  administrativeAmount: Yup.number()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED)
    .min(0, ValidationErrorMessages.NOT_ZERO),
  minParticipants: Yup.number()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED)
    .min(0, ValidationErrorMessages.POSITIVE),
  maxParticipants: Yup.number().when('minParticipants', {
    is: (minParticipants: any) => !!minParticipants,
    then: Yup.number()
      .min(
        Yup.ref('minParticipants'),
        '"Il mumero massimo di partecipanti non può essere inferiore al numero minimo"!'
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.number()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
  membershipFee: Yup.string()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED),
  registrationFee: Yup.string()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED),
  courseFee: Yup.string()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED),
});

import React from 'react';
import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { onavPositions } from '../../api/onavPositions';
import { FieldError } from 'react-hook-form';
import getOnavPositionLabel from '../../pages/Sezioni/helpers/getOnavPositionLabel';

const OnavPositionsSelect = ({
  defaultValue,
  control,
  disabled,
  required,
  error,
}: {
  defaultValue?: number;
  control?: any;
  disabled?: boolean;
  required?: boolean;
  error?: FieldError;
}) => {
  const query = useQuery('onavPosition', onavPositions);

  return (
    <>
      {match(query)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          return (
            <SelectPresentational
              name='onavPosition'
              label='Ruolo istituzionale'
              control={control}
              disabled={disabled}
              defaultValue={defaultValue}
              options={query.data?.data.map((position) => ({
                value: position.id,
                label: getOnavPositionLabel(position.attributes.role),
              }))}
              required={required}
              error={error}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default OnavPositionsSelect;

import React from 'react';
import classNames from '../../libs/utils/classNames';

interface PrimaryButtonFullProps {
  onClick: any;
  outline?: boolean;
  children: any;
}

const PrimaryButtonFull: React.FC<
  PrimaryButtonFullProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({ children, onClick, outline }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base bg-primary rounded-full text-white py-2 px-6 w-full',

        outline ? '!text-primary border border-primary !bg-transparent' : ''
      )}
    >
      {children}
    </button>
  );
};

export default PrimaryButtonFull;

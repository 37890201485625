import { Fragment, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Dialog, Transition } from '@headlessui/react';

// context
import { useAuth } from '../../contexts/Auth';

// api
import { postCourseTemplateLesson } from '../../api/course-lesson-templates';
import { getLessonTemplates } from '../../api/lesson-template';

// components
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import FilterLessons from '../Filters/FilterLessons';
import SelectLessons from '../../pages/Corsi/Tables/SelectLessons';

interface AddExistingLessonModalProps {
  startFrom?: number;
  filterIds?: number[];
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const AddExistingLessonModal: React.FC<AddExistingLessonModalProps> = ({
  startFrom = 0,
  filterIds = [],
}) => {
  const queryClient = useQueryClient();
  const [{ token }] = useAuth();
  const { id: courseTemplateId } = useParams();
  const [searchParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const [selectedLessons, setSelectedLessons] = useState<number[]>([]);
  const [open, setOpen] = useState(false);

  const { data } = useQuery(['lessons', ...debounceSearchParams], () =>
    getLessonTemplates(searchParams, token)
  );

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({ lessonTemplateId, order }: any) => {
      return postCourseTemplateLesson(
        Number(courseTemplateId),
        lessonTemplateId,
        order,
        token
      );
    },
  });

  const createRelations = async () => {
    const promises = selectedLessons.map((lessonTemplateId, order) =>
      mutateAsync({ lessonTemplateId, order: order + startFrom })
    );

    await Promise.all(promises);
    queryClient.refetchQueries('course-lesson-templates');
  };

  const lessons = data?.data.filter((l) => !filterIds.includes(l.id)) || [];

  return (
    <>
      <UnderlineButton onClick={() => setOpen(true)}>
        Seleziona template lezione
      </UnderlineButton>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Aggiungi uno o più template di lezione
                </h4>

                <div className='space-y-5'>
                  <FilterLessons />
                  <SelectLessons
                    lessons={lessons}
                    selectedLessons={selectedLessons}
                    setSelectedLessons={setSelectedLessons}
                  />
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton textSmall onClick={() => setOpen(false)}>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton
                    small
                    onClick={() => {
                      setOpen(false);
                      setSelectedLessons([]);
                      createRelations();
                    }}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Aggiungi template lezione
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AddExistingLessonModal;

import axios from 'axios';
import qs from 'qs';
import { ProvinceData, ProvinceListResponse } from '../interfaces/province';

const { REACT_APP_API_URL } = process.env;

export const provinces = async () => {
  const query = {
    populate: '*',
    pagination: {
      pageSize: 200,
    },
  };

  const { data } = await axios.get<ProvinceListResponse>(
    `${REACT_APP_API_URL}/provinces?${qs.stringify(query)}`
  );
  return data;
};

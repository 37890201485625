import React from 'react';
import SelectPresentational from '../Form/SelectPresentational';

const GenderSelect = ({
  defaultValue,
  control,
  required,
  legendIcons
}: {
  defaultValue?: string;
  control?: any;
  required?: boolean;
  legendIcons?: JSX.Element[];
}) => {
  const options = [
    { value: 'M', label: 'Maschio' },
    { value: 'F', label: 'Femmina' },
  ];

  return (
    <SelectPresentational
      name='gender'
      label='Sesso'
      defaultValue={defaultValue}
      options={options}
      control={control}
      required={required}
      legendIcons={legendIcons}
    />
  );
};

export default GenderSelect;

import axios from 'axios';
import qs from 'qs';
import { sendStatus } from '../constants/announcement';

import {
  AnnouncmentsListResponse,
  AnnouncmentResponse,
} from '../interfaces/announcments';
const instance = axios.create();
const { REACT_APP_API_URL } = process.env;

export const listAnnouncment = async (
  searchParams: any,
  token: string | null
) => {
  const selectedStatus = sendStatus.filter(({ title }) =>
    searchParams.getAll('status').find((v: string) => v === title)
  );
  const statusArr = selectedStatus.map((object) => object.id);
  const query = {
    populate: ['audience.profile', 'pdf'],

    pagination: {
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize') || 20,
    },
    filters: {
      title: { $contains: searchParams.get('search') },

      status: { $eq: statusArr },
      $and: [
        {
          updatedAt: {
            $lte: searchParams.get('dateTo'),
            $gte: searchParams.get('dateFrom'),
          },
        },
      ],
    },
  };

  const { data } = await axios.get<AnnouncmentsListResponse>(
    `${REACT_APP_API_URL}/announcments?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const announcmentInfo = async (id: number, token: string | null) => {
  const query = {
    populate: '*',
  };

  const { data } = await axios.get<AnnouncmentResponse>(
    `${REACT_APP_API_URL}/announcments/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createAnnouncment = async ({
  data,
  token,
}: {
  data: any;
  token: string | null;
}) => {
  const formData = new FormData();
  const attachment = data?.data?.pdf?.[0];

  if (attachment) {
    formData.append('files.pdf', attachment);
    delete data.data.pdf;
  }

  formData.append('data', JSON.stringify(data.data));

  const response = await instance.post(
    `${REACT_APP_API_URL}/announcments`,
    formData,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return response;
};

export const updateAnnouncment = async ({
  data,
  token,
  id,
}: {
  data: any;
  token: string | null;
  id: number;
}) => {
  const formData = new FormData();
  const shouldReplaceAttachment = !Boolean(data.pdf.data?.id);

  if (shouldReplaceAttachment) {
    const attachment = data.pdf[0];
    formData.append('files.pdf', attachment);
  }

  delete data.pdf;
  formData.append('data', JSON.stringify(data));

  const response = await instance.put(
    `${REACT_APP_API_URL}/announcments/${id}`,
    formData,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const sendAnnouncement = async ({
  id,
  token,
}: {
  id: string;
  token: string | null;
}) => {
  const response = await instance.post(
    `${REACT_APP_API_URL}/announcments/${id}/schedule`,
    {},
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const deleteAnnouncment = async (
  announcmentId: number,
  token: string | null
) => {
  const { data } = await axios.delete(
    `${REACT_APP_API_URL}/announcments/${announcmentId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const sendAnnouncment = async (
  announcmentId: number,
  token: string | null
) => {
  const { data } = await axios.post(
    `${REACT_APP_API_URL}/announcments/${announcmentId}/sendAnnouncment`,
    {},
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

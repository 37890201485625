import React from 'react';

interface NoteInputTableProps {
  id: string;
  name: string;
  type: string;
  disabled?: boolean;
  register?: any;
  required?: boolean;
}
const NoteInputTable: React.FC<NoteInputTableProps> = ({
  id,
  name,
  type,
  disabled,
  register,
  required = false,
}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      autoComplete={id}
      required={required}
      {...(register &&
        register(name, {
          ...(type === 'number' && {
            valueAsNumber: true,
          }),
        }))}
      disabled={disabled}
      className='w-[200px] appearance-none block px-3 py-2 text-sm border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
    />
  );
};

export default NoteInputTable;

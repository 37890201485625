import _ from 'lodash';
import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import IconButton from '../../../components/Buttons/IconButton';
import { MedalIcon } from '../../../components/CustomIcons';
import Checkbox from '../../../components/Form/Checkbox';
import StatusPaymentModal from '../../../components/Modals/StatusPaymentModal';
import VoteModal from '../../../components/Modals/VoteModal';
import {
  Level,
  StrapiResponse,
  StrapiResponseList,
} from '../../../interfaces/commons';
import { ProductType } from '../../../interfaces/orders';
import {
  Participant,
  ParticipantsResponse,
  ParticipantStatus,
} from '../../../interfaces/participants';
import {
  Payment,
  PaymentMethod,
  PaymentStatus,
} from '../../../interfaces/payments';
import classNames from '../../../libs/utils/classNames';
import {
  formatCurrency,
  getCouponTotalHTML,
} from '../../../libs/utils/helpers';
import Highlighter from 'react-highlight-words';
import Badge from '../../../components/Badge';
import { CourseResponse } from '../../../interfaces/courses';
import Underline from '../../../components/TextLink/Underline';
import { useNavigate, useParams } from 'react-router';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import { useState } from 'react';
import CancelParticipationsModal from '../../../components/Modals/CancelParticipationsModal';
import RenewParticipationsModal from '../../../components/Modals/RenewParticipationsModal';
import NoteModal from '../../../components/Modals/NoteModal';
import moment from 'moment';
import TableHeader from '../../../components/Table/TableHeader';

const tableElements = [
  { key: 'profile.badge.badgeNumber', label: 'Tessera' },
  {
    key: 'profile.surname',
    label: 'Cognome',
    class: 'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-0',
  },
  {
    key: 'profile.name',
    label: 'Nome',
    class: 'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-[120px]',
  },
  { key: 'profile.user.email', label: 'Email' },
  { key: 'profile.mobilePhone', label: 'Telefono' },
  { key: 'profile.profileCategory.title', label: 'Categoria' },
  { key: 'profile.section.name', label: 'Sezione' },
  { key: null, label: 'Esame' },
  { key: null, label: 'Voto' },
  { key: null, label: 'Quota partecipazione' },
  { key: null, label: 'Coupon utilizzato' },
  { key: null, label: 'Quota associativa' },
  { key: null, label: 'Stato pagamento' },
  { key: null, label: 'Metodo di pagamento' },
  { key: null, label: 'Data pagamento' },
  { key: 'createdAt', label: 'Data iscrizione' },
  { key: 'status', label: 'Stato iscrizione' },
  { key: null, label: 'Data ricevuta' },
  { key: null, label: 'Numero ricevuta' },
  { key: null, label: '' },
  { key: null, label: 'Note' },
];

//Stato pagamenti ammessi per la creazione di ricevute
const ALLOWABLE_PAYMENTS_STATES = [
  PaymentStatus.COMPLETED,
  PaymentStatus.PARTIAL,
];

interface SelectPartecipantsProps {
  courseDetailQuery: UseQueryResult<
    StrapiResponse<CourseResponse> | undefined,
    unknown
  >;
  participantsQuery: UseQueryResult<
    StrapiResponseList<ParticipantsResponse>,
    unknown
  >;
  selectedParticipants: Partial<
    ParticipantsResponse & {
      multiple?: boolean | undefined;
    }
  >[];
  checked: boolean;
  onCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedParticipants: React.Dispatch<
    React.SetStateAction<
      Partial<
        ParticipantsResponse & {
          multiple?: boolean | undefined;
        }
      >[]
    >
  >;
  toggleVotesModal: () => void;
  toggleNotesModal: () => void;
  isAssigningVotes: boolean;
  isAssigningNotes: boolean;
  onUpdateParticipant: ({
    vote,
    cumLaude,
    note,
    status,
  }: {
    vote?: string;
    cumLaude?: boolean;
    note?: string;
    status?: ParticipantStatus;
  }) => void;
  bulkUpdateParticipants: (
    participants: { id: number; data: Participant }[]
  ) => void;
  isCancelingParticipations: boolean;
  isRenewingParticipations: boolean;
  toggleCancelingParticipations: () => void;
  toggleRenewingParticipations: () => void;
  onUpdatePayment: ({
    payment,
    id,
  }: {
    payment: Partial<Payment>;
    id?: number;
  }) => void;
  onDownloadReceipt: (arr: (number | undefined)[]) => void;
  searchWords: string[];
  disabled?: boolean;
  isLoading: boolean;
}

const SelectPartecipants: React.FC<SelectPartecipantsProps> = ({
  courseDetailQuery,
  participantsQuery,
  selectedParticipants,
  checked,
  onCheckAll,
  setSelectedParticipants,
  toggleVotesModal,
  toggleNotesModal,
  isAssigningVotes,
  isAssigningNotes,
  onUpdateParticipant,
  onUpdatePayment,
  searchWords,
  onDownloadReceipt,
  isCancelingParticipations,
  isRenewingParticipations,
  toggleCancelingParticipations,
  toggleRenewingParticipations,
  bulkUpdateParticipants,
  disabled,
  isLoading,
}) => {
  const { id } = useParams();
  const [blockModalForDelegates, setBlockModalForDelegates] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const toggle = () => setBlockModalForDelegates((v) => !v);

  const handleRedirectReceipt = (participant: ParticipantsResponse) => {
    if (disabled) return;
    const coursePayment =
      participant.attributes.profile?.data?.attributes?.orders?.data?.find(
        (elem) =>
          elem.attributes.course?.data?.id === courseDetailQuery.data?.data?.id
      )?.attributes.payment;
    //se l'utente è delegato di sezione, può creare ricevute solo se il pagamento è completo
    // if (
    //   isDelegato(profile) &&
    //   coursePayment?.data.attributes.amount !==
    //     courseDetailQuery.data?.data.attributes.subscriptionAmount
    // )
    //   return toggle();

    navigate(
      `/corsi/dettaglio-corso/${id}/partecipanti/${
        participant?.id
      }/ricevute?productType=${ProductType.COURSE}&paymentId=${
        coursePayment?.data?.id
      }&orderId=${
        participant.attributes.profile?.data?.attributes?.orders?.data.find(
          (elem) =>
            elem.attributes.course?.data?.id ===
            courseDetailQuery.data?.data?.id
        )?.id
      }`
    );
  };

  const [initialNote] = selectedParticipants.map(
    (elem) => elem.attributes?.note
  );

  return (
    <>
      {match(participantsQuery)
        .with({ status: 'success' }, (participantsQuery) => {
          const { data: participantsData } = participantsQuery;

          return (
            <div className='-my-2 overflow-x-auto'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='ring-1 border ring-black ring-opacity-5'>
                  <table className='min-w-full table-fixed divide-y divide-gray-300'>
                    <TableHeader
                      columnsNames={tableElements}
                      checked={checked}
                      onCheckAll={onCheckAll}
                    />
                    <VoteModal
                      isOpen={isAssigningVotes}
                      toggle={toggleVotesModal}
                      partecipantName={`${selectedParticipants.map(
                        (elem) =>
                          ` ${elem.attributes?.profile?.data?.attributes?.name} ${elem.attributes?.profile?.data?.attributes?.surname}`
                      )}`}
                      onConfirm={(vote, cumLaude = false) =>
                        onUpdateParticipant({ vote, cumLaude })
                      }
                      course={courseDetailQuery.data?.data.attributes}
                    />
                    <NoteModal
                      isOpen={isAssigningNotes}
                      toggle={toggleNotesModal}
                      partecipantName={`${selectedParticipants.map(
                        (elem) =>
                          ` ${elem.attributes?.profile?.data?.attributes?.name} ${elem.attributes?.profile?.data?.attributes?.surname}`
                      )}`}
                      course={courseDetailQuery.data?.data.attributes}
                      onConfirm={(note) => onUpdateParticipant({ note })}
                      initialNote={initialNote}
                    />
                    <CancelParticipationsModal
                      isOpen={isCancelingParticipations}
                      isLoading={isLoading}
                      toggle={toggleCancelingParticipations}
                      partecipantName={`${selectedParticipants.map(
                        (elem) =>
                          ` ${elem.attributes?.profile?.data?.attributes?.name} ${elem.attributes?.profile?.data?.attributes?.surname}`
                      )}`}
                      onConfirm={(status) => onUpdateParticipant({ status })}
                    />
                    <RenewParticipationsModal
                      isOpen={isRenewingParticipations}
                      isLoading={isLoading}
                      toggle={toggleRenewingParticipations}
                      onConfirm={(participants) =>
                        bulkUpdateParticipants(participants)
                      }
                      partecipantName={`${selectedParticipants.map(
                        (elem) =>
                          ` ${elem.attributes?.profile?.data?.attributes?.name} ${elem.attributes?.profile?.data?.attributes?.surname}`
                      )}`}
                      selectedParticipants={selectedParticipants}
                      courseDetailQuery={courseDetailQuery}
                    />
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {participantsData?.data?.map((participant) => {
                        const subscriptionAmount = Math.ceil(
                          Number(
                            participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                              (elem) =>
                                elem?.attributes?.productType ===
                                  ProductType.COURSE &&
                                elem?.attributes?.course?.data?.id ===
                                  courseDetailQuery.data?.data?.id
                            )?.attributes.payment?.data?.attributes?.amount
                          )
                        );
                        return (
                          <tr
                            key={participant?.id}
                            className={
                              selectedParticipants
                                .filter((elem) => elem.multiple)
                                .find(
                                  (_participant) =>
                                    participant?.id === _participant?.id
                                )
                                ? 'bg-sand-50'
                                : 'bg-white'
                            }
                          >
                            <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                              {selectedParticipants
                                .filter((elem) => elem.multiple)
                                .find(
                                  (_participant) =>
                                    participant?.id === _participant?.id
                                ) && (
                                <div className='absolute inset-y-0 left-0 w-0.5 bg-primary' />
                              )}
                              <Checkbox
                                value={participant?.id}
                                checked={
                                  !!selectedParticipants
                                    .filter((elem) => elem.multiple)
                                    .find(
                                      (_participant) =>
                                        participant?.id === _participant?.id
                                    )
                                }
                                onChange={(e) =>
                                  setSelectedParticipants(
                                    e.target.checked
                                      ? [
                                          ...selectedParticipants,
                                          { ...participant, multiple: true },
                                        ]
                                      : selectedParticipants.filter(
                                          (p) => p?.id !== participant?.id
                                        )
                                  )
                                }
                              />
                            </td>
                            <td
                              className={classNames(
                                'whitespace-nowrap py-4 pr-3 text-sm',
                                selectedParticipants
                                  .filter((elem) => elem.multiple)
                                  .find(
                                    (_participant) =>
                                      participant?.id === _participant?.id
                                  )
                                  ? 'text-primary'
                                  : 'text-gray-600'
                              )}
                            >
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  participant.attributes.profile?.data
                                    ?.attributes?.badge?.data?.attributes
                                    ?.badgeNumber || '-'
                                }
                              />
                            </td>
                            <td
                              className={classNames(
                                'flex text-left px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-0 min-h-[65px]',
                                selectedParticipants.find(
                                  (_p) => _p.id === participant.id
                                )
                                  ? 'bg-sand-50'
                                  : 'bg-white'
                              )}
                            >
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                className='my-auto text-left'
                                autoEscape={true}
                                textToHighlight={`${participant.attributes.profile?.data?.attributes?.surname}`}
                              />
                            </td>
                            <td
                              className={classNames(
                                'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-[120px]',
                                selectedParticipants.find(
                                  (_p) => _p.id === participant.id
                                )
                                  ? 'bg-sand-50'
                                  : 'bg-white'
                              )}
                            >
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={`${participant.attributes.profile?.data?.attributes?.name}`}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  participant.attributes.profile?.data
                                    ?.attributes?.user?.data?.attributes
                                    ?.email || '-'
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  participant.attributes.profile?.data?.attributes?.mobilePhone?.replace(
                                    '+39',
                                    ''
                                  ) || '-'
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  participant.attributes.profile?.data
                                    ?.attributes?.profileCategory?.data
                                    ?.attributes?.title || '-'
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  participant.attributes.profile?.data
                                    ?.attributes?.section?.data?.attributes
                                    ?.name
                                    ? participant.attributes.profile?.data
                                        ?.attributes?.section?.data?.attributes
                                        ?.name + ''
                                    : '-'
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <IconButton
                                redTxt
                                disabled={
                                  !!participant.attributes.evaluation ||
                                  disabled
                                }
                                onClick={() => {
                                  toggleVotesModal();
                                  setSelectedParticipants([participant]);
                                }}
                              >
                                <MedalIcon
                                  fill={
                                    participant.attributes.evaluation
                                      ? '#830136'
                                      : undefined
                                  }
                                  disabled={!!participant.attributes.evaluation}
                                />
                              </IconButton>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {courseDetailQuery.data?.data.attributes.level ===
                              Level.PRIMO_LIVELLO ? (
                                '-'
                              ) : participant.attributes.evaluation ? (
                                <>
                                  <span className='text-gray-800 font-IBM font-medium text-lg'>
                                    <Highlighter
                                      highlightClassName='p-1 rounded-md'
                                      searchWords={searchWords}
                                      autoEscape={true}
                                      textToHighlight={`${
                                        participant.attributes.evaluation
                                      }${
                                        participant.attributes.cumLaude
                                          ? 'L'
                                          : ''
                                      }`}
                                    />
                                  </span>
                                  {` / 30`}
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={formatCurrency(
                                  subscriptionAmount
                                )}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: getCouponTotalHTML(
                                    participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                                      (elem) =>
                                        elem?.attributes?.course?.data?.id ===
                                        courseDetailQuery.data?.data.id
                                    )?.attributes?.coupon?.data
                                  ),
                                }}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={formatCurrency(
                                  participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                    (elem) =>
                                      elem?.attributes.productType ===
                                      ProductType.SUBSCRIPTION
                                  )?.attributes.payment?.data.attributes.amount
                                )}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <StatusPaymentModal
                                participant={participant}
                                onSubmit={(data) => onUpdatePayment(data)}
                                entity='course'
                                disabled={disabled}
                                isLoading={isLoading}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={
                                  participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                    (elem) =>
                                      elem.attributes.course?.data?.id ===
                                      courseDetailQuery.data?.data?.id
                                  )?.attributes.payment?.data?.attributes
                                    .method || '-'
                                }
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <Highlighter
                                highlightClassName='p-1 rounded-md'
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={(() => {
                                  const dateValue =
                                    participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                      (elem) =>
                                        elem.attributes.course?.data?.id ===
                                        courseDetailQuery.data?.data?.id
                                    )?.attributes.payment?.data?.attributes
                                      .date;

                                  return dateValue
                                    ? moment(dateValue).format('DD/MM/YYYY')
                                    : '-';
                                })()}
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-not-allowed'>
                              {moment(participant.attributes.createdAt).format(
                                'DD/MM/YYYY'
                              )}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-not-allowed'>
                              {getParticipantStatus(
                                participant.attributes.status
                              )}
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                (elem) =>
                                  elem.attributes.course?.data?.id ===
                                  courseDetailQuery.data?.data?.id
                              )?.attributes.payment?.data?.attributes?.receipt
                                ?.data?.attributes?.code &&
                                moment(
                                  participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                    (elem) =>
                                      elem.attributes.course?.data?.id ===
                                      courseDetailQuery.data?.data?.id
                                  )?.attributes.payment?.data?.attributes
                                    ?.receipt?.data?.attributes?.date
                                ).format('DD/MM/YYYY')}
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              {
                                participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                  (elem) =>
                                    elem.attributes.course?.data?.id ===
                                    courseDetailQuery.data?.data?.id
                                )?.attributes.payment?.data?.attributes?.receipt
                                  ?.data?.attributes?.code
                              }
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                              {/*È possibile creare ricevute solo se non sono già state create prima*/}
                              {!participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                (elem) =>
                                  elem.attributes.course?.data?.id ===
                                  courseDetailQuery.data?.data?.id
                              )?.attributes.payment?.data?.attributes.receipt
                                ?.data &&
                                participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                  (elem) =>
                                    elem.attributes.course?.data?.id ===
                                    courseDetailQuery.data?.data?.id
                                )?.attributes.payment?.data?.attributes
                                  .method !== PaymentMethod.PAYPAL &&
                                ALLOWABLE_PAYMENTS_STATES.includes(
                                  participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                    (elem) =>
                                      elem.attributes.course?.data?.id ===
                                      courseDetailQuery.data?.data?.id
                                  )?.attributes.payment?.data?.attributes
                                    .status!
                                ) && (
                                  <button
                                    onClick={() =>
                                      handleRedirectReceipt(participant)
                                    }
                                    disabled={disabled}
                                  >
                                    <Underline label='Crea ricevuta' />
                                  </button>
                                )}
                              {participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                (elem) =>
                                  Number(elem.attributes.course?.data?.id) ===
                                  Number(courseDetailQuery.data?.data?.id)
                              )?.attributes.payment?.data?.attributes.receipt
                                ?.data?.id && (
                                <button
                                  onClick={() =>
                                    onDownloadReceipt([
                                      participant.attributes.profile?.data?.attributes?.orders?.data?.find(
                                        (elem) =>
                                          elem.attributes.course?.data?.id ===
                                          courseDetailQuery.data?.data?.id
                                      )?.attributes.payment?.data?.attributes
                                        .receipt?.data?.id,
                                    ])
                                  }
                                  disabled={disabled}
                                >
                                  <Underline label='Scarica ricevuta' />
                                </button>
                              )}
                              <ConfirmationModal
                                isOpen={blockModalForDelegates}
                                onClose={toggle}
                                onConfirm={toggle}
                                title='ATTENZIONE'
                                subtitle='Il pagamento di questo corso non è stato completato. Per creare una ricevuta rivolgersi ad un segretario.'
                              />
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                              <button
                                onClick={() => {
                                  toggleNotesModal();
                                  setSelectedParticipants([participant]);
                                }}
                                disabled={disabled}
                              >
                                <Underline
                                  label={
                                    participant.attributes.note
                                      ? 'Vedi nota'
                                      : 'Scrivi nota'
                                  }
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })
        .with({ status: 'idle' }, { status: 'loading' }, () => {
          return <p>Caricamento partecipanti...</p>;
        })
        .with({ status: 'error' }, () => {
          return <p>Errore caricamento partecipanti...</p>;
        })
        .exhaustive()}
    </>
  );
};

export const getParticipantStatus = (status?: ParticipantStatus) => {
  switch (status) {
    case ParticipantStatus.PENDING:
      return <Badge yellow text='In attesa' />;
    case ParticipantStatus.CONFIRMED:
      return <Badge green text='Confermato' />;
    case ParticipantStatus.CANCELED:
      return <Badge red text='Annullato' />;
    case ParticipantStatus.NOT_CONFIRMED:
      return <Badge gray text='Non iscritto' />;
    case ParticipantStatus.WAITING_LIST:
      return <Badge teal text={`Lista d'attesa`} />;
    default:
      return <span>-</span>;
  }
};

export default SelectPartecipants;

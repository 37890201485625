import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const courseValidator = Yup.object().shape({
  title: Yup.string().required(ValidationErrorMessages.REQUIRED),
  subtitle: Yup.string()
    .max(250, 'Limite massimo di caratteri raggiunto')
    .required(ValidationErrorMessages.REQUIRED),
  minProfileCategorySub: Yup.number().required(
    ValidationErrorMessages.REQUIRED
  ),
  section: Yup.number().required(ValidationErrorMessages.REQUIRED),
  level: Yup.string().required(ValidationErrorMessages.REQUIRED),
  shortDescription: Yup.string().required(ValidationErrorMessages.REQUIRED),
  startDate: Yup.date()
    .required(ValidationErrorMessages.REQUIRED)
    .typeError(ValidationErrorMessages.REQUIRED),
  endDate: Yup.date().when('startDate', {
    is: (startDate: any) => !!startDate,
    then: Yup.date()
      .min(
        Yup.ref('startDate'),
        'La data di fine deve essere successiva alla data di inizio!'
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.date()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
  lastRegistrationDate: Yup.date().when(['startDate', 'endDate'], {
    is: (startDate: string | null, endDate: string | null) =>
      !!startDate && !!endDate,
    then: Yup.date()
      .min(
        Yup.ref('startDate'),
        "L'ultima data ammessa per l'iscrizione deve essere successiva alla data di inizio!"
      )
      .max(
        Yup.ref('endDate'),
        "L'ultima data ammessa per l'iscrizione deve essere precedente alla data di fine!"
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.date()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
  mailDate: Yup.date()
    .required(ValidationErrorMessages.REQUIRED)
    .typeError(ValidationErrorMessages.REQUIRED),
  firstReminderDate: Yup.date().when('mailDate', {
    is: (mailDate: any) => !!mailDate,
    then: Yup.date()
      .min(
        Yup.ref('mailDate'),
        'La data del primo reminder deve essere successiva alla data della mail!'
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.date()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
  secondReminderDate: Yup.date().when('firstReminderDate', {
    is: (firstReminderDate: any) => !!firstReminderDate,
    then: Yup.date()
      .min(
        Yup.ref('firstReminderDate'),
        'La data del secondo reminder deve essere successiva alla data del primo reminder!'
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.date()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
  location: Yup.number().required(ValidationErrorMessages.REQUIRED),
  // address: Yup.string().required(ValidationErrorMessages.REQUIRED),
  // city: Yup.string().required(ValidationErrorMessages.REQUIRED),
  // nation: Yup.string().required(ValidationErrorMessages.REQUIRED),
  subscriptionAmount: Yup.number()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED)
    .min(0, ValidationErrorMessages.POSITIVE),
  minParticipants: Yup.number()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED)
    .min(1, ValidationErrorMessages.NOT_ZERO),
  maxParticipants: Yup.number().when('minParticipants', {
    is: (minParticipants: any) => !!minParticipants,
    then: Yup.number()
      .min(
        Yup.ref('minParticipants'),
        '"Il mumero massimo di partecipanti non può essere inferiore al numero minimo"!'
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.number()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
});

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TitlePage from '../../components/TitlePage';
import PageNotFound from '../404';
import ListaStampe from './Pages/listaStampe';
import StampaDiploma from './Pages/stampaDiploma';
import StampaEtichetta from './Pages/stampaEtichetta';

const Stampa = () => {
  const SIDEBAR_ITEMS = [
    { href: "/stampa", label: "Stampa tessere e lettere" },
    { href: '/stampa/diploma', label: 'Stampa diploma' },
    { href: "/stampa/etichetta", label: "Stampa etichetta" },
  ];

  return (
    <div className='pt-7 pb-10 lg:py-12 px-4 lg:px-10 w-full grid grid-cols-12 gap-8'>
      <TitlePage title='Stampa' />
      <Sidebar items={SIDEBAR_ITEMS} />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <Routes>
          <Route index element={<ListaStampe />} />
          <Route path='diploma' element={<StampaDiploma />} />
          <Route path='etichetta' element={<StampaEtichetta />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default Stampa;

import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import IconButton from '../Buttons/IconButton';

type UploadedFile = File[] | StrapiFile;

type StrapiFile = {
  data: {
    id: number;
    attributes: {
      name: string;
      alternativeText: any;
      caption: any;
      width: any;
      height: any;
      formats: any;
      hash: string;
      ext: string;
      mime: string;
      size: number;
      url: string;
      previewUrl: any;
      provider: string;
      provider_metadata: any;
      createdAt: string;
      updatedAt: string;
    };
  };
};

interface UploadedFilePreviewProps {
  file: UploadedFile;
  onRemove?: (fileId?: number) => void;
}
const UploadedFilePreview: React.FC<UploadedFilePreviewProps> = ({
  file,
  onRemove = () => {},
}) => {
  if (Array.isArray(file))
    return (
      <div className='flex items-center justify-between gap-3'>
        <p className='uppercase text-gray-500 font-medium'>{file[0].name}</p>
        <IconButton redTxt onClick={() => onRemove(undefined)}>
          <XIcon className='w-5 h-5' />
        </IconButton>
      </div>
    );

  if (!file.data) return null;

  return (
    <div className='flex items-center justify-between gap-3'>
      <a
        href={file?.data?.attributes?.url}
        className='uppercase text-gray-500 font-medium hover:text-primary'
        target='_blank'
        rel='noopener noreferrer'
      >
        {file.data.attributes.name}
      </a>
      <IconButton redTxt onClick={() => onRemove(file.data.id)}>
        <XIcon className='w-5 h-5' />
      </IconButton>
    </div>
  );
};

export default UploadedFilePreview;

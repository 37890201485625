import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BackButton from './Buttons/BackButton';
import PrimaryButton from './Buttons/PrimaryButton';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import H1Styled from './Typography/H1Styled';
import { useAuth } from '../contexts/Auth';
import { getStatusTitle } from '../libs/utils/course';

interface HeaderTitleProps {
  category: string;
  title?: string;
  preview?: boolean;
  modal?: any;
  primaryButtonText?: string;
  primaryButtonOnClick?: any;
  previewButtonOnClick?: any;
  approvalRequest?: boolean;
  showInOnavit?: boolean;
}
const HeaderTitleTemplate: React.FC<HeaderTitleProps> = ({
  category,
  title,
  preview,
  modal,
  primaryButtonText,
  primaryButtonOnClick,
  previewButtonOnClick,
}) => {
  const { id } = useParams();
  const { watch, handleSubmit, register } = useFormContext();
  const [{ profile, token }] = useAuth();

  return (
    <>
      <BackButton />
      <div className='flex flex-col  md:flex-row mt-2'>
        <div className='w-full md:w-2/3 text-center md:text-left'>
          <H1Styled>
            <span className='font-medium'>{category}</span>: {title}
          </H1Styled>
        </div>
        <div className='flex flex-col items-center md:items-end gap-4 w-full md:w-1/3'>
          <div className='flex gap-3'>
            <PrimaryButton onClick={handleSubmit(primaryButtonOnClick)}>
              {'Salva'}
            </PrimaryButton>
          </div>

          {modal && (
            <div className='flex items-center gap-2'>
              <p className='text-gray-500 '>
                Stato {category}:{' '}
                <span className='uppercase text-gray-800 font-bold'>
                  {watch('status') ? getStatusTitle(watch('status')) : ' - '}
                </span>
              </p>
              {modal}
            </div>
          )}

          <SimpleCheckboxPresentational
            label='Visibile su onav.it'
            id='visibleForOnavIT'
            name='visibleForOnavIT'
            describedby='visibleForOnavIT'
            register={register}
          />

          <SimpleCheckboxPresentational
            label='Modificabile da Delegato'
            id='canDelegateEdit'
            name='canDelegateEdit'
            describedby='canDelegateEdit'
            register={register}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderTitleTemplate;

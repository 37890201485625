import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BANK_TRANSFER_MAX_DAYS, IBAN } from '../../constants/course';
import { useAuth } from '../../contexts/Auth';
import { isDelegato } from '../../libs/utils/auth';
import AlertMessage from '../AlertMessage';
import InputPresentational from './InputPresentational';
import StaticInputPresentational from './StaticInputPresentational';
import classNames from '../../libs/utils/classNames';
import { formatCurrency } from '../../libs/utils/helpers';
import { useLocation } from 'react-router-dom';
interface RadioGroupPaymentCourseProps {
  isCourseCanceled?: boolean;
}
const RadioGroupPaymentCourse: React.FC<RadioGroupPaymentCourseProps> = ({
  isCourseCanceled,
}) => {
  const { register, watch, setValue } = useFormContext();
  const [{ profile, settings }] = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const { pathname } = useLocation();

  const [initialSubscriptionAmount, subscriptionQuote, subscriptionAmount] =
    watch([
      'initialSubscriptionAmount',
      'headquarterBalance.administrations.estimatedPriceUnit',
      'subscriptionAmount',
    ]);

  const TransferSum = subscriptionAmount
    ? (subscriptionAmount || 0) + (subscriptionQuote || 0)
    : (initialSubscriptionAmount || 0) + (subscriptionQuote || 0);

  const TransferMarkup = TransferSum * (settings?.bankTransferFee || 0.2);
  const bankTransferTotal = Math.ceil(TransferSum + TransferMarkup);
  const transferTotal = subscriptionAmount
    ? Math.ceil((subscriptionAmount || 0) + (subscriptionQuote || 0))
    : Math.ceil((initialSubscriptionAmount || 0) + (subscriptionQuote || 0));

  return (
    <fieldset className='mt-4'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-6'>
        <div key='payment1' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='transferPayment'
              aria-describedby='description`'
              type='checkbox'
              disabled={isCourseCanceled}
              checked={watch('transferPayment')}
              {...register('transferPayment')}
              onChange={() => {
                if (isDelegato(profile) && pathname.includes('corsi')) {
                  setShowAlert(true);
                  setValue('transferPayment', watch('transferPayment'));
                  return;
                }
                setValue('transferPayment', !watch('transferPayment'));
              }}
              className={classNames(
                'focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded cursor-pointer disabled:cursor-not-allowed'
              )}
              defaultChecked={true}
            />
          </div>
          <div className='ml-3 w-full'>
            <label
              htmlFor='transferPayment'
              className='font-medium text-gray-700 cursor-pointer'
            >
              Pagamento con bonifico
            </label>
            {watch('transferPayment') && (
              <div className='bg-neutral-100 px-3 py-4 mt-4 rounded-md space-y-4 -ml-7 '>
                <InputPresentational
                  id='iban'
                  label='Codice iban'
                  name='iban'
                  type='text'
                  register={register}
                  disabled={isCourseCanceled}
                  defaultValue={IBAN}
                />
                <div>
                  <InputPresentational
                    id='bankTransferMaxDays'
                    label='Tempo di ricezione pagamento con bonifico (giorni)'
                    name='bankTransferMaxDays'
                    type='number'
                    value={BANK_TRANSFER_MAX_DAYS}
                    // disabled={isCourseCanceled}
                    disabled={true}
                    register={register}
                  />
                  <p className='mt-1 mb-0 italic text-gray-400 text-xs'>
                    Tempo entro il quale ad un utente viene annullata la
                    prenotazione nel caso in cui NON VIENE registrato il
                    pagamento
                  </p>
                </div>

                <div>
                  <StaticInputPresentational
                    big
                    label='Prezzo unitario di vendita'
                    text={formatCurrency(bankTransferTotal)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div key='partialPayment' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='partialPayment'
              aria-describedby='description`'
              {...register('partialPayment')}
              type='checkbox'
              checked={watch('partialPayment')}
              className={classNames(
                'focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded cursor-pointer disabled:cursor-not-allowed'
              )}
              disabled={isCourseCanceled}
              onChange={() => {
                if (isDelegato(profile) && pathname.includes('corsi')) {
                  setShowAlert(true);
                  setValue('transferPayment', watch('transferPayment'));
                  return;
                }
                setValue('partialPayment', !watch('partialPayment'));
              }}
              defaultChecked={true}
            />
          </div>
          <div className='ml-3 w-full'>
            <label
              htmlFor='partialPayment'
              className='font-medium text-gray-700 cursor-pointer'
            >
              Pagamento rateale
            </label>
            {watch('partialPayment') && (
              <div className='bg-neutral-100 px-3 py-4 mt-4 rounded-md -ml-7'>
                <StaticInputPresentational
                  big
                  label='Prezzo unitario di vendita scontato'
                  text={`${formatCurrency(bankTransferTotal)} - ${
                    Number(settings?.bankTransferFee) * 100
                  }% = ${formatCurrency(transferTotal)}`}
                />
              </div>
            )}
          </div>
        </div>

        <div key='cardPayment' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='cardPayment'
              aria-describedby='description`'
              {...register('cardPayment')}
              type='checkbox'
              checked={watch('cardPayment')}
              onChange={() => {
                if (isDelegato(profile) && pathname.includes('corsi')) {
                  setShowAlert(true);
                  setValue('transferPayment', watch('transferPayment'));
                  return;
                }
                setValue('cardPayment', !watch('cardPayment'));
              }}
              className={classNames(
                'focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded cursor-pointer disabled:cursor-not-allowed'
              )}
              defaultChecked={true}
              disabled={isCourseCanceled}
            />
          </div>

          <div className='ml-3 w-full'>
            <label
              htmlFor='cardPayment'
              className='font-medium text-gray-700 cursor-pointer'
            >
              Pagamento con carte
            </label>
            {watch('cardPayment') && (
              <div className='bg-neutral-100 px-3 py-4 mt-4 rounded-md -ml-7'>
                <StaticInputPresentational
                  big
                  label='Prezzo unitario di vendita scontato'
                  text={`${formatCurrency(bankTransferTotal)} - ${
                    Number(settings?.bankTransferFee) * 100
                  }% = ${formatCurrency(transferTotal)}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showAlert && (
        <AlertMessage
          borderLeft
          noTitle
          text='Per modificare le modalità di pagamento contatta la segreteria nazionale'
        />
      )}
    </fieldset>
  );
};

export default RadioGroupPaymentCourse;

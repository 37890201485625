import axios from 'axios';

import { StrapiResponseList, StrapiResponse } from '../interfaces/commons';
import qs from 'qs';
import { Location } from '../interfaces/location';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

const create = async ({ data, token }: { data: any; token: string | null }) => {
  const response = await instance.post(`${REACT_APP_API_URL}/locations`, data, {
    headers: { authorization: `Bearer ${token}` },
  });

  return response;
};

const destroy = async ({ token, id }: { token: string | null; id: number }) => {
  const response = await instance.delete(
    `${REACT_APP_API_URL}/locations/${id}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

const find = async ({
  token,
  query,
}: {
  token: string | null;
  query: object;
}) => {
  const { data } = await axios.get<StrapiResponseList<Location>>(
    `${REACT_APP_API_URL}/locations?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const findOne = async ({
  token,
  query,
  id,
}: {
  token?: string | null;
  query?: object;
  id?: string | number | null;
}) => {
  const { data } = await axios.get<StrapiResponse<Location>>(
    `${REACT_APP_API_URL}/locations/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const locationsAPIs = {
  find,
  findOne,
  create,
  destroy,
};

export default locationsAPIs;

import { useParams } from 'react-router-dom';
import React from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import { match } from 'ts-pattern';
import IconLink from '../../../components/TextLink/IconLink';
import { UseQueryResult } from 'react-query';
import ReceiptRow from './ReceiptRow';
import { StrapiResponseList } from '../../../interfaces/commons';
import { ReceiptResponse } from '../../../interfaces/receipts';

interface TesoreriaTableProps {
  receiptsQuery: UseQueryResult<StrapiResponseList<ReceiptResponse>, unknown>;
  searchingWords?: string[];
  disabled?: boolean;
}

const TesoreriaTable: React.FC<TesoreriaTableProps> = ({
  receiptsQuery,
  searchingWords,
  disabled,
}) => {
  const tableElements = [
    'Nominativo',
    'Numero',
    'Data emissione',
    'Importo',
    'Coupon utilizzato',
    'Voci che visualizza il partecipante',
    'Causale modifica',
    'Tipologia',
    'Metodo di pagamento',
    'Sezione',
    'N tessera',
    'Codice fiscale',
    '',
    '',
  ];

  return (
    <>
      {match(receiptsQuery)
        .with({ status: 'success' }, ({ data: receiptsResponse }) => {
          return (
            <div className='-my-2 overflow-x-auto'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, idx) => (
                          <th
                            key={idx}
                            className='font-normal px-6 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {receiptsResponse.data?.map((receipt) => {
                        return (
                          <ReceiptRow
                            key={receipt.id}
                            receipt={receipt}
                            searchWords={searchingWords || []}
                            disabled={disabled}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })
        .with({ status: 'error' }, () => {
          return <p>Erore caricamento ricevute...</p>;
        })
        .with({ status: 'idle' }, () => {
          return <p>IDLE</p>;
        })
        .with({ status: 'loading' }, { status: 'idle' }, () => {
          return <p>Caricamento ricevute...</p>;
        })

        .exhaustive()}
    </>
  );
};

export default TesoreriaTable;

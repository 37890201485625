import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import IconButton from '../Buttons/IconButton';

interface DocumentUploadPrevProps {
  documentName: string;
  fieldName?: string;
  onRemove?: (fileId?: number) => void;
  fileId?: number | undefined;
}
const DocumentUploadPrev: React.FC<DocumentUploadPrevProps> = ({
  documentName,
  fieldName = '',
  onRemove = () => {},
  fileId,
}) => {
  return documentName ? (
    <div className='flex items-center justify-between gap-3'>
      <p className='uppercase text-gray-500 font-medium'>{documentName}</p>
      <IconButton redTxt onClick={() => onRemove(fileId)}>
        <XIcon className='w-5 h-5' />
      </IconButton>
    </div>
  ) : null;
};

export default DocumentUploadPrev;

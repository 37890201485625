import { PencilIcon } from '@heroicons/react/outline';
import React from 'react';
import { UseQueryResult } from 'react-query';
import DeleteModal from '../../../components/Modals/DeleteModal';
import IconLink from '../../../components/TextLink/IconLink';
import { StrapiResponseList } from '../../../interfaces/commons';
import { Location } from '../../../interfaces/location';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import DeleteLocationModal from '../../../components/Modals/DeleteLocationModal';
import { useNavigate } from 'react-router-dom';

interface LocationTableProps {
  query: UseQueryResult<StrapiResponseList<Location>>;
}
const LocationTable = ({ query }: LocationTableProps) => {
  const navigate = useNavigate();

  const tableElements = ['Location', 'Indirizzo', 'Città', ''];

  return (
    <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8'>
        <div className='overflow-hidden border ring-1 ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='text-sm text-gray-500 uppercase bg-gray-50'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='px-6 py-3 font-normal tracking-wide text-left'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {query.data.data.map((location) => (
                        <tr
                          key={location.id}
                          className='cursor-pointer hover:bg-neutral-100'
                          onClick={() => {
                            navigate(`${location.id}`);
                          }}
                        >
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {location.attributes.name}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {location.attributes.address}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {location.attributes.city}
                          </td>
                          <div>
                            <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                              <IconLink orangeTxt href={`${location.id}`}>
                                <PencilIcon className='w-6 h-6' />
                              </IconLink>
                            </td>
                            <td
                              className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteLocationModal
                                locationId={location.id}
                                buttonType='icon'
                                titleLocation={location.attributes.name}
                              />
                            </td>
                          </div>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='my-20 text-center text-gray-500'>
                    Non sono presenti location
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default LocationTable;

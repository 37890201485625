import React from 'react';
import CurrencyInputField from 'react-currency-input-field';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';

interface Props {
  control?: Control<FieldValues>;
  name: string;
  className?: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: FieldError;
  required?: boolean;
  placeholder?: string;
  id?: string;
}

const ControlledCurrencyInput: React.FC<Props> = (props) => {
  const {
    control,
    name,
    error,
    disabled,
    required,
    placeholder,
    defaultValue,
    label,
    id,
  } = props;
  return (
    <div>
      <label
        htmlFor={id}
        className='flex justify-between text-sm text-gray-500 '
      >
        <div>{`${label}${required ? ' *' : ''}`} </div>
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <CurrencyInputField
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            decimalsLimit={2}
            onValueChange={onChange}
            disabled={disabled}
            required={required}
            intlConfig={{ locale: 'it-IT', currency: 'EUR' }}
            allowNegativeValue={false}
            className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
              error?.message
                ? 'border-red-100 focus:border-red-100 border-[1px]'
                : ''
            }`}
          />
        )}
      />
      {error && error.message && (
        <p className='mt-1 absolute text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export default ControlledCurrencyInput;

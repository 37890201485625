import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TesoreriaCreaRicevuta from './Pages/crea-ricevuta';
import TesoreriaDettaglioRicevuta from './Pages/dettaglio-ricevuta';
import ListaRicevute from './Pages/lista-ricevute';
import TesoreriaUscite from './Pages/uscite';

const Tesoreria = () => {
  const SIDEBAR_ITEMS = [
    { href: '/tesoreria/entrate', label: 'Tutte le ricevute (entrate)' },
    // { href: '/tesoreria/uscite', label: 'Tutte le uscite' },
    { href: '/tesoreria/crea-ricevuta?mode=FREE', label: 'Crea ricevuta' },
  ];

  return (
    <div className='pt-7 pb-10 lg:py-12 px-4 lg:px-10 w-full grid grid-cols-12 gap-8'>
      <Sidebar items={SIDEBAR_ITEMS} />
      <Routes>
        <Route path='entrate' element={<ListaRicevute />} />
        <Route path='uscite' element={<TesoreriaUscite />} />
        <Route path='crea-ricevuta' element={<TesoreriaCreaRicevuta />} />
        <Route
          path='dettaglio-ricevuta/:receiptId'
          element={<TesoreriaDettaglioRicevuta />}
        />
      </Routes>
    </div>
  );
};

export default Tesoreria;

import React, { useEffect } from 'react';
import DeleteCouponModal from '../../../components/Modals/DeleteCouponModal';
import BadgeBoolean from '../../../components/BadgeBoolean';
import { UseQueryResult } from 'react-query';
import { CouponListResponse } from '../../../interfaces/coupon';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import IconLink from '../../../components/TextLink/IconLink';
import { PencilIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';

interface CouponTableProps {
  query: UseQueryResult<CouponListResponse>;
}
const CouponTable: React.FC<CouponTableProps> = ({ query }) => {
  const tableElements = [
    'ID',
    'Titolo',
    'Importo sconto',
    'Intervallo validità',
    'Valido per',
    'Singolo / Nominale',
    'Attivo',
    '',
  ];

  const navigate = useNavigate();

  // if query status is error automatically refresh page
  if (query.status === 'error') {
    window.location.reload();
  }

  return (
    <div className='mx-4 my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8'>
        <div className='overflow-hidden border ring-1 ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='text-sm text-gray-500 uppercase bg-gray-50'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='px-6 py-3 font-normal tracking-wide text-left'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {query.data.data.map((coupon) => (
                        <tr
                          key={coupon.id}
                          className='cursor-pointer hover:bg-neutral-100'
                          onClick={() => {
                            navigate(`dettaglio-coupon/${coupon.id}`);
                          }}
                        >
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {coupon.id}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {coupon.attributes.title}
                          </td>
                          <td className='px-6 py-4 text-sm font-bold text-gray-500 whitespace-nowrap'>
                            {coupon.attributes.price}€
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            Dal {formatDate(coupon.attributes.startValidity, 'DD/MM/YYYY')}{' '}
                            <br /> al{' '}
                            {formatDate(coupon.attributes.endValidity, 'DD/MM/YYYY')}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {coupon.attributes.validFor === 'course'
                              ? 'Corsi'
                              : coupon.attributes.validFor === 'event'
                              ? 'Eventi'
                              : coupon.attributes.validFor === 'all'
                              ? 'Tutto il sito'
                              : null}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            <BadgeBoolean active={coupon.attributes.single} />
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            <BadgeBoolean active={coupon.attributes.active} />
                          </td>

                          <div>
                            <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                              <IconLink
                                orangeTxt
                                href={`dettaglio-coupon/${coupon.id}`}
                              >
                                <PencilIcon className='w-6 h-6' />
                              </IconLink>
                            </td>
                            <td
                              className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap '
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteCouponModal
                                couponId={coupon.id}
                                titleCoupon={coupon.attributes.title}
                                buttonType='icon'
                              />
                            </td>
                          </div>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='my-20 text-center text-gray-500'>
                    Non sono presenti coupon
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default CouponTable;

import React from 'react';
import { courseStatus } from '../constants/course';
import { CourseStatus } from '../interfaces/commons';
import classNames from '../libs/utils/classNames';

interface BadgeStatusProps {
  status: string;
}
const BadgeStatus: React.FC<BadgeStatusProps> = ({ status }) => {
  const selectedStatus = courseStatus.find(({ id }) => id === status);

  return selectedStatus ? (
    <span
      className={classNames(
        'inline-flex items-center justify-center px-5 py-1.5 rounded-full text-sm font-normal',
        status === CourseStatus.PUBLISHED ? 'bg-green-600 text-white' : '',
        status === CourseStatus.NOT_APPROVED ? 'bg-red-700 text-white' : '',
        status === CourseStatus.FINAL_BALANCE_DELEGATION
          ? 'bg-teal-600 text-white'
          : '',
        status === CourseStatus.FINAL_BALANCE_NATIONAL
          ? 'bg-teal-600 text-white'
          : '',
        status === CourseStatus.TO_APPROVE ? 'bg-orange-500 text-white' : '',
        status === CourseStatus.DRAFT ? 'bg-yellow-500 text-white' : '',
        status === CourseStatus.CANCELED ? 'bg-red-700 text-white' : ''
        // status === Status.CLOSED ? 'bg-gray-500 text-white' : '' -> non presente in strapi
        // status === Status.SENT ? 'bg-gray-500 text-white' : '' -> non presente in strapi
      )}
    >
      {selectedStatus?.title}
    </span>
  ) : (
    <></>
  );
};

export default BadgeStatus;

import { Fragment, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/outline';

import { useAuth } from '../../contexts/Auth';

import { deleteAnnouncment } from '../../api/announcments';

import PrimaryButton from '../Buttons/PrimaryButton';
import IconButton from '../Buttons/IconButton';
import Spinner from '../Layout/Loading/Spinner';

interface DeleteAnnouncmentModalProps {
  titleAnnouncment: string;
  buttonTxt?: string;
  buttonType: 'text' | 'icon';
  AnnouncmentId: number;
  allowDelete?: boolean;
}

const DeleteCouponModal: React.FC<DeleteAnnouncmentModalProps> = ({
  titleAnnouncment,
  buttonTxt = 'Elimina',
  buttonType,
  AnnouncmentId,
  allowDelete,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [{ token }] = useAuth();
  const [open, setOpen] = useState(false);

  const { mutateAsync: deleteAnnouncmentMutation } = useMutation({
    mutationFn: () => {
      setLoading(true);
      return deleteAnnouncment(AnnouncmentId, token);
    },
    onSuccess: (data) => {
      if (!data) throw new Error();
      setOpen(false);
      toast.success('Comunicato eliminato con successo');
      setLoading(false);
      queryClient.invalidateQueries(['announcments']);
    },
    onError: () => {
      setLoading(false);
      toast.error("Qualcosa e' andato storto!");
    },
  });

  return (
    <>
      {buttonType === 'text' && (
        <PrimaryButton textSmall onClick={() => setOpen(true)}>
          <span className='font-medium'>{buttonTxt}</span>
        </PrimaryButton>
      )}

      {buttonType === 'icon' && (
        <IconButton
          redTxt={allowDelete}
          onClick={() => allowDelete && setOpen(true)}
        >
          <TrashIcon className='w-7 h-7' />
        </IconButton>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Eliminare coupon{' '}
                  <span className='font-medium'>{titleAnnouncment}</span>
                </h4>

                <div className='px-4 py-6 border-2 bg-sand-50 border-sand-100'>
                  <p className='text-gray-700'>
                    Sei sicuro di voler eliminare il comunicato{' '}
                    <span className='italic font-medium text-primary'>
                      {titleAnnouncment}
                    </span>
                    ?
                    <br />
                    <br />
                    L'azione sarà irreversibile.
                  </p>
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  {loading && <Spinner />}
                  <PrimaryButton textSmall onClick={() => setOpen(false)}>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton small onClick={deleteAnnouncmentMutation}>
                    Elimina
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteCouponModal;

import PrimaryButton from '../../components/Buttons/PrimaryButton';
import IntroSctTitle from '../../components/IntroSctTitle';
import ShowMore from '../../components/TextLink/ShowMore';
import TitlePage from '../../components/TitlePage';
import H3Styled from '../../components/Typography/H3Styled';
import CoursesToBeApproved from './Tables/CoursesToBeApproved';
import CoursesToReport from './Tables/CoursesToReport';
import EventsToReport from './Tables/EventsToReport';
import Print from './Tables/Print';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { profileInfoFromUser } from '../../api/profile';
import { useAuth } from '../../contexts/Auth';
import coursesAPIs, { listCourses } from '../../api/courses';
import { isDelegato } from '../../libs/utils/auth';
import { useDebounce } from 'use-debounce';
import { useSearchParams } from 'react-router-dom';
import { find } from '../../api/orders';
import Pagination from '../../components/Pagination';
import { ProductType } from '../../interfaces/orders';
import jsPDF from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import { useState } from 'react';
import { toast } from 'react-toastify';
import eventsAPIs from '../../api/events';
import StampaTable from '../Stampa/Tables/StampaTable';
import badgesToAPIs, { badgesToPdf, badgesToPrint } from '../../api/badgesAPIs';
import { BadgeToPrint } from '../../interfaces/badgeToPrint';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function Home() {
  const [searchParams] = useSearchParams();
  const [{ profile, token }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const queryClient = useQueryClient();

  const query = useQuery(['profile', profile?.id], () => {
    if (!profile?.id) return;
    return profileInfoFromUser(profile?.id, token);
  });

  const coursesToApproveQuery = useQuery(
    ['coursesToApproved', ...debounceSearchParams],
    () => listCourses(debounceSearchParams, token)
  );

  const coursesToReportQuery = useQuery(
    ['coursesToReport', ...debounceSearchParams],
    () =>
      coursesAPIs.find({
        token,
        query: {
          populate: ['section'],
          filters: {
            endDate: { $lt: new Date() },
            // $and: [
            //   {
            //     status: { $ne: CourseStatus.FINAL_BALANCE_DELEGATION },
            //   },
            //   {
            //     status: {
            //       $ne: CourseStatus.FINAL_BALANCE_NATIONAL,
            //     },
            //   },
            // ],
          },
          sort: { startDate: 'desc' },
        },
      })
  );

  const eventsToReportQuery = useQuery(
    ['eventsToReport', ...debounceSearchParams],
    () =>
      eventsAPIs.find({
        token,
        query: {
          populate: ['section'],
          filters: {
            startDate: { $lt: new Date() },
            // $and: [
            //   {
            //     status: { $ne: EventStatus.FINAL_BALANCE_DELEGATION },
            //   },
            //   {
            //     status: {
            //       $ne: EventStatus.FINAL_BALANCE_NATIONAL,
            //     },
            //   },
            // ],
          },
          sort: { startDate: 'desc' },
        },
      })
  );

  const badgesToPrintQuery = useQuery(
    ['badgesToPrint', ...debounceSearchParams],
    () =>
      badgesToPrint({
        token,
        query: {
          pagination: {
            page: 1,
            pageSize: 10,
          },
          filters: {
            $or: [
              {
                cardHistory: { printed: false },
              },
              {
                cardHistory: { letterPrinted: false },
              },
            ],
          },
          sort: { createdAt: 'DESC' },
        },
      }),
    {
      onSuccess: () => {},
    }
  );

  const [selectedBadges, setSelectedBadges] = useState<BadgeToPrint[]>([]);

  const { mutate: badgesToPDFMutation } = useMutation(
    'createBadges',
    badgesToPdf,
    {
      onSuccess: () => {
        toast.success('Tessere generate con successo');
        badgesToPrintQuery.refetch();
        setSelectedBadges([]);
      },
      onError: () => {
        toast.error('Ops... Qualcosa è andato storto');
      },
    }
  );

  const handleGeneratePdfBadges = () => {
    badgesToPDFMutation({
      body: [...selectedBadges],
      token,
    });
  };

  const { mutate: mutateLetters } = useMutation(
    'createLetters',
    badgesToAPIs.lettersToPdf,
    {
      onSuccess: () => {
        toast.success('Lettere generate con successo');
        queryClient.invalidateQueries(['badgesToPrint']);
        setSelectedBadges([]);
      },
      onError: () => {
        toast.error('Ops... Qualcosa è andato storto');
      },
    }
  );

  const handleGeneratePdfLetters = () => {
    mutateLetters({
      body: selectedBadges.map((elem) => elem.id),
      token,
    });
  };

  return (
    <div>
      <TitlePage title='Home Dashboard' />
      <IntroSctTitle>
        <span className='font-medium'>Benvenuto</span> {''}
        {query.data?.data.attributes?.surname}{' '}
        {query.data?.data.attributes?.name}
      </IntroSctTitle>

      <div className='w-full px-4 space-y-8 py-7 lg:py-12 lg:px-10'>
        {!isDelegato(profile) && (
          <div className='space-y-5'>
            <div className='flex flex-col items-start justify-start gap-4 sm:flex-row sm:items-center sm:justify-between'>
              <H3Styled>Corsi da approvare</H3Styled>
              <ShowMore href='/corsi' label='Vedi tutti i corsi' />
            </div>
            <CoursesToBeApproved query={coursesToApproveQuery} />
            {/* <Pagination
              pagination={coursesToApproveQuery.data?.meta.pagination}
            /> */}
          </div>
        )}

        <div className='space-y-5'>
          <div className='flex flex-col items-start justify-start gap-4 sm:flex-row sm:items-center sm:justify-between'>
            <H3Styled>Corsi da rendicontare</H3Styled>
            <ShowMore href='/corsi' label='Vedi tutti i corsi' />
          </div>
          {coursesToReportQuery.data?.data.length ? (
            <CoursesToReport query={coursesToReportQuery} />
          ) : (
            <p className='text-gray-700 text-sm font-IBM font-light'>
              Non sono presenti corsi da rendicontare
            </p>
          )}
          {/* <Pagination pagination={coursesToRetortQuery.data?.meta.pagination} /> */}
        </div>

        <div className='space-y-5'>
          <div className='flex flex-col items-start justify-start gap-4 sm:flex-row sm:items-center sm:justify-between'>
            <H3Styled>Eventi da rendicontare</H3Styled>
            <ShowMore href='/eventi' label='Vedi tutti gli eventi' />
          </div>
          {eventsToReportQuery?.data?.data?.length ? (
            <EventsToReport query={eventsToReportQuery} />
          ) : (
            <p className='text-gray-700 text-sm font-IBM font-light'>
              Non sono presenti eventi da rendicontare
            </p>
          )}
        </div>

        {!isDelegato(profile) && (
          <>
            <TitlePage title='Stampa' />
            <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
              <div className='space-y-5'>
                <div className='w-full flex justify-end'>
                  <div className='flex w-full flex-col items-start justify-start gap-4 sm:flex-row sm:items-center sm:justify-between'>
                    <H3Styled>Stampa</H3Styled>

                    <div className='flex items-center justify-between w-full gap-2 sm:justify-end sm:gap-6'>
                      <div className='flex items-center gap-3'>
                        <PrimaryButton small onClick={handleGeneratePdfLetters}>
                          stampa <span className='italic ml-0.5'>LETTERA</span>
                        </PrimaryButton>
                        <PrimaryButton small onClick={handleGeneratePdfBadges}>
                          stampa <span className='italic ml-0.5'>TESSERA</span>
                        </PrimaryButton>
                      </div>

                      <ShowMore href='/stampa' label='Vedi tutte' />
                    </div>
                  </div>
                </div>
                <StampaTable
                  query={badgesToPrintQuery}
                  setSelectedBadges={setSelectedBadges}
                  selectedBadges={selectedBadges}
                  applyForAll={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import { AuthAction, AuthActionType, AuthState } from './Auth';

const INITIAL_AUTH_STATE: AuthState = Object.freeze({
  appLoaded: false,
  isAuthenticated: false,
  token: null,
});

function authReducer(state: AuthState, action: AuthAction): AuthState {
  const { type, token = null, profile, settings } = action;
  switch (type) {
    case AuthActionType.Logout:
      return { ...INITIAL_AUTH_STATE, appLoaded: true };
    case AuthActionType.Login:
      return {
        ...state,
        isAuthenticated: true,
        appLoaded: true,
        token,
        profile,
        settings,
      };
    case AuthActionType.SetAppLoaded:
      return { ...state, appLoaded: true };
    case AuthActionType.SetProfile:
      return { ...state, isAuthenticated: true, appLoaded: true, profile };
    case AuthActionType.setSettings:
      return { ...state, isAuthenticated: true, appLoaded: true, settings };
    default:
      return { ...state };
  }
}

export { INITIAL_AUTH_STATE, authReducer };

export interface Badge {
  attributes: {
    badgeNumber: string;
    status: BadgeStatus;
    createdAt: string;
    updatedAt: string;
  };
  id: number;
}

export enum BadgeStatus{
  VALID="VALID",
  INVALID="INVALID",
  PENDING="PENDING"
}
import TitlePage from '../../../components/TitlePage';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import PrimaryLinkFull from '../../../components/TextLink/PrimaryLinkFull';
import FormNewLesson from '../../../components/FormNewLesson';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButtonFull from '../../../components/Buttons/PrimaryButtonFull';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createCourseLesson } from '../../../api/lesson';
import { useAuth } from '../../../contexts/Auth';
import { FormProvider, useForm } from 'react-hook-form';
import { CourseLessonRequest } from '../../../interfaces/lesson';
import { toast } from 'react-toastify';
import { emptyLesson } from '../../../constants/lesson';
import { IOption } from '../../../components/Form/Multiselect';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { lessonValidator } from '../../../validators/lesson';
import { getCourseDetail } from '../../../api/courses';
import moment from 'moment';
import { useState } from 'react';

interface AggiungiLezioneNuovoCorsoProps {
  order?: number;
}

const AggiungiLezioneNuovoCorso: React.FC<AggiungiLezioneNuovoCorsoProps> = ({
  order = 1,
}) => {
  const [{ token }] = useAuth();
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const newLessonMethods = useForm<CourseLessonRequest>({
    defaultValues: {
      ...emptyLesson,
      order: order,
      course: Number(courseId),
      date: moment().format('YYYY-MM-DDT20:30'),
    },
    resolver: useYupValidationResolver(lessonValidator),
  });

  const { mutate: createLesson, isLoading: isCreatingLesson } = useMutation({
    mutationKey: ['create-lesson', courseId],
    mutationFn: async (sendEmailNotificationToTeacher: boolean) => {
      const teacher = newLessonMethods.getValues().teacher as
        | IOption
        | undefined;

      const newLesson: CourseLessonRequest = {
        ...newLessonMethods.getValues(),
        teacher: Number(teacher?.value) || undefined,
        date: moment(newLessonMethods.getValues().date).utc().toISOString(),
        sendEmailNotificationToTeacher: sendEmailNotificationToTeacher,
      };

      return createCourseLesson(
        newLesson,
        token,
        newLessonMethods.getValues().slidePdf
      );
    },
    onSuccess: async () => {
      toast.success('Lezione creata con successo');
      queryClient.invalidateQueries(['course', courseId]);
      navigate(-1);
    },
    onError: async () => {
      toast.error('Errore durante la creazione della lezione');
    },
  });

  const courseQueryFn = () => {
    return getCourseDetail(courseId!, token);
  };

  const courseQuery = useQuery({
    queryKey: ['getCourseDetail', courseId],
    queryFn: courseQueryFn,
  });

  const onSubmit = async () => {
    createLesson(false);
  };

  return (
    <>
      <TitlePage title='Dettaglio corso | Modifica lezione' />

      <div className='mt-6'>
        <FormProvider {...newLessonMethods}>
          <form onSubmit={newLessonMethods.handleSubmit(onSubmit)}>
            <div className='pt-6 pb-8'>
              <FormNewLesson
                newLessonMethods={newLessonMethods}
                isOnline={courseQuery.data?.data.attributes.isOnline}
              />
            </div>

            <div className='flex items-center justify-start gap-4'>
              <PrimaryButton onClick={() => navigate(-1)} outline type='button'>
                Annulla
              </PrimaryButton>
              <PrimaryButton isLoading={isCreatingLesson} type='submit'>
                Salva lezione
              </PrimaryButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AggiungiLezioneNuovoCorso;

import { UploadIcon } from '@heroicons/react/outline';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

interface UploadAreaPresentationalProps {
  label: string;
  name?: string;
  multiple?: boolean;
  formMethods?: UseFormReturn<any, any>;
  acceptedExtensions: string[];
  isSingleImage?: boolean;
  disabled?: boolean;
}
const UploadAreaPresentational: React.FC<UploadAreaPresentationalProps> = ({
  label,
  name = '',
  multiple,
  formMethods,
  acceptedExtensions,
  isSingleImage = false,
  disabled = false,
}) => {
  const onSelectFiles = (e: any) => {
    e.preventDefault();
    const currentFiles = formMethods?.getValues(name) || [];
    let newFiles: File[] = [];

    if (e?.target?.files && Array.from(e.target.files).length) {
      newFiles = Array.from(e.target.files);
      if (isSingleImage) {
        formMethods?.setValue(name, newFiles);
      } else {
        formMethods?.setValue(
          name,
          Array.isArray(currentFiles)
            ? [...currentFiles, ...newFiles]
            : newFiles
        );
      }
    }

    if (e?.dataTransfer?.files) {
      newFiles = Array.from(e.dataTransfer.files);
      if (isSingleImage) {
        formMethods?.setValue(name, newFiles);
      } else {
        formMethods?.setValue(
          name,
          Array.isArray(currentFiles)
            ? [...currentFiles, ...newFiles]
            : newFiles
        );
      }
    }
  };

  const preventDefault = (e: any) => e.preventDefault();
  return (
    <div
      onDrop={onSelectFiles}
      onDragLeave={preventDefault}
      onDragOver={preventDefault}
      onDragEnter={preventDefault}
    >
      <label className='flex items-center gap-2 text-sm text-gray-500'>
        {label} <UploadIcon className='w-4 h-4 text-gray-400' />
      </label>
      <div className='flex justify-center items-center w-full mt-1'>
        <label
          className={`flex flex-col justify-center items-center w-full h-64 bg-white rounded-lg border border-gray-300 border-dashed ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${disabled ? 'bg-white' : 'hover:bg-gray-100'}`}
        >
          <div className='flex flex-col justify-center items-center pt-5 pb-6'>
            <svg
              aria-hidden='true'
              className='mb-3 w-10 h-10 text-primary'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1'
                d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
              ></path>
            </svg>
            <p className='mb-2 text-sm text-gray-500'>
              <span className='font-semibold'>Click to upload</span> or drag and
              drop
            </p>
            <p className='text-xs text-gray-400'>
              {acceptedExtensions.map((ext) => ext.toUpperCase()).join(', ')}
            </p>
          </div>
          <input
            id='dropzone-file'
            name={name}
            type='file'
            className='hidden'
            onChange={onSelectFiles}
            accept={acceptedExtensions
              .map((ext) => '.' + ext.toLowerCase())
              .join(',')}
            multiple={multiple}
            value=''
            disabled={disabled}
          />
        </label>
      </div>
    </div>
  );
};

export default UploadAreaPresentational;

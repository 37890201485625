import TitlePage from '../../../components/TitlePage';
import PrimaryLinkFull from '../../../components/TextLink/PrimaryLinkFull';
import ReceiptTemplate from '../../../components/ReceiptTemplate';
import { Order, ProductType } from '../../../interfaces/orders';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from '../../../contexts/Auth';
import { isDelegato } from '../../../libs/utils/auth';
import orders from '../../../api/orders';
import { useState } from 'react';
import {
  CostCenter,
  ReceiptCausal,
  ReceiptEntry,
} from '../../../interfaces/receipts';
import QueryString from 'qs';
import { AppSettings } from '../../../interfaces/appSettings';
import { PaymentMethod } from '../../../interfaces/payments';

export default function CreaRicevutaTemplateCorso() {
  const [queryParams] = useSearchParams();
  const { id: courseId, eventId } = useParams();
  const [{ token, profile, settings }] = useAuth();
  const [entries, setEntries] = useState<ReceiptEntry[]>([]);
  const [profileId, setProfileId] = useState<number>();
  const [subscriptionOrder, setSubscriptionOrder] = useState<{
    attributes: Order;
    id: number;
  }>();
  const productType = queryParams.get('productType') as ProductType;

  const { data: orderQueryData } = useQuery(
    'findOneOrder',
    () =>
      orders.findOne({
        token,
        id: queryParams.get('orderId'),
        query: {
          populate: [
            'event',
            'course.headquarterBalance.administrations',
            'cardHistory',
            'payment.receipt',
            'profile',
          ],
        },
      }),
    {
      onSuccess: (data) => {
        setEntries(() => getReceiptsEntries(data.data.attributes, settings));
        setProfileId(data.data.attributes.profile.data?.id);
      },
    }
  );

  useQuery(
    'findSubscriptionId',
    () =>
      orders.find({
        token,
        query: {
          populate: [
            'event',
            'course.headquarterBalance.administrations',
            'cardHistory',
            'payment.receipt',
          ],
          filters: {
            productType: ProductType.SUBSCRIPTION,
            profile: profileId,
          },
          sort: {
            createdAt: 'desc',
          },
        },
      }),
    {
      enabled: !!profileId,
      onSuccess: (_data) => {
        setSubscriptionOrder(
          _data.data?.find(
            (elem) =>
              elem.attributes.associationCode &&
              elem.attributes.associationCode ===
                orderQueryData?.data.attributes.associationCode
          )
        );
      },
    }
  );

  return (
    <>
      <TitlePage
        title={
          getEntityUtils({
            productType: queryParams.get(
              'productType'
            ) as ProductType as ProductType,
            entityId: courseId || eventId,
          }).pageTitle
        }
      />

      <div className='mt-6'>
        <div className='my-6'>
          <h5 className='text-lg text-gray-800'>
            Seleziona un template per creare una nuova ricevuta.
          </h5>

          {/* <div className='grid grid-cols-2 gap-6 mt-6 mb-6'> */}
          <div className='grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-6 mt-6 mb-6'>
            {
              <ReceiptTemplate
                entries={entries}
                title={`Saldo ${
                  productType === ProductType.COURSE ? 'corso' : 'evento'
                }`}
                queryString={`productType=${queryParams.get(
                  'productType'
                )}&orders=${QueryString.stringify([
                  queryParams.get('orderId'),
                ])}&payments=${QueryString.stringify([
                  queryParams.get('paymentId'),
                ])}`}
              />
            }
            {subscriptionOrder &&
              !subscriptionOrder?.attributes.payment?.data?.attributes.receipt
                ?.data?.attributes && (
                <ReceiptTemplate
                  title={`Saldo ${
                    productType === ProductType.COURSE ? 'corso' : 'evento'
                  } compreso di quota associativa`}
                  entries={[
                    ...entries,
                    {
                      amount:
                        subscriptionOrder?.attributes.payment?.data?.attributes
                          .amount || null,
                      causal: ReceiptCausal.QUOTA_ASSOCIATIVA,
                      costCenter: settings?.subscription.costCenter || null,
                    },
                  ]}
                  queryString={`productType=${queryParams.get(
                    'productType'
                  )}&orders=${QueryString.stringify([
                    queryParams.get('orderId'),
                    subscriptionOrder?.id,
                  ])}&payments=${QueryString.stringify([
                    queryParams.get('paymentId'),
                    subscriptionOrder?.attributes.payment?.data.id,
                  ])}`}
                />
              )}
            {!isDelegato(profile) && (
              <ReceiptTemplate
                title='Ricevuta libera'
                queryString={`productType=${queryParams.get(
                  'productType'
                )}&mode=FREE&orders=${QueryString.stringify([
                  queryParams.get('orderId'),
                ])}&payments=${QueryString.stringify([
                  queryParams.get('paymentId'),
                ])}`}
                entries={entries}
                showEntries={false}
              />
            )}
          </div>
        </div>

        <div className='flex items-center justify-start gap-4'>
          <PrimaryLinkFull
            href={
              getEntityUtils({
                productType: queryParams.get('productType') as ProductType,
                entityId: courseId || eventId,
              }).cancelUrl
            }
            outline
          >
            Annulla
          </PrimaryLinkFull>
        </div>
      </div>
    </>
  );
}

const getEntityUtils = ({
  productType,
  entityId,
}: {
  productType: ProductType;
  entityId?: string;
}) => {
  if (productType === ProductType.COURSE)
    return {
      cancelUrl: `/corsi/dettaglio-corso/${entityId}/partecipanti`,
      pageTitle: 'Dettaglio corso | Crea ricevuta da template',
    };
  return {
    cancelUrl: `/eventi/modifica/${entityId}/partecipanti`,
    pageTitle: 'Dettaglio evento | Crea ricevuta da template',
  };
};

const getReceiptsEntries = (
  order: Order,
  settings: AppSettings | undefined
) => {
  let feeCoefficient = 0;
  const courseSubAmount = Number(
    order?.course?.data?.attributes?.headquarterBalance?.administrations
      .estimatedPriceUnit || 0
  );
  const eventAmount = Number(
    order?.event?.data?.attributes?.subscriptionAmount || 0
  );
  const eventOrderQuantity = Number(order.quantity);
  const courseAmount = Number(
    order?.course?.data?.attributes?.subscriptionAmount || 0
  );
  const totalCourseAmount = courseAmount + courseSubAmount;
  if (order.payment?.data?.attributes.method !== PaymentMethod.PAYPAL) {
    feeCoefficient = settings?.bankTransferFee || 0;
  }
  switch (order.productType) {
    case ProductType.COURSE:
      return [
        {
          amount: Math.ceil(
            totalCourseAmount +
              totalCourseAmount * feeCoefficient -
              courseSubAmount
          ),
          causal: ReceiptCausal.QUOTA_CORSO,
          costCenter: order.course?.data?.attributes?.courseFee,
        },
        {
          amount: Math.ceil(courseSubAmount),
          causal: ReceiptCausal.QUOTA_ISCRIZIONE,
          costCenter: order.course?.data.attributes.registrationFee,
        },
      ];
    case ProductType.EVENT:
      return [
        {
          amount:
            Math.ceil(eventAmount + eventAmount * feeCoefficient) *
            (eventOrderQuantity || 1),
          causal: ReceiptCausal.QUOTA_EVENTO,
          costCenter: CostCenter.DELEGATION,
        },
      ];
    case ProductType.SUBSCRIPTION: {
      const causal =
        order.cardHistory.data.attributes.type === 'primaTessera'
          ? ReceiptCausal.QUOTA_ASSOCIATIVA
          : ReceiptCausal.RINNOVO_QUOTA_ASSOCIATIVA;
      return [
        {
          amount: order?.payment?.data?.attributes?.amount || null,
          causal,
          costCenter: settings?.subscription?.costCenter,
        },
      ];
    }
    default:
      return [];
  }
};

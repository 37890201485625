import moment from 'moment';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import AlertMessage from '../../../components/AlertMessage';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { FINAL_BALANCE_EXPIRATION_DAYS } from '../../../constants/course';
import { CourseStatus, StrapiResponseList } from '../../../interfaces/commons';
import { CourseResponse } from '../../../interfaces/courses';
import { useAuth } from '../../../contexts/Auth';
import { isDelegato } from '../../../libs/utils/auth';
import BadgeStatus from '../../../components/BadgeStatus';

interface CoursesToReportProps {
  query: UseQueryResult<StrapiResponseList<CourseResponse>>;
}
const CoursesToReport: React.FC<CoursesToReportProps> = ({ query }) => {
  const tableElements = ['Data fine corso', 'Corso', 'Sezione'];
  const navigate = useNavigate();
  const [{ profile }] = useAuth();

  if (!isDelegato(profile)) {
    tableElements.push('Stato');
  }

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              const filteredCourses = query.data.data.filter((course) =>
                !isDelegato(profile)
                  ? course.attributes.status ===
                      CourseStatus.FINAL_BALANCE_DELEGATION ||
                    course.attributes.status === CourseStatus.PUBLISHED
                  : course.attributes.status === CourseStatus.PUBLISHED
              );
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, idx) => (
                          <th
                            key={idx}
                            className='font-normal px-6 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                        <th
                          colSpan={3}
                          className='font-normal px-6 py-3 text-left tracking-wide'
                        ></th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {filteredCourses.map((course) => (
                        <tr
                          key={course.id}
                          className='cursor-pointer hover:bg-neutral-100'
                          onClick={() => {
                            navigate(
                              `corsi/dettaglio-corso/${course.id}/dati-principali`
                            );
                          }}
                        >
                          <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                            {course.attributes.endDate
                              ? new Date(
                                  course.attributes.endDate
                                ).toLocaleDateString('it-IT')
                              : '-'}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {course.attributes.title}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {course.attributes.section?.data?.attributes.name}
                          </td>
                          {!isDelegato(profile) && (
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              <BadgeStatus status={course.attributes.status} />
                            </td>
                          )}
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {moment().diff(
                              moment(course.attributes.endDate),
                              'days'
                            ) > FINAL_BALANCE_EXPIRATION_DAYS && (
                              <AlertMessage
                                borderLeft
                                noTitle
                                text='Corso da rendicontare urgentemente'
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                      {query.data.data.length === 0 && (
                        <p className='text-gray-700 text-sm font-IBM font-light'>
                          Non sono presenti corsi da rendicontare
                        </p>
                      )}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default CoursesToReport;

import React from "react";
import classNames from "../../../libs/utils/classNames";

const FormLoading = ({ numberItems }: { numberItems?: number }) => {
  const n = numberItems || 8;
  return (
    <div
      className={classNames(
        "animate-pulse",
        numberItems === 1
          ? "grid grid-cols-1"
          : "py-8 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 gap-y-6 "
      )}
    >
      {[...Array(n)].map((e, i) => (
        <div key={i} className="w-full flex-1 space-y-2 ">
          <div className="h-4 bg-gray-200 rounded w-2/4"></div>
          <div className="h-11 bg-gray-200 rounded"></div>
        </div>
      ))}
    </div>
  );
};
export default FormLoading;

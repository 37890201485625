import { Helmet } from "react-helmet";
interface Props {
  title: string;
}

export default function TitlePage({ title }: Props) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title} | Onav Amministrazione</title>
    </Helmet>
  );
}

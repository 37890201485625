import React from "react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import classNames from "../../../libs/utils/classNames";
import { useSearchParams } from "react-router-dom";
import PrimaryButtonFull from "../../Buttons/PrimaryButtonFull";
import UnderlineButton from "../../Buttons/UnderlineButton";

const SelectDate = ({ mobile }: { mobile?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeDateFrom = (value: string) => {
    value == ""
      ? searchParams.delete("dateFrom")
      : searchParams.set("dateFrom", value);
    searchParams.delete("page");
  };
  const onChangeDateTo = (value: string) => {
    value == ""
      ? searchParams.delete("dateTo")
      : searchParams.set("dateTo", value);
    searchParams.delete("page");
  };

  const List = () => {
    return (
      <>
        <div className="w-full">
          <label
            htmlFor="date-from"
            className="flex justify-between text-sm text-gray-500"
          >
            <div>Data da </div>
          </label>
          <div className="flex items-center mt-1">
            <input
              id="data-from"
              defaultValue={searchParams.get("dateFrom") || ""}
              type="date"
              onChange={(e) => onChangeDateFrom(e.target.value)}
              name="data-from"
              className="block w-full px-3 py-2 text-gray-800 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            />
          </div>
        </div>

        <div className="w-full pb-4">
          <label
            htmlFor="date-to"
            className="flex justify-between text-sm text-gray-500"
          >
            <div>Data a </div>
          </label>
          <div className="flex items-center mt-1">
            <input
              id="data-to"
              defaultValue={searchParams.get("dateTo") || ""}
              type="date"
              onChange={(e) => onChangeDateTo(e.target.value)}
              name="data-to"
              className="block w-full px-3 py-2 text-gray-800 placeholder-gray-400 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-gray-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            />
          </div>
        </div>

        <PrimaryButtonFull
          children={"Cerca per data"}
          onClick={() => {
            setSearchParams(searchParams);
          }}
        />
      </>
    );
  };

  return mobile ? (
    <Disclosure
      as="div"
      key="select-date"
      className="px-4 py-6 pr-1 border-t border-gray-200"
    >
      {({ open }) => (
        <>
          <h3 className="flow-root -mx-2 -my-3">
            <Disclosure.Button className="flex items-center justify-between w-full px-2 py-3 text-sm text-gray-400 bg-white">
              <span className="font-normal text-gray-800">Data</span>
              <span className="flex items-center ml-6">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-5 w-5 transform"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="w-full pr-3 space-y-6">
              <List />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Popover key="sections" className="relative inline-block px-4 text-left">
      <Popover.Button className="inline-flex justify-center text-sm font-normal text-gray-700 group hover:text-gray-800">
        <span>Filtra per Data</span>

        <ChevronDownIcon
          className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 p-4 mt-2 origin-top-right bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="w-56 space-y-6 ">
            <List />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default SelectDate;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../libs/utils/classNames';

interface IconLinkProps {
  href?: string;
  small?: boolean;
  redTxt?: boolean;
  orangeTxt?: boolean;
  greyTxt?: boolean;
  children: any;
  disabled?: boolean;
}

const IconLink: React.FC<IconLinkProps> = ({
  href,
  children,
  redTxt,
  greyTxt,
  orangeTxt,
  disabled,
}) => {
  if (disabled)
    return (
      <div
        className={classNames(
          'font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base rounded-full w-max',
          greyTxt ? 'text-gray-500' : '',
          redTxt ? 'text-red-700' : '',
          orangeTxt ? 'text-orange-400' : '',
          disabled ? 'cursor-not-allowed opacity-60' : ''
        )}
      >
        {children}
      </div>
    );

  return (
    <>
      {href ? (
        <Link
          to={href}
          className={classNames(
            'font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base rounded-full w-max',
            greyTxt ? 'text-gray-500' : '',
            redTxt ? 'text-red-700' : '',
            orangeTxt ? 'text-orange-400' : ''
          )}
        >
          {children}
        </Link>
      ) : (
        <span>
          <a
            className={classNames(
              'font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base rounded-full w-max',
              greyTxt ? 'text-gray-500' : '',
              redTxt ? 'text-red-700' : '',
              orangeTxt ? 'text-orange-400' : ''
            )}
          >
            {children}
          </a>
        </span>
      )}
    </>
  );
};

export default IconLink;

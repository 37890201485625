import TitlePage from '../../../components/TitlePage';
import FormDatiPrincipali from '../../../components/FormDatiPrincipali';
import { UseFormReturn } from 'react-hook-form';

interface DatiPrincipaliProps {
  onImageDelete?: (fileId?: number) => void;
  onCarouselImageDelete?: (index: number) => void;
  courseMethods?: UseFormReturn<any, any>;
}

const DatiPrincipali = ({
  onImageDelete,
  onCarouselImageDelete,
  courseMethods,
}: DatiPrincipaliProps) => {
  return (
    <>
      <TitlePage title='Crea nuovo corso | Dati principali' />

      <form>
        <FormDatiPrincipali
          onImageDelete={onImageDelete}
          onCarouselImageDelete={onCarouselImageDelete}
          courseMethods={courseMethods}
        />
      </form>
    </>
  );
};

export default DatiPrincipali;

import React from 'react';
import { Navigate } from 'react-router-dom';
import roles from '../../constants/roles';
import { AuthActionType, useAuth } from '../../contexts/Auth';
import Spinner from '../Layout/Loading/Spinner';

const PrivateRoute: React.FC<{
  element: React.ReactElement;
  publicRedirect?: string;
}> = (props) => {
  const [{ appLoaded, isAuthenticated, profile }, dispatch] = useAuth();
  const { element: PrivateElement } = props;

  if (appLoaded) {
    if (
      isAuthenticated &&
      [
        roles.SEGRETERIA_NAZIONALE,
        roles.DELEGATO_SEZIONE,
        roles.DELEGATO_REGIONE,
      ].includes(profile!.role.name)
    ) {
      return PrivateElement;
    } else {
      dispatch({ type: AuthActionType.Logout });
      return <Navigate replace to='/login' />;
    }
  }

  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <Spinner />
    </div>
  );
};

export default PrivateRoute;

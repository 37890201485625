import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import TabBar from '../../../components/TabBars/TabBar';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import ModificaUtenteAnagrafica from './Pages/anagrafica';
import ModificaUtenteCorsi from './Pages/corsi';
import ModificaUtenteDatiOnav from './Pages/dati-onav';
import ModificaUtenteEventi from './Pages/eventi';
import ModificaUtenteLezioni from './Pages/lezioni';
import ModificaUtenteRicevute from './Pages/ricevute';
import ModificaUtenteStorico from './Pages/storico';
import { match } from 'ts-pattern';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FormLoading from '../../../components/Layout/Loading/FormLoading';
import { profileInfo, updateProfile } from '../../../api/profile';
import { useAuth } from '../../../contexts/Auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { getSmallImage } from '../../../libs/utils/media';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';
import ModificaUtenteLezioniAbilitate from './Pages/lezioni-abilitate';
import ModificaUtenteImpostazioni from './Pages/impostazioni';
import StoricoTessera from './Pages/storico-tessera';
import { toast } from 'react-toastify';
import FullPageSpinner from '../../../components/Layout/Loading/FullPageSpinner';
import capitalize from '../../../libs/utils/capitalize';
import { isDelegato, isSegreteriaNazionale } from '../../../libs/utils/auth';
import ModificaRicevutaCorso from '../../Corsi/DettaglioCorso/modifica-ricevuta';
import UpdateReceipt from './Pages/update-receipt';
import { ProfileRequest } from '../../../interfaces/profile';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { profileValidator } from '../../../validators/profile';
import moment from 'moment';

const ModificaUtente = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const id = Number(params.id);
  const [{ token, profile }] = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateUserForm = useForm<ProfileRequest>({
    resolver: useYupValidationResolver(profileValidator),
  });

  const query = useQuery(
    ['profile2', searchParams.get('newBadge')],
    () => profileInfo({ id, token, filters: {} }),
    {
      cacheTime: 0,
      onSuccess: (data) => {
        const {
          data: { attributes },
        } = data;
        const mappedData: ProfileRequest = {
          addressesCoincide: attributes.addressesCoincide,
          birthDate: moment(attributes.birthDate).format('YYYY-MM-DD'),
          email: attributes.user.data?.attributes?.email,
          fiscalCode: attributes.fiscalCode,
          mobilePhone: attributes.mobilePhone,
          altPhone: attributes.altPhone,
          name: attributes.name,
          section: attributes.section?.data?.id,
          surname: attributes?.surname,
          region: attributes?.region?.data?.id,
          province: attributes?.province?.data?.id,
          gender: attributes.gender,
          birthPlace: attributes.birthPlace,
        };
        updateUserForm.reset({
          ...mappedData,
          badgeNumber: attributes.badge.data?.attributes.badgeNumber,
        });
      },
    }
  );

  const tabs = [
    {
      label: 'Anagrafica',
      href: `/utenti/modifica/${id}`,
    },
    {
      label: 'Dati ONAV',
      href: `/utenti/modifica/${id}/dati-onav`,
    },
    {
      label: 'Corsi',
      href: `/utenti/modifica/${id}/corsi`,
    },
    {
      label: 'Eventi',
      href: `/utenti/modifica/${id}/eventi`,
    },
    {
      label: 'Storico',
      href: `/utenti/modifica/${id}/storico`,
    },
    {
      label: 'Ricevute',
      href: `/utenti/modifica/${id}/ricevute`,
    },
    {
      label: 'Lezioni',
      href: `/utenti/modifica/${id}/lezioni`,
      showFor: 'teacher',
    },
    // {
    //   label: "Lezioni abilitate",
    //   href: `/utenti/modifica/${id}/lezioni-abilitate`,
    // },
  ];

  if (isSegreteriaNazionale(profile)) {
    tabs.push({
      label: 'Impostazioni',
      href: `/utenti/modifica/${id}/impostazioni`,
    });
  }

  const { mutate, isLoading } = useMutation(
    'updateUserProfile',
    updateProfile,
    {
      onSuccess: () => {
        toast.success('Utente aggiornato con successo');
        query.refetch();
        navigate('/utenti');
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message ===
            'Invalid format, expected a timestamp or an ISO date'
            ? 'Settare la data di nascita'
            : error.response.data.error.message
        );
      },
    }
  );

  const updateProfileInfo = async (data: FieldValues) => {
    data.birthDate = data.birthDate ? data.birthDate : undefined;
    const homeAddress = data.addressesCoincide ? null : data.homeAddress;
    mutate({
      id,
      body: {
        ...data,
        homeAddress: homeAddress,
        name: capitalize(data.name),
        surname: capitalize(data.surname),
      },
      token,
    });
  };

  //lo usiamo per mostrare un popup di errore siccome il form è distribuito su più tab
  const save = (e: any) => {
    e.preventDefault();
    const formHasError: boolean =
      Object.keys(updateUserForm.formState.errors).length > 0;
    if (formHasError) {
      return () =>
        toast.error(
          'Ci sono degli errori di validazione. Controlla tutti i campi'
        );
    }
    return updateUserForm.handleSubmit(updateProfileInfo);
  };

  useEffect(() => {
    updateUserForm.reset();
  }, [query.data]);

  return (
    <>
      <TitlePage title='Utenti' />
      {isLoading && <FullPageSpinner />}
      <div>
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <FormLoading />
          ))
          .with({ status: 'error' }, () => <div>Errore...</div>)
          .with({ status: 'success' }, (query) => {
            return (
              <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
                <FormProvider {...updateUserForm}>
                  <form className='space-y-5' onSubmit={(e) => save(e)()}>
                    {/*  INTESTAZIONE  */}
                    <div className='flex flex-col md:flex-row items-center justify-between gap-4 mb-5'>
                      <div className='flex content-center items-center '>
                        {query.data.data.attributes.profileImage.data && (
                          <img
                            src={getSmallImage(
                              query.data.data.attributes.profileImage.data
                            )}
                            className='border rounded-full h-20 w-20 mr-6 object-cover'
                          />
                        )}
                        <H1Styled noMargin>
                          Modifica profilo:{' '}
                          <span className='font-medium'>
                            {query.data.data.attributes.surname +
                              ' ' +
                              query.data.data.attributes.name}
                          </span>
                        </H1Styled>
                      </div>
                      <div className='flex items-center gap-4'>
                        <PrimaryButton
                          outline
                          onClick={() => {
                            queryClient.invalidateQueries(['profile2']);
                            navigate(-1);
                          }}
                        >
                          Chiudi
                        </PrimaryButton>
                        {!isDelegato(profile) && (
                          <PrimaryButton type='submit'>
                            Salva modifiche
                          </PrimaryButton>
                        )}
                      </div>
                    </div>
                    {/*  FORM  */}
                    <TabBar tabs={tabs} profile={query.data.data} />
                    <Routes>
                      <Route
                        path='/'
                        element={
                          <ModificaUtenteAnagrafica
                            userInfo={query.data.data}
                          />
                        }
                      />

                      <Route
                        path='dati-onav'
                        element={
                          <ModificaUtenteDatiOnav userInfo={query.data.data} />
                        }
                      />
                      <Route
                        path='storico-tessera'
                        element={
                          <StoricoTessera
                            infoUser={query.data.data}
                            meta={query.data.meta}
                          />
                        }
                      />
                      <Route
                        path='storico'
                        element={<ModificaUtenteStorico />}
                      />
                      <Route path='corsi' element={<ModificaUtenteCorsi />} />
                      <Route path='eventi' element={<ModificaUtenteEventi />} />
                      <Route
                        path='ricevute'
                        element={<ModificaUtenteRicevute />}
                      />
                      {isSegreteriaNazionale(profile) && (
                        <Route
                          path='impostazioni'
                          element={
                            <ModificaUtenteImpostazioni
                              infoUser={query.data.data}
                            />
                          }
                        />
                      )}
                    </Routes>
                  </form>
                </FormProvider>
                <Routes>
                  <Route
                    path='lezioni'
                    element={
                      <ModificaUtenteLezioni
                        profile={query.data.data}
                        meta={query.data.meta}
                      />
                    }
                  />
                  <Route
                    path='lezioni-abilitate'
                    element={<ModificaUtenteLezioniAbilitate />}
                  />
                  <Route
                    path='ricevute/:receiptId'
                    element={<UpdateReceipt />}
                  />
                </Routes>
              </div>
            );
          })
          .exhaustive()}
      </div>
    </>
  );
};

export default ModificaUtente;

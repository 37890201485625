import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import UnderlineButton from './Buttons/UnderlineButton';
import InputPresentational from './Form/InputPresentational';
import ReceiptEntry from './ReceiptEntry';
import {
  CostCenter,
  ReceiptEntry as IReceiptEntry,
  ReceiptRequest,
} from '../interfaces/receipts';
import SelectPresentational from './Form/SelectPresentational';
import SectionsSelect from './FetchSelect/SectionsSelect';
import { useSearchParams } from 'react-router-dom';
import { PAYMENT_METHOD_OPTIONS } from '../libs/utils/options';
import { formatCurrency } from '../libs/utils/helpers';
import { useMutation, useQuery } from 'react-query';
import receipts from '../api/receipts';
import { useAuth } from '../contexts/Auth';
import { useDebounce } from 'use-debounce';
import { getOneProfile, getProfiles } from '../api/profile';
import Spinner from './Layout/Loading/Spinner';
import { PaymentMethod } from '../interfaces/payments';
import sectionsAPIs from '../api/section';
import orders from '../api/orders';
import { Order, ProductType } from '../interfaces/orders';
import { SearchableSelect } from './Form/SearchableSelect';

import roles from '../constants/roles';
import { getQuerySearchFilters } from '../libs/utils/profile';

interface Props {
  receiptForm: UseFormReturn<ReceiptRequest, ReceiptRequest>;
  treasury: boolean;
  isPartecipantFetched?: boolean;
}

const ReceiptForm: React.FC<Props> = ({
  receiptForm,
  treasury,
  isPartecipantFetched = true,
}) => {
  const [queryParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const { register, watch, control, setValue, getValues, formState } =
    receiptForm;
  //questa variabile serve per sapere quando far vedere l'input con gli utenti
  const { errors } = formState;
  const [search, setSearch] = useState<string>();
  const [debouncedSearch] = useDebounce(search, 500);
  const [isFreeReceipt, setisFreeReceipt] = useState(false);
  const sectionWatch = watch('section');

  const sectionDetailQuery = useQuery(['sectionDetail', sectionWatch], () => {
    if (sectionWatch) return sectionsAPIs.findOne({ token, id: sectionWatch });
    return null;
  });

  const action = useMemo(() => queryParams.get('action'), [queryParams]);

  useEffect(() => {
    //Le ricevute sono sempre modificabili. In creazione invece solo le libere hanno i campi disabilitati
    setisFreeReceipt(queryParams.get('mode') === 'FREE' || action === 'EDIT');
  }, [queryParams.get('mode')]);

  const { fields: entries, append } = useFieldArray({
    control,
    name: 'entries',
  });

  const { data: orderData } = useQuery(
    'getRecieptOrderDetail',
    () => {
      if (watch('orders')?.length)
        return orders.findOne({
          token,
          id: (watch('orders') || [])[0],
          query: { populate: ['course', 'event'] },
        });
      return null;
    },
    { enabled: Number(watch('orders')?.length) > 0 }
  );

  const { data: nationalSectionData } = useQuery(
    'getNationalSection',
    () => sectionsAPIs.find({ token, query: { filters: { costCenter: 1 } } }),
    { enabled: !!orderData?.data.attributes }
  );

  const codeMutation = useMutation('createCode', receipts.createCode, {
    onSuccess: (data) => {
      setValue('code', data.code);
    },
  });

  useEffect(() => {
    if (getValues().profile) return setSearch(getValues().profile?.label);
    if (isFreeReceipt && action !== 'EDIT') return setSearch('');
  }, [getValues().profile, isFreeReceipt, treasury]);

  const profilesQuery = useQuery(
    ['getProfileListForReceipts', debouncedSearch, isFreeReceipt],
    () => {
      if (!!debouncedSearch) {
        return getProfiles({
          token,
          query: {
            filters: {
              $and: getQuerySearchFilters(debouncedSearch),
            },
            pagination: {
              page: 1,
              pageSize: 50,
            },
            forceListing: true,
          },
        });
      }
    },
    {}
  );

  useQuery(
    ['profileDetailQuery', watch('profile')],
    () => {
      if (watch('profile'))
        return getOneProfile({
          token,
          id: watch('profile')?.value + '',
          query: {
            populate: ['badge'],
          },
        });
      return null;
    },
    {
      onSuccess: (data) => {
        setValue('fiscalCode', data?.data.attributes.fiscalCode);
        setValue(
          'badgeNumber',
          data?.data.attributes.badge?.data?.attributes?.badgeNumber
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      sectionWatch &&
      ((queryParams.get('productType') && orderData && nationalSectionData) ||
        queryParams.get('mode') === 'FREE') &&
      action !== 'EDIT' &&
      isPartecipantFetched
    ) {
      codeMutation.mutate({
        token,
        section: getSectionId({
          section: sectionWatch,
          productType: queryParams.get('productType') as ProductType,
          order: orderData?.data.attributes,
          nationalSection: nationalSectionData?.data[0].id,
        }),
      });
    }
  }, [
    sectionWatch,
    queryParams.get('productType'),
    orderData,
    nationalSectionData,
    isPartecipantFetched,
  ]);

  return (
    <div className='pb-7 lg:pb-12 w-full space-y-10'>
      {
        <div className='pt-6 pb-8 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 gap-y-6'>
          <InputPresentational
            id='code'
            label='Numero ricevuta'
            name='code'
            type='text'
            register={register}
            disabled
            required
            error={errors.code}
          />
          <InputPresentational
            id='date'
            label='Data'
            name='date'
            type='date'
            register={register}
            required
            disabled
            error={errors.date}
          />
          <InputPresentational
            id='description'
            label='Descrizione che visualizza il partecipante'
            name='description'
            type='text'
            register={register}
          />
          <SectionsSelect
            label='Sezione - centro di costo'
            control={control}
            disabled={!isFreeReceipt || action === 'EDIT'}
            defaultValue={getValues().section}
          />
          <SelectPresentational
            name='paymentMethod'
            control={control}
            label='Metodo di pagamento'
            //se la sezione è onav nazionale, escludo l'opzione di bonifico a sezione
            //altrimenti escludo quella di bonifico a sede
            options={PAYMENT_METHOD_OPTIONS.filter((option) => {
              if (profile?.role?.name === roles.SEGRETERIA_NAZIONALE) {
                return true;
              } else if (
                Number(
                  sectionDetailQuery.data?.data?.attributes?.costCenter
                ) === 1
              )
                return option.value !== PaymentMethod.BANK_TRANSFER_SECTION;
              else return option.value !== PaymentMethod.BANK_TRANSFER_SITE;
            })}
            disabled={!isFreeReceipt}
          />
          {(!!profilesQuery.data || queryParams.get('mode') === 'FREE') && (
            <SearchableSelect
              isLoading={profilesQuery.isFetching}
              name='profile'
              control={control}
              disabled={!isFreeReceipt}
              label='Nominativo'
              options={
                profilesQuery.data?.data?.map((elem) => ({
                  label: `${elem.attributes.name} ${elem.attributes.surname}`,
                  value: elem.id,
                })) || []
              }
              onInput={setSearch}
            />
          )}
          <InputPresentational
            id='fiscalCode'
            label='Codice fiscale'
            name='fiscalCode'
            type='text'
            register={register}
            disabled
          />
          <InputPresentational
            id='badgeNumber'
            label='Numero tessera'
            name='badgeNumber'
            type='text'
            register={register}
            disabled
          />
          {action === 'EDIT' && (
            <InputPresentational
              id='notes'
              label='Causale modifica'
              name='notes'
              type='text'
              disabled={!isFreeReceipt}
              register={register}
            />
          )}
        </div>
      }
      {profilesQuery.isLoading && (
        <Spinner className='flex w-full justify-center' />
      )}
      <div className='flex w-full items-center flex-col'>
        {entries.map((_entry, idx) => (
          <ReceiptEntry
            idx={idx}
            key={`entry-${idx}`}
            receiptForm={receiptForm}
            index={idx}
            disabled={!isFreeReceipt}
            treasury={treasury}
          />
        ))}
        <UnderlineButton
          onClick={() => append({ causal: null, amount: 0, costCenter: null })}
          disabled={!isFreeReceipt}
        >
          Aggiungi voce nella ricevuta
        </UnderlineButton>
        {errors.entries && (
          <p className='mt-2 text-sm text-red-500'>{errors.entries?.message}</p>
        )}
      </div>
      <hr />
      <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
        <p className='text-gray-500'>
          Totale ricevuta <br />{' '}
          <span className='font-medium text-lg mt-0 text-black'>
            {formatCurrency(
              watch().entries.reduce(
                (acc: number, val: IReceiptEntry) =>
                  Number(acc) + Number(String(val.amount).replace(',', '.')),
                0
              )
            )}
          </span>
        </p>

        {/* <div>
          <AlertMessage
            borderLeft
            text="Mancano ancora <b>€ 300,00</b> al totale dell'importo effettivo del corso"
          />
        </div> */}
      </div>
    </div>
  );
};

const getSectionId = ({
  section,
  order,
  productType,
  nationalSection,
}: {
  section: number;
  order?: Order;
  productType: ProductType;
  nationalSection?: number | null;
}) => {
  const entityFee = getEntityFee({ order, productType });

  if (entityFee === CostCenter.DELEGATION || !productType) return section;
  return nationalSection;
};

const getEntityFee = ({
  order,
  productType,
}: {
  order?: Order;
  productType: ProductType;
}): CostCenter | undefined => {
  if (productType === ProductType.COURSE) {
    return order?.course?.data.attributes.courseFee;
  }
  return CostCenter.DELEGATION;
};

export default ReceiptForm;

import React from "react";
import classNames from "../libs/utils/classNames";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

interface BadgeProps {
  active: boolean;
  className?: string;
}
const BadgeBoolean: React.FC<BadgeProps> = ({ active, className }) => {
  return (
    <div className={"flex justify-center " + className}>
      {active ? (
        <CheckCircleIcon className="w-6 h-6 text-green-600" />
      ) : (
        <XCircleIcon className="w-6 h-6 text-red-700" />
      )}
    </div>
  );
};

export default BadgeBoolean;

import TitlePage from '../../../components/TitlePage';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import FormEditLesson from '../../../components/FormEditLesson';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getLesson, updateCourseLesson } from '../../../api/lesson';
import { useAuth } from '../../../contexts/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import Spinner from '../../../components/Layout/Loading/Spinner';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';
import _ from 'lodash';
import { CourseLessonRequest } from '../../../interfaces/lesson';
import { IOption } from '../../../components/Form/Multiselect';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { lessonValidator } from '../../../validators/lesson';
import moment from 'moment';
import { isDelegato } from '../../../libs/utils/auth';
import { deleteMedia } from '../../../libs/utils/media';
import { useEffect, useMemo } from 'react';

interface ModificaLezioneCorsoProps {
  courseId?: string;
}

export default function ModificaLezioneCorso({
  courseId,
}: ModificaLezioneCorsoProps) {
  const [{ token, profile }] = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const editLessonMethods = useForm<CourseLessonRequest>({
    resolver: useYupValidationResolver(lessonValidator),
  });

  const { watch, setValue } = editLessonMethods;

  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['getLesson', id],
    queryFn: () => {
      if (!id) return;
      return getLesson({ token, id });
    },
    onSuccess: (data) => {
      const formattedLesson: CourseLessonRequest = {
        ...data?.data?.attributes,
        areSpendingsReceived: data?.data?.attributes.areSpendingsReceived,
        lessonTemplate: data?.data.attributes.lessonTemplate,
        wineList: data?.data?.attributes.wineList,
        date: formatDate(data?.data?.attributes?.date) || '',
        title: data?.data?.attributes.title || '',
        course: data?.data?.attributes.course?.data.id,
        description: data?.data?.attributes.description || '',
        category: data?.data?.attributes.category || undefined,
        minutes: data?.data?.attributes.minutes || 0,
        estimatePayment: data?.data?.attributes.estimatePayment || 0,
        estimatePaymentLimit: data?.data?.attributes.estimatePaymentLimit || 0,
        estimateRefund: data?.data?.attributes.estimateRefund || 0,
        endCourse: Boolean(data?.data?.attributes.endCourse),
        teacher: data?.data?.attributes.teacher?.data?.id || undefined,
        //oldTeacher serve per identificare quando cambia
        //se cambia ci deve essere la gestione dell'eventuale invio email
        oldTeacher: data?.data?.attributes.teacher?.data?.id || undefined,
        isTeacherChanged: false || undefined,
        sendEmailNotificationToTeacherOldStatus:
          data?.data.attributes.sendEmailNotificationToTeacher,
        availableTeacher:
          data?.data?.attributes.lessonTemplate?.data?.attributes.availableTeacher?.data.map(
            (p: any) => p.id
          ),
        slidePdf: data?.data?.attributes.slidePdf || undefined,
        waiverTeacher: data?.data?.attributes.waiverTeacher || '',
        paymentDate: data?.data?.attributes?.paymentDate
          ? moment(data?.data?.attributes?.paymentDate).format('YYYY-MM-DD')
          : undefined,
      };

      editLessonMethods.reset(formattedLesson);
    },
  });

  useEffect(() => {
    const teacher = watch('teacher');
    const oldTeacher = watch('oldTeacher');
    let isTeacherChanged = watch('isTeacherChanged');

    if (typeof teacher === 'number') {
      isTeacherChanged = teacher !== oldTeacher;
    } else if (typeof teacher === 'object') {
      isTeacherChanged = teacher?.value !== oldTeacher;
    }

    setValue('isTeacherChanged', isTeacherChanged);
  }, [watch('teacher')]);

  useEffect(() => {
    const sendEmailNotificationToTeacherOldStatus = watch(
      'sendEmailNotificationToTeacherOldStatus'
    );
    const isTeacherChanged = watch('isTeacherChanged');

    if (isTeacherChanged) {
      setValue('sendEmailNotificationToTeacher', false);
    } else {
      setValue(
        'sendEmailNotificationToTeacher',
        sendEmailNotificationToTeacherOldStatus
      );
    }
  }, [watch('isTeacherChanged')]);

  const { mutate: updateLessonMutation, isLoading } = useMutation({
    mutationKey: ['create-lesson', courseId],
    mutationFn: async () => {
      const teacher = editLessonMethods.getValues().teacher as
        | IOption
        | undefined;

      const newLesson: CourseLessonRequest = {
        ...editLessonMethods.getValues(),
        teacher: Number(teacher?.value) || undefined,
        date: moment(editLessonMethods.getValues().date).utc().toISOString(),
        estimatePaymentLimit: isDelegato(profile)
          ? editLessonMethods.getValues().estimatePaymentLimit
          : editLessonMethods.getValues().estimatePayment,
      };

      return updateCourseLesson({
        id: id!,
        body: _.omit(newLesson, 'lessonTemplate'),
        slidePdf: editLessonMethods.getValues().slidePdf
          ? editLessonMethods.getValues().slidePdf
          : undefined,
        token,
      });
    },
    onSuccess: async () => {
      toast.success('Modifiche salvate');
      queryClient.invalidateQueries(['course', courseId]);
      navigate(-1);
    },
    onError: async (e) => {
      toast.error('Errore durante il salvataggio delle modifiche');
    },
  });

  const onSubmit = async () => {
    updateLessonMutation();
  };

  const onFilesDelete = async (fileId?: number) => {
    //e' il file di strapi
    if (fileId) {
      //cancello il file su strapi
      try {
        await deleteMedia({ fileId, token });
        query.refetch();
      } catch (err) {
        console.log('err', err);
        toast.error("Qualcosa e' andato storto");
      }
    }
    //e' il file del form
    else {
      editLessonMethods.setValue('slidePdf', undefined);
    }
  };

  return (
    <>
      <TitlePage title='Dettaglio corso | Modifica lezione' />
      <div className='mt-6'>
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => {
            return <Spinner />;
          })
          .with({ status: 'error' }, () => {
            return <div>Errore...</div>;
          })
          .with({ status: 'success' }, (query) => {
            return (
              <FormProvider {...editLessonMethods}>
                <FormEditLesson
                  data={query.data?.data!}
                  editLessonMethods={editLessonMethods}
                  onFilesDelete={() =>
                    onFilesDelete(
                      query.data?.data?.attributes?.slidePdf?.data?.id
                    )
                  }
                />

                <div className='flex items-center justify-start gap-4'>
                  <PrimaryButton onClick={() => navigate(-1)} outline>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton
                    type='submit'
                    onClick={editLessonMethods.handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Salva modifica
                  </PrimaryButton>
                </div>
              </FormProvider>
            );
          })
          .exhaustive()}
      </div>
    </>
  );
}

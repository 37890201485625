import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const couponValidator = Yup.object().shape({
  title: Yup.string().required(ValidationErrorMessages.REQUIRED),
  price: Yup.number()
    .required(ValidationErrorMessages.REQUIRED)
    .typeError(ValidationErrorMessages.REQUIRED),
  startValidity: Yup.date()
    .typeError(ValidationErrorMessages.REQUIRED)
    .required(ValidationErrorMessages.REQUIRED),
  endValidity: Yup.date().when('startValidity', {
    is: (startValidity: any) => !!startValidity,
    then: Yup.date()
      .min(
        Yup.ref('startValidity'),
        '"Valido fino al" deve essere maggiore di "Valido dal"!'
      )
      .typeError(ValidationErrorMessages.REQUIRED)
      .required(ValidationErrorMessages.REQUIRED),
    otherwise: Yup.date()
      .required(ValidationErrorMessages.REQUIRED)
      .typeError(ValidationErrorMessages.REQUIRED),
  }),
  single: Yup.boolean().optional(),
  active: Yup.boolean().optional(),
});

import _ from 'lodash';
import { useLayoutEffect, useState } from 'react';
import { Profile, ProfileAttributes } from '../../interfaces/profile';
import { CostCenter, ReceiptType } from '../../interfaces/receipts';
import { IUser } from '../../interfaces/login';
import { isDelegato, isSegreteriaNazionale } from './auth';
import { Coupon } from '../../interfaces/coupon';
import QueryString, { ParsedQs } from 'qs';
import { CardHistoryType } from '../../interfaces/cardHistory';
import {
  EventParticipant,
  ParticipantsResponse,
} from '../../interfaces/participants';
import { CourseStatus, StrapiData } from '../../interfaces/commons';
import moment from 'moment';
import { EventStatus } from '../../interfaces/events';
import {
  Balance,
  HeadquarterBalance,
} from '../../interfaces/strapiComponents/balance';
import formatTableSort from '../../components/Table/helpers/formatTableSort';
import {
  ParticipantsAudienceForm,
  ParticipantsAudienceType,
} from '../../interfaces/audiences';
import { title } from 'process';
import { CourseParticipant } from '../../interfaces/courses';

/**
 * ritorna true se l'utente non ha compilato tutti i campi tranne titolo di studio, dove lavora e telefono alternativo
 * @param profile
 * @returns boolean
 */
export const shouldUserSubscribe = (profile?: Partial<Profile>) => {
  const sanitizedProfile = _.pick(profile?.attributes, [
    'address',
    'birthDate',
    'birthPlace',
    'city',
    'fiscalCode',
    'gender',
    'email',
    'homeAddress.address',
    'homeAddress.city',
    'homeAddress.nation',
    'homeAddress.province',
    'homeAddress.region',
    'homeAddress.zipcode',
    'homeAddress.streetNumber',
    'mobilePhone',
    'name',
    'nation',
    // 'profileImage',
    'province',
    'region',
    'section',
    'surname',
    'zipcode',
    'streetNumber',
    // "altPhone",
    // "qualification",
    // "workplace"
  ]);

  return (Object.entries(sanitizedProfile || {}).filter(([key, value]) => value)
    .length /
    Object.entries(sanitizedProfile || {}).length) *
    100 ===
    100
    ? true
    : false;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const formatCurrency = (amount?: number | string | null): string => {
  return `€ ${
    (amount &&
      !isNaN(Number(amount)) &&
      Number(amount).toFixed(2).replace('.', ',')) ||
    '-'
  }`;
};

export const formatReceiptType = (type: ReceiptType) => {
  switch (type) {
    case ReceiptType.COURSE:
      return 'Corso -';
    case ReceiptType.EVENT:
      return 'Evento -';
    default:
      return '-';
  }
};
export const PAYMENT_METHODS = [
  { id: 'PAYPAL', attributes: { name: 'Paypal' } },
  { id: 'AXERVE', attributes: { name: 'Axerve' } },
  { id: 'CASH', attributes: { name: 'Contanti' } },
  { id: 'BANK_CHECK', attributes: { name: 'Assegno' } },
  {
    id: 'BANK_TRANSFER_SECTION',
    attributes: { name: 'Bonifico a sezione' },
  },
  {
    id: 'BANK_TRANSFER_SITE',
    attributes: { name: 'Bonifico a sede' },
  },
  { id: 'BANCOMAT', attributes: { name: 'Bancomat' } },
  { id: 'PAYMENT_SLIP', attributes: { name: 'Bollettino' } },
];

export const getObjectPaths = (o: Record<string, any>) => {
  return _.transform(
    o,
    (acc: any[], v: any, k: any) => {
      const keys: any =
        _.isObject(v) && !_.isEmpty(v)
          ? _.map(getObjectPaths(v), (sk: any) => _.concat(k, ...sk))
          : [[k]];
      acc.push(...keys);
    },
    []
  );
};

export const checkProfile = (
  profile: (IUser & ProfileAttributes) | undefined,
  canDelegatoEdit: boolean | undefined
) => {
  if (canDelegatoEdit && isDelegato(profile)) {
    return false;
  } else if (!canDelegatoEdit && !isDelegato(profile)) {
    return false;
  } else if (!canDelegatoEdit && isDelegato(profile)) {
    return true;
  }
};

export const getCouponTotalHTML = (coupon?: Coupon) => {
  if (!coupon) return '-';
  return `${coupon.attributes.title}<br/>${formatCurrency(
    coupon.attributes.price
  )}`;
};

export const getGroupFilter = ({
  filterName,
  searchParams,
}: {
  filterName: string;
  searchParams: URLSearchParams;
}) => {
  const filter = searchParams.get(filterName);

  if (!filter) return undefined;

  return Object.entries(QueryString.parse(filter + ''))
    .map(([, value]) => value)
    .map((elem: any) => elem.value);
};

export const getQueryObjectForStrapiAPIs = ({
  obj,
  value,
}: {
  obj: object;
  value: unknown;
}) => {
  if (!value) {
    return undefined;
  }

  return obj;
};

export const getParticipantsQuery = ({
  queryParams,
  id,
  entityName,
}: {
  queryParams: URLSearchParams;
  id?: string;
  entityName: 'event' | 'course';
}) => {
  const sort = queryParams.get('sort');
  const populate = [
    `profile.orders.payment.receipt`,
    `profile.section`,
    `profile.orders.${entityName}`,
    `profile.orders.coupon`,
    `profile.badge`,
    `${entityName}`,
    `profile.user`,
    `profile.profileCategory`,
    `${entityName}.section`,
    `${entityName}.headquarterBalance.administrations.estimatedPriceUnit`,
  ];

  if (entityName === 'event') {
    populate.push('guests');
    populate.push('freeParticipant');
  }

  const result = {
    populate,
    filters: {
      [entityName]: id,
      status: getQueryObjectForStrapiAPIs({
        obj: {
          $in: getGroupFilter({
            filterName: 'subscriptionStatus',
            searchParams: queryParams,
          }),
        },
        value: queryParams.get('subscriptionStatus'),
      }),
      profile: {
        section: getQueryObjectForStrapiAPIs({
          obj: {
            id: {
              $in: getGroupFilter({
                filterName: 'sections',
                searchParams: queryParams,
              }),
            },
          },
          value: queryParams.get('sections'),
        }),
        $or: queryParams.get('search')
          ? [
              { name: { $contains: queryParams.get('search') } },
              { surname: { $contains: queryParams.get('search') } },
              {
                user: {
                  email: { $contains: queryParams.get('search') },
                },
              },
              {
                section: {
                  name: { $contains: queryParams.get('search') },
                },
              },
              {
                profileCategory: {
                  title: { $contains: queryParams.get('search') },
                },
              },
              {
                badge: {
                  badgeNumber: {
                    $contains: queryParams.get('search'),
                  },
                },
              },
              {
                orders: {
                  payment: {
                    method: { $contains: queryParams.get('search') },
                  },
                },
              },
              {
                orders: {
                  payment: {
                    amount: { $contains: queryParams.get('search') },
                  },
                },
              },
            ]
          : undefined,
        orders: {
          $and: [
            getQueryObjectForStrapiAPIs({
              obj: {
                payment: {
                  status: {
                    $in: getGroupFilter({
                      filterName: 'status',
                      searchParams: queryParams,
                    }),
                  },
                },
                [entityName]: id,
              },
              value: queryParams.get('status'),
            }),
          ].filter((_elem) => !_.isEmpty(_elem)),
        },
      },
    },
    pagination: {
      page: queryParams?.get('page'),
      pageSize: queryParams?.get('pageSize'),
    },
    sort: formatTableSort(sort),
  };

  return result;
};

export const sanitizeCurrencyValue = (
  val: string | number | null | undefined
): number => {
  const INVALID_TYPE_ERROR_MESSAGE = `Invalid currency value. expected string or number but got ${typeof val}`;
  const INVALID_NUMBER_FORMAT_ERROR_MESSAGE = `Invalid currency value. expected a number value, comma or dot separated, but got ${val}`;

  if (!['string', 'number'].includes(typeof val)) {
    throw new Error(INVALID_TYPE_ERROR_MESSAGE);
  }

  let sanitizedValue = Number(val);

  if (!Number.isNaN(sanitizedValue)) {
    return Number(val);
  }

  const stringNumber = String(val).replace(',', '.');

  if (Number.isNaN(stringNumber))
    throw new Error(INVALID_NUMBER_FORMAT_ERROR_MESSAGE);

  return Number(stringNumber);
};

export const getCardHistoryLabel = (
  cardHistoryType?: CardHistoryType
): string => {
  if (!cardHistoryType) {
    return '-';
  }

  const cardHistoryLabels = {
    [CardHistoryType.RINNOVO]: 'Rinnovo',
    [CardHistoryType.REISCRIZIONE]: 'Reiscrizione',
    [CardHistoryType.PRIMA_TESSERA]: 'Prima tessera',
  };

  return cardHistoryLabels[cardHistoryType];
};

export const getReceiptSection = (
  participant: ParticipantsResponse,
  nationalSectionId?: number
) => {
  const course = participant.attributes.course;
  const courseFee = participant.attributes.course?.data.attributes?.courseFee;
  if (courseFee === CostCenter.DELEGATION) {
    return course?.data.attributes?.section?.data?.id;
  }

  return nationalSectionId;
};

export const getEventParticipantFirstName = (
  participant?: EventParticipant
): string => {
  if (!participant) return '-';
  const { isFreeParticipant, freeParticipant } = participant;
  if (!isFreeParticipant) {
    return `${participant?.profile?.data?.attributes?.name}`;
  }

  return `${freeParticipant?.firstName}`;
};

export const getEventParticipantLastName = (
  participant?: EventParticipant
): string => {
  if (!participant) return '-';
  const { isFreeParticipant, freeParticipant } = participant;
  if (!isFreeParticipant) {
    return `${participant?.profile?.data?.attributes?.surname}`;
  }

  return `${freeParticipant?.lastName}`;
};

export const getEventParticipantsListNames = (
  participants?: Partial<
    StrapiData<EventParticipant> & { multiple?: boolean | undefined }
  >[]
): string => {
  if (!participants) return '-';

  return participants
    ?.map((_participant) =>
      getEventParticipantFirstName(_participant.attributes)
    )
    .join(', ');
};

export const getDateTimeFromDate = (
  date: string | string[] | ParsedQs | ParsedQs[] | undefined
) => {
  if (!date || typeof date !== 'string') return undefined;

  return moment(date).tz('Europe/Rome').add(1, 'days').toISOString();
};

export const isEventCostsFormEnabled = (
  status: EventStatus | undefined | null
): boolean => {
  return [EventStatus.DRAFT, EventStatus.PUBLISHED, null, undefined].includes(
    status
  );
};

export const isEventBalanceEnabledForEdit = (
  status: EventStatus | undefined | null,
  profile: (IUser & ProfileAttributes) | undefined
) => {
  const isUserSegreteriaNazionale = isSegreteriaNazionale(profile);

  return (
    isUserSegreteriaNazionale && status === EventStatus.FINAL_BALANCE_DELEGATION
  );
};

export const isCourseCostsFormEnabled = (
  status: EventStatus | undefined | null
): boolean => {
  return [EventStatus.DRAFT, EventStatus.PUBLISHED, null, undefined].includes(
    status
  );
};

export const isCourseBalanceEnabledForEdit = (
  status: CourseStatus | undefined | null,
  profile: (IUser & ProfileAttributes) | undefined
) => {
  const isUserSegreteriaNazionale = isSegreteriaNazionale(profile);

  return (
    isUserSegreteriaNazionale &&
    status === CourseStatus.FINAL_BALANCE_DELEGATION
  );
};

export const flattenObject = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keyParts = key.split('.');
      let tempObj: any = newObj;
      for (let i = 0; i < keyParts.length; i++) {
        const currentKey = keyParts[i];
        if (i === keyParts.length - 1) {
          tempObj[currentKey] = obj[key];
        } else {
          tempObj[currentKey] = tempObj[currentKey] || {};
          tempObj = tempObj[currentKey];
        }
      }
    }
  }
  return newObj;
};

export const sumObjectsValues = (obj1: any, obj2: any) => {
  const resultObject: any = {};

  for (let key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      resultObject[key] =
        _.toNumber(obj1[key] || 0) + _.toNumber(obj2[key] || 0);
    } else {
      resultObject[key] = _.toNumber(obj1[key] || 0);
    }
  }

  for (let key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      resultObject[key] = _.toNumber(obj2[key] || 0);
    }
  }

  return resultObject;
};

export const getEntityBalanceTotals = (
  balance: Partial<Omit<Balance | HeadquarterBalance, 'id'>>,
  attributesListToExclude?: Array<string>
): any => {
  return Object.entries(_.omit(balance, attributesListToExclude || []))
    .map(([, value]) => value)
    .reduce(
      (acc: any, value: any) =>
        sumObjectsValues(_.omit(value, ['notes']), _.omit(acc, ['notes'])),
      {}
    );
};

export const calcoloTotSpeseSezioneSede = (balance: Omit<Balance, 'id'>) => {
  const speseSezioneSede: any = _.pick(balance, [
    'teacherPayments',
    'teacherRefunds',
    'direction',
  ]);

  const { finalOutflow } = getEntityBalanceTotals(speseSezioneSede);

  return finalOutflow;
};

export const calcoloTotSpeseSezioneSezione = (balance: Omit<Balance, 'id'>) => {
  const speseSezioneSezione: any = _.omit(balance, [
    'teacherPayments',
    'teacherRefunds',
    'direction',
  ]);

  const { finalOutflow } = getEntityBalanceTotals(speseSezioneSezione);

  return finalOutflow;
};

export const calcoloTotSpeseSedeSede = (
  headquarterBalance: Omit<HeadquarterBalance, 'id'>
) => {
  const speseSezioneSede: any = _.pick(headquarterBalance, [
    'shipping',
    'teachingMaterial',
  ]);

  const { finalOutflow } = getEntityBalanceTotals(speseSezioneSede);

  return finalOutflow;
};

export const getParticipantsAudienceQuery = ({
  query,
  formValues,
  selectedProfilesIds,
}: {
  query: object;
  formValues: ParticipantsAudienceForm;
  selectedProfilesIds: (number | undefined)[];
}) => {
  if (formValues.type === ParticipantsAudienceType.WHOLE)
    return { title: formValues.title, audienceQuery: query };

  console.log('selectedProfilesIds', selectedProfilesIds);

  const selectedProfilesFilters = {
    profile: { id: { $in: selectedProfilesIds.filter(Boolean) } },
  };

  return {
    title: formValues.title,
    audienceQuery: { ...query, filters: selectedProfilesFilters },
  };
};

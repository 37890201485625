import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";


interface BackButtonProps {
  label?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ label = 'Indietro' }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className="text-base flex items-center gap-2 w-max whitespace-nowrap text-gray-700 font-medium hover:text-primary"
    >
      <ChevronLeftIcon className="w-5 h-5 inline-block" />
      <p>{label}</p>
    </button>
  );
};

export default BackButton;

import RenewCardModal from '../../../components/Modals/RenewCardModal';
import classNames from '../../../libs/utils/classNames';
import { useAuth } from '../../../contexts/Auth';
import { useParams, useSearchParams } from 'react-router-dom';
import { Profile } from '../../../interfaces/profile';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';
import { isDelegato } from '../../../libs/utils/auth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import orders from '../../../api/orders';
import { Order, ProductType } from '../../../interfaces/orders';
import { PaymentStatus } from '../../../interfaces/payments';
import PaymentBadge from '../../../components/PaymentBadge';
import FullPageSpinner from '../../../components/Layout/Loading/FullPageSpinner';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';
import receipts from '../../../api/receipts';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import DisconnectOrderModal from '../../../components/Modals/DisconnectOrderModal';
import { AppSettings } from '../../../interfaces/appSettings';
import moment from 'moment';
import { useMemo } from 'react';
import CoccardaLeft from '../../../icons/coccarda-left';
import CoccardaRight from '../../../icons/coccarda-right';

const tableElements = ['Anno', 'Pagato/Rinnovato', 'Data rinnovo', ' ', ''];

const UtenteRinnovoTesseraTable = ({ infoUser }: { infoUser: Profile }) => {
  const [{ profile, token, settings }] = useAuth();
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, 500);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const notPaid = searchParams.get('notPaid');

  const registrationDate = new Date(infoUser?.attributes.createdAt || '');
  const registrationYear = registrationDate.getFullYear();
  const currentYear = new Date().getFullYear();
  const yearsPassed = currentYear - registrationYear + 1;

  const { data: subscriptionsData, isLoading: isGettingOrders } = useQuery(
    ['getOrdersForCardHistories', debounceSearchParams.get('notPaid')],
    () =>
      orders.find({
        token,
        query: {
          populate: ['cardHistory', 'payment.receipt', 'profile'],
          filters: {
            profile: id,
            productType: ProductType.SUBSCRIPTION,
          },
          pagination: {
            pageSize: 200,
          },
        },
      })
  );

  const subRows = useMemo(
    () =>
      getRows(subscriptionsData?.data, settings).filter((_subscription) =>
        notPaid
          ? _subscription.attributes.payment?.data.attributes.status !==
            PaymentStatus.COMPLETED
          : true
      ),
    [subscriptionsData, settings]
  );

  // ---- CIò CHE è COMMENTATO è PER LA SELEZIONE MULTIPLA (DA FARE PIù AVANTI) ---- //

  // const [open, setOpen] = useState(false);
  // const [checked, setChecked] = useState(false);
  // let checkbox = useRef<{ indeterminate: boolean } | undefined>();
  // const [indeterminate, setIndeterminate] = useState(false);
  // useLayoutEffect(() => {
  //   const isIndeterminate =
  //     selectedCards.length > 0 && selectedCards.length < cards.length;
  //   setChecked(selectedCards.length === cards.length);
  //   setIndeterminate(isIndeterminate);
  //   checkbox.current = { indeterminate: isIndeterminate };
  // }, [selectedCards]);

  // function toggleAll() {
  //   setSelectedCards(checked || indeterminate ? [] : cards);
  //   setChecked(!checked && !indeterminate);
  //   setIndeterminate(false);
  // }

  const { mutate: mutateDownloadReceipt } = useMutation(
    'downloadUserReceipt',
    receipts.downloadReceipt,
    {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto');
      },
      onSuccess: () => {
        toast.success('Ricevuta scaricata con successo');
      },
    }
  );

  const { mutate: mutateBulkCreateReceipt, isLoading: isCreatingReceipt } =
    useMutation('downloadUserReceipt', receipts.bulkCreate, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['getOrdersForCardHistories']);
        toast.success('Ricevuta creata con successo');
      },
    });

  return (
    <div className='overflow-x-auto'>
      {
        <div className='px-3 py-6 space-y-4 rounded-md bg-neutral-50 mb-4'>
          <div className='relative flex items-center justify-center gap-2 text-center'>
            <CoccardaLeft property='h-28 w-auto' />
            <h5 className='text-lg lowercase font-IBM text-primary'>
              <span className='font-semibold text-4.5xl'>
                {infoUser.attributes?.yearsInONAV}
              </span>
              <br />
              anni
              <br />
              consecutivi
            </h5>
            <CoccardaRight property='h-28 w-auto' />
          </div>
          <p className='text-center text-gray-800 tracking-custom'>in ONAV</p>
        </div>
      }
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='relative overflow-hidden border ring-1 ring-black ring-opacity-5'>
          {/* NB solo la segreteria nazionale può rinnovare manualmente il pagamento degli anni di tesseramento, il delegato di sezione può solamente scaricare la ricevuta. 
              Nascondere quindi  il bottone "Rinnova Selezionati" e la possibilità di fare check sulle righe agli utenti di tipologia delegato di sezione */}
          {/* {selectedCards.length > 0 && (
            <div className="absolute top-0 flex items-center space-x-3 left-12 h-11 bg-gray-50 sm:left-16">
              <RenewCardModal buttonType="header" yearRevenew="2021, 2023" />
            </div>
          )} */}
          {!isGettingOrders && (
            <table className='min-w-full divide-y divide-gray-300 table-fixed'>
              <thead className='text-sm text-gray-500 uppercase bg-gray-50'>
                <tr className='custom-padding-table'>
                  {/* <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                  <input
                    type="checkbox"
                    className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-primary focus:ring-primary sm:left-6"
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th> */}
                  {tableElements.map((element, idx) => (
                    <th
                      key={idx}
                      className='px-3 py-3 font-normal tracking-wide text-left'
                    >
                      {element}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {subRows?.map((_subscription) => (
                  <tr key={_subscription.id}>
                    <td
                      className={classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm px-4',
                        'text-gray-600'
                      )}
                    >
                      {
                        _subscription.attributes?.cardHistory?.data.attributes
                          ?.year
                      }
                    </td>
                    <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
                      <PaymentBadge
                        className='!justify-start ml-4'
                        status={
                          _subscription.attributes?.payment?.data.attributes
                            ?.status
                        }
                      />
                    </td>
                    <td
                      className={classNames(
                        'whitespace-nowrap py-4 px-3 text-sm',
                        // selectedCards.includes(_subscription)
                        //   ? 'text-primary'
                        'text-gray-600'
                      )}
                    >
                      {_subscription?.attributes?.payment?.data.attributes
                        .status !== 'DRAFT' &&
                        _subscription?.attributes?.cardHistory?.data?.attributes
                          .updatedAt &&
                        formatDate(
                          _subscription?.attributes?.payment?.data.attributes
                            .date
                        )}
                    </td>
                    <td
                      className={classNames(
                        'whitespace-nowrap py-4 px-3 text-sm',
                        // selectedCards.includes(_subscription)
                        //   ? 'text-primary'
                        'text-gray-600'
                      )}
                    >
                      <DisconnectOrderModal subscription={_subscription} />
                    </td>
                    <td className='flex items-center justify-end gap-3 px-3 py-4 whitespace-nowrap'>
                      {_subscription.attributes.payment?.data?.attributes
                        ?.status === PaymentStatus.COMPLETED ? (
                        // Per ordini con pagamenti multipli intervenire qui
                        <>
                          {_subscription.attributes?.payment?.data.attributes
                            ?.receipt?.data?.id && (
                            <UnderlineButton
                              small
                              onClick={() =>
                                mutateDownloadReceipt({
                                  token,
                                  body: {
                                    receipts: [
                                      _subscription.attributes?.payment?.data
                                        .attributes?.receipt?.data?.id,
                                    ],
                                  },
                                  fileName: `Ricevute utente ${_subscription.attributes.profile.data?.attributes.name} ${_subscription.attributes.profile.data?.attributes.surname}`,
                                })
                              }
                            >
                              {'Scarica ricevuta'}
                            </UnderlineButton>
                          )}
                          {!_subscription.attributes?.payment?.data.attributes
                            ?.receipt?.data?.id && (
                            <UnderlineButton
                              small
                              onClick={() =>
                                mutateBulkCreateReceipt({
                                  token,
                                  body: {
                                    orderIds: [_subscription.id],
                                  },
                                })
                              }
                              disabled={isCreatingReceipt}
                            >
                              {'Crea ricevuta'}
                            </UnderlineButton>
                          )}
                        </>
                      ) : (
                        !isDelegato(profile) && (
                          <RenewCardModal
                            buttonType='table'
                            infoUser={infoUser}
                            subscription={_subscription}
                            defaultYear={
                              _subscription.attributes.cardHistory.data
                                .attributes.year
                            }
                          />
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isGettingOrders && <FullPageSpinner />}
    </div>
  );
};

const getRows = (
  data:
    | {
        attributes: Order;
        id: number;
      }[]
    | undefined,
  appSettings?: AppSettings
) => {
  const currentYear = new Date().getFullYear();
  if (!data || data.length === 0) {
    const result = [
      {
        id: 0,
        attributes: {
          cardHistory: { data: { attributes: { year: currentYear } } },
        },
      } as {
        attributes: Order;
        id: number;
      },
    ];

    const [day, month] = appSettings?.displayNextSubscriptionDayMonth?.split(
      '/'
    ) || [moment().day(), moment().month()];

    if (
      moment(`${month}/${day}/${moment().year()}`).isSameOrBefore(
        moment().toISOString()
      )
    ) {
      result.push({
        id: 1,
        attributes: {
          cardHistory: { data: { attributes: { year: currentYear + 1 } } },
        },
      } as {
        attributes: Order;
        id: number;
      });
    }
    return result.sort(
      (a, b) =>
        b.attributes.cardHistory.data.attributes.year -
        a.attributes.cardHistory.data.attributes.year
    );
  }

  let startYear =
    Number(data?.length) > 0
      ? data
          ?.map((elem) => elem.attributes?.cardHistory?.data?.attributes?.year)
          .reduce((a, b) => Math.min(a, b)) || new Date().getFullYear()
      : new Date().getFullYear();
  //quando si può rinnovare la nuova tessera aggiungiamo un anno.
  if (startYear > currentYear) {
    startYear = currentYear;
  }
  let yearOffset = 0;
  const [day, month] = appSettings?.displayNextSubscriptionDayMonth?.split(
    '/'
  ) || [moment().day(), moment().year()];

  if (
    moment(`${month}/${day}/${new Date().getFullYear()}`).isSameOrBefore(
      moment()
    )
  ) {
    yearOffset = 1;
  }

  const result = Array.from(
    { length: new Date().getFullYear() + 1 + yearOffset - Number(startYear) },
    (v, k) => {
      return (
        data?.find(
          (elem) =>
            elem.attributes?.cardHistory?.data?.attributes?.year ===
            startYear + k
        ) ||
        ({
          id: k,
          attributes: {
            cardHistory: { data: { attributes: { year: startYear + k } } },
          },
        } as {
          attributes: Order;
          id: number;
        })
      );
    }
  ).sort(
    (a, b) =>
      b.attributes.cardHistory.data.attributes.year -
      a.attributes.cardHistory.data.attributes.year
  );

  return result;
};

export default UtenteRinnovoTesseraTable;

import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import IconButton from '../Buttons/IconButton';
import { PencilIcon } from '@heroicons/react/outline';
import { useFormContext } from 'react-hook-form';
import { EventRequest, EventStatus } from '../../interfaces/events';
import { useAuth } from '../../contexts/Auth';
import { isSegreteriaNazionale } from '../../libs/utils/auth';

const eventStatus = [
  { id: 'draft', title: 'Bozza' },
  { id: 'published', title: 'Pubblicato' },
  { id: 'final_balance_delegation', title: 'Consuntivato da sezione' },
  {
    id: 'final_balance_national',
    title: 'Consuntivato da segreteria nazionale',
  },
  { id: 'canceled', title: 'Annullato' },
];
interface StatusEventModalProps {
  save: (status: EventStatus) => () => void;
}

const StatusEventModal: React.FC<StatusEventModalProps> = ({ save }) => {
  const { watch } = useFormContext<EventRequest>();

  const [open, setOpen] = useState(false);
  const [{ profile }] = useAuth();
  const [status, setStatus] = useState<string>(watch('status'));

  const onSave = () => {
    setOpen(false);
    save(status as EventStatus)();
  };

  return (
    <>
      {isSegreteriaNazionale(profile) && status !== EventStatus.CANCELED && (
        <IconButton orangeTxt onClick={() => setOpen(true)}>
          <PencilIcon className='w-6 h-6' />
        </IconButton>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM text-gray-700 mb-6'>
                  Stato dell'evento
                </h4>

                <fieldset className='mt-4'>
                  <div className='space-y-4'>
                    {eventStatus.map((eventStatus) => (
                      <div key={eventStatus.id} className='flex items-center'>
                        <input
                          id={eventStatus.id}
                          name='notification-method'
                          type='radio'
                          defaultChecked={eventStatus.id === watch('status')}
                          onChange={(radioButtonOnChange) =>
                            setStatus(radioButtonOnChange.target.id)
                          }
                          className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
                        />
                        <label
                          htmlFor={eventStatus.id}
                          className='ml-3 block font-normal text-gray-700'
                        >
                          {eventStatus.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton small onClick={onSave}>
                    Salva
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default StatusEventModal;

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PrimaryButton from "../Buttons/PrimaryButton";
import IconButton from "../Buttons/IconButton";
import { TrashIcon } from "@heroicons/react/outline";

interface DeleteLessonModalProps {
  titleLesson: string;
  buttonTxt?: string;
  buttonType: "text" | "icon";
  onConfirm: () => void;
}

const DeleteLessonModal: React.FC<DeleteLessonModalProps> = ({
  titleLesson,
  buttonTxt = "Elimina",
  buttonType,
  onConfirm,
}) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const handleDelete = () => {
    onConfirm();
    closeModal();
  };
  return (
    <>
      {buttonType === "text" && (
        <PrimaryButton textSmall onClick={openModal}>
          <span className='font-medium'>{buttonTxt}</span>
        </PrimaryButton>
      )}

      {buttonType === "icon" && (
        <IconButton redTxt onClick={openModal}>
          <TrashIcon className='w-7 h-7' />
        </IconButton>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Elimina template lezione{" "}
                  <span className='font-medium'>{titleLesson}</span>
                </h4>

                <div className='bg-sand-50 border-2 border-sand-100 px-4 py-6'>
                  <p className='text-gray-700'>
                    Sei sicuro di voler eliminare il template lezione{" "}
                    <span className='font-medium italic text-primary'>
                      {titleLesson}
                    </span>
                    ?
                    <br />
                    <br />
                    L'azione sarà irreversibile.
                  </p>
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton textSmall onClick={() => setOpen(false)}>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton small onClick={handleDelete}>
                    Elimina template lezione
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteLessonModal;

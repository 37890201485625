import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useMutation, useQuery } from 'react-query';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useAuth } from '../../../../contexts/Auth';

import {
  announcmentInfo,
  sendAnnouncement,
  updateAnnouncment,
} from '../../../../api/announcments';

import DettaglioComunicato from './dettaglio-comunicato';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import H1Styled from '../../../../components/Typography/H1Styled';
import FormLoading from '../../../../components/Layout/Loading/FormLoading';
import BackButton from '../../../../components/Buttons/BackButton';
import Spinner from '../../../../components/Layout/Loading/Spinner';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { announcementValidator } from '../../../../validators/announcement';
import { AnnouncmentRequest } from '../../../../interfaces/announcments';
import { deleteMedia } from '../../../../libs/utils/media';

const ModificaComunicato = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [{ token }] = useAuth();
  const [loading, setLoading] = useState(false);

  const updateAnnouncementMethods = useForm<AnnouncmentRequest>({
    resolver: useYupValidationResolver(announcementValidator),
  });
  const { handleSubmit, reset } = updateAnnouncementMethods;

  const announcementQuery = useQuery(['announcment', id], () =>
    announcmentInfo(Number(id), token)
  );

  const { mutate } = useMutation(updateAnnouncment, {
    onSuccess: () => {
      setLoading(false);
      toast.success('Comunicato aggiornato con successo');
      navigate('/comunicati');
    },
    onError: (error: any) => {
      setLoading(false);
      toast.error(error.response?.data?.error?.message);
    },
  });

  const { mutate: sendAnnouncment } = useMutation({
    mutationFn: () => {
      setLoading(true);
      return sendAnnouncement({ id, token });
    },
    onSuccess: () => {
      setLoading(false);
      toast.success("L'invio del comunicato è stato preso in carico!");
      navigate('/comunicati');
    },
    onError: (e: any) => {
      setLoading(false);
      toast.error(e.response?.data?.error?.message);
    },
  });

  const updateAnnouncement = (data: FieldValues) => {
    setLoading(true);

    mutate({ id: Number(id), data: data, token });
  };

  const onFilesDelete = async (fileId?: number) => {
    //e' il file di strapi
    if (fileId) {
      //cancello il file su strapi
      try {
        await deleteMedia({ fileId, token });
        announcementQuery.refetch();
      } catch (err) {
        console.log('err', err);
        toast.error("Qualcosa e' andato storto");
      }
    }
    //e' il file del form
    else {
      updateAnnouncementMethods.setValue('pdf', undefined);
    }
  };

  useEffect(() => {
    reset({
      title: announcementQuery.data?.data.attributes.title,
      audience: announcementQuery.data?.data.attributes.audience.data.id,
      description: announcementQuery.data?.data.attributes.description,
      pdf: announcementQuery.data?.data.attributes.pdf,
    });
  }, [announcementQuery.data]);

  return (
    <>
      <div>
        {match(announcementQuery)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <FormLoading />
          ))
          .with({ status: 'error' }, () => <div>Errore...</div>)
          .with({ status: 'success' }, (query) => {
            const { data: announcement } = query.data;

            const getStatus = () => {
              switch (announcement.attributes.status) {
                case 'draft':
                  return 'BOZZA';
                case 'scheduled':
                  return 'PROGRAMMATO';
                case 'sent':
                  return 'INVIATO';
                default:
                  return;
              }
            };

            return (
              <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
                <BackButton label='Indietro' />
                <FormProvider {...updateAnnouncementMethods}>
                  <form onSubmit={handleSubmit(updateAnnouncement)}>
                    <div className='flex justify-between mt-5'>
                      <H1Styled>
                        {announcement.attributes.status !== 'sent'
                          ? 'Modifica'
                          : 'Visualizza'}{' '}
                        Comunicato
                      </H1Styled>
                      <div className='flex items-center h-10'>
                        {announcement.attributes.status !== 'sent' &&
                        announcement.attributes.status !== 'scheduled' ? (
                          <>
                            <div className='mr-6'>
                              <PrimaryButton type='submit' outline>
                                Salva
                              </PrimaryButton>
                            </div>
                            {announcement.attributes.status === 'draft' && (
                              <PrimaryButton
                                type='button'
                                onClick={sendAnnouncment}
                                disabled={loading}
                                isLoading={loading}
                              >
                                Invia
                              </PrimaryButton>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className='flex items-center justify-end mr-8'>
                      <p className='text-lg text-gray-500'>
                        STATO:
                        <span className='ml-3 font-bold text-gray-800 uppercase'>
                          {getStatus()}
                        </span>
                      </p>
                    </div>

                    <div className='pt-12'>
                      <DettaglioComunicato
                        announcmentData={announcement}
                        formMethods={updateAnnouncementMethods}
                        onFilesDelete={() =>
                          onFilesDelete(query.data.data.attributes.pdf.data.id)
                        }
                      />
                    </div>
                  </form>
                </FormProvider>
              </div>
            );
          })
          .exhaustive()}
      </div>
    </>
  );
};
export default ModificaComunicato;

import { useFormContext } from 'react-hook-form';
import TextAreaPresentational from '../../../../components/Form/TextAreaPresentational';
import TitlePage from '../../../../components/TitlePage';
import { Section, SectionRequest } from '../../../../interfaces/section';

export default function Firma({ sectionData }: { sectionData: Section }) {
  const { register, control } = useFormContext<SectionRequest>();
  return (
    <>
      <TitlePage title='Modifica Sezione | Firma ' />

      <div className='mt-6'>
        <div className='my-10'>
          <TextAreaPresentational
            id='signature'
            label='Firma'
            name='signature'
            register={register}
            control={control}
            defalutValue={sectionData.attributes.signature}
          />
        </div>
      </div>
    </>
  );
}

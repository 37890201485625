import SelectPresentational from '../Form/SelectPresentational';

const RoleSelect = ({
  defaultValue,
  control,
  disabled,
}: {
  defaultValue?: number | string;
  control?: any;
  disabled?: boolean;
}) => {
  return (
    <SelectPresentational
      name='role'
      label='Ruolo di amministrazione nel software'
      defaultValue={defaultValue}
      disabled={disabled}
      control={control}
      options={[
        { value: 'Authenticated', label: 'Nessun ruolo' },
        { value: 'Delegato di sezione', label: 'Delegato di sezione' },
        { value: 'Segreteria nazionale', label: 'Segreteria nazionale' },
        { value: 'Delegato di regione', label: 'Delegato di regione' },
      ]}
    />
  );
};

export default RoleSelect;

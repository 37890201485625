import { useNavigate } from 'react-router-dom';
import React from 'react';
import Badge from '../../../components/Badge';
import { UseQueryResult } from 'react-query';
import { AnnouncmentsListResponse } from '../../../interfaces/announcments';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import IconButton from '../../../components/Buttons/IconButton';
import { PencilIcon } from '@heroicons/react/outline';
import DeleteAnnouncmentModal from '../../../components/Modals/DeleteAnnouncmentModal';
import moment from 'moment';

interface ComunicatiTableProps {
  query: UseQueryResult<AnnouncmentsListResponse>;
}
const ComunicatiTable: React.FC<ComunicatiTableProps> = ({ query }) => {
  const tableElements = ['Titolo', 'Data invio', 'Audience', 'Stato', ''];

  const navigate = useNavigate();

  const handleDetailNavigation = (id: number, e: any) => {
    // if (!e) var e = window.event;                // Get the window event
    e.cancelBubble = true; // IE Stop propagation
    if (e.stopPropagation) e.stopPropagation(); // Other Broswers
    navigate(`dettaglio-comunicato/${id}`);
  };

  // if query status is error automatically refresh page
  if (query.status === 'error') {
    window.location.reload();
  }

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'draft':
        return <Badge gray text='Bozza' />;
      case 'scheduled':
        return <Badge yellow text='Programmato' />;
      case 'sent':
        return <Badge green text='Inviato' />;
      default:
        return;
    }
  };

  return (
    <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='inline-block min-w-full px-4 py-2 align-middle sm:px-6 lg:px-8'>
        <div className='overflow-hidden border ring-1 ring-black ring-opacity-5 '>
          {match(query)
            .with(
              { status: 'idle' },
              { status: 'loading' },
              { status: 'error' },
              () => <TableLoading tableElements={tableElements} />
            )
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='text-sm text-gray-500 uppercase bg-gray-50'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='px-6 py-3 font-normal tracking-wide text-left'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='items-center bg-white divide-y divide-gray-200'>
                      {query.data.data.map((announcment, index) => (
                        <tr
                          key={index}
                          className='cursor-pointer hover:bg-neutral-100'
                          onClick={() => {
                            navigate(`dettaglio-comunicato/${announcment.id}`);
                          }}
                        >
                          <td className='items-center px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {announcment.attributes.title}
                          </td>
                          <td className='items-center px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap'>
                            {announcment.attributes.send_at
                              ? moment(announcment.attributes.send_at).format(
                                  'DD/MM/YYYY H:mm'
                                )
                              : null}
                          </td>
                          <td className='items-center px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {
                              announcment.attributes?.audience?.data?.attributes
                                ?.title
                            }
                          </td>
                          <td className='items-center py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {getStatusBadge(announcment.attributes.status)}
                          </td>

                          <td
                            className='flex items-center justify-end gap-3 pr-6 py-4 text-sm text-gray-500 whitespace-nowrap'
                            onClick={(event: any) => event.stopPropagation()}
                          >
                            <IconButton
                              orangeTxt={
                                announcment.attributes.status === 'draft'
                              }
                              onClick={() => {
                                if (announcment.attributes.status !== 'draft')
                                  return;
                                navigate(
                                  `dettaglio-comunicato/${announcment.id}`
                                );
                              }}
                            >
                              <PencilIcon className='w-6 h-6' />
                            </IconButton>

                            <DeleteAnnouncmentModal
                              allowDelete={
                                announcment.attributes.status === 'draft'
                              }
                              AnnouncmentId={announcment.id}
                              titleAnnouncment={announcment.attributes.title}
                              buttonType='icon'
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='my-20 text-center text-gray-500'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default ComunicatiTable;

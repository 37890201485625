import React from 'react';
import { useMutation, UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import { deleteJob } from '../../../api/section';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import DeleteModal from '../../../components/Modals/DeleteModal';
import IconLink from '../../../components/TextLink/IconLink';
import { useAuth } from '../../../contexts/Auth';
import { StrapiResponseList } from '../../../interfaces/commons';
import { SectionJobs } from '../../../interfaces/section';
import { PencilIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { isDelegato } from '../../../libs/utils/auth';
import classNames from '../../../libs/utils/classNames';
import getOnavPositionLabel from '../helpers/getOnavPositionLabel';

interface MansioniTableProps {
  mansioniQuery?: UseQueryResult<StrapiResponseList<SectionJobs>>;
}
const MansioniTable: React.FC<MansioniTableProps> = ({ mansioniQuery }) => {
  const [{ token, profile }] = useAuth();
  const navigate = useNavigate();

  const tableElements = [
    'Ruolo istituzionale',
    // 'Mansione',
    'Nominativo',
    'N. Tessera',
    '',
    '',
  ];

  const { mutate: deleteJobMutation } = useMutation('deleteJob', deleteJob, {
    onSuccess: () => {
      mansioniQuery?.refetch();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {mansioniQuery &&
            match(mansioniQuery)
              .with({ status: 'idle' }, { status: 'loading' }, () => {
                return <TableLoading tableElements={tableElements} />;
              })
              .with({ status: 'error' }, () => {
                return <div>Qualcosa e' andato storto...</div>;
              })
              .with({ status: 'success' }, (query) => {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element) => (
                          <th className='font-normal px-6 py-3 text-left tracking-wide'>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query?.data?.data?.map((task) => (
                        <tr
                          key={task?.id}
                          className={classNames(
                            !isDelegato(profile)
                              ? 'cursor-pointer hover:bg-neutral-100'
                              : ''
                          )}
                          onClick={() => {
                            if (!isDelegato(profile)) navigate(`${task?.id}`);
                            return;
                          }}
                        >
                          <td className='whitespace-nowrap px-6 py-4 text-sm  text-gray-500'>
                            {getOnavPositionLabel(
                              task?.attributes?.onavPosition?.data?.attributes
                                ?.role
                            )}
                          </td>
                          {/* <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {task.attributes.job}
                          </td> */}

                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
                            {task?.attributes?.profile?.data?.attributes
                              ?.name &&
                            task?.attributes?.profile?.data?.attributes?.surname
                              ? task?.attributes?.profile?.data?.attributes
                                  ?.name +
                                ' ' +
                                task?.attributes?.profile?.data?.attributes
                                  ?.surname
                              : '-'}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 '>
                            {task?.attributes?.profile?.data?.attributes?.badge
                              ?.data?.attributes?.badgeNumber
                              ? task?.attributes?.profile?.data?.attributes
                                  ?.badge?.data?.attributes?.badgeNumber
                              : '-'}
                          </td>
                          {!isDelegato(profile) && (
                            <td className='whitespace-nowrap px-6 py-4 '>
                              <IconLink orangeTxt href={`${task?.id}`}>
                                <PencilIcon className='w-6 h-6' />
                              </IconLink>
                            </td>
                          )}

                          <td
                            className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'
                            onClick={(event) => event.stopPropagation()}
                          >
                            <DeleteModal
                              item={`${task?.attributes?.profile?.data?.attributes?.name} ${task?.attributes?.profile?.data?.attributes?.surname} - ${task?.attributes?.onavPosition?.data?.attributes?.name}`}
                              title='Elimina Ruolo istituzionale'
                              description='Sei sicuro di voler eliminare il ruolo istituzionale'
                              buttonType='icon'
                              onConfirm={() =>
                                deleteJobMutation({ token, id: task?.id })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              })
              .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default MansioniTable;

import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';

import { findEventById, updateEvent } from '../../../api/events';
import HeaderTitle from '../../../components/HeaderTitle';
import StatusEventModal from '../../../components/Modals/StatusEventModal';
import TabBar from '../../../components/TabBars/TabBar';
import { useAuth } from '../../../contexts/Auth';
import { StrapiResponse } from '../../../interfaces/commons';
import {
  EventRequest,
  EventResponse,
  EventStatus,
} from '../../../interfaces/events';
import { isDelegato, isSegreteriaNazionale } from '../../../libs/utils/auth';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { newEventValidator } from '../../../validators/event';
import { emptyEvent } from '../utils/emptyItems';
import CostiEvento from './tabs/costi';
import DatiPrincipaliEvento from './tabs/dati-principali';
import LocationEvento from './tabs/location';
import PartecipantiEvento from './tabs/partecipanti';
import RicevuteEvento from './tabs/ricevute';
import CreaRicevutaCorso from '../../Corsi/DettaglioCorso/crea-ricevuta';
import CreaRicevutaTemplateCorso from '../../Corsi/DettaglioCorso/crea-ricevuta-template';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';
import BaseModal from '../../../components/Modals/BaseModal';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { deleteMedia } from '../../../libs/utils/media';
import { uploadToStrapi } from '../../../api/courses';
import FullscreenSpinner from '../../../components/Layout/Loading/FullscreenSpinner';
import locationsAPIs from '../../../api/locationsAPIs';
import _ from 'lodash';
// import RicevuteEvento from './tabs/ricevute';

const getTabs = (eventId: string | number) => [
  {
    label: 'Dati principali',
    href: '/eventi/modifica/' + eventId + '',
  },
  {
    label: 'Location',
    href: '/eventi/modifica/' + eventId + '/location',
  },
  {
    label: 'Partecipanti',
    href: '/eventi/modifica/' + eventId + '/partecipanti',
  },
  {
    label: 'Costi',
    href: '/eventi/modifica/' + eventId + '/costi',
  },
  {
    label: 'Ricevute',
    href: '/eventi/modifica/' + eventId + '/ricevute',
  },
];

const ModificaEvento = () => {
  const navigate = useNavigate();
  const [{ token, profile }] = useAuth();
  const { eventId } = useParams();
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const updateEventFormMethods = useForm<EventRequest>({
    defaultValues: emptyEvent,
    resolver: useYupValidationResolver(newEventValidator),
  });

  const findEventQueryFn = () => findEventById(eventId!, token);

  const onFindEventQuerySuccess = ({ data }: StrapiResponse<EventResponse>) => {
    const eventAttributes = data.data.attributes;

    const eventFormValues: EventRequest = {
      ...eventAttributes,
      location: data.data.attributes.location?.data?.id,
      section: data.data?.attributes?.section?.data?.id,
      minProfileCategorySub:
        data.data.attributes.minProfileCategorySub?.data?.id,
      startDate: formatDate(eventAttributes.startDate),
      mailDate: formatDate(eventAttributes.mailDate),
      firstReminderDate: formatDate(eventAttributes.firstReminderDate),
      secondReminderDate: formatDate(eventAttributes.secondReminderDate),
      partecipations: eventAttributes.partecipations,
      image: eventAttributes.image?.data,
      autosetReminderDates: false,
      carousel:
        eventAttributes.carousel?.data?.map((carousel) => carousel) || [],
      initialMaxParticipants: eventAttributes.maxParticipants,
    };

    updateEventFormMethods.reset(eventFormValues);
  };

  const disableForDelegates = useMemo(
    () =>
      ![EventStatus.DRAFT, EventStatus.PUBLISHED, null, undefined].includes(
        updateEventFormMethods.watch('status')
      ) && isDelegato(profile),
    [updateEventFormMethods.watch('status')]
  );

  const onFindEventQueryError = () => {
    toast.error("Errore durante il recupero dell'evento");
    navigate('/404');
  };

  const findEventQuery = useQuery({
    queryKey: ['getEvent', eventId],
    queryFn: findEventQueryFn,
    onSuccess: onFindEventQuerySuccess,
    onError: onFindEventQueryError,
    refetchOnReconnect: false,
    retry: 0,
  });

  const updateEventMutationFn = (updatedEvent: EventRequest) => {
    return updateEvent(
      eventId!,
      _.omit(updatedEvent, ['balance.couponsVirtuosity']) as EventRequest,
      token!
    );
  };

  const onUpdateEventMutationSuccess = () => {
    toast.success('Evento modificato con successo!');
    navigate('/eventi');
  };

  const onUpdateEventMutationErrors = () => {
    toast.error("Errore durante la modifica dell'evento");
  };

  const updateEventMutation = useMutation({
    mutationKey: ['createEvent'],
    mutationFn: updateEventMutationFn,
    onSuccess: onUpdateEventMutationSuccess,
    onError: onUpdateEventMutationErrors,
  });

  const { mutateAsync: uploadFileMutation, isLoading: isUploadingFile } =
    useMutation({
      mutationKey: ['uploadFile'],
      mutationFn: ({ files, token }: { files: File[]; token: string | null }) =>
        uploadToStrapi(files, token),

      onError: (err) => {
        console.log('err', err);
        toast.error('Qualcosa è andato storto!');
      },
    });

  const save = (status?: EventStatus) => {
    const formHasError: boolean =
      Object.keys(updateEventFormMethods.formState.errors).length > 0;
    if (formHasError) {
      return () =>
        toast.error(
          'Ci sono degli errori di validazione. Controlla tutti i campi'
        );
    }
    return updateEventFormMethods.handleSubmit(async (updatedEventValues) => {
      let uploadEventImageRes: any;
      let uploadEventCarouselRes: any;

      const areElementsFile = (elements?: any[] | null) => {
        return elements?.some((element) => element instanceof File);
      };

      let existingCarouselIds: any[] = [];
      if (Array.isArray(updatedEventValues.carousel)) {
        existingCarouselIds = updatedEventValues.carousel.map(
          (item: any) => item.id
        );
      }

      if (updatedEventValues.image) {
        uploadEventImageRes = await uploadFileMutation({
          files: updatedEventValues.image as File[],
          token,
        });
      }

      if (areElementsFile(updatedEventValues.carousel)) {
        uploadEventCarouselRes = await uploadFileMutation({
          files: updatedEventValues.carousel as File[],
          token,
        });
      }

      const newCarouselIds = uploadEventCarouselRes?.map(
        (file: any) => file.id
      );

      updateEventMutation.mutate({
        ...updatedEventValues,
        mailDate: moment(updatedEventValues.mailDate).utc().toISOString(),
        firstReminderDate: moment(updatedEventValues.firstReminderDate)
          .utc()
          .toISOString(),
        secondReminderDate: moment(updatedEventValues.secondReminderDate)
          .utc()
          .toISOString(),
        startDate: moment(updatedEventValues.startDate).utc().toISOString(),
        status: status || updatedEventValues.status,
        image: uploadEventImageRes?.[0]?.id,
        carousel: [...existingCarouselIds, ...(newCarouselIds || [])],
      });
    });
  };

  const saveAsDraft = save(EventStatus.DRAFT);

  const saveAndPublish = save(EventStatus.PUBLISHED);

  const saveAndFinalBalanceDelegation = () => {
    if (isDelegato(profile)) {
      save(EventStatus.FINAL_BALANCE_DELEGATION)();
    } else {
      toggleModal();
    }
  };

  const saveAndFinalBalanceNational = () => {
    if (isSegreteriaNazionale(profile)) {
      save(EventStatus.FINAL_BALANCE_NATIONAL)();
    } else {
      toast.error(
        'Il corso è già stato consuntivato dalla segreteria nazionale'
      );
    }
  };

  const getHeaderButtons = (status: EventStatus) => {
    const endEvent = updateEventFormMethods.watch('startDate');

    const isEventEnded = moment().isAfter(moment(endEvent).add(24, 'hours'));

    return match(status)
      .with(EventStatus.DRAFT, () => {
        return {
          primaryButtonText: 'Pubblica',
          primaryButtonOnClick: saveAndPublish,
          secondaryButtonText: 'Salva bozza',
          secondaryButtonOnClick: saveAsDraft,
        };
      })
      .with(EventStatus.PUBLISHED, () => {
        return {
          primaryButtonText: isEventEnded
            ? 'Rendiconta evento'
            : 'Pubblica modifiche',
          primaryButtonOnClick: isEventEnded
            ? saveAndFinalBalanceDelegation
            : save(),
          secondaryButtonText: isEventEnded ? 'Salva modifiche' : undefined,
          secondaryButtonOnClick: save(),
        };
      })
      .with(EventStatus.FINAL_BALANCE_DELEGATION, () => {
        return {
          primaryButtonText: isEventEnded
            ? 'Rendiconta evento'
            : 'Salva modifiche',
          primaryButtonOnClick: isEventEnded
            ? saveAndFinalBalanceNational
            : save(),
          secondaryButtonText: isEventEnded ? 'Salva modifiche' : undefined,
          secondaryButtonOnClick: save(),
        };
      })
      .with(EventStatus.FINAL_BALANCE_NATIONAL, () => ({}))
      .with(EventStatus.CANCELED, () => ({}))
      .exhaustive();
  };

  const status = updateEventFormMethods.watch('status') || EventStatus.DRAFT;

  const formValues = updateEventFormMethods.getValues();

  const checkLocations = async () => {
    const section = updateEventFormMethods.watch('section');
    const selectedLocation = updateEventFormMethods.watch('location');
    if (!section) return;
    const { data: locations } = await locationsAPIs.find({
      query: {
        filters: { sections: { id: section } },
        pagination: { pageSize: 500 },
      },
      token,
    });

    const isLoacationAllowed = locations.some(
      (location) => location.id === selectedLocation
    );
    if (!isLoacationAllowed)
      updateEventFormMethods.setValue('location', undefined);
  };

  const onImageDelete = async (fileId?: number) => {
    //e' il file di strapi
    if (fileId) {
      //cancello il file su strapi
      try {
        await deleteMedia({ fileId, token });
        findEventQuery.refetch();
      } catch (err) {
        console.log('err', err);
        toast.error("Qualcosa e' andato storto");
      }
    }
    //e' il file del form
    else {
      updateEventFormMethods.setValue('image', undefined);
    }
  };

  const onCarouselImageDelete = async (index: number) => {
    const carouselImages = Array.isArray(formValues.carousel)
      ? [...formValues.carousel]
      : [];
    const carouselImagesLoaded =
      findEventQuery.data?.data.data.attributes.carousel?.data || [];
    // se in Strapi sono presenti immagini nel carosello le cancello da strapi
    if (index >= 0 && index < (carouselImagesLoaded?.length || 0)) {
      const imageId = carouselImagesLoaded[index].id;
      try {
        await deleteMedia({ fileId: imageId, token });
        findEventQuery.refetch();
      } catch (err) {
        console.log('err', err);
        toast.error('Qualcosa è andato storto');
      }
      // se le immagini del carosello non sono state caricate su strapi ma solo nel form
    } else {
      carouselImages?.splice(index, 1); // Rimuovi l'immagine dall'array
      updateEventFormMethods.setValue('carousel', carouselImages as File[]);
    }
  };

  const isLoading = updateEventMutation.isLoading;

  useEffect(() => {
    checkLocations();
  }, [updateEventFormMethods.watch('section')]);

  if (findEventQuery.isFetching) return <FullscreenSpinner />;

  return (
    <FormProvider {...updateEventFormMethods}>
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <HeaderTitle
          category='Evento'
          {...getHeaderButtons(status)}
          modal={<StatusEventModal save={save} />}
          buttonsDisabled={isUploadingFile || isLoading || disableForDelegates}
        />
        <BaseModal
          title={'Evento non rendicontato da sezione'}
          isOpen={isOpen}
          toggle={toggleModal}
          className={'mx-auto md:w-[500px] w-11/12'}
          hideBottom
        >
          <>
            Non è possibile consuntivare l'evento perchè non è ancora in stato{' '}
            <strong>consuntivato da sezione</strong>.
            <div className='flex items-center justify-end gap-4 mt-6'>
              <PrimaryButton onClick={toggleModal} small>
                {'OK'}
              </PrimaryButton>
            </div>
          </>
        </BaseModal>

        <div className='pt-4'>
          <TabBar tabs={getTabs(eventId!)} />
          <Routes>
            <Route
              index
              element={
                <DatiPrincipaliEvento
                  onImageDelete={() =>
                    onImageDelete(
                      findEventQuery.data?.data.data.attributes.image?.data?.id
                    )
                  }
                  onCarouselImageDelete={(index) =>
                    onCarouselImageDelete(index)
                  }
                  eventMethods={updateEventFormMethods}
                />
              }
            />
            <Route path='location' element={<LocationEvento />} />
            <Route
              path='partecipanti'
              element={<PartecipantiEvento eventDetailQuery={findEventQuery} />}
            />
            <Route
              path='partecipanti/:participantId/ricevute/crea'
              element={<CreaRicevutaCorso />}
            />
            <Route
              path='partecipanti/:participantId/ricevute'
              element={<CreaRicevutaTemplateCorso />}
            />
            {findEventQuery?.data?.data.data.attributes && (
              <Route
                path='costi'
                element={
                  <CostiEvento
                    defaultEvent={findEventQuery.data.data.data.attributes}
                  />
                }
              />
            )}
            <Route
              path='ricevute'
              element={<RicevuteEvento eventDetailQuery={findEventQuery} />}
            />
          </Routes>
        </div>
      </div>
    </FormProvider>
  );
};
export default ModificaEvento;

import React from "react";
import classNames from "../../libs/utils/classNames";
import { Link } from "react-router-dom";

interface PrimaryLinkFullProps {
  href?: string;
  outline?: boolean;
  children: any;
  onClick?: any;
}

const PrimaryLinkFull: React.FC<PrimaryLinkFullProps> = ({
  href,
  children,
  outline,
  onClick,
}) => {
  return (
    <Link to={href+''}>
      <button
        onClick={onClick}
        className={classNames(
          "font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base bg-primary rounded-full text-white py-2 px-6 w-full",
          outline ? "!text-primary border border-primary !bg-transparent" : ""
        )}
      >
        {children}
      </button>
    </Link>
  );
};

export default PrimaryLinkFull;

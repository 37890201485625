import { OnavPositionRole } from '../../../interfaces/onavPosition';

const getOnavPositionLabel = (role: OnavPositionRole | null) => {
  switch (role) {
    case OnavPositionRole.CONSIGLIERE:
      return 'Consigliere';
    case OnavPositionRole.CONSULENTE_CONSIGLIO:
      return 'Consulente consiglio';
    case OnavPositionRole.DELEGATO_PRO_TEMPORE:
      return 'Delegato pro tempore';
    case OnavPositionRole.MEMBRO_CONSIGLIO_SCIENTIFICO:
      return 'Membro consiglio scientifico';
    case OnavPositionRole.CONSIGLIERE_AUSILIARE:
      return 'Consigliere ausiliare';
    case OnavPositionRole.DELEGATO:
      return 'Delegato';
    case OnavPositionRole.DIRETTORE_GENERALE:
      return 'Direttore generale';
    case OnavPositionRole.PRESIDENTE:
      return 'Presidente';
    case OnavPositionRole.PRESIDENTE_COLLEGIO_PROBIVIRI:
      return 'Presidente collegio probiviri';
    case OnavPositionRole.PRESIDENTE_COLLEGIO_SINDACALE:
      return 'Presidente colleggio sindacale';
    case OnavPositionRole.PRESIDENTE_CONSIGLIO_SCIENTIFICO:
      return 'Presidente consiglio scientifico';
    case OnavPositionRole.PROBIVIRO:
      return 'Probiviro';
    case OnavPositionRole.RAPPRESENTANTE:
      return 'Rappresentante';
    case OnavPositionRole.RESP_SOCIAL:
      return 'Responsabile social';
    case OnavPositionRole.SINDACO:
      return 'Sindaco';
    case OnavPositionRole.TESORIERE:
      return 'Tesoriere';
    case OnavPositionRole.TESORIERE_PRO_TEMPORE:
      return 'Tesoriere pro tempore';
    case OnavPositionRole.VICE_DELEGATO:
      return 'Vice delegato';
    case OnavPositionRole.VICE_PRESIDENTE:
      return 'Vice presidente';
    default:
      return '-';
  }
};

export default getOnavPositionLabel;

import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../components/Buttons/BackButton';
import PrimaryButton from '../components/Buttons/PrimaryButton';

export default function PrivatePage() {
  return (
    <div className='text-center h-[calc(100vh-380px)] flex justify-center items-center flex-col'>
      <h1 className='text-4.5xl text-neutral-700 font-IBM font-medium'>
        Sezione protetta
      </h1>
      <p className='mb-16 text-gray-600'>
        Solo la Segreteria nazionale può acccedere a questa sezione
      </p>
      <div className='grid grid-cols-2 w-fit-content'>
        <BackButton />
        <PrimaryButton>
          <Link to={'/'}>Torna alla Home</Link>
        </PrimaryButton>
      </div>
    </div>
  );
}

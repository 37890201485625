import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import AudienceTable from '../Tables/audienceTable';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import { listAudiences } from '../../../api/audiences';
import Pagination from '../../../components/Pagination';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const Audience = () => {
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const query = useQuery(['audiences', ...debounceSearchParams], () =>
    listAudiences(debounceSearchParams, token)
  );
  return (
    <>
      <TitlePage title='Audience' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Lista Audience</H1Styled>

        <div className='space-y-5'>
          <AudienceTable query={query} />
          <Pagination pagination={query.data?.meta.pagination} />
        </div>
      </div>
    </>
  );
};

export default Audience;

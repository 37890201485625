import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import React, { Fragment, useEffect, useState } from 'react';
import { FieldError, useController } from 'react-hook-form';

import classNames from '../../libs/utils/classNames';

interface IOption {
  label: string;
  value: any;
}
interface SelectPresentationalProps {
  label?: string;
  options?: IOption[];
  defaultValue?: any;
  disabled?: boolean;
  control?: any;
  register?: any;
  name: string;
  required?: boolean;
  onChangeValue?: (e?: IOption) => void;
  legendIcons?: JSX.Element[];
  error?: FieldError;
}

const SelectPresentational = (props: SelectPresentationalProps) => {
  const {
    label,
    options,
    defaultValue,
    control,
    error,
    required,
    name,
    onChangeValue,
    legendIcons,
  } = props;
  const defaultOption =
    defaultValue && options?.find((option) => option.value === defaultValue);
  const [selected, setSelected] = useState<IOption | undefined>(defaultOption);

  useEffect(() => {
    if (!selected) setSelected(defaultOption);
  }, [defaultOption]);

  // se non è stato passato control alle props
  let changeValue: any;
  let valueId: number;
  if (control) {
    const {
      field: { value, onChange },
    } = useController(props);

    if (typeof value === 'object') {
      valueId = value?.data?.id;
    } else valueId = value;

    changeValue = onChange;

    useEffect(() => {
      setSelected(options?.find((option) => option.value === valueId));
    }, [value]);
  } else {
    changeValue = setSelected;
  }

  return (
    <div className='w-full'>
      <Listbox
        disabled={props.disabled}
        value={selected}
        onChange={(e) => {
          changeValue(e);
          onChangeValue && onChangeValue(e);
        }}
        name={name}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='block text-sm text-gray-500'>
                <div className='flex flex-row'>
                  {`${label}${required ? ' *' : ''}`}
                  {legendIcons && (
                    <ul className='flex flex-row'>
                      {legendIcons.map((legendIcon, idx) => {
                        return (
                          <li
                            className='flex h-5 mx-1'
                            key={`input-legend-${idx}`}
                          >
                            {legendIcon}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </Listbox.Label>
            )}
            <div className='relative mt-1'>
              <Listbox.Button
                className={`relative w-full px-3 py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-0 focus:border-primary sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                  error?.message
                    ? 'border-red-100 focus:border-red-100 border-[1px]'
                    : ''
                }`}
              >
                <span className='block truncate'>
                  {selected?.label || ' - '}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  <SelectorIcon
                    className='w-5 h-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {options?.length === 0 && (
                    <div className='relative px-4 py-2 text-gray-700 cursor-default select-none'>
                      Nessun risultato
                    </div>
                  )}
                  {options ? (
                    options.map((option) => (
                      <Listbox.Option
                        key={option.value}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary' : 'text-gray-800',
                            'cursor-default select-none relative py-2 pl-8 pr-4 z-30'
                          )
                        }
                        value={control ? option.value : option}
                      >
                        {({ active }) => (
                          <>
                            <span
                              className={classNames(
                                option.value === selected?.value
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {option.label}
                            </span>

                            {option.value === selected?.value ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                )}
                              >
                                <CheckIcon
                                  className='w-5 h-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))
                  ) : (
                    <div className='flex justify-center my-6 text-sm font-normal text-center text-gray-500'>
                      Nessun risultato
                    </div>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export default SelectPresentational;

import React from "react";

interface H4SpacingLetterProps {
  children: any;
}

const H4SpacingLetter: React.FC<H4SpacingLetterProps> = ({ children }) => {
  return (
    <h4 className="text-lg text-gray-500 tracking-custom font-medium">{children}</h4>
  );
};

export default H4SpacingLetter;

import { useFormContext } from 'react-hook-form';

import InputPresentational from './Form/InputPresentational';
import SelectPresentational from './Form/SelectPresentational';
import TextAreaPresentational from './Form/TextAreaPresentational';
import { levels } from '../constants/levels';
import { useFilesContext } from '../contexts/CourseTemplateFilesContext';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import { CourseTemplateRequest } from '../interfaces/course-templates';

import H4SpacingLetter from './Typography/H4SpacingLetter';
import GadgetForm from './GadgetForm';
import ProfileCategorySelect from './FetchSelect/ProfileCategorySelect';
import { useEffect } from 'react';
import { useAuth } from '../contexts/Auth';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FormDatiPrincipali = () => {
  const [{ settings }] = useAuth();
  const { id } = useParams();
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<CourseTemplateRequest>();

  const { image, carousel, setImage, setCarousel } = useFilesContext();

  const onFilesSelect = (field: string, files: File[]) => {
    if (field === 'image') setImage(files);
    if (field === 'carousel') setCarousel(files);
  };

  const removeItem = (field: string, index: number) => {
    switch (field) {
      case 'image':
        setImage(image.filter((_, idx) => idx !== index));
        break;
      case 'carousel':
        setCarousel(carousel.filter((_, idx) => idx !== index));
        break;
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

  useEffect(() => {
    if (!id) {
      setValue('membershipFee', settings?.courseDefaultFees?.membership);
      setValue('registrationFee', settings?.courseDefaultFees?.registration);
      setValue('courseFee', settings?.courseDefaultFees?.course);
    }
  }, []);

  return (
    <div className='pt-6 pb-8 grid grid-cols-6 gap-4 md:gap-x-12'>
      <div className='col-span-6 grid grid-cols-1 md:grid-cols-2 gap-6'>
        <InputPresentational
          id='title'
          label='Titolo'
          name='title'
          type='text'
          register={register}
          required
          error={{ ...errors.title, type: 'required' }}
        />
        <SimpleCheckboxPresentational
          id='isOnline'
          name='isOnline'
          label='Corso Online'
          register={register}
        />
      </div>
      <div className='col-span-6'>
        <InputPresentational
          id='subtitle'
          label='Sottotitolo'
          name='subtitle'
          type='text'
          register={register}
          required
          error={{ ...errors.subtitle, type: 'required' }}
        />
      </div>

      <div className='col-span-6 grid grid-cols-1 md:grid-cols-2 gap-6'>
        <ProfileCategorySelect
          label='Rivolto a'
          id='minProfileCategorySub'
          name='minProfileCategorySub'
          control={control}
          required
          error={{ ...errors.minProfileCategorySub, type: 'required' }}
        />
        <SelectPresentational
          label='Livello'
          name='level'
          control={control}
          options={levels}
          required
          error={{ ...errors.level, type: 'required' }}
        />
      </div>
      <div className='col-span-6 '>
        <SimpleCheckboxPresentational
          id='includeTeachers'
          name='includeTeachers'
          label='Solo per docenti'
          register={register}
        />
      </div>
      <div className='col-span-6'>
        <TextAreaPresentational
          id='shortDescription'
          label='Descrizione breve'
          name='shortDescription'
          register={register}
          required
          error={{ ...errors.shortDescription, type: 'required' }}
        />
      </div>

      <div className='col-span-6'>
        <label
          htmlFor={'description'}
          className='flex justify-between text-sm text-gray-500 mb-2'
        >
          <div>Descrizione</div>
        </label>
        <ReactQuill
          id='description'
          theme='snow'
          value={watch('description')}
          onChange={(value) => setValue('description', value)}
          modules={modules}
          formats={formats}
        />
      </div>

      <div className='col-span-6'>
        <H4SpacingLetter>Media</H4SpacingLetter>
      </div>

      <div className='col-span-6 md:col-span-3 space-y-3'>
        {/* {!watch('image')?.data && (
          <UploadAreaPresentetional
            label='Immagine singola'
            name='image'
            onFilesSelect={onFilesSelect}
            formMethods={courseMethods}
          />
        )}  */}
        {/* TODO SISTEMARE QUESTA PARTE  */}
        {/* <div className='flex flex-wrap items-start justify-start gap-3'>
          {
            watch('image')?.data ? (
              <ImageUploadPrev
                image={`${process.env.REACT_APP_API_URL?.replace('/api', '')}${
                  watch('image')?.data.attributes.formats.thumbnail.url
                }`}
              />
            ) : null

            (
              watch('image')?.length > 0 &&
              watch('image').map((file, index) => (
                <ImageUploadFilePreview
                  file={file}
                  onRemove={() => removeItem('image', index)}
                />
              ))
            )
          }
        </div> */}
      </div>
      <div className='col-span-6 md:col-span-3 space-y-3'>
        {/* {!watch('carousel')?.data && (
          <UploadAreaPresentetional
            label='Carosello'
            name='carousel'
            onFilesSelect={onFilesSelect}
            formMethods={courseMethods}
            multiple
          />
        )}
        <div className='flex flex-wrap items-start justify-start gap-3'>
          {watch('carousel')?.data
            ? watch('carousel')?.data.map((image: any) => {
                return (
                  image.attributes.formats?.thumbnail.url && (
                    <ImageUploadPrev
                      image={`${process.env.REACT_APP_API_URL?.replace(
                        '/api',
                        ''
                      )}${image.attributes.formats.thumbnail.url}`}
                    />
                  )
                );
              })
            : carousel?.length > 0 &&
              carousel.map((file, index) => (
                <ImageUploadFilePreview
                  file={file}
                  onRemove={() => removeItem('carousel', index)}
                />
              ))}
        </div>  */}
      </div>
      <div className='col-span-6'>
        <InputPresentational
          id='videoUrl'
          label='Video URL'
          name='videoUrl'
          type='text'
          register={register}
        />
      </div>

      <div className='col-span-6'>
        <H4SpacingLetter>Cosa comprende il corso</H4SpacingLetter>
      </div>
      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.lessonsDescription'
          label='Descrizione lezioni'
          name='details.lessonsDescription'
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.winesDescription'
          label='Descrizione vini'
          name='details.winesDescription'
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.aimings'
          label='Descrizione prove'
          name='details.aimings'
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <GadgetForm />
      </div>
    </div>
  );
};

export default FormDatiPrincipali;

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { PresentationChartBarIcon } from '@heroicons/react/outline';
import StaticInputPresentational from '../Form/StaticInputPresentational';
import { useQuery } from 'react-query';
import eventsAPIs from '../../api/events';
import { useAuth } from '../../contexts/Auth';
import Spinner from '../Layout/Loading/Spinner';

interface Props {
  id?: number;
  disabled?: boolean;
}

const EventReportModal: React.FC<Props> = ({ id, disabled }) => {
  const [open, setOpen] = useState(false);
  const [{ token }] = useAuth();

  const { data: eventReportData, isFetching } = useQuery(
    ['getEventReport', id],
    () => eventsAPIs.findReport({ token, id }),
    {
      enabled: !!id,
    }
  );

  return (
    <>
      <UnderlineButton onClick={() => setOpen(true)} disabled={disabled}>
        <PresentationChartBarIcon className='w-4 h-4' /> Report
      </UnderlineButton>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              {isFetching ? (
                <Spinner />
              ) : (
                <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full sm:p-6'>
                  <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-10'>
                    Report dei partecipanti
                  </h4>

                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8'>
                    <StaticInputPresentational
                      label='Totale CONFERMATI'
                      text={String(eventReportData?.totalConfirmed)}
                    />
                    <StaticInputPresentational
                      label='di cui CONVIVENTI'
                      text={String(eventReportData?.totalGuests)}
                    />
                    <StaticInputPresentational
                      label='Totale PRESENZE'
                      text={String(eventReportData?.totalPresents)}
                    />

                    <StaticInputPresentational
                      label='Totale IN ATTESA'
                      text={String(eventReportData?.totalPending)}
                    />
                    <StaticInputPresentational
                      label='Totale ANNULLATI'
                      text={String(eventReportData?.totalCanceled)}
                    />
                    <StaticInputPresentational
                      label='Totale NON ISCRITTI'
                      text={String(eventReportData?.totalNotConfirmed)}
                    />
                  </div>

                  <div className='flex items-center justify-end gap-4 mt-10'>
                    <PrimaryButton textSmall onClick={() => setOpen(false)}>
                      Chiudi
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default EventReportModal;

import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import badgesToAPIs, {
  badgesToPdf,
  badgesToPrint,
} from '../../../api/badgesAPIs';
import UnderlineButton from '../../../components/Buttons/UnderlineButton';
import Pagination from '../../../components/Pagination';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { useAuth } from '../../../contexts/Auth';
import StampaTable from '../Tables/StampaTable';
import { toast } from 'react-toastify';
import { BadgeToPrint } from '../../../interfaces/badgeToPrint';
import FiltersBar from '../../../components/Filters/FiltersBar';
import { FilterType } from '../../../interfaces/filters';
import coursesAPIs from '../../../api/courses';
import { yearsFilterBar } from '../../../constants/years';
import { profileCategories } from '../../../api/profileCategories';
import QueryString from 'qs';
import {
  getDateTimeFromDate,
  getGroupFilter,
} from '../../../libs/utils/helpers';
import moment from 'moment';
import { CardHistoryType } from '../../../interfaces/cardHistory';

const ListaStampe = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const { REACT_APP_DEBOUNCE_DELAY } = process.env;
  const delay = Number(REACT_APP_DEBOUNCE_DELAY);
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const queryClient = useQueryClient();
  const applyForAll = useMemo(
    () => searchParams.get('applyForAll') === 'true',
    [searchParams]
  );
  const [listQuery, setListQuery] = useState<object>();

  const getAssociatedProductsQuery = (arr?: string[]) => {
    if (!arr || arr.length === 2 || arr.length === 0) return undefined;
    if (arr.includes('true'))
      return {
        $notNull: true,
      };
    if (arr.includes('false'))
      return {
        $null: true,
      };
  };
  const sortParams = JSON.parse(searchParams.get('sort') ?? '{}');

  const badgesQuery = useQuery(
    ['badgesQueryList', ...debounceSearchParams],
    () => {
      const keywords = debounceSearchParams.get('search')?.split(' ');
      const search =
        keywords?.map((keyword) => {
          return {
            $or: [
              { profile: { name: { $contains: keyword } } },
              { profile: { surname: { $contains: keyword } } },
              { profile: { user: { email: { $contains: keyword } } } },
              { profile: { badge: { badgeNumber: { $contains: keyword } } } },
              {
                profile: { profileCategory: { title: { $contains: keyword } } },
              },
            ],
          };
        }) || [];

      const query = {
        populate: ['payment', 'cardHistory', 'profile'],
        filters: {
          $and: [...search],
          cardHistory: {
            printed: {
              $not:
                searchParams.get('printedBadge') === 'true' ? true : undefined,
            },
            year: {
              $in: getGroupFilter({ filterName: 'years', searchParams }),
            },
            type: {
              $in: getGroupFilter({ filterName: 'type', searchParams }),
            },
          },
          payment: {
            date: {
              $gte: QueryString.parse(
                Object.entries(
                  QueryString.parse(debounceSearchParams.get('date') + '')
                ).map(([, value]) => value as any)[0]?.label
              ).from,
              $lte: getDateTimeFromDate(
                QueryString.parse(
                  Object.entries(
                    QueryString.parse(debounceSearchParams.get('date') + '')
                  ).map(([, value]) => value as any)[0]?.label
                ).to || undefined
              ),
            },
          },
          profile: {
            printDigitalBadge: {
              $not:
                searchParams.get('digitalBadge') === 'true' ? true : undefined,
            },
            profileCategory: {
              id: {
                $in: getGroupFilter({
                  filterName: 'profileCategories',
                  searchParams,
                }),
              },
            },

            courseParticipations: {
              ...(!!getGroupFilter({ filterName: 'courses', searchParams }) && {
                status: 'CONFIRMED',
              }),
              course: {
                id: {
                  $in: getGroupFilter({ filterName: 'courses', searchParams }),
                },
              },
            },
          },
          associationCode: getAssociatedProductsQuery(
            getGroupFilter({
              filterName: 'isFromCourse',
              searchParams,
            })
          ),
        },
        pagination: {
          page: searchParams.get('page'),
          pageSize: searchParams.get('pageSize'),
        },
        sort:
          JSON.stringify(sortParams) === '{}'
            ? [{ payment: { date: 'desc' } }, { id: 'asc' }]
            : { profile: sortParams },
      };
      setListQuery(query);

      return badgesToPrint({
        token,
        query,
      });
    }
  );

  const filterBar = useMemo(() => {
    const filters = [
      {
        type: FilterType.DATE,
        attribute: 'date',
        label: 'Filtra per data',
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'courses',
        label: 'Corsi',
        key: 'coursesListFilter',
        source: coursesAPIs.filtersFind,
        searchForAttributes: ['title'],
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'profileCategories',
        label: 'Titolo',
        key: 'profileCategoriesFilter',
        searchForAttributes: ['title'],
        source: profileCategories,
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'years',
        label: 'Anno',
        key: 'years',
        source: () => ({ data: yearsFilterBar }),
      },
      {
        type: FilterType.CHECKBOX,
        attribute: 'printedBadge',
        label: 'Tessera non stampata',
      },
      {
        type: FilterType.CHECKBOX,
        attribute: 'digitalBadge',
        label: 'Tessera non digitale',
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'isFromCourse',
        label: 'Modalità acquisto tessera',
        key: 'isFromCourse',
        source: () => ({
          data: CARD_PURCHASE_TYPES,
        }),
        isSearchable: false,
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'type',
        label: 'Tipologia',
        key: 'type',
        source: () => ({
          data: CARD_TYPE,
        }),
        isSearchable: false,
      },
      {
        type: FilterType.SEARCH_BAR,
        attribute: 'search',
        label: 'Cerca...',
      },
    ];

    return [...filters];
  }, [profile]);

  const CARD_PURCHASE_TYPES = [
    { attributes: { name: 'Tessera acquistata da un corso' }, id: 'true' },
    { attributes: { name: 'Tessera acquistata singolarmente' }, id: 'false' },
  ];
  const CARD_TYPE = [
    {
      attributes: { name: 'Prima associazione' },
      id: CardHistoryType.PRIMA_TESSERA,
    },
    { attributes: { name: 'Rinnovo' }, id: CardHistoryType.RINNOVO },
    { attributes: { name: 'Reiscrizione' }, id: CardHistoryType.REISCRIZIONE },
  ];

  const [selectedBadges, setSelectedBadges] = useState<BadgeToPrint[]>([]);

  const { mutate: badgesToPDFMutation } = useMutation(
    'createBadges',
    badgesToPdf,
    {
      onSuccess: () => {
        toast.success('Tessere generate con successo');
        queryClient.invalidateQueries(['badgesQueryList']);

        setSelectedBadges([]);
      },
      onError: () => {
        toast.error('Ops... Qualcosa è andato storto');
      },
    }
  );

  const handleGeneratePdfBadges = () => {
    if (applyForAll) {
      massBadgesToPdfMutation({
        token,
        fileName: `LISTA_TESSERE_${moment().format('LL')}`,
        query: listQuery,
      });
    } else {
      badgesToPDFMutation({
        body: selectedBadges,
        token,
      });
    }
  };

  const { mutate: mutateLetters } = useMutation(
    'createLetters',
    badgesToAPIs.lettersToPdf,
    {
      onSuccess: () => {
        toast.success('Lettere generate con successo');
        queryClient.invalidateQueries(['badgesQueryList']);
        setSelectedBadges([]);
      },
      onError: () => {
        toast.error('Ops... Qualcosa è andato storto');
      },
    }
  );

  const { mutate: massLettersToPdfMutation, isLoading: isLoadingMassLetters } =
    useMutation('massLettersPDF', badgesToAPIs.massLettersToPdf, {
      onSuccess: () => {
        if (Number(badgesQuery.data?.meta?.pagination?.total || 0) <= 500) {
          toast.success('Lettere generate con successo');
        } else {
          toast.warn(
            'Lettere generate con successo. Abbiamo limitato la stampa a 500 record!'
          );
        }

        queryClient.invalidateQueries(['badgesQueryList']);
        searchParams.delete('applyForAll');
        setSearchParams(searchParams);
      },
      onError: () => {
        toast.error('Ops... Qualcosa è andato storto');
      },
    });

  const { mutate: massBadgesToPdfMutation, isLoading: isLoadingMassBadges } =
    useMutation('massBadgesPDF', badgesToAPIs.massBadgesToPdf, {
      onSuccess: () => {
        if (Number(badgesQuery.data?.meta?.pagination?.total || 0) <= 500) {
          toast.success('Badge generati con successo');
        } else {
          toast.warn(
            'Badge generate con successi. Abbiamo limitato la stampa a 500 record!'
          );
        }
        queryClient.invalidateQueries(['badgesQueryList']);
        searchParams.delete('applyForAll');
        setSearchParams(searchParams);
      },
      onError: () => {
        toast.error('Ops... Qualcosa è andato storto');
      },
    });

  const handleGeneratePdfLetters = () => {
    if (applyForAll) {
      massLettersToPdfMutation({
        token,
        fileName: `LISTA_LETTERE_${moment().format('LL')}`,
        query: listQuery,
      });
    } else {
      mutateLetters({
        body: selectedBadges.map((elem) => elem.id),
        token,
      });
    }
  };

  return (
    <>
      <TitlePage title='Stampa' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Stampa tessere e lettere</H1Styled>

        <div className='space-y-5'>
          <FiltersBar filters={filterBar} />
          <div className='w-full flex justify-between'>
            <button
              onClick={() => {
                searchParams.set('applyForAll', !applyForAll + '');
                setSearchParams(searchParams);
              }}
              className='cursor-pointer hover:underline'
            >
              <span className='text-gray-600'>
                Seleziona tutti in tutte le pagine
              </span>
              <input
                type='checkbox'
                className='cursor-pointer ml-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6'
                checked={applyForAll}
                onChange={() => {}}
              />
            </button>

            <div className='flex'>
              <p className='text-gray-600'>Genera PDF per la stampa:</p>
              <UnderlineButton
                children={'Lettera'}
                onClick={handleGeneratePdfLetters}
                className={'mx-4'}
                disabled={
                  (applyForAll ? false : !selectedBadges.length) ||
                  isLoadingMassLetters
                }
              />

              <UnderlineButton
                children={'Tessera'}
                onClick={handleGeneratePdfBadges}
                disabled={
                  (applyForAll ? false : !selectedBadges.length) ||
                  isLoadingMassBadges
                }
              />
            </div>
          </div>
          <StampaTable
            query={badgesQuery}
            setSelectedBadges={setSelectedBadges}
            selectedBadges={selectedBadges}
            applyForAll={applyForAll}
          />
          <Pagination pagination={badgesQuery.data?.meta.pagination} />
        </div>
      </div>
    </>
  );
};

export default ListaStampe;

import UtenteLezioniTable from '../../Tables/UtenteLezioni';
import { Profile } from '../../../../interfaces/profile';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import { FilterType } from '../../../../interfaces/filters';
import coursesAPIs from '../../../../api/courses';
import { StrapiMeta } from '../../../../interfaces/commons';
import { useAuth } from '../../../../contexts/Auth';

interface Props {
  profile: Profile;
  meta: StrapiMeta;
}

const ModificaUtenteLezioni: React.FC<Props> = ({ profile, meta }) => {
  const [{ token }] = useAuth();
  const lessonsFilters = [
    {
      type: FilterType.MULTISELECT,
      attribute: 'status',
      label: 'Stato',
      key: 'lessonStatusesList',
      source: () => ({
        data: [
          { id: 'ACCEPTED', attributes: { name: 'Accettato' } },
          { id: 'REJECTED', attributes: { name: 'Rifiutato' } },
          { id: 'PENDING', attributes: { name: 'In attesa' } },
        ],
      }),
    },
    {
      type: FilterType.MULTISELECT,
      attribute: 'courses',
      label: 'Corsi',
      key: 'coursesList',
      source: coursesAPIs.filtersFindForTeacher,
      searchForAttributes: ['title'],
    },
    {
      type: FilterType.SEARCH_BAR,
      attribute: 'search',
      label: 'Cerca...',
    },
  ];
  return (
    <>
      <div className='pb-10'>
        <FiltersBar filters={lessonsFilters} />
      </div>
      {/* <div className='w-full flex justify-end'>
        <UnderlineButton onClick={() => {}}>
          <DownloadIcon className='w-4 h-4' /> Scarica CSV
        </UnderlineButton>
      </div> */}
      <UtenteLezioniTable profile={profile} />
    </>
  );
};

export default ModificaUtenteLezioni;

import { StrapiData } from '../../interfaces/commons';
import { Order, ProductType } from '../../interfaces/orders';
import { PaymentStatus } from '../../interfaces/payments';

export const mapStatusPayment = (
  method: PaymentStatus | undefined | null
): string => {
  switch (method) {
    case PaymentStatus.AUTHORIZED:
      return 'Autorizzato';
    case PaymentStatus.CANCELED:
      return 'Cancellato';
    case PaymentStatus.COMPLETED:
      return 'Completato';
    case PaymentStatus.DRAFT:
      return 'Bozza';
    case PaymentStatus.FAILED:
      return 'Fallito';
    case PaymentStatus.PARTIAL:
      return 'Parziale';
    case PaymentStatus.PENDING:
      return 'Pendente';
    default:
      return '-';
  }
};

export const getUserExistingSubDataIfExisting = (
  orders: StrapiData<Order>[]
): Partial<StrapiData<Order>> => {
  const availableSubs = orders.filter(
    (_order) => _order.attributes.productType === ProductType.SUBSCRIPTION
  );

  if (availableSubs && availableSubs.length > 0) {
    const lastSub = availableSubs
      .filter(
        (_sub) =>
          _sub.attributes.payment?.data.attributes.status !==
          PaymentStatus.DRAFT
      )
      .sort(
        (a, b) =>
          a.attributes.cardHistory.data.attributes.year -
          b.attributes.cardHistory.data.attributes.year
      )
      .at(0) as Partial<StrapiData<Order>>;

    if (lastSub) return lastSub;
  }

  return {
    attributes: {
      cardHistory: {
        data: {
          attributes: { year: new Date().getFullYear() } as any,
        },
      },
      id: null,
    } as any,
  } as Partial<StrapiData<Order>>;
};

import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { ProfileHistory } from '../../../interfaces/profile-history';
import moment from 'moment';

const tableElements = ['Data', 'Descrizione'];

const UtenteStoricoTable = ({
  query,
}: {
  query: UseQueryResult<ProfileHistory[]>;
}) => {
  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='relative overflow-hidden ring-1 border ring-black ring-opacity-5'>
          <table className='min-w-full table-fixed divide-y divide-gray-300'>
            <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
              <tr>
                {tableElements.map((element) => (
                  <th className='font-normal px-3 py-3 text-left tracking-wide'>
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {match(query)
                .with({ status: 'idle' }, { status: 'loading' }, () => (
                  <TableLoading tableElements={tableElements} />
                ))
                .with({ status: 'error' }, () => <div>Errore...</div>)
                .with({ status: 'success' }, (query) => {
                  return query?.data?.map((profileHistory, key: number) => (
                    <tr key={key}>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {moment(profileHistory.date).format('DD-MM-YYYY')}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {profileHistory.description}
                      </td>
                    </tr>
                  ));
                })
                .exhaustive()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UtenteStoricoTable;

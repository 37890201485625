import React, { useState } from 'react';

import { FieldError, UseControllerProps } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getProfiles } from '../../api/profile';
import { useAuth } from '../../contexts/Auth';
import { useDebounce } from 'use-debounce';
import { SearchableSelect } from '../Form/SearchableSelect';
import { match } from 'ts-pattern';
import { getQuerySearchFilters } from '../../libs/utils/profile';

type SingleTeacherSelectProps = {
  id: string;
  label: string;
  disabled?: boolean;
  error?: FieldError;
  availableTeacher?: number[];
  lessonTemplateId?: number;
} & UseControllerProps;

const SingleTeacherSelect: React.FC<SingleTeacherSelectProps> = ({
  control,
  name,
  label,
  disabled = false,
  error,
  availableTeacher,
  defaultValue,
  lessonTemplateId,
}) => {
  const [{ token }] = useAuth();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);

  const query = useQuery(['teachersLessons', debouncedSearch], () => {
    const filters: any = {
      $and: getQuerySearchFilters(debouncedSearch),
      isTeacher: true,
    };

    if (availableTeacher && availableTeacher?.length > 0 && lessonTemplateId) {
      filters.lessonTemplates = lessonTemplateId;
    }

    return getProfiles({
      token,
      query: {
        filters,
        pagination: {
          pageSize: 500,
        },
        forceListing: true,
      },
    });
  });

  return (
    <SearchableSelect
      label={label}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      control={control}
      error={error}
      onInput={setSearch}
      isLoading={query.isFetching}
      options={match(query)
        .with(
          { status: 'error' },
          { status: 'idle' },
          { status: 'loading' },
          () => []
        )
        .with({ status: 'success' }, (query) => {
          const options =
            query.data?.data.map((profile) => ({
              label: profile.attributes.name + ' ' + profile.attributes.surname,
              value: profile.id,
            })) || [];

          if (availableTeacher && availableTeacher?.length > 0) {
            return options.filter(
              (o) => availableTeacher.indexOf(o.value) > -1
            );
          }

          return options;
        })
        .exhaustive()}
    />
  );
};

export default SingleTeacherSelect;

import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Profile } from '../../interfaces/profile';
import classNames from '../../libs/utils/classNames';

interface TabBarItems {
  href: string;
  label: string;
  showFor?: string;
}
interface TabBarProps {
  tabs: TabBarItems[];
  profile?: Profile;
}

const TabBar = ({ tabs, profile }: TabBarProps) => {
  const location = useLocation();
  const navigation = useNavigate();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHref = event.target.value;
    navigation(selectedHref);
  };

  return (
    <div className='w-full'>
      <div className='md:hidden flex items-center gap-4'>
        <label
          htmlFor='tabs'
          className='text-light text-gray-500 tracking-custom'
        >
          Visualizza
        </label>
        <select
          id='tabs'
          name='tabs'
          className='block w-full pl-3 pr-10 py-2 text-base bg-neutral-50 border-gray-200 focus:outline-none focus:ring-primary focus:border-primary md:text-sm'
          onChange={handleSelectChange}
        >
          {tabs
            .filter((el) =>
              el.showFor === 'teacher' ? profile?.attributes.isTeacher : true
            )
            .map((tab) => (
              <option key={tab.label} value={tab.href}>
                {tab.label}
              </option>
            ))}
        </select>
      </div>
      <div className='hidden md:block overflow-x-auto'>
        <div className='border-b border-gray-200 px-3'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs
              .filter((el) =>
                el.showFor === 'teacher' ? profile?.attributes.isTeacher : true
              )
              .map((tab) => (
                <Link
                  key={tab.label}
                  to={tab.href}
                  className={classNames(
                    location.pathname === tab.href
                      ? 'border-primary text-primary font-medium'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-3 px-1 border-b-2 text-sm tracking-custom'
                  )}
                >
                  {tab.label}
                </Link>
              ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TabBar;

import React, { ChangeEvent, Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import { BackspaceIcon, XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from '../../libs/utils/classNames';
import { useSearchParams } from 'react-router-dom';

import { categories } from '../../constants/categories';

const filters = [
  {
    id: 'category',
    name: 'Categoria',
    options: categories,
  },
  {
    id: 'endCourse',
    name: 'Tipologia',
    options: [
      { value: 'true', label: 'Fine corso' },
      { value: 'false', label: 'Non fine corso' },
    ],
  },
];

export default function FilterLessons() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [open, setOpen] = useState(false);

  const activeFilters = Array.from(searchParams.entries()).reduce(
    (acc: { [key: string]: string[] }, [key, value]) => ({
      ...acc,
      [key]: [...(acc[key] ? acc[key] : []), value],
    }),
    {}
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [key, value] = event.target.name.split('-');

    if (event.target.checked) {
      return setSearchParams({
        ...activeFilters,
        [key]: [...searchParams.getAll(key), value],
      });
    }

    removeFilter(key, value);
  };

  const removeFilter = (key: string, value: string) => {
    setSearchParams({
      ...activeFilters,
      [key]: searchParams.getAll(key).filter((v) => v !== value),
    });
  };

  const onChangeSearch = (value: string) => {
    // TODO: aggiungere useDebounce
    setSearch(value);

    value === ''
      ? searchParams.delete('search')
      : searchParams.set('search', value);
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const hasFilters: boolean =
    Array.from(searchParams.entries()).filter(([key]) => key !== 'title')
      .length > 0;

  return (
    <div className='bg-white'>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-40 sm:hidden'
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto'>
              <div className='px-4 flex items-center justify-between'>
                <h2 className='text-lg font-IBM font-medium text-primary'>
                  Filtri
                </h2>
                <button
                  type='button'
                  className='-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>

              {/* Filters */}
              <form className='mt-4'>
                {filters.map((filter) => (
                  <Disclosure
                    as='div'
                    key={filter.name}
                    className='border-t border-gray-200 px-4 py-6'
                  >
                    {({ open }) => (
                      <>
                        <h3 className='-mx-2 -my-3 flow-root'>
                          <Disclosure.Button className='px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400'>
                            <span className='font-normal text-gray-800'>
                              {filter.name}
                            </span>
                            <span className='ml-6 flex items-center'>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? '-rotate-180' : 'rotate-0',
                                  'h-5 w-5 transform'
                                )}
                                aria-hidden='true'
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className='pt-6'>
                          <div className='space-y-6'>
                            {filter.options.map((option, optionIdx) => {
                              const value = searchParams.getAll(filter.id);
                              return (
                                <div
                                  key={`${filter.id}-${optionIdx}`}
                                  className='flex items-center'
                                >
                                  <label
                                    htmlFor={`filter-mobile-${filter.id}-${optionIdx}`}
                                    className='ml-3 text-sm text-gray-500'
                                  >
                                    {option.label}
                                    <input
                                      id={`filter-mobile-${filter.id}-${optionIdx}`}
                                      name={`${filter.id}-${option.value}`}
                                      type='checkbox'
                                      checked={value?.includes(option.value)}
                                      onChange={onChange}
                                      className='h-4 w-4 border-gray-300 rounded text-primary focus:ring-primary'
                                    />
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}

                <div className='flex w-full items-center px-4'>
                  <input
                    type='text'
                    name='search'
                    id='search'
                    className='block w-full text-sm border-gray-300 rounded-full focus:ring-primary focus:border-primary bg-neutral-50'
                    placeholder='Cerca...'
                    value={search}
                    onChange={(e) => onChangeSearch(e.target.value)}
                  />
                  {search && (
                    <BackspaceIcon
                      className='w-5 h-5 -ml-8 text-gray-500 cursor-pointer'
                      aria-hidden='true'
                      onClick={() => {
                        searchParams.delete('search');
                        setSearch('');
                        setSearchParams(searchParams);
                      }}
                    />
                  )}
                </div>
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters */}

      <section aria-labelledby='filter-heading'>
        <h2 id='filter-heading' className='sr-only'>
          Mobile filters
        </h2>

        <div className='relative z-10 bg-white pb-4'>
          <div className='w-full pr-4 flex items-center justify-end gap-6 '>
            <button
              type='button'
              className='inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden'
              onClick={() => setOpen(true)}
            >
              Filtri
            </button>

            <div className='hidden sm:block pr-4'>
              <div className='flow-root'>
                <Popover.Group className='-mx-4 flex items-center divide-x divide-gray-200'>
                  {filters.map((filter, sectionIdx) => (
                    <Popover
                      key={filter.name}
                      className='px-4 relative inline-block text-left'
                    >
                      <Popover.Button className='group inline-flex justify-center text-sm font-normal text-gray-700 hover:text-gray-800'>
                        <span>{filter.name}</span>

                        <ChevronDownIcon
                          className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <form className='space-y-4'>
                            {filter.options.map((option, optionIdx) => {
                              const value = searchParams.getAll(filter.id);

                              return (
                                <div
                                  key={option.value}
                                  className='flex items-center'
                                >
                                  <input
                                    id={`filter-${filter.id}-${optionIdx}`}
                                    name={`${filter.id}-${option.value}`}
                                    type='checkbox'
                                    checked={value?.includes(option.value)}
                                    onChange={onChange}
                                    className='h-4 w-4 border-gray-300 rounded text-primary focus:ring-primary'
                                  />
                                  <label
                                    htmlFor={`filter-${filter.id}-${optionIdx}`}
                                    className='ml-3 pr-6 text-sm font-normal text-gray-900 whitespace-nowrap'
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              );
                            })}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                  <div className='flex items-center w-48 pl-4'>
                    <input
                      type='text'
                      name='search'
                      id='search'
                      className='block w-full text-sm border-gray-300 rounded-full focus:ring-primary focus:border-primary bg-neutral-50'
                      placeholder='Cerca...'
                      value={search}
                      onChange={(e) => onChangeSearch(e.target.value)}
                    />
                    {search && (
                      <BackspaceIcon
                        className='w-5 h-5 -ml-8 text-gray-500 cursor-pointer'
                        aria-hidden='true'
                        onClick={() => {
                          searchParams.delete('search');
                          setSearch('');
                          setSearchParams(searchParams);
                        }}
                      />
                    )}
                  </div>
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Filtri applicati */}
        {hasFilters && (
          <div className='bg-neutral-100'>
            <div className='py-3 px-4 sm:flex sm:items-center'>
              <h3 className='text-xs font-medium uppercase tracking-wide text-gray-500'>
                Filtri applicati
              </h3>

              <div
                aria-hidden='true'
                className='hidden w-px h-5 bg-gray-300 sm:block sm:ml-4'
              />

              <div className='mt-2 sm:mt-0 sm:ml-4'>
                <div className='-m-1 flex flex-wrap items-center'>
                  {Object.entries(activeFilters).map(([key, values]) => {
                    const abc: {
                      label: string;
                      selected: { label: string; value: string }[];
                    }[] = filters.map((a) => ({
                      label: a.name,
                      selected: a.options.filter((c) =>
                        values.includes(c.value)
                      ),
                    }));

                    return abc.map((f) => {
                      return f.selected.map((s) => (
                        <span
                          key={'c'}
                          className='m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-normal bg-white text-gray-900'
                        >
                          <span>{`${f.label}: ${s.label}`}</span>
                          <button
                            type='button'
                            onClick={() => removeFilter(key, s.value)}
                            className='flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500'
                          >
                            <svg
                              className='h-2 w-2'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 8 8'
                            >
                              <path
                                strokeLinecap='round'
                                strokeWidth='1.5'
                                d='M1 1l6 6m0-6L1 7'
                              />
                            </svg>
                          </button>
                        </span>
                      ));
                    });
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

import React from "react";
import classNames from "../../libs/utils/classNames";

interface H1StyledProps {
  children: any;
  noMargin?: boolean
}

const H1Styled: React.FC<H1StyledProps> = ({ children, noMargin }) => {
  return (
    <h1
      className={classNames(
        "font-IBM font-normal text-gray-800 mb-5 text-1.5xl md:text-3xl",
        noMargin ? "!mb-0" : ""
      )}
    >
      {children}
    </h1>
  );
};

export default H1Styled;

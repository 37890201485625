import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import ComunicatiTable from '../Tables/comunicatiTable';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { listAnnouncment } from '../../../api/announcments';
import Filters from '../../../components/Filters/Filters';
import Pagination from '../../../components/Pagination';
import { useAuth } from '../../../contexts/Auth';
import { useDebounce } from 'use-debounce';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ListaComuicati = () => {
  const [searchParams] = useSearchParams();
  const [{ token }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const query = useQuery(['announcments', ...debounceSearchParams], () =>
    listAnnouncment(debounceSearchParams, token)
  );

  return (
    <>
      <TitlePage title='Tutti i comunicati' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Tutti i comunicati</H1Styled>

        <div className='space-y-5'>
          <Filters searching date send />
          {/* TODO: Aggiungere il filtro pubblico e stato */}
          <ComunicatiTable query={query} />
          <Pagination pagination={query.data?.meta.pagination} />
        </div>
      </div>
    </>
  );
};

export default ListaComuicati;

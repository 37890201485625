import { formatDate } from '../../libs/utils/formatters/datetimeFormatter';

export const modelData = (
  data: any,
  checked: 'all' | 'course' | 'event' | ''
) => {
  const dataCopy = { ...data };

  delete dataCopy.all_coupon;
  delete dataCopy.course_coupon;
  delete dataCopy.event_coupon;
  dataCopy.validFor = checked;
  dataCopy.startValidity = formatDate(dataCopy.startValidity, 'YYYY-MM-DD');
  dataCopy.endValidity = formatDate(dataCopy.endValidity, 'YYYY-MM-DD');
  if (checked === 'all') {
    dataCopy.course = undefined;
    dataCopy.event = undefined;
  }
  if (checked === 'course') {
    dataCopy.event = undefined;
    dataCopy.course = Number(dataCopy.course?.value);
  }
  if (checked === 'event') {
    dataCopy.course = undefined;
    dataCopy.event = Number(dataCopy.event?.value);
  }

  return dataCopy;
};

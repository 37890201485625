import React from 'react';
import classNames from '../../libs/utils/classNames';

interface PriceInputTableProps {
  id: string;
  name: string;
  type: string;
  register?: any;
  defaultValue?: number;
  readOnly?: boolean;
  disabled?: boolean;
  min?: number;
  className?: string;
}
const PriceInputTable: React.FC<PriceInputTableProps> = ({
  id,
  name,
  type,
  register,
  defaultValue = 0,
  readOnly = false,
  disabled,
  min = 0,
  className = '',
}) => {
  return (
    <div className='mt-1 relative'>
      <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
        <span className='text-gray-800 sm:text-sm'>€</span>
      </div>
      <input
        id={id}
        type={type}
        autoComplete={id}
        required
        defaultValue={defaultValue}
        readOnly={readOnly}
        min={min}
        {...(register &&
          register(name, {
            ...(type === 'number' && {
              valueAsNumber: true,
            }),
          }))}
        className={classNames(
          'w-[110px] appearance-none block pl-7 pr-3 py-2 text-sm border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:border-slate-200',
          className
        )}
        disabled={disabled}
      />
    </div>
  );
};

export default PriceInputTable;

import axios from 'axios';
import qs from 'qs';
import { StrapiResponse, StrapiResponseList } from '../interfaces/commons';
import { Location } from '../interfaces/location';
import QueryString from 'qs';

const { REACT_APP_API_URL } = process.env;

export const location = async (id: string | number) => {
  const { data } = await axios.get<StrapiResponse<Location>>(
    `${REACT_APP_API_URL}/locations/${id}`
    /* {
      headers: { authorization: `Bearer ${token}` },
    } */
  );

  return data;
};

export const listLocations = async ({ query = {} }: { query?: object }) => {
  const { data } = await axios.get<StrapiResponse<Location[]>>(
    `${REACT_APP_API_URL}/locations?${QueryString.stringify({
      ...query,
      sort: { updatedAt: 'desc' },
    })}`
    /* {
      headers: { authorization: `Bearer ${token}` },
    } */
  );

  return data;
};

export const listLocationsBySection = async (
  sectionId: number,
  token: string | null,
  pagination?: object
) => {
  const query = {
    filters: {
      sections: {
        id: sectionId,
      },
    },
    sort: { name: 'asc' },
    pagination,
  };

  const { data } = await axios.get<StrapiResponseList<Location>>(
    `${REACT_APP_API_URL}/locations?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

// create a new location in the database
export const createLocation = async (
  location: Location,
  token: string | null
) => {
  const { data } = await axios.post<StrapiResponse<Location>>(
    `${REACT_APP_API_URL}/locations`,
    { data: location },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

// update a location in the database
export const updateLocation = async (
  locationId: number,
  location: Location,
  token: string | null
) => {
  const { data } = await axios.put<StrapiResponse<Location>>(
    `${REACT_APP_API_URL}/locations/${locationId}`,
    { data: location },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

// delete a location from the database
export const deleteLocation = async (
  locationId: number,
  token: string | null
) => {
  const { data } = await axios.delete(
    `${REACT_APP_API_URL}/locations/${locationId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

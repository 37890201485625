import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAuth } from '../contexts/Auth';
import { Profile, ProfileRequest } from '../interfaces/profile';
import GenderSelect from './FetchSelect/GenderSelect';
import InputPresentational from './Form/InputPresentational';
import InputPresentationalTaxCode from './Form/InputPresentationalTaxCode';
import H3Styled from './Typography/H3Styled';
import NumberSelect from './FetchSelect/NumberSelect';
import NationsSelect from './FetchSelect/NationsSelect';
import RegionsSelect from './FetchSelect/RegionsSelect';
import ProvincesSelect from './FetchSelect/ProvincesSelect';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import { isSegreteriaNazionale } from '../libs/utils/auth';
import { IdentificationIcon } from '@heroicons/react/outline';

const LEGEND_ICONS = [<IdentificationIcon className='text-primary' key={1} />];
const { REACT_APP_ASSISTENCE_EMAIL_ADDRESS } = process.env;

const FormDatiPrincipaliUtente = ({
  infoUser,
  creationForm = false,
}: {
  infoUser?: Profile;
  creationForm?: boolean;
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<ProfileRequest>();
  const [{ profile }] = useAuth();
  const [coincide, setCoincide] = useState(
    infoUser ? infoUser.attributes.addressesCoincide : true
  );

  useEffect(() => {
    setCoincide(watch('addressesCoincide'));
  }, [watch('addressesCoincide')]);

  return (
    <div className=''>
      <div>
        <div className='flex justify-start flex-col sm:flex-row sm:justify-between'>
          <H3Styled>Dati anagrafica</H3Styled>
          <div className='mt-2 sm:mt-0'>
            <ul className='flex flex-col sm:flex-row'>
              <li className='flex flex-row'>
                <strong>*</strong>
                <span className='text-sm text-gray-500 px-2'>
                  Campi obbligatori
                </span>
              </li>
              <li className='flex flex-row'>
                <IdentificationIcon className='h-5 w-5 text-primary' key={1} />
                <span className='text-sm text-gray-500 px-2'>
                  Campi necessari per acquistare la tessera ONAV
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className='pt-6 pb-8 grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 gap-x-8 gap-y-6'>
          {creationForm ? (
            <InputPresentational
              id='email'
              label='Email'
              name='email'
              type='email'
              register={register}
              required
              error={{ ...errors.email, type: 'required' }}
              legendIcons={LEGEND_ICONS}
            />
          ) : (
            <>
              <InputPresentational
                id='badgeNumber'
                label='Numero tessera'
                name='badgeNumber'
                type='number'
                disabled
                register={register}
                legendIcons={LEGEND_ICONS}
              />
              <InputPresentational
                id='email'
                label='Email'
                name='email'
                type='email'
                disabled
                required
                error={{ ...errors.email, type: 'required' }}
                register={register}
                info={
                  isSegreteriaNazionale(profile) && (
                    <a
                      className='text-primary underline'
                      href={`mailto:${REACT_APP_ASSISTENCE_EMAIL_ADDRESS}?subject=Richiesta cambio indirizzo email utente ONAV`}
                    >
                      Per il cambio email clicca qui
                    </a>
                  )
                }
                legendIcons={LEGEND_ICONS}
              />
            </>
          )}

          <InputPresentational
            register={register}
            id='surname'
            label='Cognome'
            name='surname'
            type='text'
            required
            capitalize
            legendIcons={LEGEND_ICONS}
            error={{ ...errors.surname, type: 'required' }}
          />
          <InputPresentational
            register={register}
            id='name'
            label='Nome'
            name='name'
            type='text'
            required
            capitalize
            legendIcons={LEGEND_ICONS}
            error={{ ...errors.name, type: 'required' }}
          />
          <GenderSelect control={control} legendIcons={LEGEND_ICONS} />
          <InputPresentationalTaxCode
            legendIcons={LEGEND_ICONS}
            required
            error={{ ...errors.fiscalCode, type: 'required' }}
          />
          <InputPresentational
            register={register}
            id='birthDate'
            label='Data di nascita'
            name='birthDate'
            type='date'
            legendIcons={LEGEND_ICONS}
          />
          <InputPresentational
            register={register}
            id='birthPlace'
            label='Luogo di nascita'
            name='birthPlace'
            type='text'
            legendIcons={LEGEND_ICONS}
          />

          <NumberSelect
            name='mobilePhone'
            label='Telefono cellulare'
            defaultValue={infoUser && infoUser.attributes.mobilePhone}
            required
            legendIcons={LEGEND_ICONS}
            error={{ ...errors.mobilePhone, type: 'required' }}
          />
          <NumberSelect
            name='altPhone'
            label='Telefono alternativo'
            defaultValue={infoUser && infoUser.attributes.altPhone}
          />

          <InputPresentational
            id='qualification'
            label='Titolo di studio'
            name='qualification'
            type='text'
            register={register}
            defaultValue={infoUser && infoUser.attributes.qualification}
          />
          <InputPresentational
            id='profession'
            label='Professione'
            name='profession'
            type='text'
            register={register}
            defaultValue={infoUser && infoUser.attributes.profession}
          />
        </div>
        <h4 className='font-IBM text-xl text-gray-600 pt-6'>
          Indirizzo di residenza
        </h4>
        <div className='pt-6 pb-8 grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 gap-x-8 gap-y-6'>
          <NationsSelect
            defaultValue={infoUser && infoUser.attributes.nation?.data?.id}
            control={control}
            legendIcons={LEGEND_ICONS}
          />
          <RegionsSelect
            defaultValue={infoUser && infoUser.attributes.region?.data?.id}
            control={control}
            legendIcons={LEGEND_ICONS}
          />

          <ProvincesSelect
            defaultValue={infoUser && infoUser.attributes.province?.data?.id}
            control={control}
            region={watch('region')}
            legendIcons={LEGEND_ICONS}
          />
          <InputPresentational
            register={register}
            id='city'
            label='Città'
            name='city'
            type='text'
            defaultValue={infoUser && infoUser.attributes.city}
            legendIcons={LEGEND_ICONS}
          />
          <InputPresentational
            register={register}
            id='address'
            label='Indirizzo'
            name='address'
            type='text'
            defaultValue={infoUser && infoUser.attributes.address}
            legendIcons={LEGEND_ICONS}
          />
          <InputPresentational
            id='streetNumber'
            label='Civico'
            name='streetNumber'
            type='text'
            register={register}
            defaultValue={infoUser && infoUser.attributes.streetNumber}
            legendIcons={LEGEND_ICONS}
          />
          <InputPresentational
            register={register}
            id='zipcode'
            label='Cap'
            name='zipcode'
            type='text'
            defaultValue={infoUser && infoUser.attributes.zipcode}
            legendIcons={LEGEND_ICONS}
          />
        </div>
        <SimpleCheckboxPresentational
          id='addressesCoincide'
          label={`L'indirizzo di domicilio coincide con l'indirizzo di residenza`}
          name='addressesCoincide'
          checked={watch('addressesCoincide')}
          defaultValue={infoUser && infoUser.attributes.addressesCoincide}
          register={register}
        />

        {!coincide && (
          <>
            <h4 className='font-IBM text-xl text-gray-600 pt-12'>
              Indirizzo di domicilio
            </h4>
            <div className='pt-6 pb-8 grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 gap-x-8 gap-y-6'>
              <NationsSelect
                control={control}
                name='homeAddress.nation'
                defaultValue={
                  infoUser && infoUser.attributes.homeAddress?.nation?.data?.id
                }
                legendIcons={LEGEND_ICONS}
              />
              <RegionsSelect
                control={control}
                name='homeAddress.region'
                defaultValue={
                  infoUser && infoUser.attributes.homeAddress?.region?.data?.id
                }
                legendIcons={LEGEND_ICONS}
              />
              <ProvincesSelect
                control={control}
                name='homeAddress.province'
                defaultValue={
                  infoUser &&
                  infoUser.attributes.homeAddress?.province?.data?.id
                }
                region={watch('homeAddress.region')}
                legendIcons={LEGEND_ICONS}
              />
              <InputPresentational
                id='city'
                label='Città'
                name='homeAddress.city'
                type='text'
                register={register}
                defaultValue={infoUser && infoUser.attributes.homeAddress?.city}
                legendIcons={LEGEND_ICONS}
              />
              <InputPresentational
                id='address'
                label='Indirizzo'
                name='homeAddress.address'
                type='text'
                register={register}
                defaultValue={
                  infoUser && infoUser.attributes.homeAddress?.address
                }
                legendIcons={LEGEND_ICONS}
              />
              <InputPresentational
                id='streetNumber'
                label='Civico'
                name='homeAddress.streetNumber'
                type='text'
                register={register}
                defaultValue={
                  infoUser && infoUser.attributes.homeAddress?.streetNumber
                }
                legendIcons={LEGEND_ICONS}
              />

              <InputPresentational
                id='zipcode'
                label='CAP'
                name='homeAddress.zipcode'
                type='text'
                register={register}
                defaultValue={
                  infoUser && infoUser.attributes.homeAddress?.zipcode
                }
                legendIcons={LEGEND_ICONS}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FormDatiPrincipaliUtente;

import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import FreeLabel from '../Form/FreeLabel';
import { CourseAttributes } from '../../interfaces/courses';
import { EventAttributes } from '../../interfaces/events';

interface NoteModalProps {
  partecipantName: string;
  course?: CourseAttributes | null;
  event?: EventAttributes | null;
  isOpen: boolean;
  toggle: () => void;
  onConfirm: (note: string) => void;
  initialNote?: string | null;
}

const NoteModal: React.FC<NoteModalProps> = ({
  partecipantName,
  course,
  event,
  isOpen,
  toggle,
  onConfirm,
  initialNote,
}) => {
  const [note, setNote] = useState<string>(initialNote || '');
  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={toggle}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Scrivi una nota
                </h4>

                <div className='py-10'>
                  <p className='text-gray-700'>
                    Scrivi una nota su <i>{partecipantName}</i> in riferimento
                    alla sua partecipazione {course ? 'al corso' : "all'evento"}{' '}
                    "
                    {course && (
                      <span className='font-medium italic text-primary'>
                        {course?.title}
                      </span>
                    )}
                    {event && (
                      <span className='font-medium italic text-primary'>
                        {event?.title}
                      </span>
                    )}{' '}
                    "
                  </p>
                  <div className='mt-8'>
                    <FreeLabel label='Nota' />
                    <div className='flex flex-row  items-center gap-4'>
                      <textarea
                        id='note'
                        name='note'
                        defaultValue={initialNote || ''}
                        onChange={(e) => setNote(e.target.value)}
                        autoComplete='note'
                        className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none h-24'
                      />
                    </div>
                  </div>
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton textSmall outline onClick={toggle}>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton
                    small
                    onClick={() => {
                      onConfirm(note);
                      toggle();
                    }}
                  >
                    Salva nota
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NoteModal;

import FormModificaDatiPrincipaliSezione from '../../../../components/FormModificaDatiPrincipaliSezione';
import TitlePage from '../../../../components/TitlePage';
import { Section } from '../../../../interfaces/section';

export default function DatiPrincipali({
  sectionData,
}: {
  sectionData: Section;
}) {
  return (
    <>
      <TitlePage title='Modifica Sezione | Dati principali' />

      <div>
        <FormModificaDatiPrincipaliSezione sectionData={sectionData} />
      </div>
    </>
  );
}

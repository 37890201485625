import { EventStatus } from '../interfaces/events';

interface StatusOption {
  id: EventStatus;
  title: string;
}

export const eventStatus: StatusOption[] = [
  { id: EventStatus.DRAFT, title: 'Bozza' },
  { id: EventStatus.PUBLISHED, title: 'Pubblicato' },
  {
    id: EventStatus.FINAL_BALANCE_DELEGATION,
    title: 'Consuntivato da sezione',
  },
  {
    id: EventStatus.FINAL_BALANCE_NATIONAL,
    title: 'Consuntivato da segreteria nazionale',
  },
  { id: EventStatus.CANCELED, title: 'Annullato' },
];
export const FINAL_BALANCE_EXPIRATION_DAYS = 90;

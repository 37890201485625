import { useState } from 'react';
import { login, me } from '../api/login';
import AlertMessage from '../components/AlertMessage';
import InputPresentational from '../components/Form/InputPresentational';
import TitlePage from '../components/TitlePage';
import { FieldValues, useForm } from 'react-hook-form';
import isEmpty from 'lodash.isempty';
import { useNavigate } from 'react-router-dom';
import { AuthActionType, useAuth } from '../contexts/Auth';
import { toast } from 'react-toastify';
import appSettings from '../api/appSettings';
import { getOneProfile } from '../api/profile';

export default function Login() {
  const [{}, dispatch] = useAuth();
  const { REACT_APP_ONAVIT_URL } = process.env;
  const [isLoading, setIsLoading] = useState(false);
  const onLogin = async (data: FieldValues) => {
    setError(false);
    try {
      setError(false);
      setIsLoading(true);
      const user = await login({
        identifier: data.email,
        password: data.password,
      });

      const { data: userData } = await me(user.jwt);
      const { data: profileData } = await getOneProfile({
        token: user.jwt,
        id: userData.profileId + '',
        query: {
          populate: '*',
        },
      });

      const { data: settingsData } = await appSettings.find({
        token: user.jwt,
      });
      dispatch({
        type: AuthActionType.Login,
        token: user.jwt,
        remember: data.remember,
      });
      dispatch({
        type: AuthActionType.SetProfile,
        profile: { ...profileData.attributes, ...userData },
      });
      dispatch({
        type: AuthActionType.setSettings,
        settings: settingsData,
      });

      userData?.role?.type === 'authenticated' &&
        toast.error(
          'Solo un utente amministratore può accedere a questo sito. Visita: onav.it'
        );
      navigate('/');
      setIsLoading(false);
    } catch (err: any) {
      err?.response?.data?.message === 'Invalid identifier or password'
        ? setError(true)
        : toast.error('Errore del server, riprova più tardi');
      setIsLoading(false);
    }
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const [error, setError] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <TitlePage title='Login' />

      <div className='bg-sand-50 min-h-screen flex flex-col justify-center py-14 px-4 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img
            className='h-14 w-auto mx-auto'
            src='/images/logo-onav-orizzontale.svg'
            alt='Onav Organizzazione Nazionale Assaggiatori Vino'
            title='Onav Organizzazione Nazionale Assaggiatori Vino'
          />
          <h2 className='font-IBM mt-6 text-center text-3xl text-gray-700'>
            <span className='font-medium'>Accedi</span> al tuo account
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
          <div className='bg-white py-8 px-4 sm:px-10'>
            <form
              className='space-y-6'
              method='POST'
              onSubmit={handleSubmit(onLogin)}
            >
              <InputPresentational
                id='email'
                label='Email'
                type='text'
                name='email'
                register={register}
                required
              />

              <InputPresentational
                id='password'
                label='Password'
                type='password'
                name='password'
                register={register}
                required
              />

              {/* messaggio da visualizzare solo se uno dei due campi, o entrambi, sono errati */}
              {(!isEmpty(errors) || error) && (
                <AlertMessage
                  borderLeft
                  text='Email o password errata. Ti ricordiamo che se è il tuo primo login nel nuovo portale, devi resettare la password cliccando su Password dimenticata'
                />
              )}

              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <input
                    id='remember'
                    type='checkbox'
                    defaultChecked={true}
                    className='h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded'
                    {...(register && register('remember'))}
                  />
                  <label
                    htmlFor='remember'
                    className='ml-2 block text-sm text-gray-400'
                  >
                    Ricordami
                  </label>
                </div>
                <div className='text-sm'>
                  <a
                    className='text-primary hover:opacity-80'
                    href={`${REACT_APP_ONAVIT_URL}/forgot-password`}
                  >
                    Password dimenticata?
                  </a>
                </div>
              </div>

              <button
                type='submit'
                className='font-normal flex items-center justify-center text-center whitespace-nowrap hover:opacity-80 text-base bg-primary rounded-full text-white py-2 px-6 w-full disabled:cursor-wait disabled:bg-primary/75'
                disabled={isLoading}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

import TitlePage from '../../../components/TitlePage';
import FormDatiPrincipaliDettaglioCorso from '../../../components/FormDatiPrincipaliDettaglioCorso';
import { UseFormReturn } from 'react-hook-form';

interface DatiPrincipaliCorsoProps {
  onImageDelete?: (fileId?: number) => void;
  onCarouselImageDelete?: (index: number) => void;
  courseMethods?: UseFormReturn<any, any>;
}

export default function DatiPrincipaliCorso({
  onImageDelete,
  onCarouselImageDelete,
  courseMethods,
}: DatiPrincipaliCorsoProps) {
  return (
    <>
      <TitlePage title='Dettaglio corso | Dati principali' />

      <form className='mt-6'>
        <FormDatiPrincipaliDettaglioCorso
          onImageDelete={onImageDelete}
          onCarouselImageDelete={onCarouselImageDelete}
          courseMethods={courseMethods}
        />
      </form>
    </>
  );
}

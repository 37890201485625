import React from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { CourseStatus, StrapiResponseList } from '../../../interfaces/commons';
import { CourseResponse } from '../../../interfaces/courses';
import { formatCurrency } from '../../../libs/utils/formatters/currency';

interface CoursesToBeApprovedProps {
  query: UseQueryResult<StrapiResponseList<CourseResponse>>;
}
const CoursesToBeApproved: React.FC<CoursesToBeApprovedProps> = ({ query }) => {
  const tableElements = [
    'Data',
    'Corso',
    'Sezione',
    'Minimo partecipanti',
    'Quote per associazione',
    'Entrate previste',
    'Uscite previste',
    'Margine previsto',
  ];

  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, idx) => (
                          <th
                            key={idx}
                            className='font-normal px-6 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data?.data
                        .filter(
                          (course) =>
                            course.attributes.status === CourseStatus.TO_APPROVE
                        )
                        .map((course) => (
                          <tr
                            key={course.id}
                            className='cursor-pointer hover:bg-neutral-100'
                            onClick={() => {
                              navigate(
                                `corsi/dettaglio-corso/${course.id}/dati-principali`
                              );
                            }}
                          >
                            <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                              {new Date(
                                course.attributes.createdAt
                              ).toLocaleDateString('it-IT')}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.title}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.section?.data?.attributes.name}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {course.attributes.minParticipants}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.subscriptionAmount
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.estimatesEarnings
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.estimatesOutflow
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                course.attributes.estimatesProfit
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default CoursesToBeApproved;

import React from 'react';
import classNames from '../../libs/utils/classNames';

interface StaticInputPresentationalProps {
  label: string;
  text?: string | number;
  big?: boolean;
}
const StaticInputPresentational: React.FC<StaticInputPresentationalProps> = ({
  label,
  text,
  big,
}) => {
  return (
    <div className='flex flex-row '>
      <span className='flex my-auto'>
        <label className='flex text-sm text-gray-500 text-center'>
          {label}
        </label>
      </span>
      <p
        className={classNames(
          'text-gray-800 ml-2  text-center my-auto',

          big ? 'text-2xl font-bold' : ''
        )}
      >
        {text}
      </p>
    </div>
  );
};

export default StaticInputPresentational;

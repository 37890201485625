import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from '../../../contexts/Auth';

import { ProfileResponse } from '../../../interfaces/profile';
import { create, find } from '../../../api/receipts';
import { profileInfo } from '../../../api/profile';

import H1Styled from '../../../components/Typography/H1Styled';
import TitlePage from '../../../components/TitlePage';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import ReceiptForm from '../../../components/ReceiptForm';
import {
  ReceiptAttributes,
  ReceiptRequest,
  ReceiptResponse,
} from '../../../interfaces/receipts';

import moment from 'moment';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { receiptsValidator } from '../../../validators/receipts';
import { sanitizeCurrencyValue } from '../../../libs/utils/helpers';

const emptyReceipt: ReceiptAttributes = {
  code: null,
  description: '',
  date: moment().format('YYYY-MM-DD'),
  paymentMethod: null,
  fiscalCode: null,
  entries: [],
  badgeNumber: null,
  profile: null,
  type: null,
  notes: null,
  section: null,
  payments: {
    data: [],
  },
};

const TesoreriaCreaRicevuta = () => {
  const navigate = useNavigate();

  const [{ token, profile }] = useAuth();
  const [section, setSection] = useState<number | null>(null);

  const goBack = () => {
    navigate('/tesoreria/entrate');
  };

  const profileInfoQueryFn = () =>
    profileInfo({ id: profile?.profileId!, token });
  const onProfileInfoQuerySuccess = (profileResponse: ProfileResponse) => {
    const profileSectionId = profileResponse.data.attributes.section.data?.id;
    setSection(profileSectionId || null);
  };

  useQuery({
    queryKey: ['profileInfo'],
    queryFn: profileInfoQueryFn,
    onSuccess: onProfileInfoQuerySuccess,
  });

  const lastReceiptsQueryFn = () =>
    find({
      token,
      query: {
        filters: {
          section,
        },
        sort: { id: 'desc' },
        pagination: { pageSize: 1, page: 1 },
      },
    });

  const { refetch: refetchLastReceiptsQuery } = useQuery({
    queryKey: ['findReceipts'],
    queryFn: lastReceiptsQueryFn,
  });

  const newReceiptFormMethods = useForm<ReceiptRequest>({
    defaultValues: {
      ...emptyReceipt,
      section,
      payments: [],
      entries: [],
      orders: [],
    },
    resolver: useYupValidationResolver(receiptsValidator),
  });

  const newReceiptMutationFn = (body: ReceiptRequest) => {
    return create({ token, body });
  };

  const onCreateReceiptMutationSuccess = () => {
    toast.success('Ricevuta creata correttamente');
    navigate('/tesoreria/entrate');
  };

  const onCreateReceiptMutationError = () => {
    toast.error('Errore durante la creazione della ricevuta');
  };

  const { mutate: newReceiptMutate } = useMutation({
    mutationKey: ['create', 'receipit'],
    mutationFn: newReceiptMutationFn,
    onSuccess: onCreateReceiptMutationSuccess,
    onError: onCreateReceiptMutationError,
  });

  const onSubmit = newReceiptFormMethods.handleSubmit(
    (body: ReceiptRequest) => {
      newReceiptMutate({
        ...body,
        profile: body.profile?.value as any,
        entries: body.entries?.map((_entry) => ({
          ..._entry,
          amount: sanitizeCurrencyValue(_entry.amount),
        })),
      });
    }
  );

  useEffect(() => {
    newReceiptFormMethods.setValue('section', section);
    refetchLastReceiptsQuery();
  }, [section]);

  const newReceiptFormSection = newReceiptFormMethods.watch().section || null;
  useEffect(() => {
    setSection(newReceiptFormSection);
  }, [newReceiptFormSection]);

  return (
    <FormProvider {...newReceiptFormMethods}>
      <TitlePage title='Crea ricevuta' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <form className='mt-12' onSubmit={onSubmit}>
          <div className='flex flex-row items-center justify-between'>
            <H1Styled noMargin>Crea ricevuta</H1Styled>
            <div className='flex gap-3'>
              <PrimaryButton type='button' onClick={goBack} outline>
                Annulla
              </PrimaryButton>
              <PrimaryButton type='submit'>Crea ricevuta</PrimaryButton>
            </div>
          </div>

          <ReceiptForm receiptForm={newReceiptFormMethods} treasury={true} />
        </form>
      </div>
    </FormProvider>
  );
};

export default TesoreriaCreaRicevuta;

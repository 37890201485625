import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getLessonTemplate,
  putLessonTemplate,
} from '../../../../api/lesson-template';
import BackButton from '../../../../components/Buttons/BackButton';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { IOption } from '../../../../components/Form/Multiselect';
import FormLessonTemplate from '../../../../components/FormLessonTemplate';
import DeleteLessonModal from '../../../../components/Modals/DeleteLessonModal';
import TitlePage from '../../../../components/TitlePage';
import H1Styled from '../../../../components/Typography/H1Styled';
import { emptyLessonTemplate } from '../../../../constants/lesson-template';
import { useAuth } from '../../../../contexts/Auth';
import { Profile } from '../../../../interfaces/profile';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { lessonTemplateValidator } from '../../../../validators/lesson';

export default function ModificaTemplateLezione() {
  const { id } = useParams();
  const [{ token }] = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const editLessonTemplateMethods = useForm({
    defaultValues: emptyLessonTemplate,
    mode: 'onSubmit',
    resolver: useYupValidationResolver(lessonTemplateValidator),
  });

  const fetchLessonTemplate = async () => {
    try {
      const { data } = await getLessonTemplate(id!, token);
      const availableTeacher = data?.attributes?.availableTeacher as {
        data: Profile[];
      };
      const fetchedLessonTemplate = {
        ...data.attributes,
        availableTeacher: availableTeacher.data?.map((teacher: any) => ({
          label: teacher.attributes.name + ' ' + teacher.attributes.surname,
          value: teacher.id,
        })),
      };
      editLessonTemplateMethods.reset(fetchedLessonTemplate);
    } catch (e) {
      console.log(e);
      toast.error('Errore durante il recupero dei dati', { type: 'error' });
      navigate('/corsi/template/lista-lezioni');
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const availableTeacher = editLessonTemplateMethods.getValues()
      .availableTeacher as IOption[];
    const updatedLessonTemplate = {
      ...editLessonTemplateMethods.getValues(),
      availableTeacher: availableTeacher.map((teacher: any) => teacher.value),
    };
    try {
      await putLessonTemplate(id!, updatedLessonTemplate, token);
      toast.success('Template modificato con successo', { type: 'success' });
      navigate('/corsi/template/lista-lezioni');
    } catch (e) {
      toast.error('Errore durante il salvataggio delle modifiche', {
        type: 'error',
      });
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (id) fetchLessonTemplate();
  }, [id]);

  return (
    <FormProvider {...editLessonTemplateMethods}>
      <TitlePage title='Modifica template lezione' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <div className='space-y-4'>
          <BackButton />

          <div className='flex flex-col sm:flex-row items-start md:items-center justify-between gap-4'>
            <H1Styled noMargin>Modifica template lezione</H1Styled>
            <div className='flex items-center gap-4'>
              {/* <DeleteLessonModal
                titleLesson='Assaggiatore di vino'
                buttonType='text'
              /> */}
              <PrimaryButton
                onClick={editLessonTemplateMethods.handleSubmit(onSubmit)}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Salva
              </PrimaryButton>
            </div>
          </div>

          <FormLessonTemplate />
        </div>

        <div className='space-y-5'></div>
      </div>
    </FormProvider>
  );
}

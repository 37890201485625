import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import React, { ChangeEvent, useState } from 'react';
import { FieldError } from 'react-hook-form';

interface InputPresentationalProps {
  id?: string;
  label?: string;
  name?: string;
  type: string;
  disabled?: boolean;
  info?: any;
  register?: any;
  required?: boolean;
  defaultValue?: string | number | Date;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  capitalize?: boolean;
  readOnly?: boolean;
  value?: string | number;
  error?: FieldError;
  legendIcons?: JSX.Element[];
  className?: string;
  maxLength?: number;
}
const InputPresentational: React.FC<InputPresentationalProps> = ({
  id,
  label = '',
  name,
  type,
  disabled,
  info,
  register,
  required = false,
  defaultValue,
  capitalize,
  readOnly,
  legendIcons,
  maxLength,
  error,
  className = '',
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`w-full ${className}`}>
      <div className='flex'>
        <label
          htmlFor={id}
          className='flex justify-between text-sm text-gray-500'
        >
          <div>{`${label}${required ? ' *' : ''}`} </div>
          {info}
        </label>
        {legendIcons && (
          <ul className='flex flex-row'>
            {legendIcons.map((legendIcon, idx) => {
              return (
                <li className='flex h-5 mx-1' key={`input-legend-${idx}`}>
                  {legendIcon}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className='flex items-center'>
        <input
          id={id}
          defaultValue={defaultValue}
          type={showPassword === true ? 'text' : type}
          autoComplete={id}
          name={name}
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          {...(register &&
            register(name, {
              required,
              ...(type === 'number' && {
                valueAsNumber: true,
              }),
            }))}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
            capitalize && ' capitalize'
          } ${
            error?.message
              ? 'border-red-100 focus:border-red-100 border-[1px]'
              : ''
          }`}
          {...rest}
        />
        {type === 'password' && (
          <div className='-ml-10 '>
            {showPassword && (
              <EyeIcon
                className='w-8 h-8 p-1 text-gray-500 rounded-full cursor-pointer hover:bg-gray-100'
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
            {!showPassword && (
              <EyeOffIcon
                className='w-8 h-8 p-1 text-gray-500 rounded-full cursor-pointer hover:bg-gray-100'
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        )}
      </div>
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};
export default InputPresentational;

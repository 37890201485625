import React from "react";

interface RoundedCheckboxPresentationalProps {
  id: string;
  label: string;
  name: string;
  describedby: string;
  register?: any;
  control?: any;
  defaultValue?: boolean;
  defaultChecked?: boolean;
  onChange?: any;
  checked?: boolean;
  
}
const RoundedCheckboxPresentational: React.FC<
  RoundedCheckboxPresentationalProps
> = ({ id, label, name, describedby, register, control, defaultValue, defaultChecked, onChange, checked }) => {
  return (
    <>
      <div className='relative flex items-start px-4'>
        <div className='flex items-center h-5'>
          <input
            id={id}
            aria-describedby={describedby}
            name={name}
            type='checkbox'
            className='w-4 h-4 rounded-full cursor-pointer focus:ring-primary text-primary border-primary'
            {...(register && register(name))}
            defaultValue={defaultChecked}
            
            control={control}
            onChange={(e) => {
              onChange && onChange(e);
            }}
            checked={checked}

          />
        </div>
        <label
          htmlFor={id}
          className='ml-3 text-sm font-normal text-gray-700 cursor-pointer'
        >
          {label}
        </label>
      </div>
    </>
  );
};

export default RoundedCheckboxPresentational;

import Spinner from "./Spinner";

const FullPageSpinner = () => {
  return (
    <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 z-10 backdrop-blur-md">
      <Spinner />
    </div>
  );
};

export default FullPageSpinner;

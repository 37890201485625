import axios, { AxiosError } from "axios";
export const addErrorInterceptor = (
  errorStatusToIntercept: number,
  callback: (_error: Error) => void
) =>
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const { response: axiosResponse = { status: undefined } } = error;
      const { status } = axiosResponse;
      status === errorStatusToIntercept && callback(error);
      return error;
    }
  );

import React, { PropsWithChildren } from "react";

interface State {
  image: File[];
  carousel: File[];
  setImage: React.Dispatch<React.SetStateAction<File[]>>;
  setCarousel: React.Dispatch<React.SetStateAction<File[]>>;
}

const FilesContext = React.createContext({} as State);

export const withFilesContext = (Component: any) => {
  const wrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const [image, setImage] = React.useState<File[]>([]);
    const [carousel, setCarousel] = React.useState<File[]>([]);

    return (
      <FilesContext.Provider value={{ image, carousel, setImage, setCarousel }}>
        <Component>{children}</Component>
      </FilesContext.Provider>
    );
  };
  return wrapper;
};

export const useFilesContext = () => React.useContext(FilesContext);

import axios from "axios";
import qs from "qs";
import { Params } from "../interfaces/params";
import { PaymentModeListResponse } from "../interfaces/PaymentModes";

const { REACT_APP_API_URL } = process.env;

export const paymentMode = async ({ search }: Params) => {
  const query = {
    pagination: {
      pageSize: 30,
    },
    filters: {
      title: { $contains: search },
    },
  };

  const { data } = await axios.get<PaymentModeListResponse>(
    `${REACT_APP_API_URL}/payment-modes?${qs.stringify(query)}`
  );
  return data;
};

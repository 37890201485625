import React from "react";

const TableLoading = ({ tableElements }: { tableElements: string[] }) => {
  function getRandomInt() {
    return Math.floor(Math.random() * 3);
  }

  const width = ["w-52", "w-32", "w-40"];

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50 text-sm uppercase text-gray-500">
        <tr>
          {tableElements.map((element, index) => (
            <th key={index} className="font-normal px-6 py-3 text-left tracking-wide">
              {element}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {[...Array(20)].map((e, i) => (
          <tr key={i} className="w-full flex-1 space-y-2 animate-pulse">
            {tableElements.map((element, index) => (
              <td className="p-3 py-4" key={index}>
                <div
                  className={"h-6 bg-gray-200 rounded " + width[getRandomInt()]}
                ></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableLoading;

import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import IconButton from '../Buttons/IconButton';
import { PencilIcon } from '@heroicons/react/outline';
import { useFormContext } from 'react-hook-form';
import { courseStatus } from '../../constants/course';
import { useQueryClient, useMutation } from 'react-query';
import { CourseRequest } from '../../interfaces/courses';
import { updateCourse } from '../../api/courses';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/Auth';
import { useParams } from 'react-router-dom';
import { CourseStatus } from '../../interfaces/commons';
import { isSegreteriaNazionale } from '../../libs/utils/auth';

interface StatusCourseModalProps {
  defaultStatus: CourseStatus;
}

const StatusCourseModal: React.FC<StatusCourseModalProps> = ({
  defaultStatus,
}) => {
  const { id: courseId } = useParams();
  const [{ token, profile }] = useAuth();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<CourseStatus>(defaultStatus);
  const { register, getValues, handleSubmit } = useFormContext<CourseRequest>();

  const toggleModal = () => {
    setOpen(!open);
  };

  const { mutateAsync: saveCourseMutation, isLoading } = useMutation({
    mutationKey: ['saveCourse', courseId],
    mutationFn: ({
      courseId,
      courseWithUpdatedStatus,
    }: {
      courseId: string;
      courseWithUpdatedStatus: CourseRequest;
    }) => updateCourse(courseId, courseWithUpdatedStatus, token),
    onSuccess: () => {
      toast.success('Stato corso modificato con successo!');
      queryClient.invalidateQueries({ queryKey: ['course', courseId] });
      setOpen(false);
    },
    onError: (err) => {
      console.log('err', err);
      toast.error('Qualcosa è andato storto!');
    },
  });

  const onSubmit = () => {
    saveCourseMutation({
      courseId: courseId!,
      courseWithUpdatedStatus: {
        ...getValues(),
        status: status || getValues().status,
      },
    });
  };

  return (
    <>
      {isSegreteriaNazionale(profile) &&
        defaultStatus !== CourseStatus.CANCELED && (
          <IconButton orangeTxt onClick={toggleModal}>
            <PencilIcon className='w-6 h-6' />
          </IconButton>
        )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={toggleModal}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM text-gray-700 mb-6'>
                  Stato del corso
                </h4>

                <fieldset className='mt-4'>
                  <div className='space-y-4'>
                    {courseStatus.map((courseStatus) => (
                      <div key={courseStatus.id} className='flex items-center'>
                        <input
                          id={courseStatus.id}
                          type='radio'
                          {...register('status')}
                          value={courseStatus.id}
                          checked={courseStatus.id === status}
                          className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
                          onChange={() => setStatus(courseStatus.id)}
                        />
                        <label
                          htmlFor={courseStatus.id}
                          className='ml-3 block font-normal text-gray-700'
                        >
                          {courseStatus.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton
                    isLoading={isLoading}
                    disabled={isLoading}
                    small
                    onClick={handleSubmit(onSubmit)}
                  >
                    Salva selezione
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default StatusCourseModal;

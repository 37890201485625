import React, { useState } from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { FetchCheckboxProps } from '../../../interfaces/fetchCheckbox';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import classNames from '../../../libs/utils/classNames';
import CheckboxLoading from '../../Layout/Loading/CheckboxLoading';
import { BackspaceIcon } from '@heroicons/react/outline';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';

const FetchCheckbox = ({
  api,
  label,
  queryName,
  mobile,
  filterName,
}: FetchCheckboxProps) => {
  const [params, setParams] = useState({ pageSize: 300 });
  const [{ token }] = useAuth();
  const query = useQuery([queryName, params], () => api(params, token));
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSearch = (i: any) => {
    // TODO: aggiungere attesa di tot secondi quando cambia il search prima di fare nuove chiamate
    setSearch(i);
    const newFilters = {
      ...params,
      search: i,
    };
    setParams(newFilters);
  };

  const onChangeCheck = (item: any) => {
    searchParams.delete('page');
    const exist = searchParams.getAll(filterName).includes(item);
    if (!exist) {
      searchParams.append(filterName, item);
      setSearchParams(searchParams);
    } else {
      const newFilter = [...searchParams].filter(
        ([key, value]) => key !== filterName || value !== item
      );
      setSearchParams(newFilter);
    }
  };

  const List = () => {
    return (
      <form className='space-y-4 max-h-96 overflow-auto mt-5 pt-1'>
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <CheckboxLoading />
          ))
          .with({ status: 'error' }, () => (
            <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
              Errore caricamento dati
            </div>
          ))
          .with({ status: 'success' }, (query) => {
            if (query.data) {
              return Object.keys(query.data.data).length === 0 ? (
                <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
                  Nessun risultato
                </div>
              ) : (
                query.data.data.map((section: any) => (
                  <div key={section.id} className='flex items-center pl-1 '>
                    <input
                      id={`${section.id}`}
                      name={`${section.id}`}
                      type='checkbox'
                      checked={searchParams
                        .getAll(filterName)
                        .includes(
                          section.attributes.name || section.attributes.title
                        )}
                      className='h-4 w-4 border-gray-300 rounded text-primary focus:ring-primary cursor-pointer'
                      onChange={() =>
                        onChangeCheck(
                          section.attributes.name || section.attributes.title
                        )
                      }
                    />
                    <label
                      htmlFor={`${section.id}`}
                      className='ml-3 pr-6 text-sm font-normal text-gray-900 whitespace-nowrap truncate w-72 cursor-pointer select-none'
                    >
                      {section.attributes.name || section.attributes.title}
                    </label>
                  </div>
                ))
              );
            } else {
              return (
                <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
                  Non hai i permessi per visualizzarli
                </div>
              );
            }
          })
          .exhaustive()}
      </form>
    );
  };

  return mobile ? (
    <Disclosure
      as='div'
      key={label}
      className='border-t border-gray-200 px-4 py-6 pr-1'
    >
      {({ open }) => (
        <>
          <h3 className='-mx-2 -my-3 flow-root'>
            <Disclosure.Button className='px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400'>
              <span className='font-normal text-gray-800'>{label}</span>
              <span className='ml-6 flex items-center'>
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-5 w-5 transform'
                  )}
                  aria-hidden='true'
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className='pt-6'>
            <div className='flex items-center'>
              <input
                type='text'
                name='search'
                id='search'
                className='focus:ring-primary focus:border-primary block text-sm border-gray-300 rounded-full w-[96%]'
                placeholder='Cerca..'
                autoComplete='off'
                value={search}
                onChange={(e) => onChangeSearch(e.target.value)}
              />
              {search && (
                <BackspaceIcon
                  className='h-5 w-5 text-gray-500 -ml-8 cursor-pointer'
                  aria-hidden='true'
                  onClick={() => {
                    onChangeSearch('');
                  }}
                />
              )}
            </div>
            <List />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Popover key='sections' className='px-4 relative inline-block text-left'>
      <Popover.Button className='group inline-flex justify-center text-sm font-normal text-gray-700 hover:text-gray-800'>
        <span>{label}</span>

        <ChevronDownIcon
          className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
          aria-hidden='true'
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 pr-1 ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='flex items-center'>
            <input
              type='text'
              name='search'
              id='search'
              className='focus:ring-primary focus:border-primary block text-sm border-gray-300   rounded-full w-[96%]'
              placeholder='Cerca...'
              autoComplete='off'
              value={search}
              onChange={(e) => onChangeSearch(e.target.value)}
            />
            {search && (
              <BackspaceIcon
                className='h-5 w-5 text-gray-500 -ml-8 cursor-pointer'
                aria-hidden='true'
                onClick={() => {
                  onChangeSearch('');
                }}
              />
            )}
          </div>

          <List />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default FetchCheckbox;

import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import InputPresentational from './Form/InputPresentational';
import { MinusIcon } from '@heroicons/react/outline';
import { EventParticipantForm } from './Modals/AddEventParticipantsModal';

interface Props {
  index: number;
  disabled?: boolean;
  productForm: UseFormReturn<EventParticipantForm, any>;
}
const ExtraPartecipantItem: React.FC<Props> = ({
  index,
  disabled,
  productForm,
}) => {
  const { register, watch, setValue, formState } = productForm;
  const { errors } = formState;
  return (
    <div className='w-full flex items-center flex-row text-left gap-4'>
      <div className='flex-shrink-0 w-10 h-10 flex items-center justify-center border border-primary rounded-full'>
        <span className='text-primary font-IBM font-semibold text-lg'>
          {index + 1}
        </span>
      </div>
      <div className='w-full flex flex-col sm:flex-row md:flex-col lg:flex-row gap-4'>
        <div className='w-full'>
          <InputPresentational
            disabled={disabled}
            id='name'
            label='Nome'
            name={`guests[${index}].firstName`}
            type='text'
            register={register}
            //@ts-ignore
            error={errors['guests'] && errors[`guests`][index]?.firstName}
          />
        </div>
        <div className='w-full'>
          <InputPresentational
            id='surname'
            label='Cognome'
            name={`guests[${index}].lastName`}
            type='text'
            disabled={disabled}
            error={errors['guests'] && errors[`guests`][index]?.lastName}
            register={register}
          />
        </div>
      </div>
      <button
        disabled={disabled}
        onClick={() =>
          // @ts-ignore
          setValue(
            'guests',
            (watch('guests') || [])?.filter(
              (_participant, _index) => _index !== index
            )
          )
        }
        type='button'
        className={`w-8 h-8 border flex-shrink-0 flex items-center justify-center rounded-full ${
          disabled
            ? 'cursor-not-allowed border-primary/70 text-primary/70'
            : 'border-primary text-primary hover:bg-primary hover:text-white'
        }`}
      >
        <MinusIcon className='w-4 h-4' />
      </button>
    </div>
  );
};

export default ExtraPartecipantItem;

import qs from 'qs';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import eventParticipants from '../../../../api/eventParticipants';
import eventsAPIs from '../../../../api/events';
import sectionsAPIs from '../../../../api/section';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import { useAuth } from '../../../../contexts/Auth';
import { FilterType } from '../../../../interfaces/filters';
import { ParticipantsResponse } from '../../../../interfaces/participants';
import UtenteEventi from '../../Tables/UtenteEventi';
import payments from '../../../../api/payments';
import { toast } from 'react-toastify';
import Pagination from '../../../../components/Pagination';
import { PAYMENT_STATUSES_OPTIONS_SOURCE } from '../../../../interfaces/payments';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ModificaUtenteEventi = () => {
  const [{ token }] = useAuth();
  const [queryParams] = useSearchParams();
  const params = useParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);
  const [selectedParticipants, setSelectedPartecipants] = useState<
    Partial<ParticipantsResponse & { multiple?: boolean }>[]
  >([]);
  const queryClient = useQueryClient();

  const participantsQuery = useQuery(
    ['getParticipants', ...debounceSearchParams],
    () => {
      return eventParticipants.find({
        token,
        query: {
          populate: [
            'profile.section',
            'profile.badge',
            'event.section.nation',
            'event.details.otherInfos',
            'event.location',
            'event.profileCategory',
            'event.headquarterExpense.estimateBalance',
            'event.headquarterExpense.finalBalance',
            'event.balance.estimateBalance',
            'event.balance.finalBalance',
            'profile.user',
            'profile.profileCategory',
            'guests',
            'isPresent',
            'profile.orders.payment',
            'profile.orders.event',
            'profile.orders.coupon',
          ],
          sort: 'event.startDate:desc',
          filters: {
            profile: { id: params.id },
            $or: [
              {
                event: {
                  $or: [
                    {
                      id: Object.entries(
                        qs.parse(queryParams.get('events') + '')
                      )
                        .map(([, value]) => value)
                        .map((elem: any) => elem.value),
                    },
                    {
                      title: { $contains: debounceSearchParams.get('search') },
                    },
                    {
                      section: {
                        id: {
                          $in: Object.entries(
                            qs.parse(queryParams.get('sections') + '')
                          )
                            .map(([, value]) => value)
                            .map((elem: any) => elem.value),
                        },
                      },
                    },
                  ],
                  orders: {
                    payment: {
                      status: {
                        $in: Object.entries(
                          qs.parse(queryParams.get('status') + '')
                        )
                          .map(([, value]) => value)
                          .map((elem: any) => elem.value),
                      },
                    },
                    profile: params.id,
                  },
                },
              },
              {
                profile: {
                  $or: [
                    {
                      section: {
                        name: { $contains: debounceSearchParams.get('search') },
                      },
                    },
                    {
                      orders: {
                        payment: {
                          method: {
                            $contains: debounceSearchParams.get('search'),
                          },
                        },
                      },
                    },
                    {
                      orders: {
                        payment: {
                          amount: {
                            $contains: debounceSearchParams.get('search'),
                          },
                        },
                      },
                    },
                  ],
                },
              },
            ],
            isPresent: {
              $eq: debounceSearchParams.get('isPresent'),
            },
          },
          pagination: {
            page: queryParams.get('page') || 1,
            pageSize: queryParams.get('pageSize'),
          },
        },
      });
    }
  );

  const { mutate: mutatePaymentUpdate, isLoading: isUpdatingPayment } =
    useMutation('updatePayment', payments.update, {
      onSuccess: () => {
        participantsQuery.refetch();
        queryClient.invalidateQueries(['getParticipants']);
        toast.success(`Operazione conclusa con successo!`);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    });
  return (
    <>
      <FiltersBar
        filters={[
          {
            type: FilterType.MULTISELECT,
            attribute: 'status',
            label: 'Stato pagamento',
            key: 'lessonStatusesList',
            source: () => ({
              data: PAYMENT_STATUSES_OPTIONS_SOURCE,
            }),
          },
          {
            type: FilterType.CHECKBOX,
            attribute: 'isPresent',
            label: 'Presente',
          },
          {
            type: FilterType.MULTISELECT,
            attribute: 'sections',
            label: 'Sezione',
            key: 'sectionsListFilters',
            searchForAttributes: ['name'],
            source: (data: any) =>
              sectionsAPIs.findOptions({
                ...data,
                query: { ...data.query },
              }),
          },
          {
            type: FilterType.MULTISELECT,
            attribute: 'events',
            label: 'Eventi',
            key: 'userEventsFilters',
            searchForAttributes: ['title'],
            source: eventsAPIs.filtersFind,
          },
          {
            type: FilterType.SEARCH_BAR,
            attribute: 'search',
            label: 'Cerca...',
          },
        ]}
      />
      <UtenteEventi
        participantsQuery={participantsQuery}
        selectedParticipants={selectedParticipants}
        setSelectedParticipants={setSelectedPartecipants}
        isLoading={isUpdatingPayment}
        searchWords={[
          debounceSearchParams.get('search') + '',
          ...Object.entries(qs.parse(queryParams.get('status') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.label),
          ...Object.entries(qs.parse(queryParams.get('region') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.label),
        ]}
        onUpdatePayment={(data) =>
          mutatePaymentUpdate({ token, id: data.id, body: data.payment })
        }
      />
      <Pagination pagination={participantsQuery?.data?.meta?.pagination} />
    </>
  );
};

export default ModificaUtenteEventi;

import { useSearchParams } from 'react-router-dom';
import React from 'react';
import Badge from '../../../components/Badge';
import RegisterPaymentModal from '../../../components/Modals/RegisterPaymentModal';
import { useQuery } from 'react-query';
import { getLessons } from '../../../api/lesson';
import { useAuth } from '../../../contexts/Auth';
import { Profile } from '../../../interfaces/profile';
import qs from 'qs';
import { TeachersAcceptanceStatus } from '../../../interfaces/lesson';
import { useDebounce } from 'use-debounce';
import moment from 'moment';
import Pagination from '../../../components/Pagination';
import { CourseStatus } from '../../../interfaces/commons';
import TableHeader from '../../../components/Table/TableHeader';
import formatTableSort from '../../../components/Table/helpers/formatTableSort';

interface UtenteLezioniTableProps {
  profile: Profile;
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const UtenteLezioniTable: React.FC<UtenteLezioniTableProps> = ({ profile }) => {
  const [{ token }] = useAuth();
  const [searchParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const { data } = useQuery(
    ['getTeacherLessons', ...debounceSearchParams],
    () =>
      getLessons({
        token,
        filters: {
          teacher: profile.id,
          course: {
            status: { $ne: CourseStatus.CANCELED },
            id: { $notNull: true },
          },
          $or: [
            {
              course: {
                title: {
                  $contains: debounceSearchParams.get('search') || undefined,
                },
              },
            },
            {
              title: {
                $contains: debounceSearchParams.get('search') || undefined,
              },
            },
            ...((
              Object.entries(
                qs.parse(debounceSearchParams.get('status') as string) as any
              ) as { key: string; value: string }[][]
            ).map(([, value]) => ({
              teachersAcceptanceStatus: value?.value,
            })) as any),
            ...((
              Object.entries(
                qs.parse(debounceSearchParams.get('courses') as string) as any
              ) as { key: string; value: string }[][]
            ).map(([, value]) => ({ course: value?.value })) as any),
          ],
        },
        sort:
          formatTableSort(debounceSearchParams.get('sort') as string) ||
          undefined,
        searchParams,
      })
  );

  const tableElements = [
    { label: 'N', key: 'id' },
    { label: 'Data', key: 'date' },
    { label: 'Corso', key: null },
    { label: 'Lezione', key: 'title' },
    { label: 'Stato', key: 'teachersAcceptanceStatus' },
    { label: 'Compenso', key: null },
    { label: 'Rimborso', key: null },
    { label: 'Data pagamento', key: 'paymentDate' },
    { label: '', key: null },
  ];

  return (
    <>
      <div className='-my-2 overflow-x-auto'>
        <div className='inline-block min-w-full py-2 align-middle'>
          <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
            <table className='min-w-full divide-y divide-gray-300'>
              <TableHeader columnsNames={tableElements} />

              <thead className='bg-gray-50 text-sm uppercase text-gray-500'></thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {data?.data.map((lezione) => (
                  <tr key={lezione.id} className='cursor-default'>
                    <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                      {lezione.id}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 uppercase'>
                      {moment(lezione.attributes.date).format('DD/MM/YYYY')}
                    </td>

                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lezione.attributes.course?.data?.attributes?.title}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lezione?.attributes?.title}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lezione.attributes.teachersAcceptanceStatus ===
                        TeachersAcceptanceStatus.ACCEPTED && (
                        <Badge green text={'Accettato'} />
                      )}
                      {lezione.attributes.teachersAcceptanceStatus ===
                        TeachersAcceptanceStatus.PENDING && (
                        <Badge yellow text={'In attesa'} />
                      )}
                      {lezione.attributes.teachersAcceptanceStatus ===
                        TeachersAcceptanceStatus.REJECTED && (
                        <Badge red text={'Rifiutato'} />
                      )}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lezione.attributes.actualPayment || (
                        <RegisterPaymentModal lesson={lezione} />
                      )}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lezione.attributes.actualRefund}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {lezione.attributes.paymentDate
                        ? moment(lezione.attributes.paymentDate).format('L')
                        : '-'}
                    </td>
                    {/* <td className='whitespace-nowrap px-6 py-4'>
                    <UnderlineButton
                      small
                      onClick={() =>
                        mutateTeachersSpendings({
                          token,
                          profileId: profile.id,
                        })
                      }
                    >
                      Visualizza documenti
                    </UnderlineButton>
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination pagination={data?.meta.pagination} />
    </>
  );
};

export default UtenteLezioniTable;

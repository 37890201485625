import { Category } from '../interfaces/commons';
import { CourseLessonRequest } from '../interfaces/lesson';
import { formatDate } from '../libs/utils/formatters/datetimeFormatter';

export const emptyLesson: CourseLessonRequest = {
  title: '',
  description: '',
  minutes: 0,
  estimatePayment: 0,
  estimatePaymentLimit: 0,
  estimateRefund: 0,
  category: Category.ASSAGGIATORE,
  order: 1,
  date: '',
  areSpendingsReceived: false,
};

import React from 'react';

import { Control, FieldError, UseControllerProps } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { profilelist } from '../../api/profile';
import { useAuth } from '../../contexts/Auth';

import Multiselect, { IOption } from '../Form/Multiselect';

type TeachersSelectProps = {
  id: string;
  label: string;
  error?: FieldError;
  required?: boolean;
} & UseControllerProps;

const TeachersSelect: React.FC<TeachersSelectProps> = ({
  control,
  id,
  name,
  label,
  error,
  required = false,
}) => {
  const [searchParams] = useSearchParams();
  searchParams.set('isTeacher', 'true');
  const [{ token }] = useAuth();

  const query = useQuery('teachers', () => profilelist(searchParams, token));

  const options: IOption[] =
    query.data?.data.map((profile) => ({
      label: profile.attributes.name + ' ' + profile.attributes.surname,
      value: profile.id,
    })) || [];

  return (
    <Multiselect
      id={id}
      label={label}
      name={name}
      control={control}
      options={options}
      error={error}
      required={required}
    />
  );
};

export default TeachersSelect;

import { useState } from 'react';
import Checkbox from '../Form/Checkbox';
import _ from 'lodash';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import classNames from '../../libs/utils/classNames';
import useTableSort from './hooks/useTableSort';

interface Props {
  columnsNames: { key: string | null; label: string; class?: string }[];
  checked?: boolean;
  onCheckAll?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  tableName?: string;
}

const TableHeader: React.FC<Props> = (props) => {
  const { columnsNames, checked, onCheckAll } = props;
  const { onSort, sort } = useTableSort();

  return (
    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
      <tr className='custom-padding-table'>
        {onCheckAll && (
          <th scope='col' className='relative w-12 px-6 sm:w-16 sm:px-8'>
            <Checkbox checked={checked} onChange={(e) => onCheckAll(e)} />
          </th>
        )}
        {columnsNames.map((element, idx) => (
          <th
            key={`table-header-${idx}`}
            className={classNames(
              'font-normal px-3 py-3 text-left tracking-wide  select-none bg-gray-50',
              element.key ? 'cursor-pointer hover:bg-gray-200' : '',
              element.key && sort[element.key] ? 'bg-slate-100' : '',
              element.class || ''
            )}
            onClick={() => element.key && onSort(element.key)}
          >
            <span className='flex justify-between'>
              {element.label}
              {element.key && sort[element.key] === 'DESC' && (
                <ArrowDownIcon className='h-4 w-4' />
              )}
              {element.key && sort[element.key] === 'ASC' && (
                <ArrowUpIcon className='h-4 w-4' />
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;

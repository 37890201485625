import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Audience from "./Pages/audience";
import ListaComuicati from "./Pages/lista-comunicati";
import NuovoComunicato from "./Pages/NuovoComunicato";
import ModificaComunicato from "./Pages/ModificaComunicato";


const Comunicati = () => {
  const SIDEBAR_ITEMS = [
    { href: "/comunicati", label: "Tutti i comunicati" },
    { href: "/comunicati/nuovo", label: "Nuovo comunicato" },
    { href: "/comunicati/audience", label: "Audience" },
  ];

  return (
    <div className='grid w-full grid-cols-12 gap-8 px-4 pb-10 pt-7 lg:py-12 lg:px-10'>
      <Sidebar items={SIDEBAR_ITEMS} />
      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
      <Routes>
        <Route index element={<ListaComuicati />} />
        <Route path='/nuovo' element={<NuovoComunicato />} />
        <Route path='dettaglio-comunicato/:id' element={<ModificaComunicato />} />
        <Route path='/audience' element={<Audience />} />
      </Routes>
      </div>
    </div>
  );
};

export default Comunicati;

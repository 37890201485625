import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { match } from 'ts-pattern';
import BadgeBoolean from '../../../components/BadgeBoolean';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { BadgeToPrint } from '../../../interfaces/badgeToPrint';
import { StrapiResponseList } from '../../../interfaces/commons';
import classNames from '../../../libs/utils/classNames';
import { useSearchParams } from 'react-router-dom';
import { getCardHistoryLabel } from '../../../libs/utils/helpers';
import { CardHistoryType } from '../../../interfaces/cardHistory';
import TableHeader from '../../../components/Table/TableHeader';

const tableElements = [
  { key: null, label: 'Tessera' },
  {
    key: 'surname',
    label: 'Cognome',
    class: 'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-0',
  },
  {
    key: 'name',
    label: 'Nome',
    class: 'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-[120px]',
  },
  { key: null, label: 'Data di pagamento' },
  { key: null, label: "Categoria dell'utente" },
  { key: null, label: 'Anno' },
  { key: null, label: 'Tessera stampata' },
  { key: null, label: 'Lettera stampata' },
  { key: null, label: 'Tipologia' },
  { key: null, label: 'Digitale' },
  { key: null, label: 'Corso (Con acquisto quota associativa)' },
];

interface StampaTableProps {
  query: UseQueryResult<StrapiResponseList<BadgeToPrint>>;
  selectedBadges: BadgeToPrint[];
  setSelectedBadges: any;
  applyForAll: boolean;
}

const SelectCards: React.FC<StampaTableProps> = ({
  query,
  selectedBadges,
  setSelectedBadges,
  applyForAll,
}) => {
  const [checked, setChecked] = useState(false);

  function toggleAll(data: BadgeToPrint[]) {
    setChecked(!checked);
    checked ? setSelectedBadges([]) : setSelectedBadges(data);
  }
  useEffect(() => {
    setSelectedBadges(selectedBadges);
  }, [selectedBadges]);

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='relative overflow-hidden ring-1 border ring-black ring-opacity-5'>
          {match(query)
            .with({ status: 'success' }, (query) => {
              // setData(query.data.data);
              if (query.data) {
                return (
                  <table className='min-w-full table-fixed divide-y divide-gray-300'>
                    <TableHeader
                      columnsNames={tableElements}
                      checked={checked || applyForAll}
                      onCheckAll={() => toggleAll(query.data.data)}
                    />
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data.map((subscription) => (
                        <tr
                          key={subscription.id}
                          className={
                            selectedBadges.includes(subscription) || applyForAll
                              ? 'bg-sand-50'
                              : undefined
                          }
                        >
                          <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                            {(selectedBadges.includes(subscription) ||
                              applyForAll) && (
                              <div className='absolute inset-y-0 left-0 w-0.5 bg-primary' />
                            )}
                            <input
                              type='checkbox'
                              className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-8'
                              value={subscription.id}
                              disabled={applyForAll}
                              checked={
                                selectedBadges.includes(subscription) ||
                                applyForAll
                              }
                              onChange={(e) => {
                                setChecked(false);
                                setSelectedBadges(
                                  e.target.checked
                                    ? [...selectedBadges, subscription]
                                    : selectedBadges.filter(
                                        (p) => p !== subscription
                                      )
                                );
                              }}
                            />
                          </td>
                          <td
                            className={classNames(
                              'whitespace-nowrap py-4 pr-3 text-sm',
                              selectedBadges.includes(subscription)
                                ? 'text-primary'
                                : 'text-gray-600'
                            )}
                          >
                            {subscription.profile?.badge?.badgeNumber}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {subscription?.profile?.surname}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {subscription?.profile?.name}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {moment(subscription?.payment?.date).format(
                              'DD/MM/YYYY'
                            )}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {subscription?.profile?.profileCategory?.title}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {subscription.cardHistory?.year}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <BadgeBoolean
                              active={subscription.cardHistory?.printed}
                            />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <BadgeBoolean
                              active={subscription.cardHistory?.letterPrinted}
                            />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {getCardHistoryLabel(
                              subscription.cardHistory?.type as CardHistoryType
                            )}
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <BadgeBoolean
                              active={
                                !!subscription?.profile?.printDigitalBadge
                              }
                            />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {subscription.associatedEntity?.title ||
                              subscription.associatedEntity?.title}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Nessun risultato
                  </div>
                );
              }
            })
            .with({ status: 'idle' }, { status: 'loading' }, () => {
              return <p>Caricamento Utenti...</p>;
            })
            .with({ status: 'error' }, () => {
              return <p>Errore caricamento Utenti...</p>;
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default SelectCards;

import {
  InfoPaymentMethod,
  PaymentMethod,
  PaymentStatus,
} from '../../interfaces/payments';
import { ReceiptEntry, ReceiptResponse } from '../../interfaces/receipts';

export const mapPaymentMethod = (
  method: PaymentMethod | null | undefined
): string => {
  if (method === PaymentMethod.PAYPAL) return 'Paypal';
  if (method === PaymentMethod.CASH) return 'Contanti';
  if (method === PaymentMethod.BANK_CHECK) return 'Assegno';
  if (method === PaymentMethod.BANK_TRANSFER_SECTION)
    return 'Bonifico a sezione';
  if (method === PaymentMethod.BANK_TRANSFER_SITE) return 'Bonifico a sede';
  if (method === PaymentMethod.BANCOMAT) return 'Bancomat';
  if (method === PaymentMethod.PAYMENT_SLIP) return 'Bollettino';
  if (method === PaymentMethod.AXERVE) return 'Axerve';
  return '-';
};

export const mapInfoMethods = (method: InfoPaymentMethod | null): string => {
  switch (method) {
    case InfoPaymentMethod.CASH_SECTION:
      return 'Contanti sezione';
    case InfoPaymentMethod.CASH_HEADQUARTER:
      return 'Contanti sede';
    case InfoPaymentMethod.BANK_TRANSFER_SECTION:
      return 'Bonifico sezione';
    case InfoPaymentMethod.BANK_TRANSFER_HEADQUARTER:
      return 'Bonifico sede';
    case InfoPaymentMethod.ATM:
      return 'Bancomat';
    case InfoPaymentMethod.CARD:
      return 'Carta';
    default:
      return '-';
  }
};

export const getCouponValue = (receiptResponse: ReceiptResponse): number => {
  const receipt = receiptResponse.attributes;

  if (receipt.orders?.data && receipt.orders?.data?.length > 0) {
    const order = receipt.orders.data[0];

    if (order.attributes?.coupon?.data) {
      return order.attributes.coupon.data.attributes?.price || 0;
    }
  }

  return 0;
};

export const applyCouponToEntries = (
  entries: ReceiptEntry[],
  couponAmount: number
) => {
  const updatedEntries = [...entries]; // Copia dell'array entries

  for (let i = 0; i < updatedEntries.length && couponAmount > 0; i++) {
    const entry = updatedEntries[i];

    if (entry.amount && entry.amount > couponAmount) {
      entry.amount -= couponAmount;
      couponAmount = 0;
    } else {
      couponAmount -= entry.amount || 0;
      entry.amount = 0;
    }
  }

  return updatedEntries;
};

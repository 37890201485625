import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  tableName?: string | undefined;
}

const DEFAULT_TABLE_NAME = 'sort';

const useTableSort = (props?: Props) => {
  const tableName = props?.tableName;
  const queryName = useMemo(() => tableName || DEFAULT_TABLE_NAME, [tableName]);

  const [searchParams, setSearchParams] = useSearchParams();
  const sort = useMemo(
    () => JSON.parse(searchParams.get(queryName) || '{}') || ({} as any),
    [searchParams]
  );

  const onSort = (key: string) => {
    //non ha uno stato iniziale -> lo setto ad ASC

    if (!_.has(sort, key)) {
      searchParams.set(queryName, JSON.stringify({ ...sort, [key]: 'ASC' }));
    } else {
      //ha stato iniziale ASC -> lo setto a DESC
      if (sort[key] === 'ASC') {
        searchParams.set(queryName, JSON.stringify({ ...sort, [key]: 'DESC' }));
      } else {
        //ha stato iniziale DESC -> lo tolgo
        searchParams.set(queryName, JSON.stringify(_.omit(sort, key)));
      }
    }
    setSearchParams(searchParams);
  };

  return { onSort, sort };
};

export default useTableSort;

import moment from 'moment';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import AlertMessage from '../../../components/AlertMessage';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { FINAL_BALANCE_EXPIRATION_DAYS } from '../../../constants/events';
import { EventListResponse, EventStatus } from '../../../interfaces/events';
import { isDelegato } from '../../../libs/utils/auth';
import { useAuth } from '../../../contexts/Auth';
import BadgeStatus from '../../../components/BadgeStatus';

interface EventsToReportProps {
  query: UseQueryResult<EventListResponse>;
}
const EventsToReport: React.FC<EventsToReportProps> = ({ query }) => {
  const tableElements = ['Data evento', 'Evento', 'Sezione'];
  const navigate = useNavigate();
  const [{ profile }] = useAuth();

  if (!isDelegato(profile)) {
    tableElements.push('Stato');
  }

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, idx) => (
                          <th
                            key={idx}
                            className='font-normal px-6 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                        <th
                          colSpan={3}
                          className='font-normal px-6 py-3 text-left tracking-wide'
                        ></th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data
                        .filter((event) =>
                          !isDelegato(profile)
                            ? event.attributes.status ===
                                EventStatus.FINAL_BALANCE_DELEGATION ||
                              event.attributes.status === EventStatus.PUBLISHED
                            : event.attributes.status === EventStatus.PUBLISHED
                        )
                        .map((event) => (
                          <tr
                            key={event.id}
                            className='cursor-pointer hover:bg-neutral-100'
                            onClick={() => {
                              navigate(`eventi/modifica/${event.id}`);
                            }}
                          >
                            <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
                              {event.attributes.startDate
                                ? new Date(
                                    event.attributes.startDate
                                  ).toLocaleDateString('it-IT')
                                : '-'}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes.title}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes.section?.data?.attributes.name}
                            </td>
                            {!isDelegato(profile) && (
                              <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                                <BadgeStatus status={event.attributes.status} />
                              </td>
                            )}
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {moment().diff(
                                moment(event.attributes.startDate),
                                'days'
                              ) > FINAL_BALANCE_EXPIRATION_DAYS && (
                                <AlertMessage
                                  borderLeft
                                  noTitle
                                  text='Evento da rendicontare urgentemente'
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default EventsToReport;

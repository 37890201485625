import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import ListaEventi from './Pages/lista-eventi';
import ModificaEvento from './Pages/modifica-evento';
import NuovoEvento from './Pages/nuovo-evento';

const Eventi = () => {
  const SIDEBAR_ITEMS = [
    { href: '/eventi', label: 'Tutti gli eventi' },
    { href: '/eventi/nuovo', label: 'Crea nuovo evento' },
  ];

  return (
    <div className='pt-7 pb-10 lg:py-12 px-4 lg:px-10 w-full grid grid-cols-12 gap-8'>
      <Sidebar items={SIDEBAR_ITEMS} />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <Routes>
          <Route index element={<ListaEventi />} />
          <Route path='nuovo/*' element={<NuovoEvento />} />
          <Route path='modifica/:eventId/*' element={<ModificaEvento />} />
        </Routes>
      </div>
    </div>
  );
};

export default Eventi;

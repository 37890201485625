import React from "react";

interface CoccardaRightProps {
  property: string;
}
const CoccardaRight: React.FC<CoccardaRightProps> = ({ property }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Coccarda_right"
      data-name="Coccarda right"
      width="24.977"
      height="52.331"
      viewBox="0 0 24.977 52.331"
      className={property}
    >
      <path
        id="Tracciato_468"
        data-name="Tracciato 468"
        d="M28.25,83.868s5.171-1.412,7.8,1.509C36.055,85.377,31.426,87.974,28.25,83.868Z"
        transform="translate(-27.877 -33.855)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_469"
        data-name="Tracciato 469"
        d="M38.471,80.185a3.775,3.775,0,0,0,.309-2.272,5.759,5.759,0,0,0-3.742,3.5A12.7,12.7,0,0,1,38.471,80.185Z"
        transform="translate(-34.696 -31.58)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_470"
        data-name="Tracciato 470"
        d="M17.752,80.436s4.631-2.706,7.927-.572C25.679,79.864,21.878,83.576,17.752,80.436Z"
        transform="translate(-11.259 -32.038)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_471"
        data-name="Tracciato 471"
        d="M29.1,76.059a3.8,3.8,0,0,0-.287-2.277,5.777,5.777,0,0,0-2.714,4.35A12.786,12.786,0,0,1,29.1,76.059Z"
        transform="translate(-19.681 -29.905)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_472"
        data-name="Tracciato 472"
        d="M9.213,74.146s3.776-3.818,7.51-2.613C16.723,71.532,14.007,76.1,9.213,74.146Z"
        transform="translate(2.775 -28.899)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_473"
        data-name="Tracciato 473"
        d="M20.51,69.715a3.774,3.774,0,0,0-.861-2.123,5.784,5.784,0,0,0-1.5,4.907A12.762,12.762,0,0,1,20.51,69.715Z"
        transform="translate(-6.275 -27.396)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_474"
        data-name="Tracciato 474"
        d="M3.211,65.51s2.664-4.669,6.583-4.477C9.794,61.033,8.347,66.158,3.211,65.51Z"
        transform="translate(13.273 -24.735)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_475"
        data-name="Tracciato 475"
        d="M13.976,61.582a3.773,3.773,0,0,0-1.381-1.826,5.794,5.794,0,0,0-.185,5.13A12.9,12.9,0,0,1,13.976,61.582Z"
        transform="translate(3.924 -24.22)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_476"
        data-name="Tracciato 476"
        d="M.16,55.221s1.37-5.2,5.2-6.035C5.365,49.186,5.288,54.511.16,55.221Z"
        transform="translate(19.516 -19.936)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_477"
        data-name="Tracciato 477"
        d="M10.04,52.215a3.746,3.746,0,0,0-1.8-1.4,5.791,5.791,0,0,0,1.142,5A12.708,12.708,0,0,1,10.04,52.215Z"
        transform="translate(10.126 -20.594)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_478"
        data-name="Tracciato 478"
        d="M0,44.128s-.018-5.382,3.47-7.182C3.47,36.946,4.769,42.109,0,44.128Z"
        transform="translate(21.345 -14.975)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_479"
        data-name="Tracciato 479"
        d="M8.523,42.253a3.733,3.733,0,0,0-2.1-.888A5.778,5.778,0,0,0,8.811,45.9,12.841,12.841,0,0,1,8.523,42.253Z"
        transform="translate(12.349 -16.766)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_480"
        data-name="Tracciato 480"
        d="M2.513,32.989s-1.405-5.192,1.5-7.84C4.015,25.15,6.6,29.8,2.513,32.989Z"
        transform="translate(18.862 -10.193)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_481"
        data-name="Tracciato 481"
        d="M8.625,32.338a3.73,3.73,0,0,0-2.262-.311,5.759,5.759,0,0,0,3.48,3.759A12.853,12.853,0,0,1,8.625,32.338Z"
        transform="translate(11.349 -12.958)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_482"
        data-name="Tracciato 482"
        d="M8.032,22.566S5.338,17.915,7.463,14.6C7.463,14.6,11.158,18.422,8.032,22.566Z"
        transform="translate(11.737 -5.918)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_483"
        data-name="Tracciato 483"
        d="M11.312,23.027a3.747,3.747,0,0,0-2.267.289,5.742,5.742,0,0,0,4.33,2.726A12.838,12.838,0,0,1,11.312,23.027Z"
        transform="translate(6.222 -9.316)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_484"
        data-name="Tracciato 484"
        d="M16.052,13.57s-3.8-3.793-2.6-7.544C13.452,6.026,18.005,8.754,16.052,13.57Z"
        transform="translate(0.578 -2.442)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_485"
        data-name="Tracciato 485"
        d="M16.388,14.917a3.746,3.746,0,0,0-2.113.865,5.721,5.721,0,0,0,4.885,1.507A12.639,12.639,0,0,1,16.388,14.917Z"
        transform="translate(-2.673 -6.046)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_486"
        data-name="Tracciato 486"
        d="M25.888,6.61S21.24,3.935,21.432,0C21.432,0,26.531,1.451,25.888,6.61Z"
        transform="translate(-13.709 0)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_487"
        data-name="Tracciato 487"
        d="M23.519,8.725A3.764,3.764,0,0,0,21.7,10.112a5.731,5.731,0,0,0,5.107.186A12.718,12.718,0,0,1,23.519,8.725Z"
        transform="translate(-14.737 -3.536)"
        fill="#ced5de"
      />
      <path
        id="Tracciato_488"
        data-name="Tracciato 488"
        d="M36.76,5.286s-5.252,1.062-7.688-2.024C29.072,3.262,33.862.979,36.76,5.286Z"
        transform="translate(-29.072 -1.077)"
        fill="#ced5de"
      />
    </svg>
  );
};

export default CoccardaRight;

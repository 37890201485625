import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StrapiPagination } from '../interfaces/commons';
import SelectPresentational from './Form/SelectPresentational';
import { getPageSizeOpts } from '../libs/utils/pagination';

interface Props {
  pagination?: StrapiPagination;
  defaultPageSize?: number;
  pageAttrName?: string;
  pageSizeAttrName?: string;
}

const Pagination: React.FC<Props> = ({
  pagination,
  pageAttrName = 'page',
  defaultPageSize = 10,
  pageSizeAttrName = 'pageSize',
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get(pageAttrName)) || 1
  );
  const [size, setSize] = useState(defaultPageSize);

  useEffect(() => {
    searchParams.set(pageSizeAttrName, String(size));
    setSearchParams(searchParams);
  }, [size]);

  useEffect(() => {
    setCurrentPage(Number(searchParams.get(pageAttrName)) || 1); //se si applica un filtro generico si ritorna alla prima pagina
  }, [...searchParams]);

  const onChange = (i: number) => {
    setCurrentPage(i);
    searchParams.set(pageAttrName, String(i));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (
      (pagination?.pageCount && currentPage > pagination.pageCount) ||
      pagination?.pageCount === 0
    ) {
      searchParams.delete(pageAttrName);
      setSearchParams(searchParams);
    }
  }, [pagination?.pageCount]);

  const totalPage = pagination?.pageCount || 1;

  const regularClass =
    'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer';

  return (
    <div className='flex items-center pb-20 gap-x-10 mt-5 border-t'>
      <div className='flex items-center gap-x-3 h-[42px] pt-5 -mt-1'>
        <div className='w-24'>
          <SelectPresentational
            name={pageSizeAttrName}
            options={getPageSizeOpts(defaultPageSize)}
            onChangeValue={(e) => setSize(e?.value)}
            defaultValue={defaultPageSize}
          />
        </div>
        <span className='items-center text-sm font-small text-gray-300 whitespace-nowrap hidden md:inline-flex'>
          elementi per pagina
        </span>
      </div>
      <nav className='flex items-center justify-between border-gray-200 px-4 sm:px-0 w-full '>
        <div className='-mt-px flex w-0 flex-1 xl:ml-48'>
          {currentPage > 1 ? (
            <div
              onClick={() => onChange(Number(currentPage) - 1)}
              className='inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer'
            >
              <ArrowLeftIcon
                className='mr-3 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              Prima
            </div>
          ) : (
            <div className='inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-200 cursor-default'>
              <ArrowLeftIcon
                className='mr-3 h-5 w-5 text-gray-200'
                aria-hidden='true'
              />
              Prima
            </div>
          )}
        </div>
        <div className='hidden md:-mt-px md:flex'>
          {currentPage > 2 && (
            <>
              <div onClick={() => onChange(1)} className={regularClass}>
                1
              </div>
              {currentPage > 3 && (
                <span className='inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500'>
                  ...
                </span>
              )}
            </>
          )}
          {currentPage > 1 && (
            <>
              <div
                onClick={() => onChange(Number(currentPage) - 1)}
                className={regularClass}
              >
                {Number(currentPage) - 1}
              </div>

              {currentPage === 1 && (
                <div
                  onClick={() => onChange(Number(currentPage) + 2)}
                  className={regularClass}
                >
                  {Number(currentPage) + 2}
                </div>
              )}
            </>
          )}

          <div
            className={
              'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-primary text-primary cursor-default'
            }
          >
            {currentPage}
          </div>
          {currentPage < totalPage && (
            <div
              onClick={() => onChange(Number(currentPage) + 1)}
              className={regularClass}
            >
              {Number(currentPage) + 1}
            </div>
          )}

          {currentPage <= totalPage - 3 && (
            <>
              <span className='inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500'>
                ...
              </span>
              <div
                onClick={() => onChange(pagination?.pageCount || 0)}
                className={regularClass}
              >
                {pagination?.pageCount}
              </div>
            </>
          )}
          {currentPage === totalPage - 2 && (
            <>
              <div
                onClick={() => onChange(pagination?.pageCount || 0)}
                className={regularClass}
              >
                {totalPage}
              </div>
            </>
          )}
        </div>
        <div className='-mt-px flex w-0 flex-1 justify-end xl:mr-48'>
          {currentPage < totalPage ? (
            <div
              onClick={() => onChange(Number(currentPage) + 1)}
              className='inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer'
            >
              Dopo
              <ArrowRightIcon
                className='ml-3 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
          ) : (
            <div className='inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-200 cursor-default'>
              <ArrowRightIcon
                className='mr-3 h-5 w-5 text-gray-200'
                aria-hidden='true'
              />
              Dopo
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Pagination;

import axios from 'axios';
import qs from 'qs';

import {
  AudiencesListResponse,
  ParticipantsAudienceData,
} from '../interfaces/audiences';

const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const audiences = async (token: string | null) => {
  const query = {
    populate: '*',
    pagination: {
      pageSize: 1000,
    },
  };

  const { data } = await axios.get<AudiencesListResponse>(
    `${REACT_APP_API_URL}/audiences?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const listAudiences = async (
  searchParams: any,
  token: string | null,
  pageSize?: number
) => {
  const query = {
    populate: '*',

    pagination: {
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize') || pageSize || 20,
    },
    filters: {
      title: { $contains: searchParams.get('search') },
    },
  };

  const { data } = await axios.get<AudiencesListResponse>(
    `${REACT_APP_API_URL}/audiences?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createAudience = async ({
  data,
  token,
}: {
  data: any;
  token: string | null;
}) => {
  const response = await instance.post(`${REACT_APP_API_URL}/audiences`, data, {
    headers: { authorization: `Bearer ${token}` },
  });

  return response;
};

export const createCourseParticipantsAudience = async ({
  data,
  token,
  id,
}: {
  data: ParticipantsAudienceData;
  token: string | null;
  id: string;
}) => {
  const response = await instance.post(
    `${REACT_APP_API_URL}/course-participants/${id}/create-audience`,
    { data },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return response;
};

export const createEventParticipantsAudience = async ({
  data,
  token,
  id,
}: {
  data: ParticipantsAudienceData;
  token: string | null;
  id: string;
}) => {
  const response = await instance.post(
    `${REACT_APP_API_URL}/event-participants/${id}/create-audience`,
    { data },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return response;
};

export const deleteAudience = async (
  audienceId: number,
  token: string | null
) => {
  const { data } = await axios.delete(
    `${REACT_APP_API_URL}/audiences/${audienceId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PrimaryButton from "../Buttons/PrimaryButton";
import { TrashIcon } from "@heroicons/react/outline";
import { deleteProfile, deleteUser } from "../../api/profile";
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";
import { Profile } from "../../interfaces/profile";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

interface DeleteAccountModalProps {
  infoUser: Profile;
}

const DeleteAccountModal = ({ infoUser }: { infoUser: Profile }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [{ token }] = useAuth();

  const { mutate: mutateProfile, isLoading: isLoadingProfile } = useMutation(
    deleteProfile,
    {
      onSuccess: () => {
        toast.success("Profilo eliminato con successo");
        navigate("/utenti");
      },
      onError: (error: any) => {
        toast.error(error.response.data.error.message);
      },
    }
  );

  const { mutate: mutateUser, isLoading: isLoadingUser } = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        toast.success("Account eliminato con successo");
      },
      onError: (error: any) => {
        toast.error(error.response.data.error.message);
      },
    }
  );

  const deleteProfileAndUser = async () => {
    // se ha lo user cancella lo user e il profile
    // Se ha solo il profile senza user elimina il profile
    infoUser.attributes.user.data
      ? (mutateUser({ id: infoUser.attributes.user.data?.id, token }),
        mutateProfile({ id: infoUser.id, token }))
      : mutateProfile({ id: infoUser.id, token });
  };

  return (
    <>
      <PrimaryButton outline onClick={() => setOpen(true)}>
        <TrashIcon className="w-5 h-5 mr-2" /> Elimina account
      </PrimaryButton>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <h4 className="text-1.5xl font-IBM font-light text-gray-700 mb-6">
                  Elimina Account
                </h4>

                <div className="bg-sand-50 border-2 border-sand-100 px-4 py-6">
                  <p className="text-gray-700">
                    Sei sicuro di voler eliminare l'occount di{" "}
                    <span className="font-medium italic text-primary">
                      {infoUser.attributes.surname +
                        " " +
                        infoUser.attributes.name}
                    </span>
                    ?
                    <br />
                    <br />
                    L'azione sarà irreversibile.
                  </p>
                </div>

                <div className="flex items-center justify-end gap-4 mt-6">
                  <PrimaryButton textSmall onClick={() => setOpen(false)}>
                    Annulla
                  </PrimaryButton>
                  <PrimaryButton small onClick={() => deleteProfileAndUser()}>
                    Elimina account
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeleteAccountModal;

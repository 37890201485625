import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getSectionJobs } from '../../../../api/section';
import UnderlineButton from '../../../../components/Buttons/UnderlineButton';
import TitlePage from '../../../../components/TitlePage';
import roles from '../../../../constants/roles';
import { useAuth } from '../../../../contexts/Auth';
import MansioniTable from '../../Tables/mansioniTable';

export default function Mansioni() {
  const [{ token, profile }] = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const mansioniQuery = useQuery('listMansioni', () =>
    getSectionJobs({ token, sectionId: Number(id) })
  );

  return (
    <>
      <TitlePage title='Modifica sezione | Mansioni' />

      <div className='space-y-5 mt-10'>
        <div className='w-full flex justify-end'>
          {profile?.role.name === roles.SEGRETERIA_NAZIONALE && (
            <UnderlineButton
              onClick={() => {
                navigate(`/sezioni/modifica/${id}/mansioni/nuova`);
              }}
            >
              Aggiungi nuovo ruolo istituzionale
            </UnderlineButton>
          )}
        </div>
        <MansioniTable mansioniQuery={mansioniQuery} />
      </div>
    </>
  );
}

import { ReceiptResponse } from '../../../interfaces/receipts';
import IconLink from '../../../components/TextLink/IconLink';
import { DownloadIcon, PencilIcon } from '@heroicons/react/outline';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { useAuth } from '../../../contexts/Auth';
import roles from '../../../constants/roles';
import { mapPaymentMethod } from '../utils';
import { useMutation } from 'react-query';
import receipts from '../../../api/receipts';
import { toast } from 'react-toastify';
import ReceiptRowAmounts from './ReceiptRowAmounts';
import { getCouponTotalHTML } from '../../../libs/utils/helpers';

interface Props {
  receipt: ReceiptResponse;
  searchWords: string[];
  disabled?: boolean;
}

const ReceiptRow: React.FC<Props> = ({ receipt, searchWords, disabled }) => {
  const [{ profile }] = useAuth();
  const { mutate: mutateReceipt } = useMutation(
    'downloadUserReceipt',
    receipts.downloadReceipt,
    {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto');
      },
      onSuccess: () => {
        toast.success('Ricevuta scaricata con successo');
      },
    }
  );

  const [{ token }] = useAuth();

  const receiptCoupon = receipt?.attributes?.orders?.data.find(
    (order) => order?.attributes?.coupon?.data
  );

  return (
    <tr className='cursor-default'>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {`${receipt?.attributes?.profile?.data?.attributes?.name} ${receipt?.attributes?.profile?.data?.attributes?.surname}`}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={String(receipt.attributes.code || '-')}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 uppercase'>
        {moment(receipt.attributes.date).format('DD/MM/YYYY')}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600'>
        <ReceiptRowAmounts receipt={receipt} />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <p
          dangerouslySetInnerHTML={{
            __html: getCouponTotalHTML(receiptCoupon?.attributes.coupon?.data),
          }}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={receipt.attributes.description || '-'}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={receipt.attributes.notes || '-'}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={receipt.attributes.type || '-'}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={mapPaymentMethod(receipt.attributes.paymentMethod)}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={
            receipt.attributes?.section?.data?.attributes?.name || '-'
          }
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={String(receipt.attributes.badgeNumber || '-')}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 uppercase'>
        <Highlighter
          highlightClassName='p-1 rounded-md'
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={receipt.attributes.fiscalCode || '-'}
        />
      </td>

      {/* SOLO GLI AMMINISTRATORI NAZIONALI POSSONO MODIFICARE LE RICEVUTE */}
      {profile?.role.name === roles.SEGRETERIA_NAZIONALE && (
        <td className='whitespace-nowrap px-6 py-4'>
          <IconLink
            orangeTxt
            href={`/tesoreria/dettaglio-ricevuta/${receipt.id}?mode=FREE&action=EDIT`}
            disabled={disabled}
          >
            <PencilIcon className='w-6 h-6' />
          </IconLink>
        </td>
      )}
      <td className='whitespace-nowrap px-6 py-4'>
        <div className='flex gap-4'>
          <button
            onClick={() =>
              mutateReceipt({
                token,
                body: {
                  receipts: [receipt.id],
                },
                fileName: `Ricevuta ${receipt.attributes.code}`,
              })
            }
          >
            <IconLink greyTxt href='#'>
              <DownloadIcon className='w-6 h-6' />
            </IconLink>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ReceiptRow;

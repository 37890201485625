import React, { useEffect } from 'react';
import UploadAreaPresentational from './Form/UploadAreaPresentational';
import FreeLabel from './Form/FreeLabel';
import InputPresentational from './Form/InputPresentational';
import RadioGroupTeacher from './Form/RadioGroupTeacher';
import RoundedCheckboxPresentational from './Form/RoundedCheckboxPresentational';
import SelectPresentational from './Form/SelectPresentational';

import TextAreaPresentational from './Form/TextAreaPresentational';

import { categories } from '../constants/categories';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { CourseLessonRequest } from '../interfaces/lesson';
import UploadedFilePreview from './Form/UploadedFilePreview';

interface FormNewLessonProps {
  newLessonMethods: UseFormReturn<CourseLessonRequest>;
  isOnline?: boolean;
}
const FormNewLesson: React.FC<FormNewLessonProps> = ({
  newLessonMethods,
  isOnline,
}) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<CourseLessonRequest>();

  useEffect(() => {
    setValue('isOnline', isOnline);
  }, [isOnline]);

  const fileSelected = newLessonMethods?.watch('slidePdf');

  return (
    <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6'>
      <div className='space-y-4'>
        <InputPresentational
          id='title'
          label='Titolo'
          name='title'
          type='text'
          required={isOnline ? false : true}
          register={register}
          error={{ ...errors.title, type: 'required' }}
        />

        <TextAreaPresentational
          id='description'
          label='Descrizione'
          name='description'
          register={register}
          required={isOnline ? false : true}
          error={{ ...errors.description, type: 'required' }}
        />
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <SelectPresentational
          name='category'
          label='Categoria'
          control={control}
          options={categories}
          required={isOnline ? false : true}
          error={{ ...errors.category, type: 'required' }}
        />
        <InputPresentational
          id='minutes'
          label='Durata (in minuti)'
          name='minutes'
          type='number'
          required={isOnline ? false : true}
          register={register}
          error={{ ...errors.minutes, type: 'required' }}
        />

        <InputPresentational
          id='estimatePayment'
          label='Compenso preventivo docente'
          name='estimatePayment'
          type='text'
          register={register}
          required={isOnline ? false : true}
          error={{ ...errors.estimatePayment, type: 'required' }}
        />
        <InputPresentational
          id='estimateRefund'
          label='Rimborso preventivo docente'
          name='estimateRefund'
          type='text'
          register={register}
        />
        <div className='h-full flex items-center mt-3 -ml-3 '>
          <RoundedCheckboxPresentational
            id='endCourse'
            describedby='endCourse'
            label='Fine corso'
            name='endCourse'
            register={register}
          />
        </div>
      </div>
      <div className='pt-6 pb-8 col-span-2'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <InputPresentational
            id='date'
            label='Data'
            name='date'
            type='datetime-local'
            required={isOnline ? false : true}
            register={register}
            error={{ ...errors.date, type: 'required' }}
          />
          <div className='col-span-1 md:col-span-2'>
            <FreeLabel label='Scelta docente *' />
            <RadioGroupTeacher />
          </div>
          <TextAreaPresentational
            id='wineList'
            label='Lista dei vini'
            name='wineList'
            register={register}
          />

          <div className='space-y-3'>
            <UploadAreaPresentational
              label='Slide lezioni'
              name='slidePdf'
              formMethods={newLessonMethods}
              acceptedExtensions={['pdf']}
              disabled={Array.isArray(fileSelected) && fileSelected?.length > 0}
            />

            {!!fileSelected && (
              <UploadedFilePreview
                file={fileSelected}
                onRemove={() => {}}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormNewLesson;

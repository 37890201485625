import { useEffect, useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { PlusIcon } from '@heroicons/react/outline';
import BaseModal from './BaseModal';
import { useQuery, UseQueryResult } from 'react-query';
import { getProfiles } from '../../api/profile';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/Auth';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { ProductType } from '../../interfaces/orders';
import { ParticipantsResponse } from '../../interfaces/participants';
import { Option, SearchableSelect } from '../Form/SearchableSelect';
import { EventGuest } from '../../interfaces/events';
import ExtraParticipantSection from '../ExtraParticipantSection';
import { ProfileListResponse } from '../../interfaces/profile';
import { EventParticipation } from '../../interfaces/event-participants';
import { BadgeStatus } from '../../interfaces/badge';
import { getQuerySearchFilters } from '../../libs/utils/profile';

export interface EventParticipantForm {
  profile?: Option;
  guests: EventGuest[];
}

interface Props {
  onConfirm?: (data: EventParticipantForm) => void;
  participantsQuery?: UseQueryResult<
    {
      data: ParticipantsResponse[] | EventParticipation[];
    },
    unknown
  >;
  disabled?: boolean;
  entityDate: string;
  isLoading?: boolean;
}

const AddCoursePartecipantsModal: React.FC<Props> = ({
  onConfirm,
  participantsQuery,
  disabled,
  entityDate,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [participantsIds, setParticipantsIds] = useState<number[]>([]);

  const [{ token }] = useAuth();
  const [debouncedSearch] = useDebounce(search, 500);

  const profilesQuery = useQuery(
    ['getProfileListForParticipations', participantsIds],
    () => {
      return getProfiles({
        token,
        query: {
          filters: {
            $and: getQuerySearchFilters(search),
            id: {
              $notIn: participantsIds,
            },
            badgeYear: new Date(entityDate).getFullYear(),
          },
          populate: ['orders.payment', 'orders.cardHistory'],
          pagination: {
            pageSize: 50,
          },
        },
      });
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    setParticipantsIds(
      participantsQuery?.data?.data?.map(
        (elem) => elem.attributes.profile?.data?.id as number
      ) || []
    );
  }, [participantsQuery?.data?.data]);

  const participantForm = useForm<EventParticipantForm>({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(
      Yup.object().shape({
        profile: Yup.object()
          .shape({
            value: Yup.number().required('Questo campo è obbligatorio'),
            label: Yup.string().optional(),
          })
          .required('Questo campo è obbligatorio'),
        guests: Yup.array()
          .of(
            Yup.object().shape({
              firstName: Yup.string()
                .min(1)
                .required('Questo campo è obbligatorio'),
              lastName: Yup.string()
                .min(1)
                .required('Questo campo è obbligatorio'),
            })
          )
          .optional(),
      })
    ),
  });

  const { control, handleSubmit, reset, formState } = participantForm;

  const toggleModal = () => {
    setIsOpen((v) => !v);
    reset({});
  };

  const onSubmit = (values: EventParticipantForm) => {
    onConfirm && onConfirm(values);
  };

  useEffect(() => {
    profilesQuery.refetch();
  }, [debouncedSearch]);

  const [hadSubmitted, setHadSubmitted] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setHadSubmitted(true);
    }

    if (hadSubmitted && !isLoading) {
      toggleModal();
      setHadSubmitted(false);
    }
  }, [isLoading, hadSubmitted]);

  function formatOptions(query: UseQueryResult<ProfileListResponse>) {
    if (query.isSuccess) {
      const options = query.data.data.map((profile) => ({
        label: `${profile.attributes.name} ${profile.attributes.surname}`,
        value: profile.id,
        isDisabled: profile.attributes.badgeStatus !== BadgeStatus.VALID,
      }));

      return options;
    } else return [];
  }

  return (
    <>
      <UnderlineButton disabled={disabled} onClick={() => setIsOpen(true)}>
        <PlusIcon className='w-4 h-4' /> Aggiungi partecipante
      </UnderlineButton>

      <BaseModal
        title={'Aggiungi partecipante'}
        isOpen={isOpen}
        toggle={toggleModal}
        className={'mx-auto md:w-[500px] w-11/12'}
        hideBottom
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='min-h-[360px]'>
            <SearchableSelect
              onInput={setSearch}
              options={formatOptions(profilesQuery)}
              name='profile'
              placeholder={'Cerca...'}
              control={control}
              //@ts-ignore
              error={{ ...formState.errors['profile.value'], type: 'required' }}
              isLoading={profilesQuery.isFetching}
            />
          </div>

          <div className='flex items-center justify-end gap-4'>
            <PrimaryButton onClick={toggleModal} textSmall>
              {'Annulla'}
            </PrimaryButton>
            <PrimaryButton
              isLoading={isLoading}
              disabled={isLoading}
              type='submit'
              small
            >
              {'Conferma'}
            </PrimaryButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
};

export default AddCoursePartecipantsModal;

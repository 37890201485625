import axios from 'axios';
import qs from 'qs';
import { BadgeToPrint } from '../interfaces/badgeToPrint';
import { StrapiResponseList } from '../interfaces/commons';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

const getSearchParamValue = (searchParams: any, name: string) =>
  Object.entries(qs.parse(searchParams.get(name) + ''))
    .map(([, value]) => value)
    .map((elem: any) => elem.value);

const getSearchParamLabel = (searchParams: any, name: string) =>
  Object.entries(qs.parse(searchParams.get(name) + ''))
    .map(([, value]) => value)
    .map((elem: any) => elem.label);

/**
 * Una get non si fa così.
 */
export const badgesToPrint = async ({
  query,
  token,
}: {
  query: object;
  token?: string | null;
}) => {
  const { data } = await instance.get<StrapiResponseList<BadgeToPrint>>(
    `${REACT_APP_API_URL}/badges-to-print?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const badgesToPdf = async ({
  token,
  body,
}: {
  token?: string | null;
  body: any;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/badges-to-pdf`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data as any);
  link.download = `lista-tessere.pdf`;
  document.body.append(link);
  link.click();
  link.remove();

  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const lettersToPdf = async ({
  token,
  body,
}: {
  token?: string | null;
  body: any;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/letters-to-pdf`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data as any);
  link.download = `lista-lettere.pdf`;
  document.body.append(link);
  link.click();
  link.remove();

  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const massLettersToPdf = async ({
  token,
  fileName,
  query,
}: {
  token?: string | null;
  fileName: string;
  query?: object;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/letters-to-pdf/mass?${qs.stringify(query)}`,
    {},
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.pdf`;
  document.body.append(link);
  link.click();
  link.remove();

  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const massBadgesToPdf = async ({
  token,
  fileName,
  query,
}: {
  token?: string | null;
  fileName: string;
  query?: object;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/badges-to-pdf/mass?${qs.stringify(query)}`,
    {},
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.pdf`;
  document.body.append(link);
  link.click();
  link.remove();

  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const badgesToAPIs = {
  lettersToPdf,
  massLettersToPdf,
  massBadgesToPdf,
};

export default badgesToAPIs;

import TitlePage from '../../../components/TitlePage';
import InputPresentational from '../../../components/Form/InputPresentational';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { location } from '../../../api/location';
import LocationsSelect from '../../../components/FetchSelect/LocationsSelect';
import { useQuery } from 'react-query';
import sectionsAPIs from '../../../api/section';

const Location = () => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const locationQuery = useQuery(['location', watch('location')], () =>
    location(watch('location'))
  );

  const nationQuery = useQuery(['nation', watch('section')], () =>
    watch('section.data')
      ? sectionsAPIs.findSectionNation(watch('section.data.id'))
      : sectionsAPIs.findSectionNation(watch('section'))
  );

  useEffect(() => {
    if (locationQuery.data && locationQuery.status === 'success') {
      const { city, address } = locationQuery.data?.data.attributes;
      setValue('address', address);
      setValue('city', city);
    }
  }, [watch('location'), locationQuery.status]);

  useEffect(() => {
    if (nationQuery.data && nationQuery.status === 'success')
      setValue('nation', nationQuery.data?.data.attributes.name);
    else setValue('nation', '');
  }, [watch('section'), nationQuery.status]);

  return (
    <>
      <TitlePage title='Crea nuovo corso | Location' />

      <form className='mt-6'>
        <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-4'>
          <LocationsSelect
            locationId={getValues('location')}
            required
            error={{ ...errors.location, type: 'required' }}
          />
          <InputPresentational
            id='address'
            label='Indirizzo'
            name='address'
            type='text'
            register={register}
            disabled
            required
            error={{ ...errors.address, type: 'required' }}
          />
          <InputPresentational
            id='city'
            label='Città'
            name='city'
            type='text'
            register={register}
            disabled
            required
            error={{ ...errors.city, type: 'required' }}
          />
          <InputPresentational
            id='nation'
            label='Nazione'
            name='nation'
            type='text'
            register={register}
            disabled
            required
            error={{ ...errors.nation, type: 'required' }}
          />
          {/* <TextAreaPresentational
            id='googleLink'
            label='Link Google Maps'
            name='googleLink'
            register={register}
          /> */}

          {/* <fieldset className='space-y-5'>
            <div>
              <div className='relative flex items-start'>
                <SimpleCheckboxPresentational
                  id='mapEnabled'
                  name='mapEnabled'
                  label='Disattiva la mappa'
                  register={register}
                />
              </div>
              <p id='offers-description' className='text-gray-400 text-sm'>
                Disattiva il link per visualizzare la mappa in onav.it nei casi
                in cui la location non venga trovata
              </p>
            </div>
          </fieldset> */}
        </div>
      </form>
    </>
  );
};

export default Location;

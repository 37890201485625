import axios from 'axios';
import moment from 'moment';
import qs from 'qs';

import { OutflowListResponse } from '../interfaces/outflows';
const instance = axios.create();
const { REACT_APP_API_URL } = process.env;

export const listOutflows = async (searchParams: any, token: string | null) => {
  const query = qs.stringify({
    filters: {
      section: {
        name: { $in: searchParams.getAll('section') },
      },
      finalBalanceDate: {
        $gte:
          searchParams?.get('dateFrom') ||
          moment()
            .subtract({
              month: 1,
            })
            .toISOString(),
        $lte: searchParams?.get('dateTo') || moment().toISOString(),
      },

      title: { $containsi: searchParams.get('search') },
    },
  });

  const { data } = await instance.get<OutflowListResponse>(
    `${REACT_APP_API_URL}/outflows?${query}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

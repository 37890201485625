import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import FreeLabel from '../Form/FreeLabel';
import { CourseAttributes } from '../../interfaces/courses';
import { Level } from '../../interfaces/commons';
import SimpleCheckboxPresentational from '../Form/SimpleCheckboxPresentational';

interface VoteModalProps {
  partecipantName: string;
  course?: CourseAttributes | null;
  isOpen: boolean;
  toggle: () => void;
  onConfirm: (vote: string, cumLaude?: boolean) => void;
}

const VoteModal: React.FC<VoteModalProps> = ({
  partecipantName,
  course,
  isOpen,
  toggle,
  onConfirm,
}) => {
  const [vote, setVote] = useState<string>('');
  const [cumLaude, setCumLaude] = useState<boolean>(false);
  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={toggle}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Conferma esame
                </h4>

                <div className='py-10'>
                  <p className='text-gray-700'>
                    Confermi che <i>{partecipantName}</i> ha superato la prova
                    di esame finale del corso "
                    <span className='font-medium italic text-primary'>
                      {course?.title}
                    </span>{' '}
                    "?
                  </p>

                  {course?.level !== Level.PRIMO_LIVELLO && (
                    <div className='flex items-center mt-8'>
                      <div>
                        <FreeLabel label='Voto' />
                        <div className='flex flex-row  items-center gap-4'>
                          <input
                            id='vote'
                            name='vote'
                            type='number'
                            value={vote}
                            step={1}
                            min={0}
                            max={30}
                            onChange={(e) => setVote(e.target.value)}
                            autoComplete='vote'
                            className='appearance-none block w-[80px] px-3 py-2 font-IBM font-medium border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
                          />
                          <p className='m-0 text-gray-700'>/ 30</p>
                        </div>
                      </div>
                      {vote === '30' && (
                        <SimpleCheckboxPresentational
                          id='cumLaude'
                          name='cumLaude'
                          label='Lode'
                          className='pt-6 pl-10'
                          onChange={(e: any) => setCumLaude(e.target.checked)}
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton
                    small
                    onClick={() => {
                      if (course?.level === Level.PRIMO_LIVELLO) {
                        onConfirm('30');
                      } else {
                        onConfirm(vote, cumLaude);
                      }

                      toggle();
                      setVote('');
                      setCumLaude(false)
                    }}
                  >
                    Conferma voto
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default VoteModal;

import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { coursesListForStats } from '../../api/courses';
import { eventsListForStats } from '../../api/events';
import sectionsAPIs from '../../api/section';
import StaticInputPresentational from '../../components/Form/StaticInputPresentational';
import Pagination from '../../components/Pagination';
import TitlePage from '../../components/TitlePage';
import H1Styled from '../../components/Typography/H1Styled';
import H3Styled from '../../components/Typography/H3Styled';
import H4SpacingLetter from '../../components/Typography/H4SpacingLetter';
import { useAuth } from '../../contexts/Auth';
import { isSegreteriaNazionale } from '../../libs/utils/auth';
import StatisticsCourse from './Tables/StatisticsCourse';
import StatisticsEvent from './Tables/StatisticsEvents';
import statisticsAPIs from '../../api/statisticsAPIs';
import moment from 'moment';
import 'moment/locale/it';
import { useEffect } from 'react';
import { match } from 'ts-pattern';
import FormLoading from '../../components/Layout/Loading/FormLoading';
import FiltersBar from '../../components/Filters/FiltersBar';
import { FilterType } from '../../interfaces/filters';

moment.locale('it');

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function Statistiche() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  useEffect(() => {
    searchParams.set('coursesPageSize', '10');
    searchParams.set('eventsPageSize', '10');
    searchParams.set('year', '2024');
    setSearchParams(searchParams);
  }, []);

  const statisticsQuery = useQuery(
    ['findStatistics', searchParams.get('year')],
    () =>
      statisticsAPIs.find({
        token,
        query: {
          year: searchParams.get('year'),
        },
      })
  );

  const courseQuery = useQuery(
    [
      'coursesStatistics',
      [
        debounceSearchParams.get('year'),
        debounceSearchParams.get('sections'),
        debounceSearchParams.get('coursesPage'),
        debounceSearchParams.get('coursesPageSize'),
      ],
    ],
    () =>
      coursesListForStats(
        debounceSearchParams,
        token,
        'coursesPage',
        'coursesPageSize'
      )
  );

  const eventsQuery = useQuery(
    [
      'eventsStatistics',
      [
        debounceSearchParams.get('year'),
        debounceSearchParams.get('sections'),
        debounceSearchParams.get('eventsPage'),
        debounceSearchParams.get('eventsPageSize'),
      ],
    ],
    () =>
      eventsListForStats(
        debounceSearchParams,
        token,
        'eventsPage',
        'eventsPageSize'
      )
  );

  return (
    <div>
      <TitlePage title='Home Dashboard' />
      <div className='py-10 px-4 lg:px-10'>
        <H1Styled>Statistiche per corsi ed eventi</H1Styled>
        <p className='text-gray-500'>
          Statistiche con indicatori totali per anno e per sezione
        </p>
      </div>

      <div className='pb-7 lg:pb-12 px-4 lg:px-10 w-full space-y-10'>
        <div className='space-y-5'>
          <FiltersBar
            hideAppliedFilters
            filters={[
              {
                type: FilterType.RADIO,
                attribute: 'year',
                label: 'Anno',
                source: arrayRange(2023, new Date().getFullYear(), 1)
                  .reverse()
                  .map((year) => ({
                    value: year.toString(),
                    label: year.toString(),
                  })),
              },
            ]}
          />

          <div className='bg-neutral-50 py-8 px-4 rounded-lg space-y-8'>
            {match(statisticsQuery)
              .with({ status: 'idle' }, { status: 'loading' }, () => (
                <FormLoading numberItems={1} />
              ))
              .with({ status: 'error' }, () => (
                <div>Errore nel recupero delle statistiche...</div>
              ))
              .with({ status: 'success' }, ({ data: statisticsData }) => (
                <>
                  <div className='space-y-4'>
                    <H3Styled>{`Statistiche ${statisticsData?.year} (Ultimo aggiornamento: ${statisticsData?.lastUpdate})`}</H3Styled>
                    <H4SpacingLetter>Indicatori totali CORSI</H4SpacingLetter>

                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                      <StaticInputPresentational
                        label='Corsi pubblicati a partire dal 1 gennaio'
                        text={statisticsData?.coursesReports.published}
                      />
                    </div>
                  </div>

                  <div className='space-y-4'>
                    <H4SpacingLetter>Indicatori totali EVENTI</H4SpacingLetter>

                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                      <StaticInputPresentational
                        label='Eventi pubblicati a partire dal 1 gennaio'
                        text={statisticsData?.eventsReports.published}
                      />
                    </div>
                  </div>
                  <div className='space-y-4'>
                    <H4SpacingLetter>
                      Statistiche sottoscrizioni
                    </H4SpacingLetter>

                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                      <StaticInputPresentational
                        label={`Soci`}
                        text={statisticsData?.subscriptionsReports.totals}
                      />
                      <StaticInputPresentational
                        label={`Rinnovi`}
                        text={`${
                          statisticsData?.subscriptionsReports.renewals
                        } (${
                          statisticsData?.subscriptionsReports
                            .renewalsPercentage
                        } % del ${statisticsData?.year! - 1})`}
                      />
                      <StaticInputPresentational
                        label={`Soci nuovi`}
                        text={statisticsData?.subscriptionsReports.newSubs}
                      />
                      <StaticInputPresentational
                        label={`Soci riattivati`}
                        text={
                          statisticsData?.subscriptionsReports.reenrollments
                        }
                      />
                      <StaticInputPresentational
                        label={`Junior`}
                        text={`${statisticsData?.subscriptionsReports.juniors} (${statisticsData?.subscriptionsReports.juniorsPercentage} % sul totale)`}
                      />
                    </div>
                  </div>
                </>
              ))
              .exhaustive()}
          </div>
        </div>

        <div className='space-y-5'>
          <H3Styled>
            Preventivo e consuntivo <span className='font-medium'>Corsi</span>
          </H3Styled>
          {isSegreteriaNazionale(profile) && (
            <FiltersBar
              filters={[
                {
                  type: FilterType.MULTISELECT,
                  attribute: 'sections',
                  label: 'Sezione',
                  key: 'sectionsListFilters',
                  source: (data: any) =>
                    sectionsAPIs.findOptions({
                      ...data,
                      query: { ...data.query },
                    }),
                  searchForAttributes: ['name'],
                },
              ]}
            />
          )}
          <StatisticsCourse query={courseQuery} />
          <Pagination
            pagination={courseQuery.data?.meta.pagination}
            pageAttrName='coursesPage'
            pageSizeAttrName='coursesPageSize'
          />
        </div>

        <div className='space-y-5'>
          <H3Styled>
            Preventivo e consuntivo <span className='font-medium'>Eventi</span>
          </H3Styled>
          {isSegreteriaNazionale(profile) && (
            <FiltersBar
              filters={[
                {
                  type: FilterType.MULTISELECT,
                  attribute: 'sections',
                  label: 'Sezione',
                  key: 'sectionsListFilters',
                  source: (data: any) =>
                    sectionsAPIs.findOptions({
                      ...data,
                      query: { ...data.query },
                    }),
                  searchForAttributes: ['name'],
                },
              ]}
            />
          )}
          <StatisticsEvent query={eventsQuery} />
          <Pagination
            pagination={eventsQuery.data?.meta.pagination}
            pageAttrName='eventsPage'
            pageSizeAttrName='eventsPageSize'
          />
        </div>
      </div>
    </div>
  );
}

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );

import { useNavigate } from 'react-router-dom';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { Outflow, OutflowListResponse } from '../../../interfaces/outflows';
import { match } from 'ts-pattern';
import FormLoading from '../../../components/Layout/Loading/FormLoading';
import moment from 'moment';

interface UsciteTableProps {
  outflowsQuery: UseQueryResult<OutflowListResponse, unknown>;
}
const UsciteTable: React.FC<UsciteTableProps> = ({ outflowsQuery }) => {
  const tableElements = [
    'Data consuntivo',
    'Importo',
    'Tipologia',
    'Nome corso/evento',
    'Sezione',
  ];

  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
              <tr>
                {tableElements.map((element) => (
                  <th className='font-normal px-6 py-3 text-left tracking-wide'>
                    {element}
                  </th>
                ))}
              </tr>
            </thead>
            {match(outflowsQuery)
              .with({ status: 'idle' }, { status: 'loading' }, () => (
                <p>Caricando...</p>
              ))
              .with({ status: 'error' }, () => <div>Errore...</div>)
              .with({ status: 'success' }, ({ data: outflows }) => {
                return (
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {outflows.map((outflow: Outflow, idx) => (
                      <tr
                        key={idx}
                        onClick={() => {
                          navigate('/uscita/dettaglio-uscita');
                        }}
                        className='hover:cursor-pointer'
                      >
                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600'>
                          {outflow.finalBalanceDate
                            ? moment(outflow.finalBalanceDate).format(
                                'DD/MM/YYYY'
                              )
                            : '-'}
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 uppercase font-semibold'>
                          € {outflow.totalOutflow}
                        </td>

                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                          {outflow.type}
                        </td>

                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                          {outflow.title}
                        </td>
                        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                          {outflow.section}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                );
              })

              .exhaustive()}
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsciteTable;

import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import TabBar from '../../../../components/TabBars/TabBar';
import H1Styled from '../../../../components/Typography/H1Styled';
import { useAuth } from '../../../../contexts/Auth';
import { match } from 'ts-pattern';
import DatiPrincipali from './dati-principali';
import Firma from './firma';
import sectionsAPIs, { updateSection } from '../../../../api/section';
import ListaLocation from './lista-location';
import Mansioni from './mansioni';
import { useMutation, useQuery } from 'react-query';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import FormLoading from '../../../../components/Layout/Loading/FormLoading';
import { toast } from 'react-toastify';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { sectionValidator } from '../../../../validators/section';
import { SectionRequest } from '../../../../interfaces/section';

const ModificaSezione = () => {
  const { id } = useParams();
  const [{ token }] = useAuth();
  const [searchParams] = useSearchParams();

  const query = useQuery(['section', searchParams.get('newSection')], () =>
    sectionsAPIs.findOne({ id: Number(id), token, query: { populate: '*' } })
  );

  const navigate = useNavigate();

  const tabs = [
    {
      label: 'Dati principali',
      href: `/sezioni/modifica/${id}`,
    },
    {
      label: 'Location',
      href: `/sezioni/modifica/${id}/location`,
    },
    {
      label: 'Firma',
      href: `/sezioni/modifica/${id}/firma`,
    },
    {
      label: 'Ruoli istituzionali',
      href: `/sezioni/modifica/${id}/mansioni`,
    },
  ];

  const { mutate, isLoading } = useMutation(updateSection, {
    onSuccess: () => {
      toast.success('Sezione aggiornata con successo');
      navigate('/sezioni');
    },
    onError: (error: any) => {
      toast.error(error.response.data.error.message);
    },
  });

  const updateSectionInfo = (data: FieldValues) => {
    mutate({ id: Number(id), data: data, token });
  };

  const updateSectionMethods = useForm<SectionRequest>({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(sectionValidator),
  });
  const { handleSubmit, register, control, reset } = updateSectionMethods;

  useEffect(() => {
    reset({
      ...query.data,
      type: query.data?.data.attributes?.type,
      enabled: query.data?.data.attributes.enabled,
      nation: query.data?.data.attributes.nation.data?.id,
      region: query.data?.data.attributes.region.data?.id,
      province: query.data?.data.attributes.province.data?.id,
      costCenter: query.data?.data.attributes?.costCenter,
      name: query.data?.data.attributes?.name,
      city: query.data?.data.attributes.city,
      cap: query.data?.data.attributes?.cap,
      phoneDelegate: query.data?.data.attributes.phoneDelegate,
      phoneTreasurer: query.data?.data.attributes.phoneTreasurer,
      email: query.data?.data.attributes.email,
      emailTreasurer: query.data?.data.attributes.emailTreasurer,
      iban: query.data?.data.attributes.iban,
    });
  }, [query.data]);

  return (
    <>
      <div>
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <FormLoading />
          ))
          .with({ status: 'error' }, () => <div>Errore...</div>)
          .with({ status: 'success' }, (query) => {
            return (
              <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
                <FormProvider {...updateSectionMethods}>
                  <form onSubmit={handleSubmit(updateSectionInfo)}>
                    <div className='flex justify-between'>
                      <H1Styled>Modifica sezione</H1Styled>
                      <div className='flex items-center h-10'>
                        <div className='mr-6'>
                          <PrimaryButton
                            outline
                            onClick={() => {
                              navigate('/sezioni');
                            }}
                          >
                            Chiudi
                          </PrimaryButton>
                        </div>
                        <PrimaryButton type='submit'>Salva</PrimaryButton>
                      </div>
                    </div>

                    <div className='pt-12'>
                      <TabBar tabs={tabs} />
                      <Routes>
                        <Route
                          index
                          element={
                            <DatiPrincipali sectionData={query.data?.data} />
                          }
                        />

                        <Route path='/location' element={<ListaLocation />} />

                        <Route
                          path='firma'
                          element={<Firma sectionData={query.data?.data} />}
                        />
                        <Route path='mansioni' element={<Mansioni />} />
                      </Routes>
                    </div>
                  </form>
                </FormProvider>
              </div>
            );
          })
          .exhaustive()}
      </div>
    </>
  );
};
export default ModificaSezione;

import TitlePage from "../../../../components/TitlePage";
import FormCostiNuovoTemplate from "../../../../components/FormCostiNuovoTemplate";

interface CostiTemplateProps {
}

const CostiTemplate: React.FC<CostiTemplateProps> = ({ }) => {
  return (
    <>
      <TitlePage title='Template corsi | Costi' />

      <form>
        <FormCostiNuovoTemplate />
      </form>
    </>
  );
};

export default CostiTemplate;

export interface ListFilters {
  teacher?: boolean;
  volunteer?: boolean;
  searching?: boolean;
  magazine?: boolean;
  section?: boolean;
  course?: boolean;
  coupon?: boolean;
  single?: boolean;
  active?: boolean;
  all?: boolean;
  onavPosition?: boolean;
  event?: boolean;
  profileCategory?: boolean;
  exam?: boolean;
  paid?: boolean;
  notPaid?: boolean;
  presence?: boolean;
  paymenMethods?: boolean;
  date?: boolean;
  hideAppliedFilters?: boolean;
  region?: boolean;
  onavIt?: boolean;
  status?: boolean;
  eventstatus?: boolean;
  send?: boolean;
  digitalBadge?: boolean;
  year?: boolean;
  printedBadge?: boolean;
  lessonCategories?: boolean;
  endCourse?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum FilterType {
  SEARCH_BAR = 'SEARCH_BAR',
  CHECKBOX = 'CHECKBOX',
  MULTISELECT = 'MULTISELECT',
  DATE = 'DATE',
  RADIO = 'RADIO',

}

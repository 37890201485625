import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import classNames from '../../libs/utils/classNames';

interface SimpleCheckboxPresentationalProps {
  id: string;
  label?: string;
  name: string;
  describedby?: string;
  checked?: boolean;
  register?: UseFormRegister<any>;
  onChange?: any;
  defaultValue?: boolean;
  disabled?: boolean;
  className?: string;
}
const SimpleCheckboxPresentational: React.FC<
  SimpleCheckboxPresentationalProps
> = ({
  id,
  label,
  name,
  describedby,
  register,
  onChange,
  checked,
  defaultValue,
  disabled = false,
  className = '',
}) => {
  return (
    <div className={`relative flex items-center ${className}`}>
      <div className={`flex items-center h-5`}>
        <input
          id={id}
          aria-describedby={describedby}
          name={name}
          checked={checked}
          type='checkbox'
          onChange={onChange}
          defaultChecked={defaultValue}
          className={classNames(
            'w-4 h-4 flex border-gray-300 cursor-pointer rounded focus:ring-primary text-primary my-auto',
            disabled ? 'text-primary/50 cursor-not-allowed' : ''
          )}
          {...(register && register(name))}
          disabled={disabled}
        />
      </div>
      <div className='ml-3'>
        <label htmlFor={id} className='font-normal text-gray-700 select-none'>
          {label}
        </label>
      </div>
    </div>
  );
};

export default SimpleCheckboxPresentational;

import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import classNames from '../../../libs/utils/classNames';
import CheckboxLoading from '../../Layout/Loading/CheckboxLoading';
import { BackspaceIcon } from '@heroicons/react/outline';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/Auth';
import qs from 'qs';
import { useWindowSize } from '../../../libs/utils/helpers';
import { useDebounce } from 'use-debounce';

interface Props {
  api: any;
  label: string;
  queryKey: string;
  filterName: string;
  searchForAttributes?: string[];
  isSearchable?: boolean;
  isDisabled?: boolean;
  defaultValues?: any[];
  isMutualEsclusion?: boolean;
}

type SelectedOptionValue = { value: string; label: string };

const SelectFilter = ({
  api,
  label,
  queryKey,
  filterName,
  searchForAttributes,
  isSearchable = true,
  isDisabled,
  defaultValues = [],
  isMutualEsclusion = false,
}: Props) => {
  const [params, setParams] = useState({ pageSize: 300 });
  const [{ token }] = useAuth();
  const [search, setSearch] = useState('');
  const [debouncedParams] = useDebounce(search, 500);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionValue[]>(
    []
  );

  const [windowWidth] = useWindowSize();
  const query = useQuery([queryKey, debouncedParams], () =>
    api({ ...params, token })
  );

  const onChangeSearch = (i: any) => {
    setSearch(i);

    const filters = searchForAttributes
      ? {
          $or: searchForAttributes.map((_key) => ({
            [_key]: { $contains: i },
          })),
        }
      : undefined;

    setParams((oldValues: any) => ({
      ...oldValues,
      query: {
        filters,
      },
    }));
  };

  const onChangeCheck = (item: { value: string; label: string }) => {
    //trasformo la stringa in array, parsandola
    const options: { value: string; label: string }[] = searchParams.get(
      filterName
    )
      ? (Object.entries(qs.parse(searchParams.get(filterName) + '')).map(
          ([, value]) => value
        ) as any)
      : [];

    //se l'opzione è già stata selezionata, allora la tolgo
    if (options.find((elem) => elem.value === item.value + '')) {
      searchParams.set(
        filterName,
        qs.stringify([
          ...options.filter((elem) => elem.value !== item.value + ''),
        ])
      );
      setSelectedOptions([
        ...options.filter((elem) => elem.value !== item.value + ''),
      ]);
    }
    //atrimenti la aggiungo
    else {
      const prevOptions =
        isMutualEsclusion && selectedOptions.length > 0 ? [] : options;
      searchParams.set(filterName, qs.stringify([...prevOptions, item]));

      setSelectedOptions([...prevOptions, item]);
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    const _filters: { value: string; label: string }[] = Object.entries(
      qs.parse(searchParams.get(filterName) as any)
    ).map(([, value]) => ({ ...(value as object) })) as any;
    setSelectedOptions([..._filters, ...defaultValues]);

    if (defaultValues.length > 0) {
      searchParams.set(filterName, qs.stringify(defaultValues));
      setSearchParams(searchParams);
    }

    if (searchParams.get(filterName) === '') {
      searchParams.delete(filterName);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const List = () => {
    return (
      <form
        className={classNames(
          !isSearchable
            ? 'space-y-4 max-h-96 overflow-auto pt-1'
            : 'space-y-4 max-h-96 overflow-auto mt-5 pt-1'
        )}
      >
        {match(query)
          .with({ status: 'idle' }, { status: 'loading' }, () => (
            <CheckboxLoading />
          ))
          .with({ status: 'error' }, () => (
            <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
              Errore caricamento dati
            </div>
          ))
          .with({ status: 'success' }, (query) => {
            if (query.data) {
              return Object.keys(query.data.data).length === 0 ? (
                <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
                  Nessun risultato
                </div>
              ) : (
                query.data.data.map((section: any) => (
                  <div key={section.id} className='flex items-center pl-1 '>
                    <input
                      disabled={isDisabled}
                      id={`${section.id}`}
                      name={`${section.id}`}
                      type='checkbox'
                      checked={Boolean(
                        selectedOptions.find(
                          (elem) =>
                            elem.value + '' === section.id + '' ||
                            elem.value === section.value + ''
                        )
                      )}
                      className='h-4 w-4 border-gray-300 rounded text-primary focus:ring-primary cursor-pointer disabled:cursor-not-allowed'
                      onChange={() =>
                        onChangeCheck({
                          value: section.id || section.value,
                          label:
                            section.attributes?.title ||
                            section.attributes?.name ||
                            section.label,
                        })
                      }
                    />
                    <label
                      htmlFor={`${section.id}`}
                      className={classNames(
                        !isSearchable
                          ? 'ml-3 pr-6 text-sm font-normal text-gray-900 whitespace-wrap w-72 select-none'
                          : 'ml-3 pr-6 text-sm font-normal text-gray-900 whitespace-wrap w-72 select-none',
                        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                      )}
                    >
                      {section?.attributes?.name ||
                        section?.attributes?.title ||
                        section.label}
                    </label>
                  </div>
                ))
              );
            } else {
              return (
                <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
                  Non hai i permessi per visualizzarli
                </div>
              );
            }
          })
          .exhaustive()}
      </form>
    );
  };

  return windowWidth < 1280 ? (
    <Disclosure
      as='div'
      key={label}
      className='border-t border-gray-200 px-4 py-6 pr-1'
    >
      {({ open }) => (
        <>
          <h3 className='-mx-2 -my-3 flow-root'>
            <Disclosure.Button className='px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400'>
              <span className='font-normal text-gray-800'>{label}</span>
              <span className='ml-6 flex items-center'>
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-5 w-5 transform'
                  )}
                  aria-hidden='true'
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className='pt-6'>
            {isSearchable && (
              <div className='flex items-center'>
                <input
                  type='text'
                  name='search'
                  id='search'
                  className='focus:ring-primary focus:border-primary block text-sm border-gray-300 rounded-full w-[96%]'
                  placeholder='Cerca..'
                  autoComplete='off'
                  value={search}
                  onChange={(e) => onChangeSearch(e.target.value)}
                />
                {search && (
                  <BackspaceIcon
                    className='h-5 w-5 text-gray-500 -ml-8 cursor-pointer'
                    aria-hidden='true'
                    onClick={() => {
                      onChangeSearch('');
                    }}
                  />
                )}
              </div>
            )}
            <List />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Popover key='sections' className='px-4 relative inline-block text-left'>
      <Popover.Button className='group inline-flex justify-center text-sm font-normal text-gray-700 hover:text-gray-800'>
        <span>{label}</span>

        <ChevronDownIcon
          className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
          aria-hidden='true'
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 pr-1 ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {isSearchable && (
            <div className='flex items-center'>
              <input
                type='text'
                name='search'
                id='search'
                className='focus:ring-primary focus:border-primary block text-sm border-gray-300   rounded-full w-[96%]'
                placeholder='Cerca...'
                autoComplete='off'
                value={search}
                onChange={(e) => onChangeSearch(e.target.value)}
              />
              {search && (
                <BackspaceIcon
                  className='h-5 w-5 text-gray-500 -ml-8 cursor-pointer'
                  aria-hidden='true'
                  onClick={() => {
                    onChangeSearch('');
                  }}
                />
              )}
            </div>
          )}

          <List />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default SelectFilter;

import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { profileCategories } from '../../api/profileCategories';
import { FieldError, UseControllerProps } from 'react-hook-form';

const ProfileCategorySelect = ({
  defaultValue,
  control,
  disabled,
  name,
  label,
  required,
  error,
}: {
  id?: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  error?: FieldError;
} & UseControllerProps<any>) => {
  const query = useQuery('profileCategories', () => profileCategories({}));

  return (
    <>
      {match(query)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          return (
            <SelectPresentational
              label={label}
              name={name}
              control={control}
              disabled={disabled}
              defaultValue={defaultValue}
              options={query.data?.data?.map((position) => ({
                value: position.id,
                label: position.attributes?.title,
              }))}
              required={required}
              error={error}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default ProfileCategorySelect;

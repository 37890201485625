import axios from 'axios';
import qs from 'qs';
import {
  Participant,
  ParticipantDataCreation,
  ParticipantsResponse,
} from '../interfaces/participants';
import { DiplomaForm } from '../components/Modals/CreateDiplomaModal';
import { StrapiResponseList } from '../interfaces/commons';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const find = async ({
  token,
  filters,
  populate = '*',
  pagination,
  sort,
}: {
  populate?: object | string;
  filters?: object;
  sort?: string;
  token?: string | null;
  pagination?: Object;
}) => {
  const query = {
    populate,
    filters,
    pagination,
    sort,
  };

  const { data } = await axios.get<StrapiResponseList<ParticipantsResponse>>(
    `${REACT_APP_API_URL}/admin/course-participants?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const bulkUpdate = async ({
  body,
  token,
}: {
  body: { participantsIds: number[]; data: Participant };
  token?: string | null;
}) => {
  const { data } = await instance.put<{ data: ParticipantsResponse[] }>(
    `${REACT_APP_API_URL}/course-participant/bulk-update`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const bulkUpdateParticipants = async ({
  body,
  token,
}: {
  body: { participants: { id: number; data: Participant }[] };
  token?: string | null;
}) => {
  const { data } = await instance.put<{ data: ParticipantsResponse[] }>(
    `${REACT_APP_API_URL}/course-participant/bulk-update-participants`,
    { participants: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const bulkPrintDiplomas = async ({
  body,
  token,
  fileName,
  query,
}: {
  body: { participantsIds: number[]; customData?: DiplomaForm };
  token?: string | null;
  fileName: string;
  query: object;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/course-participant/bulk-print?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.pdf`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const create = async ({
  body,
  token,
  query,
}: {
  body: ParticipantDataCreation;
  token?: string | null;
  query?: object;
}) => {
  const { data } = await instance.post<{ data: ParticipantsResponse }>(
    `${REACT_APP_API_URL}/course-participants?${qs.stringify(query)}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const findOne = async ({
  id,
  token,
  query,
}: {
  id: string;
  token?: string | null;
  query?: object;
}) => {
  const { data } = await instance.get<{ data: ParticipantsResponse }>(
    `${REACT_APP_API_URL}/course-participants/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const update = async ({
  id,
  body,
  token,
}: {
  id: string;
  body: Participant;
  token?: string | null;
}) => {
  const { data } = await instance.put<{ data: ParticipantsResponse }>(
    `${REACT_APP_API_URL}/course-participants/${id}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const downloadCSV = async ({
  token,
  body,
  query,
  fileName,
}: {
  token?: string | null;
  body: Object;
  query?: Object;
  fileName: string;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/course-participant/csv?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const courseParticipants = {
  find,
  findOne,
  create,
  bulkUpdate,
  bulkUpdateParticipants,
  bulkPrintDiplomas,
  update,
  downloadCSV,
};

export default courseParticipants;

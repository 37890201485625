import moment from 'moment';
import { CourseLessonResponse } from '../../../../interfaces/lesson';
import { ClockIcon, PencilIcon } from '@heroicons/react/outline';
import Badge from '../../../../components/Badge';
import RegisterPaymentModal from '../../../../components/Modals/RegisterPaymentModal';
import IconLink from '../../../../components/TextLink/IconLink';
import DeleteLessonModal from '../../../../components/Modals/DeleteLessonModal';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import Checkbox from '../../../../components/Form/Checkbox';
import classNames from '../../../../libs/utils/classNames';

interface LessonRowProps {
  page?: number | null;
  pageSize?: number | null;
  lesson: CourseLessonResponse;
  lessonIdx: number;
  isCourseCanceled?: boolean;
  courseId?: string;
  isDetail?: boolean;
  isDirty?: boolean;
  disabled?: boolean;
  selectedLessons?: Partial<
    CourseLessonResponse & {
      multiple?: boolean | undefined;
    }
  >[];

  setSelectedLessons?: React.Dispatch<
    React.SetStateAction<
      Partial<
        CourseLessonResponse & {
          multiple?: boolean | undefined;
        }
      >[]
    >
  >;
}

const LessonRow: React.FC<LessonRowProps> = ({
  page,
  pageSize,
  lesson,
  lessonIdx,
  isCourseCanceled,
  courseId,
  isDetail,
  isDirty,
  disabled,
  selectedLessons = [],
  setSelectedLessons,
}) => {
  const { attributes: teacher } = lesson.attributes.teacher?.data || {};
  const { isWaiverTeacher, waiverTeacher } = lesson.attributes;
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const toggleWarning = () => setIsWarningOpen((v) => !v);
  const isRowSelected = useMemo<boolean>(
    () =>
      !!selectedLessons
        .filter((elem) => elem.multiple)
        .find((_lesson) => lesson?.id === _lesson?.id),
    [selectedLessons]
  );
  const navigate = useNavigate();
  const AcceptanceStatusBadge: React.FC<{ status: string }> = ({ status }) => {
    if (status === 'PENDING') return <Badge gray text='In attesa' />;
    if (status === 'REJECTED') return <Badge red text='Rifiutato' />;
    if (status === 'ACCEPTED') return <Badge green text='Accettato' />;
    return null;
  };

  const handleModifyLesson = () => {
    if (isDirty) return toggleWarning();
    if (isCourseCanceled) return;
    if (disabled) return;
    isDetail
      ? navigate(
          `/corsi/dettaglio-corso/${courseId}/lezioni/modifica-lezione/${lesson.id}`
        )
      : navigate(
          `/corsi/nuovo-corso/${courseId}/lezioni/modifica-lezione/${lesson.id}`
        );
  };

  return (
    <>
      <tr
        className={classNames(
          'cursor-pointer hover:bg-neutral-100',
          isRowSelected ? 'bg-sand-50' : ''
        )}
        onClick={handleModifyLesson}
      >
        <td
          className='whitespace-nowrap px-8 py-4'
          onClick={(e) => e.stopPropagation()}
        >
          {setSelectedLessons && (
            <Checkbox
              value={lesson?.id}
              checked={isRowSelected}
              onChange={(e) => {
                setSelectedLessons(
                  e.target.checked
                    ? [...selectedLessons, { ...lesson, multiple: true }]
                    : selectedLessons.filter((p) => p?.id !== lesson?.id)
                );
              }}
            />
          )}
        </td>
        <td
          className='whitespace-nowrap px-6 py-4'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='font-IBM w-8 h-8 flex items-center justify-center border border-gray-700 text-gray-700 rounded-full text-sm font-medium'>
            {page === 1
              ? lessonIdx + 1
              : lessonIdx +
                1 +
                Number(page) * Number(pageSize) -
                Number(pageSize)}
          </div>
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-600 flex flex-col items-start'>
          {lesson.attributes.date ? (
            <>
              {' '}
              <p>{moment(lesson.attributes.date).format('DD/MM/YYYY')}</p>
              <p className='flex items-center gap-1 text-primary font-light'>
                <ClockIcon className='h-4 w-4 ' />{' '}
                {moment
                  .utc(lesson.attributes.date, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                  .local()
                  .format('HH:mm')}
              </p>
            </>
          ) : (
            '-'
          )}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {lesson.attributes.title}
        </td>

        <td className='whitespace-nowrap px-6 py-4  text-sm text-gray-500'>
          <div className='flex items-center gap-2'>
            {isWaiverTeacher && waiverTeacher}
            {!isWaiverTeacher &&
              `${teacher?.name || ''} ${teacher?.surname || '-'}`}
          </div>
        </td>
        <td className='whitespace-nowrap px-6 py-4  text-sm text-gray-500'>
          <div className='flex items-center gap-2'>
            {lesson.attributes.emailToTeacherSent ? 'Sì' : 'No'}
          </div>
        </td>
        <td className='whitespace-nowrap px-6 py-4  text-sm text-gray-500'>
          <div className='flex items-center gap-2'>
            {isWaiverTeacher && <Badge orange text='Docente in deroga' />}
            {!isWaiverTeacher &&
              teacher &&
              lesson.attributes.teachersAcceptanceStatus && (
                <AcceptanceStatusBadge
                  status={lesson.attributes.teachersAcceptanceStatus}
                />
              )}
          </div>
        </td>

        {lesson.attributes.paymentDate ? (
          <>
            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
              € {lesson.attributes.actualPayment}
            </td>
            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
              € {lesson.attributes.actualRefund}
            </td>
            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
              {moment(lesson.attributes.paymentDate).format('DD/MM/YYYY')}
            </td>
          </>
        ) : (
          <td colSpan={3}>
            <RegisterPaymentModal lesson={lesson} />
          </td>
        )}

        <td
          className='whitespace-nowrap px-6 py-4 '
          onClick={(e) => e.stopPropagation()}
        >
          <div className='flex items-center justify-end gap-2'>
            <button onClick={handleModifyLesson}>
              <IconLink
                orangeTxt
                // href={
                //   isDetail
                //     ? `/corsi/dettaglio-corso/${courseId}/lezioni/modifica-lezione/${lesson.id}`
                //     : `/corsi/nuovo-corso/${courseId}/lezioni/modifica-lezione/${lesson.id}`
                // }
                disabled={isCourseCanceled || disabled}
              >
                <PencilIcon className='w-6 h-6' />
              </IconLink>
            </button>
            <DeleteLessonModal
              lessonId={lesson.id}
              titleLesson={lesson.attributes.title}
              buttonType='icon'
              disabled={isCourseCanceled || disabled}
            />
          </div>
        </td>
      </tr>
      <ConfirmationModal
        isOpen={isWarningOpen}
        onClose={toggleWarning}
        onConfirm={() =>
          isDetail
            ? navigate(
                `/corsi/dettaglio-corso/${courseId}/lezioni/modifica-lezione/${lesson.id}`
              )
            : navigate(
                `/corsi/nuovo-corso/${courseId}/lezioni/modifica-lezione/${lesson.id}`
              )
        }
        title='ATTENZIONE!'
        subtitle='Se prosegui perderai le ultime modifiche apportate'
        textButton='Prosegui'
      />
    </>
  );
};

export default LessonRow;

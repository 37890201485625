import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import ModificaUtente from './Modifica';
import NuovoUtente from './Nuovo';
import ListaUtenti from './listaUtenti';
import { useAuth } from '../../contexts/Auth';
import { isDelegato } from '../../libs/utils/auth';

const Utenti = () => {
  const [{ profile }] = useAuth();
  const SIDEBAR_ITEMS = useMemo(() => {
    const commonSections = [{ href: '/utenti', label: 'Tutti gli utenti' }];
    if (isDelegato(profile)) {
      return commonSections;
    } else
      return [
        ...commonSections,
        { href: '/utenti/nuovo', label: 'Nuovo utente' },
      ];
  }, []);

  return (
    <div className='grid w-full grid-cols-12 gap-8 px-4 pb-10 pt-7 lg:py-12 lg:px-10'>
      <Sidebar items={SIDEBAR_ITEMS} />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <Routes>
          <Route index element={<ListaUtenti />} />
          <Route path='nuovo/*' element={<NuovoUtente />} />
          <Route path='modifica/:id/*' element={<ModificaUtente />} />
        </Routes>
      </div>
    </div>
  );
};

export default Utenti;

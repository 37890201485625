import React, { useEffect } from 'react';

import { UseFormReturn } from 'react-hook-form';
import Underline from './TextLink/Underline';
import ExtraPartecipantItem from './extraParticipantItem';
import { EventParticipantForm } from './Modals/AddEventParticipantsModal';

interface Props {
  participantForm: UseFormReturn<EventParticipantForm, any>;
}
const ExtraParticipantSection: React.FC<Props> = ({ participantForm }) => {
  const { watch, setValue } = participantForm;

  useEffect(() => {
    // @ts-ignore
    setValue('quantity', (Number(watch('participants')?.length) || 0) + 1);
    // @ts-ignore
  }, [watch('participants')]);

  return (
    <div className='flex flex-col items-center justify-center w-full gap-6 mt-6'>
      {watch('guests')?.map((_guest, idx) => (
        <ExtraPartecipantItem
          key={`event-participant-${idx}`}
          index={idx}
          productForm={participantForm}
        />
      ))}

      {(!watch('guests') || watch('guests')?.length < 1) && (
        <button
          type='button'
          onClick={() =>
            setValue('guests', [
              ...(watch('guests') || []),
              {
                firstName: '',
                lastName: '',
                id: (watch('guests')?.length || 0) + 1,
              },
            ])
          }
        >
          <Underline label='Aggiungi socio pagante' />
        </button>
      )}
    </div>
    // </TitleBordered>
  );
};

export default ExtraParticipantSection;

import React from "react";
import Header from "../NavBar/Header";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <>
      <Header />
      <div className="App bg-white overflow-x-hidden pr-4 lg:pr-0">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
export default MainLayout;

import { useEffect } from 'react';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import InputPresentational from '../../components/Form/InputPresentational';
import IntroSctTitle from '../../components/IntroSctTitle';
import TitlePage from '../../components/TitlePage';
import H3Styled from '../../components/Typography/H3Styled';
import { useMutation, useQuery } from 'react-query';
import { profileInfoFromUser, updateProfile } from '../../api/profile';
import { match } from 'ts-pattern';
import FormLoading from '../../components/Layout/Loading/FormLoading';
import SectionsSelect from '../../components/FetchSelect/SectionsSelect';
import OnavPositionsSelect from '../../components/FetchSelect/OnavPosizionsSelect';
import { useAuth } from '../../contexts/Auth';
import UserBooleanBox from '../../components/UserBooleanBox';
import SectionsModal from '../../components/SectionsModal';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import RoleSelect from '../../components/FetchSelect/RoleSelection';
import FormDatiPrincipaliUtente from '../../components/FormDatiPrincipaliUtenti';
import { isSegreteriaNazionale } from '../../libs/utils/auth';

const Profilo = () => {
  const [{ profile, token }] = useAuth();
  const [searchParams] = useSearchParams();

  const profileQuery = useQuery(
    ['profile', profile?.id, searchParams.get('newBadge')],
    () => {
      if (!profile?.id) return;
      return profileInfoFromUser(profile?.id, token);
    }
  );

  const { mutate, isLoading } = useMutation(
    'updateProfileUser',
    updateProfile,
    {
      onSuccess: (data) => {
        profileQuery.refetch();
        toast.success('Profilo aggiornato con successo');
      },
      onError: (error: any) => {
        toast.error(error.response.data.error.message);
      },
    }
  );

  const updateProfileInfo = async (data: FieldValues) => {
    profileQuery.data?.data.id
      ? mutate({ id: profileQuery.data?.data.id, body: data, token })
      : toast.error('Profilo non trovato');
  };

  const userUpdateForm = useForm({
    mode: 'onSubmit',
  });
  const { handleSubmit, setValue, control, reset, watch } = userUpdateForm;

  useEffect(() => {
    reset();
  }, [profileQuery.data]);

  return (
    <>
      <TitlePage title='Area riservata' />

      {match(profileQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <div className='pb-7 lg:pb-12 px-4 lg:px-10 w-full space-y-10'>
            <FormLoading />
          </div>
        ))
        .with({ status: 'error' }, () => (
          <div className='w-full font-normal text-gray-500 pr-6 text-center my-20'>
            Errore caricamento dati
          </div>
        ))
        .with({ status: 'success' }, (query) => {
          if (query.data) {
            return (
              <FormProvider {...userUpdateForm}>
                <form onSubmit={handleSubmit(updateProfileInfo)}>
                  <IntroSctTitle>
                    <div className='flex justify-between'>
                      <p>
                        <span className='font-medium'>Area riservata di</span>{' '}
                        {query.data.data.attributes.surname +
                          ' ' +
                          query.data.data.attributes.name}
                      </p>
                      <PrimaryButton type='submit'>
                        Salva modifiche
                      </PrimaryButton>
                    </div>
                  </IntroSctTitle>
                  <div className='pb-7 lg:pb-12 px-4 lg:px-10 w-full space-y-10 mt-12'>
                    <FormDatiPrincipaliUtente infoUser={query.data.data} />

                    <div className='mt-12'>
                      <H3Styled>Dati ONAV utente</H3Styled>
                      <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2  gap-x-8 gap-y-6'>
                        <div className='flex flex-col gap-y-6'>
                          <RoleSelect
                            disabled={!isSegreteriaNazionale(profile)} // Non puoi segliere un ruolo se non sei sereteria nazionale
                            control={control}
                            defaultValue={profile?.role.name}
                          />
                          <OnavPositionsSelect
                            defaultValue={
                              query.data.data.attributes.onavPosition?.data?.id
                            }
                            control={control}
                            disabled={!isSegreteriaNazionale(profile)}
                          />
                          <InputPresentational
                            id='title'
                            label='Titolo ONAV - categoria'
                            name='title'
                            type='text'
                            disabled
                            defaultValue={
                              query.data.data.attributes.profileCategory?.data
                                ?.attributes.title
                            }
                          />
                        </div>
                        <UserBooleanBox
                          userInfo={query.data.data}
                          setValueForm={setValue}
                        />

                        <fieldset className='space-y-5'></fieldset>
                      </div>
                    </div>
                    <div className='mt-12 pb-40'>
                      <H3Styled>Sezione di appartenenza</H3Styled>
                      <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6'>
                        <div className='grid gap-x-8 gap-y-6'>
                          <SectionsSelect
                            label='Sezione di appartenenza'
                            defaultValue={
                              query.data.data.attributes.section?.data?.id
                            }
                          />
                        </div>
                        <div>
                          <label className='block text-sm text-gray-500'>
                            Altre sezioni seguite
                          </label>

                          <SectionsModal
                            setValueForm={setValue}
                            defaultValues={
                              query.data.data.attributes.secondarySections
                                ?.data || []
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            );
          } else {
            return (
              <div className='w-80 text-sm font-normal text-gray-500 text-center pr-6 my-6'>
                Non hai i permessi per visualizzarli
              </div>
            );
          }
        })
        .exhaustive()}
    </>
  );
};

export default Profilo;

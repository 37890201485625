import { getGroupFilter } from './helpers';

export const buildPrintDigitalMagazineFilters = (
  searchParams: URLSearchParams
) => {
  const query = getGroupFilter({
    filterName: 'printDigitalMagazine',
    searchParams,
  });

  let result: any = {};

  if (!query) return result;
  result = { printDigitalMagazine: query };

  if (query.includes('false')) {
    result = {
      $or: [
        { printDigitalMagazine: false },
        { printDigitalMagazine: { $null: true } },
      ],
    };
    if (query.includes('true')) {
      result = {
        ...result,
        $or: [...result.$or, { printDigitalMagazine: true }],
      };
    }
  }

  return result;
};

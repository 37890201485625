import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';
import classNames from '../libs/utils/classNames';

interface SidebarItem {
  href: string;
  label: string;
}
interface SidebarProps {
  items: SidebarItem[];
  secondMenuTitle?: string;
  secondMenuItems?: SidebarItem[];
}

export default function Sidebar({
  items,
  secondMenuTitle,
  secondMenuItems,
}: SidebarProps) {
  const location = useLocation();
  let act;

  return (
    <div className='col-span-12 lg:col-span-3 xl:col-span-2 text-gray-500'>
      <ul className='space-y-2'>
        {items.map((item: any, key) => (
          <li
            key={item.href}
            className={classNames(
              (
                key === 0
                  ? item.href === location.pathname
                  : location.pathname.includes(item.href)
              )
                ? ' cursor-default text-primary'
                : 'hover:text-primary '
            )}
          >
            <Link to={item.href} className='flex items-center'>
              <ChevronRightIcon className='w-4 h-4 inline-block mr-4' />
              <span>{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>

      {secondMenuItems && (
        <>
          <h3 className='mt-6 mb-4 font-IBM text-gray-800 font-medium text-lg'>
            {secondMenuTitle}
          </h3>

          <ul className='space-y-2'>
            {secondMenuItems.map((item: any, key) => (
              <li
                key={item.href}
                className={classNames(
                  (
                    key === 0
                      ? item.href === location.pathname
                      : location.pathname.includes(item.href)
                  )
                    ? ' cursor-default text-primary'
                    : 'hover:text-primary '
                )}
              >
                <Link to={item.href} className='flex items-center'>
                  <ChevronRightIcon className='w-4 h-4 inline-block mr-4' />
                  <span>{item.label}</span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { profileHistory } from '../../../../api/profile-history';
import { useAuth } from '../../../../contexts/Auth';
import UtenteStoricoTable from '../../Tables/UtenteStorico';
import Pagination from '../../../../components/Pagination';

const ModificaUtenteStorico = () => {
  const params = useParams();
  const id = Number(params.id);
  const [{ token }] = useAuth();
  const query = useQuery('profileHistory', () => profileHistory(id, token));

  return (
    <>
      <UtenteStoricoTable query={query} />
      {/* <Pagination pagination={query.} /> */}
    </>
  );
};

export default ModificaUtenteStorico;

import TitlePage from '../../../../components/TitlePage';
import { useMutation, useQueryClient } from 'react-query';
import { CourseRequest } from '../../../../interfaces/courses';
import { ControlledSelect } from '../../../../components/Form/Selects';
import { UseFormReturn } from 'react-hook-form';
import H4SpacingLetter from '../../../../components/Typography/H4SpacingLetter';
import { updateCourse } from '../../../../api/courses';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../contexts/Auth';
import { useParams } from 'react-router-dom';
import { CourseTemplateRequest } from '../../../../interfaces/course-templates';
import { COSTS_CENTER_OPTIONS } from '../../../../libs/utils/options';

interface Props {
  title: string;
  form: UseFormReturn<CourseTemplateRequest, any>;
}

const CostsCenter: React.FC<Props> = ({ title, form }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;
  const queryClient = useQueryClient();
  const [{ token }] = useAuth();
  const { id } = useParams();

  const { mutateAsync: saveCourseMutation } = useMutation({
    mutationKey: ['saveCourse', id],
    mutationFn: ({
      courseId,
      courseWithUpdatedStatus,
    }: {
      courseId: number;
      courseWithUpdatedStatus: CourseRequest;
    }) => updateCourse(courseId, courseWithUpdatedStatus, token),
    onSuccess: () => {
      toast.success('Stato corso modificato con successo!');
      queryClient.invalidateQueries({
        queryKey: ['course', id],
      });
    },
    onError: (err) => {
      console.log('err', err);
      toast.error('Qualcosa è andato storto!');
    },
  });

  const onSubmit = async (values: CourseTemplateRequest) => {
    saveCourseMutation({
      courseId: Number(id),
      courseWithUpdatedStatus: values as any,
    });
  };

  return (
    <>
      <TitlePage title={`Dettaglio corso | ${title}`} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='pt-6 pb-8 space-y-6 flex md:w-1/2 w-full'>
          <ul className='grid grid-cols-1 w-full gap-y-4'>
            <li className='flex flex-row justify-between'>
              <div className='col-span-6 w-full'>
                <H4SpacingLetter>Quota associativa</H4SpacingLetter>
              </div>
              <div className='col-span-6 w-full'>
                <ControlledSelect
                  control={control as any}
                  name={'membershipFee'}
                  options={COSTS_CENTER_OPTIONS}
                  required
                  error={{ ...errors.membershipFee, type: 'required' }}
                />
              </div>
            </li>
            <li className='flex flex-row justify-between'>
              <div className='col-span-6 w-full'>
                <H4SpacingLetter>Quota di iscrizione</H4SpacingLetter>
              </div>
              <div className='col-span-6 w-full'>
                <ControlledSelect
                  control={control as any}
                  name={'registrationFee'}
                  options={COSTS_CENTER_OPTIONS}
                  required
                  error={{ ...errors.registrationFee, type: 'required' }}
                />
              </div>
            </li>
            <li className='flex flex-row justify-between'>
              <div className='col-span-6 w-full'>
                <H4SpacingLetter>Quota corso</H4SpacingLetter>
              </div>
              <div className='col-span-6 w-full'>
                <ControlledSelect
                  control={control as any}
                  name={'courseFee'}
                  options={COSTS_CENTER_OPTIONS}
                  required
                  error={{ ...errors.courseFee, type: 'required' }}
                />
              </div>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default CostsCenter;

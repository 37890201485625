import { useState } from 'react';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { fetchCoursesForCoupon } from '../../api/courses';
import { SearchableSelect } from '../Form/SearchableSelect';
import { useDebounce } from 'use-debounce';
import FullPageSpinner from '../Layout/Loading/FullPageSpinner';
import { useAuth } from '../../contexts/Auth';

const CourseSelect = ({
  defaultValue,
  control,
  name,
}: {
  defaultValue?: number | string;
  control?: any;
  name?: string;
}) => {
  const [search, setSearch] = useState<string>('');
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const [debouncedSearch] = useDebounce(search, 500);
  const [{ token }] = useAuth();

  const query = useQuery(
    ['coursesCoupon', debouncedSearch],
    () => {
      const keywords = debouncedSearch
        .trim()
        .split(' ')
        .filter((keyword) => keyword !== '');

      const searchFilters = keywords?.map((keyword) => {
        return {
          $or: [{ title: { $contains: keyword } }],
        };
      });

      return fetchCoursesForCoupon({
        query: {
          filters: {
            $and: [
              ...searchFilters,
              { $or: [{ status: 'published' }, { status: 'draft' }] },
            ],
          },
        },
        token,
      });
    },
    {
      onSuccess: () => {
        setFirstRender(true);
      },
    }
  );

  /**
   * Se renderizzi la select senza le opzioni, non visualizza la label di default anche se dopo arriva dall'api
   * Quindi faremo vedere la select solo dopo il primo fetch, quando abbiamo le opzioni
   */
  if (query.isFetching && !firstRender) {
    return <FullPageSpinner />;
  }

  return (
    <SearchableSelect
      label='Seleziona il corso'
      name={name || 'course'}
      defaultValue={defaultValue}
      onInput={setSearch}
      control={control}
      isLoading={query.isFetching}
      options={match(query)
        .with(
          { status: 'error' },
          { status: 'idle' },
          { status: 'loading' },
          () => []
        )
        .with({ status: 'success' }, (query) => {
          const options = query.data.data.map((course) => ({
            value: course.id,
            label: course.attributes.title,
          }));
          return options;
        })
        .exhaustive()}
    />
  );
};

export default CourseSelect;

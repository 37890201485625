import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import FormCostiEventi from '../../../../components/FormCostiEventi';
import TitlePage from '../../../../components/TitlePage';

import { EventAttributes, EventRequest } from '../../../../interfaces/events';
import {
  Balance,
  HeadquarterBalance,
} from '../../../../interfaces/strapiComponents/balance';
import { useQuery } from 'react-query';
import eventsAPIs from '../../../../api/events';
import { useAuth } from '../../../../contexts/Auth';
import { useParams } from 'react-router-dom';
import FullPageSpinner from '../../../../components/Layout/Loading/FullPageSpinner';
import {
  isCourseCostsFormEnabled,
  isEventBalanceEnabledForEdit,
} from '../../../../libs/utils/helpers';

interface Props {
  defaultEvent: EventAttributes | undefined;
}

const CostiEvento: React.FC<Props> = ({ defaultEvent }) => {
  const { watch, setValue, getValues } = useFormContext<EventRequest>();
  const { eventId } = useParams();

  const [{ token, profile }] = useAuth();

  const { isFetching } = useQuery(
    'findEventBalance',
    () => eventsAPIs.findBalance({ token, id: eventId }),
    {
      enabled: !!token && !!eventId,
      onSuccess: (data) => {
        setValue(
          'headquarterBalance.cards.finalIncome',
          data.subscriptionFinalIncome
        );
        setValue('balance.price.finalIncome', data.finalIncome);
      },
    }
  );

  const lessons = 1; // ??
  const partecipants = watch('partecipations');

  const minParticipants = watch('minParticipants');
  const subscriptionAmount = watch('subscriptionAmount');

  const balance = watch('balance') || {};
  const balanceVoiceKeys = Object.keys(balance) as unknown as (keyof Balance)[];

  const headquarterBalance = watch('headquarterBalance') || {};
  const headquarterBalanceVoiceKeys = Object.keys(
    headquarterBalance
  ) as unknown as (keyof HeadquarterBalance)[];

  const getEstimatedOutflow = (voicesToSkip?: string[]) => {
    const estimatedOutflows = balanceVoiceKeys.reduce(
      (estimatedOutflows, balanceVoice) => {
        const skipVoice =
          voicesToSkip?.indexOf(balanceVoice)! > -1 || balanceVoice === 'id';
        if (skipVoice) return estimatedOutflows;
        return (
          estimatedOutflows + (balance[balanceVoice]?.estimatedOutflow || 0)
        );
      },
      0
    );
    return estimatedOutflows;
  };

  const getEstimatedIncome = () => {
    const estimatedIncomes = balanceVoiceKeys.reduce(
      (estimatedIncomes, balanceVoice) => {
        if (balanceVoice === 'id') return estimatedIncomes;
        return estimatedIncomes + (balance[balanceVoice]?.estimatedIncome || 0);
      },
      0
    );
    return estimatedIncomes;
  };

  const getFinalOutflow = (voicesToSkip?: string[]) => {
    const finalOutflows = balanceVoiceKeys.reduce(
      (finalOutflows, balanceVoice) => {
        const skipVoice =
          voicesToSkip?.indexOf(balanceVoice)! > -1 || balanceVoice === 'id';
        if (skipVoice) return finalOutflows;
        return finalOutflows + (balance[balanceVoice]?.finalOutflow || 0);
      },
      0
    );

    return finalOutflows;
  };

  const getFinalIncome = () => {
    const finalIncomes = balanceVoiceKeys.reduce(
      (finalIncomes, balanceVoice) => {
        if (balanceVoice === 'id') return finalIncomes;
        return finalIncomes + (balance[balanceVoice]?.finalIncome || 0);
      },
      0
    );
    return finalIncomes;
  };

  const getEstimatedWinesOutflow = () => {
    const wines = watch('wines');
    if (!wines) return 0;

    const estimatedWinesOutflow = wines.reduce(
      (estimatedWinesOutflow, wine) => {
        const estimatedTotal =
          wine.estimatedPricePerBottle * wine.estimatedBottles;
        return estimatedWinesOutflow + estimatedTotal;
      },
      0
    );
    return estimatedWinesOutflow;
  };

  const getFinalWinesOutflow = () => {
    const wines = watch('wines');
    if (!wines) return 0;

    const finalWinesOutflow = wines.reduce((finalWinesOutflow, wine) => {
      const finalTotal = wine.finalPricePerBottle * wine.finalBottles;
      return finalWinesOutflow + finalTotal;
    }, 0);
    return finalWinesOutflow;
  };

  const getEstimatedHeadquarterOutflow = () => {
    const estimatedOutflow = headquarterBalanceVoiceKeys.reduce(
      (estimatedOutflow, balanceVoice) => {
        if (balanceVoice === 'id') return estimatedOutflow;
        return (
          estimatedOutflow + headquarterBalance[balanceVoice]?.estimatedOutflow
        );
      },
      0
    );

    return estimatedOutflow;
  };

  const getFinalHeadquarterOutflow = () => {
    const finalOutflow = headquarterBalanceVoiceKeys.reduce(
      (finalIncomes, balanceVoice) => {
        if (balanceVoice === 'id') return finalIncomes;
        return finalIncomes + headquarterBalance[balanceVoice]?.finalOutflow;
      },
      0
    );
    return finalOutflow;
  };

  const updatePriceVoice = () => {
    const updatedEstimatedIncome = subscriptionAmount * minParticipants;
    setValue('balance.price.estimatedPriceUnit', subscriptionAmount);
    setValue('balance.price.estimatedIncome', updatedEstimatedIncome);

    setValue('balance.price.finalPriceUnit', subscriptionAmount);
    if (!partecipants?.data.length) return;
  };

  const updateFoodVoice = () => {
    const food = getValues('balance.food');
    const updatedEstimatedOutflow = food?.estimatedPriceUnit * minParticipants;
    setValue('balance.food.estimatedOutflow', updatedEstimatedOutflow);

    const confirmedParticipants = watch('confirmedParticipants');

    if (!confirmedParticipants) return;

    const updatedFinalPriceUnit = food?.finalOutflow / confirmedParticipants;
    setValue('balance.food.finalPriceUnit', updatedFinalPriceUnit);
  };

  const updateRoomsVoice = () => {
    const rooms = getValues('balance.rooms');

    const updatedEstimatedPriceUnit = rooms.estimatedOutflow / lessons;
    setValue('balance.rooms.estimatedPriceUnit', updatedEstimatedPriceUnit);

    const updatedFinalPriceUnit = rooms.finalOutflow / lessons;
    setValue('balance.rooms.finalPriceUnit', updatedFinalPriceUnit);
  };

  const updateSponsorshipVoice = () => {
    const sponsorship = getValues('balance.sponsorship');

    const updatedEstimatedPriceUnit =
      sponsorship.estimatedOutflow / minParticipants;
    setValue(
      'balance.sponsorship.estimatedPriceUnit',
      updatedEstimatedPriceUnit
    );

    if (!partecipants?.data.length) return;
    const updatedFinalPriceUnit =
      sponsorship.finalOutflow / partecipants.data.length;

    setValue('balance.sponsorship.finalPriceUnit', updatedFinalPriceUnit);
  };

  const updateExtraVoice = () => {
    const estimatedExtraOutflow = getEstimatedOutflow(['extras']) * 0.1;

    setValue('balance.extras.estimatedOutflow', estimatedExtraOutflow);
  };

  const updateTeacherPaymentsVoice = () => {
    const teacherPayments = watch('balance.teacherPayments');

    const estimatedTeacherPaymentsPriceUnit =
      teacherPayments.estimatedOutflow / minParticipants;

    setValue(
      'balance.teacherPayments.estimatedPriceUnit',
      estimatedTeacherPaymentsPriceUnit
    );
    // const finalTeacherPaymentsPriceUnit =
    //   teacherPayments.finalOutflow / partecipants.length;
    //   setValue(
    //     'balance.teacherPayments.finalPriceUnit',
    // finalTeacherPaymentsPriceUnit
    // );
  };

  const updateTeacherRefundsVoice = () => {
    const teacherRefunds = watch('balance.teacherRefunds');

    const estimatedTeacherRefundsPriceUnit =
      teacherRefunds.estimatedOutflow / minParticipants;
    setValue(
      'balance.teacherRefunds.estimatedPriceUnit',
      estimatedTeacherRefundsPriceUnit
    );

    // const finalTeacherRefundsPriceUnit =
    //   teacherRefunds.finalOutflow / partecipants.length;
    // setValue(
    //   'balance.teacherRefunds.finalPriceUnit',
    //   finalTeacherRefundsPriceUnit
    // );
  };

  const updateTeachingMaterialVoice = () => {
    const teachingMaterials = getValues('headquarterBalance.teachingMaterial');
    const totalParticipants = watch('confirmedParticipants');
    const defaultTeachingMaterialsBalance =
      defaultEvent?.headquarterBalance?.teachingMaterial;

    /**
     * SOLO IN MODIFICA
     *
     * Se l'utente modifica il valore delle entrate della quota material didattico,
     * allora bisogna segnarlo attraverso l'attributo isTouched
     *
     * Questo servirà poi quando si sincronizza il bilancio.
     *
     * Se è stato toccato significa che non verrà più calcolato automaticamente.
     */

    if (defaultTeachingMaterialsBalance) {
      const { isTouched, finalOutflow: defaultFinalOutflow } =
        defaultTeachingMaterialsBalance;

      if (
        !isTouched &&
        defaultFinalOutflow !== teachingMaterials.finalOutflow
      ) {
        setValue('headquarterBalance.teachingMaterial.isTouched', true);
      } else if (!isTouched) {
        setValue('headquarterBalance.teachingMaterial.isTouched', false);
      }
    }

    const estimatedOutflow =
      teachingMaterials?.estimatedPriceUnit * minParticipants;
    const finalPriceUnit = totalParticipants
      ? teachingMaterials?.finalOutflow / totalParticipants
      : 0;

    setValue(
      'headquarterBalance.teachingMaterial.estimatedOutflow',
      estimatedOutflow
    );
    setValue(
      'headquarterBalance.teachingMaterial.finalPriceUnit',
      finalPriceUnit
    );
  };

  const updateWinesVoice = () => {
    const estimatedWinesOutflow = getEstimatedWinesOutflow();
    const finalWinesOutflow = getFinalWinesOutflow();

    const wines = watch('wines') || [];

    const estimatedBottles = wines.reduce((bottles, wine) => {
      return bottles + wine.estimatedBottles;
    }, 0);

    const finalBottles = wines.reduce((bottles, wine) => {
      return bottles + wine.finalBottles;
    }, 0);

    const estimatedWinesPriceUnit = estimatedWinesOutflow / estimatedBottles;

    setValue('balance.wines.estimatedPriceUnit', estimatedWinesPriceUnit);
    setValue('balance.wines.estimatedOutflow', estimatedWinesOutflow);

    if (!partecipants?.data.length) return;
    const finalWinesPriceUnit = finalWinesOutflow / finalBottles;
    setValue('balance.wines.finalPriceUnit', finalWinesPriceUnit);
    setValue('balance.wines.finalOutflow', finalWinesOutflow);
  };

  const watchedValues = watch([
    'minParticipants',
    'subscriptionAmount',
    'partecipations',

    'balance.food.estimatedPriceUnit',
    'balance.food.finalOutflow',

    'balance.rooms.estimatedOutflow',
    'balance.rooms.finalOutflow',

    'balance.contributes.estimatedIncome',
    'balance.contributes.finalIncome',

    'balance.sponsorship.estimatedOutflow',
    'balance.sponsorship.finalOutflow',

    'balance.teacherPayments.estimatedOutflow',
    'balance.teacherPayments.finalOutflow',

    'balance.teacherRefunds.estimatedOutflow',
    'balance.teacherRefunds.finalOutflow',

    'headquarterBalance.teachingMaterial.estimatedPriceUnit',
    'headquarterBalance.teachingMaterial.finalOutflow',

    'headquarterBalance.shipping.estimatedPriceUnit',
    'headquarterBalance.shipping.finalPriceUnit',

    'balance.wines.estimatedOutflow',
    'balance.wines.finalOutflow',
  ]);

  const estimatedWinesOutflow = getEstimatedWinesOutflow();
  const finalWinesOutflow = getFinalWinesOutflow();

  const disableForDelegates = useMemo(
    () => !isCourseCostsFormEnabled(watch('status')),
    [watch('status')]
  );

  const disableEditConsuntivo = useMemo(
    () => !isEventBalanceEnabledForEdit(watch('status'), profile),
    [watch('status'), profile]
  );

  useEffect(() => {
    updatePriceVoice();
    updateWinesVoice();
    updateFoodVoice();
    updateRoomsVoice();
    updateSponsorshipVoice();
    updateExtraVoice();
    updateTeacherPaymentsVoice();
    updateTeacherRefundsVoice();

    updateTeachingMaterialVoice();
  }, [
    partecipants,
    estimatedWinesOutflow,
    finalWinesOutflow,
    ...watchedValues,
  ]);

  return (
    <>
      {isFetching && <FullPageSpinner />}
      <TitlePage title={`Dettaglio evento | Costi`} />
      <FormCostiEventi
        estimatedIncome={getEstimatedIncome()}
        estimatedOutflow={getEstimatedOutflow()}
        finalIncome={getFinalIncome()}
        finalOutflow={getFinalOutflow()}
        estimatedHeadquarterOutflow={getEstimatedHeadquarterOutflow()}
        finalHeadquarterOutflow={getFinalHeadquarterOutflow()}
        disabled={disableForDelegates}
        disableEditConsuntivo={disableEditConsuntivo}
        eventStatus={watch('status')}
      />
    </>
  );
};

export default CostiEvento;

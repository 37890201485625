import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';
import { getProfiles } from '../../../../api/profile';
import { createJob, getSectionJob, updateJob } from '../../../../api/section';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import OnavPositionsSelect from '../../../../components/FetchSelect/OnavPosizionsSelect';
import InputPresentational from '../../../../components/Form/InputPresentational';
import { SearchableSelect } from '../../../../components/Form/SearchableSelect';
import TitlePage from '../../../../components/TitlePage';
import H1Styled from '../../../../components/Typography/H1Styled';
import roles from '../../../../constants/roles';
import { useAuth } from '../../../../contexts/Auth';
import { CreateJobBody } from '../../../../interfaces/section';
import useYupValidationResolver from '../../../../libs/YupValidationResolver';
import { sectionJobValidator } from '../../../../validators/sectionJob';
import { useDebounce } from 'use-debounce';
import { getQuerySearchFilters } from '../../../../libs/utils/profile';

export default function ModificaMansione() {
  const [{ token, profile }] = useAuth();
  const navigate = useNavigate();
  const { id, mansioneId } = useParams();

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);

  const [badge, setBadge] = useState<string>('');
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: useYupValidationResolver(sectionJobValidator),
  });

  if (mansioneId) {
    useQuery({
      queryKey: ['job', mansioneId],
      queryFn: () => getSectionJob({ jobId: Number(mansioneId), token }),
      onSuccess: (data) => {
        reset({
          // job: data.data.attributes.job,
          onavPosition: data?.data?.attributes?.onavPosition?.data?.id,
          profile: {
            value: data?.data?.attributes?.profile?.data?.id || null,
            label:
              data?.data?.attributes?.profile?.data?.attributes?.name &&
              data?.data?.attributes?.profile?.data?.attributes?.surname
                ? data?.data?.attributes?.profile?.data?.attributes?.name +
                  ' ' +
                  data?.data?.attributes?.profile?.data?.attributes?.surname
                : '-',
            cardNumber:
              data?.data?.attributes?.profile?.data?.attributes?.badge?.data
                ?.attributes?.badgeNumber,
          },
        });
      },
    });
  }

  const jobProfilesQuery = useQuery(
    ['getProfileListForNewJob', debouncedSearch],
    () => {
      return getProfiles({
        token,
        query: {
          filters: {
            $and: getQuerySearchFilters(debouncedSearch),
          },
          pagination: {
            limit: 100,
          },
          populate: ['badge'],
        },
      });
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    const { profile } = getValues();
    setBadge(profile?.cardNumber || '-');
  }, [watch('profile')]);

  const { mutate: createJobMutation } = useMutation('createJob', createJob, {
    onSuccess: () => {
      navigate('/sezioni/modifica/' + id + '/mansioni');
      toast.success('Mansione creata con successo!');
    },
    onError: () => {
      toast.error("Ops, qualcosa e' andato storto");
    },
  });

  const { mutate: updateJobMutation } = useMutation('updateJob', updateJob, {
    onSuccess: () => {
      navigate('/sezioni/modifica/' + id + '/mansioni');
      toast.success('Mansione modificata con successo!');
    },
    onError: () => {
      toast.error("Ops, qualcosa e' andato storto");
    },
  });

  const createNewJob = async (data: any) => {
    if (!data.onavPosition) return toast.error("Carica ONAV e' obbligatoria!");
    data.profile = data.profile.value;
    data.section = Number(id);

    if (mansioneId)
      updateJobMutation({
        body: data as CreateJobBody,
        token,
        jobId: mansioneId,
      });
    else createJobMutation({ body: data as CreateJobBody, token });
  };

  return (
    <>
      <TitlePage title='Crea nuovo corso | Location' />

      <form className='mt-6' onSubmit={handleSubmit(createNewJob)}>
        <H1Styled>
          {mansioneId ? 'Modifica' : 'Aggiungi Nuovo'} Ruolo istituzionale
        </H1Styled>
        <div className='grid grid-cols-1 gap-4 pt-10 pb-8 md:grid-cols-2'>
          <OnavPositionsSelect
            control={control}
            disabled={profile?.role.name !== roles.SEGRETERIA_NAZIONALE}
            required
            error={{ ...errors.onavPosition, type: 'required' }}
          />
          <SearchableSelect
            onInput={setSearch}
            name='profile'
            placeholder='Cerca per nome o cognome...'
            control={control}
            label='Nominativo *'
            required
            error={{ ...errors.profile, type: 'required' }}
            isLoading={jobProfilesQuery.isFetching}
            options={match(jobProfilesQuery)
              .with(
                { status: 'error' },
                { status: 'idle' },
                { status: 'loading' },
                () => []
              )
              .with({ status: 'success' }, (query) => {
                const options = (query?.data?.data || []).map((profile) => ({
                  label: `${profile.attributes.name} ${profile.attributes.surname}`,
                  value: profile.id,
                  cardNumber:
                    profile.attributes.badge?.data?.attributes?.badgeNumber,
                }));
                return options;
              })
              .exhaustive()}
          />

          <InputPresentational
            id='badge'
            label='Numero tessera'
            name='badge'
            type='text'
            disabled={true}
            defaultValue={badge}
          />
        </div>

        <div className='flex items-center h-10'>
          <div className='flex items-center justify-between space-x-6'>
            <PrimaryButton
              outline
              onClick={() => {
                navigate('/sezioni/modifica/' + id + '/mansioni');
              }}
            >
              Indietro
            </PrimaryButton>
            <PrimaryButton type='submit'>Salva</PrimaryButton>
          </div>
        </div>
      </form>
    </>
  );
}

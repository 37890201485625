/**
 * N.B. -> Quanto fatto di seguito non è da prendere come esempio, ma con le pinze.
 */
import { useEffect, useMemo } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import SendButton from '../Buttons/SandButton';
import * as Yup from 'yup';
import InputPresentational from '../Form/InputPresentational';
import PriceInputPresentational from '../Form/PriceInputPresentational';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/Auth';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { EventRequest, EventStatus } from '../../interfaces/events';
import { updateEvent } from '../../api/events';
import moment from 'moment';
import { isDelegato } from '../../libs/utils/auth';

interface FormModel {
  amount: number;
  paymentDate?: string | null;
}

interface Props {
  event: EventRequest;
}

const RegisterEventTeacherRefund: React.FC<Props> = ({ event }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [{ token, profile }] = useAuth();
  const disabled = useMemo(
    () =>
      ![EventStatus.PUBLISHED, EventStatus.FINAL_BALANCE_DELEGATION].includes(
        event.status
      ),
    [event]
  );

  const { eventId } = useParams();
  const queryClient = useQueryClient();

  const toggleModal = () => {
    setOpen((v) => !v);
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormModel>({
    resolver: useYupValidationResolver(
      Yup.object().shape({
        paymentDate: Yup.date()
          .typeError('Valore non validao')
          .required('Questo campo è obbligatorio'),
        amount: Yup.number()
          .min(0)
          .typeError('Valore non validao')
          .required('Questo campo è obbligatorio'),
      })
    ),
  });

  const { mutate, isLoading: isUpdating } = useMutation(
    ['addPaymentForCourseManagement'],
    ({
      eventId,
      values,
      token,
    }: {
      eventId: string;
      values: Partial<EventRequest>;
      token: string | null;
    }) => updateEvent(eventId, values as EventRequest, token),
    {
      onSuccess: () => {
        toast.success('Operazione conclusa con successo');
        setOpen(false);
        queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] });
      },
      onError: () => {
        toast.error(`Ooops... C'è stato un errore`);
        queryClient.invalidateQueries({
          queryKey: ['getEvent', eventId],
        });
      },
    }
  );

  const onSubmit = (values: Partial<FormModel>) => {
    const { amount, paymentDate } = values;

    const data: Partial<EventRequest> = {
      ...event,
      balance: {
        ...event.balance,
        teacherRefunds: {
          ...event.balance?.teacherRefunds,
          finalOutflow: amount || 0,
          paymentDate: moment(paymentDate).format('YYYY-MM-DD'),
        },
      },
    };
    mutate({
      eventId: eventId || '',
      values: data,
      token,
    });
  };

  useEffect(() => {
    if (!open) {
      reset({});
    } else {
      reset({
        paymentDate: event?.balance?.teacherRefunds?.paymentDate,
        amount: event?.balance?.teacherRefunds?.finalOutflow,
      });
    }
  }, [event, open]);

  if (!eventId || isDelegato(profile)) return <></>;

  return (
    <>
      <UnderlineButton
        small
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        disabled={disabled}
      >
        <CurrencyEuroIcon className='w-4 h-4' /> Registra pagamento
      </UnderlineButton>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div
            className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'
            onClick={(e) => e.stopPropagation()}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Registra pagamento per{' '}
                  <span className='italic font-semibold'>
                    eventuali rimborsi relatori da saldare
                  </span>
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='bg-neutral-100 px-4 py-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4'>
                    <InputPresentational
                      id='paymentDate'
                      label='Data'
                      name='paymentDate'
                      type='date'
                      required
                      register={register}
                      error={errors.paymentDate}
                    />
                    <PriceInputPresentational
                      id='amount'
                      label='Compenso'
                      name='amount'
                      type='text'
                      error={errors.amount}
                      register={register}
                    />
                  </div>

                  <div className='flex items-center justify-end gap-4 mt-6'>
                    <PrimaryButton textSmall onClick={toggleModal}>
                      Annulla
                    </PrimaryButton>
                    <SendButton
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      smallRadius
                      disabled={isUpdating}
                      isLoading={isUpdating}
                    >
                      Registra pagamento
                    </SendButton>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RegisterEventTeacherRefund;

import Filters from "../../../../components/Filters/Filters";
import UtenteLezioniAbilitateTable from "../../Tables/UtenteLezioniAbilitate";

const ModificaUtenteLezioniAbilitate = () => {
  return (
    <>
      {/* NB: mostrare questa tab solo per gli utenti che sono anche docenti */}
      <Filters searching course/>
      <UtenteLezioniAbilitateTable />
    </>
  );
};

export default ModificaUtenteLezioniAbilitate;

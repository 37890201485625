import React from "react";

interface IntroSctTitleProps {
  children: any;
}

const IntroSctTitle: React.FC<IntroSctTitleProps> = ({ children }) => {
  return (
    <div className="bg-sand-50 py-10 px-4 lg:px-10">
      <h1 className="font-IBM font-light text-gray-800 text-2xl md:text-3xl">
        {children}
      </h1>
    </div>
  );
};

export default IntroSctTitle;

import { useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import BaseModal from './BaseModal';
import { useForm } from 'react-hook-form';
import SimpleCheckboxPresentational from '../Form/SimpleCheckboxPresentational';
import InputPresentational from '../Form/InputPresentational';

export interface DiplomaForm {
  withBackground: boolean;
  date?: string;
  location?: string;
}

interface Props {
  onConfirm: (values?: DiplomaForm) => void;
  disabled?: boolean;
  participants: string[];
  isLoading?: boolean;
}

const CreateDiplomaModal: React.FC<Props> = ({
  onConfirm,
  disabled,
  participants,
  isLoading,
}) => {
  const [isOpen, setOpen] = useState(false);

  const { handleSubmit, reset, register } = useForm<DiplomaForm>({
    mode: 'onSubmit',
    defaultValues: { withBackground: false },
  });

  const toggleModal = () => {
    setOpen((v) => !v);
    reset({});
  };

  const onSubmit = (values: DiplomaForm) => {
    onConfirm(values);
    toggleModal();
  };

  return (
    <>
      <UnderlineButton disabled={disabled} onClick={() => setOpen(true)}>
        Stampa diploma
      </UnderlineButton>
      <BaseModal
        title={'Stampa diploma'}
        isOpen={isOpen}
        toggle={toggleModal}
        subtitle={'Vuoi stampare i diplomi per i seguenti partecipanti?'}
        className={'mx-auto md:w-[500px] w-11/12'}
        hideBottom
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className='text-xs font-bold py-2 text-gray-600'>
            {participants.join(', ')}.
          </p>
          <InputPresentational
            id='date'
            label='Data'
            name='date'
            type='text'
            register={register}
            className='mt-2'
          />
          <InputPresentational
            id='location'
            label='Luogo'
            name='location'
            type='text'
            className='mt-2'
            register={register}
          />
          <SimpleCheckboxPresentational
            register={register}
            name='withBackground'
            id='withBackground'
            label='Immagine fondo'
            className='mt-2'
          />
          <div className='flex items-center justify-end gap-4 mt-6'>
            <PrimaryButton onClick={toggleModal} textSmall>
              {'Annulla'}
            </PrimaryButton>
            <PrimaryButton
              type='submit'
              small
              isLoading={isLoading}
              disabled={isLoading}
            >
              {'Conferma'}
            </PrimaryButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
};

export default CreateDiplomaModal;

import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const profileValidator = Yup.object().shape({
  email: Yup.string()
    .email(ValidationErrorMessages.EMAIL)
    .required(ValidationErrorMessages.REQUIRED),
  surname: Yup.string().required(ValidationErrorMessages.REQUIRED),
  name: Yup.string().required(ValidationErrorMessages.REQUIRED),
  fiscalCode: Yup.string().required(ValidationErrorMessages.REQUIRED),
  mobilePhone: Yup.string().required(ValidationErrorMessages.REQUIRED),
  section: Yup.number().required(ValidationErrorMessages.REQUIRED),
});

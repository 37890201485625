import axios from 'axios';
import { Coupon } from '../interfaces/coupon';
import qs from 'qs';
import { CouponListResponse, CouponResponse } from '../interfaces/coupon';
import { couponType } from '../constants/coupon';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const listCoupons = async (searchParams: any, token: string | null) => {
  const validFor = searchParams.getAll('type');
  const query = {
    populate: '*',
    filters: {
      active: { $eq: searchParams.get('active') },
      single: { $eq: searchParams.get('single') },
      $or: [
        {
          title: { $contains: searchParams.get('search') },
        },

        ...validFor.map((type: string) => {
          return {
            validFor: { $eq: type },
          };
        }),
      ],
    },
    pagination: {
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize'),
    },
    sort: { updatedAt: 'desc' },
  };

  const { data } = await axios.get<CouponListResponse>(
    `${REACT_APP_API_URL}/coupons?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createCoupon = async ({
  data,
  token,
}: {
  data: any;
  token: string | null;
}) => {
  const response = await instance.post(`${REACT_APP_API_URL}/coupons`, data, {
    headers: { authorization: `Bearer ${token}` },
  });

  return response;
};

export const updateCoupon = async ({
  data,
  token,
  id,
}: {
  data: any;
  token: string | null;
  id: number;
}) => {
  const response = await instance.put(
    `${REACT_APP_API_URL}/coupons/${id}`,
    { data: data },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const getCoupon = async (id: number, token: string | null) => {
  const query = {
    populate: '*',
  };

  const { data } = await axios.get<CouponResponse>(
    `${REACT_APP_API_URL}/coupons/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const deleteCoupon = async (couponId: number, token: string | null) => {
  const { data } = await axios.delete(
    `${REACT_APP_API_URL}/coupons/${couponId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

import React, { useState } from 'react';
import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { regions } from '../../api/regions';
import { FieldError } from 'react-hook-form';

const RegionsSelect = ({
  defaultValue,
  control,
  name,
  required,
  legendIcons,
  error
}: {
  defaultValue?: number;
  control?: any;
  name?: string;
  required?: boolean;
  legendIcons?: JSX.Element[];
  error?: FieldError
}) => {
  const query = useQuery('regions', regions);

  return (
    <>
      {match(query)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          return (
            <SelectPresentational
              label='Regione'
              name={name || 'region'}
              control={control}
              defaultValue={defaultValue}
              options={query.data?.data.map((region) => ({
                value: region.id,
                label: region.attributes.name,
              }))}
              required={required}
              error={error}
              legendIcons={legendIcons}
            />
          );
        })
        .exhaustive()}
    </>
  );
};

export default RegionsSelect;

import React, { useMemo } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import SectionsSelect from './FetchSelect/SectionsSelect';
import InputPresentational from './Form/InputPresentational';
import SelectPresentational from './Form/SelectPresentational';
import TextAreaPresentational from './Form/TextAreaPresentational';
import { levels } from '../constants/levels';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import { CourseTemplateRequest } from '../interfaces/course-templates';
import H4SpacingLetter from './Typography/H4SpacingLetter';
import GadgetForm from './GadgetForm';
import { formatDate } from '../libs/utils/formatters/datetimeFormatter';
import { subDaysFromDate } from '../libs/utils/date';
import { CourseStatus } from '../interfaces/commons';
import ProfileCategorySelect from './FetchSelect/ProfileCategorySelect';
import ReactQuill from 'react-quill';
import UploadAreaPresentational from './Form/UploadAreaPresentational';
import 'react-quill/dist/quill.snow.css';
import ImageUploadFilePreview from './Form/ImageUploadFilePreview';
import CarouselUploadFilesPreview from './Form/CarouselUploadFilesPreview';
import { useAuth } from '../contexts/Auth';
import { checkProfile } from '../libs/utils/helpers';
import moment from 'moment';
import { isDelegato } from '../libs/utils/auth';

interface FormDatiPrincipaliProps {
  onImageDelete?: (fileId?: number) => void;
  onCarouselImageDelete?: (index: number) => void;
  courseMethods?: UseFormReturn<any, any>;
}

const FormDatiPrincipali = ({
  onImageDelete,
  onCarouselImageDelete,
  courseMethods,
}: FormDatiPrincipaliProps) => {
  const {
    REACT_APP_MAIL_DAYS,
    REACT_APP_FIRST_REMINDER_DAYS,
    REACT_APP_SECOND_REMINDER_DAYS,
  } = process.env;
  const {
    register,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CourseTemplateRequest>();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

  const [{ profile }] = useAuth();
  const canDelegatoEdit = watch('canDelegateEdit');

  const onChangeStartDate = (date: string) => {
    if (!getValues('startDate')) return;

    const startDate = new Date(date);

    setValue(
      'mailDate',
      formatDate(
        moment(subDaysFromDate(startDate, Number(REACT_APP_MAIL_DAYS))).set({
          hour: 19,
          minute: 0,
        })
      ),
      { shouldValidate: true }
    );

    setValue(
      'firstReminderDate',
      formatDate(
        moment(
          subDaysFromDate(startDate, Number(REACT_APP_FIRST_REMINDER_DAYS))
        ).set({ hour: 19, minute: 0 })
      ),
      { shouldValidate: true }
    );

    setValue(
      'secondReminderDate',
      formatDate(
        moment(
          subDaysFromDate(startDate, Number(REACT_APP_SECOND_REMINDER_DAYS))
        ).set({ hour: 19, minute: 0 })
      ),
      { shouldValidate: true }
    );

    delete errors.startDate;
  };

  const isCourseCanceled = useMemo(
    () => getValues('status') === CourseStatus.CANCELED,
    [watch('status')]
  );

  const disableForDelegates = useMemo(() => false, []);

  return (
    <div className='pt-6 pb-8 grid grid-cols-6 gap-4 md:gap-x-12'>
      <div className='col-span-6 grid grid-cols-1 md:grid-cols-2 gap-6'>
        <InputPresentational
          id='title'
          label='Titolo'
          name='title'
          type='text'
          register={register}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          required
          error={{ ...errors.title, type: 'required' }}
        />
        <SimpleCheckboxPresentational
          id='isOnline'
          name='isOnline'
          label='Corso Online'
          register={register}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
        />
      </div>
      <div className='col-span-6'>
        <InputPresentational
          id='subtitle'
          label='Sottotitolo'
          name='subtitle'
          type='text'
          register={register}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          required
          error={{ ...errors.subtitle, type: 'required' }}
        />
      </div>

      <div className='col-span-6'>
        <SimpleCheckboxPresentational
          id='includeTeachers'
          name='includeTeachers'
          label='Solo per docenti'
          register={register}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <ProfileCategorySelect
          label='Rivolto a'
          id='minProfileCategorySub'
          name='minProfileCategorySub'
          defaultValue={watch('minProfileCategorySub')}
          control={control}
          required
          error={{ ...errors.minProfileCategorySub, type: 'required' }}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <SectionsSelect
          control={control}
          defaultValue={watch('section') || watch('section.data.id')}
          disabled={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
          required
          error={{ ...errors.section, type: 'required' }}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <SelectPresentational
          label='Livello'
          name='level'
          control={control}
          options={levels}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          required
          error={{ ...errors.level, type: 'required' }}
        />
      </div>

      <div className='col-span-6'>
        <TextAreaPresentational
          id='shortDescription'
          label='Descrizione breve'
          name='shortDescription'
          register={register}
          disabled={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
          required
          error={{ ...errors.shortDescription, type: 'required' }}
        />
      </div>

      <div className='col-span-6'>
        <label
          htmlFor={'description'}
          className='flex justify-between text-sm text-gray-500 mb-2'
        >
          <div>Descrizione</div>
        </label>
        <ReactQuill
          id='description'
          theme='snow'
          value={watch('description')}
          onChange={(value) => setValue('description', value)}
          modules={modules}
          formats={formats}
          readOnly={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='startDate'
          label='Data inizio corso'
          name='startDate'
          type='date'
          disabled={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
          register={register}
          onChange={(e) => onChangeStartDate(e.target.value)}
          required
          error={{ ...errors.startDate, type: 'required' }}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='endDate'
          label='Data fine corso'
          name='endDate'
          type='date'
          disabled={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
          register={register}
          required
          error={{ ...errors.endDate, type: 'required' }}
        />
      </div>
      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='lastRegistrationDate'
          label="Ultima data ammessa per l'iscrizione"
          name='lastRegistrationDate'
          type='date'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
          required
          error={{ ...errors.lastRegistrationDate, type: 'required' }}
        />
      </div>
      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='mailDate'
          label='Data invio email'
          name='mailDate'
          type='datetime-local'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
          required
          error={{ ...errors.mailDate, type: 'required' }}
        />
      </div>
      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='firstReminderDate'
          label='Data reminder 1'
          name='firstReminderDate'
          type='datetime-local'
          disabled={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
          register={register}
          required
          error={{ ...errors.firstReminderDate, type: 'required' }}
        />
      </div>
      <div className='col-span-6 md:col-span-3 lg:col-span-2'>
        <InputPresentational
          id='secondReminderDate'
          label='Data reminder 2'
          name='secondReminderDate'
          type='datetime-local'
          disabled={isCourseCanceled || checkProfile(profile, canDelegatoEdit)}
          register={register}
          required
          error={{ ...errors.secondReminderDate, type: 'required' }}
        />
      </div>

      <div className='col-span-6 md:col-span-6 lg:col-span-6 '>
        <SimpleCheckboxPresentational
          id='disableEmails'
          name='disableEmails'
          label='Disabilita notifiche email'
          register={register}
          disabled={disableForDelegates}
        />
      </div>

      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='supervisorName'
          label='Responsabile'
          name='supervisorName'
          type='text'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <InputPresentational
          id='treasurerName'
          label='Tesoriere'
          name='treasurerName'
          type='text'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
        />
      </div>
      <div className='col-span-6'>
        <H4SpacingLetter>Media</H4SpacingLetter>
      </div>

      <div className='col-span-6 md:col-span-3 space-y-3'>
        <UploadAreaPresentational
          label='Immagine singola'
          name='image'
          formMethods={courseMethods}
          acceptedExtensions={['jpg', 'jpeg', 'png']}
          isSingleImage={true}
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
        />
        <div className='flex flex-wrap items-start justify-start gap-3'>
          <ImageUploadFilePreview
            imageSource={watch('image')}
            onRemove={onImageDelete}
            disabled={
              isCourseCanceled ||
              checkProfile(profile, canDelegatoEdit) ||
              disableForDelegates
            }
          />
        </div>
      </div>
      <div className='col-span-6 md:col-span-3 space-y-3'>
        <UploadAreaPresentational
          label='Carosello'
          name='carousel'
          formMethods={courseMethods}
          acceptedExtensions={['jpg', 'jpeg', 'png']}
          multiple
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
        />
        <div className='flex flex-wrap items-start justify-start gap-3'>
          <CarouselUploadFilesPreview
            imageSources={watch('carousel')}
            onRemove={onCarouselImageDelete}
            disabled={
              isCourseCanceled ||
              checkProfile(profile, canDelegatoEdit) ||
              disableForDelegates
            }
          />
        </div>
      </div>
      <div className='col-span-6'>
        <InputPresentational
          id='videoUrl'
          label='Video URL'
          name='videoUrl'
          type='text'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
        />
      </div>

      <div className='col-span-6'>
        <H4SpacingLetter>Cosa comprende il corso</H4SpacingLetter>
      </div>
      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.lessonsDescription'
          label='Descrizione lezioni'
          name='details.lessonsDescription'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.winesDescription'
          label='Descrizione vini'
          name='details.winesDescription'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <TextAreaPresentational
          id='details.aimings'
          label='Descrizione prove'
          name='details.aimings'
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
          register={register}
        />
      </div>
      <div className='col-span-6 md:col-span-3'>
        <GadgetForm
          disabled={
            isCourseCanceled ||
            checkProfile(profile, canDelegatoEdit) ||
            disableForDelegates
          }
        />
      </div>
    </div>
  );
};

export default FormDatiPrincipali;

import axios from 'axios';
import { CreateJobBody, Section, SectionJobs } from '../interfaces/section';
import { StrapiResponseList, StrapiResponse } from '../interfaces/commons';
import qs from 'qs';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

const create = async ({ data, token }: { data: any; token: string | null }) => {
  const response = await instance.post(`${REACT_APP_API_URL}/sections`, data, {
    headers: { authorization: `Bearer ${token}` },
  });

  return response;
};

export const updateSection = async ({
  data,
  token,
  id,
}: {
  data: any;
  token: string | null;
  id: number;
}) => {
  const response = await instance.put(
    `${REACT_APP_API_URL}/sections/${id}`,
    { data: data },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

const destroy = async ({ token, id }: { token: string | null; id: number }) => {
  const response = await instance.delete(
    `${REACT_APP_API_URL}/sections/${id}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const getSectionJobs = async ({
  sectionId,
  token,
}: {
  sectionId: number;
  token: string | null;
}) => {
  const query = {
    filters: { section: sectionId },
    populate: ['profile', 'onavPosition', 'profile.badge'],
    sort: { updatedAt: 'desc' },
  };
  const { data } = await instance.get<StrapiResponseList<SectionJobs>>(
    `${REACT_APP_API_URL}/section-jobs?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const getSectionJob = async ({
  jobId,
  token,
}: {
  jobId: number;
  token: string | null;
}) => {
  const query = {
    populate: ['profile', 'onavPosition', 'profile.badge'],
  };
  const { data } = await instance.get<StrapiResponse<SectionJobs>>(
    `${REACT_APP_API_URL}/section-jobs/${jobId}?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const getProfileJobs = async ({
  profileId,
  token,
}: {
  profileId: number;
  token: string | null;
}) => {
  const query = {
    filters: { profile: profileId },
    populate: ['onavPosition', 'section'],
  };
  const { data } = await instance.get<StrapiResponseList<SectionJobs>>(
    `${REACT_APP_API_URL}/section-jobs?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const deleteJob = async ({
  token,
  id,
}: {
  id: number;
  token: string | null;
}) => {
  const { data } = await instance.delete(
    `${REACT_APP_API_URL}/section-jobs/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const createJob = async ({
  token,
  body,
}: {
  body: CreateJobBody;
  token: string | null;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/section-jobs`,
    { data: body },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};

export const updateJob = async ({
  token,
  body,
  jobId,
}: {
  body: CreateJobBody;
  token: string | null;
  jobId: string;
}) => {
  const { data } = await instance.put(
    `${REACT_APP_API_URL}/section-jobs/${jobId}`,
    { data: body },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data;
};

const find = async ({
  token,
  query,
}: {
  token: string | null;
  query: object;
}) => {
  const { data } = await axios.get<StrapiResponseList<Section>>(
    `${REACT_APP_API_URL}/sections?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const findOne = async ({
  token,
  query,
  id,
}: {
  token?: string | null;
  query?: object;
  id?: string | number | null;
}) => {
  const { data } = await axios.get<StrapiResponse<Section>>(
    `${REACT_APP_API_URL}/sections/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const findSectionNation = async (id: string) => {
  const query = {
    populate: 'nation',
  };
  const { data } = await axios.get<StrapiResponse<Section>>(
    `${REACT_APP_API_URL}/sections/${id}?${qs.stringify(query)}`
  );

  return data?.data?.attributes.nation;
};

const findOptions = async ({
  token,
  enableAuthentication = true,
  query,
}: {
  token: string | null;
  query?: object;
  enableAuthentication?: boolean;
}) => {
  const { data } = await axios.get<StrapiResponseList<Section>>(
    `${REACT_APP_API_URL}/sections/options?${qs.stringify(query)}`,
    {
      headers:
        enableAuthentication && token
          ? { authorization: `Bearer ${token}` }
          : undefined,
    }
  );
  return data;
};

const sectionsAPIs = {
  find,
  findOne,
  create,
  destroy,
  findSectionNation,
  findOptions,
};

export default sectionsAPIs;

import {
  AcademicCapIcon,
  HandIcon,
  HeartIcon,
  StarIcon,
} from '@heroicons/react/outline';
import {
  AcademicCapIcon as AcademicCapIconSolid,
  HandIcon as HandIconSolid,
  HeartIcon as HeartIconSolid,
  StarIcon as StarIconSolid,
} from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Profile } from '../interfaces/profile';
import UnderlineButton from './Buttons/UnderlineButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';
import { isDelegato } from '../libs/utils/auth';
import { BadgeStatus } from '../interfaces/badge';
import RenewCardModal from './Modals/RenewCardModal';
import { getUserExistingSubDataIfExisting } from '../pages/Utenti/utils';

interface UserBooleanBoxProps {
  userInfo?: Profile;
  setValueForm?: any;
}
const UserBooleanBox: React.FC<UserBooleanBoxProps> = ({
  userInfo,
  setValueForm,
}) => {
  const associatedUSer =
    userInfo?.attributes.badge.data?.attributes.status === BadgeStatus.VALID;
  const [honoraryUser, setHonoraryUser] = useState(
    userInfo?.attributes.isHonoraryMember || false
  );
  const [teacher, setTeacher] = useState(
    userInfo?.attributes.isTeacher || false
  );
  const [volunteer, setVolunteer] = useState(
    userInfo?.attributes.isVolunteer || false
  );
  const navigate = useNavigate();
  const id = userInfo?.id;
  const [{ profile }] = useAuth();

  return (
    <div className='p-8 space-y-4 text-gray-500 rounded-lg bg-neutral-50 height-auto'>
      <div className='flex'>
        {associatedUSer ? (
          <HeartIconSolid className='w-6 h-6 mr-4 text-primary' />
        ) : (
          <HeartIcon className='w-6 h-6 mr-4' />
        )}
        <p className='w-1/3'>Utente associato</p>

        <div className='w-2/3 flex flex-wrap gap-4'>
          {!userInfo && (
            <p className='font-light'>
              Potrai associare l’utente (in modifica) solo dopo averlo creato
            </p>
          )}
          {userInfo?.attributes.badge?.data?.attributes.badgeNumber ? (
            <UnderlineButton
              onClick={() => navigate(`/utenti/modifica/${id}/storico-tessera`)}
            >
              Storico tessera N.
              {userInfo?.attributes.badge?.data?.attributes.badgeNumber}
            </UnderlineButton>
          ) : (
            !associatedUSer &&
            !isDelegato(profile) &&
            userInfo && (
              <RenewCardModal
                buttonType='table'
                infoUser={userInfo}
                subscription={getUserExistingSubDataIfExisting(
                  userInfo.attributes.orders.data
                )}
              />
            )
          )}
        </div>
      </div>
      <div className='flex'>
        {honoraryUser ? (
          <StarIconSolid className='w-6 h-6 mr-4 text-primary' />
        ) : (
          <StarIcon className='w-6 h-6 mr-4' />
        )}
        <p className='w-1/3'>Utente onorario</p>
        <div className='w-2/3'>
          <UnderlineButton
            onClick={() => {
              setHonoraryUser(!honoraryUser);
              setValueForm('isHonoraryMember', !honoraryUser);
            }}
            children={honoraryUser ? 'Disabilita' : 'Abilita'}
          />
        </div>
      </div>

      <div className='flex'>
        {teacher ? (
          <AcademicCapIconSolid className='w-6 h-6 mr-4 text-primary' />
        ) : (
          <AcademicCapIcon className='w-6 h-6 mr-4' />
        )}
        <p className='w-1/3'>Docente</p>
        <div className='flex flex-wrap w-2/3 gap-4'>
          <UnderlineButton
            onClick={() => {
              setTeacher(!teacher);
              setValueForm('isTeacher', !teacher);
            }}
            children={teacher ? 'Disabilita' : 'Abilita'}
          />
          {/* {userInfo && (
            <Underline
              href={`/utenti/modifica/${id}/lezioni-abilitate`}
              label="Lezioni abilitate"
            />
          )} */}
        </div>
      </div>
      <div className='flex'>
        {volunteer ? (
          <HandIconSolid className='w-6 h-6 mr-4 text-primary' />
        ) : (
          <HandIcon className='w-6 h-6 mr-4' />
        )}

        <p className='w-1/3'>Volontario</p>
        <div className='w-2/3'>
          <UnderlineButton
            onClick={() => {
              setVolunteer(!volunteer);
              setValueForm('isVolunteer', !volunteer);
            }}
            children={volunteer ? 'Disabilita' : 'Abilita'}
          />
        </div>
      </div>
    </div>
  );
};

export default UserBooleanBox;

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from '../../libs/utils/classNames';

interface UnderlineProps {
  href?: string;
  label?: string;
  small?: boolean;
  disabled?: boolean;
}

const Underline: React.FC<UnderlineProps> = ({
  label,
  href,
  small,
  disabled,
}) => {
  if (disabled)
    return (
      <span
        className={classNames(
          'text-base flex items-center gap-2 w-max whitespace-nowrap pb-0.5 border-b border-primary text-primary',
          small ? '!text-sm' : '',
          disabled? "cursor-not-allowed text-primary/60": ""
        )}
      >
        {label}
      </span>
    );
  return (
    <>
      {href ? (
        <Link to={href}>
          <a
            className={classNames(
              'text-base flex items-center gap-2 w-max whitespace-nowrap pb-0.5 border-b border-primary text-primary ',
              small ? '!text-sm' : ''
            )}
          >
            {label}
          </a>
        </Link>
      ) : (
        <span>
          <a
            className={classNames(
              'text-base flex items-center gap-2 w-max whitespace-nowrap pb-0.5 border-b border-primary text-primary ',
              small ? '!text-sm' : ''
            )}
          >
            {label}
          </a>
        </span>
      )}
    </>
  );
};

export default Underline;

import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const receiptsValidator = Yup.object().shape({
  code: Yup.string()
    .required(ValidationErrorMessages.REQUIRED)
    .typeError(ValidationErrorMessages.REQUIRED),
  entries: Yup.array()
    .min(1, 'Devi aggiungere almeno una voce alla ricevuta!')
    .of(
      Yup.object().shape({
        amount: Yup.string()
          .required(ValidationErrorMessages.REQUIRED)
          .min(0, "L'importo deve essere maggiore o uguale 0"),
        causal: Yup.string()
          .required(ValidationErrorMessages.REQUIRED)
          .nullable(),
        costCenter: Yup.string()
          .required(ValidationErrorMessages.REQUIRED)
          .nullable(),
        customLabel: Yup.string().when('causal', {
          is: (causal: string) => causal === 'CUSTOM',
          then: Yup.string()
            .required(ValidationErrorMessages.REQUIRED)
            .typeError(ValidationErrorMessages.REQUIRED),
          otherwise: Yup.string().optional().nullable(),
        }),
      })
    ),
});

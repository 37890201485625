import InputPresentational from './Form/InputPresentational';
import { Announcment, AnnouncmentRequest } from '../interfaces/announcments';
import AudienceSelect from './FetchSelect/AudienceSelect';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadAreaPresentational from './Form/UploadAreaPresentational';
import DocumentUploadPrev from './Form/DocumentUploadPrev';

interface FormModificaComunicatoProps {
  announcmentData?: Announcment;
  formMethods?: UseFormReturn<any, any>;
  onFilesDelete?: (fileId?: number) => void;
}

const FormModificaComunicato = ({
  announcmentData,
  formMethods,
  onFilesDelete,
}: FormModificaComunicatoProps) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<AnnouncmentRequest>();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

  const fileSelected = watch('pdf');

  return (
    <div className='grid grid-cols-4 gap-4 pt-6 pb-8 mt-12'>
      <div className='grid grid-cols-1 col-span-12 gap-4 sm:grid-cols-2 xl:col-span-6'>
        <InputPresentational
          id='title'
          label='Titolo'
          name='title'
          type='text'
          register={register}
          defaultValue={announcmentData?.attributes.title}
          required
          error={{ ...errors.title, type: 'required' }}
        />

        <AudienceSelect
          control={control}
          register={register}
          defaultValue={announcmentData?.attributes?.audience?.data?.id}
          required
          error={{ ...errors.audience, type: 'required' }}
        />
      </div>
      <div className='grid grid-cols-1 col-span-12 gap-4 sm:grid-cols-2 xl:col-span-6'>
        <div className='col-span-4'>
          <label
            htmlFor={'description'}
            className='flex justify-between text-sm text-gray-500 mb-2'
          >
            <div>Testo del messaggio*</div>
          </label>
          <ReactQuill
            id='description'
            theme='snow'
            value={watch('description')}
            onChange={(value) => setValue('description', value)}
            modules={modules}
            formats={formats}
          />
          {errors.description && (
            <p className='mt-2 text-sm text-red-500'>
              {errors.description.message}
            </p>
          )}
        </div>
        <div className='space-y-3'>
          <UploadAreaPresentational
            name='pdf'
            label='Pdf comunicato'
            formMethods={formMethods}
            acceptedExtensions={['pdf']}
          />
          <DocumentUploadPrev
            documentName={
              (fileSelected?.length && fileSelected[0].name) ||
              announcmentData?.attributes?.pdf?.data?.attributes?.name
            }
            fieldName='pdf'
            fileId={
              fileSelected?.length
                ? announcmentData?.attributes.pdf?.data?.id
                : undefined
            }
            onRemove={onFilesDelete}
          />
        </div>
        {/* <div className='space-y-4'>
          <p className='w-min'>
            {attachedFile?.url && (
              <>
                <LinkTo
                  href={attachedFile?.url}
                  className='flex items-center hover:text-red-600'
                >
                  {attachedFile?.name} <DownloadIcon width={18} />{' '}
                </LinkTo>
              </>
            )}

            {!attachedFile?.url &&
              attachedFile?.name &&
              `caricato file ${attachedFile.name}`}
          </p>
          <input type='file' {...register('pdf')} />
        </div> */}

        {/* <SimpleCheckboxPresentational
          label='Inserisci firma'
          describedby=''
          id='signature'
          name='signature'
          register={register}
          defaultValue={AnnouncmentData.attributes.signature}
        /> */}
      </div>
    </div>
  );
};

export default FormModificaComunicato;
